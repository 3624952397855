import { Component, Input, OnInit } from '@angular/core';

// Help panel display component which can be given custom content.
@Component({
  selector: 'hub-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit {

  @Input()
  title: string;

  togglePanel: boolean;

  constructor() { }

  ngOnInit(): void {
    this.togglePanel = false;
  }

  onTogglePanel = () => this.togglePanel = !this.togglePanel;

}

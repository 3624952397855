/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { colors } from 'app/shared/values/colours';
import { AddScaffoldDiaryMediaModalComponent } from 'app/sites/components/site-detail/diary/add-diary-media-modal/add-diary-media-modal.component';
import { DeleteScaffoldDiaryMediaModalComponent } from 'app/sites/components/site-detail/diary/delete-diary-media-modal/delete-diary-media-modal.component';
import { AddScaffoldDiaryMediaModalContext } from 'app/sites/models/add-scaffold-diary-media-modal-context';
import { DeleteDiaryMediaModalContext } from 'app/sites/models/delete-diary-media-modal-context';
import { RenameDiaryMediaModalContext } from 'app/sites/models/rename-diary-media-modal-context';
import { DiaryQueryService } from 'app/sites/services/diary-query-service';
import { mediaTypes } from 'app/sites/values/media-types';
import { saveAs } from 'file-saver';
import { Observable, combineLatest } from 'rxjs';
import {
  ConfigurationData,
  DiaryCategoryData,
  MediaBaseData,
  MediaData,
  MediaTranscriptionData,
  ScaffoldCommandService,
  DiaryData,
  ScaffoldWebAppData,
  TranscribeMediaCommand,
  DiaryDataTypeEnum,
  DiaryQuery,
  SiteCommandService,
  SiteDiaryTranscribeMediaCommand
} from 'app/core/hub-api';
import { RenameScaffoldMediaModalComponent } from '../rename-diary-media-modal/rename-diary-media-modal.component';
import { InsightBaseComponent } from 'app/core/components/insight/insight-base.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MediaDataTranscription } from 'app/sites/models/media-data-transcription';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';
import { ToasterService } from 'angular2-toaster';

/**
 * Diary Detail Page
 * This page allows the user to view all the information for a selected diary
 * A media section is displayed where all media can be viewed, downloaded, renamed or transcribed
 *
 * @export
 * @class DiaryDetailComponent
 * @extends {InsightBaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-diary-detail',
  templateUrl: './diary-detail.component.html',
  styleUrls: ['./diary-detail.component.scss']
})
export class DiaryDetailComponent extends InsightBaseComponent implements OnInit, OnDestroy {
  /** Stores all media types */
  mediaTypes = mediaTypes;
  /** Stores the selected site reference (e.g. 00001) */
  siteReference: string;
  /** Stores the scaffold diary */
  scaffoldDiary: DiaryData;
  /** Stores all media data for the diary, contains Audio, Video, Images and PDF */
  mediaData: MediaData[];
  /** Stores the selected scaffold */
  scaffold: ScaffoldWebAppData;
  /** Stores the selected diary category */
  diaryCategory: DiaryCategoryData;
  /** Stores the modal title (e.g. Scaffold Diary or Site Diary) */
  title: string;
  /** Detects if the current is a Site Diary, if not then it is a Scaffold Diary */
  isSiteDiary: boolean;
  /** Detects if the data has loaded so the view can be loaded */
  loaded: boolean;
  /** Stores all the icons for the media types */
  mediaTypeIcons = {
    [mediaTypes.video]: 'fa-video-camera',
    [mediaTypes.audio]: 'fa-headphones',
    [mediaTypes.image]: 'fa-image',
    [mediaTypes.transcription]: 'fa-file-text',
    [mediaTypes.pdf]: 'fa-file-pdf'
  };
  /** Stores the percentage standing data (e.g. 90) */
  percentageStandingData: number[];
  /** Stores the percentage standing list of colors */
  percentageStandingColors: string[];
  /** Stores the selected scaffoldId or siteId */
  id: string;
  /** Stores the selected diary type */
  diaryType: DiaryDataTypeEnum;

  constructor(
    private diaryQueryService: DiaryQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private scaffoldCommandService: ScaffoldCommandService,
    private siteCommandService: SiteCommandService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private bsModalService: BsModalService

  ) {
    super();
  }

  /**
   * Component Initialisation
   *
   * @memberof DiaryDetailComponent
   */
  public ngOnInit(): void {
    this.loaded = false;
    const diaryId = this.route.snapshot.params['diaryId'];
    const diaryTypeParam = this.route.snapshot.params['diaryType'];
    this.diaryType = diaryTypeParam === '0' ? DiaryDataTypeEnum.SiteDiary : DiaryDataTypeEnum.ScaffoldDiary;

    this.id = this.route.snapshot.params['id'];

    this.siteReference = this.route.parent.parent.snapshot.params['siteReference'];

    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = diaryId;
    diaryQuery.DiaryType = this.diaryType;

    const scaffoldDiaryChanges = this.diaryQueryService.getDiaryData(diaryQuery);
    combineLatest([scaffoldDiaryChanges,
      this.configurationQueryService.configurationDataChanges()]).subscribe(latest => {
        this.refreshComponent(latest[0], latest[1]);
      });
  }

  /**
   * Refreshes the diary details page with new/cached data
   *
   * @param {DiaryData[]} diaries
   * @param {ConfigurationData} configuration
   * @memberof DiaryDetailComponent
   */
  public refreshComponent(diaries: DiaryData[], configuration: ConfigurationData): void {

    if (diaries == null) {
      throw (new Error('null value obtained'));
    }
    if (diaries.length === 0) {
      throw (new Error('no diaries found'));
    }
    if (diaries.length > 1) {
      throw (new Error('duplicate diaries found'));
    }
    const diary = diaries[0];
    this.diaryCategory = configuration.DiaryCategories[diary.DiaryCategoryId];
    this.scaffoldDiary = diary;
    this.isSiteDiary = diary.DiaryType === DiaryDataTypeEnum.SiteDiary;
    this.title = this.isSiteDiary ? 'Site Diary' : `Scaffold ${diary.ScaffoldNumber}`;
    this.percentageStandingData = [this.scaffoldDiary.PercentageStanding, 100 - this.scaffoldDiary.PercentageStanding];
    this.percentageStandingColors = [colors.positive, colors.lightGrey];
    const mediaData = this.scaffoldDiary.Images.concat(this.scaffoldDiary.Audios, this.scaffoldDiary.Videos, this.scaffoldDiary.Transcriptions as any, this.scaffoldDiary.PDFs);
    this.mediaData = mediaData.filter(d => d.Title = !d.Title ? 'Untitled' : d.Title)
      .sort((a, b) => (a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1));
    this.loaded = true;
  }

  /**
   * Will open the respective selected file
   * The file will be opened on the DiaryMediaViewerComponent
   *
   * @param {MediaBaseData} media selected media from the side list
   * @memberof DiaryDetailComponent
   */
  onMediaClicked(media: MediaBaseData): void {
    this.router.navigate([media.Type, media.MediaReferenceId], { relativeTo: this.route, state: { selectedMedia: media } });
  }

  /**
   * Allows to download the transcription as a word format document
   *
   * @param {MediaTranscriptionData} transcription selected media file data
   * @memberof DiaryDetailComponent
   */
  public onDownloadClicked(transcription: MediaTranscriptionData): void {
    const transcriptionBlob = new Blob([`<!DOCTYPE html>${transcription.Transcription}`], { type: 'text/html' });
    const filename = `${transcription.Title}.doc`;
    saveAs(transcriptionBlob, filename);
  }


  /**
   * Dialog to confirm if user wants to transcribe the selected media
   *
   * @param {MediaDataTranscription} media selected media file
   * @memberof DiaryDetailComponent
   */
  public onTranscribeClicked(media: MediaDataTranscription): void {
    const context: SSDialogContext = {
      title: 'Transcribe Media?',
      body: `This operation might take a while depending on the audio duration. You will be notified when the transcription file is completed.`,
      okBtnText: 'Transcribe Media',
      cancelBtnText: 'Cancel',
    };
    const modal = this.bsModalService.show(ModalDialogComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe(
      result => {
        if (result !== null) {
          return this.transcribeMedia(media);
        }
      },
      error => {
        console.error(error);
      },
      () => { }
    );
  }


  /**
   * Transcribes the selected media
   * In case of success or failure it will show a toaster message
   *
   * @param {MediaDataTranscription} media selected media file
   * @public was made public so we can do unit testing
   * @memberof DiaryDetailComponent
   */
  public transcribeMedia(media: MediaDataTranscription): void {
    media.transcribing = true;
    const transcribeObservable = this.isSiteDiary ? this.transcribeSiteMedia(media) : this.transcribeScaffoldMedia(media);
    transcribeObservable.subscribe(
      () => {
        this.toasterService.pop('success', 'Transcription Complete', `Transcription of "${media.Title}" is complete.`);
        media.transcribing = false;
        const diaryQuery = new DiaryQuery();
        diaryQuery.DiaryId = this.scaffoldDiary.DiaryId;
        diaryQuery.DiaryType = this.diaryType;

        const scaffoldDiaryChanges = this.diaryQueryService.getDiaryData(diaryQuery);
        combineLatest([scaffoldDiaryChanges,
          this.configurationQueryService.configurationDataChanges()]).subscribe(latest => {
            this.refreshComponent(latest[0], latest[1]);
          });
      },
      () => {
        this.toasterService.pop('error', 'Transcription Failed', `Transcription of "${media.Title}" failed.`);
        media.transcribing = false;
      }
    );
  }

  /**
   * Transcribes the media when it is a Site Diary
   *
   * @private
   * @param {MediaData} media selected media file
   * @returns {Observable<any>}
   * @memberof DiaryDetailComponent
   */
  private transcribeSiteMedia(media: MediaData): Observable<any> {
    const transcribeMediaCommand: SiteDiaryTranscribeMediaCommand = {
      SiteId: this.id,
      MediaReferenceId: media.MediaReferenceId
    };
    return this.siteCommandService
      .SiteDiaryTranscribeMediaCommand(transcribeMediaCommand);
  }

  /**
   * Transcribes the media when it is a Scaffold Diary
   *
   * @private
   * @param {MediaData} media selected media file
   * @returns {Observable<any>}
   * @memberof DiaryDetailComponent
   */
  private transcribeScaffoldMedia(media: MediaData): Observable<any> {
    const transcribeMediaCommand: TranscribeMediaCommand = {
      ScaffoldId: this.id,
      MediaReferenceId: media.MediaReferenceId
    };
    return this.scaffoldCommandService.TranscribeMediaCommand(transcribeMediaCommand);
  }



  /**
   * Detects if the selected media file can be transcribed
   * NOTE: This is used on UI
   *
   * @param {MediaDataTranscription} media selected media file
   * @returns {boolean}
   * @memberof DiaryDetailComponent
   */
  public canBeTranscribed(media: MediaDataTranscription): boolean {
    // The markup is commented
    return !media.transcribing && (media.Type === mediaTypes.audio || media.Type === mediaTypes.video);
  }

  /**
   * User triggered action to rename the selected media file
   *
   * @param {MediaBaseData} media selected media file
   * @memberof DiaryDetailComponent
   */
  public onRename(media: MediaBaseData): void {
    const context: RenameDiaryMediaModalContext = {
      transcriptionMode: media.Type === mediaTypes.transcription,
      scaffoldDiary: this.scaffoldDiary,
      media: media,
      id: this.id
    };
    this.bsModalService.show(RenameScaffoldMediaModalComponent, SSModalConfig.generate(context));
    this.bsModalService.onHide.subscribe(
      (newName) => {
        context.media.Title = newName;
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * User triggered action to deleted the selected media file
   *
   * @param {MediaBaseData} media selected media file
   * @memberof DiaryDetailComponent
   */
  public onDelete(media: MediaBaseData): void {
    const context: DeleteDiaryMediaModalContext = {
      diary: this.scaffoldDiary,
      media: media,
      id: this.id
    };
    this.bsModalService.show(DeleteScaffoldDiaryMediaModalComponent, SSModalConfig.generate(context));
    this.bsModalService.onHide.subscribe(
      (mediaReferenceId) => {
        this.mediaData = this.mediaData.filter(a => a.MediaReferenceId !== mediaReferenceId);
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * User triggered option opens the upload media modal
   *
   * @memberof DiaryDetailComponent
   */
  public onUploadMedia(): void {
    const context: AddScaffoldDiaryMediaModalContext = {
      siteReference: this.siteReference,
      diary: this.scaffoldDiary,
      scaffoldId: this.id,
      diaryType: this.diaryType
    };
    this.bsModalService.show(AddScaffoldDiaryMediaModalComponent, SSModalConfig.generate(context));
    this.bsModalService.onHide.subscribe(
      () => {
        const diaryQuery = new DiaryQuery();
        diaryQuery.DiaryId = this.scaffoldDiary.DiaryId;
        diaryQuery.DiaryType = this.diaryType;
        const scaffoldDiaryChanges = this.diaryQueryService.getDiaryData(diaryQuery);
        const obs$ = combineLatest([scaffoldDiaryChanges,
          this.configurationQueryService.configurationDataChanges()]);
        obs$.subscribe(latest => {
          this.refreshComponent(latest[0], latest[1]);
        });
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * Required by the AutoUnsubscribe
   *
   * @memberof DiaryDetailComponent
   */
  public ngOnDestroy(): void { }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivityModalService } from 'app/shared/services/activity-modal.service';
import { slice as _slice } from 'lodash';
import { Activity } from '../../models/activity.model';
@Component({
  selector: 'hub-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit, OnChanges {
  @Input()
  viewApiData: Activity[];
  @Input()
  refresh: boolean;
  @Output() sortActivity = new EventEmitter();
  @Output() bufferedRecords: EventEmitter<any> = new EventEmitter();
  displayData = [];
  startNum = 0;
  noResults = false;
  loading = false;

  columnHeaders: any[] = [
    {
      name: 'Name',
      value: 'StaffName'
    },
    {
      name: 'Time',
      value: 'ActivityDateTime'
    },
    {
      name: 'Sites',
      value: 'SiteName'
    },
    {
      name: 'Customer',
      value: 'CustomerName'
    },
    {
      name: 'Scaffold',
      value: 'ScaffoldNumber'
    },
    {
      name: 'Area',
      value: 'Area'
    },
    {
      name: 'Activity',
      value: 'ActivityType'
    },
    {
      name: 'Status',
      value: 'Status'
    }
  ];
  constructor(private activityModal: ActivityModalService) { }

  ngOnInit(): void {
    this.activityModal.loading.subscribe(result => {
      this.loading = result;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const apiData = changes.viewApiData.currentValue;
    if (!Array.isArray(apiData) || apiData.length === 0) {
      this.noResults = true;
      return;
    }

    this.startNum = 0;
    this.displayData = [];
    if (this.refresh) {
      this.displayData = apiData;
    } else {
      this.displayData.push(..._slice(apiData, this.startNum, (this.startNum += 25)));
    }

    this.noResults = false;
  }

  scaffoldSubTitle(name: string, description: string): string {
    const retString = name + ', ' + description;
    return retString.trim().replace(/(^,)|(,$)/g, '');
  }

  loadMore(): void {
    this.bufferedRecords.emit((this.viewApiData.length - this.displayData.length));
    if (!Array.isArray(this.viewApiData) || this.displayData.length >= this.viewApiData.length) return;
    const numberToLoad = Math.min(this.viewApiData.length, 25);
    this.displayData.push(..._slice(this.viewApiData, this.startNum, (this.startNum += numberToLoad)));
  }

  sorting(event): any {
    if (event !== 'ActivityType' && event !== 'Status') {
      this.sortActivity.emit(event);
    }
  }

  openModal(activityType: number, activityTypeId: string, siteReference: string): void {
    this.loading = true;
    this.activityModal.openActivityModal(activityType, activityTypeId, siteReference);
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-principal-contract',
  templateUrl: './principal-contract.component.html',
  styleUrls: ['./principal-contract.component.scss']
})
export class PrincipalContractComponent implements OnInit {

  tooltipMessage: string;
  icon: string;
  status: string;
  _principalContract: boolean;
  @Input()
  set principalContract(principalContract: boolean) {
    this._principalContract = principalContract;
    this.isPrincipalContract();
  }

  constructor() { }

  ngOnInit(): void {
    this.isPrincipalContract();
  }

  isPrincipalContract(): void {
    if (this._principalContract) {
      this.status = 'Main Contractor';
      this.icon = 'fa-star';
      this.tooltipMessage = 'A main contractor can receive the sub-contractor\'s inspection reports.';
    } else {
      this.status = 'Sub-contractor';
      this.icon = 'fa-star-o';
      this.tooltipMessage = 'A main contractor can receive the sub-contractor\'s inspection reports.';
    }
  }

}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { LoggedInStaffService } from 'app/core/services/logged-in-staff-service';
import { SitesQueryService } from 'app/core/services/sites-query.service';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { LoadingSpinnerService } from 'app/shared/services/loading-spinner.service';
import { roleIds } from 'app/shared/values/role-ids';
import { flatMap, forEach, intersectionWith, isEqual, map, orderBy as _orderBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Observable, timer as observableTimer } from 'rxjs';
import { ActivitiesQuery, DiaryTypeEnum, ScaffoldEndpointService, StaffData, StaffDetailsWebAppData } from 'app/core/hub-api';
import { Activity } from '../../models/activity.model';
import { DatePickerOptions } from '../../../reports/models/date-picker-options';
import { DateService } from 'app/shared/services/date.service';
import { DropdownComponent } from 'app/shared/components/dropdown/dropdown.component';
import { datesDayjs } from 'app/shared/values/dates';
import { DatePickerSelectData } from 'app/shared/models/date-picker-select-data';
import { LoadingBarService } from '@ngx-loading-bar/core';

/**
 * Activity Tab Component
 * this page contains the filters and the container structure for the activity tab
 *
 * @export
 * @class ActivityTabComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-activity',
  templateUrl: './activity-tab.component.html',
  styleUrls: ['./activity-tab.component.scss']
})
export class ActivityTabComponent implements OnInit, OnDestroy {
  @ViewChild('siteDropdown') siteDropdown: DropdownComponent;
  @ViewChild('activityDropdown') activityDropdownComponent: DropdownComponent;
  @ViewChild('staffDropdown') staffDropdown: DropdownComponent;
  /** Detects if page is loading */
  loading = true;
  /** Stores the selected staff */
  staff: StaffData[];
  /** Stores all staff */
  allStaff: StaffData[];
  /** Stores all staff */
  sites: any;
  /** Stores all activity types */
  activityType: Number[] = [];
  /** Feed data to the activity table */
  viewApiData: Activity[] = [];
  /** Stores if the table is going to auto refresh */
  refresh: boolean;
  /** Stores the maximum page size */
  pageSize = 250;
  /** Stores the default page number */
  pageNumber = 1;
  /** Stores date picker configuration */
  datePickerOptions = new DatePickerOptions;
  /** Activates or Deactivates the apply filters button */
  enableButton = true;
  /** Stores the sort by property */
  sortBy: string = null;
  /** Stores if the order of the table by ASC or DESC */
  isOrderByDesc = false;
  /** Stores the data for the activity dropdown */
  activityDropdownItems = [
    {
      name: 'Adaption',
      value: 15
    },
    {
      name: 'Diary',
      value: 1
    },
    {
      name: 'Dismantle',
      value: 3
    },
    {
      name: 'Handover',
      value: 5
    },
    {
      name: 'Re-Handover',
      value: 6
    },
    {
      name: 'Inspection',
      value: 2
    },
    {
      name: 'Inspection Follow Up actions',
      value: 10
    },
    {
      name: 'Inspection Remedial Actions',
      value: 9
    },
    {
      name: 'Full Off-Hire',
      value: 7
    },
    {
      name: 'Partial Off-Hire',
      value: 8
    },
    {
      name: 'Unsafe Scaffold Report',
      value: 11
    },
    {
      name: 'Variation',
      value: 4
    }
  ];
  /** Stores the currently logged in user */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores if the form values have changed */
  hasChanges = false;

  constructor(
    private dateService: DateService,
    private scaffoldEndpointService: ScaffoldEndpointService,
    private sitesQueryService: SitesQueryService,
    private staffsQueryService: StaffsQueryService,
    private changeDetector: ChangeDetectorRef,
    public loggedInStaffService: LoggedInStaffService,
    public loadingSpinner: LoadingSpinnerService,
    private configurationQueryService: ConfigurationQueryService,
    private loader: LoadingBarService
  ) { }

  activityForm = new UntypedFormGroup({
    activityType: new UntypedFormControl(),
    sitesForm: new UntypedFormControl(),
    timeAndDate: new UntypedFormControl(),
    staffForm: new UntypedFormControl()
  });

  /**
   * Initialises the component
   *
   * @memberof ActivityTabComponent
   */
  public ngOnInit(): void {
    this.setDefaultDateRange();
    this.generateActivityName();
    this.getFormControlsData();
    this.activityForm.valueChanges.subscribe(changes => this.onFormChanges(changes));
    // TODO: Pass the autoRefresh from settings - replace 5 with the settings, this is the time in minutes.
    this.autoRefresh(5).subscribe(() => {
      const pageSize = this.pageNumber * this.pageSize;
      this.generateActivityQuery(this.sortBy, 1, false, true, pageSize);
    });
  }

  /**
  * Subscribes to changes in the activity form and handles changes on the activity type dropdown, sites dropdown, staff dropdown, and datepicker.
  * Sets the `hasChanges` property to `true` if any changes are detected, and to `false` otherwise.
  * @private
  *
  * @memberof ActivityTabComponent
  */
  private onFormChanges(changes): void {
    this.enableButton = changes.timeAndDate ? true : false;
    this.changeDetector.detectChanges();
    // Handle changes on the activity type dropdown
    if (changes.ActivityType) {
      this.hasChanges = true;
      return;
    }

    // Handle changes on the sites dropdown
    if (changes.sitesForm) {
      this.hasChanges = true;
      return;
    }

    // Handle changes on the staff dropdown
    if (changes.staffForm) {
      this.hasChanges = true;
      return;
    }

    // Handle changes on the datepicker
    // as the default date range is last 24 hours, we need to check if the user has changed the date range
    if (!changes.timeAndDate || changes.timeAndDate && changes.timeAndDate.startDate !== datesDayjs.yesterday && changes.timeAndDate.endDate !== datesDayjs.endOfToday) {
      this.hasChanges = true;
      return;
    }
    this.hasChanges = false;
  }

  /**
   * Generates the activity names for the activity types dropdown
   *
   * @private
   * @memberof ActivityTabComponent
   */
  private generateActivityName(): void {
    for (const item in DiaryTypeEnum) {
      if (!isNaN(+item)) {
        this.activityType.push(+item);
      }
    }
  }

  /**
    * Clears all filters
    * This function will only run if the user has made changes to the filters
    * this also avoids calling the API if the user has not made any changes
    *
    * @memberof ActivityTabComponent
    */
  public resetFilters(): void {
    if (!this.hasChanges) return;
    this.activityForm.get('sitesForm').setValue(null);
    this.activityForm.get('activityType').setValue(null);
    this.activityForm.get('staffForm').setValue(null);
    this.activityForm.get('timeAndDate').setValue(null);
    this.activityDropdownComponent.clearSelectedValue();
    this.staffDropdown.clearSelectedValue();
    this.siteDropdown.clearSelectedValue();
    this.viewApiData = [];
    this.setDefaultDateRange();
    this.getNewFormControlsData();
  }

  /**
   * Creates a clean form filter
   *
   * @param {string} [orderBy=null]
   * @param {number} [PageNumber=1]
   * @memberof ActivityTabComponent
   */
  public getNewFormControlsData(orderBy: string = null, PageNumber: number = 1): void {
    this.viewApiData = [];
    this.generateActivityQuery(orderBy, PageNumber);
  }

  /**
   * Builds the filters to be called later on query
   *
   * @private
   * @param {string} [orderBy=null] order by property name
   * @param {number} [PageNumber=1] current page number
   * @param {boolean} [loading=true] shows page loading
   * @param {number} [pageSize=this.pageSize] length of data visible per page
   * @returns {ActivitiesQuery}
   * @memberof ActivityTabComponent
   */
  private buildFilterObject(orderBy: string = null, PageNumber: number = 1, loading: boolean = true, pageSize: number = this.pageSize): ActivitiesQuery {
    this.loading = loading;
    const filterObject = new ActivitiesQuery();
    filterObject.ActivityType = this.activityForm.get('activityType').value;
    filterObject.StaffId = this.activityForm.get('staffForm').value;
    filterObject.SiteId = this.activityForm.get('sitesForm').value;
    filterObject.OrderBy = orderBy;
    filterObject.IsOrderByDesc = this.isOrderByDesc;
    const timeAndDate = this.activityForm.get('timeAndDate').value;
    if (timeAndDate) {
      filterObject.ActivityDateToTime = timeAndDate.endDate.toISOString();
      filterObject.ActivityDateFromTime = timeAndDate.startDate.toISOString();
    }
    filterObject.PageNumber = PageNumber;
    filterObject.PageSize = pageSize;
    return filterObject;
  }

  /**
   * Gets all data to load to the filters
   *
   * @private
   * @memberof ActivityTabComponent
   */
  private getFormControlsData(): void {
    const obs$ = combineLatest([
      this.sitesQueryService.sitesQuery(false),
      this.staffsQueryService.staffsQuery(false, false),
      this.loggedInStaffService.loggedInStaffQuery(false),
      this.configurationQueryService.configurationQuery(true)
    ]);
    obs$.subscribe(
      latest => {
        this.loggedInStaff = latest[2];
        const assignedSiteIds = map(this.loggedInStaff.Sites, s => s.SiteId);
        const assignedStaff = [];
        forEach(latest[1], s => {
          const staff = intersectionWith(s.Sites, assignedSiteIds, isEqual).map(x => s);
          if (staff.length) {
            return assignedStaff.push(staff);
          }
        });
        const isAdmin = this.loggedInStaff.RoleId === roleIds.admin;
        this.sites = _orderBy(isAdmin ? latest[0] : latest[0].filter(s => assignedSiteIds.includes(s.SiteId)), 'SiteName', 'asc');
        this.staff = _orderBy(isAdmin ? latest[1] : uniqBy(flatMap(assignedStaff), s => s.StaffId), 'ContactName', 'asc');
        this.allStaff = this.staff;
      },
      error => { },
      () => {
        this.changeDetector.detectChanges();
      }
    );
  }

  /**
   * Creates the activity tab query depending on the params
   *
   * @private
   * @param {string} [orderBy=null] order by property name
   * @param {number} [PageNumber=1] selected page number
   * @param {boolean} [loading=true] set page loading
   * @param {boolean} [autoRefresh=false] make the page to auto refresh
   * @param {number} [pageSize=this.pageSize] length of data visible per page
   * @memberof ActivityTabComponent
   */
  private generateActivityQuery(
    orderBy: string = null,
    PageNumber: number = 1,
    loading: boolean = true,
    autoRefresh: boolean = false,
    pageSize: number = this.pageSize
  ): void {
    const loadingBar = this.loader.useRef('global');
    loadingBar.start();
    this.scaffoldEndpointService.ActivitiesQuery(this.buildFilterObject(orderBy, PageNumber, loading, pageSize)).subscribe(
      latest => {
        this.viewApiData = latest.Results;
        this.refresh = autoRefresh;
      },
      error => {
        console.log(error);
      },
      () => {
        this.loading = false;
        loadingBar.complete();
      }
    );
  }

  /**
   * Sets the default period range in the date picker
   *
   * @private
   * @memberof ActivityTabComponent
   */
  private setDefaultDateRange(): void {
    const dateRange: DatePickerSelectData = {
      startDate: datesDayjs.yesterday,
      endDate: datesDayjs.endOfToday
    };
    this.activityForm.controls.timeAndDate.setValue(dateRange);
  }

  /**
   * Converts minutes to milliseconds
   * e.g. if user sets time to be 5 then it will convert those minutes into milliseconds
   *
   * @private
   * @param {number} time time in minutes
   * @returns {number}
   * @memberof ActivityTabComponent
   */
  private minutesToMilliseconds(time: number): number {
    return Math.floor(time * 60 * 1000);
  }

  /**
   * Sets the auto refresh time
   * e.g. if user sets userTime to be 5 then it will convert those minutes into milliseconds
   * and then set the refresh time to that period
   *
   * @param {number} [userTime] time in minutes
   * @returns {Observable<number>}
   * @memberof ActivityTabComponent
   */
  public autoRefresh(userTime?: number): Observable<number> {
    return observableTimer(0, this.minutesToMilliseconds(userTime));
  }

  /**
   * Sorts the table by selected column
   * (Used on UI only)
   *
   * @param {*} event DOM event
   * @returns {void}
   * @memberof ActivityTabComponent
   */
  public sortApi(event): void {
    this.sortBy = event;
    this.isOrderByDesc = !this.isOrderByDesc;
    this.generateActivityQuery(this.sortBy, 1, true, true);
  }

  /**
   * Updates the data as the user scrolls
   * jumps to next page if required
   *
   * @param {number} bufferedRecords records to be scrolled
   * @returns {void}
   * @memberof ActivityTabComponent
   */
  public updateView(bufferedRecords: number): void {
    if (!bufferedRecords || bufferedRecords > 100) return;
    const nextPageNumber = Math.floor(this.viewApiData.length / this.pageSize) + 1;
    if (this.pageNumber >= nextPageNumber) return;
    this.pageNumber = nextPageNumber;
    this.generateActivityQuery(this.sortBy, nextPageNumber, false, false);
  }

  /**
   * Detects when the selected site has changed and sets the associated staff
   *
   * @param {string} siteId
   * @returns {void}
   * @memberof ApplicationForPaymentFiltersComponent
   */
  onSelectedSiteChanged(siteId: string): void {
    this.activityForm.controls.sitesForm.setValue(siteId);
    this.staff = !siteId ? this.allStaff : this.staff.filter(s => s.Sites.includes(siteId));
  }

  /**
  * Detects when the selected activity has changed
  *
  * @param {string[]} activityIds
  * @returns {void}
  * @memberof ApplicationForPaymentFiltersComponent
  */
  onSelectedActivityChanged(activityIds: string[]): void {
    this.activityForm.controls.activityType.setValue(activityIds);
  }

  /**
   * Detects when the dates are selected by the user
   *
   * @param {*} dates range of dates
   * @memberof ActivityTabComponent
   */
  onDateRangeSelected(dates: any): void {
    // detects if the date range was cleared and sets the date to null
    if (!dates.startDate || !dates.endDate) return this.activityForm.controls.timeAndDate.setValue(null);
    this.activityForm.controls.timeAndDate.setValue(dates);
  }

  /**
  * Detects when the selected staff has changed
  *
  * @param {string[]} staffIds
  * @returns {void}
  * @memberof ApplicationForPaymentFiltersComponent
  */
  onSelectedStaffChanged(staffIds: string[]): void {
    this.activityForm.controls.staffForm.setValue(staffIds);
  }

  /**
   * Required by the AutoUnsubscribe
   *
   * @memberof ActivityTabComponent
   */
  ngOnDestroy(): void { }
}

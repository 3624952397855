import { ApplicationSettingsQueryService } from '../../services/application-settings-query.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { ConfigurationQueryService } from '../../services/configuration-query.service';

@Component({
  selector: 'hub-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  clientVersion: string = environment.clientVersion;
  serverVersion = 'unknown';

  constructor(private applicationSettingsQueryService: ApplicationSettingsQueryService) { }

  ngOnInit(): void {
    this.applicationSettingsQueryService.applicationSettingsQuery(true)
      .subscribe(settings => {
        if (settings) { this.serverVersion = settings.ServerVersion; }
      });
  }

}

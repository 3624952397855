import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { ContractCommandService, ContractData, CustomerWebAppData, EditContractCommand, SiteWebAppData } from 'app/core/hub-api';
import { CustomerDetailQueryService } from '../../../../../core/services/customer-detail-query.service';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { OpenCloseContractModalContext } from '../../../../models/open-close-contract-modal-context';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';

@Component({
  selector: 'hub-open-close-contract-modal',
  templateUrl: './open-close-contract-modal.component.html',
  styleUrls: ['./open-close-contract-modal.component.scss']
})
export class OpenCloseContractModalComponent extends FormComponent implements OnInit {
  context: Partial<OpenCloseContractModalContext>;
  form: UntypedFormGroup;
  validationMessages = {};

  openMode: boolean;
  contract: ContractData;
  customers: CustomerWebAppData[];
  site: SiteWebAppData;

  title: string;
  text: string;
  saveText: string;
  cancelText: string;

  contractStatusId: string;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    public contractCommandService: ContractCommandService,
    public siteDetailQueryService: SiteDetailQueryService,
    public customerDetailQueryService: CustomerDetailQueryService,
    public modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.form = new UntypedFormGroup({});
    this.openMode = this.context.openMode;
    this.contract = this.context.contract;
    this.customers = this.context.customers;
    this.site = this.context.site;

    if (this.openMode) {
      this.initOpenMode();
    } else {
      this.initCloseMode();
    }
    super.ngOnInit();
  }

  initOpenMode(): void {
    this.contractStatusId = contractStatusIds.open;
    this.title = 'Reopen Contract';
    this.text = `Are you sure you want to reopen the contract ${this.contract.ContractName}(${this.contract.ContractReference})?`;
    this.saveText = 'Yes reopen contract';
    this.cancelText = 'No keep it closed';
  }

  initCloseMode(): void {
    this.contractStatusId = contractStatusIds.closed;
    this.title = 'Close Contract';
    this.text =
      `Are you sure you want to close the contract ${this.contract.ContractName}(${this.contract.ContractReference})?
       You can still view closed contracts but you will no longer be able to edit it or make any changes.`;
    this.saveText = 'Yes close contract';
    this.cancelText = 'No keep it open';
  }

  onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.saveInProgress = true;

      const command: EditContractCommand = Object.assign({}, this.contract, { ContractStatusId: this.contractStatusId });

      // Here we must reload any data that this edit affects from the server.
      this.contractCommandService.EditContractCommand(command).subscribe(() => {
        const customerReference = this.customers.find(c => c.CustomerId === this.contract.CustomerId).CustomerReference;
        const obs$ = forkJoin([
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.customerDetailQueryService.customerDetailQuery(false, customerReference)
        ]);
        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.bsModalService.setDismissReason(customerReference);
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { DiaryActivityDetails, DiaryDataTypeEnum, SiteWebAppData } from 'app/core/hub-api';
import { colors } from 'app/shared/values/colours';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerService } from 'app/shared/services/loading-spinner.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SiteDetailQueryService } from 'app/sites/services/site-detail-query.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@AutoUnsubscribe()
@Component({
  selector: 'hub-diary-view',
  templateUrl: './diary-view.component.html',
  styleUrls: ['./diary-view.component.scss']
})
export class DiaryViewComponent implements OnInit, OnDestroy {
  ActivityTypeId: string;
  SiteReference: string;
  ActivityType: number;
  DiaryCategory: string;
  ActivityDetail: DiaryActivityDetails;
  percentageStandingData: number[];
  percentageStandingColors: string[];
  isSiteDiary: boolean;
  currentSite: SiteWebAppData;
  context: Partial<ActivityModalContext>;

  constructor(
    private configurationQueryService: ConfigurationQueryService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingSpinner: LoadingSpinnerService,
    private siteDetailQueryService: SiteDetailQueryService,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    // this.context.dialogClass = 'modal-dialog modal-lg';
    this.ActivityType = this.context.ActivityType;
    this.SiteReference = this.context.Site.SiteReference;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.percentageStandingData = [this.ActivityDetail.PercentageStanding, 100 - this.ActivityDetail.PercentageStanding];
    this.percentageStandingColors = [colors.positive, colors.lightGrey];
    this.isSiteDiary = !this.ActivityDetail.ScaffoldId;

    this.siteDetailQueryService.siteDetailQuery(false, this.SiteReference)
      .subscribe((site) => {
        this.currentSite = site;
      });

    this.configurationQueryService.configurationQuery(true).subscribe(res => {
      this.DiaryCategory = res.DiaryCategories[this.ActivityDetail.DiaryCategoryId].Title;
    });
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  goToDetails(): void {
    this.loadingSpinner.show();
    this.bsModalRef.hide();
    const scaffoldOrSiteId = this.getId();
    const diaryDataType = this.isSiteDiary ? DiaryDataTypeEnum.SiteDiary : DiaryDataTypeEnum.ScaffoldDiary;
    this.router.navigate([`/sites/${this.SiteReference}/diary/${this.ActivityDetail.ActivityId}/${diaryDataType}/${scaffoldOrSiteId}`], { relativeTo: this.route });
  }


  /**
   * Gets the site or scaffold id depending if it is a site diary or scaffold diary
   *
   * @returns {string}
   * @memberof DiaryViewComponent
   */
  getId(): string {
    return (this.isSiteDiary) ? this.currentSite.SiteId : this.ActivityDetail.ScaffoldId;
  }

  ngOnDestroy(): void { }
}

import { Injectable } from '@angular/core';
import { HandoverHistoryTypeEnum } from 'app/core/hub-api';
import { environment } from 'environments/environment';
import { HandoverTypeOption } from '../models/handover-type-option';

@Injectable()
export class HandoverTypeService {
  private variation: boolean;
  private handover: boolean;
  featureFlags = (environment.featureFlags as any);

  constructor() { }

  private createHandoverTypes(): HandoverTypeOption[] {
    return [
      {
        name: 'Initial Handovers',
        type: HandoverHistoryTypeEnum.FullHandover,
        display: this.handover
      },
      {
        name: 'Re-Handovers',
        type: HandoverHistoryTypeEnum.PartialHandover,
        display: this.handover
      },
      {
        name: 'Full Off-Hires',
        type: HandoverHistoryTypeEnum.FullOffHire,
        display: this.handover
      },
      {
        name: 'Partial Off-Hires',
        type: HandoverHistoryTypeEnum.PartialOffHire,
        display: this.handover
      },
      {
        name: 'Variations',
        type: HandoverHistoryTypeEnum.Variation,
        display: this.variation
      },
      {
        name: 'Adaptions',
        type: HandoverHistoryTypeEnum.Adaption,
        display: this.variation
      }
    ];
  }

  getHandoverTypes(): HandoverTypeOption[] {
    this.handover = this.featureFlags.handover;
    this.variation = this.featureFlags.variation;
    return this.createHandoverTypes();
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteScaffoldDiaryData } from 'app/core/hub-api';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';

@Injectable()
export class SiteScaffoldDiaryResolver implements Resolve<SiteScaffoldDiaryData[]> {

  constructor(private siteDiaryQueryService: SiteScaffoldDiaryQueryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<SiteScaffoldDiaryData[]> {
    const reference = route.parent.parent.params['siteReference'];
    return this.siteDiaryQueryService.siteScaffoldDiaryQuery(false, reference);
  }
}

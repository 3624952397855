import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { colors } from 'app/shared/values/colours';
import { padStart } from 'lodash';

@Injectable()
export class TextToColorService {

  // Set the icon to grey if disabled, or a colour selected based on the name string otherwise.
  textToColor(text: string, colours?: string[]): string {
    const hashedName = this.hashText(text);
    if (colours) {
      const colourIndex = Math.abs(hashedName % colours.length);
      return colours[colourIndex];
    } else {
      // Generate a random hex colour.
      const hexValue = (hashedName % Math.pow(16, 6)).toString(16);
      return `#${padStart(hexValue, 6, '0')}`;
    }
  }

  // Function taken from here: http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
  private hashText(text): number {
    let hash = 0, i, chr, len;

    if (text.length === 0) {
      return hash;
    }

    for (i = 0, len = text.length; i < len; i++) {
      chr = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

// Error messages to be displayed in the UI throughout the application.
// A key may map to a key in an error response from the server, when this is the case the value will
// be placed into the response by an interceptor.
export const errorMessages: { [key: string]: string } = {
  required: 'This field is required.',
  areaNameMustBeUnique: 'An area with this name already exists.',
  areaCannotBeDeletedIfItHasActiveScaffolds: 'The area cannot be deleted as it contains active scaffolds.',
  AreaCannotBeDeletedIfThereAreNoOtherActiveAreas: 'The site must have at least one active area',
  contractReferenceMustBeUnique: 'There is already a contract with this contract reference. Contract references must be unique.',
  contractContactNotFound: 'The contact could not be found.',
  contractNotFound: 'There was a problem finding the contract.',
  contractIdMustBeUnique: 'The contract id already exists.',
  contractStatusIdNotFound: 'There was a problem finding the contract status.',
  customerIdNotFound: 'There was a problem finding the customer',
  customerIdMustBeUnique: 'The customer id already exists.',
  customerReferenceMustBeUnique: 'The customer reference already exists.',
  customerStatusIdNotFound: 'There was a problem finding the customer status',
  duplicateEmail: 'This email address already exists.',
  emailAddressMustBeUnique: 'The email address already exists.',
  emailInvalidShortMessage: 'Invalid email address.',
  emailInvalid: 'The email address is invalid. Please enter a valid email address.',
  mustCloseContractsToCloseSite: 'You must close all of this site\'s contracts in order to close the site.',
  cannotEditSiteReferenceIfContractsExist: 'You cannot edit the site reference of a site which already has contracts.',
  cannotEditContractReferenceIfScaffoldsExist: 'You cannot edit the contract reference of a contract which already has scaffolds.',
  cannotOpenContractForDeletedCustomer: 'You cannot re-open a contract with a deleted customer.',
  cannotOpenContractForClosedSite: 'You cannot re-open a contract on a closed site.',
  minimumContractPeriod: 'Invalid contract period',
  oneMainContractor: 'There is already a main contractor on this site.',
  pauseInspectionsReasonRequired: 'Please enter a reason for why inspections have been paused.',
  pauseInspectionsResumeDateRequired: 'Please choose a date for inspections to be resumed.',
  scaffoldIdNotFound: 'There was a problem finding the scaffold.',
  scaffoldIdMustBeUnique: 'The scaffold id already exists.',
  scaffoldNumberSuffix: 'A scaffold reference suffix must be maximum of 3 numbers or uppercase letters.',
  scaffoldNumberIncremental: 'Please choose a scaffold reference to group this one with.',
  scaffoldReferenceMustBeUnique: 'The scaffold reference already exists.',
  scaffoldStatusIdNotFound: 'The scaffold status id could not be found.',
  siteAreaIdNotFound: 'The site area id could not be found.',
  siteIdMustNotExist: 'The site id already exists.',
  siteReferenceMustBeUnique: 'The site reference already exists.',
  siteStatusIdNotFound: 'The site status id could not be found.',
  staffIdsNotFound: 'The staff id(s) could not be found.',
  staffIdNotFound: 'The staff id could not be found.',
  passwordMinLength: 'The password must be a minimum of 6 characters.',
  passwordsMustMatch: 'Passwords don\'t match',
  minPercentageComplete: 'You cannot enter less than 0%',
  maxPercentageComplete: 'You cannot enter more than 100%',
  fileName: 'File name contains invalid symbols.',
  quotedItemMustHaveQuoteNumber: 'A quoted item must have a quote number.',
  quoteMustBeOnSameContractAsItem: 'An item can only be assigned to a quote on the same contract.',
  quoteNumberMustBeUnique: 'A quote number must be unique on the Contract.',
  dateMustBeInTheFuture: 'Rate start date must be in the future.',
  cannotHaveMultipleFutureRatesOfSameType: 'There is already a rate of the same type scheduled to come into effect in future.',
  cannotAddMultipleRatesOfSameType: 'You cannot add multiple rates of the same type.',
  effectiveFromMustBeStartOfPeriod: 'Rate must come into effect at the start of a period.',
  ratesMustBeInEffect: 'Unable to remove one or more rates.',
  ratesMustExistOnStaff: 'Unable to remove one or more rates.',
  ratesToDeleteMustBeFutureRates: 'Unable to remove one or more rates.',
  timesheetMustExist: 'Timesheet does not exist.',
  timesheetPeriodMustExistOnTimesheet: 'Timesheet period does not exist on the given timesheet.',
  timesheetTaskAllocatedHours: 'There are not enough allocated hours remaining',
  timesheetNotAssignedToSitesOnDepot: 'You are not assigned to any site on this depot.',
  timesheetSelectDate: 'Select a date range to create the report.',
  timesheetSelectStaff: 'Select staff to create the report.',
  timesheetSelectContract: 'Select contracts to create the report.',
  timesheetSelectDepot: 'Select a depot to create the report.',
  fileSizeErrorMsg: 'Please enter a file with a valid size no larger than 60mb.',
  passwordValidation: 'The password needs to be at least 8 characters long and contain lowercase, uppercase and a number',
  itemNumberMustBeUnique: 'The item number already exists',
  depotRequired: 'Please select a depot to create the report.',
  invalidValue: 'Please enter a positive number up to two decimal places precision',
  maxLength24: 'Please enter a maximum of 24 characters',
  invalidQuickId: 'Please enter a maximum of 3 numbers or uppercase letters',
  max5Files: 'A maximum of 5 files can be added',
  wrongFormat: 'The file format is not accepted',
  scaffoldNumberRequired: 'Please select a scaffold number',
  suffixRequired: 'Please enter a suffix'
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaffoldEndpointService, ScaffoldStatisticsData, ScaffoldStatisticsQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class ScaffoldStatisticsQueryService extends QueryService<ScaffoldStatisticsData> {

  constructor(private scaffoldEndpointService: ScaffoldEndpointService) { super(); }

  scaffoldStatisticsQuery(useCached: boolean, query:  ScaffoldStatisticsQuery): Observable<ScaffoldStatisticsData> {
    return super.query(useCached, JSON.stringify(query));
  }

  scaffoldStatisticsDataChanges(query?:  ScaffoldStatisticsQuery): Observable<ScaffoldStatisticsData> {
    return super.cachedDataChanges(JSON.stringify(query));
  }

  protected getDataFromServer(queryJson: string): Observable<ScaffoldStatisticsData> {
    const query = JSON.parse(queryJson);
    return this.scaffoldEndpointService.ScaffoldStatisticsQuery(query);
  }
}

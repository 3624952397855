import { Moment } from 'moment';

export class User {
     roles: string[];
     claims: string[];
     username: string;
     deviceId: string;
     accessToken: string;
     lastLogin: string;
     displayName: string;
     userId: string;
     expiryTime: string;
}

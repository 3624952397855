<div *ngIf="allSites" id="page-content-wrapper" class="sites-list">
  <div [ngClass]="{'ss-container fill-container': allSites.length}">
    <h2 class="inline-block"><i class="fa fa-building"></i> Sites</h2>
    <div class="ss-toolbar pull-right">
      <hub-permission permissions="AddEditSites">
        <a class="btn button-default button-rounded" role="button" (click)="onImportSite()"><i
            class="fa fa-cloud-upload"></i><span>import</span></a>
        <a class="btn button-default button-rounded" role="button" (click)="onAddSite()"><i
            class="fa fa-plus"></i><span>add site</span></a>
      </hub-permission>
    </div>
    <form *ngIf="allSites.length" [formGroup]="filtersForm" class="row table-filters shadow-header">
      <div class="col-xs-6 col-md-5">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input class="form-control sites-search" type="text" placeholder="Search..." formControlName="search">
        </div>
      </div>
      <div class="col-xs-4 col-md-3 text-left">
        <div class="table-filters">
          <div class="round-checkbox round-checkbox-medium inline-block margin-right">
            <input type="checkbox" id="showClosedSites" formControlName="showClosedSites">
            <label for="showClosedSites"></label>
          </div><span class="round-checkbox-label">Show closed sites</span>
        </div>
      </div>
      <div class="col-xs-2 col-md-4 text-right">
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn" [ngClass]="{'button-primary':!mapView,'button-default':mapView}"
            (click)="mapView = false"><i class="fa fa-list"></i><span class="hide-when-stacked">List
              View</span></button>
          <button type="button" class="btn" [ngClass]="{'button-primary':mapView,'button-default':!mapView}"
            (click)="mapView = true"><i class="fa fa-map-marker"></i><span class="hide-when-stacked">Map
              View</span></button>
        </div>
      </div>
    </form>
    <div class="row margin-top">

      <div class="col-md-12" *ngIf="allSites.length">
        <ul class="card-nav">
          <li [ngClass]="{'active': !selectedDepot}" *ngIf="depots.length > 1"><a (click)="onSelectDepot()">All
              Depots</a></li>
          <li *ngFor="let depot of depots" [ngClass]="{'active': selectedDepot === depot}"><a
              (click)="onSelectDepot(depot)">{{depot.DepotName}}</a></li>
        </ul>
      </div>

      <!-- MAP OF SITES -->
      <div class="col-md-12" *ngIf="mapView">
        <div class="google-map-wrapper">
          <google-map width="100%" [zoom]="map.defaultZoom"
            [center]="{ lat: map.defaultLatitude, lng: map.defaultLongitude }" [options]="{ scrollwheel: false }">
            <map-marker *ngFor="let site of filteredSites; let i = index"
              [position]="{ lat: site.Latitude, lng: site.Longitude }" #marker="mapMarker"
              [options]="{ draggable: false, clickable: true }" (mapClick)="openInfoWindow(marker, i)">
              <map-info-window>
                <h3>
                  <a class="title" [routerLink]="['..', site.SiteReference]">{{site.SiteName}}
                    ({{site.SiteReference}})</a>
                </h3>
                <p>{{site.SiteAddress}}</p>
                <p *ngIf="site.customerList"><strong>Customers: </strong>{{site.customerList}}</p>
                <p><strong>Status: </strong>{{ site.SiteStatusId | siteStatus }}</p>
              </map-info-window>
            </map-marker>
          </google-map>
        </div>
      </div>
      <!-- TABLE OF SITES -->
      <div class="col-md-12" *ngIf="!mapView">
        <ngx-datatable *ngIf="allSites.length" class="site-table table-hover" #sitesTable [rows]="filteredSites"
          [columnMode]="'flex'" [footerHeight]="50" [headerHeight]="40" [rowHeight]="'auto'" [limit]="10"
          [sorts]="[{prop: 'SiteName', dir: 'asc'}]" [selectionType]="'single'" (select)="onSiteSelected($event)"
          [messages]="{emptyMessage: ''}">
          <ngx-datatable-column name="Site" prop="SiteName" [flexGrow]="9">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="sites-list-row-container site-column round-table-img">
                <span class="img" [ngClass]="{'bg-image-placeholder': !row.SiteImageUrl}"
                  [ngStyle]="{'background-image':'url(' + row.SiteImageUrl + ')'}"></span>
                <div class="inline-block">
                  <strong>{{row.SiteName}}({{row.SiteReference}})</strong>
                  <p>{{row.SiteAddress}}</p>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Customers" prop="Customers" [flexGrow]="5">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="sites-list-row-container">
                {{row.customerList}}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Depots" prop="depotName" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="sites-list-row-container">
                {{row.depotName}}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Status" prop="SiteStatusId" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="sites-list-row-container site-list-status-row">
                <hub-site-status [statusId]="row.SiteStatusId"></hub-site-status>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <div class="empty-state-placeholder-large" *ngIf="!allSites.length">
          <i class="fa fa-building fa-4x"></i>
          <h3>Welcome to the sites section</h3>
          <p>No sites to show here!
            <hub-permission permissions="AddEditSites">
              <span> Click the 'Add Site' button to add a site.</span>
            </hub-permission>
          </p>
          <hub-permission permissions="AddEditSites">
            <a class="btn button-primary" role="button" (click)="onAddSite()" title="Add a new site"><i
                class="fa fa-plus"></i>Add Site</a>
          </hub-permission>
        </div>
      </div>
    </div>
  </div>
</div>


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettingsData, ConfigurationEndpointService } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class ApplicationSettingsQueryService extends QueryService<ApplicationSettingsData> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'appSettings';

  constructor(private configurationEndpointService: ConfigurationEndpointService) { super(); }

  applicationSettingsQuery(useCached: boolean): Observable<ApplicationSettingsData> {
    return super.query(useCached, this.key);
  }

  applicationSettingsDataChanges(): Observable<ApplicationSettingsData> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<ApplicationSettingsData> {
    return this.configurationEndpointService.ApplicationSettings();
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { EstimatesService } from 'app/estimates/services/estimates-service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { SiteImportModalComponent } from 'app/sites/components/site-import/site-import-modal/site-import-modal.component';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { CustomersQueryService } from 'app/core/services/customers-query.service';
import { DepotsQueryService } from 'app/core/services/depots-query.service';
import { LoggedInStaffService } from 'app/core/services/logged-in-staff-service';
import { SitesQueryService } from 'app/core/services/sites-query.service';
import { DepotData, StaffDetailsWebAppData, ConfigurationData, CustomerWebAppData, SiteWebAppData } from 'app/core/hub-api';
import { EstimateItem, Pagination } from 'app/estimates/models/estimate';
import { EstimatesRequest, Filter } from 'app/estimates/models/estimate-filter-options';
import { Toast, ToasterService } from 'angular2-toaster';
import { SiteImportModalContext } from 'app/sites/models/site-import-modal-context';
import { siteStatusIds } from 'app/shared/values/site-status-ids';
import { roleIds } from 'app/shared/values/role-ids';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'hub-estimates-container',
  templateUrl: './estimates-container.component.html',
  styleUrls: ['./estimates-container.component.scss']
})
/**
 * Represents the Estimates Container Component.
 * This component is responsible for managing the estimates table and its functionalities.
 */
export class EstimatesContainerComponent implements OnInit, OnDestroy {
  /** Holds all the estimates data for the current page */
  private allEstimates;

  /** Reference to the DatatableComponent. */
  @ViewChild(DatatableComponent) estimatesTable: DatatableComponent;

  /** Holds the sites data. */
  sites: any;

  /** Holds the customers data. */
  customers: any;

  /** Holds the value of the search input. */
  searchInputValue: string;

  /** Holds the value of the filters dropdown. */
  filtersDropdownValue: any;

  /** Represents the pagination information for the estimates container component. */
  pagination: Pagination;

  /** Represents the rows of estimates data to be displayed in the table. */
  rows = new Array<EstimateItem>();

  /** Represents the filtered estimates data based on search and filters. */
  filteredData = new Array<EstimateItem>();

  /** Represents the columns that can be searched. */
  columnsWithSearch: string[] = [];

  /** Represents the selected item in the dropdown. */
  selectedSimpleItem: any;

  /** Represents the columns configuration for the estimates table. */
  columns = [
    { name: 'Category', prop: 'Category', sortable: true },
    { name: 'Quote No', prop: 'QuotationNumber', sortable: true },
    { name: 'Description', prop: 'Description', sortable: true },
    { name: 'Start Date', prop: 'ProjectStartDate', sortable: true },
    { name: 'Customer', prop: 'CustomerAddress', sortable: true },
    { name: 'Site', prop: 'SiteAddress', sortable: true },
    { name: 'Estimate Status', prop: 'Status', sortable: true },
    { name: 'Tendered Price', prop: 'TenderedPrice', sortable: true },
    { name: 'Site Status', prop: 'SiteStatusDescription', sortable: true },
  ];

  /** Dropdown options for filters in the estimates container component. */
  filtersDropdownOptions = [
    { name: 'Category', prop: 'Category' },
    { name: 'Quote No', prop: 'QuotationNumber' },
    { name: 'Description', prop: 'Description' },
    { name: 'Customer', prop: 'CustomerAddress' },
    { name: 'Site', prop: 'SiteAddress' },
    { name: 'Site Status', prop: 'SiteStatusDescription' },
    { name: 'Estimate Status', prop: 'EstimateStatusDescription' },
  ];

  /** Represents all the columns available for filtering. */
  allFilterColumns = [];

  /** Represents the default option for filtering all columns. */
  private allColumnsDefault = { name: 'All Columns', prop: 'all' };

  /** Represents all the possible statuses for estimates. */
  allStatuses = [];

  /** Array containing all depot data. */
  allDepots: DepotData[];

  /** Represents the logged-in staff details. */
  loggedInStaff: StaffDetailsWebAppData;

  /** Represents the configuration data. */
  configuration: ConfigurationData;

  /** Represents the depots data. */
  depots: DepotData[];

  /** Represents all the customers data. */
  allCustomers: CustomerWebAppData[];

  /** Represents all the sites data. */
  allSites: any[];

  /** Represents the loading state of the component. */
  isLoading = true;

  /** Stores the table current selected sort */
  sorts: any[] = [];

  /** Represents the site status ids. */
  siteStatusIds = siteStatusIds;

  /** An array of subscriptions that need to be cleaned up when the component is destroyed. */
  subscriptionsToCleanup: Subscription[] = [];

  contextProjectLockedModal: SSDialogContext = {
    title: `Project Locked`,
    body: `This estimate is in use on another system and is locked. Do you wish to continue, data may be lost.`,
    okBtnClass: 'btn button-assertive',
    okBtnText: 'Yes',
    cancelBtnText: 'No',
  };

  /**
   * Constructs a new instance of the EstimatesContainerComponent.
   * @param bsModalRef - The Bootstrap modal reference.
   * @param modalService - The Bootstrap modal service.
   * @param estimatesService - The service for fetching estimates data.
   */
  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private estimatesService: EstimatesService,
    private router: Router,
    private route: ActivatedRoute,
    private sitesQueryService: SitesQueryService,
    private customersQueryService: CustomersQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private depotsQueryService: DepotsQueryService,
    private loggedInStaffService: LoggedInStaffService,
    private toastService: ToasterService,
  ) {
    this.pagination = {
      CurrentPage: 0,
      HasNext: false,
      HasPrevious: false,
      PageSize: 10,
      TotalPages: 0,
      TotalRecords: 0,
    };
  }

  /**
   * Lifecycle hook that is called after data-bound properties of the component are initialized.
   */
  public ngOnInit(): void {
    // makes the All Columns option the first option in the dropdown
    this.filtersDropdownOptions.unshift(this.allColumnsDefault);
    // defaults the dropdown to All Columns option
    this.filtersDropdownValue = this.allColumnsDefault.prop;
    const obs$ = combineLatest(
      [this.sitesQueryService.sitesDataChanges(),
      this.customersQueryService.customersDataChanges(),
      this.configurationQueryService.configurationDataChanges(),
      this.depotsQueryService.depotsDataChanges(),
      this.loggedInStaffService.loggedInStaffChanges()]
    );
    this.subscriptionsToCleanup.push(obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2], latest[3], latest[4])));
    this.setPage({ offset: this.pagination.CurrentPage });
  }

  /**
   * Refreshes the component with new data.
   * 
   * @param sites - An array of SiteWebAppData objects representing the sites.
   * @param customers - An array of CustomerWebAppData objects representing the customers.
   * @param configuration - An object representing the configuration data.
   * @param depots - An array of DepotData objects representing the depots.
   * @param loggedInStaff - An object representing the logged-in staff details.
   */
  private refreshComponent(
    sites: SiteWebAppData[],
    customers: CustomerWebAppData[],
    configuration: ConfigurationData,
    depots: DepotData[],
    loggedInStaff: StaffDetailsWebAppData
  ): void {
    this.allDepots = depots;
    // When logout the logged in staff gets cleared
    if (loggedInStaff == null) {
      return;
    }
    this.loggedInStaff = loggedInStaff;

    /// removes the TenderedPrice column if the logged in staff is a site supervisor
    if (this.loggedInStaff.RoleId === roleIds.siteSupervisor) {
      this.columns = this.columns.filter(column => column.prop !== 'TenderedPrice');
    }

    this.configuration = configuration;
    this.depots = depots
      .filter(d => this.loggedInStaff.SiteDepotIds.includes(d.SiteDepotId))
      .sort((a, b) => (a.DepotName.toLowerCase() > b.DepotName.toLowerCase() ? 1 : -1));
    this.allCustomers = customers;
    this.allSites = sites.map(site => {
      const tableSite = cloneDeep(site) as any;
      tableSite.depotName = this.depots.filter(d => site.SiteDepotId.includes(d.SiteDepotId)).map(d => d.DepotName);
      tableSite.customerList = this.allCustomers
        .filter(customer => site.Customers.includes(customer.CustomerId))
        .map(customer => customer.CustomerName)
        .sort()
        .join(', ');
      return tableSite;
    });

  }

  /**
   * Retrieves the selected filters based on the search input value and the filters dropdown value.
   * If the search input value is not empty, an empty array is returned.
   * If the filters dropdown value is set to the default value, filters are generated for all columns.
   * Otherwise, filters are generated for the selected column.
   * @returns An array of Filter objects representing the selected filters.
   */
  public getSelectedFilters(): Filter[] {
    var selectedFilters = [];
    if (this.searchInputValue === '' || this.searchInputValue == null) return selectedFilters;
    if (this.filtersDropdownValue === this.allColumnsDefault.prop) {
      // Filter based on all columns
      this.columns.forEach(column => {
        selectedFilters.push({
          Field: column.prop,
          Operator: 'Contains',
          Value: this.searchInputValue,
        });
      });
    } else {
      // Filter based on selected column
      selectedFilters.push({
        Field: this.filtersDropdownValue,
        Operator: 'Contains',
        Value: this.searchInputValue,
      });
    }
    return selectedFilters;
  }

  /**
   * Sets the page for pagination and retrieves estimates data.
   * 
   * @param pageInfo - The information about the selected page.
   * @param customSorting - An optional array of custom sorting fields and directions.
   */
  public setPage(pageInfo, customSorting: { Field: string; Direction: string }[] = []) {
    try {
      var toast;
      var defaultSorting = [];
      var matchingColumn;
      if (customSorting.length > 0) {
        matchingColumn = this.columns.find(column => column.prop === customSorting[0].Field);
        var toaster: Toast = {
          type: 'wait',
          title: 'Sorting',
          body: `Sorting ${matchingColumn.name} column...`,
          tapToDismiss: false,
          progressBarDirection: 'increasing',
        };
        if (matchingColumn) {
          toaster.body = `Sorting ${matchingColumn.name} column...`;
        }
        toast = this.toastService.pop(toaster);
      } else {
        this.isLoading = true;
      }
      this.pagination.CurrentPage = pageInfo.selectedPage ? pageInfo.selectedPage : pageInfo.offset;
      var selectedFilters = this.getSelectedFilters();
      var payload = new EstimatesRequest(
        { PageNumber: this.pagination.CurrentPage, PageSize: this.pagination.PageSize },
        customSorting.length > 0 ? customSorting : defaultSorting,
        selectedFilters
      ).toJson();
      this.subscriptionsToCleanup.push(this.estimatesService.requestEstimates(payload).subscribe(
        pagedData => {
          this.pagination = pagedData.Pagination;
          this.pagination.CurrentPage = this.pagination.CurrentPage;
          this.rows = pagedData.Items;
          this.filteredData = this.rows;
          this.allEstimates = cloneDeep(this.rows);
          if (this.rows && this.rows.length) this.columnsWithSearch = Object.keys(this.rows[0]);
          this.isLoading = false;
          if (customSorting.length > 0) {
            this.toastService.clear(toast.toastId);
            this.toastService.pop('success', 'Sorting', `Sorting ${matchingColumn.name} column completed`);
          }
        },
        error => {
          this.isLoading = false;
          this.toastService.pop('error', 'Error', 'An error occurred while fetching estimates data.');
          console.error(error);
        }
      ));
      this.subscriptionsToCleanup.push(this.estimatesService.getEstimateStatus().subscribe({
        next: (statuses) => {
          this.allStatuses = statuses;
        },
        error: (error) => {
          console.error(error);
        }
      }));
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  /**
   * Event handler for exporting the table data to CSV.
   */
  public onExportToCSV(): void {
    var toast = this.toastService.pop('wait', 'Exporting', 'Exporting data to CSV, please wait...');
    toast.timeout = 0;
    this.subscriptionsToCleanup.push(this.estimatesService.exportCsv().subscribe({
      next: () => {
        this.toastService.clear(toast.toastId);
      },
      error: error => {
        console.error(error);
        this.toastService.clear(toast.toastId);
        this.toastService.pop('error', 'Error', 'An error occurred while exporting the data to CSV.');
      }
    }));
  }

  /**
   * Updates the status of a new estimate.
   * 
   * @param {string} ProjectId - The ID of the project.
   * @param {string} siteReference - The reference of the site.
   */
  private updateNewEstimateStatus(ProjectId: string, siteReference: string) {
    var liveStatusId = this.allStatuses.find(status => status.Description === 'Live')?.Code;

    this.estimatesService.updateStatus(ProjectId, liveStatusId).subscribe({
      next: async () => {
        this.toastService.pop('wait', 'Success', 'The estimate has been successfully imported. \n Redirecting to the site...');
        // makes the user understand visually that the site has been imported and now is being redirected to the site
        await new Promise(resolve => setTimeout(resolve, 3000));
        this.router.navigate([`../sites/${siteReference}`], { relativeTo: this.route });
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        this.toastService.pop('error', 'Error', error.error.Message ? error.error.Message : 'An error occurred while updating the estimate status');
      }
    });
  }

  /**
   * Imports an estimate for a project.
   * 
   * @param ProjectId - The ID of the project to import the estimate for.
   * @returns A Promise that resolves when the import is complete.
   */
  private async importSite(ProjectId: string): Promise<void> {
    var toast: Toast = {
      type: 'wait',
      title: 'Importing',
      body: 'Importing estimate...',
      tapToDismiss: false,
      progressBarDirection: 'increasing',
      timeout: 0,
    };
    this.toastService.pop(toast);
    this.subscriptionsToCleanup.push(this.estimatesService.exportEstimate(ProjectId).subscribe({
      next: (estimate) => {
        this.isLoading = true;
        const context: SiteImportModalContext = {
          sites: this.allSites,
          customers: this.allCustomers,
          configuration: this.configuration,
          depots: this.depots,
          loggedInStaff: this.loggedInStaff,
          selectedEstimate: estimate,
          projectId: ProjectId,
        };
        var configs = SSModalConfig.generate(context);
        configs.class = 'modal-dialog modal-full-screen';
        const modal = this.modalService.show(SiteImportModalComponent, configs);
        this.toastService.clear(toast.toastId);
        modal.onHide.subscribe(
          siteId => {
            var siteReference = null;
            if (siteId === null || typeof siteId !== 'string') return;
            this.sitesQueryService.sitesQuery(false).subscribe(sites => {
              var site = sites.find(site => site.SiteId === siteId);
              siteReference = site ? site.SiteReference : null;
              this.estimatesService.updateSite(ProjectId, siteId).subscribe(() => {
                this.isLoading = false;
                var toast: Toast = {
                  type: 'wait',
                  title: 'Importing',
                  body: 'Checking if the estimate is locked...',
                  tapToDismiss: false,
                  progressBarDirection: 'increasing',
                  timeout: 0,
                };
                this.toastService.pop(toast);
                this.estimatesService.isEstimateLocked(ProjectId).subscribe({
                  next: (isLocked) => {
                    this.toastService.clear(toast.toastId);
                    if (isLocked) {
                      const context: SSDialogContext = {
                        title: `Project Locked`,
                        body: `This estimate is in use on another system and is locked. Do you wish to continue, data may be lost.`,
                        okBtnClass: 'btn button-assertive',
                        okBtnText: 'Yes',
                        cancelBtnText: 'No',
                      };
                      const config: ModalOptions = {
                        class: 'modal-danger',
                      };
                      const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
                      modal.content.onClose.subscribe({
                        next: result => {
                          if (result === true) {
                            toast.body = 'Updating estimate status...';
                            this.updateNewEstimateStatus(ProjectId, siteReference);
                          }
                        },
                        error: error => {
                          console.error(error);
                        }
                      });
                      return;
                    }
                    else {
                      toast.body = 'Updating estimate status...';
                      this.updateNewEstimateStatus(ProjectId, siteReference);
                    }
                  },
                  error: (error) => {
                    console.error(error);
                    this.toastService.pop('error', 'Error', 'An error occurred while checking if the estimate is locked.');
                  }
                });
              });
            })
          },
          error => {
            console.error(error);
            this.isLoading = false;
            this.toastService.clear(toast.toastId);
            this.toastService.pop('error', 'Error', 'An error occurred while importing the estimate.');
          }
        );
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        this.toastService.clear(toast.toastId);

        if (error.status === 400) {
          try {
            const errorObj = JSON.parse(error.error);
            const innerErrorObj = JSON.parse(errorObj.Message);

            var errorToast: Toast = {
              type: 'error',
              title: 'Error',
              body: innerErrorObj.ErrorCode === 600403 ? innerErrorObj.Message : 'An error occurred while importing the estimate.',
              tapToDismiss: true,
              timeout: 10000
            };
            this.toastService.pop(errorToast);
          } catch (parseError) {
            console.error('Failed to parse error response:', parseError);
            this.toastService.pop('error', 'Error', 'An error occurred while importing the estimate: Failed to parse the error message.');
          }
        }
        else {
          this.toastService.pop('error', 'Error', 'An unexpected error occurred importing the estimate.');
        }
      },
      complete: () => {
        this.isLoading = false;
      }
    }));

  }

  /**
   * Handles the start event when an estimate item is clicked.
   * @param $event - The estimate item that was clicked.
   */
  public onStart($event: EstimateItem): void {
    var toast: Toast = {
      type: 'wait',
      title: 'Importing',
      body: 'Checking if the estimate is locked...',
      tapToDismiss: false,
      progressBarDirection: 'increasing',
      timeout: 0,
    };
    this.toastService.pop(toast);
    this.estimatesService.isEstimateLocked($event.ProjectId).subscribe({
      next: (isLocked) => {
        this.toastService.clear(toast.toastId);
        if (isLocked) {
          const context: SSDialogContext = this.contextProjectLockedModal;
          const config: ModalOptions = {
            class: 'modal-danger',
          };
          const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
          modal.content.onClose.subscribe({
            next: result => {
              if (result === true) {
                toast.body = 'Importing estimate...';
                this.importSite($event.ProjectId);
              }
            },
            error: error => {
              console.error(error);
            }
          });
          return;
        }
        else {
          toast.body = 'Importing estimate...';
          this.importSite($event.ProjectId);
        }
      },
      error: (error) => {
        console.error(error);
        this.toastService.pop('error', 'Error', 'An error occurred while checking if the estimate is locked.');
      }
    });
  }

  /**
   * Event handler for when the In Progress button is clicked.
   * @param $event - The event object.
   */
  public onInProgress(estimate: EstimateItem): void {
    this.openSite(estimate);
  }

  /**
   * Event handler for when the Completed button is clicked.
   * @param $event - The event object.
   */
  public onCompleted(estimate: EstimateItem): void {
    this.openSite(estimate);
  }

  /**
   * Opens the site details page for the given estimate.
   * @param estimate - The estimate item to open the site details for.
   */
  private openSite(estimate: EstimateItem) {
    if (estimate != null) {
      if (estimate.PostContractSiteId == null) {
        this.toastService.pop('error', 'Error', 'The estimate is missing a site reference.');
        return;
      }
      var siteReference = this.allSites.find(site => site.SiteId === estimate.PostContractSiteId)?.SiteReference;
      this.router.navigate([`/sites/${siteReference}/details`], { relativeTo: this.route });
    }
  }

  /**
   * Handles when the user changes the page of the table.
   * 
   * @param event - The page change event object.
   */
  public pageChanged(event: { page: number }): void {
    const customSorting = this.sorts.length > 0 ? [{ Field: this.sorts[0].prop, Direction: this.sorts[0].dir }] : [];
    this.setPage({ offset: event.page }, customSorting);
  }

  /**
    * Applies the filter based on the selected dropdown value and search input value.
    */
  public applyFilter(): void {
    this.setPage({ offset: this.pagination.CurrentPage });
  }

  /**
  * Clears the filter and resets the table to display all data.
  */
  public clearFilter(): void {
    this.searchInputValue = '';
    this.filtersDropdownValue = this.allColumnsDefault.prop;
    this.filteredData = this.allEstimates;
    // Update the table with all data
    this.setPage({ offset: 0 });
  }

  /**
   * Handles the sorting event triggered by the user.
   * @param sort - The sorting event object.
   */
  public onSort(sort) {
    var customSorting = [{ Field: sort[0].prop, Direction: sort[0].dir }];
    this.sorts = sort;
    this.setPage({ offset: this.pagination.CurrentPage }, customSorting);
  }

  /**
   * Checks if the estimate is locked and then proceeds to update depending on the user's request
   * 
   * @param status - The status object containing the project ID, status ID, and status description.
   */
  public statusChanged(status: {
    ProjectId: string,
    StatusId: string,
    StatusDescription: string
  }): void {
    var toaster = this.toastService.pop('wait', 'Updating', `Updating estimate status to ${status.StatusDescription}`);
    toaster.timeout = 0;

    this.estimatesService.isEstimateLocked(status.ProjectId).subscribe({
      next: (isLocked) => {
        if (isLocked) {
          const context: SSDialogContext = this.contextProjectLockedModal;
          const config: ModalOptions = {
            class: 'modal-danger',
          };
          const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
          modal.content.onClose.subscribe({
            next: result => {
              if (result === true) {
                this.changeStatus(status, toaster)
              }
              else {
                this.toastService.clear(toaster.toastId);
                // Find the project in the `this.rows` array and revert its status to match the one in `this.allEstimates`.
                const projectIndex = this.rows.findIndex(row => row.ProjectId === status.ProjectId);
                if (projectIndex > -1) {
                  const originalStatus = this.allEstimates.find(estimate => estimate.ProjectId === status.ProjectId).Status;
                  this.rows[projectIndex].Status = originalStatus;
                  this.filteredData = this.rows;
                  this.toastService.pop('info', 'Info', 'Estimate status update cancelled');
                }
              }
            },
            error: error => {
              console.error(error);
            }
          });
          return;
        }
        else {
          this.changeStatus(status, toaster)
        }
      },
      error: (error) => {
        console.error(error);
        this.toastService.pop('error', 'Error', 'An error occurred while checking if the estimate is locked.');
      }
    });
  }

  /**
   * Updates the status of an estimate.
   * 
   * @param status - The status object containing the project ID, status ID, and status description.
   * @param toaster - The toaster object used for displaying toast messages.
   */
  private changeStatus(status: {
    ProjectId: string,
    StatusId: string,
    StatusDescription: string
  }, toaster) {
    this.subscriptionsToCleanup.push(this.estimatesService.updateStatus(status.ProjectId, status.StatusId).subscribe({
      next: () => {
        this.toastService.clear(toaster.toastId);
        this.toastService.pop('success', 'Success', `Estimate status updated to ${status.StatusDescription}`);
        var customSorting = [{ Field: this.sorts[0].prop, Direction: this.sorts[0].dir }];
        this.setPage({ offset: this.pagination.CurrentPage }, customSorting);
      },
      error: (error) => {
        console.error(error);
        toaster.body = error.error.Message ? error.error.Message : 'An error occurred while updating the estimate status';
        toaster.type = 'error';
        toaster.timeout = 3000;
      }
    }));
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.subscriptionsToCleanup.forEach(sub => sub.unsubscribe());
  }
}

import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurationQueryService } from '../../../core/services/configuration-query.service';
import { ActivatedRoute } from '@angular/router';
import { ArrayHelperService } from '../../../core/services/array-helper.service';
import { mobileLists } from '../../../admin/values/mobile-lists';
import { flow, cloneDeep, map, partialRight, sortBy, each, max, filter } from 'lodash';
import { ListPreviewModalContext } from '../../../admin/models/list-preview-modal-context';
import { ListPreviewModalComponent } from '../../../admin/components/list-preview-modal/list-preview-modal.component';
import { AddEditListItemModalComponent } from '../add-edit-list-item-modal/add-edit-list-item-modal.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { SSModalConfig, SSDialogContext } from 'app/shared/models/ss-modal-config';
import { LookupDataBase, ConfigurationCommandService, ConfigurationData } from 'app/core/hub-api';
@AutoUnsubscribe()
@Component({
  selector: 'hub-editable-list-table',
  templateUrl: './editable-list-table.component.html',
  styleUrls: ['./editable-list-table.component.scss']
})
export class EditableListTableComponent implements OnInit, OnDestroy, OnChanges {
  /** The title of the editable list table component. */
  title: string;
  /** An array of LookupDataBase objects representing the items to be displayed in the editable list table. */
  items: LookupDataBase[] = [];

  /** The key used to identify the list in the component. */
  @Input()
  listKey: string;

  /** A boolean value indicating whether the mobile preview is currently being shown or not. */
  @Input()
  showMobilePreview = false;

  /** The title of the list displayed in the editable list table component. */
  @Input()
  listTitle?: string;

  constructor(
    private configurationQueryService: ConfigurationQueryService,
    private arrayHelperService: ArrayHelperService,
    private configurationCommandService: ConfigurationCommandService,
    public modalService: BsModalService,
    private route: ActivatedRoute
  ) { }

  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   * This method is called only once when the directive is instantiated.
   * It is an ideal place to perform any initialization logic that depends on the input properties.
   */
  ngOnInit(): void {
    this.getSubscription();
  }

  /**
   * Responds to changes in input properties.
   * @param changes - An object containing the changed properties and their previous and current values.
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listKey']) {
      this.getSubscription();
    }
  }

  /**
   * Subscribes to configuration data changes and refreshes the component with the latest data.
   * @returns void
   */
  getSubscription(): void {
    this.configurationQueryService.configurationDataChanges().subscribe(latest => this.refreshComponent(latest));
  }

  /**
   * Refreshes the component with the given configuration data.
   * @param configuration The configuration data to use for refreshing the component.
   * @returns void
   */
  refreshComponent(configuration: ConfigurationData): void {
    const items = mobileLists.find(l => l.key === this.listKey);
    this.title = this.listTitle ? this.listTitle : items ? items.title : '';
    this.items = flow([cloneDeep, map, partialRight(sortBy, i => i.SortOrder)])(
      filter(configuration[`${this.listKey}s`], function (item): boolean {
        return !item.Deleted;
      })
    );
  }

  /**
   * Moves an item from the old index to the new index in the list of items.
   * @param oldIndex The index of the item to be moved.
   * @param newIndex The index where the item should be moved to.
   */
  onMoveItem(oldIndex: number, newIndex: number): void {
    if (this.items[oldIndex] && this.items[newIndex]) {
      this.items = this.arrayHelperService.move(this.items, oldIndex, newIndex);
      this.saveSortOrder();
    }
  }

  /**
   * Saves the current sort order of the items in the list by updating their `SortOrder` property
   * and calling the `updateItems` function to persist the changes.
   */
  saveSortOrder(): void {
    each(this.items, (t, i) => (t.SortOrder = i));
    this.updateItems(this.items);
  }

  /**
   * Updates the items in the editable list table.
   * @param items The new list of items to update.
   * @returns void
   */
  updateItems(items: LookupDataBase[]): void {
    const command = {
      [`${this.listKey}s`]: items
    };
    this.configurationCommandService[`Edit${this.listKey}sCommand`](command).subscribe(() =>
      this.configurationQueryService.configurationQuery(false)
    );
  }

  /**
   * Handles the logic for adding a new item to the list.
   * Sets up the context for the AddEditListItemModalComponent and opens the modal.
   */
  onAddItem(): void {
    const context = {
      listKey: this.listKey,
      listTitle: this.title,
      itemSortOrder: flow([partialRight(map, t => t.SortOrder), max])(this.items) + 1 || 0,
      showSubtitle: true
    };
    const modal = this.modalService.show(AddEditListItemModalComponent, SSModalConfig.generate(context));
  }

  /**
   * Sets the item at the specified index as the default item and updates the list.
   * @param itemIndex The index of the item to set as default.
   */
  onSetAsDefault(itemIndex: number): any {
    each(this.items, function (item, i): any {
      item['IsDefault'] = i === itemIndex;
    });
    this.onMoveItem(itemIndex, 0);
    this.updateItems(this.items);
  }

  /**
   * Deletes an item from the list of items.
   * If the item is a default item, it will not allow to be deleted and will show a modal dialog.
   * If the item is not a default item, it will show a confirmation modal dialog before deleting the item.
   * @param itemIndex The index of the item to be deleted.
   * @returns void
   */
  onDeleteItem(itemIndex: number): any {
    const item = this.items[itemIndex];
    let context: SSDialogContext, config: ModalOptions;
    // If it is a default item it will not allow to be deleted
    if (item.IsDefault) {
      context = {
        title: `Unable to Delete ${this.title}`,
        body: `"${item.Title}" is a default item and cannot be deleted.`,
        cancelBtnClass: 'btn button-assertive',
        cancelBtnText: 'Close',
        showOnlyCloseBtn: true,
      };
      config = {
        class: 'modal-danger',
      };
      const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
      return;
    }
    context = {
      title: `Delete ${this.title}?`,
      body: `Are you sure you want to delete "${item.Title}"?`,
      okBtnClass: 'btn button-assertive',
      okBtnText: 'Yes delete it',
      cancelBtnClass: 'btn button-default',
      cancelBtnText: 'No keep it',
      headerClass: 'confirm-modal-header',
      footerClass: 'confirm-modal-footer'
    };
    config = {
      class: 'modal-danger',
    };
    const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
    modal.content.onClose.subscribe({
      next: (result) => {
        if (result === true) {
          this.items.splice(itemIndex, 1);
          this.deleteItems([item]);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  /**
   * Deletes the specified items from the list.
   * @param items The items to delete.
   */
  deleteItems(items: LookupDataBase[]): void {
    const command = {
      [`${this.listKey}s`]: items.map(t => t[`${this.listKey}Id`])
    };
    this.configurationCommandService[`Delete${this.listKey}sCommand`](command).subscribe(() =>
      this.configurationQueryService.configurationQuery(false)
    );
  }

  /**
   * Opens a modal to preview the list items.
   * @returns void
   */
  onViewPreview(): void {
    const context: ListPreviewModalContext = {
      listTitle: this.title,
      mobileListItems: this.items
    };
    const modal = this.modalService.show(ListPreviewModalComponent, SSModalConfig.generate(context));
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Use this hook to perform any necessary cleanup, such as unsubscribing from observables.
   */
  ngOnDestroy(): void { }
}

<div class="ss-import">
  <div class="modal-header">
    <h3 class="modal-title"> Attach customer approval file </h3>
    <a class="pull-right" (click)="bsModalRef.hide()">
      <i class="fa fa-close fa-2x icon"></i>
    </a>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>
        <span>* Select a Customer: </span>
      </label>
      <select class="form-control" (change)="onCustomerSelected($event)">
        <option value="">Please select a Customer</option>
        <option *ngFor="let customer of contactNames" [value]="customer.id">{{ customer.value }}</option>
      </select>
      <span *ngIf="!contractContactId && !firstload" class="text-error">
        <i class="fa fa-warning"></i> {{errors.customerRequired}}</span>
    </div>

    <div class="file-uploader">
      <span class="file-input btn button-primary btn-file">
        <i class="fa fa-search"></i> Browse&hellip;
        <input class="btn button-primary btn-file" type="file" (change)="onFileSelected($event)">
      </span>
      <span class="import-text" *ngIf="!file"> No file selected</span>
      <span class="import-text" *ngIf="file"> {{file.name}}</span>
    </div>
    <span *ngIf="errorMessage" class="text-error">
      <i class="fa fa-warning"></i> {{errorMessage}}</span>
    <div class="modal-footer">
      <span class="required-note">* Denotes a required field</span>
      <div class="modal-buttons">
        <button class="btn btn-large button-primary pull-right" (click)="onSubmit()" [disabled]="saveInProgress">
          <i [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"
            class="fa"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>
// Maps list titles for the UI to the property of the configuration containing the list data.
export const mobileLists: any = [
  {
    title: 'Contract Type',
    key: 'ContractType'
  },
  {
    title: 'Defect Type',
    key: 'DefectType'
  },
  {
    title: 'Handover Risk Type',
    key: 'HandoverRiskType'
  },
  {
    title: 'Handover Risk Reason',
    key: 'HandoverRiskReason'
  },
  {
    title: 'Design Type',
    key: 'DesignType'
  },
  {
    title: 'Loading Limit',
    key: 'LoadingLimit'
  },
  {
    title: 'Method Of Cladding',
    key: 'MethodOfCladding'
  },
  {
    title: 'Method Of Tying',
    key: 'MethodOfTying'
  },
  {
    title: 'Scaffold Type',
    key: 'ScaffoldType'
  },
  {
    title : 'Scaffolding System',
    key : 'ScaffoldingSystem'

  }
];

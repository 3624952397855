<div *ngIf="scaffold">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['..']">
        <i class="fa fa-chevron-left text-positive"></i>back to scaffold list</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h2 class="inline-block">Scaffold {{scaffold.ScaffoldNumber}}</h2>
      <span *ngIf="currentExtraHire">
        <span class="extra-hire-icon" *ssFeatureFlag="'handover'">
          <i class="fa fa-regular fa-clock"></i>
        </span>
      </span>
      <div class="ss-toolbar inline-block pull-right">
        <hub-permission permissions="AddEditSites">
          <a class="btn button-default button-rounded" (click)="onEditScaffold()" tabindex="0">
            <i class="fa fa-pencil icon"></i>
            <span>edit scaffold</span>
          </a>
        </hub-permission>
        <span *ngIf="scaffold.ScaffoldStatusId === scaffoldStatusIds.onHire">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded button-rounded-delete" (click)="offHireScaffold()" tabindex="0">
              <i class="fa fa-regular fa-handshake icon"></i>
              <span>off hire</span>
            </a>
          </hub-permission>
        </span>
        <span *ngIf="scaffold.ScaffoldStatusId !== scaffoldStatusIds.dismantled">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded button-rounded-delete" (click)="onDismantleScaffold()"
              tabindex="0">
              <i class="fa fa-wrench icon"></i>
              <span>dismantle</span>
            </a>
          </hub-permission>
        </span>
        <span *ngIf="scaffold.ScaffoldStatusId === scaffoldStatusIds.dismantled">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded" (click)="onUndismantleScaffold()" tabindex="0">
              <i class="fa fa-wrench icon"></i>
              <span>undismantle</span>
            </a>
          </hub-permission>
        </span>

      </div>
    </div>
  </div>

  <hub-dismantle-card *ngIf="scaffold.ScaffoldStatusId === scaffoldStatusIds.dismantled"
    [dismantleData]="scaffold.Dismantle"></hub-dismantle-card>

  <div class="row">
    <div class="col-sm-5 col-md-4 col-lg-3">
      <div class="ss-container scaff-details-container">
        <div class="scaff-image">
          <hub-image-carousel [media]="[scaffold.ScaffoldImageUrl]" height="200px"></hub-image-carousel>
        </div>
        <h4 class="text-center text-positive margin-top-sm">
          <strong>{{scaffold.ScaffoldNumber}}</strong>
        </h4>
        <p class="text-center" *ngIf="scaffold.ScaffoldSpecification && scaffold.ScaffoldSpecification.Description">
          {{scaffold.ScaffoldSpecification.Description}}</p>
        <p class="text-center text-italic"
          *ngIf="!scaffold.ScaffoldSpecification || !scaffold.ScaffoldSpecification.Description">No description added!
        </p>

        <div class="scaffold-stats" *ngIf="scaffold.ScaffoldStatusId !== scaffoldStatusIds.dismantled">
          <!-- TODO: Improve UI for when the user does not have both handover and inspection module -->
          <div title="Scaffold status" *ssFeatureFlag="'handover'">
            <span
              [ngClass]="{'text-positive': scaffold.ScaffoldStatusId === scaffoldStatusIds.onHire, 'text-assertive': scaffold.ScaffoldStatusId === scaffoldStatusIds.offHire}">
              <i class="fa"
                c="{'fa-check-circle': scaffold.ScaffoldStatusId === scaffoldStatusIds.onHire, 'fa-times-circle': scaffold.ScaffoldStatusId === scaffoldStatusIds.offHire}"></i>
              <strong>{{status}}</strong>
            </span>
          </div>
          <div title="Current inspection state" *ssFeatureFlag="'inspection'">
            <hub-scaffold-inspection-state *ngIf="contract.InspectionStatus" [status]="contract.InspectionStatus"
              [inspectionsCompleted]="inspectionsCompleted" [overdue]="overdue">
            </hub-scaffold-inspection-state>
          </div>
        </div>
        <div>
          <div *ssFeatureFlag="'handover'">
            <strong>On Hire Date</strong>
            <p *ngIf="scaffold.OnHireDate">{{ scaffold.OnHireDate | datetime: 'll'}}</p>
            <p *ngIf="!scaffold.OnHireDate">Unknown</p>
          </div>
          <span *ngIf="scaffold.OffHireDate">
            <div *ssFeatureFlag="'handover'">
              <strong>Date Off Hired</strong>
              <p>{{ scaffold.OffHireDate | datetime: 'll' }}</p>
            </div>
          </span>
          <div>
            <strong>Customer</strong>
            <p>{{ customerName }}</p>
          </div>
          <span *ngIf="nextInspection">
            <div class="margin-top-sm" *ssFeatureFlag="'inspection'">
              <strong>Next Inspection</strong>
              <p>{{ nextInspection | datetime: 'll' }}</p>
            </div>
          </span>
          <div class="margin-top-sm">
            <strong>Area</strong>
            <p>{{ areaName }}</p>
          </div>
          <div class="margin-top-sm" *ngIf="scaffold.SpecificLocationDetails">
            <strong>Scaffold Name</strong>
            <p>{{ scaffold.SpecificLocationDetails }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-7 col-md-8 col-lg-9">
      <div class="ss-container scaff-details-container">
        <div class="max-width-800">
          <div class="scaffold-details-handover border-shadow-bottom" *ssFeatureFlag="'handover'">
            <div class="info-block-two">
              <p>
                <span>{{hirePeriod}}</span>wks
              </p>
              <p class="scaffold-details-handover-hire">Hire Period</p>
            </div>
            <div class="info-block-two">
              <p>
                <span>{{weeksOnHire}}</span>wks
              </p>
              <p class="scaffold-details-handover-hire">Weeks On Hire</p>
            </div>
            <div class="info-block-two">
              <p>
                <span>{{currentExtraHire}}</span>wks
              </p>
              <p class="scaffold-details-handover-extra">Current Extra Hire</p>
            </div>
            <div class="text-right" *ngIf="scaffold.EndOfContractDate">End of contract:
              <strong>{{scaffold.EndOfContractDate | datetime: 'll'}}</strong>
            </div>
          </div>
          <div class="scaffold-details-inspection" *ssFeatureFlag="'inspection'">
            <div class="info-block-three">
              <p>
                <span>{{inspectionsCompleted}}</span>
              </p>
              <p class="truncate">Inspection Summary</p>
            </div>
            <div class="info-block-three">
              <p>
                <span>{{inspectionsPassed}}</span>
              </p>
              <p class="truncate">Inspections Passed</p>
            </div>
            <div class="info-block-three">
              <p>
                <span>{{inspectionsCompleted - inspectionsPassed}}</span>
              </p>
              <p class="truncate">Inspections Failed</p>
            </div>
          </div>
          <table class="table table-borderless table-fixed table-blockquote spec-table">
            <tbody *ngFor="let prop of specificationProperties">
              <tr>
                <td class="truncate" title="{{prop.label}}">
                  <strong>{{prop.label}}</strong>
                </td>
                <td class="truncate">{{prop.value}}
                  <span *ngIf="prop.furtherInfo">({{prop.furtherInfo}})</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <hub-last-inspection *ngIf="scaffold.LastInspection" [scaffold]="scaffold"
    [config]="configuration"></hub-last-inspection>

  <div class="row" *ngIf="timelineEvents.length">
    <div class="col-md-12">
      <div class="ss-container">
        <h3>Scaffold History</h3>
        <ngx-datatable class="table table-hover" [rows]="timelineEvents" [columnMode]="'flex'" [footerHeight]="50"
          [headerHeight]="40" [rowHeight]="'auto'" [sorts]="[{prop: 'When', dir: 'desc'}]" [limit]="10"
          [selectionType]="'single'" (select)="onDiarySelected($event)">
          <ngx-datatable-column name="Event" prop="DiaryType" [flexGrow]="3">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <hub-diary-type [diary]="row" [inspectionTypes]="configuration.InspectionTypes"></hub-diary-type>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Who" prop="OwnerName" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.OwnerName }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="When" prop="When" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.When | datetime : 'lll' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Notes" [flexGrow]="4" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.Notes }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="" [flexGrow]="1" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span *ngIf="row.DiaryType === diaryTypes.Diary">Click to view details</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
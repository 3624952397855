import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubApiSettingsService } from 'app/core/hub-api';
import { StockReportFilter } from 'app/reports/models/stock-report-filter';

@Injectable()
export class StockReportQueryService {

    constructor(
        private http: HttpClient,
        private settingsService: HubApiSettingsService,
    ) { }

    GetReportFromFilter(filter: any): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
            body: filter,
        };

        return this.http.request('POST', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Filter/Report', options);
    }

    GetListFromFilter(filter: StockReportFilter): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
            body: filter,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Filter/List', options);
    }

    GetForScaffold(scaffoldId: string): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Scaffold/' + scaffoldId, options);
    }

    GetForSite(siteId: string): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Site/' + siteId, options);
    }

    GetForDepot(depotId: string): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Depot/' + depotId, options);
    }

    GetForCustomer(customerId: string): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Customer/' + customerId, options);
    }

    GetForContract(contractId: string): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
        };

        return this.http.request('GET', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/Contract/' + contractId, options);
    }

    StockReportAsCsv(filter: any): Observable<any> {
        const token = this.settingsService.getAccessToken();
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
        const options = {
            headers: headers,
            body: filter,
            responseType: 'arraybuffer'
        };

        return this.http.request('POST', this.settingsService.getApiServiceBaseUrl() + 'Reports/Stock/ReportToCSV', (options as any));
    }
}

import { Pipe, PipeTransform } from '@angular/core';

// Pipe for displaying negative number without the minus sign
@Pipe({
  name: 'removeNegativeSymbol'
})
export class RemoveNegativeSymbolPipe implements PipeTransform {

  transform(num: number): number {
    return num >= 0 ? num : Math.abs(num);
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivityModalService } from 'app/shared/services/activity-modal.service';
import { HandoverTypeService } from 'app/sites/services/handover-type-service';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import {
  ContractData,
  CustomerWebAppData,
  HandoverHistoryWebAppData,
  SiteWebAppData,
  ScaffoldTimelineData,
  DiaryTypeEnum,
  HandoverHistoryTypeEnum
} from 'app/core/hub-api';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { HandoverHistoryQueryService } from '../../../../../core/services/handover-history-query-service';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { HandoverTypeOption } from '../../../../models/handover-type-option';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { mergeMap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'hub-handovers-list',
  templateUrl: './handovers-list.component.html',
  styleUrls: ['./handovers-list.component.scss']
})
export class HandoversListComponent implements OnInit, OnDestroy {
  /** Stores the selected site */
  site: SiteWebAppData;
  /** Stores all contracts */
  contracts: ContractData[];
  /** contains the selected filters */
  filters: any;
  /** List of all customers */
  customers: CustomerWebAppData[];
  /** List of all the site handovers */
  siteHandovers: HandoverHistoryWebAppData[];
  /** Stores all filtered handovers */
  filteredHandovers: HandoverHistoryWebAppData[];
  /** Filter form group control */
  filtersForm: UntypedFormGroup;
  /** Search input form control */
  searchTermFormControl: UntypedFormControl;
  /** Handover types dropdown form control */
  handoverTypesFormControl: UntypedFormControl;
  /** Contracts dropdown form control */
  contractListFormControl: UntypedFormControl;
  /** Stores all handover type options */
  handoverTypeOptions: HandoverTypeOption[];
  /** Stores all customers for the selected site */
  siteCustomers: any;
  /** Stores the contracts to be added to the filter */
  contractNames: any = [];
  /** Stores all contract names */
  allContractNames: any;
  /** Stores the selected handovers for a the current selected customer */
  selectedHandovers: any;
  /** Specific for the ngx-datatable to be able to do changes */
  @ViewChild(DatatableComponent) handoversTable: DatatableComponent;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private handoverHistoryQueryService: HandoverHistoryQueryService,
    private handoverTypeService: HandoverTypeService,
    private activityModal: ActivityModalService,
  ) { }

  /**
   * Component Initialisation
   *
   * @memberof HandoversListComponent
   */
  public ngOnInit(): void {

    this.handoverTypeOptions = this.handoverTypeService.getHandoverTypes();

    this.handoverTypeOptions.filter(t => t.display);

    this.generateForm();

    this.searchTermFormControl.valueChanges.subscribe(searchTerm => {
      this.setContractIds();
    });

    const siteReference = this.route.parent.snapshot.params['siteReference'];
    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(siteReference);
    const siteDetailChanges$ = siteDetailChanges.pipe(mergeMap(site => this.handoverHistoryQueryService.handoverHistoryQuery(true, [site.SiteId])));

    siteDetailChanges$.subscribe(handovers => {
      this.siteHandovers = handovers;
      this.setFilteredHandovers();
    });
    const obs$ = combineLatest([siteDetailChanges, this.customersQueryService.customersDataChanges()]);
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1]));
  }

  /**
   * Generates the search and filter form that supports the handovers list
   *
   * @private
   * @memberof HandoversListComponent
   */
  private generateForm(): void {
    this.contractListFormControl = new UntypedFormControl('');
    this.searchTermFormControl = new UntypedFormControl('');
    this.handoverTypesFormControl = new UntypedFormControl(this.handoverTypeOptions.map(o => o.type));

    this.filtersForm = new UntypedFormGroup({
      searchTerm: this.searchTermFormControl,
      handoverTypes: this.handoverTypesFormControl,
      contractList: this.contractListFormControl,
    });
  }

  /**
   * Refreshes the screen with all data
   *
   * @param {SiteWebAppData} site the selected site data
   * @param {CustomerWebAppData[]} customers all customers data
   * @memberof HandoversListComponent
   */
  public refreshComponent(site: SiteWebAppData, customers: CustomerWebAppData[]): void {
    this.site = site;
    this.customers = customers;
    this.contracts = this.site.Contracts.filter(contract => contract.ContractStatusId === contractStatusIds.open)
      .map(contract => {
        contract = cloneDeep(contract);
        (contract as any).name = this.customers.find(c => c.CustomerId === contract.CustomerId).CustomerName;
        return contract as any;
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    this.filters = {
      selectedCustomer: undefined,
    };

    // Adds the contract name filters
    this.contractNames = [];
    this.contracts.map(c => {
      this.contractNames.push({ name: c.ContractName, type: c.ContractId });
    });
    this.allContractNames = this.contractNames;

    // Checks all contract names as default
    this.filtersForm.controls.contractList.setValue(this.contractNames.map(c => c.type));

    // Sets all the customers the site has
    const customerIds = this.site.Contracts.map(c => c.CustomerId);
    this.siteCustomers = this.customers.filter(c => customerIds.includes(c.CustomerId));
    this.setFilteredHandovers();
  }

  /**
   * in the case there isn't a customer selected it will set all contract ids
   * this makes the list to show all contracts
   *
   * @private
   * @returns {void}
   * @memberof HandoversListComponent
   */
  private setContractIds(): void {
    if (!this.filters.selectedCustomer) {
      this.setFilteredHandovers();
      return;
    }
    const contractIds = this.contracts.filter(c => c.CustomerId === this.filters.selectedCustomer.CustomerId).map(c => c.ContractId);
    this.setFilteredHandovers(contractIds);
  }

  /**
   * Function that sets the filtered handovers based on the user's search/filters
   * This function is not private so we can run tests against it
   *
   * @param {string[]} [contractIds] list of contract ids
   * @returns {void}
   * @memberof HandoversListComponent
   */
  public setFilteredHandovers(contractIds?: string[]): void {
    if (!this.siteHandovers || !this.contracts) {
      return;
    }

    let filteredHandovers = this.siteHandovers;
    // Filters the customers to the ones that have handovers
    const handoverCustomers = this.siteHandovers.map(h => h.CustomerId);
    if (this.siteCustomers) {
      this.siteCustomers = this.siteCustomers.filter(c => handoverCustomers.includes(c.CustomerId));
    }

    // Filter out scaffolds from closed contracts.
    filteredHandovers = filteredHandovers.filter(handover => {
      const contract = this.contracts.find(c => c.ContractId === handover.ContractId) || new ContractData();
      return contract.ContractStatusId === contractStatusIds.open;
    });

    // Filter by search term.
    if (this.searchTermFormControl.value) {
      filteredHandovers = filteredHandovers.filter(handover => {
        const keys = Object.keys(handover);
        return keys.some(key =>
          (handover[key] || '')
            .toString()
            .toLowerCase()
            .includes(this.searchTermFormControl.value.toLowerCase())
        );
      });
    }

    // Filter by selected contract.
    if (contractIds) {
      filteredHandovers = filteredHandovers.filter(h => contractIds.includes(h.ContractId));
    }

    // Filter by selected handover types.
    if (this.handoverTypesFormControl.value) {
      filteredHandovers = filteredHandovers.filter(handover => this.handoverTypesFormControl.value.includes(handover.Type));
    }

    // Set the handovers table back to the first page.
    if (this.handoversTable) {
      this.handoversTable.offset = 0;
    }

    this.filteredHandovers = filteredHandovers;
  }

  /**
   * Gets tje selected customer from the UI, enables the contracts dropdown
   *
   * @param {*} selectedCustomer the selected customer object
   * @returns {void}
   * @memberof HandoversListComponent
   */
  public selectCustomer(selectedCustomer): void {
    this.filters.selectedCustomer = selectedCustomer;

    if (selectedCustomer === undefined) {
      this.setFilteredHandovers();
      return;
    }

    this.contractNames = [];
    this.contracts.map(c => {
      if (c.CustomerId === selectedCustomer.CustomerId) {
        this.contractNames.push({ name: c.ContractName, type: c.ContractId });
      }
    });
    const customerContractIds = this.contractNames.map(c => c.type);
    this.filtersForm.controls.contractList.setValue(customerContractIds);
    this.contractFiltering();
    this.setFilteredHandovers(customerContractIds);
  }

  /**
   * Detects if user has selected a specific customer and sets the contracts accordingly
   *
   * @private
   * @memberof HandoversListComponent
   */
  private contractFiltering(): void {
    if (this.filters.selectedCustomer) {
      this.selectedHandovers = this.filteredHandovers.filter(h => h.CustomerId === this.filters.selectedCustomer.CustomerId);

      if (this.selectedHandovers) {
        const handoverContractIds = this.selectedHandovers.map(s => s.ContractId);
        this.contractNames = this.allContractNames.filter(cn => handoverContractIds.includes(cn.type));
      }
    } else {
      this.contractNames = this.allContractNames;
    }
  }

  /**
   * Detects when the user clicks on a handover and opens the activity modal for that same handover
   *
   * @param {*} event
   * @memberof HandoversListComponent
   */
  public onHandoverSelect(event): void {
    const diary: ScaffoldTimelineData = event.selected[0];
    const type: number = event.selected[0].Type;
    this.activityModal.openActivityModal(this.typeSwitch(type), diary.ScaffoldDiaryId, this.site.SiteReference);
  }

  /**
   * Detects the type of modal to be opened
   *
   * @private
   * @param {number} type
   * @returns {*}
   * @memberof HandoversListComponent
   */
  private typeSwitch(type: number): any {
    switch (type) {
      case HandoverHistoryTypeEnum.Adaption:
        return DiaryTypeEnum.Adaption;
      case HandoverHistoryTypeEnum.Variation:
        return DiaryTypeEnum.Variation;
      case HandoverHistoryTypeEnum.FullHandover:
        return DiaryTypeEnum.FullHandover;
      case HandoverHistoryTypeEnum.PartialHandover:
        return DiaryTypeEnum.PartialHandover;
      case HandoverHistoryTypeEnum.FullOffHire:
        return DiaryTypeEnum.FullOffHire;
      case HandoverHistoryTypeEnum.PartialOffHire:
        return DiaryTypeEnum.PartialOffHire;
      default:
        return;
    }
  }

  /**
   * Detects when the user changes the contracts dropdown
   *
   * @param {string[]} contractIds
   * @memberof HandoversListComponent
   */
  public onSelectedContractChanged(contractIds: string[]): void {
    this.filtersForm.controls.contractList.setValue(contractIds);
    this.setFilteredHandovers(contractIds);
  }

  /**
   * Detects when the user changes the handover types dropdown
   *
   * @param {number[]} handoverTypeIds all selected handover enums
   * @memberof HandoversListComponent
   */
  public onSelectedHandoverTypeChanged(handoverTypeIds: number[]): void {
    this.filtersForm.controls.handoverTypes.setValue(handoverTypeIds);
    this.setContractIds();

  }


  /**
   * Required by the AutoUnsubscribe()
   *
   * @memberof HandoversListComponent
   */
  public ngOnDestroy(): void { }

}

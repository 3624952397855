import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AddEditStaffModalComponent } from './components/add-edit-staff-modal/add-edit-staff-modal.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { HandoversExtraHireChartComponent } from './components/handovers-extra-hire-chart/handovers-extra-hire-chart.component';
import { HandoversScaffoldStatusChartComponent } from './components/handovers-scaffold-status-chart/handovers-scaffold-status-chart.component';
import { HelpPanelComponent } from './components/help-panel/help-panel.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { InitialsIconComponent } from './components/initials-icon/initials-icon.component';
import { InspectionResultComponent } from './components/inspection-result/inspection-result.component';
import { ModalFormFooterComponent } from './components/modal-form-footer/modal-form-footer.component';
import { ModalFormHeaderComponent } from './components/modal-form-header-component/modal-form-header.component';
import { MultiStepComponent } from './components/multi-step/multi-step.component';
import { OneDayInspectionChartComponent } from './components/one-day-inspection-chart/one-day-inspection-chart.component';
import { PlacesInputComponent } from './components/places-input/places-input.component';
import { SelectWithInfoComponent } from './components/select-with-info/select-with-info.component';
import { SevenDayHandoverChartComponent } from './components/seven-day-handover-chart/seven-day-handover-chart.component';
import { SevenDayInspectionChartComponent } from './components/seven-day-inspection-chart/seven-day-inspection-chart.component';
import { SevenDayInspectionsDueChartComponent } from './components/seven-day-inspections-due-chart/seven-day-inspections-due-chart.component';
import { TwelveMonthInspectionChartComponent } from './components/twelve-month-inspection-chart/twelve-month-inspection-chart.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { DatetimePipe } from './pipes/datetime.pipe';
import { IdToTitlePipe } from './pipes/id-to-title.pipe';
import { SiteStatusPipe } from './pipes/site-status.pipe';
import { IdToActivityPipe } from './pipes/id-to-activity.pipe';
import { LoadingSpinnerComponent } from 'app/shared/components/loading-spinner/loading-spinner';
import { RouterModule } from '@angular/router';
import { ColourCodedTitleComponent } from './components/colour-coded-title/colour-coded-title.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { DownloadClickDirective } from 'app/shared/directives/download-click.directive';
import { ExportCsvComponent } from './components/export-csv/export-csv.component';
import { PrintComponent } from './components/print/print.component';
import { EditableListTableComponent } from './components/editable-list-table/editable-list-table.component';
import { AddEditListItemModalComponent } from './components/add-edit-list-item-modal/add-edit-list-item-modal.component';
import { AddEditStaffRatesModalComponent } from './components/add-edit-staff-rates-modal/add-edit-staff-rates-modal.component';
import { NgxTrimModule } from 'ngx-trim';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DefectResultsPipe } from './pipes/defect-results.pipe';
import { OverUnderComponent } from './components/over-under/over-under.component';
import { RemoveNegativeSymbolPipe } from './pipes/remove-negative-symbol-pipe';
import { SignaturesSvgComponent } from './components/signatures-svg/signatures-svg.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import { DefectTypesChartComponent } from './components/defect-types-chart/defect-types-chart.component';
import { BrowserWarningComponent } from './components/browser-warning/browser-warning.component';
import { ActivityReportService } from './services/activity-report.service';
import { DownloadReportButtonComponent } from 'app/activity/components/download-report-button/download-report-button.component';
import { WindowScrollingService } from './services/window-scrolling.service';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayValuePipe } from './pipes/display-value.pipe';
import { FieldTypePipe } from './pipes/field-type.pipe';
import { AddEditDepotsModalComponent } from 'app/admin/components/add-edit-depots-modal/add-edit-depots-modal.component';
import { LimitReachedModalComponent } from './components/limit-reached-modal/limit-reached-modal.component';
import { CompanySettingsService } from './services/company-setting.service';
import { StringConversionService } from './services/string-conversion.service';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { YoutubeComponent } from './components/youtube/youtube.component';
import { ModalModule as NgxModalModule } from 'ngx-bootstrap/modal';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateService } from './services/date.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { FeatureFlagsModule } from './components/feature-flags/feature-flags.module';
import { AuthenticationEventsService } from 'app/core/authentication';
import { PermissionComponent } from 'app/core/authentication/components/permission/permission.component';

// Contains components, directives and pipes for use the across whole application.
//
// DO NOT PUT APPLICATION WIDE SERVICES INTENDED AS SINGLETONS IN THIS MODULE - application wide services
// should generally go in the core module to ensure that they are only instantiated once.
@NgModule({
    declarations: [
        /// PIPES
        IdToTitlePipe,
        SiteStatusPipe,
        DatetimePipe,
        IdToActivityPipe,
        DefectResultsPipe,
        RemoveNegativeSymbolPipe,
        DisplayValuePipe,
        FieldTypePipe,

        /// DIRECTIVES
        DynamicComponentDirective,
        DownloadClickDirective,

        /// COMPONENTS
        DatePickerComponent,
        InitialsIconComponent,
        SevenDayInspectionChartComponent,
        OneDayInspectionChartComponent,
        TwelveMonthInspectionChartComponent,
        SevenDayHandoverChartComponent,
        DonutChartComponent,
        FormGroupComponent,
        PlacesInputComponent,
        ImageModalComponent,
        ModalFormHeaderComponent,
        ModalFormFooterComponent,
        InspectionResultComponent,
        MultiStepComponent,
        SelectWithInfoComponent,
        SevenDayInspectionsDueChartComponent,
        HandoversScaffoldStatusChartComponent,
        HandoversExtraHireChartComponent,
        HelpPanelComponent,
        AddEditStaffModalComponent,
        LoadingSpinnerComponent,
        ColourCodedTitleComponent,
        AudioPlayerComponent,
        VideoPlayerComponent,
        ImageViewerComponent,
        TextEditorComponent,
        ExportCsvComponent,
        EditableListTableComponent,
        AddEditListItemModalComponent,
        AddEditStaffRatesModalComponent,
        OverUnderComponent,
        SignaturesSvgComponent,
        ImageCarouselComponent,
        PrintComponent,
        DefectTypesChartComponent,
        BrowserWarningComponent,
        DownloadReportButtonComponent,
        AddEditDepotsModalComponent,
        LimitReachedModalComponent,
        DropdownComponent,
        YoutubeComponent,
        ModalDialogComponent,
        PermissionComponent
    ],
    imports: [
        /// MODULES --- ANGULAR
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        /// MODULES --- PLUGINS
        NgxDatatableModule,
        FeatureFlagsModule,
        RouterModule,
        CarouselModule,
        NgSelectModule,
        TooltipModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        TranslateModule.forRoot(),
        AccordionModule.forRoot(),
        NgxModalModule.forRoot(),
        NgxExtendedPdfViewerModule,
        // TODO: Make these work on individual PR's
        NgxTrimModule,
        InfiniteScrollModule,
        NgxDaterangepickerMd.forRoot({
            format: 'DD-MM-YYYY',
        }),
        NgOptionHighlightModule,

        // Google Maps
        GoogleMapsModule,

        // Slickgrid
        AngularSlickgridModule.forRoot(),
    ],

    exports: [
        /// MODULES --- ANGULAR
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        /// MODULES --- PLUGINS
        NgxDaterangepickerMd,
        FeatureFlagsModule,
        TooltipModule,
        AlertModule,
        BsDropdownModule,
        RouterModule,
        NgxTrimModule,
        PopoverModule,
        InfiniteScrollModule,
        TranslateModule,
        CarouselModule,
        AccordionModule,
        NgxModalModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgxDatatableModule,
        NgxExtendedPdfViewerModule,

        /// COMPONENTS
        FormGroupComponent,
        ModalFormFooterComponent,
        OverUnderComponent,
        ModalFormHeaderComponent,
        DropdownComponent,
        EditableListTableComponent,
        HelpPanelComponent,
        SelectWithInfoComponent,
        MultiStepComponent,
        ImageCarouselComponent,
        DownloadReportButtonComponent,
        BrowserWarningComponent,


        // PIPES
        DatetimePipe,
        IdToActivityPipe,
        DatePickerComponent,
        IdToTitlePipe,
        InitialsIconComponent,
        SevenDayInspectionChartComponent,
        OneDayInspectionChartComponent,
        TwelveMonthInspectionChartComponent,
        SevenDayHandoverChartComponent,
        SevenDayInspectionsDueChartComponent,
        HandoversScaffoldStatusChartComponent,
        HandoversExtraHireChartComponent,
        DonutChartComponent,
        SiteStatusPipe,
        PlacesInputComponent,
        ImageModalComponent,
        InspectionResultComponent,
        AddEditStaffModalComponent,
        LoadingSpinnerComponent,
        ColourCodedTitleComponent,
        AudioPlayerComponent,
        VideoPlayerComponent,
        ImageViewerComponent,
        TextEditorComponent,
        DownloadClickDirective,
        ExportCsvComponent,
        PrintComponent,
        AddEditListItemModalComponent,
        DefectResultsPipe,
        RemoveNegativeSymbolPipe,
        SignaturesSvgComponent,
        DefectResultsPipe,
        DefectTypesChartComponent,
        DisplayValuePipe,
        FieldTypePipe,
        LimitReachedModalComponent,
        YoutubeComponent,
        ModalDialogComponent,

        // Google Maps
        GoogleMapsModule,

        // Slickgrid
        AngularSlickgridModule,
        PermissionComponent
    ],
    providers: [
        /// PIPES
        IdToTitlePipe,
        IdToActivityPipe,
        DefectResultsPipe,
        RemoveNegativeSymbolPipe,
        DisplayValuePipe,
        FieldTypePipe,

        /// SERVICES
        LoadingSpinnerService,
        ActivityReportService,
        WindowScrollingService,
        CompanySettingsService,
        StringConversionService,
        DeviceDetectorService,
        AuthenticationEventsService,
        BsModalRef,
        DateService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SharedModule { }

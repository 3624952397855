import { FEATURE_FLAGS_TOKEN } from './feature-flags.token';

import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ssFeatureFlag]'
})
export class FeatureFlagDirective {

  private hasView = false;

  constructor(
    @Inject(FEATURE_FLAGS_TOKEN) private featureFlags,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  @Input() set ssFeatureFlag(feature: string) {
    if (this.featureFlags[feature] && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.featureFlags[feature] && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

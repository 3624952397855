import { Directive, ViewContainerRef } from '@angular/core';

// Directive used for displaying a component dynamically.
@Directive({
  selector: '[hubDynamicComponent]'
})
export class DynamicComponentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }
}

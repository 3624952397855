<hub-modal-form-header title="Mobile Preview" (close)="bsModalRef.hide()"></hub-modal-form-header>
<div class="modal-body">
  <div class="mobile-report-preview">
    <div class="col-md-12">
      <div class="device-preview-container">
        <div class="device-preview">
          <div class="device-content">
            <h4>{{ title }}</h4>
            <ul>
              <li *ngFor="let item of mobileListItems">
                <p>{{ item.Title }}</p>
                <p>{{ item.Subtitle }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

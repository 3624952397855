import { CoordinatesEvent } from '../../../../shared/models/coordinates-event';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { compact, flow, join, map, partialRight, pick } from 'lodash';
import {
  CreateSiteCommand,
  DepotData,
  SiteWebAppData,
  StaffDetailsWebAppData,
  ScaffoldingSystemdata,
} from 'app/core/hub-api';
import {
  MultiStepModalFormComponent,
} from '../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { errorMessages } from '../../../../shared/values/error-messages';
import { mapValues } from '../../../../shared/values/maps';
import { SiteImportModalContext } from '../../../models/site-import-modal-context';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { UUID } from 'angular2-uuid';
import { CustomValidator } from 'app/shared/validators/custom-validators';
import { environment } from 'environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-site-import-site',
  templateUrl: './site-import-site.component.html',
  styleUrls: ['./site-import-site.component.scss']
})
export class SiteImportSiteComponent extends MultiStepModalFormComponent implements OnInit {
  context: Partial<SiteImportModalContext>;

  camerasAllowedFormControl: UntypedFormControl;
  stockAllowedFormControl: UntypedFormControl;
  totalTonnageFormControl: UntypedFormControl;
  data: SiteImportModalData;
  loggedInStaff: StaffDetailsWebAppData;
  scaffoldingSystems: ScaffoldingSystemdata[];
  selectedScaffoldingSystemid: String;
  selectedScaffoldingSystem: ScaffoldingSystemdata;
  site: SiteWebAppData;

  depots: DepotData[];
  form: UntypedFormGroup;
  siteNameFormControl: UntypedFormControl;
  siteAddressFormControl: UntypedFormControl;
  siteDepotIdFormControl: UntypedFormControl;
  toggleScaffoldingSystem = {};
  scaffoldinSystemFormArray: UntypedFormArray = new UntypedFormArray([]);
  invalidValueMessage = errorMessages.invalidValue;
  validationMessages = {
    siteName: {
      required: errorMessages.required,
    },
    siteAddress: {
      required: errorMessages.required,
    },
    siteDepotId: {
      required: errorMessages.required
    }
  };

  map = {
    latitude: mapValues.defaultLatitude,
    longitude: mapValues.defaultLongitude,
    zoom: mapValues.defaultZoom,
  };

  constructor(
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.loggedInStaff = this.context.loggedInStaff;
    this.depots = this.context.depots.filter(d => this.loggedInStaff.SiteDepotIds.includes(d.SiteDepotId));
    if (!this.data.importData.site) {
      this.data.importData.site = Object.assign(new CreateSiteCommand(), {
        StaffId: this.loggedInStaff.StaffId,
        SiteAddress: flow([
          pick,
          map,
          compact,
          partialRight(join, ', ')
        ])(this.data.siteImportFile.SiteAddress, ['AddressLines', 'Town', 'County', 'Country', 'Postcode']),
        SiteName: this.data.siteImportFile.SiteAddress.AddressName,
        SiteDepotId: this.depots[0].SiteDepotId,
        Latitude: this.data.siteImportFile.SiteAddress.Latitude,
        Longitude: this.data.siteImportFile.SiteAddress.Longitude,
        IsCameraAllowed: true,
        IsManageStockControl: false,
        TotalTonnage: 0.0
      });
    }

    this.siteNameFormControl = new UntypedFormControl(this.data.importData.site.SiteName, [Validators.required]);
    this.siteAddressFormControl = new UntypedFormControl(this.data.importData.site.SiteAddress, [Validators.required]);
    this.siteDepotIdFormControl = new UntypedFormControl(this.data.importData.site.SiteDepotId, Validators.required);
    this.camerasAllowedFormControl = new UntypedFormControl(this.data.importData.site.IsCameraAllowed);
    this.stockAllowedFormControl = new UntypedFormControl(this.data.importData.site.IsManageStockControl);

    this.form = new UntypedFormGroup({
      siteName: this.siteNameFormControl,
      siteAddress: this.siteAddressFormControl,
      siteDepotId: this.siteDepotIdFormControl,
      camerasAllowed: this.camerasAllowedFormControl,
      stockAllowed: this.stockAllowedFormControl,
      scaffoldinSystemFormArray: this.scaffoldinSystemFormArray,
      tonnage: new UntypedFormArray([])
    });
    this.scaffoldingSystems = map(this.context.configuration.ScaffoldingSystems);
    this.renderTonnage();
    super.ngOnInit();
  }
  /**
   * Get tonnage details for each scaffolding system for the site and render it
   */
  private renderTonnage(): any {

    this.scaffoldingSystems.forEach((system) => {
      this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = false;
      if (system.IsDefault) {
        this.selectedScaffoldingSystemid = system.ScaffoldingSystemId;
        this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = true;
      }

      let totalTonnage = '0.00', siteTonnageId = UUID.UUID();
      if (this.data.importData.site.Tonnages) {
        const siteTonnages = this.data.importData.site.Tonnages;
        const tonnagePerScaffolding = siteTonnages.find(tonnage => (tonnage as any).scaffoldingSystemId === system.ScaffoldingSystemId);
        if (tonnagePerScaffolding) {
          totalTonnage = (tonnagePerScaffolding as any).totalTonnage.toString();
          siteTonnageId = (tonnagePerScaffolding as any).siteTonnageId;
        }
      }

      (this.form.get('tonnage') as UntypedFormArray).push(new UntypedFormGroup({
        scaffoldingSystemId: new UntypedFormControl(system.ScaffoldingSystemId),
        scaffoldingSystemName: new UntypedFormControl(system.Title),
        totalTonnage: new UntypedFormControl(totalTonnage, [CustomValidator.negativeDecimalValidator]),
        siteTonnageId: new UntypedFormControl(siteTonnageId)
      }));
    });
  }

  get tonnage(): any { return this.form.get('tonnage'); }


  onNext(formValues): void {
    if (this.validateForm()) {
      this.data.importData.site.SiteName = formValues.siteName;
      this.data.importData.site.SiteAddress = formValues.siteAddress;
      this.data.importData.site.SiteDepotId = formValues.siteDepotId;
      this.data.importData.site.Latitude = this.map.latitude;
      this.data.importData.site.Longitude = this.map.longitude;
      this.data.importData.site.IsCameraAllowed = formValues.camerasAllowed;
      this.data.importData.site.IsManageStockControl = formValues.stockAllowed;
      if (this.data.importData.site.IsManageStockControl) {
        this.data.importData.site.Tonnages = formValues.tonnage;
      }
      this.nextStep();
    }
  }

  /**
 * Sets the map marker for the given coordinates when they are moved
 *
 * @param {*} event
 * @memberof SiteImportSiteComponent
 */
  onMarkerMoved(event: google.maps.MapMouseEvent): void {
    this.map.latitude = event.latLng.lat();
    this.map.longitude = event.latLng.lng();
  }

  /**
   * Places the selected coordinates on the map
   *
   * @param {CoordinatesEvent} event
   * @memberof SiteImportSiteComponent
   */
  onPlaceSelected(event: CoordinatesEvent): void {
    this.map.latitude = event.latitude;
    this.map.longitude = event.longitude;
  }

  isShowManageStockControl(): boolean {
    const featureFlags = environment.featureFlags as any;
    return featureFlags.stockControl;
  }

  /**
   * show only clicked scaffolding system tonnage inputs
   * @param scaffoldingSystem
   */
  toggle(scaffoldingSystem): void {
    this.scaffoldingSystems.forEach((system) => {
      this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = false;
      if (scaffoldingSystem.ScaffoldingSystemId === system.ScaffoldingSystemId) {
        this.selectedScaffoldingSystemid = scaffoldingSystem.ScaffoldingSystemId;
        this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = true;
      }
    });
  }
}

import { ImageModalContext } from './image-modal-context';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// Component for displaying an image in a modal.
@Component({
  selector: 'hub-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  context: Partial<ImageModalContext>;
  imageUrls: string[];

  constructor(private bsModalRef: BsModalRef, public modalService: BsModalService) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'image-modal';
    this.imageUrls = this.context.imageUrl;
  }

  dismiss(): void {
    this.modalService.setDismissReason('continue-loading');
    this.bsModalRef.hide();
  }

}

export const ImportColumnIndexes = {
  scaffoldName: 1,
  scaffoldDescription: 2,
  area: 3,
  scaffoldType: 4,
  loadingLimit: 5,
  methodCladding: 6,
  methodTying: 7,
  quoteNumber: 8,
  itemNumber: 9,
  itemName: 10,
  scaffoldingSystem: 11,
  maximumTonnage: 12,
  hirePeriod: 13,
  designType: 14,
  dateNextInspection: 15,
  onHireDate: 16,
  affectedAdverseWeather: 17,
};

import { SiteWebAppData, ContractData, ContractContactData, CustomerData, ConfigurationData } from 'app/core/hub-api';

export class AddEditCompanyContactModalContext {
  editMode: boolean;
  chooseContractMode: boolean;
  site: SiteWebAppData;
  contract?: ContractData;
  contractContact?: ContractContactData;
  customers: CustomerData[];
  configuration: ConfigurationData;
}

<hub-loading-spinner *ngIf="loading"></hub-loading-spinner>
<table class="table table-borderless data-entries-table disable_text_highlighting" *ngIf="!noResults || !loading">
  <thead>
    <tr class="table-header">
      <ng-container *ngFor="let headers of columnHeaders">
        <th (click)="sorting(headers?.value)">{{ headers?.name }} <i class="fa fa-sort"
            *ngIf="headers?.name !== 'Activity' && headers?.name !== 'Status' "></i></th>
      </ng-container>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let data of displayData"
      (click)="openModal(data.ActivityType, data.ActivityTypeId, data.SiteReference)">
      <td class="break-word">
        <hub-initials-icon [name]="data?.StaffName" class="avatar"></hub-initials-icon> {{ data?.StaffName }}
      </td>
      <td class="break-word">{{ data?.ActivityDateTime | date: 'MMM dd, yyy - hh:mm a' }}</td>
      <td class="break-word">{{ data?.SiteName }} ({{ data?.SiteReference }})</td>
      <td class="break-word">
        <ng-container *ngIf="data?.ScaffoldNumber">{{ data?.CustomerName }} ({{ data?.CustomerReference }})
        </ng-container>
      </td>

      <td class="break-word">
        <ng-container *ngIf="data?.ScaffoldNumber"> <span>{{data?.ScaffoldNumber}}</span>
          <span class="cell-subtitle">{{scaffoldSubTitle(data?.ScaffoldName,data?.ScaffoldDescription)}}</span>
        </ng-container>
      </td>


      <td class="break-word">{{data?.Area}}</td>
      <td>
        <img src="{{ (data?.ActivityType | idToActivity: true).icon }}" class="table-icon" /> {{ (data?.ActivityType |
        idToActivity: false).text }}
      </td>
      <td>
        <hub-inspection-result [status]="data.Status" hideTitle="true"></hub-inspection-result>
      </td>
      <td>
        <span *ngIf="data?.status"><i class="fa fa-exclamation-circle" tooltip="Important" placement="top"></i></span>
      </td>
    </tr>
  </tbody>
</table>
<div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="loadMore()"></div>
<div class="empty-state-placeholder-large" *ngIf="noResults">
  <div>
    <i class="fa fa-search fa-4x"></i>
    <h3 class="text-center">No Results Found</h3>
    <p class="text-center">Use the filters on the top of the page to make a new search.</p>
  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

import { siteStatusIds } from '../values/site-status-ids';

// Pipe displaying a site status (Open/Closed) from an ID.
@Pipe({
  name: 'siteStatus'
})
export class SiteStatusPipe implements PipeTransform {

  transform(id: string): any {
    if (id === siteStatusIds.open) { return 'Open'; }
    if (id === siteStatusIds.closed) { return 'Closed'; }
  }
}

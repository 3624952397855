import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import userflow from 'userflow.js';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for managing userflow and tracking user events.
 */
export class UserflowService {

    constructor() {
        this.loadUserflow();
    }

    /**
     * Loads the userflow by initializing the userflow with the provided key.
     */
    private loadUserflow(): void {
        userflow.init(environment.userflowKey);
    }

    /**
     * Identifies the user with the specified user ID and optional traits.
     * @param userId - The ID of the user to identify.
     * @param traits - Optional traits associated with the user.
     */
    public identifyUser(userId: string, traits?: any): void {
        userflow.identify(userId, traits);
    }

    /**
     * Tracks an event using the specified event name and properties.
     * @param eventName - The name of the event to track.
     * @param properties - Optional properties associated with the event.
     */
    public trackEvent(eventName: string, properties?: any): void {
        userflow.track(eventName, properties);
    }
}
<div class="fullscreen">
  <div class="cssload-loader-inner">
    <div class="cssload-cssload-loader-line-wrap-wrap">
      <div class="cssload-loader-line-wrap"></div>
    </div>
    <div class="cssload-cssload-loader-line-wrap-wrap">
      <div class="cssload-loader-line-wrap"></div>
    </div>
    <div class="cssload-cssload-loader-line-wrap-wrap">
      <div class="cssload-loader-line-wrap"></div>
    </div>
    <div class="cssload-cssload-loader-line-wrap-wrap">
      <div class="cssload-loader-line-wrap"></div>
    </div>
    <div class="cssload-cssload-loader-line-wrap-wrap">
      <div class="cssload-loader-line-wrap"></div>
    </div>
  </div>
</div>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { some, cloneDeep, chain, map, each, findIndex } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SiteDashBoardScaffoldInspectionData } from 'app/core/hub-api';

import { TimeoutService } from '../../../core/services/timeout.service';
import { colors } from '../../values/colours';
import { inspectionOutcomeStatusIds } from '../../values/inspection-outcome-status-ids';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'hub-twelve-month-inspection-chart',
  templateUrl: './twelve-month-inspection-chart.component.html',
  styleUrls: ['./twelve-month-inspection-chart.component.scss']
})
export class TwelveMonthInspectionChartComponent implements OnInit, OnChanges {

  @Input()
  inspectionData: SiteDashBoardScaffoldInspectionData[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  @Input()
  startDate: Moment = moment();

  @Input()
  contractId: string;

  uiSettings = {
    showChart: true
  };

  chartConfig: Highcharts.Options = {
    chart: {
    },
    colors: [
      colors.tomato,
      colors.carrot,
      colors.river,
      colors.positive
    ],
    title: {
      text: 'Monthly Inspection Results',
      x: -20 // center
    },
    subtitle: {
      text: '',
      x: -20
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Total Results'
      },
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    series: [],
    credits: {
      enabled: false
    },
  };

  constructor(private timeoutService: TimeoutService) { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('twelveMonthInspectionChart', this.chartConfig);
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['inspectionData'] || changes['contractId']) && !!this.chart) {
      this.updateChartData();
    }
  }

  updateChartData(): Promise<void> {
    if (this.chart) {
      // Inspection data formatted in the way required by the chart.
      const formattedInspectionData = chain(this.inspectionData)
        .map((scaffold) => {
          const inspectionData = map(scaffold.InspectionData, (inspection: any) => {
            inspection.ContractId = scaffold.ContractId;
            return inspection;
          });
          return inspectionData;
        })
        .flatten()
        .value();


      return this.getChartData(formattedInspectionData).then((chartData) => {

        while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

        this.chart.addSeries({
          name: 'Unsafe (U)',
          data: chartData[inspectionOutcomeStatusIds.unsafeScaffold]
        }, false);

        this.chart.addSeries({
          name: 'Minor defects remain (Nm)',
          data: chartData[inspectionOutcomeStatusIds.defectsNotFixed],
        }, false);

        this.chart.addSeries({
          name: 'Defects fixed by inspector (Nf)',
          data: chartData[inspectionOutcomeStatusIds.defectsFixed],
        }, false);

        this.chart.addSeries({
          name: 'No defects found (N)',
          data: chartData[inspectionOutcomeStatusIds.noDefects],
        }, false);

        const xAxis = { categories: this.getMonthLabels() };
        this.chart.xAxis[0].update(xAxis, false);

        // Redraw the chart as few times as possible.
        this.chart.redraw();

        this.uiSettings.showChart = this.showChart(this.chart.series);
      });
    }
  }

  private showChart(seriesCollection): boolean {
    let show;
    each(seriesCollection, (series) => {
      show = some(series.data, (el: any) => {
        return el.y > 0;
      });
    });
    return show;
  }

  // Gets an array containing one date in each month of the chart's year.
  private getMonthsForYear(): any[] {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const month = cloneDeep(this.startDate).add(i, 'months');
      months.push(month);
    }
    return months;
  }

  private getMonthLabels(): any[] {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return map(this.getMonthsForYear(), (month) => {
      return months[month.month()];
    });
  }

  private getChartData(formattedInspectionData): Promise<any> {
    const chartData = {};

    const dateInEachMonth = this.getMonthsForYear();

    each(inspectionOutcomeStatusIds, (outcomeId) => {
      chartData[outcomeId] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    });

    const processInspectionData = (inspection) => {
      const month = findIndex(dateInEachMonth, (dateInMonth) => {
        return moment(inspection.When).isSame(dateInMonth, 'month');
      });

      if ((!this.contractId || this.contractId === inspection.ContractId) && month !== -1 && inspection.InspectionOutcomeId) {
        chartData[inspection.InspectionOutcomeId][month] += 1;
      }
    };

    let resolve;
    const promise = new Promise((res) => { resolve = res; });

    const iterationsBeforeTimeout = 10;

    this.timeoutService.forWithTimeouts(formattedInspectionData, processInspectionData, iterationsBeforeTimeout).then(() => {
      resolve(chartData);
    });

    return promise;
  }

}

/**
 * @license
 * Copyright © Computer and Design Services Ltd.
 * All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
 * electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
 */
import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CreateScaffoldWebConsoleCommand,
  DesignTypedata,
  EditScaffoldWebConsoleCommand,
  LoadingLimitData,
  MethodOfTyingData,
  MethodsOfCladdingdata,
  ScaffoldTypeData,
  SiteWebAppData,
  QuoteData,
  ItemData,
  ItemStatusEnum,
  ContractCommandService,
  ItemTypeEnum,
  ScaffoldingSystemdata
} from 'app/core/hub-api';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddEditScaffoldModalContext } from '../../../../models/add-edit-scaffold-modal-context';
import { MultiStepModalFormComponent } from '../../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { forkJoin } from 'rxjs';
import { ScaffoldCommandService } from 'app/core/hub-api';
import { ScaffoldDetailQueryService } from '../../../../services/scaffold-detail-query-service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { map } from 'lodash';
import { QuotesQueryService } from 'app/core/services/quotes-query-service';
import { ItemDetailsQueryService } from 'app/core/services/item-details-query.service';
import { GenerateDefaultsService } from 'app/core/services/generateDefaults.service';
import { mustNotExistValidator } from 'app/shared/validators/must-not-exist.validator';
import { errorMessages } from 'app/shared/values/error-messages';
import { environment } from 'environments/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomValidator } from 'app/shared/validators/custom-validators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ScaffoldTimelineQueryService } from 'app/sites/services/scaffold-timeline-query-service';
import { QuoteAndItemValidationStatus } from 'app/sites/values/quote-and-item-validation-status.enum';
import { UUID } from 'angular2-uuid';

@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-scaffold-specification',
  templateUrl: './add-edit-scaffold-specification.component.html',
  styleUrls: ['./add-edit-scaffold-specification.component.scss']
})
/**
 * Component for adding or editing a scaffold specification.
 * @extends MultiStepModalFormComponent
 */
/**
 * Component for adding or editing a scaffold specification.
 * @extends MultiStepModalFormComponent
 */
export class AddEditScaffoldSpecificationComponent extends MultiStepModalFormComponent implements OnInit, OnDestroy {
  /** Data passed to the component */
  data: any;
  /** Stores if the user is editing a scaffold or creating */
  editMode: boolean;
  /** Contains data from the modal */
  context: Partial<AddEditScaffoldModalContext>;
  /** Stores the form */
  form: UntypedFormGroup;
  /** Stores the current selected site data */
  site: SiteWebAppData;
  /** Stores all quotes */
  quotes: QuoteData[];
  /** Stores all items for the selected quote */
  quoteItems: ItemData[] = [];
  /** Stores the value of add to existing quote question */
  addToExistingQuoteQuestionFormControl: UntypedFormControl;
  /** Stores the value of add to existing item question */
  addToExistingItemQuestionFormControl: UntypedFormControl;
  /** Stores the dropdown form control */
  quoteDropdownFormControl: UntypedFormControl;
  /** Stores the quote number form control */
  quoteNumberFormControl: UntypedFormControl;
  /** Stores the item dropdown form control */
  itemDropdownFormControl: UntypedFormControl;
  /** Stores the item number form control */
  itemNumberFormControl: UntypedFormControl;
  /** Stores the item name form control */
  itemNameFormControl: UntypedFormControl;
  /** Validation for quote items */
  quoteItemsValidation: any;
  /** Validation for item number */
  itemNumberValidation: any;
  /** Stores the selected item and quote */
  itemAndQuote: any = [];
  /** Stores the selected item in the dropdown */
  itemDropdownSelected: any;
  /** Stores the selected quote in the dropdown */
  quoteDropdownSelected: any;
  /** Required message for item */
  itemRequiredMessage: string;
  /** Options for scaffold type */
  scaffoldTypeOptions: ScaffoldTypeData[];
  /** Options for design type */
  designTypeOptions: DesignTypedata[];
  /** Options for loading limit */
  loadingLimitOptions: LoadingLimitData[];
  /** Options for method of tying */
  methodOfTyingOptions: MethodOfTyingData[];
  /** Options for method of cladding */
  methodOfCladdingOptions: MethodsOfCladdingdata[];
  /** Options for scaffolding system */
  scaffoldingSystemOptions: ScaffoldingSystemdata[];
  /** Stores the scaffold type form control */
  scaffoldTypeFormControl: UntypedFormControl;
  /** Stores the description form control */
  descriptionFormControl: UntypedFormControl;
  /** Stores the maximum tonnage form control */
  maximumTonnageFormControl: UntypedFormControl;
  /** Stores the standing tonnage form control */
  standingTonnageFormControl: UntypedFormControl;
  /** Stores the design type form control */
  designTypeFormControl: UntypedFormControl;
  /** Stores the loading limit form control */
  loadingLimitFormControl: UntypedFormControl;
  /** Stores the method of tying form control */
  methodOfTyingFormControl: UntypedFormControl;
  /** Stores the method of cladding form control */
  methodOfCladdingFormControl: UntypedFormControl;
  /** Stores the scaffolding system form control */
  scaffoldingSystemFormControl: UntypedFormControl;
  /** Required message for scaffolding system */
  scaffoldingSystemRequiredMessage: string;
  /** Validation messages for form controls */
  validationMessages = {
    quoteDropdown: {
      required: errorMessages.required
    },
    itemDropdown: {
      required: errorMessages.required
    },
    quoteNumber: {
      required: errorMessages.required,
      mustNotExist: errorMessages.quoteNumberMustBeUnique
    },
    itemNumber: {
      required: errorMessages.required,
      mustNotExist: errorMessages.itemNumberMustBeUnique
    },
    itemName: {
      required: errorMessages.required
    },
    scaffoldingSystem: {
      required: errorMessages.required
    },
    maximumTonnage: {
      required: errorMessages.required,
      invalidValue: errorMessages.invalidValue
    },
    standingTonnage: {
      required: errorMessages.required,
      invalidValue: errorMessages.invalidValue
    }
  };
  /** Represents the validation status of a quote and its associated items. */
  quoteAndItemValidationStatus: QuoteAndItemValidationStatus;
  isLoading = true;
  constructor(
    private scaffoldCommandService: ScaffoldCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private siteDetailQueryService: SiteDetailQueryService,
    private scaffoldDetailQueryService: ScaffoldDetailQueryService,
    private contractCommandService: ContractCommandService,
    private quotesQueryService: QuotesQueryService,
    private itemDetailsQueryService: ItemDetailsQueryService,
    private generateDefaultsService: GenerateDefaultsService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public scaffoldTimelineQueryService: ScaffoldTimelineQueryService
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.quotes = this.data.quotes;
    this.editMode = this.context.editMode;
    this.site = this.context.site;
    this.scaffoldTypeOptions = map(this.context.configuration.ScaffoldTypes);
    this.designTypeOptions = map(this.context.configuration.DesignTypes);
    this.loadingLimitOptions = map(this.context.configuration.LoadingLimits);
    this.methodOfTyingOptions = map(this.context.configuration.MethodOfTyings);
    this.methodOfCladdingOptions = map(this.context.configuration.MethodOfCladdings);
    this.scaffoldingSystemOptions = map(this.context.configuration.ScaffoldingSystems);

    if (this.data.scaffold.ScaffoldSpecification.ScaffoldingSystemData && this.data.scaffold.ScaffoldSpecification.ScaffoldingSystemData.Deleted) {
      this.scaffoldingSystemOptions.push(this.data.scaffold.ScaffoldSpecification.ScaffoldingSystemData);
    }

    const defaultScaffoldingSystem = this.scaffoldingSystemOptions.find((scaffoldingsystem) => {
      return scaffoldingsystem.IsDefault;
    });

    // Set the quote and item question is switch on/ off on specification page load on add,
    // edit scaffold and on navigate to next and previous page vise versa and retain the edited data until submit button is clicked
    var existingQuoteQuestionEnabled = this.getExistingQuoteQuestionEnabled();
    var existingItemQuestionEnabled = this.getExistingQuoteItemQuestionEnabled();
    this.addToExistingQuoteQuestionFormControl = new UntypedFormControl(existingQuoteQuestionEnabled);
    this.addToExistingItemQuestionFormControl = new UntypedFormControl(existingItemQuestionEnabled);

    const allQuoteNumbersValidation = this.quotes.length ? Object.assign(this.quotes.map(q => q.QuoteNumber)) : [];

    this.itemNumberFormControl = new UntypedFormControl();
    this.quoteNumberFormControl = new UntypedFormControl('', [mustNotExistValidator(allQuoteNumbersValidation)]);
    this.itemNameFormControl = new UntypedFormControl();

    if (!this.editMode && !this.quotes.length) {
      this.addToExistingQuoteQuestionFormControl.setValue(false);
    }

    // Set the new quote number with value during add, edit scaffold and on
    // navigate to next and previous page vise versa and retain the edited data until submit button is clicked
    if (!existingQuoteQuestionEnabled) {
      this.quoteNumberFormControl.setValue(this.data.scaffold.QuoteNumber);
    }
    // Set the new item number and item name with value during add, edit scaffold and on
    // navigate to next and previous page vise versa and retain the edited data until submit button is clicked
    if (!existingItemQuestionEnabled) {
      this.itemNumberFormControl.setValue(this.data.scaffold.ItemNumber);
      this.itemNameFormControl.setValue(this.data.scaffold.ItemName);
    }

    this.scaffoldTypeFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.ScaffoldTypeId || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.ScaffoldTypeFurtherInformation || null
    });
    this.descriptionFormControl = new UntypedFormControl(this.data.scaffold.ScaffoldSpecification.Description);

    let maximumTonnage = this.data.scaffold.ScaffoldSpecification.MaximumTonnage;
    if (!maximumTonnage || maximumTonnage === 0) {
      maximumTonnage = (0.00).toFixed(2);
    }

    let standingTonnage = this.data.scaffold.ScaffoldSpecification.StandingTonnage;
    if (!standingTonnage || standingTonnage === 0) {
      standingTonnage = (0.00).toFixed(2);
    }

    this.maximumTonnageFormControl = new UntypedFormControl(maximumTonnage, [CustomValidator.negativeDecimalValidator]);
    this.standingTonnageFormControl = new UntypedFormControl(standingTonnage, [CustomValidator.negativeDecimalValidator]);
    this.designTypeFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.DesignTypeId || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.DesignTypeFurtherInformation
    });
    this.loadingLimitFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.LoadingLimitId || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.LoadingLimitFurtherInformation
    });
    this.methodOfTyingFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.MethodOfTyingId || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.MethodOfTyingFurtherInformation
    });
    this.methodOfCladdingFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.MethodOfCladdingId || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.MethodOfCladdingFurtherInformation
    });
    this.scaffoldingSystemFormControl = new UntypedFormControl({
      id: this.data.scaffold.ScaffoldSpecification.ScaffoldingSystemId || (!this.editMode && defaultScaffoldingSystem && defaultScaffoldingSystem.ScaffoldingSystemId) || null,
      furtherInfo: this.data.scaffold.ScaffoldSpecification.ScaffoldingSystemFurtherInformation
    }, Validators.required);

    this.quoteDropdownFormControl = new UntypedFormControl(null, Validators.required);
    this.itemDropdownFormControl = new UntypedFormControl(null, Validators.required);

    if (this.quotes.length && this.data.scaffold.QuoteId && this.editMode) {
      // if in edit mode and there is an existing quote, then set the quote drop down selected value
      this.onQuoteDropdownValueChanged(this.data.scaffold.QuoteId);

      // If it is on edit mode and there is an existing item, then set the item drop down selected value
      this.onItemDropdownValueChanged(existingItemQuestionEnabled ? this.data.scaffold.ItemId : null);
    } else {
      // If there are no quotes then its the 1st scaffold of the site or the site has no quotes
      this.removeExistingQuoteValidation();
      this.removeExistingItemValidation();
    }

    this.form = new UntypedFormGroup({
      scaffoldType: this.scaffoldTypeFormControl,
      description: this.descriptionFormControl,
      quoteDropdown: this.quoteDropdownFormControl,
      itemDropdown: this.itemDropdownFormControl,
      designType: this.designTypeFormControl,
      loadingLimit: this.loadingLimitFormControl,
      methodOfTying: this.methodOfTyingFormControl,
      methodOfCladding: this.methodOfCladdingFormControl,
      addToExistingQuoteQuestion: this.addToExistingQuoteQuestionFormControl,
      quoteNumber: this.quoteNumberFormControl,
      itemNumber: this.itemNumberFormControl,
      itemName: this.itemNameFormControl,
      addToExistingItemQuestion: this.addToExistingItemQuestionFormControl,
      scaffoldingSystem: this.scaffoldingSystemFormControl,
      maximumTonnage: this.maximumTonnageFormControl,
      standingTonnage: this.standingTonnageFormControl
    });

    this.form.controls.scaffoldingSystem.valueChanges.subscribe(() => {
      this.scaffoldingSystemRequiredMessage = null;
      this.form.controls.scaffoldingSystem.setErrors(null);
    });

    this.itemNumberFormControl.valueChanges.subscribe(() => {
      if (this.addToExistingItemQuestionFormControl.value) return;
      this.itemAndQuote.itemId = null;
      this.itemAndQuote.itemNumber = this.itemNumberFormControl.value;
    });

    this.itemNameFormControl.valueChanges.subscribe(() => {
      if (this.addToExistingItemQuestionFormControl.value) return;
      this.itemAndQuote.itemId = null;
      this.itemAndQuote.itemName = this.itemNameFormControl.value;
    });

    this.quoteNumberFormControl.valueChanges.subscribe(() => {
      if (this.addToExistingQuoteQuestionFormControl.value) return;
      this.itemAndQuote.quoteNumber = this.quoteNumberFormControl.value;
    });

    this.addToExistingQuoteQuestionFormControl.valueChanges.subscribe(addExistingQuoteOption => {
      // clears the quote dropdown and input depending on add to existing quote true or false
      if (addExistingQuoteOption) {
        // Add Existing Quote - YES
        this.removeNewQuoteValidation();
        this.removeNewItemValidation();
        this.updateExistingQuoteValidation();
        if (this.addToExistingItemQuestionFormControl.value) {
          this.updateExistingItemValidation();
        } else {
          this.updateNewItemValidation();
        }
      } else {
        // Add Existing Quote - NO
        this.removeExistingQuoteValidation();
        this.updateNewItemValidation();
        this.removeNewItemValidation();
        this.removeExistingItemValidation();
      }
    });

    this.addToExistingItemQuestionFormControl.valueChanges.subscribe(addExistingItemOption => {
      // clears the item dropdown and input depending on add to existing item true or false
      if (addExistingItemOption) {
        // Add Existing Item - YES
        this.onItemDropdownValueChanged(
          this.itemDropdownSelected && this.itemDropdownSelected.ItemId // 1st checks if the user has selected a value
            ? this.itemDropdownSelected.ItemId // gets selected value
            : this.data.scaffold.ItemId // else gets the existing value
              ? this.quoteItems.find(qi => qi.ItemId === this.data.scaffold.ItemId) // if not - checks if existing scaff already has a value
              : null // else will reset.
        );
        this.removeNewItemValidation();
        this.updateExistingItemValidation();
      } else {
        // Add Existing Item - NO
        this.removeExistingItemValidation();
        this.updateNewItemValidation();
      }
    });
    this.isLoading = false;
    super.ngOnInit();
  }

  onPrevious(formValues): void {
    // Save the specification data before navigation
    this.saveSpecificationData(this.getRevisedSpecificationData(formValues));
    this.previousStep();
  }


  /**
   * Updates the quote dropdown selection and its associated items based on the selected quote ID.
   * @param selectedQuoteId The ID of the selected quote.
   */
  public onQuoteDropdownValueChanged(selectedQuoteId) {
    this.removeNewQuoteValidation();
    this.itemDropdownFormControl.setValue([]);
    this.quoteDropdownSelected = selectedQuoteId;
    const selectedQuote = this.quotes.find(q => q.QuoteId === selectedQuoteId);
    this.quoteItems = selectedQuote ? selectedQuote.Items.map(i => i) : [];
    this.quoteDropdownFormControl.setValue(selectedQuoteId);
    this.itemAndQuote.quoteNumber = selectedQuote ? selectedQuote.QuoteNumber : null;
    this.itemAndQuote.quoteId = selectedQuote ? selectedQuote.QuoteId : null;
  }

  /**
   * Handles the change event of the item dropdown.
   * @param selectedItemId The ID of the selected item.
   */
  public onItemDropdownValueChanged(selectedItemId) {
    this.removeNewItemValidation();
    var item = this.quotes.find(q => q.QuoteId === this.quoteDropdownFormControl.value).Items.find(i => i.ItemId === selectedItemId);
    this.itemDropdownSelected = item;
    this.itemAndQuote.itemId = item.ItemId;
    this.itemAndQuote.itemNumber = item.ItemNumber;
    this.itemAndQuote.itemName = item.ItemName;
    this.itemDropdownFormControl.setValue(selectedItemId);
  }

  // Get the existing quote question is switch on/off on inital load
  private getExistingQuoteQuestionEnabled(): boolean {
    if (this.data.scaffold.QuoteId ||
      (!this.data.scaffold.QuoteNumber && this.quotes.length)) {
      return true;
    } else {
      return false;
    }
  }

  /** Get the existing quote item question is switch on/off on inital load */
  private getExistingQuoteItemQuestionEnabled(): boolean {
    if (this.data.scaffold.ItemId ||
      (!this.data.scaffold.ItemNumber && this.quotes.length)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the quote number for a given quote ID.
   * @param quoteId The ID of the quote to retrieve the number for.
   * @returns The quote number for the given quote ID, or undefined if the quote is not found.
   */
  getQuoteNumber(quoteId): any {
    if (quoteId) return this.quotes.find(q => q.QuoteId === quoteId).QuoteNumber;
  }

  /**
   * To check whether the stock control is enabled for this company
   */
  isManageStockControlEnabled(): boolean {
    const featureFlags = environment.featureFlags as any;
    return !featureFlags.stockControl ? false : this.site.IsManageStockControl;
  }

  /**
   * Updates the validation rules for the quote number form control based on the current list of quotes.
   * If there are any quotes, the form control must not have a value that already exists in the list of quotes.
   * The form control is also required.
   */
  private updateNewQuoteValidation(): void {
    const allQuoteNumbersValidation = this.quotes.length ? Object.assign(this.quotes.map(q => q.QuoteNumber)) : [];
    this.quoteNumberFormControl.setValidators([mustNotExistValidator(allQuoteNumbersValidation), Validators.required]);
    this.quoteNumberFormControl.updateValueAndValidity();
  }

  /**
   * Removes the validators from the quote number form control and updates its value and validity.
   * This is used to clear validation errors when a new quote is added.
   * @returns void
   */
  private removeNewQuoteValidation(): void {
    this.quoteNumberFormControl.clearValidators();
    this.quoteNumberFormControl.updateValueAndValidity();
  }

  /**
   * Removes the validators from the quote dropdown form control and updates its value and validity.
   * @returns void
   */
  private removeExistingQuoteValidation(): void {
    this.quoteDropdownFormControl.clearValidators();
    this.quoteDropdownFormControl.updateValueAndValidity();
  }

  /** Removes validators and updates the value and validity of the item number and item name form controls. */
  private removeNewItemValidation(): void {
    this.itemNumberFormControl.clearValidators();
    this.itemNumberFormControl.updateValueAndValidity();
    this.itemNameFormControl.clearValidators();
    this.itemNameFormControl.updateValueAndValidity();
  }

  /** Removes validators and updates the value and validity of the item dropdown form control. */
  private removeExistingItemValidation(): void {
    this.itemDropdownFormControl.clearValidators();
    this.itemDropdownFormControl.updateValueAndValidity();
  }

  /**
   * Updates the validation rules for the item number and item name form controls.
   * The item number form control is set to be required and must not exist in the list of quote items.
   * The item name form control is set to be required.
   */
  updateNewItemValidation(): void {
    var selectedQuote = this.quotes.find(q => q.QuoteId === this.itemAndQuote.quoteId);
    if (selectedQuote && this.addToExistingQuoteQuestionFormControl.value) {
      this.itemNumberFormControl.setValidators([
        Validators.required,
        mustNotExistValidator(Object.assign(selectedQuote.Items.map(i => i.ItemNumber))),
        Validators.required
      ]);
    } else {
      this.itemNumberFormControl.setValidators([Validators.required]);
    }
    this.itemNumberFormControl.updateValueAndValidity();
    this.itemNameFormControl.setValidators([Validators.required]);
    this.itemNameFormControl.updateValueAndValidity();
  }

  /**
   * Updates the validation for the item dropdown form control to make it required.
   * Also updates the error message to prompt the user to select an item.
   */
  updateExistingItemValidation(): void {
    this.itemDropdownFormControl.setValidators([Validators.required]);
    if (this.itemDropdownFormControl.value === null || this.itemDropdownFormControl.value === undefined || this.itemDropdownFormControl.value === '') {
      this.itemRequiredMessage = 'Please select an item';
    }
    this.itemDropdownFormControl.updateValueAndValidity();
  }

  /**
   * Updates the validation for the quote dropdown form control to make it required.
   */
  updateExistingQuoteValidation(): void {
    this.quoteDropdownFormControl.setValidators([Validators.required]);
    this.quoteDropdownFormControl.updateValueAndValidity();
  }


  validateScaffoldingSystem(): void {
    if (this.scaffoldingSystemFormControl.value.id === null) {
      this.form.controls.scaffoldingSystem.setErrors({ required: true });
      this.scaffoldingSystemRequiredMessage = 'This field is required';
    } else {
      this.scaffoldingSystemRequiredMessage = null;
      this.form.controls.scaffoldingSystem.setErrors(null);
    }
  }

  /**
   * Returns an object containing the revised scaffold specification data based on the provided form values.
   * @param formValues - The form values to use for generating the revised specification data.
   * @returns An object containing the revised scaffold specification data.
   */
  private getRevisedSpecificationData(formValues): any {
    const selectedItem = this.itemDropdownFormControl.value;
    const item = {
      ActualHours: selectedItem && selectedItem?.ActualHours ? selectedItem?.ActualHours : null,
      AllocatedHours: selectedItem && selectedItem.AllocatedHours ? selectedItem.AllocatedHours : null,
      ContractId: this.data.scaffold.ContractId,
      Estimator: selectedItem && selectedItem.Estimator ? selectedItem.Estimator : null,
      ItemId: this.itemAndQuote.itemId,
      ItemName: this.itemAndQuote.itemName,
      ItemNumber: this.itemAndQuote.itemNumber,
      ItemStatus: formValues.ItemStatus ? formValues.ItemStatus : ItemStatusEnum.Open,
      QuoteId: this.itemAndQuote.quoteId,
      QuoteNumber: this.itemAndQuote.quoteNumber
    };

    const revisedValues = {
      scaffoldType: formValues.scaffoldType,
      description: formValues.description,
      quoteId: this.itemAndQuote.quoteId,
      item: item,
      designType: formValues.designType,
      loadingLimit: formValues.loadingLimit,
      methodOfTying: formValues.methodOfTying,
      methodOfCladding: formValues.methodOfCladding,
      scaffoldingSystem: formValues.scaffoldingSystem,
      maximumTonnage: formValues.maximumTonnage,
      standingTonnage: formValues.standingTonnage
    };

    return revisedValues;
  }

  /**
   * Sets the quote and item properties of the `itemAndQuote` object to null.
   * This is used to clear the quote and item fields when the user selects "No Quote" and "No Item".
   */
  private setNoQuoteNoItem(): void {
    this.itemAndQuote.quoteId = null;
    this.itemAndQuote.quoteNumber = null;
    this.itemAndQuote.itemId = null;
    this.itemAndQuote.itemNumber = null;
    this.itemAndQuote.itemName = null;
  }

  /**
   * Validates the quote and item fields based on whether the user wants to add to an existing quote, an existing item, or create a new quote and item.
   */
  private validateQuoteAndItem(): void {
    var existingQuote = this.addToExistingQuoteQuestionFormControl.value;
    var quoteDropdown = this.quoteDropdownFormControl.value;
    var itemDropdown = this.itemDropdownFormControl.value;
    var existingItem = this.addToExistingItemQuestionFormControl.value && (existingQuote && this.quoteItems.find(x => x.QuoteId == quoteDropdown)) && itemDropdown.length > 0;

    var quoteNumber = this.quoteNumberFormControl.value;
    var itemNumber = this.itemNumberFormControl.value;
    var itemName = this.itemNameFormControl.value;

    // make sure the quote and item are correct considering the conditional use of existing quote or item
    if (!existingQuote) {
      // clear out the quoteId
      this.itemAndQuote.quoteId = null;
    }
    if (!existingItem) {
      this.itemAndQuote.itemNumber = itemNumber;
      this.itemAndQuote.itemName = itemName;
      this.itemAndQuote.itemId = null;
    }

    if (existingQuote && existingItem) {
      // quote and item can be empty
      if (!quoteDropdown && !itemDropdown) {
        this.quoteAndItemValidationStatus = QuoteAndItemValidationStatus.NoQuoteNoItem;
        this.removeExistingQuoteValidation();
        this.removeExistingItemValidation();
        this.removeNewItemValidation();
        this.removeNewQuoteValidation();
        this.setNoQuoteNoItem();
        return;
      }
      this.quoteAndItemValidationStatus = QuoteAndItemValidationStatus.ExistingQuoteExistingItem;
      this.updateExistingQuoteValidation();
      this.updateExistingItemValidation();
      this.removeNewQuoteValidation();
      this.removeNewItemValidation();
    }
    else if (existingQuote && !existingItem) {
      this.quoteAndItemValidationStatus = QuoteAndItemValidationStatus.ExistingQuoteNewItem;
      this.removeNewQuoteValidation();
      this.updateExistingQuoteValidation();
      this.updateNewItemValidation();
      this.removeExistingItemValidation();
    }
    else {
      // new quote and new item can be empty
      if (!quoteNumber && !itemNumber && !itemName) {
        this.quoteAndItemValidationStatus = QuoteAndItemValidationStatus.NoQuoteNoItem;
        this.removeNewQuoteValidation();
        this.removeNewItemValidation();
        this.removeExistingItemValidation();
        this.removeExistingQuoteValidation();
        this.setNoQuoteNoItem();
        return;
      }
      this.quoteAndItemValidationStatus = QuoteAndItemValidationStatus.NewQuoteNewItem;
      this.updateNewQuoteValidation();
      this.updateNewItemValidation();
      this.removeExistingQuoteValidation();
      this.removeExistingItemValidation();
    }
  }

  /**
   * Handles the form submission for adding or editing a scaffold specification.
   * Validates the form data and saves the specification data.
   * If editing a scaffold and a new item is being added, creates the new item.
   * Updates various queries and closes the modal.
   * @param formValues The form data to be submitted.
   * @returns A Promise that resolves when the submission is complete.
   */
  async onSubmit(formValues): Promise<void> {

    this.validateQuoteAndItem();
    this.validateScaffoldingSystem();
    try {

      if (this.validateForm()) {
        this.saveInProgress = true;

        var revisedValues = this.getRevisedSpecificationData(formValues);
        this.saveSpecificationData(revisedValues);

        const command: CreateScaffoldWebConsoleCommand | EditScaffoldWebConsoleCommand = Object.assign(this.data.scaffold, {
          OffsetFromUtc: moment().utcOffset(),
          ItemId: this.itemAndQuote.itemId,
          ItemNumber: this.itemAndQuote.itemNumber,
          ItemName: this.itemAndQuote.itemName,
          QuoteId: this.itemAndQuote.quoteId,
          QuoteNumber: this.itemAndQuote.quoteNumber,
        });

        const commandFunction = this.editMode
          ? this.scaffoldCommandService.EditScaffoldWebConsoleCommand
          : this.scaffoldCommandService.CreateScaffoldWebConsoleCommand;

        // it should create the item in case it is a new item
        if (this.quoteAndItemValidationStatus === QuoteAndItemValidationStatus.NewQuoteNewItem
          || this.quoteAndItemValidationStatus === QuoteAndItemValidationStatus.ExistingQuoteNewItem) {
          await this.createItem(revisedValues);
          const newQuote = this.quotes.find(quote => quote.QuoteId === this.itemAndQuote.quoteId);
          if (newQuote) {
            const newItem = newQuote.Items.find(item => item.ItemNumber === this.itemAndQuote.itemNumber);
            if (newItem) {
              const newItemId = newItem.ItemId;
              command.ItemId = newItemId;
            }
          }
        }

        commandFunction.call(this.scaffoldCommandService, command).subscribe(() => {
          const obs$ = forkJoin([
            this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId),
            this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
            this.scaffoldTimelineQueryService.scaffoldTimelineQuery(false, this.data.scaffold.ScaffoldId),

          ]);
          obs$.subscribe(async () => {
            this.saveInProgress = false;
            this.modalService.setDismissReason(this.data.scaffold.ScaffoldId);
            this.bsModalRef.hide();
          });
        }, this.serverErrorCallback);
      }
    } catch (error) {
      this.saveInProgress = false;
      this.serverError = error.toString();
    }
  }

  /**
   * Creates a new item with the given form values and adds it to the contract.
   * @param formValues The form values for the new item.
   * @returns A Promise that resolves when the item has been created and added to the contract.
   *          The Promise rejects if there was an error creating the item.
   */
  async createItem(formValues): Promise<void | any> {
    const featureFlags = environment.featureFlags as any;

    // need to derive the quote number from an existing quote or the new quote number

    const createItemCommand = {
      ItemId: UUID.UUID(),
      QuoteNumber: this.itemAndQuote.quoteNumber,
      QuoteId: this.itemAndQuote.quoteId,
      ItemNumber: this.itemAndQuote.itemNumber,
      ItemName: this.itemAndQuote.itemName,
      AllocatedHours: formValues.item.AllocatedHours,
      ContractId: this.data.scaffold.ContractId,
      ScaffoldIds: this.editMode ? [this.data.scaffold.ScaffoldId] : [],
      Tasks: featureFlags.timesheet ? this.generateDefaultsService.generateDefaultTasks() : null,
      ItemStatus: ItemStatusEnum.Open,
      Estimator: '',
      ItemType: ItemTypeEnum.Quoted
    };
    const commandResult = this.contractCommandService.CreateItemWebConsoleCommand(createItemCommand);

    const obs$ = forkJoin([
      this.quotesQueryService.quotesQuery(false, createItemCommand.ContractId),
      this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.data.scaffold.ScaffoldId)
    ]);

    return new Promise<void>((resolve, reject) => {
      commandResult.subscribe({
        next: (val) => {
          obs$.subscribe({
            next: (result) => {
              const quotes = result[0];
              const newItem = new ItemData();
              newItem.ContractId = this.data.scaffold.ContractId;
              newItem.QuoteId = this.itemAndQuote.quoteId;
              newItem.QuoteNumber = this.itemAndQuote.quoteNumber;
              newItem.ItemId = createItemCommand.ItemId;
              newItem.ActualHours = createItemCommand.AllocatedHours;
              newItem.AllocatedHours = createItemCommand.AllocatedHours;
              newItem.ItemName = this.itemAndQuote.itemName;
              newItem.ItemNumber = this.itemAndQuote.itemNumber;
              newItem.ItemStatus = ItemStatusEnum.Open;
              newItem.Estimator = createItemCommand.Estimator;

              const quoteToUpdate = quotes.find(quote => quote.QuoteId === newItem.QuoteId);
              if (quoteToUpdate) {
                const existingItemIndex = quoteToUpdate.Items.findIndex(item => item.ItemId === newItem.ItemId);
                if (existingItemIndex === -1) {
                  // if the new item is not already in the quote's Items array, add it
                  quoteToUpdate.Items.push(newItem);
                } else {
                  // if the new item is already in the quote's Items array, update it
                  quoteToUpdate.Items[existingItemIndex] = newItem;
                }
              } else {
                // if the quote is not in the quotes array, create a new quote with the new item
                const newQuote = new QuoteData();
                newQuote.ContractId = this.data.scaffold.ContractId;
                newQuote.QuoteId = this.itemAndQuote.quoteId;
                newQuote.QuoteNumber = this.itemAndQuote.quoteNumber;
                newQuote.Items = [newItem];
                quotes.push(newQuote);
              }
              this.quotes = quotes;
              resolve();
            },
            error: (error) => {
              this.serverErrorCallback(error);
              reject(error);
            }
          });
        },
        error: (error) => {
          this.serverErrorCallback(error);
          reject(error);
        }
      });
    });
  }

  /**
   * Updates the scaffold specification data with the provided form values.
   * @param formValues - The form values to update the scaffold specification with.
   * @returns void
   */
  saveSpecificationData(formValues): void {
    this.data.scaffold.ScaffoldSpecification = Object.assign(this.data.scaffold.ScaffoldSpecification, {
      ScaffoldTypeId: formValues.scaffoldType.id,
      ScaffoldTypeFurtherInformation: formValues.scaffoldType.furtherInfo,
      Description: formValues.description,
      DesignTypeId: formValues.designType.id,
      DesignTypeFurtherInformation: formValues.designType.furtherInfo,
      LoadingLimitId: formValues.loadingLimit.id,
      LoadingLimitFurtherInformation: formValues.loadingLimit.furtherInfo,
      MethodOfTyingId: formValues.methodOfTying.id,
      MethodOfTyingFurtherInformation: formValues.methodOfTying.furtherInfo,
      MethodOfCladdingId: formValues.methodOfCladding.id,
      MethodOfCladdingFurtherInformation: formValues.methodOfCladding.furtherInfo,
      ScaffoldingSystemId: formValues.scaffoldingSystem.id,
      MaximumTonnage: formValues.maximumTonnage,
      StandingTonnage: formValues.standingTonnage

    });
    this.data.scaffold.QuoteId = this.itemAndQuote.quoteId;
    this.data.scaffold.QuoteNumber = this.itemAndQuote.quoteNumber;
    this.data.scaffold.ItemId = this.itemAndQuote.itemId;
    this.data.scaffold.ItemNumber = this.itemAndQuote.itemNumber;
    this.data.scaffold.ItemName = this.itemAndQuote.itemName;
  }

  ngOnDestroy(): void { }
}

import { errorMessages } from "../../shared/values/error-messages";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

// Catches errors from the server and replaces them with error messages to be shown in the UI.
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 400) {
              const leadingTrailingWhiteSpaceRegex = /^\s+|\s+$/g;
              if (
                response.error.Message &&
                response.error.Message.split(":")[1]
              ) {
                const validationCode = response.error.Message.split(
                  ":"
                )[1].replace(leadingTrailingWhiteSpaceRegex, "");
                if (errorMessages[validationCode]) {
                  response.error.Message = errorMessages[validationCode];
                } else if (validationCode.includes(", position")) { //check for stack trace
                  console.error(validationCode);
                  response.error.Message = "";
                } else {
                  // show the original error message if we don't have a suitable message corresponding to the validation code
                  response.error.Message = validationCode;
                }
              }
            }
          }
        }
      )
    );
  }
}

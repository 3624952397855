import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationSettingsQueryService } from '../services/application-settings-query.service';



// Route guard for ensuring that the user is offline.
@Injectable()
export class OfflineGuard implements CanActivate {

  constructor(private applicationSettingsQueryService: ApplicationSettingsQueryService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.applicationSettingsQueryService.applicationSettingsQuery(false).pipe(
      map(() => {
        this.router.navigate(['/']);
        return false;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}

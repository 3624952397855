import { Component, OnInit, OnDestroy } from '@angular/core';
import { CreateStaffCommand, StaffDetailsWebAppData, EditStaffCommand, ApplicationSettingsData, StaffCommandService } from 'app/core/hub-api';
import { EulaSlaModalContext } from './eula-sla-modal-context';
import { ApplicationSettingsQueryService } from 'app/core/services/application-settings-query.service';
import { forkJoin } from 'rxjs';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { StaffDetailQueryService } from 'app/core/services/staff-detail-query.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

/**
 * Modal to show the EULA (End User License Agreement)
 * Contains the EULA text and a checkbox to accept the EULA
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-eula-sla-modal',
  templateUrl: './eula-sla-modal.component.html',
  styleUrls: ['./eula-sla-modal.component.scss']
})
export class EulaSlaModalComponent implements OnInit, OnDestroy {
  /** Stores the passed in values to this modal */
  context: Partial<EulaSlaModalContext>;
  /** Stores the staff details data */
  StaffDetailsData: StaffDetailsWebAppData;
  /** Stores the application settings data where it contains the EULA accepted version */
  applicationData: ApplicationSettingsData;
  /** Whether the EULA is currently being saved/submitted.  */
  saveInProgress = false;
  /** Stores any error message of the api calls */
  serverError = '';
  /** Stores the modal settings */
  modalSettings = {
    modalTitle: 'End User License Agreement',
  };
  /** Stores if the EULA was accepted */
  acceptEula = false;

  constructor(
    private applicationSettingsQueryService: ApplicationSettingsQueryService,
    private staffCommandService: StaffCommandService,
    private staffDetailQueryService: StaffDetailQueryService,
    private staffsQueryService: StaffsQueryService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  /** Gets the initial values from the function that calls this modal */
  ngOnInit(): void {
    this.context = this.modalService.config.initialState;
    this.StaffDetailsData = this.context.StaffDetailsData;
    this.applicationSettingsQueryService.applicationSettingsQuery(false).subscribe(data => this.applicationData = data);
  }

  /** Sets the EulaVersion */
  onSubmit(): void {
    this.saveInProgress = true;

    const command: CreateStaffCommand | EditStaffCommand = {
      ContactName: this.StaffDetailsData.ContactName,
      PhoneNumber: this.StaffDetailsData.PhoneNumber,
      RoleId: this.StaffDetailsData.RoleId,
      SiteDepotIds: this.StaffDetailsData.SiteDepotIds,
      StaffId: this.StaffDetailsData.StaffId,
      EmailAddress: this.StaffDetailsData.EmailAddress,
      Password: undefined,
      CanEstimate: this.StaffDetailsData.CanEstimate,
      QuickId: this.StaffDetailsData.QuickId,
      Deleted: this.StaffDetailsData.Deleted,
      RatesToMakeIneffective: null,
      RatesToAdd: null,
      RatesToDelete: null,
      EulaAgreedVersion: this.applicationData.EulaVersion,
      CompanyPosition: this.StaffDetailsData.CompanyPosition,
    };

    const result = this.staffCommandService.EditStaffCommand(command);
    result.subscribe(
      {
        next: () => {
          const obs$ = forkJoin([
            this.staffsQueryService.staffsQuery(false),
            this.staffDetailQueryService.staffDetailQuery(false, this.StaffDetailsData.StaffId)
          ])
          obs$.subscribe(() => {
            this.modalService.setDismissReason(this.StaffDetailsData.StaffId);
            this.bsModalRef.hide();
          });
        },
        error: (err) => {
          this.serverErrorCallback(err);
        }
      });
  }

  /** Shows the server error on the modal */
  private serverErrorCallback = (response: HttpErrorResponse): void => {
    this.serverError = response.error.Message;
    this.saveInProgress = false;
    throw response.error.Message;
  }

  /** Gets if the user has accepted the modal */
  onChange(e): void {
    this.acceptEula = e.target.checked;
  }

  /** Required by the AutoUnsubscribe */
  ngOnDestroy(): void { }
}

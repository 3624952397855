import {
  ScaffoldStatisticsData,
  DefectStatisticsQuery,
  DefectStatisticData
} from 'app/core/hub-api';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import * as Highcharts from 'highcharts';
import { colors } from '../../values/colours';
import { forEach as _forEach } from 'lodash';

@Component({
  selector: 'hub-defect-types-chart',
  templateUrl: './defect-types-chart.component.html',
  styleUrls: ['./defect-types-chart.component.scss']
})
export class DefectTypesChartComponent implements OnInit, OnChanges {
  @Input()
  defectData: any;

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  chartConfig: Highcharts.Options = {
    credits: { enabled: false },
    title: {
      text: ''
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Total Defect Count'
      }
    },
    xAxis: {
      categories: [''],
      labels: {
        enabled: true
      }
    },
    legend: {
      reversed: true
    },
    tooltip: {
      formatter: function (): string {
        return `${this.x}': <b>${this.y}</b>`;
      }
    },
    chart: {
      type: 'bar',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent'
    },
    colors: [
      colors.river,
      colors.turquoise,
      colors.emerald,
      colors.calm,
      colors.lightGrey
    ],
    plotOptions: {
      series: {
        stacking: 'normal'
      },
      bar: {
        colorByPoint: true,
        showInLegend: false
      }
    },
    series: []
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defectData'] && !!this.chart) {
      this.updateChartData();
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('defectTypesChart', this.chartConfig);
    this.updateChartData();
  }

  updateChartData(): void {
    if (this.chart) {
      while (!!this.chart.series[0]) {
        this.chart.series[0].remove();
      }
      if (this.defectData.length) {
        const data = [];

        _forEach(this.defectData, defect => {
          data.push(defect.Count);
        });

        const xAxisCategories = [];
        _forEach(this.defectData, defect => {
          xAxisCategories.push(
            `<strong>${defect.Group}</strong><br/>${defect.Name}`
          );
        });

        this.chart.addSeries({
          name: '',
          data: data
        });

        this.chart.xAxis[0].update({
          categories: xAxisCategories
        });
      }
      this.chart.redraw();

    }

  }
}

<div class="modal-content modal-red-important">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ activityDetail?.SiteDetails.CustomerName }} - {{ activityDetail?.SiteDetails.SiteName }} ({{ activityDetail?.ScaffoldDetails.ScaffoldName }})
    </h4>
    <a class="pull-right" (click)="dismiss()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"><img src="{{ (activityType | idToActivity: true).icon }}" class="modal-content-icon" /></div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Site: </span>{{ activityDetail?.SiteDetails.SiteName }} ({{ site.SiteReference }})</li>
                <li><span class="bold">Address: </span>{{ activityDetail?.SiteDetails.SiteAddress }}</li>
                <li><span class="bold">Customer: </span>{{ activityDetail?.SiteDetails.CustomerName }}</li>
                <li><span class="bold">Area: </span>{{ activityDetail?.ScaffoldDetails.SiteAreaName }}</li>
                <li><span class="bold">Scaffold: </span>{{ activityDetail?.ScaffoldDetails.ScaffoldName }}</li>
                <li [hidden]="!activityDetail.SiteDetails.IsManageStockControl"><span class="bold">Standing Tonnage: </span> {{ activityDetail?.ScaffoldDetails.StandingTonnage | number:'.1-2' }}</li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul>
                <li>
                  <span class="bold" *ngIf="activityDetail?.ScaffoldDetails.Location">Scaffold Name:
                  </span>{{ activityDetail?.ScaffoldDetails.Location }}
                </li>
                <li>
                  <span class="bold" *ngIf="activityDetail?.ScaffoldDetails.ScaffoldDescription">Scaffold Description:
                  </span>{{ activityDetail?.ScaffoldDetails.ScaffoldDescription }}
                </li>
                <li><span class="bold">Date: </span>{{ activityDetail?.OffHireData.When | date: 'MMM dd, yyy' }}</li>
                <li><span class="bold">Time: </span>{{ activityDetail?.OffHireData.When | date: 'hh:mm a' }}</li>
                <li><span class="bold">Requested by: </span> {{ requestedBy }}</li>
                <li><span class="bold" *ngIf="staffName">Staff: </span> {{ staffName }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="activityDetail?.OffHireData.OffHireComments || getImageArray(activityDetail?.OffHireData.Media)">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Off-Hire Details</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-3 carousel">
                  <hub-image-carousel [media]="getImageArray(activityDetail?.OffHireData.Media)" height="100px">
                  </hub-image-carousel>
                </div>
                <div class="col-md-9" *ngIf="activityDetail?.OffHireData.OffHireComments">
                  <div class="row"><span class="bold">Comments: </span>
                    {{ activityDetail?.OffHireData.OffHireComments }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="activityDetail?.OffHireData.CustomerSignature || activityDetail?.OffHireData.StaffSignature">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Acceptance Of The Scaffold</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngIf="activityDetail?.OffHireData.StaffSignature">
                  <div>
                    <hub-signatures-svg [data]="activityDetail?.OffHireData.StaffSignature.Data" width="100">
                    </hub-signatures-svg>
                  </div>
                  <div><span class="bold">Staff: </span>{{ staffName }}</div>
                  <div><span class="bold">Date:
                    </span>{{ activityDetail?.OffHireData.StaffSignature.When | date: 'MMM dd, yyy - hh:mm a' }}</div>
                </div>
                <div class="col-md-6" *ngIf="activityDetail?.OffHireData.CustomerSignature">
                  <div>
                    <hub-signatures-svg [data]="activityDetail?.OffHireData.CustomerSignature.Data" width="100">
                    </hub-signatures-svg>
                  </div>
                  <div><span class="bold">Customer: </span>{{ customerContact }}</div>
                  <div><span class="bold">Date:
                    </span>{{ activityDetail?.OffHireData.CustomerSignature.When | date: 'MMM dd, yyy - hh:mm a' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer"><button class="btn btn-large button-primary pull-right" (click)="dismiss()">Close</button>
      <hub-download-report-button [ActivityType]="activityType" ID="{{activityDetail?.OffHireData.OffHireId}}" [ActivityDetail]="activityDetail"></hub-download-report-button>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-agreed-rates',
  templateUrl: './agreed-rates.component.html',
  styleUrls: ['./agreed-rates.component.scss']
})
export class AgreedRatesComponent implements OnInit {

  _isScheduleRatesAgreed: boolean;
  @Input()
  set isScheduleRatesAgreed(isScheduleRatesAgreed: boolean) {
    this._isScheduleRatesAgreed = isScheduleRatesAgreed;
  }

  constructor() { }

  ngOnInit(): void {
  }

}


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HandoverEndpointService,
  HandoverStatisticsData,
  SiteDashBoardScaffoldInspectionData,
  SiteEndpointService,
  ScaffoldEndpointService,
  ScaffoldStatisticsData,
  StaffEndpointService,
  CustomerEndpointService,
  StaffStatisticsData,
  SiteStatisticsData,
  CustomerStatisticsData,
  InspectionEndpointService,
  DefectStatisticsQuery,
} from 'app/core/hub-api';
import { homeFilterHeaders } from '../../home/values/home-filter-headers';

// Service for fetching data for dashboard charts and statistics from the server, don't cache each query as it is
// not that helpful and could end up being quite a large amount of data in the case of thousands of inspections per site.
@Injectable()
export class DashboardQueryService {

  constructor(
    private siteEndpointService: SiteEndpointService,
    private handoverEndpointService: HandoverEndpointService,
    private scaffoldEndpointService: ScaffoldEndpointService,
    private staffEndpointService: StaffEndpointService,
    private customerEndpointService: CustomerEndpointService,
    private inspectionEndpointService: InspectionEndpointService
  ) { }

  getInspectionData(filter?: string, id?: string): Observable<SiteDashBoardScaffoldInspectionData[]> {
    return this.siteEndpointService.ScaffoldInspectionQuery(this.buildQuery(filter, id));
  }

  getHandoverData(filter?: string, id?: string): Observable<HandoverStatisticsData> {
    return this.handoverEndpointService.HandoverStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data[0]));
  }

  getScaffoldData(filter?: string, id?: string): Observable<ScaffoldStatisticsData> {
    return this.scaffoldEndpointService.ScaffoldStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data[0]));
  }

  getStaffStatistics(filter?: string, id?: string): Observable<StaffStatisticsData> {
    return this.staffEndpointService.StaffStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data[0]));
  }

  getSiteStatistics(filter?: string, id?: string): Observable<SiteStatisticsData> {
    return this.siteEndpointService.SiteStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data[0]));
  }

  getCustomerStatistics(filter?: string, id?: string): Observable<CustomerStatisticsData> {
    return this.customerEndpointService.CustomerStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data[0]));
  }
  getDefectTypesStatistics(filter?: string, id?: string): Observable<DefectStatisticsQuery> {
    return this.inspectionEndpointService.DefectStatisticsQuery(this.buildQuery(filter, id)).pipe(map(data => data));
  }

  private buildQuery(filter, id): any {
    const query = {};
    if (filter && id) {
        query[filter] = id;
    }
    query['AdminAssignedSitesOnly'] = filter === homeFilterHeaders.mySites;
    return query;
  }
}


import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

import { CoordinatesEvent } from '../../models/coordinates-event';

export const PLACES_INPUT_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PlacesInputComponent),
  multi: true
};

// Component for autocompleting user input using Google Places.
@Component({
  selector: 'hub-places-input',
  templateUrl: './places-input.component.html',
  styleUrls: ['./places-input.component.scss'],
  providers: [
    PLACES_INPUT_COMPONENT_VALUE_ACCESSOR
  ]
})
export class PlacesInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  @ViewChild('places', { static: true })
  public placesElementRef: ElementRef;
  autocomplete: google.maps.places.Autocomplete | undefined;

  value = '';
  propagateChanges: Function;

  // Outputs the latitude and longitude of the chosen place.
  @Output()
  placeSelected = new EventEmitter<CoordinatesEvent>();

  constructor(private ngZone: NgZone) { }

  /**
   * Initializing the Autocomplete service
   */
  ngAfterViewInit() {
    // Initializing the autocomplete and binding the placesElementRef
    this.autocomplete = new google.maps.places.Autocomplete(this.placesElementRef.nativeElement);

    // We need to create an event handler for when selecting a place from the dropdown or pess Enter on the input field
    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete?.getPlace();

        if (place?.geometry === undefined || place?.geometry === null) return;
  
        this.propagateChanges(place?.name);
        this.placeSelected.emit({
          latitude: place?.geometry?.location?.lat(),
          longitude: place?.geometry?.location?.lng()
        });
      });
    })
  }

  ngOnInit(): void { }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void { }

  onValueChanged(event: any): void {
    this.propagateChanges(event.target.value);
  }
}

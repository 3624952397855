export enum OverUnderDesignType {
  timeEntry = 1,
  report = 2
}

import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import { colors } from 'app/shared/values/colours';

@Component({
  selector: 'hub-over-under',
  templateUrl: './over-under.component.html',
  styleUrls: ['./over-under.component.scss']
})
export class OverUnderComponent implements OnInit, OnChanges {
  title: string;

  @Input()
  overUnder: number;
  @Input()
  design?: OverUnderDesignType;
  overUnderDesign: typeof OverUnderDesignType;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.overUnderDesign = OverUnderDesignType;
    this.design = this.design ? this.design : OverUnderDesignType.timeEntry;
    this.initalise();
  }

  initalise(): void {
    if (this.design === 1) {
      this.elRef.nativeElement.style.backgroundColor = this.overUnder >= 0 ? colors.positive : colors.assertive;
    }
    this.title = this.overUnder >= 0 ? 'Under' : 'Over';
  }

  ngOnChanges(changes: any): void {
    this.initalise();
  }
}

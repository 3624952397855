import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-site-camera',
  templateUrl: './site-camera.component.html',
  styleUrls: ['./site-camera.component.scss']
})
export class SiteCameraComponent implements OnInit {

  private _cameraAllowed: boolean;
  tooltipMessage: string;
  icon: string;
  status: string;
  @Input()
  set cameraAllowed(cameraAllowed: boolean) {
    this._cameraAllowed = cameraAllowed;
    this.isCameraAllowed();
  }

  constructor() { }

  ngOnInit(): void {
    this.isCameraAllowed();
  }

  isCameraAllowed(): void {
    if (!this._cameraAllowed) {
      this.status = 'Not Permitted';
      this.icon = 'fa-camera text-assertive';
      this.tooltipMessage = 'Cameras are not permitted on site so all app photo fields will be optional.';
    } else {
      this.status = 'Permitted';
      this.icon = 'fa-camera text-positive';
      this.tooltipMessage = 'Cameras are allowed on site so some app photo fields will be mandatory.';
    }
  }

}




import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportEndpointService, SiteQuery, SiteWebAppData } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class ReportSitesQueryService extends QueryService<SiteWebAppData[]> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'report sites';

  constructor(private reportEndpointService: ReportEndpointService) { super(); }

  reportSitesQuery(useCached: boolean): Observable<SiteWebAppData[]> {
    return super.query(useCached, this.key);
  }

  reportSitesDataChanges(): Observable<SiteWebAppData[]> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<SiteWebAppData[]> {
    return this.reportEndpointService.GetSiteData(new SiteQuery());
  }
}

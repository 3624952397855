import { Injectable } from '@angular/core';

@Injectable()
export class WindowScrollingService {
  private styleTag: HTMLStyleElement;

  constructor() {
    this.styleTag = this.buildStyleElement();
  }
  // I disable the scrolling feature on the main viewport.
  public disable(): void {
    document.body.appendChild(this.styleTag);
  }

  // I re-enable the scrolling feature on the main viewport.
  public enable(): void {
    const element = document.getElementById('overflow');
    if (element) {
      document.body.removeChild(this.styleTag);
    }
  }

  // I build and return a Style element that will prevent scrolling on the body.
  private buildStyleElement(): HTMLStyleElement {
    const style = document.createElement('style');

    style.type = 'text/css';
    style.id = 'overflow';
    style.textContent = `
          body {
              overflow: hidden !important ;
          }
      `;

    return style;
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LoadingSpinnerService } from './shared/services/loading-spinner.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'environments/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import userflow from 'userflow.js'
import { LoggedInStaffService } from './core/services/logged-in-staff-service';
import { UserflowService } from './core/authentication/services/userflow.service';
import { CompanySettingsService } from './shared/services/company-setting.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'hub-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  loading: Boolean;

  constructor(
    private router: Router,
    private loadingSpinner: LoadingSpinnerService,
    // private mapsLoader: MapsAPILoader,
    private loader: LoadingBarService,
    private loggedInStaffService: LoggedInStaffService,
    private userflowService: UserflowService,
    private companySettingsService: CompanySettingsService,
  ) {
    setTheme('bs3'); // or 'bs4'
  }

  ngOnInit(): void {
    this.startUserflow();
    this.loadApplicationInsights();
    this.loadingSpinner.show();
    this.loadingSpinner.loading.subscribe(state => {
      this.loading = state;
    });

    // Dynamically load the Google Maps API script with the API key from the environment file.
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;
    document.body.appendChild(script);

    // In the general case we want to scroll to the top of the page on out changes as in a traditional website,
    // but there are some exceptions, eg. when the route change corresponds to simply changing a section of the page content.
    const scrollExceptions = [
      /\/diary\/.*\/audio\//,
      /\/diary\/.*\/video\//,
      /\/diary\/.*\/image\//,
      /\/diary\/.*\/transcription\//
    ];
    const ref = this.loader.useRef('global');

    this.router.events.subscribe(event => {
      // triggers the global loading bar when a route change starts
      if (event instanceof NavigationStart) {
        ref.start();
      }

      // completes the global loading bar when a route change ends
      if (event instanceof NavigationError || event instanceof NavigationCancel) {
        ref.complete();
      }

      if (event instanceof NavigationEnd) {
        // Hide app loading spinner in case it is not already hidden.
        // this.loading = false;
        this.loadingSpinner.hide();
        // Scroll to top of screen.
        if (!scrollExceptions.some(e => e.test(event.urlAfterRedirects))) {
          window.scrollTo(0, 0);
        }
        const results$ = combineLatest([
          this.companySettingsService.getCompanyDetailsFromServer(),
          this.loggedInStaffService.loggedInStaffChanges(),
        ]);
        results$.subscribe({
          next: async res => {
            const clientData = res[0];
            const loggedInStaff = res[1];
            if (loggedInStaff !== null && clientData !== null) {
              // Identify the user in Userflow. This needs to be started before each page is loaded
              this.userflowService.identifyUser(
                loggedInStaff.StaffId,
                {
                  name: loggedInStaff.ContactName,
                  email: loggedInStaff.EmailAddress,
                  signed_up_at: new Date().toISOString(),
                  company_id: clientData.clientId,
                  company_name: clientData.clientName
                }
              );
            }
          },
          error: error => {
            console.error('ERROR: Unable to identify user on userflow:', error);
            throw error;
          }
        });
        ref.complete();
      }
    });

    // Set up HTTP interceptors here.
    // this.httpInterceptorService.response().addInterceptor(httpErrorResponseInterceptor);
  }

  /**
  * Initializes the userflow and identifies the logged-in staff member.
  */
  private startUserflow() {
    userflow.init(environment.userflowKey);
  }

  /**
  * This function loads the application insights plugin into the application.
  * This plugin enables the application to send telemetry to Azure Application Insights.
  *
  * @memberof AppComponent
  */
  public loadApplicationInsights() {
    // var angularPlugin = new AngularPlugin();
    // const appInsights = new ApplicationInsights({
    //   config: {
    //     instrumentationKey: environment.appInsights.ingestionEndpoint,
    //     extensions: [angularPlugin],
    //     extensionConfig: {
    //       [angularPlugin.identifier]: { router: this.router }
    //     }
    //   }
    // });
    // appInsights.loadAppInsights();
  }
}




import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimesheetDepotsQuery, TimesheetEndpointService, TimesheetDepotData } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class TimesheetDepotsQueryService extends QueryService<TimesheetDepotData[]> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'timesheet-depots';

  constructor(private timesheetEndpointService: TimesheetEndpointService) { super(); }

  timesheetDepotsQuery(useCached: boolean): Observable<TimesheetDepotData[]> {
    return super.query(useCached, this.key);
  }

  timesheetDepotsDataChanges(): Observable<TimesheetDepotData[]> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<TimesheetDepotData[]> {
    return this.timesheetEndpointService.TimesheetDepotsQuery(new TimesheetDepotsQuery());
  }
}

import { DepotsQueryService } from '../../../core/services/depots-query.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { errorMessages } from '../../../shared/values/error-messages';
import { mapValues } from '../../../shared/values/maps';
import { AddEditDepotsModalContext } from '../../models/add-edit-depots-modal-context';
import { forkJoin, Subject } from 'rxjs';
import { LoggedInStaffService } from '../../../core/services/logged-in-staff-service';
import { StaffsQueryService } from '../../../core/services/staffs-query.service';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DepotData, SiteDepotCommandService, CreateDepotCommand, EditDepotCommand } from 'app/core/hub-api';

@Component({
  selector: 'hub-add-edit-depots-modal',
  templateUrl: './add-edit-depots-modal.component.html',
  styleUrls: ['./add-edit-depots-modal.component.scss']
})
export class AddEditDepotsModalComponent extends FormComponent implements OnInit {
  editMode: boolean;
  context: Partial<AddEditDepotsModalContext>;
  depots: DepotData[];
  depot: DepotData;

  form: UntypedFormGroup;
  depotNameFormControl: UntypedFormControl;
  depotAddressFormControl: UntypedFormControl;
  emailAddressFormControl: UntypedFormControl;
  phoneNumberFormControl: UntypedFormControl;

  validationMessages = {
    depotName: {
      required: errorMessages.required
    },
    emailAddress: {
      required: errorMessages.required,
      pattern: errorMessages.emailInvalid
    }
  };

  constructor(
    public depotsQueryService: DepotsQueryService,
    public siteDepotCommandService: SiteDepotCommandService,
    public loggedInStaffService: LoggedInStaffService,
    public staffsQueryService: StaffsQueryService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState as Partial<AddEditDepotsModalContext>;
    this.editMode = this.context.editMode;

    this.depot = this.context.depot || this.getNewDepot();

    this.depotNameFormControl = new UntypedFormControl(this.depot.DepotName, [Validators.required]);
    this.depotAddressFormControl = new UntypedFormControl(this.depot.DepotAddress);
    this.emailAddressFormControl = new UntypedFormControl(this.depot.EmailAddress, [Validators.required, Validators.pattern(emailAddressRegex)]);
    this.phoneNumberFormControl = new UntypedFormControl(this.depot.PhoneNumber);
    this.form = new UntypedFormGroup({
      depotName: this.depotNameFormControl,
      depotAddress: this.depotAddressFormControl,
      emailAddress: this.emailAddressFormControl,
      phoneNumber: this.phoneNumberFormControl
    });
    super.ngOnInit();
  }

  getNewDepot(): DepotData {
    return {
      SiteDepotId: UUID.UUID(),
      Staff: [],
      DepotName: '',
      DepotAddress: '',
      Latitude: mapValues.defaultLatitude,
      Longitude: mapValues.defaultLongitude,
      PhoneNumber: '',
      EmailAddress: '',
      Deleted: false
    };
  }

  onSubmit(formValues): void {
    if (this.validateForm()) {
      this.saveInProgress = true;

      const command: CreateDepotCommand | EditDepotCommand = {
        SiteDepotId: this.depot.SiteDepotId,
        DepotName: formValues.depotName,
        DepotAddress: formValues.depotAddress,
        Latitude: this.depot.Latitude,
        Longitude: this.depot.Longitude,
        PhoneNumber: formValues.phoneNumber,
        EmailAddress: formValues.emailAddress,
        Deleted: this.depot.Deleted
      };

      const result = this.editMode
        ? this.siteDepotCommandService.EditDepotCommand(command)
        : this.siteDepotCommandService.CreateDepotCommand(command);

      result.subscribe(() => {
        const obs$ = forkJoin([
          this.depotsQueryService.depotsQuery(false),
          this.loggedInStaffService.loggedInStaffQuery(false),
          this.staffsQueryService.staffsQuery(false)
        ]);
        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.modalService.setDismissReason(this.depot.SiteDepotId);
          this.bsModalRef.hide();
        });
      });
    }
  }
}

<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ ActivityDetail?.SiteDetails.CustomerName }} - {{ ActivityDetail?.SiteDetails.SiteName }} ({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})
    </h4>
    <a class="pull-right" (click)="dismiss()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"><img src="{{ (ActivityType | idToActivity: [true, ActivityDetail?.ScaffoldInspections.InspectionOutcomeId] ).icon }}" class="modal-content-icon" />
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Site: </span>{{ ActivityDetail?.SiteDetails.SiteName }} ({{ SiteReference }})</li>
                <li><span class="bold">Address: </span>{{ ActivityDetail?.SiteDetails.SiteAddress }}</li>
                <li><span class="bold">Customer: </span>{{ ActivityDetail?.SiteDetails.CustomerName }}</li>
                <li><span class="bold">Area: </span>{{ ActivityDetail?.ScaffoldDetails.SiteAreaName }}</li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Scaffold: </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldName }}</li>
                <li>
                  <span class="bold" *ngIf="ActivityDetail?.ScaffoldDetails.Location">Scaffold Name: </span
                  >{{ ActivityDetail?.ScaffoldDetails.Location }}
                </li>
                <li>
                  <span class="bold" *ngIf="ActivityDetail?.ScaffoldDetails.ScaffoldDescription">Scaffold Description: </span
                  >{{ ActivityDetail?.ScaffoldDetails.ScaffoldDescription }}
                </li>
                <li><span class="bold">Date: </span>{{ ActivityDetail?.ScaffoldInspections.DateOfInspection | date: 'MMM dd, yyy' }}</li>
                <li><span class="bold">Time: </span>{{ ActivityDetail?.ScaffoldInspections.DateOfInspection | date: 'hh:mm a' }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          <div class="panel" [ngClass]="{'panel-success': !isUnsafe, 'panel-danger': isUnsafe}">
            <div class="panel-heading">
              <h4 class="panel-title">Inspection Details</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div *ngIf="scaffoldInspectionMedia.length" class="col-sm-3 carousel">
                  <hub-image-carousel [media]="scaffoldInspectionMedia" height="100px"></hub-image-carousel>
                </div>
                <div class="my-1" [ngClass]="!scaffoldInspectionMedia.length ? 'col-md-12' : 'col-sm-9'">
                  <div class="row">
                    <div class="col-sm-12 my-1" *ngIf="Outcome?.comments"><span class="bold">Comments: </span>
                      {{ Outcome?.comments }}</div>
                    <div class="col-sm-12 my-1"><span class="bold">Outcome: </span> {{ Outcome?.title }}</div>
                    <div class="col-sm-12 my-1"><span class="bold">Inspection Type: </span> {{ Outcome?.type }}</div>
                    <!-- TODO: needs further information checking -->
                    <div class="col-sm-12 my-1">
                      <span class="bold">Inspected By: </span>{{ ActivityDetail?.ScaffoldInspections.Inspector }}
                      <span *ngIf="ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition?.length">({{ ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition }})</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="Defects !== null && Defects !== undefined && Defects?.length > 0">
        <div class="row" *ngFor="let defect of Defects">
          <div class="col-sm-12">
            <div class="panel panel-danger">
              <div class="panel-heading">
                <h4 class="panel-title">{{ defect?.DefectName }} {{ defect?.DefectTypeFurtherInformation }}</h4>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="carousel" [ngClass]="defect?.DefectStatus && !defect?.Media.length ? 'hidden' : 'col-sm-3'">
                    <hub-image-carousel [media]="defect?.Media" height="100px"></hub-image-carousel>
                  </div>
                  <div class="my-1" *ngIf="defect?.DefectStatus" [ngClass]="defect?.DefectStatus && !defect?.Media.length ? 'col-md-12' : 'col-sm-9'">
                    <span class="bold">Defect Status: </span> <i
                      class="fa {{ (defect.DefectStatus | defectResults).icon }}"></i>
                    {{ (defect?.DefectStatus | defectResults).title }}
                  </div>
                  <div class="col-sm-9 my-1" *ngIf="defect?.Comments"><span class="bold">Comment: </span>
                    {{ defect?.Comments }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row" *ngIf="InspectionFollowUpInstructions?.Instructions">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Inspection Follow Up</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 my-1" *ngIf="InspectionFollowUpNotifys?.InspectorsRecommendations">
                  <span class="bold">Inspectors Recommendations: </span>
                  {{ InspectionFollowUpNotifys?.InspectorsRecommendations }}
                </div>
                <div class="col-sm-12 my-1" *ngIf="notifyPeople">
                  <span class="bold">Defects Reported To: </span>
                    {{ notifyPeople.join(', ') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="InspectionFollowUpInstructions?.Instructions">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Remedial Actions</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 my-1" *ngIf="staffFromActionToBeCarriedOutBy">
                  <span class="bold">Action to be carried out by: </span>
                  <ng-container *ngFor="let staff of staffFromActionToBeCarriedOutBy; let i = index">
                    <span class="bold">{{ staff.ContactName }}</span><ng-container *ngIf="staff.CompanyPosition"> ({{ staff.CompanyPosition }})</ng-container>
                    <ng-container *ngIf="i < staffFromActionToBeCarriedOutBy.length - 1">, </ng-container>
                  </ng-container>
                </div>
                <div class="col-sm-12 my-1" *ngIf="InspectionFollowUpInstructions?.Instructions">
                  <span class="bold">Action Required: </span> {{ InspectionFollowUpInstructions?.Instructions }}
                </div>
                <div class="col-sm-12 my-1" *ngIf="InspectionFollowUpInstructions.ToBeDoneBy">
                  <span class="bold">Defect To be Rectified by: </span>
                  {{ InspectionFollowUpInstructions?.ToBeDoneBy | date: 'MMM dd, yyy - hh:mm a' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="ActivityDetail?.ScaffoldInspections.Inspector">
        <div class="col-sm-12">
          <div class="panel" [ngClass]="{'panel-success': !isUnsafe, 'panel-danger': isUnsafe}">
            <div class="panel-heading">
              <h4 class="panel-title">Inspected by</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <div>
                    <!-- TODO: ADD inspectors signature -->
                    <hub-signatures-svg [data]="ActivityDetail?.ScaffoldInspections.Data" width="100">
                    </hub-signatures-svg>
                  </div>
                  <div>
                    <span class="bold">Staff: </span>{{ ActivityDetail?.ScaffoldInspections.Inspector }}
                    <span *ngIf="ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition?.length">({{ ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition }})</span>
                  </div>
                  <div><span class="bold">Date:
                    </span>{{ ActivityDetail?.ScaffoldInspections.DateOfInspection | date: 'MMM dd, yyy - hh:mm a' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer"><button class="btn btn-large button-primary pull-right" (click)="dismiss()">Close</button>
      <hub-download-report-button *ngIf="activateDownloadReport" [ActivityType]="ActivityType" ID="{{ActivityDetail.RelativeInspectionId}}" [ActivityDetail]="ActivityDetail"></hub-download-report-button>
    </div>
  </div>
</div>

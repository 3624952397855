import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowScrollingService } from './window-scrolling.service';

@Injectable()
export class LoadingSpinnerService {
  private loadingSource = new BehaviorSubject<Boolean>(false);
  loading = this.loadingSource.asObservable();

  constructor(private scrollingService: WindowScrollingService) {}

  show(): void {
    this.loadingSource.next(true);
    this.scrollingService.disable();
  }

  hide(): void {
    this.loadingSource.next(false);
    this.scrollingService.enable();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractData, SiteContractInspectionStatusQuery, SiteEndpointService } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

@Injectable()
export class ContractInspectionStatusQueryService extends QueryService<ContractData[]> {

  constructor(private siteEndpointService: SiteEndpointService) { super(); }

  contractInspectionStatusQuery(useCached: boolean, siteId: string): Observable<ContractData[]> {
    return super.query(useCached, siteId);
  }

  contractInspectionStatusDataChanges(siteId: string): Observable<ContractData[]> {
    return super.cachedDataChanges(siteId);
  }

  protected getDataFromServer(siteId: string): Observable<ContractData[]> {
    const query = Object.assign(new SiteContractInspectionStatusQuery(), { SiteId: siteId });
    return this.siteEndpointService.SiteContractInspectionStatusQuery(query);
  }
}

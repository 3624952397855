import { StaffsQueryService } from '../services/staffs-query.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { StaffData } from 'app/core/hub-api';

// Resolves all staffs, exluding the CADS admin staff.
@Injectable()
export class StaffsResolver implements Resolve<StaffData[]> {

  constructor(private staffsQueryService: StaffsQueryService) { }

  resolve(): Observable<StaffData[]> {
    return this.staffsQueryService.staffsQuery(false);
  }
}

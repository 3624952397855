import '../rxjs-imports';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { InsightMonitoringService } from './core/services/insight-monitoring.service';
import { InsightBaseComponent } from './core/components/insight/insight-base.component';
import { ActivityModule } from './activity/activity.module';
import { SharedModule } from './shared/shared.module';
import { AngularWebStorageModule } from 'angular-web-storage';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { EstimatesModule } from './estimates/estimates.module';
import { EstimatesService } from './estimates/services/estimates-service';

@NgModule({
  declarations: [
    AppComponent,
    InsightBaseComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ActivityModule,
    SharedModule,
    AngularWebStorageModule,
    EstimatesModule,
  ],
  providers: [
    InsightMonitoringService,
    EstimatesService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
  }
}

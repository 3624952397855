import { roleIds } from 'app/shared/values/role-ids';
import { DepotData, StaffDetailsWebAppData, SiteWebAppData } from 'app/core/hub-api';
import { map, find, sortBy, chain } from 'lodash';
import { siteStatusIds } from '../../shared/values/site-status-ids';

export class FilterService {

    // Only display the sites that belong to the depots that the user has access to
    // this.filterSites = function(Sites, loggedInStaff) {
    //     var sites = angular.copy(Sites);
    //     if (loggedInStaff.RoleId !== RoleIds.admin) {
    //         sites = _.filter(sites, function(site) {
    //             return _.find(loggedInStaff.SiteDepotIds, function(id) {
    //                 return site.SiteDepotId === id;
    //             });
    //         });
    //     }
    //     return _.sortBy(sites, function(site) {
    //         return site.SiteName.toLowerCase();
    //     });
    // };

    // Only display the depots that the logged in user has access to
    filterDepots(siteDepots: DepotData[], loggedInStaff: StaffDetailsWebAppData): DepotData[] {
        if (loggedInStaff.RoleId !== roleIds.admin) {
            siteDepots = siteDepots.filter(depot => find(loggedInStaff.SiteDepotIds, id => depot.SiteDepotId === id));
        }
        return sortBy(siteDepots, function (depot: DepotData): string {
            return depot.DepotName ? depot.DepotName.toLowerCase() : '';
        });
    }

    // Only display the staff that belong to the site depot
    // this.filterStaff = function(Staff, siteDepotId) {
    //     var staffList =  _.filter(Staff, function(staff) {
    //         return _.contains(staff.SiteDepotIds, siteDepotId);
    //     });
    //     return _.sortBy(staffList, function(staff){
    //         return staff.ContactName.toLowerCase();
    //     });
    // };

    // this.filterCustomer = function (Sites, Customers, loggedInStaff) {
    //     if (loggedInStaff.RoleId === RoleIds.admin) {
    //         return Customers;
    //     }
    //     var customers = angular.copy(Customers);
    //     customers = _.filter(customers, function(customer) {
    //         return _.find(Sites, function(site){
    //             return _.contains(site.Customers, customer.CustomerId);
    //         });
    //     });
    //     return customers;
    // };

    filterClosedSites(sites: SiteWebAppData[]): SiteWebAppData[] {
        return chain(sites)
            .filter(function (site: SiteWebAppData): boolean {
                return site.SiteStatusId !== siteStatusIds.closed;
            })
            .sortBy(function (site: SiteWebAppData): string {
                return site.SiteName.toLowerCase();
            })
            .value();
    }
    sortByIgnoreCase(dataSource: any, sortByName: string): any {
        if (dataSource && sortByName && dataSource[0][sortByName]) {// Exist sortBy "value" in dataSource
            return dataSource.sort((a, b) => (a[sortByName].toLowerCase() > b[sortByName].toLowerCase()) ? 1 : ((b[sortByName].toLowerCase() > a[sortByName].toLowerCase()) ? -1 : 0));
        } else {
            return dataSource;
        }
    }
}

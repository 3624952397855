<form [formGroup]="form" [ngClass]="{'custom-select': requiresFurtherInfo }">
  <ng-select [items]="options" [bindLabel]="titleProperty" [bindValue]="idProperty" [searchable]="true"
    formControlName="id" [placeholder]="optionPlaceholder">

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <ng-container *ngIf="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:itemsToDisplay">
          <span class="ng-value-label text-and-icon">
            <i *ngIf="icon" class="fa {{icon}}"></i> {{item[titleProperty]}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > itemsToDisplay">
          <span class="ng-value-label">{{getLengthSelected()}}</span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="dropdown-finder">
        <p class="text-and-icon">
          <i *ngIf="icon" class="fa {{icon}}"></i> <span [ngOptionHighlight]="search">{{item[titleProperty]}}</span>
        </p>
        <p class="description">
          <i *ngIf="descriptionIcon" class="fa {{descriptionIcon}}"></i> <span
            [ngOptionHighlight]="search">{{item[description]}}</span>
        </p>
      </div>
    </ng-template>
  </ng-select>

  <div *ngIf="requiresFurtherInfo" class="inline"><span class="large-brackets">(</span>
    <input formControlName="furtherInfo" class="form-control input-underline" type="text"
      placeholder="{{furtherInfoPlaceholder}}" (blur)="propagateTouched()" ngxTrim><span class="large-brackets">)</span>
  </div>
</form>
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hub-signatures-svg',
    templateUrl: './signatures-svg.component.html',
    styleUrls: ['./signatures-svg.component.scss']
})
export class SignaturesSvgComponent implements OnInit {
    @Input() data;
    @Input() width?;

    path: string;
    svgWidth: string;

    constructor() { }

    ngOnInit(): void {
        this.svgWidth = this.width ? this.width : '100%';
        this.decodeSvgSignatures(this.data);
    }

    decodeSvgSignatures(data: any[]): any {
        if (data) {
            const points = data;
            let draw = '';

            let lastX = null;
            let lastY = null;
            if (points) {
                for (let indx = 0; indx < points.length; indx++) {

                    const lX = points[indx][0];
                    const lY = points[indx][1];
                    const cX = points[indx][2];
                    const cY = points[indx][3];

                    if (lastX !== lX || lastY !== lY) {
                        draw = draw + ' M ' + lX + ' ' + lY;
                    }
                    draw = draw + ' L ' + cX + ' ' + cY;
                    lastX = cX;
                    lastY = cY;
                }
            }
            this.path = draw;
        }
        return;
    }

}

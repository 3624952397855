<div class="form-group" [ngClass]="{'has-error': control.touched && validationErrors[name] }">
  <label class="form-group-title-text">
    <span *ngIf="required">*</span>{{ label }}
    <div *ngIf="tooltipText" class="form-group-tooltip-text tooltip-circle"><i class="fa fa-info-circle"
        placement="{{tooltipPlacement ? tooltipPlacement : 'top'}}" tooltip="{{tooltipText}}"></i></div>
  </label>

  <ng-content></ng-content>
  <div *ngIf="validationErrors[name] && control.touched" class="form-group-validation-text text-error">
    <i class="fa fa-warning"></i> {{ validationErrors[name] }}
  </div>
</div>
import { ScaffoldStatisticsData } from 'app/core/hub-api';
import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { colors } from '../../values/colours';

@Component({
  selector: 'hub-handovers-scaffold-status-chart',
  templateUrl: './handovers-scaffold-status-chart.component.html',
  styleUrls: ['./handovers-scaffold-status-chart.component.scss']
})
export class HandoversScaffoldStatusChartComponent implements OnInit, OnChanges {

  @Input()
  scaffoldData: ScaffoldStatisticsData;

  @Output() selectedLegend = new EventEmitter();

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;
  selectedLeg: any;


  chartConfig: Highcharts.Options = {
    credits: { enabled: false },
    title: {
      text: '',
      y: 15,
      style: {
        color: colors.river
      }
    },
    subtitle: {
      text: 'Total Scaffolds',
      y: 30
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Total'
      }
    },
    xAxis: {
      categories: [
        '', '', ''
      ],
    },
    tooltip: {},
    chart: {
      type: 'column',
    },
    colors: [
      colors.calm,
      colors.positive,
      colors.tomato
    ],
    plotOptions: {
      series: {
        events: {
          legendItemClick: (e) => {
            // Gets the selected chart legend
            const point1 = (this.chart.series[0] as any).points[0];
            const point2 = (this.chart.series[1] as any).points[0];
            const point3 = (this.chart.series[2] as any).points[0];

            const points = [
              { name: point1.series.name.replace(/\s/g, ''), visible: point1.series.visible },
              { name: point2.series.name.replace(/\s/g, ''), visible: point2.series.visible },
              { name: point3.series.name.replace(/\s/g, ''), visible: point3.series.visible }
            ];

            this.selectedLeg = points.filter(s => s.visible).map(s => s.name);
            if ((e.target as any).visible) {
              const index = this.selectedLeg.findIndex(s => s === (e.target as any).name);
              this.selectedLeg.splice(index, 1);
            } else {
              this.selectedLeg.push((e.target as any).name);
            }
            this.selectedLegend.emit(this.selectedLeg);
          }
        },
        showInLegend: true,
      },
      column: {},
    },
    series: [],
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scaffoldData'] && !!this.chart) {
      this.updateChartData();
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('handoversScaffoldStatusChart', this.chartConfig);
    this.updateChartData();
  }

  updateChartData(): void {
    if (this.chart) {
      while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

      this.chart.addSeries({
        name: 'Incomplete',
        type: 'column',
        data: [this.scaffoldData.TotalScaffoldsIncomplete]

      });

      this.chart.addSeries({
        name: 'On Hire',
        type: 'column',
        data: [this.scaffoldData.TotalScaffoldsOnHire]
      });

      this.chart.addSeries({
        name: 'Off Hire',
        type: 'column',
        data: [this.scaffoldData.TotalScaffoldsOffHire]

      });

      const points = [
        (this.chart.series[0] as any).points[0].series.name.replace(/\s/g, ''),
        (this.chart.series[1] as any).points[0].series.name.replace(/\s/g, ''),
        (this.chart.series[2] as any).points[0].series.name.replace(/\s/g, '')
      ];

      this.selectedLegend.emit(points);

      this.changeDetectorRef.detectChanges();

      this.chart.setTitle({ text: this.scaffoldData.TotalScaffolds.toString() }, undefined, false);
      this.chart.redraw();
    }
  }

}

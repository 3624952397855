import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { mediaTypes } from 'app/sites/values/media-types';
import {
  DiaryData,
  EditScaffoldMediaCommand,
  EditScaffoldMediaTranscriptionCommand,
  MediaBaseData,
  MediaTranscriptionData,
  ScaffoldCommandService,
  SiteCommandService,
  EditSiteDiaryMediaCommand,
  DiaryQuery,
} from 'app/core/hub-api';
import { DeleteDiaryMediaModalContext } from '../../../../models/delete-diary-media-modal-context';
import { Observable } from 'rxjs';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-scaffold-diary-media-modal',
  templateUrl: './delete-diary-media-modal.component.html',
  styleUrls: ['./delete-diary-media-modal.component.scss']
})
export class DeleteScaffoldDiaryMediaModalComponent extends FormComponent implements OnInit {
  context: Partial<DeleteDiaryMediaModalContext>;
  form: UntypedFormGroup;
  validationMessages: { [key: string]: { [key: string]: string; }; };
  media: MediaBaseData;
  scaffoldDiary: DiaryData;
  saveInProgress = false;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private scaffoldCommandService: ScaffoldCommandService,
    private siteCommandService: SiteCommandService,
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.media = this.context.media;
    this.scaffoldDiary = this.context.diary;
  }

  onSubmit(): void {
    this.saveInProgress = true;
    const commandResponse = (this.context.diary.DiaryType === 0) ? this.editSiteDiary() : this.editScaffoldDiary();

    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = this.scaffoldDiary.DiaryId;
    diaryQuery.DiaryType = this.scaffoldDiary.DiaryType;

    commandResponse.subscribe(() => {
      this.saveInProgress = false;
      this.bsModalService.setDismissReason(this.media.MediaReferenceId);
      this.bsModalRef.hide();

    });
  }

  editScaffoldDiary(): Observable<any> {
    const editScaffoldMediaCommand: EditScaffoldMediaCommand | EditScaffoldMediaTranscriptionCommand = {
      ScaffoldId: this.context.id,
      MediaReferenceId: this.media.MediaReferenceId,
      Title: this.media.Title,
      Transcription: (this.media as MediaTranscriptionData).Transcription,
      Deleted: true,
    };

    const commandResponse = this.media.Type === mediaTypes.transcription
      ? this.scaffoldCommandService.EditScaffoldMediaTranscriptionCommand(editScaffoldMediaCommand)
      : this.scaffoldCommandService.EditScaffoldMediaCommand(editScaffoldMediaCommand);
    return commandResponse;
  }

  editSiteDiary(): Observable<any> {
    const editSiteMediaCommand: EditSiteDiaryMediaCommand = {
      SiteId: this.context.id,
      MediaReferenceId: this.media.MediaReferenceId,
      Title: this.media.Title,
      Deleted: true,
    };

    return this.siteCommandService.EditSiteDiaryMediaCommand(editSiteMediaCommand);
  }

}

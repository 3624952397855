// Here you declare the global interfaces then you need to create or use one of the existing
// This will make the extensions available all over the smart manager

declare const require: any;
require('./sort.extension');

export { }; // this is required so angular does not recognise this page as a module

declare global {
    interface Array<T> {
        sortAlphaNumeric(fieldName: string): Array<T>;
    }
    interface Navigator {
        msSaveBlob: (blobOrBase64: Blob | string, filename: string) => boolean;
    }
}

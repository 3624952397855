<form name="siteForm" [formGroup]="form">
  <hub-modal-form-header [title]="editMode ? 'Edit Site' : 'Add Site'" (close)="closeModal()"></hub-modal-form-header>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <fieldset>
          <hub-form-group label="Site Name" name="siteName" [control]="siteNameFormControl"
            [validationErrors]="validationErrors">
            <input type="text" class="form-control" formControlName="siteName" ngxTrim>
          </hub-form-group>
          <hub-form-group label="Location" name="siteAddress" [control]="siteAddressFormControl"
            [validationErrors]="validationErrors">
            <hub-places-input formControlName="siteAddress" (placeSelected)="onPlaceSelected($event)">
            </hub-places-input>
          </hub-form-group>

          <hub-form-group label="Depot" name="siteDepotId" [control]="siteDepotIdFormControl"
            [validationErrors]="validationErrors">
            <hub-dropdown formControlName="siteDepotId" [items]="depots" idProperty="SiteDepotId"
              titleProperty="DepotName" icon="fa fa-building text-positive"
              (valueChanges)="onSelectedDepotsChanged($event)" placeholder="Choose a depot">
            </hub-dropdown>
          </hub-form-group>

          <hub-form-group name="camerasAllowed" [control]="camerasAllowedFormControl"
            [validationErrors]="validationErrors">
            <label for="camerasAllowed" class="control-label text-left">
              Cameras permitted on site?
              <i class="fa fa-info-circle pull-right"
                tooltip="If 'no' then photos will be made optional on the mobile apps" tooltip-placement="right"></i>
            </label>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </hub-form-group>

          <hub-form-group name="stockAllowed" [control]="stockAllowedFormControl" [validationErrors]="validationErrors">
            <label for="stockAllowed" class="control-label text-left">
              Manage stock control for this site?
              <i class="fa fa-info-circle pull-right"
                tooltip="Scaffolding material tonnage is tracked on site, so some app tonnage fields will be mandatory."
                tooltip-placement="right"></i>
            </label>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="stockAllowed" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="stockAllowed" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </hub-form-group>
        </fieldset>
      </div>

      <div class="col-md-5">
        <div class="google-map-wrapper">
          <google-map width="100%" height="100%" [zoom]="map.zoom" [center]="{ lat: map.latitude, lng: map.longitude }"
            [options]="{ scrollwheel: false }">
            <map-marker [position]="{ lat: map.latitude, lng: map.longitude }" [options]="{ draggable: true }"
              (mapDragend)="onMarkerMoved($event)"></map-marker>
          </google-map>
        </div>
      </div>

    </div>

    <div class="row tonnage-container" [hidden]="!form.value.stockAllowed" *ngIf="isStockControlEnable">

      <div class="col-md-4 scaffold-system">
        <ul>
          <li *ngFor="let scaffoldingSystem of scaffoldingSystems" (click)="toggle(scaffoldingSystem)">
            <a
              [ngStyle]="{'color':scaffoldingSystem.ScaffoldingSystemId === selectedScaffoldingSystemid ? 'green' : 'black'}">
              {{scaffoldingSystem.Title}}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-8 tonnage-options">
        <ul formArrayName="tonnage">
          <li *ngFor="let scaffoldingSystem of tonnage.controls; let i = index" formGroupName="{{i}}">
            <div [hidden]="!toggleScaffoldingSystem[scaffoldingSystem.controls.scaffoldingSystemId.value]">
              <div class="col-md-12">
                <h4 class="tonnage-title">{{scaffoldingSystem.controls.scaffoldingSystemName.value}}</h4>
              </div>
              <div [ngClass]="editMode ? 'col-md-6' : 'col-md-12'">
                <hub-form-group label="Total Tonnage" name="totalTonnage">
                  <input type="number" class="form-control" formControlName="totalTonnage" placeholder="0.00" min="0"
                    (keyup)="updateIdleRackTonnage($event,scaffoldingSystem)" ngxTrim>
                  <div *ngIf="scaffoldingSystem.controls.totalTonnage?.errors?.invalidValue"
                    class="text-error ng-star-inserted">
                    <i class="fa fa-warning"></i> <span>{{invalidValueMessage}}</span>
                  </div>
                </hub-form-group>
                <hub-form-group *ngIf="editMode" label="Allocated Tonnage" name="allocatedTonnage"
                  [validationErrors]="validationErrors">
                  <input type="number" class="form-control" formControlName="allocatedTonnage" placeholder="0.00"
                    min="0" [attr.disabled]="true" ngxTrim readonly>
                </hub-form-group>
              </div>
              <div class="col-md-6" *ngIf="editMode">
                <hub-form-group label="On-hire Erected Tonnage" name="onHireErectedTonnage"
                  [validationErrors]="validationErrors">
                  <input type="number" class="form-control" formControlName="onHireErectedTonnage" placeholder="0.00"
                    min="0" [attr.disabled]="true" ngxTrim readonly>
                </hub-form-group>
                <hub-form-group [ngClass]="scaffoldingSystem.value.idleRackTonnage < 0 ? 'negative-value' : ''"
                  label="Idle Rack Tonnage" name="idleRackTonnage" [validationErrors]="validationErrors">
                  <input type="number" class="form-control" formControlName="idleRackTonnage" placeholder="0.00"
                    [attr.disabled]="true" ngxTrim readonly>
                </hub-form-group>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <div class="options">
      <!-- Close/Reopen Site -->
      <div *ngIf="editMode" class="form-group modal-options">
        <!--- Close Site -->
        <p *ngIf="siteIsOpen() && siteWasOpen && closeSiteValid" class="animate-fade"><a (click)="closeSite()"
            class="text-danger"><i class="fa fa-times" name="SiteStatusId" id="SiteStatusId"></i> Close site</a></p>
        <p class="animate-fade" *ngIf="!siteIsOpen() && siteWasOpen"><i class="fa fa-check"></i> The site will be
          closed. Click <a (click)="openSite()" class="text-danger">undo</a> if this was a mistake!</p>
        <p *ngIf="!closeSiteValid" class="animate-fade text-danger"><i class="fa fa-warning"></i> All
          <strong>contracts</strong> must be closed before closing the site
        </p>
        <!--- Open Site -->
        <p *ngIf="!siteIsOpen() && !siteWasOpen" class="animate-fade"><a (click)="openSite()" class="text-danger"><i
              class="fa fa-times" name="SiteStatusId" id="SiteStatusId"></i> Open site</a></p>
        <p class="animate-fade" *ngIf="siteIsOpen() && !siteWasOpen"><i class="fa fa-check"></i> The site will be
          opened. Click <a (click)="closeSite()" class="text-danger">undo</a> if this was a mistake!</p>
      </div>
      <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
        (close)="closeModal()"></hub-modal-form-footer>
    </div>

  </div>
</form>
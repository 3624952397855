

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerEndpointService, CustomerQuery, CustomerWebAppData } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class CustomersQueryService extends QueryService<CustomerWebAppData[]> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'customers';

  constructor(private customerEndpointService: CustomerEndpointService) { super(); }

  customersQuery(useCached: boolean): Observable<CustomerWebAppData[]> {
    return super.query(useCached, this.key);
  }

  customersDataChanges(): Observable<CustomerWebAppData[]> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<CustomerWebAppData[]> {
    return this.customerEndpointService.CustomerQuery(new CustomerQuery());
  }
}

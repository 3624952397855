import { Injectable } from '@angular/core';
import { AuthenticationEventsService } from './authentication-events.service';
import { User } from '../models/user';
import * as moment from 'moment';
import { LocalStorageService } from 'angular-web-storage';

// Class for querying and updating the logged in user data.
@Injectable()
export class UserIdentityService {

  private user: User;

  constructor(
    private authEventsService: AuthenticationEventsService,
    private localStorageService: LocalStorageService,
  ) {
    // Pull current user from local storage.
    this.user = this.localStorageService.get('currentUser');

    // Flush out the user data if it is not valid or access token is expired.
    const validAuthenticatedUser = this.user && this.user.username && this.user.accessToken && moment() < moment(this.user.expiryTime);
    if (!validAuthenticatedUser) {
      this.user = undefined;
      this.localStorageService.set('currentUser', undefined);
    }
    this.authEventsService.userUpdatedEvent.trigger(this.user);
  }

  isAuthenticated(): boolean {
    return !!this.user && this.user.accessToken && (moment() < moment(this.user.expiryTime));
  }

  currentUser(): User {
    return this.user;
  }

  // Updates user in memory and in local storage - use undefined for no user.
  updateUser(user: User, notifyListeners = true): User {
    this.user = user;
    this.localStorageService.set('currentUser', user);
    if (notifyListeners) {
      this.authEventsService.userUpdatedEvent.trigger(user);
    }
    return user;
  }

  // Get the access token or trigger the login required event if there is not a valid token.
  getAccessToken(): string {
    const currentTime = moment();
    const expired = this.user && moment().isAfter(this.user.expiryTime);
    if (!this.user || !this.user.accessToken || expired) {
      this.authEventsService.loginRequiredEvent.trigger({ expired: expired });
      return;
    } else {
      return this.user.accessToken;
    }
  }
}

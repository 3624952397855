import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteScaffoldDiaryData, SiteEndpointService, SiteScaffoldDiaryQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class SiteScaffoldDiaryQueryService extends QueryService<SiteScaffoldDiaryData[]> {

  constructor(private siteEndpointService: SiteEndpointService) { super(); }

  siteScaffoldDiaryQuery(useCached: boolean, siteReference: string): Observable<SiteScaffoldDiaryData[]> {
    return super.query(useCached, siteReference);
  }

  siteScaffoldDiaryDataChanges(siteReference: string): Observable<SiteScaffoldDiaryData[]> {
    return super.cachedDataChanges(siteReference);
  }

  protected getDataFromServer(siteReference: string): Observable<SiteScaffoldDiaryData[]> {
    const query = Object.assign(new SiteScaffoldDiaryQuery(), { SiteReference: siteReference });
    return this.siteEndpointService.SiteScaffoldDiaryQuery(query);
  }
}

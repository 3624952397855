import { Injectable } from '@angular/core';
import { ExportTypeEnum, ExportEndpointService } from 'app/core/hub-api';

import * as moment from 'moment';

@Injectable()
export class ExportService {

  constructor(
    private exportEndpointService: ExportEndpointService
  ) { }

  exportScaffolds(siteId: string, siteName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.exportEndpointService.Scaffolds(siteId, ExportTypeEnum.Csv).subscribe(
        result =>  {
          const name = siteName.replace(/\s+/g, '-').toLowerCase();
          const fileName = `${name}-scaffold-export`;
          resolve(this.createBlobContainer(result, fileName));
        },
        error => reject(error)
      );
    });
  }

  createBlobContainer(result: any, fileSuffix: string): any {
    const dateTime = moment().format('D-MM-YY-hh-mm-ss');
    const name = fileSuffix.replace(/\s+/g, '-').toLowerCase();

    const file = new Blob([result], { type: 'application/octet-stream' });
    const fileName = `${dateTime}-${name}.csv`;
    const fileURL = URL.createObjectURL(file);

    return {
      file: file,
      fileName: fileName,
      fileURL: fileURL
    };
  }

}

<form name="importContractForm" [formGroup]="form" class="contact-setup import-fixed-table">
    <div class="ss-toolbar">
      <a class="btn button-default button-rounded" role="button" (click)="onAddContact()">
        <i class="fa fa-plus"></i>
        <span>add contact</span>
      </a>
    </div>
    <div *ngIf="!contactsFormArray.length">
      <div class="empty-state-placeholder">
        <i class="fa fa-user fa-4x"></i>
        <h3>No contacts found</h3>
        <p>Add a contact by tapping the 'add contact' button above.</p>
      </div>
    </div>
    <div *ngIf="contactsFormArray.length">
      <table class="table table-borderless table-hover hover-positive">
        <tr>
          <th>*Name</th>
          <th>*Email Address</th>
          <th>Phone Number</th>
          <th>*Contact Type</th>
        </tr>
        <tr formArrayName="contacts" *ngFor="let contact of form.get('contacts').controls; let i = index;">
          <td class="vert-align-middle" [formGroupName]="i">
            <div class="form-group inline-block" [ngClass]="{'has-error': contact.controls.contactName.invalid && contact.controls.contactName.touched }">
              <input type="text" class="form-control" formControlName="contactName" ngxTrim>
              <div class="text-error" *ngIf="contact.controls.contactName.invalid && contact.controls.contactName.touched">
                <i class="fa fa-warning"></i> {{ errorMessages.required }}
              </div>
            </div>
          </td>
          <td class="vert-align-middle" [formGroupName]="i">
            <div class="form-group inline-block" [ngClass]="{'has-error': contact.controls.emailAddress.invalid && contact.controls.emailAddress.touched }">
              <input type="text" class="form-control" formControlName="emailAddress" ngxTrim>
              <div class="text-error" *ngIf="contact.controls.emailAddress.invalid && contact.controls.emailAddress.touched">
                <i class="fa fa-warning"></i> {{ errorMessages.emailInvalidShortMessage }}
              </div>
            </div>
          </td>
          <td class="vert-align-middle" [formGroupName]="i">
            <div class="form-group inline-block" [ngClass]="{'has-error': contact.controls.phoneNumber.invalid && contact.controls.phoneNumber.touched }">
              <input type="text" class="form-control" formControlName="phoneNumber" ngxTrim>
            </div>
          </td>
          <td class="vert-align-middle" [formGroupName]="i">
            <div class="form-group inline-block" [ngClass]="{'has-error': contact.controls.contactTypeId.invalid && contact.controls.contactTypeId.touched }">
              <select formControlName="contactTypeId" name="ContactType" class="form-control">
                <option value="">-- choose a contact type --</option>
                <option *ngFor="let type of contactTypes" [value]="type.ContactTypeId">{{type.Title}}</option>
              </select>
              <div class="text-error" *ngIf="contact.controls.contactTypeId.invalid && contact.controls.contactTypeId.touched">
                <i class="fa fa-warning"></i> {{ errorMessages.required }}
              </div>
            </div>
          </td>
          <td class="vert-align-middle" [formGroupName]="i" class="edit-buttons sortable ">
            <a (click)="onDeleteContact(i)" class="margin-right-sm " title="delete">
              <i class="fa fa-regular fa-trash-can"></i>
            </a>
          </td>
        </tr>
      </table>
    </div>
    <div class="modal-footer">
      <span class="required-note">* Denotes a required field</span>
      <div class="modal-buttons">
        <button class="btn btn-large btn-inverse" type="button" (click)="onPrevious(form.value)">
          <i class="fa fa-chevron-circle-left"></i> Back</button>
        <button class="btn btn-large btn-inverse" type="button" (click)="onNext(form.value)">
          <i class="fa fa-chevron-circle-right"></i> Next</button>
      </div>
    </div>
</form>

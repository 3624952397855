import { Component, OnInit } from '@angular/core';
import { IPrintOptions } from '../../models/print-options-interface';

@Component({
  selector: 'hub-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent {
  private styles = {
    landscape: `@page {size: A4 landscape;} html, body {width: 297mm;height:210mm;}`,
    portrait: `@page {size: A4 portrait;} html, body {width: 210mm;height:297mm;}`
  };
  private style = this.styles.portrait;

  constructor() {}

  print(options: IPrintOptions): void {
    if (options.orientation) {
      this.style = options.orientation === 'landscape' ? this.styles.landscape : this.styles.portrait;
    }

    const html = `
    <html>
      <head>
        <title>${options.title}</title>
        <style>${this.style}</style>
        <link rel="stylesheet" type="text/css" href="/assets/print.css" />
        <link rel="stylesheet" type="text/css" href="/assets/font-awesome-print/font-awesome.min.css" />
      </head>
      <body>
        <div id="report-print-bar">
          <button class="print-button" onclick="window.print()">Print Report</button>
          <a onclick="window.close()" class="close-btn"></a>
        </div>
        <div class="print-preview-wrapper ${options.orientation}">
          ${options.content}
        </div>
      </body>
    </html>`;
    const newWindow = window.open('/timesheet/reports/print-preview');
    newWindow.document.write(html);
  }
}

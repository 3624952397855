import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { some, cloneDeep, chain, filter, isEqual as _isEqual, sortBy as _sortBy } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SiteDashBoardScaffoldInspectionData, ScaffoldData, SiteWebAppData, InspectionWorkflowStatusEnum } from 'app/core/hub-api';
import { TimeoutService } from '../../../core/services/timeout.service';
import { colors } from '../../values/colours';
import { siteStatusIds } from '../../values/site-status-ids';
import { contractStatusIds } from '../../values/contract-status-ids';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'hub-one-day-inspection-chart',
  templateUrl: './one-day-inspection-chart.component.html',
  styleUrls: ['./one-day-inspection-chart.component.scss']
})
export class OneDayInspectionChartComponent implements OnInit, OnChanges {

  @Input()
  inspectionData: SiteDashBoardScaffoldInspectionData[] = [];

  @Input()
  date: Moment = moment();

  @Input()
  contractId: string;

  @Input()
  hideList: boolean;

  uiSettings = {
    showChart: true,
    allCleared: false
  };

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  private readonly iterationsBeforeTimeout = 10;

  inspectionsBySite: any[];

  chartConfig: Highcharts.Options = {
    title: {
      text: '',
      align: 'center',
      y: 15,
      style: {
        color: colors.river,
      }
    },
    subtitle: {
      text: 'Inspections today',
      align: 'center',
      y: 35,
    },
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    colors: [colors.positive, colors.calm, colors.assertive],
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          formatter(): any {
            return this.y > 0 ? Math.round(this.percentage * 100) / 100 + ' %' : null;
          },
          distance: 15,
          color: 'black'
        },
        showInLegend: true,
        innerSize: '80%'
      }
    },
    series: [],
    credits: {
      enabled: false
    },
  };

  constructor(private timeoutService: TimeoutService) { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('onDayInspectionChart', this.chartConfig);
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['inspectionData'] || changes['contractId']) && !!this.chart) {
      this.updateChartData();
    }
  }

  updateChartData(): Promise<void> {
    this.inspectionData = filter(this.inspectionData, function (scaffold): boolean {
      return scaffold.SiteStatusId !== siteStatusIds.closed && scaffold.ContractStatusId !== contractStatusIds.closed;
    });
    return this.getChartData().then((chartData) => {

      while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

      this.chart.addSeries({
        name: 'Inspections',
        type: 'pie',
        data: chartData.data
      }, false);

      const titleText = (chartData.data[0][1] + chartData.data[1][1] + chartData.data[2][1]).toString();
      this.chart.setTitle({ text: titleText }, undefined, false);

      this.inspectionsBySite = _sortBy(chartData.sites, 'siteName');
      this.uiSettings.allCleared = false;
      if (_isEqual([], this.inspectionsBySite)) {
        this.uiSettings.allCleared = true;
      }

      // Redraw only once at the end instead of on setTitle and addSeries.
      this.chart.redraw();

      this.uiSettings.showChart = some(chartData.data, (el) => {
        return el[1] > 0;
      });
    });

  }

  private createChartDataBySite(): any {
    const chartDataBySite = {
      tryGetSite: function (scaffold): any {
        if (!scaffold.SiteId) {
          return;
        }
        let site = this.sites[scaffold.SiteId];
        if (!site) {
          site = this.createSiteData(scaffold);
          this.sites[scaffold.SiteId] = site;
        }
        return site;
      },
      incrementComplete: function (scaffold): void {
        const site = chartDataBySite.tryGetSite(scaffold);
        site.incrementComplete();
      },
      incrementRemaining: function (scaffold): void {
        const site = chartDataBySite.tryGetSite(scaffold);
        site.incrementRemaining();
      },
      incrementOverdue: function (scaffold): void {
        const site = chartDataBySite.tryGetSite(scaffold);
        site.incrementOverdue();
      },
      sites: {},
      createSiteData: this.createSiteData
    };
    return chartDataBySite;
  }

  private getChartData(): Promise<any> {
    const chartDataBySite = this.createChartDataBySite();
    const chartData = {
      incrementComplete(): void {
        this.data[0][1] += 1;
      },
      incrementRemaining(): void {
        this.data[1][1] += 1;
      },
      incrementOverdue(): void {
        this.data[2][1] += 1;
      },
      data: [
        ['Complete', 0],
        ['Remaining', 0],
        ['Overdue', 0]
      ]
    };

    const promises = [];
    promises.push(this.getChartDataFromScaffoldNextInspectionDates(chartData, chartDataBySite));
    promises.push(this.getChartDataFromInspectionWorkflowStatuses(chartData, chartDataBySite));

    return Promise.all(promises).then((resolutions) => {
      return resolutions[0];
    });
  }

  private createSiteData(scaffold): any {
    return (function (scaff): any {

      let complete = 0;
      let overdue = 0;
      let remaining = 0;
      const siteName = scaff.SiteName;
      const siteReference = scaff.SiteReference;

      return {
        incrementComplete: function (): void {
          complete += 1;
        },
        incrementOverdue: function (): void {
          overdue += 1;
        },
        incrementRemaining: function (): void {
          remaining += 1;
        },
        completeCount: function (): number {
          return complete;
        },
        overdueCount: function (): number {
          return overdue;
        },
        remainingCount: function (): number {
          return remaining;
        },
        siteName: siteName,
        siteReference: siteReference
      };
    }(scaffold));
  }

  // Calculate the number of overdue and remaining inspections from the scaffold next inspection dates.
  getChartDataFromScaffoldNextInspectionDates(chartData, chartDataBySite): Promise<any> {

    const processScaffoldData = (scaffold) => {
      if (this.contractId && this.contractId !== scaffold.ContractId) {
        return;
      }

      if (moment(scaffold.NextInspectionDate).isSame(this.date, 'day')) {
        chartData.incrementRemaining();
        chartDataBySite.incrementRemaining(scaffold);
      }

      if (moment(scaffold.NextInspectionDate).isBefore(this.date, 'day')) {
        chartData.incrementOverdue();
        chartDataBySite.incrementOverdue(scaffold);
      }
    };

    let resolve;
    const promise = new Promise((res) => { resolve = res; });

    // Make sure we don't freeze the UI.
    this.timeoutService.forWithTimeouts(this.inspectionData, processScaffoldData, this.iterationsBeforeTimeout).then(() => {
      resolve({ data: chartData.data, sites: chartDataBySite.sites });
    });

    return promise;
  }

  // Calculate the number of completed and remaining inspections from the workflow status of existing scaffold inspections.
  getChartDataFromInspectionWorkflowStatuses(chartData, chartDataBySite): Promise<any> {

    const processInspectionData = (inspection) => {
      if (this.contractId && this.contractId !== inspection.ContractId ||
        !moment(inspection.When).isSame(this.date, 'day')) {
        return;
      }

      if (inspection.InspectionWorkflowStatus === InspectionWorkflowStatusEnum.Complete ||
        inspection.InspectionWorkflowStatus === InspectionWorkflowStatusEnum.Finished) {
        chartData.incrementComplete();
        chartDataBySite.incrementComplete(inspection);
      }

      if (inspection.InspectionWorkflowStatus === InspectionWorkflowStatusEnum.Inspected ||
        inspection.InspectionWorkflowStatus === InspectionWorkflowStatusEnum.InProgress) {
        chartData.incrementRemaining();
        chartDataBySite.incrementRemaining(inspection);
      }
    };

    const inspections = chain(this.inspectionData)
      .map((scaffold) => {
        const inspectionData = cloneDeep(scaffold.InspectionData);
        return inspectionData.map((i: any) => {
          i.ContractId = scaffold.ContractId;
          i.SiteId = scaffold.SiteId;
          i.SiteReference = scaffold.SiteReference;
          i.SiteName = scaffold.SiteName;
          return i;
        });
      })
      .flatten()
      .value();

    let resolve;
    const promise = new Promise((res) => { resolve = res; });

    // Make sure we don't freeze the UI.
    this.timeoutService.forWithTimeouts(inspections, processInspectionData, this.iterationsBeforeTimeout).then(() => {
      resolve({ data: chartData.data, sites: chartDataBySite.sites });
    });

    return promise;
  }

}

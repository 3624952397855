/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import '../../rxjs-imports';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng4-click-outside';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EstimatesRoutingModule } from './estimates-routing.module';
import { EstimatesContainerComponent } from './components/estimates-container/estimates-container.component';
import { EstimatesTableComponent } from './estimates-table/estimates-table.component';

/**
 * Controls all modules, imports, providers and declarations for the Estimates route
 *
 * @export
 * @class EstimatesModule
 */
@NgModule({
  imports: [
    SharedModule,
    NgxDatatableModule,
    ClickOutsideModule,
    FormsModule,
    EstimatesRoutingModule,
  ],
  declarations: [
    EstimatesContainerComponent,
    EstimatesTableComponent
  ],
  providers: [],
  exports: [
    NgxDatatableModule
  ]
})
export class EstimatesModule { }

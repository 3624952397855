import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DiaryData } from 'app/core/hub-api';

import { DiaryQueryService } from '../services/diary-query-service';

@Injectable()
export class ScaffoldDiaryResolver implements Resolve<DiaryData> {

  constructor(private diaryQueryService: DiaryQueryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<DiaryData> {
    const id = route.params['scaffoldDiaryId'];
    return this.diaryQueryService.diaryQuery(true, id);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { map, cloneDeep } from 'lodash';
import { SiteAreaData, SiteCommandService, SiteWebAppData, UpdateAreasCommand, SiteAreaDetails } from 'app/core/hub-api';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { AddEditAreaModalContext } from '../../../../models/add-edit-area-modal-context';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { mustNotExistValidator } from '../../../../../shared/validators/must-not-exist.validator';
import { forkJoin } from 'rxjs';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-add-edit-area-modal',
  templateUrl: './add-edit-area-modal.component.html',
  styleUrls: ['./add-edit-area-modal.component.scss']
})
export class AddEditAreaModalComponent extends FormComponent implements OnInit {
  context: Partial<AddEditAreaModalContext>;

  editMode: boolean;

  site: SiteWebAppData;

  area: SiteAreaData;

  form: UntypedFormGroup;
  areaNameFormControl: UntypedFormControl;

  deleteValidationMessage: string;

  validationMessages = {
    areaName: {
      required: errorMessages.required,
      mustNotExist: errorMessages.areaNameMustBeUnique,
    },
  };

  areaWasDeleted: boolean;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private siteCommandService: SiteCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    public modalService: BsModalService,
    ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.site = this.context.site;
    this.area = cloneDeep(this.context.area) || this.getNewArea();
    const areaNames = map(this.site.SiteAreas).map(a => a.AreaName);
    const areaValidation = this.editMode ? [Validators.required] : [Validators.required, mustNotExistValidator(areaNames)];
    this.areaNameFormControl = new UntypedFormControl(this.area.AreaName, areaValidation);
    this.form = new UntypedFormGroup({
      areaName: this.areaNameFormControl
    });
    this.deleteValidationMessage = '';
    this.areaWasDeleted = this.area.Deleted;
    super.ngOnInit();
  }

  getNewArea(): SiteAreaData {
    return Object.assign(new SiteAreaData(), {
      SiteAreaId: UUID.UUID(),
      AreaName: '',
      Deleted: false
    });
  }

  onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const area: SiteAreaDetails = this.area;
      area.AreaName = formValues.areaName;

      const areas = map(this.site.SiteAreas).filter(a => a.SiteAreaId !== this.area.SiteAreaId) as SiteAreaDetails[];
      areas.push(area);

      const updateAreasCommand: UpdateAreasCommand = {
        SiteId: this.site.SiteId,
        Areas: areas
      };

      this.siteCommandService.UpdateAreasCommand(updateAreasCommand)
        .subscribe(() => {
          // Here we must reload any data that this add/edit affects from the server.
          forkJoin([this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId)]).subscribe(() => {
            this.saveInProgress = false;
            this.bsModalService.setDismissReason(this.area.SiteAreaId);
            this.bsModalRef.hide();
          });
        }, this.serverErrorCallback);
    }
  }

  deleteArea(): void {
    const isOnlyActiveArea = map(this.site.SiteAreas).every(a => a.Deleted || a.SiteAreaId === this.area.SiteAreaId);
    if (isOnlyActiveArea) {
      this.deleteValidationMessage = errorMessages.AreaCannotBeDeletedIfThereAreNoOtherActiveAreas;
    } else if (this.area.NumberOfScaffolds > 0) {
      this.deleteValidationMessage = errorMessages.areaCannotBeDeletedIfItHasActiveScaffolds;
    } else {
      this.area.Deleted = true;
    }
  }

  activateArea(): void {
    this.area.Deleted = false;
  }
}

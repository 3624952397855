import { Injectable } from '@angular/core';
@Injectable()
export class AutoGenerateQuickIdService {

    private allStaff: any;

    /**
    * Return the quickId with respect to different pattern
    * e.g. - staff name is John Samuel
    * pattern 01 : return staffinitial(JS)
    * pattern 02 : return single digit numer with staffInitial (JS1-JS9)
    * pattern 03 : return single letter with staffInitial (JSA-JSZ)
    * pattern 04 : return three digit numer(111-999)
    * pattern 05 : return three letter alphabet(AAA-ZZZ)
    * pattern 06 : return empty string
    */
    getQuickId(staffInitial, allStaff): string {

        this.allStaff = allStaff;
        let _quickId = this.tryDefaultStaffInitial(staffInitial);

        if (_quickId) { return _quickId; }
        _quickId = this.tryAddSingleDigitNumber(staffInitial);

        if (_quickId) { return _quickId; }
        _quickId = this.tryAddSingleLetterAlphabet(staffInitial);

        if (_quickId) { return _quickId; }
        _quickId = this.tryAddThreeDigitNumber();

        if (_quickId) { return _quickId; }
        _quickId = this.tryAddThreeLetterAlphabet();
        return _quickId ? _quickId : '';

    }

    /**
    * Return staffQuickId if not eixst in staff table
    * e.g.- staffname is John Samuel and check 'JS' is exist in staff table
    */
    private isStaffQuickIdExist(quickId: string): boolean {
        return this.allStaff.find(i => i.QuickId === quickId) ? true : false;
    }

    /**
    * pattern 01 : Return staff default initial if not exist in staff table
    * e.g.- if staffname is John Samuel then default initial should be JS
    */
    private tryDefaultStaffInitial(staffInitials: string): string {
        return !this.isStaffQuickIdExist(staffInitials) ? staffInitials : null;
    }

    /**
    * pattern 02 : Return staffinitial with number
    * e.g.- JS1-JS9
    */
    private tryAddSingleDigitNumber(staffInitials: string, suffix: number = 1): string {
        if (suffix > 9) return null;
        const _quickId = staffInitials + suffix;
        return this.isStaffQuickIdExist(_quickId) ? this.tryAddSingleDigitNumber(staffInitials, suffix + 1) : _quickId;
    }


    /**
    * pattern 03 : Return staffinitial with alphabet
    * e.g.- JSA-JSZ
    */
    private tryAddSingleLetterAlphabet(staffInitials: string, suffix: number = 65): string {
        if (suffix > 90) return null;
        const _suffixLetter = String.fromCharCode(suffix);
        const _quickId = staffInitials + _suffixLetter;
        return this.isStaffQuickIdExist(_quickId) ? this.tryAddSingleLetterAlphabet(staffInitials, suffix + 1) : _quickId;
    }


    /**
    * pattern 04 : Return three digit number
    * e.g.- 111-999
    */
    private tryAddThreeDigitNumber(quickId: number = 111): string {
        if (quickId > 999) return null;
        return this.isStaffQuickIdExist(quickId.toString()) ? this.tryAddThreeDigitNumber(quickId + 1) : quickId.toString();
    }


    /**
    * pattern 05 : Return three letter alphabet
    * e.g.- AAA-ZZZ
    */
    private tryAddThreeLetterAlphabet(quickId: string = 'AAA'): string {
        if (!quickId) return null;
        return this.isStaffQuickIdExist(quickId) ? this.tryAddThreeLetterAlphabet(this.tryGetNextThreeLetterQuickId(quickId)) : quickId;
    }


    /**
    * Remove the last character of input string and add the next letter of alphabet
    * e.g.- AAA is input and output should be AAB
    */
    private replaceAt(index: number, character: string, replactStr: string): string {
        return replactStr.substr(0, index) + character + replactStr.substr(index + character.length);
    }

    private tryGetNextThreeLetterQuickId(previousQuickId: string): string {
        if (previousQuickId === 'ZZZ') return null;
        const _stop = 'Z';
        const _start = 'A';
        const _currentIndex = previousQuickId.length - 1;
        let _quickId = this.replaceAt(_currentIndex, String.fromCharCode(previousQuickId.charCodeAt(_currentIndex) + 1), previousQuickId);

        for (let i = _quickId.length - 1; i > 0; i--) {
            if (_quickId[i] === String.fromCharCode(_stop.charCodeAt(0) + 1)) {
                _quickId = this.replaceAt(i - 1, String.fromCharCode(_quickId.charCodeAt(i - 1) + 1), _quickId);
                _quickId = this.replaceAt(i, String.fromCharCode(_start.charCodeAt(0)), _quickId);
            }
        }
        return _quickId;
    }
}

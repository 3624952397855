import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeniedComponent } from './core/components/denied/denied.component';
import { OfflineComponent } from './core/components/offline/offline.component';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated-guard';
import { OfflineGuard } from './core/guards/offline-guard';
import { OnlineGuard } from './core/guards/online-guard';
import { LoggedInStaffResolver } from './core/resolvers/logged-in-staff-resolver';
import { MainComponent } from './core/components/main/main.component';
import { AuthenticationSetupGuard } from 'app/core/guards/authentication-setup-guard';
import { Error404Component } from './core/components/error-404/error-404.component';
import { ActivityTabComponent } from './activity/components/activity-tab/activity-tab.component';
import { EulaSlaGuard } from './core/guards/eula-sla.guard';
import { AuthorisedGuard } from './core/guards/authorised-guard';
import { GenericErrorComponent } from './core/components/generic-error/generic-error.component';

const routes: Routes = [
  {
    path: 'error',
    component: GenericErrorComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthenticationSetupGuard],
    children: [
      {
        path: '',
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
          { path: 'sites', loadChildren: () => import('./sites/sites.module').then(m => m.SitesModule) },
          { path: 'estimates', loadChildren: () => import('./estimates/estimates.module').then(m => m.EstimatesModule) },
          { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
          { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
          { path: 'staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule) },
          { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
          { path: 'activity', component: ActivityTabComponent, canActivate: [EulaSlaGuard] },
          { path: 'setup', loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule), canActivate: [EulaSlaGuard] },
          { path: 'timesheet', loadChildren: () => import('./timesheet/timesheet.module').then(m => m.TimesheetModule) },
          { path: 'helpcentre', loadChildren: () => import('./helpcentre/helpcentre.module').then(m => m.HelpcentreModule), canActivate: [AuthorisedGuard] },
          {
            path: 'denied',
            component: DeniedComponent,
          }
        ],
        resolve: {
          loggedInStaff: LoggedInStaffResolver
        },
        canActivate: [OnlineGuard]
      },
      /* when the session expires and you re-login sometimes it directs the user to this page /#/state , causing a 404 */
      /* TODO: Take a look at this later */
      {
        path: 'state',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      /* when doing password reset ad is redirecting back to app with path /#/id_token which is causing a 404 */
      {
        path: 'id_token',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [OnlineGuard, NotAuthenticatedGuard]
      },
      {
        path: 'offline',
        component: OfflineComponent,
        canActivate: [OfflineGuard]
      },
      { path: '**', component: Error404Component }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

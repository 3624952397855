<form [formGroup]="form" >
    <div class="date-picker-group">
        <input 
            formControlName="date"
            class="date-picker" 
            type="text"
            ngxDaterangepickerMd 
            [closeOnAutoApply]="closeOnAutoApply"
            [autoApply]="!isRange"
            [singleDatePicker]="!isRange"
            [showRangeLabelOnInput]="isRange"
            [linkedCalendars]="!isRange"
            (datesUpdated)="onDateRangeSelected($event)" 
            [alwaysShowCalendars]="alwaysShowCalendars" 
            [showCustomRangeLabel]="showCustomRangeLabel"
            [ranges]="ranges" 
            [isCustomDate]="isCustomDate"
            [showClearButton]="showClearButton" 
            [placeholder]="placeholder"
            [locale]="locale"
            [opens]="opens"
            [drops]="drops"
            [startKey]="startKey"
            [endKey]="endKey"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [showDropdowns]="showDropdowns"
            [ngClass]="{'disabled': disable}"
            (keyup)="onKeyUp($event)"
        />
        <button
        type="button"
        *ngIf="form.controls.date.value && form.controls.date.value[startKey] && form.controls.date.value[startKey] !== null"
        (click)="clear($event)">
        <i class="fa fa-xmark"></i>
        </button>
    </div>

    </form>
 
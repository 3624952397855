<form name="depotsForm" [formGroup]="form">
  <hub-modal-form-header [title]="editMode ? 'Edit Depot': 'Add Depot'" (close)="bsModalRef.hide()">
  </hub-modal-form-header>
  <div class="modal-body">
    <fieldset>
      <hub-form-group label="Depot Name" name="depotName" [control]="depotNameFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="depotName" ngxTrim>
      </hub-form-group>
      <hub-form-group label="Depot Address" name="depotAddress" [control]="depotAddressFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="depotAddress" ngxTrim>
      </hub-form-group>
      <hub-form-group label="Email Address" name="emailAddress" [control]="emailAddressFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="emailAddress" ngxTrim>
      </hub-form-group>
      <hub-form-group label="Phone Number" name="phoneNumber" [control]="phoneNumberFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="phoneNumber" ngxTrim>
      </hub-form-group>
    </fieldset>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defectResults'
})
export class DefectResultsPipe implements PipeTransform {
  transform(status: number): any {
    switch (status) {
      case 0:
        return {
          icon: 'fa-exclamation-circle text-orange',
          title: 'Fixed by inspector'
        };
      case 1:
        return {
          icon: 'fa-exclamation-circle text-assertive',
          title: 'Unsafe'
        };
      case 2:
        return {
          icon: 'fa-exclamation-circle text-amber',
          title: 'Minor defects remain'
        };
      default:
        return {
          icon: 'fa-question-circle text-gray-50',
          title: 'Unknown'
        };
    }
  }
}

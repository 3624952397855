import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { fileNameRegex } from 'app/shared/regex/file-name.regex';
import { RenameDiaryMediaModalContext } from 'app/sites/models/rename-diary-media-modal-context';
import {
  DiaryData,
  EditScaffoldMediaCommand,
  EditScaffoldMediaTranscriptionCommand,
  EditSiteDiaryMediaCommand,
  MediaBaseData,
  MediaTranscriptionData,
  ScaffoldCommandService,
  SiteCommandService,
} from 'app/core/hub-api';

import { errorMessages } from '../../../../../shared/values/error-messages';
import { DiaryQueryService } from '../../../../services/diary-query-service';
import { Observable } from 'rxjs';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-rename-diary-media-modal',
  templateUrl: './rename-diary-media-modal.component.html',
  styleUrls: ['./rename-diary-media-modal.component.scss']
})
export class RenameScaffoldMediaModalComponent extends FormComponent implements OnInit {
  context: Partial<RenameDiaryMediaModalContext>;
  form: UntypedFormGroup;
  mediaTitleFormControl: UntypedFormControl;

  transcriptionMode: boolean;
  media: MediaBaseData;
  diary: DiaryData;

  validationMessages = {
    mediaTitle: {
      required: errorMessages.required,
      pattern: errorMessages.fileName,
    }
  };

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private scaffoldCommandService: ScaffoldCommandService,
    private siteCommandService: SiteCommandService,
    private diaryQueryService: DiaryQueryService,
    public modalService: BsModalService,
  ) {
    super();
  }

  //
  // initialization
  //
  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.media = this.context.media;
    this.diary = this.context.scaffoldDiary;
    this.transcriptionMode = this.context.transcriptionMode;

    this.mediaTitleFormControl = new UntypedFormControl(this.media.Title, [Validators.required, Validators.pattern(fileNameRegex)]);

    this.form = new UntypedFormGroup({
      mediaTitle: this.mediaTitleFormControl,
    });

    super.ngOnInit();
  }

  //
  // submit Edit diary media based on the formvaluues
  //
  onSubmit(formValues): void {
    if (!this.validateForm()) {
      return;
    }

    this.saveInProgress = true;

    const commandResponse = (this.context.scaffoldDiary.DiaryType === 0) ? this.editSiteMedia(formValues) : this.editScaffoldMedia(formValues);

    commandResponse.subscribe(() => {
      this.diaryQueryService.diaryQuery(false, this.diary.DiaryId).subscribe(() => {
        this.saveInProgress = false;
        this.bsModalService.setDismissReason(formValues.mediaTitle);
        this.bsModalRef.hide();
      });
    });
  }

  //
  // Edit site media based on the formvalues
  //
  editSiteMedia(formValues): Observable<any> {

    const editSiteDiaryMediaCommand: EditSiteDiaryMediaCommand = {
      SiteId: this.context.id,
      MediaReferenceId: this.media.MediaReferenceId,
      Title: formValues.mediaTitle,
      Deleted: false,
    };

    return this.siteCommandService.EditSiteDiaryMediaCommand(editSiteDiaryMediaCommand);
  }

  //
  // Edit scaffold media based on the formvalues
  //
  editScaffoldMedia(formValues): Observable<any> {

    const editScaffoldMediaCommand: EditScaffoldMediaCommand | EditScaffoldMediaTranscriptionCommand = {
      ScaffoldId: this.context.id,
      MediaReferenceId: this.media.MediaReferenceId,
      Title: formValues.mediaTitle,
      Transcription: (this.media as MediaTranscriptionData).Transcription,
      Deleted: false,
    };

    const commandResponse = this.transcriptionMode
      ? this.scaffoldCommandService.EditScaffoldMediaTranscriptionCommand(editScaffoldMediaCommand)
      : this.scaffoldCommandService.EditScaffoldMediaCommand(editScaffoldMediaCommand);

    return commandResponse;
  }
}


import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserIdentityService } from 'app/core/authentication';

@Component({
  selector: 'hub-denied',
  templateUrl: './denied.component.html',
  styleUrls: ['./denied.component.scss']
})
export class DeniedComponent implements OnInit {
  decideRoute: { pageName: string; url: any; };

  constructor(
    private location: Location,
    private userIdentityService: UserIdentityService,
  ) { }

  ngOnInit(): void {
    this.userRedirect();
  }

  private userRedirect(): void {
    if (this.userIdentityService.currentUser().roles[0] === 'Payroll Officer') {
      this.decideRoute = {
        pageName: 'timesheet reports',
        url: ['../timesheet/reports/collective'],
      };
    } else {
      this.decideRoute = {
        pageName: 'sites list',
        url: ['/sites'],
      };
    }
  }

  back(): void {
    this.location.back();
  }

}

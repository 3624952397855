import { StaffDetailQueryService } from '../../../../../core/services/staff-detail-query.service';
import { combineLatest, forkJoin } from 'rxjs';
import { StaffsQueryService } from '../../../../../core/services/staffs-query.service';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditSiteStaffModalComponent } from '../add-edit-site-staff-modal/add-edit-site-staff-modal.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'lodash';
import {
  ConfigurationData,
  RolesData,
  SiteWebAppData,
  StaffData,
  SetSiteStaffPermissionsCommand,
  SiteCommandService
} from 'app/core/hub-api';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig, SSDialogContext } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToasterService } from 'angular2-toaster';

@AutoUnsubscribe()
@Component({
  selector: 'hub-site-detail-staff',
  templateUrl: './site-detail-staff.component.html',
  styleUrls: ['./site-detail-staff.component.scss']
})
export class SiteDetailStaffComponent implements OnInit, OnDestroy {
  site: SiteWebAppData;
  siteStaffs: StaffData[];
  allStaffs: StaffData[];
  roles: RolesData[];

  constructor(
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private siteDetailQueryService: SiteDetailQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private staffsQueryService: StaffsQueryService,
    private staffDetailQueryService: StaffDetailQueryService,
    private siteCommandService: SiteCommandService,
    private loadingBar: LoadingBarService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.snapshot.params['siteReference'];
    const obs$ = combineLatest(
      [this.siteDetailQueryService.siteDetailDataChanges(siteReference),
      this.configurationQueryService.configurationDataChanges(),
      this.staffsQueryService.staffsDataChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2]));
  }

  refreshComponent(site: SiteWebAppData, configuration: ConfigurationData, staffs: StaffData[]): void {
    this.site = site;
    this.siteStaffs = staffs.filter(staff => this.site.Staff.map(s => s.StaffId).includes(staff.StaffId));
    this.allStaffs = staffs;
    this.roles = map(configuration.Roles);
  }

  onAddStaff(): void {
    const allDepotStaffs = this.allStaffs.filter(s => s.SiteDepotIds.includes(this.site.SiteDepotId));
    const siteStaffIds = this.site.Staff.map(s => s.StaffId);
    const staffList = allDepotStaffs
      .filter(s => !siteStaffIds.includes(s.StaffId))
      .map(s => Object.assign(s, { selected: false }));
    if (!staffList.length) {
      const data: SSDialogContext = {
        title: 'No Staff To Add',
        body: '<i class="fa fa-warning text-warning"></i> There are no more staff associated with this site.',
        okBtnClass: 'btn button-primary',
        okBtnText: 'Ok got it',
        cancelBtnClass: 'hide',
        cancelBtnText: 'No keep it',
        showHeaderClose: true,
        showOnlyCloseBtn: false,
        dialogClass: 'modal-dialog modal-warning',
        headerClass: 'modal-header confirm-modal-header modal-header-warning',
        footerClass: 'modal-footer confirm-modal-footer'
      };
      this.bsModalService.show(ModalDialogComponent, SSModalConfig.generate(data));
    }

    const context = {
      site: this.site,
      staffs: staffList
    };
    this.bsModalService.show(AddEditSiteStaffModalComponent, SSModalConfig.generate(context));
  }

  onDeleteStaff(staff: StaffData): void {
    const _loading = this.loadingBar.useRef('global');
    _loading.start();
    const setSiteStaffPermissionsCommand = Object.assign(new SetSiteStaffPermissionsCommand(), {
      StaffIds: [staff.StaffId],
      IsInactive: true,
      SiteId: this.site.SiteId
    });
    this.siteCommandService.SetSiteStaffPermissionsCommand(setSiteStaffPermissionsCommand).subscribe({
      next: () => {
        this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference)

        this.staffDetailQueryService.staffDetailQuery(false, staff.StaffId)
      },
      error: (error) => {
        console.debug('Site Staff Not Removed', error);
        this.toasterService.pop('error', 'Unable to remove staff', `There was a problem removing ${staff.ContactName} from the list.`);
        _loading.stop();
      },
      complete: () => {
        this.siteStaffs = this.siteStaffs.filter(s => s.StaffId !== staff.StaffId);
        this.toasterService.pop('success', 'Site Staff Removed', `${staff.ContactName} is now removed from the list.`);
        _loading.stop();
      }
    });
  }

  ngOnDestroy(): void { }
}

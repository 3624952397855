import { scaffoldNumberComparator } from '../../../../../shared/comparators/scaffold-number-comparator';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { cloneDeep, sortBy } from 'lodash';
import {
  ScaffoldWebAppData,
  SiteCommandService,
  SiteWebAppData,
  UpdateScaffoldNumbersCommand,
} from 'app/core/hub-api';
import { FixScaffoldNumbersModalContext } from '../../../../models/fix-scaffold-numbers-modal-context';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormComponent } from 'app/shared/components/form/form.component';
import { ReferenceGeneratorService } from 'app/core/utility';

@Component({
  selector: 'hub-fix-scaffold-numbers-modal',
  templateUrl: './fix-scaffold-numbers-modal.component.html',
  styleUrls: ['./fix-scaffold-numbers-modal.component.scss']
})
export class FixScaffoldNumbersModalComponent extends FormComponent implements OnInit {
  context: Partial<FixScaffoldNumbersModalContext>;

  form: UntypedFormGroup;
  validationMessages = {};

  site: SiteWebAppData;

  duplicateScaffolds: ScaffoldWebAppData[];

  scaffoldNumberComparator = scaffoldNumberComparator;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private siteCommandService: SiteCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private referenceGeneratorService: ReferenceGeneratorService,
    public modalService: BsModalService,
    ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.form = new UntypedFormGroup({});

    this.site = this.context.site;
    const allScaffolds = this.context.allScaffolds;
    const duplicateScaffolds = sortBy(this.context.duplicateScaffolds, 'ScaffoldNumber');
    const configuration = this.context.configuration;

    const scaffoldList = allScaffolds.slice(0);
    this.duplicateScaffolds = duplicateScaffolds.map(scaffold => {
      scaffold = cloneDeep(scaffold);

      (scaffold as any).oldScaffoldNumber = scaffold.ScaffoldNumber;
      scaffold.ScaffoldNumber = this.referenceGeneratorService
        .incrementalReference(scaffoldList.map(s => s.ScaffoldNumber), configuration.ScaffoldReferenceLength);
      scaffoldList.push(scaffold);
      return scaffold;
    });
    super.ngOnInit();
  }

  onSubmit(formValues: any): void {
    this.saveInProgress = true;

    const command: UpdateScaffoldNumbersCommand = {
      SiteId: this.site.SiteId,
      Scaffolds: this.duplicateScaffolds,
    };

    this.siteCommandService.UpdateScaffoldNumbersCommand(command)
      .subscribe(() => {
        this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId)
          .subscribe(() => {
            this.saveInProgress = false;
            this.bsModalService.setDismissReason(this.site.SiteReference);
            this.bsModalRef.hide();
          });
      }, this.serverErrorCallback);
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ApplicationSettingsQueryService } from '../services/application-settings-query.service';
import { map, catchError } from 'rxjs/operators';

// Route guard for ensuring that the user is online.
@Injectable()
export class OnlineGuard implements CanActivate {
  constructor(private applicationSettingsQueryService: ApplicationSettingsQueryService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.applicationSettingsQueryService.applicationSettingsQuery(false).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        this.router.navigate(['/offline']);
        return of(false);
      })
    );
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

/**
 * This class contains all texts properties used in the dropdown component
 *
 * @export
 * @class DropdownTexts
 */
export class DropdownTexts {
    checkAll?: string;
    uncheckAll?: string;
    checked?: string;
    checkedPlural?: string;
    searchPlaceholder?: string;
    searchEmptyResult?: string;
    searchNoRenderText?: string;
    searchEmptyResultText?: string;
    defaultTitle?: string;
    allSelected?: string;
}

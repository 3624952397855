import { ScheduleTypeEnum } from 'app/core/hub-api';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class ScheduledReportsStartGeneratorService {

  getStartDate(scheduleTypeEnum: ScheduleTypeEnum, currentDatetime = moment()): string {
    switch (scheduleTypeEnum) {
      case ScheduleTypeEnum.Daily:
        return currentDatetime.startOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm');
      case ScheduleTypeEnum.Weekly:
        return currentDatetime.startOf('isoWeek').add(1, 'weeks').add(9, 'hour').toISOString();
      case ScheduleTypeEnum.Monthly:
        return currentDatetime.startOf('month').add(1, 'months').add(9, 'hour').toISOString();
    }
  }
}

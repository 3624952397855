import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { LoggedInStaffService } from '../services/logged-in-staff-service';
import { StaffDetailsWebAppData, ApplicationSettingsData } from 'app/core/hub-api';
import { EulaSlaModalContext } from 'app/home/components/eula-sla-modal/eula-sla-modal-context';
import { EulaSlaModalComponent } from 'app/home/components/eula-sla-modal/eula-sla-modal.component';
import { ApplicationSettingsQueryService } from '../services/application-settings-query.service';
import { Injectable } from '@angular/core';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EulaSlaGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  loggedInStaff: StaffDetailsWebAppData = new StaffDetailsWebAppData();
  applicationData: ApplicationSettingsData;
  result: boolean;
  /**
   *
   */
  constructor(
    private loggedInStaffService: LoggedInStaffService,
    private applicationSettingsQueryService: ApplicationSettingsQueryService,
    public modalService: BsModalService) {

  }
  /**
   * Eula Sla guard to show Eula popup when the user has not accepted on every routing
   */
  canActivate(): Observable<boolean> {

    const ob$ = combineLatest([
      this.applicationSettingsQueryService.applicationSettingsQuery(false),
      this.loggedInStaffService.loggedInStaffChangesFromServer()
    ]);

    return ob$.pipe(
      map(data => {
        this.applicationData = data[0];
        this.loggedInStaff = data[1];
        if (this.loggedInStaff && this.loggedInStaff.EulaAgreedVersion !== this.applicationData.EulaVersion) {
          const context: EulaSlaModalContext = {
            StaffDetailsData: this.loggedInStaff,
            keyboard: null,
          };
          const modal = this.modalService.show(EulaSlaModalComponent, SSModalConfig.generate(context));
          modal.setClass('license-agreement');
        }
        return true;
      })
    );
  }
}

<form name="scaffoldSpecificationForm" [formGroup]="form" *ngIf="!isLoading">
    <div class="multistep-table">

      <hub-form-group label="Scaffold Type" name="scaffoldType" [control]="scaffoldTypeFormControl">
        <hub-select-with-info formControlName="scaffoldType" optionPlaceholder="-- Select Scaffold Type -- "
          furtherInfoPlaceholder="Enter the type of scaffold" [options]="scaffoldTypeOptions" titleProperty="Title"
          idProperty="ScaffoldTypeId" requiresInfoProperty="RequireFurtherInformation">
        </hub-select-with-info>
      </hub-form-group>

      <hub-form-group label="Scaffold Description" name="description" [control]="descriptionFormControl">
        <input type="text" class="form-control" formControlName="description" ngxTrim>
      </hub-form-group>

      <hub-form-group label="Scaffolding System" name="scaffoldingSystem" [control]="scaffoldingSystemFormControl">
        <hub-select-with-info formControlName="scaffoldingSystem" optionPlaceholder="-- Select Scaffolding System -- "
          [options]="scaffoldingSystemOptions" sortByProperty="SortOrder" titleProperty="Title"
          idProperty="ScaffoldingSystemId">
        </hub-select-with-info>
        <div *ngIf="scaffoldingSystemRequiredMessage" class="text-error"><i class="fa fa-warning text-assertive"></i>
          This field is required</div>
      </hub-form-group>

      <div class="row inner-form" *ngIf="isManageStockControlEnabled()">
        <div class="col-md-6">
          <hub-form-group label="Maximum Tonnage" name="maximumTonnage" [control]="maximumTonnageFormControl"
            [validationErrors]="validationErrors">
            <input type="number" class="form-control" formControlName="maximumTonnage">
          </hub-form-group>
        </div>
        <div class="col-md-6">
          <hub-form-group label="Standing Tonnage" name="standingTonnage" [control]="standingTonnageFormControl"
            [validationErrors]="validationErrors">
            <input type="number" class="form-control" formControlName="standingTonnage" ngxTrim>
          </hub-form-group>
        </div>
      </div>


      <hub-form-group label="Add to existing quote?" *ngIf="quotes.length" name="addToExistingQuoteQuestion"
        [control]="addToExistingQuoteQuestionFormControl">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="addToExistingQuoteQuestion" [value]="true"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="addToExistingQuoteQuestion" [value]="false"> No
            </label>
          </div>
        </div>
      </hub-form-group>

      <hub-form-group *ngIf="addToExistingQuoteQuestionFormControl.value" label="Quote Number" name="quoteDropdown"
        [control]="quoteDropdownFormControl" [validationErrors]="validationErrors">
        <hub-dropdown formControlName="quoteDropdown" [items]="quotes" idProperty="QuoteId" titleProperty="QuoteNumber"
          (valueChanges)="onQuoteDropdownValueChanged($event)" [multiselect]="false"
          icon="fa fa-clipboard text-positive" placeholder="Please choose a quote">
        </hub-dropdown>
      </hub-form-group>

      <hub-form-group *ngIf="!addToExistingQuoteQuestionFormControl.value" label="Quote Number" name="quoteNumber"
        [control]="quoteNumberFormControl" [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="quoteNumber" ngxTrim>
      </hub-form-group>

      <hub-form-group *ngIf="addToExistingQuoteQuestionFormControl.value && quoteDropdownFormControl.value"
        label="Add to existing item?" name="addToExistingItemQuestion" [control]="addToExistingItemQuestionFormControl">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="addToExistingItemQuestion" [value]="true"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="addToExistingItemQuestion" [value]="false"> No
            </label>
          </div>
        </div>
      </hub-form-group>

      <div class="row"
        *ngIf="!addToExistingQuoteQuestionFormControl.value || !addToExistingItemQuestionFormControl.value">
        <div class="col-md-4">
          <hub-form-group label="Item Number" name="itemNumber" [control]="itemNumberFormControl"
            [validationErrors]="validationErrors">
            <input type="text" class="form-control" formControlName="itemNumber" ngxTrim>
          </hub-form-group>
        </div>
        <div class="col-md-8">
          <hub-form-group label="Item Name" name="itemName" [control]="itemNameFormControl"
            [validationErrors]="validationErrors">
            <input type="text" class="form-control" formControlName="itemName" ngxTrim>
          </hub-form-group>
        </div>
      </div>

      <ng-container *ngIf="quotes.length">
        <div *ngIf="addToExistingQuoteQuestionFormControl.value && addToExistingItemQuestionFormControl.value">
          <hub-form-group
            *ngIf="addToExistingQuoteQuestionFormControl.value && addToExistingItemQuestionFormControl.value || !addToExistingItemQuestionFormControl.value"
            label="Item" name="item" [control]="itemDropdownFormControl" [validationErrors]="validationErrors">
            <hub-dropdown formControlName="itemDropdown" [items]="quoteItems" idProperty="ItemId"
              titleProperty="ItemName" titleReferenceProperty="ItemNumber" [multiselect]="false"
              icon="fa fa-list text-positive" (valueChanges)="onItemDropdownValueChanged($event)"
              placeholder="Please choose an item">
            </hub-dropdown>
            <div *ngIf="itemRequiredMessage" class="text-error"><i class="fa fa-warning text-assertive"></i>
              {{ itemRequiredMessage }}</div>
          </hub-form-group>
        </div>
      </ng-container>

      <hub-form-group label="Design Type" name="designType" [control]="designTypeFormControl">
        <hub-select-with-info formControlName="designType" optionPlaceholder="-- Select Design Type -- "
          furtherInfoPlaceholder="Enter a design number" [options]="designTypeOptions" titleProperty="Title"
          idProperty="DesignTypeId" requiresInfoProperty="RequireFurtherInformation">
        </hub-select-with-info>
      </hub-form-group>

      <hub-form-group label="Loading Limit" name="loadingLimit" [control]="loadingLimitFormControl">
        <hub-select-with-info formControlName="loadingLimit" optionPlaceholder="-- Select Loading Limit -- "
          furtherInfoPlaceholder="Enter loading details" [options]="loadingLimitOptions" titleProperty="Title"
          idProperty="LoadingLimitId" requiresInfoProperty="RequireFurtherInformation">
        </hub-select-with-info>
      </hub-form-group>

      <hub-form-group label="Method of Tying" name="methodOfTying" [control]="methodOfTyingFormControl">
        <hub-select-with-info formControlName="methodOfTying" optionPlaceholder="-- Select Method of Tying -- "
          furtherInfoPlaceholder="Enter a method of tying" [options]="methodOfTyingOptions" titleProperty="Title"
          idProperty="MethodOfTyingId" requiresInfoProperty="RequireFurtherInformation">
        </hub-select-with-info>
      </hub-form-group>

      <hub-form-group label="Method of Cladding" name="methodOfCladding" [control]="methodOfCladdingFormControl">
        <hub-select-with-info formControlName="methodOfCladding" optionPlaceholder="-- Select Method of Cladding -- "
          furtherInfoPlaceholder="Enter a method of cladding" [options]="methodOfCladdingOptions" titleProperty="Title"
          idProperty="MethodOfCladdingId" requiresInfoProperty="RequireFurtherInformation">
        </hub-select-with-info>
      </hub-form-group>

      <div class="modal-footer">
        <div class="alert alert-danger server-error" role="alert" *ngIf="serverError">
          {{ serverError }}
        </div>
        <span class="required-note">* Denotes a required field</span>
        <div class="modal-buttons">
          <button class="btn btn-large btn-inverse" type="button" (click)="onPrevious(form.value)"
            [disabled]="saveInProgress">
            <i class="fa fa-chevron-circle-left"></i> Previous</button>
          <button class="btn btn-large button-primary" type="button" (click)="onSubmit(form.value)"
            [disabled]="saveInProgress">
            <i class="fa fa-check"
              [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"></i>
            Save</button>
        </div>
      </div>
    </div>
</form>


import { map } from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SiteDetailQueryService } from '../services/site-detail-query.service';
import { CustomersQueryService } from 'app/core/services/customers-query.service';

// Route guard for ensuring that a site has no contracts before navigating to the route.
@Injectable()
export class NoSiteContractsGuard implements CanActivate {

  constructor(
    private siteDetailQueryService: SiteDetailQueryService,
    private router: Router,
    private customersQueryService: CustomersQueryService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const siteReference = route.parent.parent.params['siteReference'];
    return this.siteDetailQueryService.siteDetailQuery(true, siteReference).pipe(map(site => {
      if (site.Contracts.length) {
        const custObs$ = this.customersQueryService.customersDataChanges();
        let customers;
        custObs$.subscribe(customer => customers = customer);
        const customerIds = site.Contracts.map(c => c.CustomerId);
        const siteCustomers = customers.filter(c => customerIds.includes(c.CustomerId));
        this.router.navigate([state.url, siteCustomers[0].CustomerReference]);
      }
      return !site.Contracts.length;
    }));
  }
}

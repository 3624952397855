import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringConversionService {

  constructor() { }

  /**
   * Returns a string separated with comma from [value1] and [value2]
   * Example:
   * * value1 = ScaffoldName
   * * value2 = ScaffoldDescription
   * Output: "ScaffoldName, ScaffoldDescription"
   * * In case there is no value1 it will return value2 if has data
   * * If it doesn't have value 1 and 2 it will return null
   *
   * @param {string} value1
   * @param {string} value2
   * @returns {string}
   * @memberof StringConversionService
   */
  commaSeparatedString(value1: string, value2: string): string {
    if (value1 && value2 && value1 !== '' && value2 !== '') {
      return `${value1.trim()}, ${value2.trim()}`;
    }
    if (value1 && value1 !== '') {
      return value1.trim();
    } else if (value2 && value2 !== '') {
      return value2.trim();
    }
    return null;
  }

}

import { ModalOptions } from 'ngx-bootstrap/modal';

/**
 * Extended version of the ModalOptions class from ngx-bootstrap. Used to match our modal configurations
 *
 * @export
 * @class SSModalConfig
 * @extends {ModalOptions}
 */
export class SSModalConfig extends ModalOptions {
    // initialState?: {
    //     context: {};
    // };

    /**
     * Gets the default modal configuration
     *
     * @private
     * @static
     * @returns {SSModalConfig}
     * @memberof SSModalConfig
     */
    private static getDefault(): SSModalConfig {
        return {
            animated: true,
            class: 'modal-dialog modal-lg',
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
                // context: {}
            }
        };
    }

    /**
     * Generates an modal config file
     * @param context - This is the data we want to assign and pass to the modal
     * @param  config - This is the config file, if not passed it will be passed as null
     * and use default config file.
     * @returns A new config file
     */
    public static generate(context: Object, config: Object = null): SSModalConfig {
        const newConfig = SSModalConfig.getDefault();
        Object.assign(newConfig, config);
        if (context) {
            Object.assign(newConfig.initialState, context);
        }
        return newConfig;
    }
}

/**
 * This class is used to create a modal dialog.
 *
 * @export
 * @class SSDialogContext
 */
export class SSDialogContext {
    title: string;
    body: string;
    okBtnText?: string;
    cancelBtnText?: string;
    cancelBtnClass?: string;
    dialogClass?: string;
    footerClass?: string;
    headerClass?: string;
    okBtnClass?: string;
    showHeaderClose?: boolean;
    showOnlyCloseBtn?: boolean;

    private static getDefault(): SSDialogContext {
        return {
            title: null,
            body: null,
            okBtnText: 'Yes',
            cancelBtnText: 'No',
            okBtnClass: 'btn button-primary',
            cancelBtnClass: 'btn btn-default',
            headerClass: 'confirm-modal-header',
            footerClass: 'confirm-modal-footer',
            dialogClass: 'modal-dialog',
            showHeaderClose: true,
            showOnlyCloseBtn: false
        };
    }

    /**
     * Generates an modal config file
     *
     * @static
     * @param {Object} config
     * @returns {SSDialogContext}
     * @memberof SSDialogContext
     */
    public static generate(config: Object): SSDialogContext {
        const newConfig = SSDialogContext.getDefault();
        Object.assign(newConfig, config);
        return newConfig;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { fieldTypes } from '../values/field-types';

@Pipe({
  name: 'fieldType'
})
export class FieldTypePipe implements PipeTransform {
  transform(type: number): any {
    switch (type) {
      case fieldTypes.Text:
        return 'Text';
      case fieldTypes.YesNo:
        return 'Yes / No';
      case fieldTypes.YesNoNA:
        return 'Yes / No / N/A';
      default:
        return 'Unknown';
    }
  }
}

export const loadingLimitIds = {
  veryLight: 'f62c55ee-630f-40e3-97c0-161691486705',
  light: '2aa467d6-a52f-4dcd-bc5a-c7058daf9369',
  general: 'd7e0f4a1-c6a6-4e08-b2ad-378c6f3642c8',
  heavy: 'f5ee2e83-c0ae-48c1-8c82-deecf6ea6f75',
  loadingBay10: 'd3c1e631-c338-4305-8351-091a3e5ff54a',
  loadingBay20: 'b05d41be-4108-4fbb-9efd-52ff9fb29613',
  notToBeLoaded: 'b05d41be-4108-4fbb-9efd-52ff9fb296c2',
  specialPurpose: '8d9e044a-3aad-4cce-a544-92e0a7fea5e1'
};

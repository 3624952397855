
import { mergeMap, map } from 'rxjs/operators';
import { ImportEndpointService, ExportEndpointService } from 'app/core/hub-api';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, AsyncSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable()
export class FileUploadService {

  constructor(private importEndpointService: ImportEndpointService,
    private exportEndpointService: ExportEndpointService, private http: HttpClient) { }

  /**
   * Uploads the file to Blob and calls the API to update database
   *
   * @param {File} file
   * @param {string} path
   * @returns {Observable<any>}
   * @memberof FileUploadService
   */
  uploadFile(file: File, path: string): Observable<any> {
    const fileReader = new FileReader();
    const subject = new AsyncSubject();

    fileReader.onload = (e: any) => {
      const paths = {
        [path]: path
      };
      this.importEndpointService.UploadFile(paths).pipe(mergeMap((results) => {
        const uploadKeys = results as any;
        return this.http.put(uploadKeys.UploadPaths[path], e.target.result, {
          headers: new HttpHeaders({
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type,
            'x-ms-blob-content-type': file.type
          }),
        });
      })).subscribe(() => {
        subject.next(true);
        subject.complete();
      });
    };
    fileReader.readAsArrayBuffer(file);

    return subject.asObservable();
  }

  /**
   * Downloads the file by calling the API
   *
   * @param {string} fileName
   * @param {string} path
   * @returns {*}
   * @memberof FileUploadService
   */
  downloadFile(fileName: string, path: string): any {
    this.exportEndpointService.DownloadFile(fileName, path).subscribe(
      val => {
        const blob = new Blob([val]);
        this.generateDownload(blob, fileName);
      });
  }


  /**
   * Download the file
   *
   * @private
   * @param {Blob} blob
   * @param {string} fileName
   * @returns {*}
   * @memberof FileUploadService
   */
  private generateDownload(blob: Blob, fileName: string): any {

    const name = fileName;
    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveOrOpenBlob(blob, name);
    } else {
      saveAs(blob, name);
    }
  }
}


/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { ImageUploadService } from 'app/core/services/image-upload.service';
import { LoggedInStaffService } from 'app/core/services/logged-in-staff-service';
import { fileExtensionRegex } from 'app/shared/regex/file-extension.regex';
import { diaryCategoryIds } from 'app/shared/values/diary-category-ids';
import { errorMessages } from 'app/shared/values/error-messages';
import { uploadMaxAllowedContentLength, uploadMinAllowedContentLength } from 'app/shared/values/file-values';
import { DiaryQueryService } from 'app/sites/services/diary-query-service';
import * as moment from 'moment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { forkJoin } from 'rxjs';
import { CreateSiteDiaryWebConsoleCommand, SiteCommandService, SiteWebAppData, StaffDetailsWebAppData } from 'app/core/hub-api';
import { AddSiteDiaryModalComponentContext } from './add-site-diary-modal-context';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

/**
 * Add Site Diary Modal
 * Shows a modal with a form to create a site diary
 *
 * @export
 * @class AddSiteDiaryModalComponent
 * @extends {FormComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-site-diary-modal',
  templateUrl: './add-site-diary-modal.component.html',
  styleUrls: ['./add-site-diary-modal.component.scss']
})
export class AddSiteDiaryModalComponent extends FormComponent implements OnInit, OnDestroy {
  /** Stores all the parameters for this modal */
  context: Partial<AddSiteDiaryModalComponentContext>;
  /** Form group */
  form: UntypedFormGroup;
  /** Category form control */
  categoryFormControl: UntypedFormControl;
  /** Notes form control */
  notesFormControl: UntypedFormControl;
  /** Stores all selected files from input */
  files = [];
  /** Shows the error message for file size */
  fileSizeErrMsg: string;
  /** File input ngModel */
  sizeRestrictedFile: any;
  /** Gets the file size restrictions */
  public sizeRestrictions: { min: number; max: number; } = {
    min: uploadMinAllowedContentLength,
    max: uploadMaxAllowedContentLength,
  };
  /** Stores the maximum files upload limit */
  maxFilesLimit = 5;
  /** Stores all diary categories */
  diaryCategories: any[];
  /** Stores the selected site reference (e.g. 00001) */
  siteReference: string;
  /** Contains all validation messages for the reactive forms */
  validationMessages = {
    category: {
      required: errorMessages.required
    },
  };
  /** Gets all error messages from errorMessages class */
  errorMessages = errorMessages;
  /** Detects if the form is being saved and triggers the spinning icon on the save button */
  saveInProgress: boolean;
  /** Stores all files that are over the limit size */
  oversizeFiles = [];
  /** Stores all files that have a wrong format */
  wrongFormatFiles = [];
  /** Stores the selected site */
  site: SiteWebAppData;
  /** Generates a new site diary id */
  siteDiaryId = UUID.UUID();
  /** Stores the logged in user details */
  loggedInStaff: StaffDetailsWebAppData;
  /** List of allowed file formats */
  allowedFileFormats = ['.jpg', '.jpeg', '.png', '.exif', '.tiff', '.gif', '.bmp', '.pdf'];

  /**
   * gets the length of errors in the file upload section
   *
   * @readonly
   * @type {String}
   * @memberof AddSiteDiaryModalComponent
   */
  public get errorLength(): String {
    return this.oversizeFiles.length ? ', ' + this.oversizeFiles.length + ' error' + ((this.oversizeFiles.length === 1) ? '' : 's') : '';
  }

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    public loggedInStaffService: LoggedInStaffService,
    public imageUploadService: ImageUploadService,
    public diaryQueryService: DiaryQueryService,
    public siteDiaryQueryService: SiteScaffoldDiaryQueryService,
    public siteCommandService: SiteCommandService,
    public modalService: BsModalService,
  ) { super(); }

  /**
   * Component initialisation
   *
   * @memberof AddSiteDiaryModalComponent
   */
  public ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    // gets the values passed to the modal context
    this.diaryCategories = this.context.diaryCategories;
    this.siteReference = this.context.siteReference;
    this.site = this.context.site;

    // gets the current user login
    this.loggedInStaffService.loggedInStaffChanges().subscribe(lg => {
      this.loggedInStaff = lg;
    });

    this.createForm();
    super.ngOnInit();
  }

  /**
   * Generates the form with default values
   *
   * @private
   * @memberof AddSiteDiaryModalComponent
   */
  private createForm(): void {
    // defaults the category to uncategorised
    const uncategorised = this.diaryCategories.find((d) => d.DiaryCategoryId === diaryCategoryIds.uncategorised);
    this.categoryFormControl = new UntypedFormControl(uncategorised.DiaryCategoryId, Validators.required);
    this.notesFormControl = new UntypedFormControl(null);
    this.form = new UntypedFormGroup({
      categories: this.categoryFormControl,
      notes: this.notesFormControl,
    });
  }

  /**
   * Gets the file extension from a file
   *
   * @param {File} file selected input file
   * @returns {String}
   * @memberof AddSiteDiaryModalComponent
   */
  public getFileExtensionFromFile(file: File): String {
    return file.name.split('.').pop().toLowerCase();
  }

  /**
   * Generates the error messages for each file
   *
   * @param {File} file selected input file
   * @returns {{ messages: any[]; }}
   * @memberof AddSiteDiaryModalComponent
   */
  public getFileErrorMessage(file: File): { messages: string[]; } {
    const isFileOversize = this.oversizeFiles.includes(file.name);
    const isFileWrongFormat = this.wrongFormatFiles.includes(file.name);
    const messages = [];

    if (isFileOversize) messages.push(this.errorMessages.fileSizeErrorMsg);
    if (isFileWrongFormat) messages.push(this.errorMessages.wrongFormat);

    return { messages };
  }

  /**
   * When a file is selected it checks the validation and adds it to the list
   * NOTE: Used on html only
   *
   * @param {Event} event input DOM
   * @memberof AddSiteDiaryModalComponent
   */
  public onFileSelected(event: Event): void {
    const files = (event.target as any).files;
    const selectedFiles = Array.from(files);
    selectedFiles.forEach((f: File) => {
      // ignore if already exists on the array
      if (this.files.find((fl) => fl.name === f.name)) return;
      const fileExtension = this.getFileExtensionFromFile(f);
      // in case the file format is not accepted it will add it to the error list
      if (!this.allowedFileFormats.includes(`.${fileExtension}`)) this.wrongFormatFiles.push(f.name);
      if (f.size > this.sizeRestrictions.max) this.oversizeFiles.push(f.name);
      // Remove the file extension.
      const mediaName = f.name.replace(fileExtensionRegex, '');
      (f as any).mediaName = mediaName;
      this.files.push(f);
    });
  }


  /**
   * Removes the file form the error logger (presented to user)
   *
   * @private
   * @param {File} file input selected file
   * @memberof AddSiteDiaryModalComponent
   */
  private removeFileFromErrorLog(file: File): void {
    // if the file is in oversize error list
    if (this.oversizeFiles.includes(file.name)) {
      const index = this.oversizeFiles.indexOf(file.name);
      if (index != null) this.oversizeFiles.splice(index, 1);
    }
    // if the file is in wrong format error list
    if (this.wrongFormatFiles.includes(file.name)) {
      const index = this.wrongFormatFiles.indexOf(file.name);
      if (index != null) this.wrongFormatFiles.splice(index, 1);
    }
  }


  /**
   * When user removes file
   * NOTE: used on html only
   *
   * @param {File} file selected input file
   * @param {number} i index of the files array
   * @memberof AddSiteDiaryModalComponent
   */
  public onRemoveFile(file: File, i: number): void {
    this.removeFileFromErrorLog(file);
    this.files.splice(i, 1);
  }

  /**
   * Detects if the file has errors
   * NOTE: used on html only
   *
   * @param {File} file selected input file
   * @returns {boolean}
   * @memberof AddSiteDiaryModalComponent
   */
  public onFileError(file: File): boolean {
    return file.size > this.sizeRestrictions.max || this.isFileWrongFormat(file);
  }

  /**
   * Detects if the file has a wrong format
   *
   * @param {File} file selected input file
   * @returns {boolean}
   * @memberof AddSiteDiaryModalComponent
   */
  public isFileWrongFormat(file: File): boolean {
    const fileExtension = this.getFileExtensionFromFile(file);
    return !this.allowedFileFormats.includes(`.${fileExtension}`);
  }


  /**
   * Checks the form validation by checking the format and size limit
   *
   * @returns
   * @memberof AddSiteDiaryModalComponent
   */
  public formValidation(): boolean {
    // file error validation checker
    if (this.wrongFormatFiles.length || this.oversizeFiles.length) {
      const sumErrors = this.oversizeFiles.length + this.wrongFormatFiles.length;
      this.serverError = `${sumErrors.toString()} errors found, please review the files to upload.`;
      this.saveInProgress = false;
      return false;
    }
    // file limit validation checker
    if (this.files.length > this.maxFilesLimit) {
      this.serverError = `Please select a maximum of ${this.maxFilesLimit.toString()} files. More files can be added later when reviewing the Site Diary entry.`;
      this.saveInProgress = false;
      return false;
    }
    // default form validation checker
    if (this.validateForm()) { return true; }
  }

  /**
   * Sends the new site diary to api
   * All images will be individually uploaded to blob
   * Will throw a server error in case the http request is not successfull
   *
   * @memberof AddSiteDiaryModalComponent
   */
  public onSubmit(): void {

    if (this.formValidation()) {
      this.saveInProgress = true;
      const mediaFiles = [];
      const filePath = `Site/${this.site.SiteId}/SiteDiary/${this.siteDiaryId}/`;

      // creates the media files structure to be added to the command
      this.files.forEach(file => {
        mediaFiles.push({
          MimeType: file.type,
          Path: filePath + file.name,
          Title: file.mediaName
        });
      });

      // sends the files to blob with the correct file path, in case it fails it will display an error message to the user
      this.files.forEach((file) => {
        this.imageUploadService.uploadImage(file, filePath + file.name).subscribe(() => {
          console.log('File Uploaded', file);
        }, this.serverErrorCallback);
      });

      // gets the selected category (dropdown)
      const selectedCategory = this.diaryCategories.find(dc => dc.DiaryCategoryId === this.form.value.categories);

      const command: CreateSiteDiaryWebConsoleCommand = {
        DiaryId: this.siteDiaryId,
        By: this.loggedInStaff.StaffId,
        Media: mediaFiles,
        SiteId: this.site.SiteId,
        When: moment().toISOString(),
        OffsetFromUtc: moment().utcOffset(),
        Category: selectedCategory,
        Notes: this.form.value.notes,
        CommandVersion: '',
        SourceApp: 'WebConsole',
        SourceAppVersion: ''
      };
      // sends the command to create a new site diary, in case it fails it will display an error message to the user
      this.siteCommandService.CreateSiteDiaryWebConsoleCommand(command).subscribe(() => {
        forkJoin([
          this.diaryQueryService.diaryQuery(false, command.DiaryId),
          this.siteDiaryQueryService.siteScaffoldDiaryQuery(false, this.siteReference)
        ]).subscribe(() => {
          this.saveInProgress = false;
          this.bsModalService.setDismissReason(command as any);
          this.bsModalRef.hide();
        }, this.serverErrorCallback);
      }, this.serverErrorCallback);
    }
  }

  /**
   * Required by the AutoUnsubscriber
   *
   * @memberof AddSiteDiaryModalComponent
   */
  public ngOnDestroy(): void { }
}

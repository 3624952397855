import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DepotData, StaffDetailsWebAppData, ConfigurationData, StaffData, SiteWebAppData } from 'app/core/hub-api';
import { Router, ActivatedRoute } from '@angular/router';
import { LimitReachedModalContext } from 'app/shared/models/limit-reached-modal-context';
import { AddEditSiteModalComponent } from 'app/sites/components/sites-list/add-edit-site-modal/add-edit-site-modal.component';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { AddEditStaffModalComponent } from '../add-edit-staff-modal/add-edit-staff-modal.component';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';
import { FormComponent } from '../form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@Component({
  selector: 'hub-limit-reached-modal',
  templateUrl: './limit-reached-modal.component.html',
  styleUrls: ['./limit-reached-modal.component.scss']
})
export class LimitReachedModalComponent extends FormComponent implements OnInit, OnDestroy {
  context: Partial<LimitReachedModalContext>;
  editMode = false;
  addEditText: string;
  ClientMaxSitesLimit: number;
  NoOfOpenSites: number;
  ClientMaxStaffsLimit: number;
  NoOfOpenStaffs: number;
  depots: DepotData[];
  loggedInStaff: StaffDetailsWebAppData;
  configuration: ConfigurationData;
  title: string;
  validationMessages = {};
  form: UntypedFormGroup;
  type: string;
  mainMessageSubString: string;
  noteMessageSubString: string;
  cancel = new EventEmitter();
  allStaffs: StaffData[];
  closeDeleteText: string;
  site: SiteWebAppData;
  staff: StaffDetailsWebAppData;
  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private configurationQueryService: ConfigurationQueryService,
    private companySettingsService: CompanySettingsService,
    private bsModalService: BsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.configuration = this.context.configuration;
    this.depots = this.context.depots;
    this.loggedInStaff = this.context.loggedInStaff;
    this.type = this.context.type;
    this.title = this.editMode ? `Edit ${this.type}` : `Add ${this.type}`;
    this.addEditText = this.editMode ? `re-opening a ${this.type}` : `adding a new ${this.type}`;
    this.closeDeleteText = this.type === 'site' ? 'close an open one' : 'delete one';
    this.ClientMaxSitesLimit = this.context.ClientMaxSitesLimit;
    this.NoOfOpenSites = this.context.NoOfOpenSites;
    this.ClientMaxStaffsLimit = this.context.ClientMaxStaffsLimit;
    this.NoOfOpenStaffs = this.context.NoOfOpenStaffs;
    this.site = this.context.site;
    this.allStaffs = this.context.staffs;
    this.staff = this.context.staff;
    this.mainMessageSubString = this.type === 'site' ? 'open sites' : 'staff members';
    this.noteMessageSubString = this.type === 'site' ? 'Sites' : 'Staff Members';
  }

  onRefresh(): void {
    this.saveInProgress = true;
    this.companySettingsService.getCompanyDetailsForceFetchFromServer().subscribe(clientSettings => {
      this.ClientMaxSitesLimit = clientSettings.MaxOpenSites;
      this.ClientMaxStaffsLimit = clientSettings.MaxStaff;

      this.configurationQueryService.configurationQueryFromServer().subscribe(configuration => {
        this.NoOfOpenSites = configuration.CompanyStatisticData.NoOfOpenSite;
        this.NoOfOpenStaffs = configuration.CompanyStatisticData.NoOfNonDeletedStaff;
        this.saveInProgress = false;

        if (this.type === 'site' && !this.editMode && this.NoOfOpenSites < this.ClientMaxSitesLimit) {
          this.bsModalRef.hide();

          const context = {
            editMode: false,
            configuration: configuration,
            depots: this.depots,
            loggedInStaff: this.loggedInStaff,
            stockControl: clientSettings.FeatureFlags.stockControl
          };
          const modal = this.bsModalService.show(AddEditSiteModalComponent, SSModalConfig.generate(context))
          modal.onHidden.subscribe(
            siteReference => {
              if (siteReference === null || typeof siteReference !== 'string') return;
              this.router.navigate([`/sites/${siteReference}`], { relativeTo: this.route });
            },
            error => {
              console.log(error);
            },
            () => { }
          );
        }

        if (this.type === 'site' && this.editMode && this.NoOfOpenSites < this.ClientMaxSitesLimit) {
          this.bsModalRef.hide();
          const context = {
            editMode: true,
            configuration: configuration,
            depots: this.depots,
            loggedInStaff: this.loggedInStaff,
            site: this.site,
            stockControl: clientSettings.FeatureFlags.stockControl
          };
          this.bsModalService.show(AddEditSiteModalComponent, SSModalConfig.generate(context));
        }

        if (this.type === 'staff' && !this.editMode && this.NoOfOpenStaffs < this.ClientMaxStaffsLimit) {
          this.bsModalRef.hide();
          const context = {
            editMode: false,
            staffs: this.allStaffs,
            configuration: configuration,
            depots: this.depots,
            loggedInStaff: this.loggedInStaff
          };
          const modal = this.bsModalService.show(AddEditStaffModalComponent, SSModalConfig.generate(context))
          modal.onHidden.subscribe(
            staffId => {
              if (staffId === null || typeof staffId !== 'string') return;
              this.router.navigate([`/staff/${staffId}`], { relativeTo: this.route });
            },
            error => {
              console.log(error);
            },
            () => { }
          );
        }

        if (this.type === 'staff' && this.editMode && this.NoOfOpenStaffs < this.ClientMaxStaffsLimit) {
          this.bsModalRef.hide();
          const context = {
            editMode: true,
            staff: this.staff,
            staffs: this.allStaffs,
            configuration: configuration,
            depots: this.depots,
            loggedInStaff: this.loggedInStaff
          };
          this.bsModalService.show(AddEditStaffModalComponent, SSModalConfig.generate(context));
        }
      });
    });
  }

  ngOnDestroy(): void { }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Pagination } from '../models/estimate';

@Component({
    selector: 'hub-estimates-table',
    templateUrl: './estimates-table.component.html',
    styleUrls: ['./estimates-table.component.scss']
})
/**
 * Represents the EstimatesTableComponent class.
 * This component is responsible for displaying the estimates table.
 */
export class EstimatesTableComponent {
    @ViewChild('estimatesTable', { static: false }) estimatesTable: DatatableComponent;
    /**
     * The column mode for the estimates table.
     * Determines how the columns should behave when resizing or scrolling.
     */
    ColumnMode = ColumnMode;

    /** The input property that holds the rows data for the estimates table. */
    @Input() rows: any[];

    /** The input property that holds the columns data for the estimates table. */
    @Input() columns: any[];

    /** The input property that holds all the possible statuses for the estimates table. */
    @Input() allStatuses: any[];

    /** The input property that holds the current page data for the estimates table. */
    @Input() page: Pagination;

    /** Sets the table in loading mode */
    @Input() isLoading: boolean;

    /** Gets the sorting set by the user */
    @Input() sorts: any[] = [];

    /** The output property that emits an event when the page changes in the estimates table. */
    @Output() pageChange = new EventEmitter<any>();

    /** The output property that emits an event when the start action is triggered on a row in the estimates table. */
    @Output() start = new EventEmitter<any>();

    /** The output property that emits an event when the completed action is triggered on a row in the estimates table. */
    @Output() completed = new EventEmitter<any>();

    /** The output property that emits an event when the in progress action is triggered on a row in the estimates table. */
    @Output() inProgress = new EventEmitter<any>();

    /** The output property that emits an event when the page has changed */
    @Output() footerPageChanged = new EventEmitter<any>();

    /** The output property that emits an event when the user has sorted the table */
    @Output() sort = new EventEmitter<any>();

    @Output() statusChanged = new EventEmitter<any>();
    /**
     * Handles the status changed event.
     * @param event - The event object containing the new status data.
     */
    public statusChanges(newStatus: { Code: string, Description: string }, projectId: string) {
        var data = {
            ProjectId: projectId,
            StatusId: newStatus.Code,
            StatusDescription: newStatus.Description
        }
        this.statusChanged.emit(data);
    }
    /**
     * Sets the page for the estimates table.
     * @param event - The event object containing the new page data.
     */
    setPage(pageInfo: { offset: number }): void {
        this.pageChange.emit({ page: pageInfo.offset + 1 });
    }

    /**
     * Triggers the start action on a specific row in the estimates table.
     * @param row - The row object on which the start action is triggered.
     */
    public onStart(row: any) {
        this.start.emit(row);
    }

    /**
     * Triggers the completed action on a specific row in the estimates table.
     * @param row - The row object on which the completed action is triggered.
     */
    public onCompleted(row: any) {
        this.completed.emit(row);
    }

    /**
     * Triggers the in progress action on a specific row in the estimates table.
     * @param row - The row object on which the in progress action is triggered.
     */
    public onInProgress(row: any) {
        this.inProgress.emit(row);
    }

    /**
     * Matches the given estimate status code with its corresponding description.
     * 
     * @param status - The estimate status code to match.
     * @returns The description of the matched estimate status, or an empty string if no match is found.
     */
    public matchEstimateStatus(status: number) {
        var selectedStatus = this.allStatuses.find(s => s.Code === status);
        return selectedStatus ? selectedStatus.Description : '';
    }

    /**
     * Handles the page change event.
     * 
     * @param event - The page change event object containing the new page number.
     */
    public handlePageChange(event: { page: number }): void {
        this.footerPageChanged.emit(event);
        this.estimatesTable.sorts = this.sorts;
    }

    /**
     * Handles the sort event.
     * @param event - The sort event object.
     */
    public onSort(event) {
        const sort = event.sorts[0]; // Get the first sort (if multi-sort, this will be the primary)
        this.sorts = [sort]; // Update the sorts array with the new sort object
        this.sort.emit(this.sorts);

        // Workaround to existing bug on ngx-datatable https://github.com/swimlane/ngx-datatable/issues/765#issuecomment-377610554
        this.estimatesTable.bodyComponent.updateOffsetY(this.page.CurrentPage);
        this.estimatesTable.offset = this.page.CurrentPage - 1;
        this.sorts = [sort];
        this.estimatesTable.sorts = this.sorts;
    }
}
<div class="content container-fluid padding-20px">

  <div class="row">
    <div class="col-md-6">
      <div class="ss-container no-padding relative" id="handovers-scaffoldstatus">
        <h4 class="dash-header">
          <i class="fa fa-dashboard" aria-hidden="true"></i> Current Scaffold Status</h4>
        <hub-loading-spinner *ngIf="!scaffoldStatisticsData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div class="ss-db-container" *ngIf="scaffoldStatisticsData">
            <hub-handovers-scaffold-status-chart [scaffoldData]="scaffoldStatisticsData">
            </hub-handovers-scaffold-status-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="ss-container no-padding relative" id="handovers-onhire">
        <h4 class="dash-header">
          <i class="fa fa-dashboard" aria-hidden="true"></i> Scaffolds in Extra Hire</h4>
        <hub-loading-spinner *ngIf="!scaffoldStatisticsData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div class="ss-db-container" *ngIf="scaffoldStatisticsData">
            <hub-handovers-extra-hire-chart [scaffoldData]="scaffoldStatisticsData"></hub-handovers-extra-hire-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="ss-container no-padding relative" id="handovers-last7days">
        <h4 class="dash-header">
          <i class="fa fa-dashboard" aria-hidden="true"></i> Handovers Last 7 days</h4>
        <hub-loading-spinner *ngIf="!handoverStatisticsData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div class="ss-db-container" *ngIf="handoverStatisticsData">
            <hub-seven-day-handover-chart [handoverData]="handoverStatisticsData"></hub-seven-day-handover-chart>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-9">
      <div class="ss-container no-padding relative" id="inspections-last7days">
        <h4 class="dash-header">
          <i class="fa fa-dashboard" aria-hidden="true"></i> Inspections Last 7 Days</h4>
        <hub-loading-spinner *ngIf="!inspectionData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div *ngIf="inspectionData" class="ss-db-container">
            <hub-seven-day-inspection-chart [inspectionData]="inspectionData" [startDate]="sixDaysAgo"
              [contractId]="contractId"></hub-seven-day-inspection-chart>
          </div>
        </div>
      </div>
      <div class="ss-container no-padding relative" id="inspections-monthly">
        <h4 class="dash-header">
          <i class="fa fa-dashboard" aria-hidden="true"></i> Monthly Inspection Results</h4>
        <hub-loading-spinner *ngIf="!inspectionData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div class="ss-db-container" *ngIf="inspectionData">
            <hub-twelve-month-inspection-chart [inspectionData]="inspectionData" [startDate]="elevenMonthsAgo"
              [contractId]="contractId"></hub-twelve-month-inspection-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="ss-container no-padding relative" id="inspections-today">
        <h4 class="dash-header">
          <i class="fa fa-line-chart" aria-hidden="true"></i> Inspections Today</h4>
        <hub-loading-spinner *ngIf="!inspectionData"></hub-loading-spinner>
        <div class="padding-10px charts-min-height">
          <div class="ss-db-container" *ngIf="inspectionData">
            <hub-one-day-inspection-chart [inspectionData]="inspectionData" [date]="today" [contractId]="contractId">
            </hub-one-day-inspection-chart>
          </div>
        </div>
      </div>
      <div class="ss-container no-padding relative" id="inspections-duenext7days">
        <div class="padding-10px" *ngIf="inspectionData">
          <hub-seven-day-inspections-due-chart [inspectionData]="inspectionData" [startDate]="tomorrow"
            [hideList]="true"></hub-seven-day-inspections-due-chart>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="site-header">
    <div class="pull-left">
        <div class="inline-block">
            <h1><i class="fa fa-exclamation-circle" aria-hidden="true"></i> 404 Error</h1>
            <p>Page not found.</p>
        </div>
    </div>
  </div>
  <div class="row margin-top">
    <div class="col-md-4 col-md-offset-4 empty-state-placeholder">
        <img src="assets/img/house.png" width="564" class="img-responsive">
        <h3>Something's gone wrong!</h3>
        <p>The page you were looking for appears to have been moved, deleted or does not exist. You could go <a (click)="back()" class="text-positive bold">back</a> to where you were or head to the <a [routerLink]="['/home']" class="text-positive bold">home page</a>.</p>
    </div>
  </div>

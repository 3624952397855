<form [formGroup]="form" *ngIf="items">

    <ng-select #dropdownComponent [items]="items" [bindLabel]="titleProperty" [bindValue]="idProperty" [searchable]="searchable"
        formControlName="id" [multiple]="multiselect" [placeholder]="placeholder" [clearable]="clearable"
        [selectableGroupAsModel]="selectableGroupAsModel" editableSearchTerm="true" [selectableGroup]="selectableGroup"
        [notFoundText]="searchEmptyResultText" [readonly]="readonly" [addTag]="addTag" [addTagText]="addTagText"
        [closeOnSelect]="closeOnSelect ? closeOnSelect : multiselect ? false : true" [loading]="loading"
        (focus)="onFocus($event)" [clearSearchOnAdd]="clearSearchOnAdd" [groupBy]="groupBy" (change)="onChange($event)">
        <ng-template ng-header-tmp *ngIf="multiselect">
            <div class="select-all-section">
                <button class="btn btn-success" (click)="onSelectAll()"><i class="fa fa-check"></i>{{ checkAllText
                    }}</button>
                <button class="btn btn-default" (click)="onClearAll()"><i class="fa fa-times"></i>{{ uncheckAllText
                    }}</button>
            </div>
        </ng-template>

        <ng-template *ngIf="groupBy" ng-optgroup-tmp let-item="item">
            <span class="group-by">
                <i *ngIf="groupIcon" class="fa {{groupIcon}}"></i> <span
                    [ngOptionHighlight]="search">{{item[groupBy]}}</span>
            </span>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <ng-container *ngIf="items">
                <div class="ng-value" *ngFor="let item of items | slice:0:itemsToDisplay">
                    <span class="ng-value-label text-and-icon">
                        <i *ngIf="icon && item[titleProperty] && item[titleProperty] !== ''" class="fa {{icon}}"></i> {{item[titleProperty]}} <span
                            *ngIf="titleReferenceProperty && item[titleReferenceProperty]">({{item[titleReferenceProperty]}})</span></span>
                    <span *ngIf="clearItemOption" class="ng-value-icon right" (click)="clear(item)"
                        aria-hidden="true">×</span>
                </div>
                <div class="dropdown-total-selected-count ng-value" *ngIf="items.length > itemsToDisplay">
                    <span class="ng-value-label">{{getLengthSelected()}}</span>
                </div>
            </ng-container>
        </ng-template>
        <ng-template class="dropdown-items" ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="dropdown-finder">
                <p class="text-and-icon">
                    <i *ngIf="icon && item[titleProperty]" class="fa {{icon}}"></i> <span [ngOptionHighlight]="search">{{item[titleProperty]}}
                        <span
                            *ngIf="titleReferenceProperty && item[titleReferenceProperty]">({{item[titleReferenceProperty]}})</span></span>
                </p>
                <p class="description">
                    <i *ngIf="descriptionIcon" class="fa {{descriptionIcon}}"></i> <span
                        [ngOptionHighlight]="search">{{item[description]}}</span>
                </p>
            </div>
        </ng-template>
    </ng-select>
</form>

import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { StaffDetailQueryService } from './staff-detail-query.service';
import { mergeMap } from 'rxjs/operators';
import { StaffDetailsWebAppData } from '../hub-api';
import { AuthenticationEventsService, UserIdentityService } from '../authentication';

// Service for listening to changes to the logged in staff - including both changes to which staff is logged in,
// and changes to the staff's data (ie name, email etc..).
@Injectable()
export class LoggedInStaffService {
  constructor(
    private staffDetailQueryService: StaffDetailQueryService,
    private authenticationEventsService: AuthenticationEventsService,
    private userIdentityService: UserIdentityService,
  ) { }


  loggedInStaffChanges(): Observable<StaffDetailsWebAppData> {
    return this.authenticationEventsService.userUpdatedEvent.asObservable().pipe(
      mergeMap(user => {
        if (!user) {
          return of(undefined);
        }
        return this.staffDetailQueryService.staffDetailDataChanges(user.userId);
      })
    );
  }

  loggedInStaffChangesFromServer(): Observable<any> {
    return this.authenticationEventsService.userUpdatedEvent.asObservable().pipe(
      mergeMap(user => {
        if (!user) {
          return of(undefined);
        }
        return this.staffDetailQueryService.getDataFromServer(user.userId);
      })
    );
  }

  loggedInStaffQuery(useCached: boolean): Observable<StaffDetailsWebAppData> {
    return this.staffDetailQueryService.staffDetailQuery(useCached, this.userIdentityService.currentUser().userId);
  }
}


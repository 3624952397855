import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { cloneDeep } from 'lodash';
import {
  ContractContactData,
  ContractData,
  StaffData,
  CreateEditBccContractContactCommand,
} from 'app/core/hub-api';
import {
  ContractCommandService,
  SiteWebAppData
} from 'app/core/hub-api';
import { contactTypeIds } from '../../../../../shared/values/contact-type-ids';
import { AddEditBccContactModalContext } from '../../../../models/add-edit-bcc-contact-modal-context';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-bcc-contact-modal',
  templateUrl: './add-edit-bcc-contact-modal.component.html',
  styleUrls: ['./add-edit-bcc-contact-modal.component.scss']
})
export class AddEditBccContactModalComponent extends FormComponent implements OnInit {
  context: Partial<AddEditBccContactModalContext>;
  editMode: boolean;
  contract: ContractData;
  contractContact: ContractContactData;
  site: SiteWebAppData;
  modalTitle: string;
  errorMessage: boolean;
  form: UntypedFormGroup;
  emailAddressFormControl: UntypedFormControl;
  staffs: StaffData[];

  validationMessages = {
    emailAddress: {
      pattern: errorMessages.emailInvalid
    }
  };

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private contractCommandService: ContractCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    private staffsQueryService: StaffsQueryService,
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): any {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.contract = this.context.contract;
    this.site = this.context.site;
    this.contractContact = cloneDeep(this.context.contractContact) || this.getNewBccContact();
    this.modalTitle = this.editMode ? `Edit Bcc Email Address ` : `Add Bcc Email Address`;
    this.emailAddressFormControl = new UntypedFormControl(this.contractContact.EmailAddress, [Validators.pattern(emailAddressRegex)]);
    this.staffsQueryService.staffsDataChanges(false).subscribe(staffs => this.staffs = staffs);
    this.form = new UntypedFormGroup({
      emailAddress: this.emailAddressFormControl,

    });

    super.ngOnInit();
  }
  getNewBccContact(): ContractContactData {
    return Object.assign(new ContractContactData(), {
      ContractContactId: UUID.UUID(),
      EmailAddress: '',
    });
  }


  onSubmit(formValues): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const command: CreateEditBccContractContactCommand = {
        ContractContactId: this.contractContact.ContractContactId,
        EmailAddress: formValues.emailAddress,
        ContractId: this.contract.ContractId,
        ContactTypeId: contactTypeIds.bccContact,
        IsInactive: false
      };


      if (command.EmailAddress.trim() === '') {
        command.IsInactive = true;
      }

      const commandFunction = this.contractCommandService.CreateEditBccContractContactCommand;

      commandFunction.call(this.contractCommandService, command)
        .subscribe(() => {
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference)
            .subscribe(() => {
              this.saveInProgress = false;
              this.bsModalService.setDismissReason(command.ContractId);
              this.bsModalRef.hide();
            });
        }, this.serverErrorCallback);
    }
  }

}

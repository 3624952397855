import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import {
  uniqBy as _uniqBy,
  flatMap as _flatMap,
  forEach as _forEach,
} from 'lodash';
import { ContractData, DiaryTypeEnum } from 'app/core/hub-api';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomerApprovalModalContext } from 'app/activity/models/customer-approval-modal-context.model';
import { CustomerApprovalImportModalComponent } from '../customer-approval-file-import/customer-approval-file-import.component';
import { FileUploadService } from 'app/core/services/file-upload-service';
import { contactTypeIds } from 'app/shared/values/contact-type-ids';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@AutoUnsubscribe()
@Component({
  selector: 'hub-variation-view',
  templateUrl: './variation-view.component.html',
  styleUrls: ['./variation-view.component.scss'],
})
export class VariationViewComponent implements OnInit, OnDestroy {
  ActivityType: Number;
  ActivityDetail: any;
  RequestedBy: string;
  HandoverDetails = [];
  SiteReference: String;
  staffName: {};
  staffCompanyPosition: string;
  siteInstructedBy: {};
  customerContact: string;
  activeContract: ContractData;
  scaffoldTitle: { name: string; other: any };
  dimensions = [];
  risks: any[];
  handoverRiskReasons: any;
  handoverRiskTypes: any;
  activityName: string;
  customerContactId: string;
  fileName: string;
  fileAttachmentDate: any;
  context: Partial<ActivityModalContext>;


  constructor(
    private configurationQueryService: ConfigurationQueryService,
    private staffsQueryService: StaffsQueryService,
    private fileUploadService: FileUploadService,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.ActivityType = this.context.ActivityType;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.activeContract = this.context.Site.Contracts.find(
      (siteContract) => {
        return (
          siteContract.ContractReference ===
          this.ActivityDetail.SiteDetails.ContractReference
        );
      }
    );
    this.siteInstructedBy = this.siteInstructedContact();
    this.SiteReference = this.context.Site.SiteReference;
    this.customerContact = this.getCustomerContact();
    this.customerContactId = this.getCustomerContactId();
    // sliced 'covi-' from the filename eg. covi-file.pdf, then the filename would be file.pdf in UI
    this.fileName =
      this.ActivityDetail.CustomerAttachment.length > 0
        ? this.ActivityDetail.CustomerAttachment[0].FileName.slice(5)
        : undefined;
    this.fileAttachmentDate =
      this.ActivityDetail.CustomerAttachment.length > 0
        ? this.ActivityDetail.CustomerAttachment[0].When
        : undefined;
    this.generateHandoverDetails();
    this.getStaffNames();
    this.generateDimensions();
    this.risks = _flatMap(this.ActivityDetail.IdentifiedRisks);
    this.activityName =
      this.ActivityType === DiaryTypeEnum.Adaption ? 'Adaption' : 'Variation';
  }

  getRiskReason(riskReasonId): any {
    if (!this.handoverRiskReasons) return null;
    return this.handoverRiskReasons[riskReasonId]
      ? this.handoverRiskReasons[riskReasonId]
      : null;
  }

  getRiskType(riskTypeId): any {
    if (!this.handoverRiskTypes) return null;
    return this.handoverRiskTypes[riskTypeId]
      ? this.handoverRiskTypes[riskTypeId]
      : null;
  }

  getImageArray(media): string[] {
    if (Object.keys(media).length) {
      const keys = [];
      _forEach(media, (m) => {
        keys.push(m);
      });
      return keys;
    }
    return;
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  /**
   * Gets Customer Contact Name
   *
   * @private
   * @returns {*}
   * @memberof VariationViewComponent
   */
  private getCustomerContact(): any {
    if (this.ActivityDetail.CustomerSignature) {
      return this.activeContract.ContractContacts[
        this.ActivityDetail.CustomerSignature.ContractContactId
      ].ContactName;
    }
    if (
      this.ActivityDetail &&
      this.ActivityDetail.CustomerAttachment.length > 0
    ) {
      return this.activeContract.ContractContacts[
        this.ActivityDetail.CustomerAttachment[0].ContractContactId
      ].ContactName;
    }
  }

  private getStaffNames(): void {
    this.staffsQueryService.staffsQuery(false).subscribe((res) => {
      this.staffName = _flatMap(res).filter(
        (s) => s.StaffId === this.ActivityDetail.StaffSignature.StaffId
      );
      this.staffCompanyPosition = _flatMap(res).filter(s => s.StaffId === this.ActivityDetail.StaffSignature.StaffId)[0].CompanyPosition
    });
  }

  private siteInstructedContact(): any {
    return this.activeContract.ContractContacts[
      this.ActivityDetail.VariationInstruction.SiteInstructionReceivedFromId
    ].ContactName;
  }

  private generateDimensions(): void {
    if (this.ActivityDetail == null || this.ActivityDetail.Dimensions == null) {
      return;
    }

    this.ActivityDetail.Dimensions.forEach((dms) => {
      const dimension = [];
      dimension.push(
        {
          name: 'Quantity',
          value: dms.Quantity ? dms.Quantity : '',
        },
        {
          name: 'Length',
          value: dms.Length ? dms.Length : '',
        },
        {
          name: 'Width',
          value: dms.Width ? dms.Width : '',
        },
        {
          name: 'Height',
          value: dms.Height ? dms.Height : '',
        },
        {
          name: 'Boarded Lifts',
          value: dms.TotalNumberOfBoardedLift
            ? dms.TotalNumberOfBoardedLift
            : '',
        },
        {
          name: 'Total Lifts',
          value: dms.TotalNumberOfLift ? dms.TotalNumberOfLift : '',
        }
      );

      this.dimensions.push(dimension);
    });
  }

  private generateHandoverDetails(): void {
    this.configurationQueryService.configurationQuery(true).subscribe((res) => {
      const scaffold = this.ActivityDetail.Scaffolds.find((s) => {
        return (
          s.ScaffoldNumber === this.ActivityDetail.ScaffoldDetails.ScaffoldName
        );
      });
      this.handoverRiskTypes = res.HandoverRiskTypes;
      this.handoverRiskReasons = res.HandoverRiskReasons;
      if (!scaffold || !scaffold.ScaffoldSpecification) {
        return;
      } else {
        this.HandoverDetails.push(
          {
            name: 'Design No:',
            value: scaffold.ScaffoldSpecification.DesignTypeId
              ? res.DesignTypes[scaffold.ScaffoldSpecification.DesignTypeId]
                .Title
              : null,
            other: scaffold.ScaffoldSpecification.DesignTypeFurtherInformation,
          },
          {
            name: 'Quote No:',
            value: scaffold.ScaffoldSpecification.QuoteNumber,
          },
          {
            name: 'Item No:',
            value: scaffold.ScaffoldSpecification.QuoteItemNumber,
          },
          {
            name: 'Item Name:',
            value: scaffold.ScaffoldSpecification.QuoteItemName,
          },
          {
            name: 'Loading:',
            value: scaffold.ScaffoldSpecification.LoadingLimitId
              ? res.LoadingLimits[scaffold.ScaffoldSpecification.LoadingLimitId]
                .Title
              : null,
            other:
              scaffold.ScaffoldSpecification.LoadingLimitFurtherInformation,
          },
          {
            name: 'Cladding:',
            value: scaffold.ScaffoldSpecification.MethodOfCladdingId
              ? res.MethodOfCladdings[
                scaffold.ScaffoldSpecification.MethodOfCladdingId
              ].Title
              : null,
            other:
              scaffold.ScaffoldSpecification.MethodOfCladdingFurtherInformation,
          },
          {
            name: 'Ties:',
            value: scaffold.ScaffoldSpecification.MethodOfTyingId
              ? res.MethodOfTyings[
                scaffold.ScaffoldSpecification.MethodOfTyingId
              ].Title
              : null,
            other:
              scaffold.ScaffoldSpecification.MethodOfTyingFurtherInformation,
          }
        );
        this.scaffoldTitle = {
          name: scaffold.ScaffoldSpecification.ScaffoldTypeId
            ? res.ScaffoldTypes[scaffold.ScaffoldSpecification.ScaffoldTypeId]
              .Title
            : null,
          other: scaffold.ScaffoldSpecification.ScaffoldTypeFurtherInformation,
        };
      }
    });
  }

  /**
   * Gets ContractContactId
   *
   * @private
   * @returns {*}
   * @memberof VariationViewComponent
   */
  private getCustomerContactId(): any {
    if (
      this.ActivityDetail &&
      this.ActivityDetail.CustomerAttachment.length > 0
    ) {
      return this.activeContract.ContractContacts[
        this.ActivityDetail.CustomerAttachment[0].ContractContactId
      ].ContractContactId;
    }
  }

  /**
   * Opens a modal and Uploads file
   *
   * @memberof VariationViewComponent
   */
  uploadFile(): void {
    const context: CustomerApprovalModalContext = {
      Contracts: this.getContractContacts(),
      HandoverId: this.ActivityDetail.HandoverId,
      CustomerId: this.ActivityDetail.CustomerId,
    };
    this.modalService.show(CustomerApprovalImportModalComponent, SSModalConfig.generate(context)).onHide.subscribe(
      (res) => {
        var value = res as any;
        this.fileName = value;
      },
      error => {
        console.error(error); // This can be left here as it gives us info if any errors
      }
    );
  }

  /**
   * Downloads uploaded file
   *
   * @memberof VariationViewComponent
   */
  downloadFile(): void {
    const path = `Handover/${this.ActivityDetail.HandoverId}/contact/${this.customerContactId}/covi-${this.fileName}`;
    this.fileUploadService.downloadFile(this.fileName, path);
  }

  /**
   * Gets ContractContacts which are active
   *
   * @private
   * @returns {*}
   * @memberof VariationViewComponent
   */
  private getContractContacts(): any {
    const contractContactList = [];

    _forEach(this.activeContract.ContractContacts, (contact) => {
      if (
        !contact.IsInactive &&
        contact.ContactTypeId !== contactTypeIds.companyContact &&
        contact.ContactTypeId !== contactTypeIds.bccContact
      ) {
        contractContactList.push(contact);
      }
    });
    return contractContactList;
  }

  ngOnDestroy(): void { }
}

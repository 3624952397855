<form name="listItemForm" [formGroup]="form">
  <hub-modal-form-header [title]="'Add ' + listTitle"(close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <fieldset>
      <hub-form-group label="Title" name="title" [control]="titleFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="title" ngxTrim>
      </hub-form-group>
      <hub-form-group *ngIf="showReportText" label="Report Text" name="reportText" [control]="reportTextFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="reportText" ngxTrim>
      </hub-form-group>
      <hub-form-group *ngIf="showSubtitle" label="Subtitle" name="subtitle" [control]="subtitleFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="subtitle" ngxTrim>
      </hub-form-group>
      <hub-form-group *ngIf="groupNames && groupNames.length" label="Group" name="groupName" [control]="groupNameFormControl"
        [validationErrors]="validationErrors">
        <hub-dropdown formControlName="groupName" [items]="groupNames" idProperty="name" titleProperty="name"
          (valueChanges)="onSelectedGroupNameChanged($event)" placeholder="Choose a group"></hub-dropdown>
      </hub-form-group>
    </fieldset>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
     (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
<div id="onDayInspectionChart"></div>
<div class="overdueList list-group" *ngIf="!uiSettings.allCleared && !hideList">
    <ul>
        <li class="list-group-item" *ngFor="let site of inspectionsBySite">
            <a [routerLink]="['/sites', site.siteReference]">
                <div class="flex-list">
                    <div class="flex-text-truncated">{{site.siteName}}</div>
                    <div class="badgebadge white-bg">
                        <div class="badge badge-success label-size-fix">{{site.completeCount()}}</div>
                        <div class="badge badge-default label-size-fix">{{site.remainingCount()}}</div>
                        <div class="badge badge-danger label-size-fix">{{site.overdueCount()}}</div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>
<div class='text-center height-150px' *ngIf="uiSettings.allCleared && !hideList">
    <div class="vertical-horizontal-center">
        <i class="fa fa-check fa-3x text-positive"></i>
        <p>All Cleared</p>
    </div>
</div>

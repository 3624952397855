<form name="areaForm" [formGroup]="form">
  <hub-modal-form-header [title]="editMode ? 'Edit Site Area' : 'Add Site Area'" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <fieldset>
      <hub-form-group label="Area Name" name="areaName" [control]="areaNameFormControl" [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="areaName" ngxTrim>
      </hub-form-group>
      <div class="modal-options" *ngIf="editMode">
        <div *ngIf="!areaWasDeleted && !deleteValidationMessage">
          <!--- Delete Area -->
          <a class="pull-left text-danger animate-fade" (click)="deleteArea()" *ngIf="!area.Deleted"><i class="fa fa-times"></i> Delete area</a>
          <!--- Undo Delete -->
          <span class="pull-left animate-fade" *ngIf="area.Deleted"><i class="fa fa-check"></i> This area will be deleted. Click  <a (click)="activateArea()" class="text-danger">undo</a> if this was a mistake!</span>
        </div>

        <div *ngIf="areaWasDeleted && !deleteValidationMessage">
          <!--- Reopen Area -->
          <a class="pull-left text-danger animate-fade" (click)="activateArea()" *ngIf="area.Deleted"><i class="fa fa-check"></i> Reopen area</a>
          <!--- Undo Reopen -->
          <span class="pull-left animate-fade" *ngIf="!area.Deleted"><i class="fa fa-check"></i> The area will be reactivated. Click  <a (click)="deleteArea()" class="text-danger">undo</a> if this was a mistake!</span>
        </div>
        <div *ngIf="deleteValidationMessage" class="text-danger"><i class="fa fa-warning"></i> {{deleteValidationMessage}}</div>
      </div>
    </fieldset>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)" (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>

import { DynamicComponent } from '../../models/dynamic-component';
import { FormComponent } from '../form/form.component';
import { Directive } from "@angular/core";

/**
 * Abstract class for steps in a multi-step modal
 *
 * @export
 * @abstract
 * @class MultiStepModalFormComponent
 * @extends {FormComponent}
 * @implements {DynamicComponent}
 */
@Directive()
export abstract class MultiStepModalFormComponent extends FormComponent implements DynamicComponent {
  context: any;
  data: any;
  nextStep: Function;
  previousStep: Function;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'lodash';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import {
  ContractData,
  CustomerWebAppData,
  ScaffoldItemData,
  ScaffoldTimelineData,
  SiteWebAppData,
  ConfigurationData,
  ScaffoldWebAppData,
  StaffDetailsWebAppData
} from 'app/core/hub-api';
import { DiaryTypeEnum } from 'app/core/hub-api';

import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { ImageModalContext } from '../../../../../shared/components/image-modal/image-modal-context';
import { ImageModalComponent } from '../../../../../shared/components/image-modal/image-modal.component';
import { IdToTitlePipe } from '../../../../../shared/pipes/id-to-title.pipe';
import { inspectionOutcomeStatusIds } from '../../../../../shared/values/inspection-outcome-status-ids';
import { scaffoldStatusIds } from '../../../../../shared/values/scaffold-status-ids';
import { AddEditScaffoldModalContext } from '../../../../models/add-edit-scaffold-modal-context';
import { DismantleScaffoldModalContext } from '../../../../models/dismantle-scaffold-modal-context';
import { ScaffoldDetailQueryService } from '../../../../services/scaffold-detail-query-service';
import { ScaffoldTimelineQueryService } from '../../../../services/scaffold-timeline-query-service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { AddEditScaffoldModalComponent } from '../add-edit-scaffold-modal/add-edit-scaffold-modal.component';
import { DismantleScaffoldModalComponent } from '../dismantle-scaffold-modal/dismantle-scaffold-modal.component';
import { UndismantleScaffoldModalComponent } from '../undismantle-scaffold-modal/undismantle-scaffold-modal.component';
import { OffHireScaffoldModalComponent } from '../off-hire-scaffold-modal/off-hire-scaffold-modal.component';
import { ActivityModalService } from 'app/shared/services/activity-modal.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { mergeMap } from 'rxjs/operators';
import { LoggedInStaffService } from '../../../../../core/services/logged-in-staff-service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'hub-scaffold-detail',
  templateUrl: './scaffold-detail.component.html',
  styleUrls: ['./scaffold-detail.component.scss']
})
export class ScaffoldDetailComponent implements OnInit, OnDestroy {
  customer: CustomerWebAppData;
  site: SiteWebAppData;
  scaffolds: ScaffoldWebAppData[] = [];
  customers: CustomerWebAppData[];
  scaffold: ScaffoldWebAppData;
  scaffoldStatusIds = scaffoldStatusIds;
  contract: ContractData;
  specificationProperties: any[];
  configuration: ConfigurationData;
  timelineEvents: ScaffoldTimelineData[];
  loggedInStaff: StaffDetailsWebAppData;

  diaryTypes = DiaryTypeEnum;

  customerName: string;
  areaName: string;
  nextInspection: string;
  status: string;
  hirePeriod: number;
  weeksOnHire: number;
  currentExtraHire: number;
  overdue: boolean;
  inspectionsCompleted: number;
  inspectionsPassed: number;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private scaffoldDetailQueryService: ScaffoldDetailQueryService,
    private scaffoldTimelineQueryService: ScaffoldTimelineQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private customersQueryService: CustomersQueryService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private idToTitlePipe: IdToTitlePipe,
    private router: Router,
    public activityModal: ActivityModalService,
    private loggedInStaffService: LoggedInStaffService,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.parent.snapshot.params['siteReference'];
    const scaffoldId = this.route.snapshot.params['scaffoldId'];

    this.loggedInStaffService.loggedInStaffChanges().subscribe(loggedInStaff => this.loggedInStaff = loggedInStaff);
    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(siteReference);
    const scaffoldsChanges = siteDetailChanges.pipe(
      mergeMap(site =>
        this.siteScaffoldQueryService.siteScaffoldDataChanges(site.SiteId)
      ));
    const obs$ = combineLatest(
      [siteDetailChanges,
        this.scaffoldDetailQueryService.scaffoldDetailDataChanges(scaffoldId),
        scaffoldsChanges,
        this.scaffoldTimelineQueryService.scaffoldTimelineDataChanges(scaffoldId),
        this.configurationQueryService.configurationDataChanges(),
        this.customersQueryService.customersDataChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2], latest[3], latest[4], latest[5]));
  }

  refreshComponent(
    site: SiteWebAppData,
    scaffold: ScaffoldWebAppData,
    scaffolds: ScaffoldWebAppData[],
    timelineEvents: ScaffoldTimelineData[],
    configuration: ConfigurationData,
    customers: CustomerWebAppData[]
  ): void {
    this.site = site;
    this.scaffolds = scaffolds;
    this.customers = customers;
    this.timelineEvents = timelineEvents;
    this.scaffold = scaffold;
    this.contract = site.Contracts.find(c => c.ContractId === this.scaffold.ContractId);
    this.customer = customers.find(c => c.CustomerId === this.contract.CustomerId);
    this.customerName = this.customer.CustomerName;
    this.areaName = map(site.SiteAreas).find(a => a.SiteAreaId === this.scaffold.SiteAreaId).AreaName;
    this.configuration = configuration;
    this.nextInspection = this.scaffold.DateOfNextInspection;
    this.status = configuration.ScaffoldStatuses[this.scaffold.ScaffoldStatusId].Title;
    this.hirePeriod = scaffold.HirePeriod || 0;
    this.weeksOnHire = scaffold.WeeksOnHire || 0;
    this.currentExtraHire = scaffold.CurrentExtraHire > 0 ? scaffold.CurrentExtraHire : 0;
    this.overdue = moment(scaffold.DateOfNextInspection).isBefore();
    const inspections = timelineEvents.filter(e => e.DiaryType === DiaryTypeEnum.Inspection);
    this.inspectionsCompleted = inspections.length;
    this.inspectionsPassed = inspections.filter(
      i =>
        i.InspectionOutcomeId === inspectionOutcomeStatusIds.noDefects ||
        i.InspectionOutcomeId === inspectionOutcomeStatusIds.defectsFixed
    ).length;

    this.specificationProperties = this.getSpecificationProperties();
  }

  getSpecificationProperties(): any {
    const specification = this.scaffold.ScaffoldSpecification || new ScaffoldItemData();
    return [
      {
        label: 'Scaffold Type',
        value: specification.ScaffoldTypeId
          ? this.idToTitlePipe.transform(specification.ScaffoldTypeId, this.configuration.ScaffoldTypes)
          : '-',
        furtherInfo: specification.ScaffoldTypeFurtherInformation
      },
      {
        label: 'Quote Number',
        value: this.scaffold.QuoteNumber ? this.scaffold.QuoteNumber : '-'
      },
      {
        label: 'Item Number',
        value: this.scaffold.ItemNumber ? this.scaffold.ItemNumber : '-'
      },
      {
        label: 'Item Name',
        value: this.scaffold.ItemName ? this.scaffold.ItemName : '-'
      },
      {
        label: 'Design Number',
        value: specification.DesignTypeId
          ? this.idToTitlePipe.transform(specification.DesignTypeId, this.configuration.DesignTypes)
          : '-',
        furtherInfo: specification.DesignTypeFurtherInformation
      },
      {
        label: 'Ties',
        value: specification.MethodOfTyingId
          ? this.idToTitlePipe.transform(specification.MethodOfTyingId, this.configuration.MethodOfTyings)
          : '-',
        furtherInfo: specification.MethodOfTyingFurtherInformation
      },
      {
        label: 'Cladding',
        value: specification.MethodOfCladdingId
          ? this.idToTitlePipe.transform(specification.MethodOfCladdingId, this.configuration.MethodOfCladdings)
          : '-',
        furtherInfo: specification.MethodOfCladdingFurtherInformation
      },
      {
        label: 'Loading Limit',
        value: specification.LoadingLimitId
          ? this.idToTitlePipe.transform(specification.LoadingLimitId, this.configuration.LoadingLimits)
          : '-',
        furtherInfo: specification.LoadingLimitFurtherInformation
      },
      {
        label: 'Adverse Weather',
        value: this.scaffold.AdverseWeatherIndicator ? 'Affected' : 'Not affected'
      }
    ];
  }

  onEditScaffold(): void {
    const context: AddEditScaffoldModalContext = {
      editMode: true,
      configuration: this.configuration,
      site: this.site,
      customers: this.customers,
      scaffolds: this.scaffolds,
      scaffold: this.scaffold,
      loggedInStaff: this.loggedInStaff
    };
    const modal = this.modalService.show(AddEditScaffoldModalComponent, SSModalConfig.generate(context));
    modal.onHide.subscribe(() => {
      // refreshes the scaffold detail
      this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.scaffold.ScaffoldId);
    });
  }

  private getContext(): DismantleScaffoldModalContext {
    return {
      site: this.site,
      scaffold: this.scaffold
    };
  }

  getCurrentContract(contractId: string): ContractData {
    return this.site.Contracts.find(x => x.ContractId === contractId);
  }

  onDismantleScaffold(): Promise<void> {
    const currentContract = this.getCurrentContract(this.scaffold.ContractId);

    if (this.scaffold.ScaffoldStatusId === scaffoldStatusIds.onHire && !currentContract.AllowDismantleWithoutOffhire) {
      const context: SSDialogContext = {
        title: 'Unable to dismantle scaffold', // Required
        body: '<i class="fa fa-warning text-warning"></i> You must <strong>fully off-hire</strong> the scaffold before dismantling it.',
        okBtnClass: 'btn button-primary',
        okBtnText: 'Ok got it',
        cancelBtnClass: 'btn btn-default',
        cancelBtnText: 'No keep it',
        showHeaderClose: true,
        showOnlyCloseBtn: false,
        dialogClass: 'modal-dialog',
        headerClass: 'confirm-modal-header',
        footerClass: 'confirm-modal-footer'
      };
      const config: ModalOptions = {
        animated: true,
        class: 'modal-warning',
        ignoreBackdropClick: true, // Required
        keyboard: false, // Required
        initialState: {
          // context: context
        }
      };
      this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
      return;
    }
    this.modalService.show(DismantleScaffoldModalComponent, SSModalConfig.generate(this.getContext()));
  }

  onUndismantleScaffold(): void {
    this.modalService.show(UndismantleScaffoldModalComponent, SSModalConfig.generate(this.getContext()));
  }

  offHireScaffold(): void {
    const context = {
      site: this.site,
      scaffold: this.scaffold,
      customer: this.customer
    }
    const modal = this.modalService.show(OffHireScaffoldModalComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe(() => {
      // refreshes the scaffold data
      this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.scaffold.ScaffoldId).subscribe();
    });
  }

  onImageClick(): void {
    const context: ImageModalContext = {
      imageUrl: [this.scaffold.ScaffoldImageUrl]
    };
    this.modalService.show(ImageModalComponent, SSModalConfig.generate(context));
  }

  onDiarySelected(event): void {
    const diary: ScaffoldTimelineData = event.selected[0];
    const diaryType: number = event.selected[0].DiaryType;
    const disallowedModals = [
      DiaryTypeEnum.PauseInspections,
      DiaryTypeEnum.ResumeInspections,
      DiaryTypeEnum.StopInspections
    ];
    if (disallowedModals.includes(diaryType)) {
      return;
    }
    this.activityModal.openActivityModal(diaryType, diary.ScaffoldDiaryId, this.site.SiteReference);
  }

  ngOnDestroy(): void { }
}

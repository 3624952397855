import { QueryService } from './query.service';
import { QuoteData, ContractEndpointService, QuoteQuery, QuoteScaffoldData } from 'app/core/hub-api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class QuotesQueryService extends QueryService<QuoteData[]> {

  constructor( private contractEndpointService: ContractEndpointService) {
    super();
  }

  quotesQuery(useCached: boolean, contractId: string): Observable<QuoteData[]> {
    return super.query(useCached, contractId);
  }

  quotesDataChanges(contractId: string): Observable<QuoteData[]> {
    return super.cachedDataChanges(contractId);
  }

  unassignedScaffoldQuery(contractId: string): Observable<QuoteScaffoldData[]> {
    return this.contractEndpointService.UnassignedScaffoldQuery({ContractId: contractId});
  }

  protected getDataFromServer(contractId: string): Observable<QuoteData[]> {
    return this.contractEndpointService.QuoteQuery(Object.assign(new QuoteQuery(), {ContractId: contractId}));
  }
}

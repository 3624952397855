
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SiteDetailQuery, SiteEndpointService, SiteWebAppData } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries site data from the server (or in memory cache where specified) and allows consumer to subscribe to site data changes.
@Injectable()
export class SiteDetailQueryService extends QueryService<SiteWebAppData> {

  constructor(private siteEndpointService: SiteEndpointService) { super(); }

  siteDetailQuery(useCached: boolean, siteReference: string): Observable<SiteWebAppData> {
    return super.query(useCached, siteReference);
  }

  siteDetailCachedData(siteReference: string): Observable<SiteWebAppData> {
    return super.cachedDataChanges(siteReference);
  }

  /**
   * Gets the site data from the server or in memory cache.
   * @param siteReference - The reference of the site to retrieve data for.
   * @returns An observable of SiteWebAppData.
   */
  siteDetailDataChanges(siteReference: string): Observable<SiteWebAppData> {
    return super.cachedDataChanges(siteReference).pipe(
      switchMap((site) => {
        if (site) {
          return of(site);
        } else {
          return super.query(false, siteReference);
        }
      })
    );
  }

  protected getDataFromServer(siteReference: string): Observable<SiteWebAppData> {
    const query = Object.assign(new SiteDetailQuery(), { SiteReference: siteReference });
    return this.siteEndpointService.SiteDetailQuery(query).pipe(map(data => data[0]));
  }
}

import { Injectable } from '@angular/core';
import { HandoverViewComponent } from 'app/activity/modals/handover-view/handover-view.component';
import { DiaryViewComponent } from 'app/activity/modals/diary-view/diary-view.component';
import { InspectionViewComponent } from 'app/activity/modals/inspection-view/inspection-view.component';
import { DismantleViewComponent } from 'app/activity/modals/dismantle-view/dismantle-view.component';
import { UnsafeViewComponent } from 'app/activity/modals/unsafe-view/unsafe-view.component';
import { VariationViewComponent } from 'app/activity/modals/variation-view/variation-view.component';
import { DiaryTypeEnum, ActivityDetailQuery, ScaffoldEndpointService } from 'app/core/hub-api';
import { OffhireViewComponent } from 'app/activity/modals/offhire-view/offhire-view.component';
import { of, BehaviorSubject, combineLatest } from 'rxjs';
import { SiteDetailQueryService } from 'app/sites/services/site-detail-query.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from '../models/ss-modal-config';

@Injectable()
export class ActivityModalService {
  private loadingSource = new BehaviorSubject<boolean>(false);
  public loading = this.loadingSource.asObservable();
  private disableApiRequest = false;
  constructor(
    private scaffoldEndpointService: ScaffoldEndpointService,
    private siteDetailsQueryService: SiteDetailQueryService,
    public modalService: BsModalService
  ) { }
  /**
   * TODO: Add a check for what componet gets passed so we can query the correct API.
   *  Add different API Endpoints
   */

  public openActivityModal(type: number, scaffoldDiaryId: string, siteReference?: string): any {
    const apiQuery = new ActivityDetailQuery();
    apiQuery.ActivityId = scaffoldDiaryId;
    apiQuery.ActivityType = type;
    const siteDetailQuery = siteReference ? this.siteDetailsQueryService.siteDetailQuery(true, siteReference) : of('');
    if (this.disableApiRequest) {
      return;
    } else {
      this.disableApiRequest = true;
      const obs$ = combineLatest([siteDetailQuery, this.scaffoldEndpointService.ActivityDetailQuery(apiQuery)]);
      obs$.subscribe(
        latest => {
          const context = {
            ActivityTypeId: scaffoldDiaryId,
            ActivityType: type,
            Site: latest[0],
            ActivityDetail: latest[1]
          };
          const modal = this.modalService.show(this.modalToLoad(type), SSModalConfig.generate(context));

          modal.onHide.subscribe(
            res => {
              this.loadingSource.next(false);
              return;
            },
            error => {
              //  Catch errors when user clicks escape button in the modal. So we just handle it.
              console.error(error);
            },
          );
        },
        error => {
          console.error(error);
        },
        () => {
          this.disableApiRequest = false;
        }
      );
    }
  }

  private modalToLoad(ActivityType: Number): any {
    switch (ActivityType) {
      case DiaryTypeEnum.Adaption:
      case DiaryTypeEnum.Variation:
        return VariationViewComponent;
      case DiaryTypeEnum.FullHandover:
      case DiaryTypeEnum.PartialHandover:
        return HandoverViewComponent;
      case DiaryTypeEnum.FullOffHire:
      case DiaryTypeEnum.PartialOffHire:
        return OffhireViewComponent;
      case DiaryTypeEnum.Diary:
        return DiaryViewComponent;
      case DiaryTypeEnum.Inspection:
      case DiaryTypeEnum.InspectionFollowUpInstruction:
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return InspectionViewComponent;
      case DiaryTypeEnum.Dismantle:
        return DismantleViewComponent;
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return UnsafeViewComponent;
    }
    return ActivityType;
  }
}

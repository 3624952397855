<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ ActivityDetail?.SiteDetails.CustomerName }} - {{ ActivityDetail?.SiteDetails.SiteName }}
      ({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})
    </h4>
    <a class="pull-right" (click)="dismiss()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"><img src="{{ (ActivityType | idToActivity: true).icon }}" class="modal-content-icon" />
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Site: </span>{{ ActivityDetail?.SiteDetails.SiteName }} ({{ SiteReference }})
                </li>
                <li><span class="bold">Address: </span>{{ ActivityDetail?.SiteDetails.SiteAddress }}</li>
                <li><span class="bold">Customer: </span>{{ ActivityDetail?.SiteDetails.CustomerName }}</li>
                <li><span class="bold">Area: </span>{{ ActivityDetail?.ScaffoldDetails.SiteAreaName }}</li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Scaffold: </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldName }}</li>
                <li [hidden]="!ActivityDetail.SiteDetails.IsManageStockControl"><span class="bold">Standing Tonnage: </span> {{ ActivityDetail?.ScaffoldDetails.StandingTonnage | number:'.1-2' }}</li>
                <li><span class="bold">Scaffold Name: </span>{{ ActivityDetail?.ScaffoldDetails.Location }}</li>
                <li><span class="bold">Scaffold Description:
                  </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldDescription }}</li>
                <li><span class="bold">Date: </span>{{ ActivityDetail?.When | date: 'MMM dd, yyy' }}</li>
                <li><span class="bold">Time: </span>{{ ActivityDetail?.When | date: 'hh:mm a' }}</li>
                <li>
                  <span class="bold">Staff Name:</span> {{ staffName[0]?.ContactName }}
                  <span *ngIf="staffCompanyPosition?.length"> ({{ staffCompanyPosition }})</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">{{activityName}} Details</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-3 carousel">
                  <hub-image-carousel [media]="getImageArray(ActivityDetail?.Media)"
                    height="100px"></hub-image-carousel>
                </div>
                <div class="col-sm-9 my-1">
                  <div class="row">
                    <div class="col-sm-12 my-1">
                      <span class="bold">Scaffolds:</span>
                      <ng-container *ngFor="let scaffold of ActivityDetail?.Scaffolds">
                        {{ scaffold.SpecificLocationDetails }} ({{ scaffold.ScaffoldNumber }})
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 my-1"><span class="bold">Comments: </span>
                      {{ ActivityDetail?.HandoverComments }}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="bold">Hire Period: </span> {{ ActivityDetail?.HirePeriod }}
                      <span *ngIf="ActivityDetail?.HirePeriod > 0"> week(s)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <span class="bold">Contract Price: </span>
                      <ng-container
                        *ngIf="ActivityDetail?.VariationInstruction.ContractPrice || ActivityDetail?.VariationInstruction.ContractPrice >= 0">
                        {{ ActivityDetail?.VariationInstruction.ContractPrice | currency: 'GBP' }}</ng-container>
                      <ng-container
                        *ngIf="!ActivityDetail?.VariationInstruction.ContractPrice && ActivityDetail?.VariationInstruction.ContractPrice !== 0">
                        Priced To Agreed Schedule Of Rates</ng-container>
                    </div>
                    <div class="col-sm-6">
                      <span class="bold">Extra Hire Rate: </span>
                      <ng-container
                        *ngIf="ActivityDetail?.VariationInstruction.ExtraHireRate || ActivityDetail?.VariationInstruction.ExtraHireRate >= 0">
                        {{ ActivityDetail?.VariationInstruction.ExtraHireRate | currency: 'GBP' }}
                        <span *ngIf="ActivityDetail?.VariationInstruction.ExtraHireRate > 0"> per week</span>
                      </ng-container>
                      <ng-container
                        *ngIf="!ActivityDetail?.VariationInstruction.ExtraHireRate && ActivityDetail?.VariationInstruction.ExtraHireRate !== 0">
                        Priced To Agreed Schedule Of Rates</ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ scaffoldTitle?.name }}
                <span *ngIf="scaffoldTitle?.other"> - {{ scaffoldTitle?.other }}</span>
                ({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-4" *ngFor="let details of HandoverDetails">
                  <span class="bold">{{ details?.name }}</span> {{ details?.value }}
                  <div class="col-sm-12" *ngIf="details?.other">({{ details?.other }})</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngFor="let risk of risks">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ getRiskType(risk.HandoverRiskTypeId)?.Title }}
                <span *ngIf="risk.HandoverRiskTypeFurtherInformation"> -
                  {{ risk.HandoverRiskTypeFurtherInformation }}</span>
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-3 carousel">
                  <hub-image-carousel [media]="getImageArray(risk?.Media)" height="100px"></hub-image-carousel>
                </div>
                <div class="col-sm-9 my-1"><span class="bold">Reason: </span>
                  {{ getRiskReason(risk.HandoverRiskReasonId)?.Title }}<span
                    *ngIf="risk.HandoverRiskReasonFurtherInformation"> -
                    {{ risk.HandoverRiskReasonFurtherInformation }}</span></div>
                <div class="col-sm-9 my-1" *ngIf="risk?.Notes"><span class="bold">Notes: </span> {{ risk?.Notes }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">Site Instructions</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 my-1">
                  <span class="bold">Site Instruction Reference: </span>
                  {{ ActivityDetail?.VariationInstruction.SiteInstructionReference }}
                </div>
                <div class="col-sm-12 my-1"><span class="bold">Site Instruction Instructed By: </span>
                  {{ siteInstructedBy }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngFor="let dimension of ActivityDetail?.Dimensions,let i =index">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">Dimensions - {{ dimension?.Description }}</h4>
            </div>
            <div class="panel-body">
              <div class="row my-1">
                <div class="col-sm-12 my-1">
                  <span class="bold">Dimensions Description: </span>
                  {{ dimension.Description }}
                </div>
                <div class="col-sm-4" *ngFor="let details of dimensions[i]">
                  <span class="bold">{{ details?.name }}: </span> {{ details?.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="ActivityDetail?.CustomerSignature || ActivityDetail?.StaffSignature">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">Acceptance Of The Scaffold</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6" *ngIf="ActivityDetail?.StaffSignature">
                  <div>
                    <hub-signatures-svg [data]="ActivityDetail?.StaffSignature.Data" width="100">
                    </hub-signatures-svg>
                  </div>
                  <div>
                    <span class="bold">Staff: </span>{{ staffName[0].ContactName }}
                    <span *ngIf="staffCompanyPosition?.length"> ({{ staffCompanyPosition }})</span>
                  </div>
                  <div><span class="bold">Date:
                    </span>{{ ActivityDetail?.StaffSignature.When | date: 'MMM dd, yyy - hh:mm a' }}</div>
                </div>
                <div class="col-md-6">
                  <div *ngIf="ActivityDetail?.CustomerSignature; else noCustomer">
                    <div>
                      <hub-signatures-svg [data]="ActivityDetail?.CustomerSignature.Data" width="100">
                      </hub-signatures-svg>
                    </div>
                    <div><span class="bold">Customer: </span>{{ customerContact }}</div>
                    <div><span class="bold">Date:
                      </span>{{ ActivityDetail?.CustomerSignature.When | date: 'MMM dd, yyy - hh:mm a' }}
                    </div>
                  </div>
                  <ng-template #noCustomer>
                    <span>
                      <div *ngIf="ActivityDetail?.CustomerAttachment.length === 0" class="attach-file">
                        <button class="btn btn-large button-primary pull-center" (click)="uploadFile()"> <i
                            class="fa fa-paperclip"></i>Attach customer approval file</button>
                        <div class="size my-1"><span>Attach a file sent by the customer to approve this activity,
                            e.g. a confirmation of verbal instruction certificate</span></div>
                      </div>
                      <div *ngIf="ActivityDetail?.CustomerAttachment.length > 0">
                        <div class="file-uploader">
                          <span class="file-input btn button-primary btn-file" (click)="downloadFile()">
                            <i class="fa fa-cloud-download"></i>Download File
                          </span>
                          <span class="import-text"> {{ fileName }}</span>
                        </div>
                        <div class="my-1"><span class="bold">Customer: </span>{{ customerContact }}</div>
                        <div><span class="bold">Date:
                          </span>{{ fileAttachmentDate | date: 'MMM dd, yyy - hh:mm a' }}</div>
                      </div>
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer"><button class="btn btn-large button-primary pull-right" (click)="dismiss()">Close</button>
    <hub-download-report-button [ActivityType]="ActivityType" ID="{{ActivityDetail?.VariationId}}"
      [ActivityDetail]="ActivityDetail"></hub-download-report-button>
  </div>
</div>

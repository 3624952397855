import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ScaffoldWebAppData, SiteEndpointService, SiteScaffoldQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries scaffold data from the server (or in memory cache where specified) and allows consumer to subscribe to scaffold data changes.
@Injectable()
export class SiteScaffoldQueryService extends QueryService<ScaffoldWebAppData[]> {

  constructor(private siteEndpointService: SiteEndpointService) { super(); }

  siteScaffoldQuery(useCached: boolean, siteId: string): Observable<ScaffoldWebAppData[]> {
    return super.query(useCached, siteId);
  }

  siteScaffoldDataCached(siteId: string): Observable<ScaffoldWebAppData[]> {
    return super.cachedDataChanges(siteId);
  }

  /**
 * Gets the scaffold data from the server or in memory cache.
 * @param siteId - The reference of the scaffold to retrieve data for.
 * @returns An observable of ScaffoldWebAppData[].
 */
  siteScaffoldDataChanges(siteId: string): Observable<ScaffoldWebAppData[]> {
    return super.cachedDataChanges(siteId).pipe(
      switchMap((site) => {
        if (site) {
          return of(site);
        } else {
          return super.query(false, siteId);
        }
      })
    );
  }

  protected getDataFromServer(siteId: string): Observable<ScaffoldWebAppData[]> {
    const query = Object.assign(new SiteScaffoldQuery(), { SiteId: siteId });
    return this.siteEndpointService.SiteScaffoldQuery(query);
  }
}

import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { HandoverStatisticsData } from 'app/core/hub-api';
import * as Highcharts from 'highcharts';
import { environment } from 'environments/environment';

import { colors } from '../../values/colours';

@Component({
  selector: 'hub-seven-day-handover-chart',
  templateUrl: './seven-day-handover-chart.component.html',
  styleUrls: ['./seven-day-handover-chart.component.scss']
})
export class SevenDayHandoverChartComponent implements OnInit, OnChanges {

  @Input()
  handoverData: HandoverStatisticsData;

  @Output() selectedHandover7DaysLegend = new EventEmitter();
  selectedTest: any;

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  uiSettings = {
    showChart: true
  };

  chartConfig: Highcharts.Options = {
    title: {
      y: 15,
      style: {
        color: colors.river
      },
      text: ''
    },
    subtitle: {
      text: 'Handovers last 7 days',
      y: 30,
    },
    chart: {
      type: 'pie',
      // plotBackgroundColor: null,
      // plotBorderWidth: null,
      // plotShadow: false,
    },
    colors: [
      colors.positive,
      colors.carrot,
      colors.assertive,
      colors.amethyst,
      colors.river,
      colors.lightBlue
    ],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter: function (): any {
            return this.y > 0 ? Math.round(this.percentage * 100) / 100 + ' %' : null;
          },
          color: 'black'
        }
      },
      pie: {
        point: {
          events: {
            legendItemClick: (e) => {
              // Gets the selected chart legend
              const points = (this.chart.series[0] as any).points;
              this.selectedTest = points.filter(s => s.visible).map(s => s.name);
              if ((e.target as any).visible) {
                const index = this.selectedTest.findIndex(s => s === (e.target as any).name);
                this.selectedTest.splice(index, 1);
              } else {
                this.selectedTest.push((e.target as any).name);
              }
              this.selectedHandover7DaysLegend.emit(this.selectedTest);
            }
          },
        },

        dataLabels: {
          enabled: true,
          formatter(): any {
            return this.y > 0 ? Math.round(this.percentage * 100) / 100 + ' %' : null;
          },
          distance: 15,
          color: 'black'
        },
        showInLegend: true,
        innerSize: '80%'
      }
    },
    series: [],
    credits: {
      enabled: false
    },
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('handoversLast7Days', this.chartConfig);
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['handoverData'] && !!this.chart) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    if (this.chart) {
      while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

      let variation, adaption;
      const featureFlags = environment.featureFlags as any;
      if (featureFlags.variation) {
        adaption = ['Adaptions', this.handoverData.TotalAdaptionsForLastSevenDays];
        variation = ['Variations', this.handoverData.TotalVariationsForLastSevenDays];
      }

      this.chart.addSeries({
        type: 'pie',
        data: [
          ['Initial Handovers', this.handoverData.TotalInitialHandoverForLastSevenDays],
          ['Re-Handovers', this.handoverData.TotalReHandoverForLastSevenDays],
          ['Full Off-Hires', this.handoverData.TotalFullOffHiresForLastSevenDays],
          ['Partial Off-Hires', this.handoverData.TotalPartialOffhiresForLastSevenDays],
          adaption, variation
        ]
      });
      // Resets the selected legend
      this.selectedHandover7DaysLegend.emit(this.chart.series[0].data.map(d => d.name));


      const totalHandovers = Object.keys(this.handoverData)
        .map((k) => this.handoverData[k])
        .reduce((total, value) => total + value);

      this.uiSettings.showChart = !!totalHandovers;
      this.changeDetectorRef.detectChanges();

      this.chart.setTitle({ text: totalHandovers.toString() }, undefined, false);
      this.chart.redraw();
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hub-contracts-info-text',
  templateUrl: './contracts-info-text.component.html',
  styleUrls: ['./contracts-info-text.component.scss']
})
export class ContractsInfoTextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}

<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ ActivityDetail?.SiteDetails.CustomerName }} - {{ ActivityDetail?.SiteDetails.SiteName }} ({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})
    </h4>
    <a class="pull-right" (click)="bsModalRef.hide()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"><img src="{{ (ActivityType | idToActivity: true).icon }}" class="modal-content-icon" /></div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Site: </span>{{ ActivityDetail?.SiteDetails.SiteName }} ({{ SiteReference }})</li>
                <li><span class="bold">Address: </span>{{ ActivityDetail?.SiteDetails.SiteAddress }}</li>
                <li><span class="bold">Customer: </span>{{ ActivityDetail?.SiteDetails.CustomerName }}</li>
                <li><span class="bold">Area: </span>{{ ActivityDetail?.ScaffoldDetails.SiteAreaName }}</li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Scaffold: </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldName }}</li>
                <li [hidden]="!ActivityDetail.SiteDetails.IsManageStockControl"><span class="bold">Standing Tonnage: </span> {{ ActivityDetail?.ScaffoldDetails.StandingTonnage | number:'.1-2' }}</li>
                <li>
                  <span class="bold" *ngIf="ActivityDetail?.ScaffoldDescription">Scaffold Description:
                  </span>{{ ActivityDetail?.ScaffoldDescription }}
                </li>
                <li><span class="bold">Date: </span>{{ ActivityDetail?.ActivityDateTime | date: 'MMM dd, yyy' }}</li>
                <li><span class="bold">Time: </span>{{ ActivityDetail?.ActivityDateTime | date: 'hh:mm a' }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="notes" *ngIf="ActivityDetail?.DismantleData.Notes">Notes: {{ ActivityDetail?.DismantleData.Notes }}
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-success">
            <div class="panel-heading">
              <h4 class="panel-title">Dismantled By</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-3 carousel">
                  <hub-image-carousel [media]="dismantleImages" height="100px"></hub-image-carousel>
                </div>
                <div class="col-xs-6">
                  <div class="col-sm-12 my-1"><span class="bold">Staff: </span>
                    {{ ActivityDetail?.DismantleData.OwnerName }}
                    <span *ngIf="ActivityDetail?.DismantleData.StaffCompanyPosition?.length">({{ ActivityDetail?.DismantleData.StaffCompanyPosition }})</span>
                  </div>
                  <div class="col-sm-12 my-1">
                    <span class="bold">Date: </span>
                    {{ ActivityDetail?.DismantleData.When | date: 'MMM dd, yyy - hh:mm a' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"><button class="btn btn-large button-primary pull-right" (click)="bsModalRef.hide()">Close</button>
  </div>
</div>

<div *ngIf="site" class="handover-history">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['/sites/list']"><i class="fa fa-chevron-left text-positive"></i>back
        to sites list</a>
    </div>
  </div>
  <!-- Empty state placeholder -->
  <div *ngIf="siteHandovers && !siteHandovers.length" class="empty-state-placeholder-large">
    <i class="fa fa-building fa-4x"></i>
    <h3>Welcome to the site handovers area</h3>
    <p>It doesn't look like any handovers have been completed yet!</p>
  </div>
  <!-- Loading Div -->
  <div *ngIf="!siteHandovers" class="empty-state-placeholder-large">
    <!-- Show spinner if we are waiting for the scaffold data HTTP request. -->
    <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
    <h4>Loading Handover Data...</h4>
  </div>
  <div *ngIf="siteHandovers && siteHandovers.length">
    <div class="row margin-bottom">
      <div class="col-md-12">
        <h2 class="inline-block">Handovers</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="card-nav">
          <li [ngClass]="!filters.selectedCustomer ? 'active' : ''" (click)="selectCustomer(undefined)"
            *ngIf="siteCustomers && siteCustomers.length > 1"><a>All Customers</a></li>
          <li *ngFor="let customer of siteCustomers" (click)="selectCustomer(customer)"
            [ngClass]="siteCustomers.length === 1 ? 'active' : filters.selectedCustomer === customer ? 'active' : ''">
            <a>{{customer.CustomerName}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="ss-container">
      <div class="row margin-bottom">
        <form name="filtersForm" [formGroup]="filtersForm">
          <div class="col-xs-4">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input class="form-control" type="text" placeholder="Search..." formControlName="searchTerm" />
            </div>
          </div>
          <div class="col-xs-4 ">
            <hub-dropdown icon="fa fa-briefcase text-positive" formControlName="contractList" [items]="contractNames"
              idProperty="type" titleProperty="name" itemsToDisplay="2" [multiselect]="true"
              (valueChanges)="onSelectedContractChanged($event)" placeholder="Choose contracts"
              [hidden]="!filters.selectedCustomer">
            </hub-dropdown>
          </div>
          <div class="col-xs-4">
            <hub-dropdown formControlName="handoverTypes" [items]="handoverTypeOptions" idProperty="type"
              titleProperty="name" itemsToDisplay="2" [multiselect]="true"
              (valueChanges)="onSelectedHandoverTypeChanged($event)" placeholder="Choose handover types">
            </hub-dropdown>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ngx-datatable #handoversTable class="table handover-table" [rows]="filteredHandovers" [columnMode]="'flex'"
            [footerHeight]="50" [headerHeight]="40" [rowHeight]="'auto'" [sorts]="[{ prop: 'When', dir: 'desc' }]"
            [limit]="10" [selectionType]="'single'" (select)="onHandoverSelect($event)"
            [messages]="{ emptyMessage: '' }">
            <ngx-datatable-column name="Type" prop="Type" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <hub-handover-type [type]="row.Type"></hub-handover-type>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Reference" prop="Reference" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.Reference }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Scaffolds" prop="Scaffolds" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.Scaffolds.join(', ') }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Comments" prop="Comments" [sortable]="false" [flexGrow]="2">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.Comments }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Staff" prop="Staff" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.Staff }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date/Time" prop="When" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.When | datetime: 'DD/MM/YY [at] HH:mm' }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { UUID } from 'angular2-uuid';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';
import { FilterService } from 'app/core/services/filter-service';
import { TextToColorService } from 'app/core/services/text-to-color.service';
import { MainComponent } from 'app/core/components/main/main.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { environment } from '../../environments/environment';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { DeniedComponent } from './components/denied/denied.component';
import { OfflineComponent } from './components/offline/offline.component';
import { AuthenticatedGuard } from './guards/authenticated-guard';
import { AuthenticationSetupGuard } from './guards/authentication-setup-guard';
import { AuthorisedGuard } from './guards/authorised-guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated-guard';
import { OfflineGuard } from './guards/offline-guard';
import { OnlineGuard } from './guards/online-guard';
import { ConfigurationResolver } from './resolvers/configuration.resolver';
import { CustomersResolver } from './resolvers/customers.resolver';
import { DepotsResolver } from './resolvers/depots.resolver';
import { HandoverStatisticsResolver } from './resolvers/handover-statistics.resolver';
import { LoggedInStaffResolver } from './resolvers/logged-in-staff-resolver';
import { ScaffoldInspectionResolver } from './resolvers/scaffold-inspection.resolver';
import { SitesResolver } from './resolvers/sites.resolver';
import { StaffsResolver } from './resolvers/staffs.resolver';
import { ApplicationSettingsQueryService } from './services/application-settings-query.service';
import { ArrayHelperService } from './services/array-helper.service';
import { ConfigurationQueryService } from './services/configuration-query.service';
import { CustomerDetailQueryService } from './services/customer-detail-query.service';
import { CustomersQueryService } from './services/customers-query.service';
import { DashboardQueryService } from './services/dashboard-query.service';
import { DepotsQueryService } from './services/depots-query.service';
import { HandoverHistoryQueryService } from './services/handover-history-query-service';
import { HandoverStatisticsQueryService } from './services/handover-statistics-query-service';
import { ImageUploadService } from './services/image-upload.service';
import { LoggedInStaffService } from './services/logged-in-staff-service';
import { ReportSitesQueryService } from './services/report-sites-query.service';
import { ScaffoldInspectionQueryService } from './services/scaffold-inspection-query-service';
import { ScaffoldStatisticsQueryService } from './services/scaffold-statistics-query-service';
import { SitesQueryService } from './services/sites-query.service';
import { StaffDetailQueryService } from './services/staff-detail-query.service';
import { StaffsQueryService } from './services/staffs-query.service';
import { AutoGenerateQuickIdService } from './services/auto-generate-quickId.service';
import { TimeoutService } from './services/timeout.service';
import { HttpErrorResponseInterceptor } from './interceptors/http-error-response.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfirmLeaveGuard } from 'app/core/guards/confirm-leave.guard';
import { ExportService } from './services/export-service';
import { Error404Component } from './components/error-404/error-404.component';
import { UnassignedScaffoldQueryService } from './services/unassigned-scaffold-query-service';
import { ItemDetailsQueryService } from './services/item-details-query.service';
import { TimesheetDepotsQueryService } from './services/timesheet-depots-query-service';
import { UtilityService } from './services/utility-service';
import { HighchartsChartModule } from 'highcharts-angular';
import { HandoverViewComponent } from 'app/activity/modals/handover-view/handover-view.component';
import { DiaryViewComponent } from 'app/activity/modals/diary-view/diary-view.component';
import { DismantleViewComponent } from 'app/activity/modals/dismantle-view/dismantle-view.component';
import { OffhireViewComponent } from 'app/activity/modals/offhire-view/offhire-view.component';
import { UnsafeViewComponent } from 'app/activity/modals/unsafe-view/unsafe-view.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InspectionViewComponent } from 'app/activity/modals/inspection-view/inspection-view.component';
import { ActivityModalService } from 'app/shared/services/activity-modal.service';
import { VariationViewComponent } from '../activity/modals/variation-view/variation-view.component';
import { GenerateDefaultsService } from './services/generateDefaults.service';
import { StockReportQueryService } from './services/stock-report-query-service';
import { FileUploadService } from './services/file-upload-service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { UtilityModule } from './utility';
import { FeatureFlagsModule } from 'app/shared/components/feature-flags';
import { HubApiModule } from './hub-api';
import { AuthenticationModule } from './authentication';
import { HttpCancelService } from './services/http-cancel.service';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';
import { GenericErrorComponent } from './components/generic-error/generic-error.component';

declare const require: any;

var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);


export function settingsFactory(): any {
  return environment;
}
export function featureFlagFactory(): any {
  return environment.featureFlags;
}

// Module for application wide services, and also components used in the app template.
// SHOULD ONLY BE IMPORTED ONCE, IN THE ROOT MODULE OF THE APPLICATION!
@NgModule({
  declarations: [
    AppFooterComponent,
    AppHeaderComponent,
    DeniedComponent,
    GenericErrorComponent,
    Error404Component,
    MainComponent,
    OfflineComponent,
    DiaryViewComponent,
    HandoverViewComponent,
    DismantleViewComponent,
    OffhireViewComponent,
    InspectionViewComponent,
    UnsafeViewComponent,
    VariationViewComponent,
  ],
  imports: [
    LoadingBarRouterModule,
    LoadingBarModule,
    SharedModule,
    AlertModule.forRoot(),
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    ToasterModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    InfiniteScrollModule,
    CommonModule,
    UtilityModule.forRoot(),
    FeatureFlagsModule.forRoot(featureFlagFactory),
    HubApiModule.forRoot(settingsFactory),
    AuthenticationModule.forRoot(settingsFactory),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  exports: [
    // MODULES
    SharedModule,
    BrowserModule,
    TooltipModule,
    AppRoutingModule,
    InfiniteScrollModule,

    // COMPONENTS
    LoadingBarRouterModule,
    LoadingBarModule,
    AppHeaderComponent,
    AppFooterComponent,
    HandoverViewComponent,
    DiaryViewComponent,
    DismantleViewComponent,
    OffhireViewComponent,
    UnsafeViewComponent,
    FroalaEditorModule,
    FroalaViewModule,
  ],
  providers: [
    // GUARDS
    AuthenticatedGuard,
    OnlineGuard,
    OfflineGuard,
    ConfirmLeaveGuard,
    NotAuthenticatedGuard,
    AuthenticationSetupGuard,

    // RESOLVERS
    ConfigurationResolver,
    StaffsResolver,
    LoggedInStaffResolver,
    ScaffoldInspectionResolver,
    HandoverStatisticsResolver,
    CustomersResolver,
    DepotsResolver,

    // SERVICES
    UUID,
    DashboardQueryService,
    ConfigurationQueryService,
    TimeoutService,
    SitesResolver,
    StaffsQueryService,
    AutoGenerateQuickIdService,
    CustomersQueryService,
    SitesQueryService,
    ApplicationSettingsQueryService,
    DepotsQueryService,
    HandoverHistoryQueryService,
    HandoverStatisticsQueryService,
    ScaffoldInspectionQueryService,
    ScaffoldStatisticsQueryService,
    CustomerDetailQueryService,
    StaffDetailQueryService,
    LoggedInStaffService,
    ReportSitesQueryService,
    StockReportQueryService,
    AuthorisedGuard,
    ImageUploadService,
    ArrayHelperService,
    FilterService,
    SiteScaffoldDiaryQueryService,
    TextToColorService,
    ExportService,
    UnassignedScaffoldQueryService,
    TimesheetDepotsQueryService,
    ItemDetailsQueryService,
    UtilityService,
    GenerateDefaultsService,
    ActivityModalService,
    ToasterService,
    FileUploadService,
    HttpCancelService,
    CompanySettingsService,

    // INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true
    },
  ]

})
export class CoreModule {
}

import { errorMessages } from '../../../../shared/values/error-messages';
import { mustNotExistValidator } from '../../../../shared/validators/must-not-exist.validator';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddEditContractModalContext } from '../../../models/add-edit-contract-modal-context';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { compact, flow, join, map, partialRight, pick } from 'lodash';
import { emailAddressRegex } from '../../../../shared/regex/email-address.regex';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationData, CustomerWebAppData, CreateCustomerCommand } from 'app/core/hub-api';
import { ReferenceGeneratorService } from 'app/core/utility';

@Component({
  selector: 'hub-site-import-customer',
  templateUrl: './site-import-customer.component.html',
  styleUrls: ['./site-import-customer.component.scss']
})
export class SiteImportCustomerComponent extends FormComponent implements OnInit {

  @Input()
  editMode: boolean;
  @Output()
  cancel = new EventEmitter();
  @Output()
  done = new EventEmitter();
  @Input()
  data: SiteImportModalData;
  @Input()
  context: Partial<AddEditContractModalContext>;

  configuration: ConfigurationData;
  customers: CustomerWebAppData[];

  form: UntypedFormGroup;
  customerNameFormControl: UntypedFormControl;
  customerAddressFormControl: UntypedFormControl;
  customerReferenceFormControl: UntypedFormControl;
  phoneNumberFormControl: UntypedFormControl;
  emailAddressFormControl: UntypedFormControl;
  websiteFormControl: UntypedFormControl;

  validationMessages = {
    customerName: {
      required: errorMessages.required,
    },
    customerReference: {
      required: errorMessages.required,
      mustNotExist: errorMessages.customerReferenceMustBeUnique,
    },
    emailAddress: {
      pattern: errorMessages.emailInvalid
    }
  };

  constructor(
    private referenceGeneratorService: ReferenceGeneratorService,
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.configuration = this.context.configuration;
    this.customers = this.context.customers;
    const customerReferences = this.customers.map(c => c.CustomerReference);

    if (!this.data.importData.customer) {
      this.data.importData.customer = Object.assign(new CreateCustomerCommand(), {
        CustomerName: this.data.siteImportFile.CustomerAddress.AddressName,
        CustomerAddress: flow([
          pick,
          map,
          compact,
          partialRight(join, ', ')
        ])(this.data.siteImportFile.CustomerAddress, ['AddressLines', 'Town', 'County', 'Country', 'Postcode']),
        CustomerReference: this.referenceGeneratorService
          .incrementalReference(customerReferences, this.configuration.CustomerReferenceLength),
        PhoneNumber: this.data.siteImportFile.CustomerAddress.TelephoneNumber || this.data.siteImportFile.CustomerAddress.MobileNumber,
        EmailAddress: this.data.siteImportFile.CustomerAddress.EmailAddress,
        Website: this.data.siteImportFile.CustomerAddress.Website
      });
    }
    this.customerNameFormControl = new UntypedFormControl(
      this.context.customerName
        ? this.context.customerName
        : this.data.importData.customer.CustomerName, [Validators.required]
    );
    this.customerAddressFormControl = new UntypedFormControl(this.data.importData.customer.CustomerAddress);
    this.customerReferenceFormControl = new UntypedFormControl(this.data.importData.customer.CustomerReference,
      [Validators.required, mustNotExistValidator(customerReferences)]);
    this.phoneNumberFormControl = new UntypedFormControl(this.data.importData.customer.PhoneNumber);
    this.emailAddressFormControl = new UntypedFormControl(this.data.importData.customer.EmailAddress, Validators.pattern(emailAddressRegex));
    this.websiteFormControl = new UntypedFormControl(this.data.importData.customer.Website);
    this.form = new UntypedFormGroup({
      customerName: this.customerNameFormControl,
      customerAddress: this.customerAddressFormControl,
      customerReference: this.customerReferenceFormControl,
      phoneNumber: this.phoneNumberFormControl,
      emailAddress: this.emailAddressFormControl,
      website: this.websiteFormControl,
    });
    super.ngOnInit();
  }

  onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.data.importData.customer.CustomerName = formValues.customerName;
      this.data.importData.customer.CustomerAddress = formValues.customerAddress;
      this.data.importData.customer.CustomerReference = formValues.customerReference;
      this.data.importData.customer.PhoneNumber = formValues.phoneNumber;
      this.data.importData.customer.EmailAddress = formValues.emailAddress;
      this.data.importData.customer.Website = formValues.website;
      this.done.emit();
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }
}

<form name="contractForm" [formGroup]="form">
  <div class="modal-body">
    <p>Select a contract where you wish to import the scaffolds:</p>
    <ul>
      <li class="dd-checkbox dd-checkbox-medium" *ngFor="let contract of contractControls['controls']; let i=index">
        <label [for]="i">
          <input type="checkbox" [value]="contractList[i].ContractId" [name]="i" [id]="i" [formControl]="contract"
            [checked]="contractList[i].selected" (click)="updateSelection(contractList[i])">
          <span class="dd-label"></span>
          <span class="dd-checkbox-label">{{ contractList[i].ContractName }}
            ({{contractList[i].ContractReference}})</span>
        </label>
      </li>
    </ul>

    <div class="modal-footer">
      <div class="modal-buttons">
          <span *ngIf="required" class="text-error text-left center-block"><i class="fa fa-warning"></i> {{ required }}</span>
    
        <button class="btn btn-large btn-inverse" type="button" (click)="onNext()" [disabled]="required">
          <i class="fa fa-chevron-circle-right"></i> Next
        </button>
      </div>
    </div>
  </div>
</form>


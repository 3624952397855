<div class="modal-content add-edit-company-contact-modal">

  <hub-modal-form-header [title]="modalTitle" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <form name="contractContactForm" [formGroup]="form">

      <div *ngIf="editMode">
        <hub-form-group label="Contact Name" name="contactName" [control]="contactNameFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="contactName" ngxTrim>
        </hub-form-group>
        <hub-form-group label="Email Address" name="emailAddress" [control]="emailAddressFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="email" formControlName="emailAddress" ngxTrim>
        </hub-form-group>
      </div>

      <hub-form-group *ngIf="!editMode" label="Email Address" name="emailAddress" [control]="emailAddressFormControl"
        [validationErrors]="validationErrors">
        <ng-select *ngIf="!editMode" [items]="staffs" bindLabel="EmailAddress" [searchable]="true"
          bindValue="EmailAddress" labelForId="ContactName" [multiple]="true" placeholder="Select Staff"
          (add)="addCompanyContact($event)" clearAllText="Clear" formControlName="emailAddress"
          (remove)="removeCompanyContact($event)" (clear)="rePrepareStaffList()">
          <ng-template ng-header-tmp>
            <div class="header">
              <p class="title-add">Add Custom Contact</p>
              <div class="form-body">
                <label class="bold"><span>Name</span>
                  <input type="text" formControlName="contactName" /></label>
                <label class="bold"><span>Email</span>
                  <input type="email" type="text" formControlName="contactEmail" /></label>
                <button class="pull-right" (click)="AddEmail()"><i class="fa fa-plus"></i> Add</button>
              </div>
              <div class="errors">
                <div class="text-error" *ngIf="contactName.errors?.required || contactEmail.errors?.required"><i
                    class="fa fa-exclamation-triangle"></i>
                  {{ contactName.errors?.required ? 'Contact Name is required' : 'Contact Email is required'}}</div>
                <div class="text-error" *ngIf="contactEmail.errors?.email || contactEmail.errors?.pattern "><i
                    class="fa fa-exclamation-triangle"></i>
                  {{ validationMessages.emailAddress?.pattern}}</div>
                <div class="text-error" *ngIf="contactEmail.errors?.duplicate"><i
                    class="fa fa-exclamation-triangle"></i>
                  {{ validationMessages.emailAddress?.duplicateEmail}}</div>
              </div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="dropdown-finder">
              <p class="text-and-icon"><i class="fa fa-user"></i>
                <span class="bold" [ngOptionHighlight]="search"> {{item.ContactName}}</span>
                <span *ngIf="item.UserCode"> ({{item.UserCode}})</span>
              </p>
              <p *ngIf="item.EmailAddress" class="text-muted">
                <span><i class="fa fa-envelope"></i> {{item.EmailAddress}}</span>
              </p>
            </div>
          </ng-template>

        </ng-select>
      </hub-form-group>
      <div class="subscriptions">
        <h4>Subscriptions</h4>
        <p>Select which reports you would like this contact to receive.</p>
        <div class="form-group text-smaller">
          <label *ssFeatureFlag="'handover'">
            <input type="checkbox" name="subscriptions" formControlName="handoverCertificate"> Handover Certificates
          </label>
          <label *ssFeatureFlag="'variation'">
            <input type="checkbox" name="subscriptions" formControlName="variationInstructions"> Variation Instructions
          </label>
          <label *ssFeatureFlag="'inspection'">
            <input type="checkbox" name="subscriptions" formControlName="inspectionsCompleted"> Inspection Summary
            Report
          </label>
          <label *ssFeatureFlag="'inspection'">
            <input type="checkbox" name="subscriptions" formControlName="detailedInspection"> Detailed Inspection Report
          </label>
          <label *ssFeatureFlag="'inspection'">
            <input type="checkbox" name="subscriptions" formControlName="inspectionStatuses"> Inspection Status Reports
          </label>
          <span *ngIf="principalContractSelected()">
            <label class="checkbox-inline" *ssFeatureFlag="'inspection'">
              <input type="checkbox" name="subscriptions" formControlName="subContractorInspections"> Subcontractor
              Inspection Reports
            </label>
          </span>
        </div>
      </div>
      <div class="modal-options" *ngIf="editMode">
        <a *ngIf="editMode && !contractContact.IsInactive" class="text-danger animate-fade"
          (click)="deleteContractContact()">
          <i class="fa fa-times"></i> Delete contact</a>
        <span *ngIf="editMode && contractContact.IsInactive" class="animate-fade">
          <i class="fa fa-check"></i> This contact will be deleted. Click
          <a (click)="undoDeleteContractContact()" class="text-danger">undo</a> if this was a mistake!</span>
      </div>
      <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
        (close)="bsModalRef.hide()"></hub-modal-form-footer>
    </form>
  </div>
</div>
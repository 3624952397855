<div *ngIf="site" class="ss-container">
  <div class="row">
    <div class="col-md-12">
      <div class="ss-toolbar pull-right">
        <span *ngIf="siteStaffs.length">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded" (click)="onAddStaff()" title="Add a new staff member"><i
                class="fa fa-plus"></i><span>add staff</span></a>
          </hub-permission>
        </span>
      </div>
      <h2 class="inline-block margin-right"><span class="text-large-highlight">{{siteStaffs.length}}</span>Staff</h2>
      <div *ngIf="!siteStaffs.length" class="row">
        <div class="col-md-12 empty-state-placeholder">
          <i class="fa fa-user fa-4x"></i>
          <h3>Welcome to the Site Staff area</h3>
          <p>It looks like you haven't added any staff to the site yet. Click the 'Add Staff' button to add a new staff
            member
            to the site.</p>
          <hub-permission permissions="AddEditSites">
            <a class="btn button-primary" (click)="onAddStaff()" title="Add a new staff member"><i
                class="fa fa-plus"></i>Add Staff</a>
          </hub-permission>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-borderless site-staff-table">
            <tbody>
              <tr *ngFor="let staff of siteStaffs">
                <td class="text-left staff-details-column">
                  <div class="icon-header">
                    <i class="fa fa-user fa-2x"></i>
                    <div>
                      <h4><a>{{staff.ContactName}}</a></h4>
                      <p>{{staff.PhoneNumber}}</p>
                    </div>
                  </div>
                </td>
                <td class="align-middle staff-email-column"><a href="mailto:{{staff.EmailAddress}}"
                    tooltip="Email {{staff.ContactName}}" placement="right">{{staff.EmailAddress}}</a></td>
                <td class="align-middle staff-role-column">{{staff.RoleId | idToTitle : roles : "RoleName"}}</td>
                <hub-permission permissions="AddEditSites">
                  <td class="align-middle staff-edit-column"><a (click)="onDeleteStaff(staff)"><i
                        class="fa fa-regular fa-trash-can"
                        tooltip="Delete '{{staff.ContactName}}' from {{site.SiteName}}?" placement="top"></i></a></td>
                </hub-permission>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
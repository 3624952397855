import { ScaffoldWebAppData } from 'app/core/hub-api';
import { MultiStepFormStep } from '../../../../../shared/models/multi-step-form-step';
import { AddEditScaffoldModalContext } from '../../../../models/add-edit-scaffold-modal-context';
import { Component, OnInit } from '@angular/core';
import { AddEditScaffoldDetailComponent } from '../add-edit-scaffold-detail/add-edit-scaffold-detail.component';
import {
  AddEditScaffoldSpecificationComponent,
} from '../add-edit-scaffold-specification/add-edit-scaffold-specification.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-add-edit-scaffold-modal',
  templateUrl: './add-edit-scaffold-modal.component.html',
  styleUrls: ['./add-edit-scaffold-modal.component.scss']
})
export class AddEditScaffoldModalComponent implements OnInit {
  context: Partial<AddEditScaffoldModalContext>;

  data: { scaffold: ScaffoldWebAppData };
  steps: MultiStepFormStep[] | any;

  constructor(public bsModalRef: BsModalRef, public modalService: BsModalService) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.steps = [
      {
        title: 'Details',
        component: AddEditScaffoldDetailComponent,
      },
      {
        title: 'Specification',
        component: AddEditScaffoldSpecificationComponent
      }
    ];
    this.context.dialogClass = 'modal-dialog modal-lg';
  }

}

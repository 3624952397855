import { ScaffoldDetailQueryService } from '../../../../services/scaffold-detail-query-service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import {
  ScaffoldWebAppData,
  SiteWebAppData,
  CreateDismantleWebConsoleCommand,
  DismantleCommandService,
} from 'app/core/hub-api';
import { UUID } from 'angular2-uuid';
import { ScaffoldTimelineQueryService } from '../../../../services/scaffold-timeline-query-service';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { DismantleScaffoldModalContext } from '../../../../models/dismantle-scaffold-modal-context';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import { dates, datesDayjs } from 'app/shared/values/dates';
import { Moment } from 'moment';
import { UserIdentityService } from 'app/core/authentication';
import { DatePickerSelectData } from 'app/shared/models/date-picker-select-data';

@Component({
  selector: 'hub-dismantle-scaffold-modal',
  templateUrl: './dismantle-scaffold-modal.component.html',
  styleUrls: ['./dismantle-scaffold-modal.component.scss']
})
export class DismantleScaffoldModalComponent extends FormComponent implements OnInit {
  datePickerOptions: DatePickerOptions;
  notesFormControl: UntypedFormControl;
  dismantleDateFormControl: UntypedFormControl;
  scaffold: ScaffoldWebAppData;
  site: SiteWebAppData;
  title: string;
  context: Partial<DismantleScaffoldModalContext>;

  form: UntypedFormGroup;

  validationMessages = {
    dismantleDate: {
      required: errorMessages.required
    }
  };

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private dismantleCommandService: DismantleCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private siteDetailQueryService: SiteDetailQueryService,
    private userIdentityService: UserIdentityService,
    private scaffoldTimelineQueryService: ScaffoldTimelineQueryService,
    private scaffoldDetailQueryService: ScaffoldDetailQueryService,
  ) {
    super();
  }

  ngOnInit(): void {

    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.datePickerOptions = {
      alwaysShowCalendars: false,
      ranges: {},
      showCustomRangeLabel: false,
    };
    const dates = {
      startDate: datesDayjs.now,
    };
    this.notesFormControl = new UntypedFormControl('');
    this.dismantleDateFormControl = new UntypedFormControl(dates, Validators.required);

    this.form = new UntypedFormGroup({
      notes: this.notesFormControl,
      dismantleDate: this.dismantleDateFormControl
    });

    this.scaffold = cloneDeep(this.context.scaffold);
    this.site = this.context.site;

    this.title = `Dismantle Scaffold ${this.scaffold.ScaffoldNumber}`;
    this.dismantleDateFormControl.setValue(dates.startDate);
    super.ngOnInit();
  }

  /**
  * Detects the selected date range
  *
  * @param {*} dateRange date range selected by the user
  * @memberof ApplicationForPaymentFiltersComponent
  */
  public onDateRangeSelected(dateRange: any): void {
    this.dismantleDateFormControl.setValue(dateRange.startDate);
  }

  onSubmit(): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      let dismantleDate = new Date(this.form.value.dismantleDate);
      let now = new Date();
      let actualDateTimeDismantled = new Date(dismantleDate.getFullYear(), dismantleDate.getMonth(), dismantleDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
      const command: CreateDismantleWebConsoleCommand = {
        DismantleId: UUID.UUID(),
        Scaffolds: [this.scaffold.ScaffoldId],
        Notes: this.form.value.notes ? this.form.value.notes : '',
        OffsetFromUtc: moment().utcOffset(),
        OwnerId: this.userIdentityService.currentUser().userId,
        When: actualDateTimeDismantled.toISOString(),
      };

      this.dismantleCommandService.CreateDismantleWebConsoleCommand(command).subscribe(() => {
        const obs$ = forkJoin([
          this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId),
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.scaffold.ScaffoldId),
          this.scaffoldTimelineQueryService.scaffoldTimelineQuery(false, this.scaffold.ScaffoldId)
        ]);
        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.modalService.setDismissReason(this.scaffold.ScaffoldId);
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }
}

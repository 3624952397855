import { Component, OnInit, Input } from '@angular/core';
import { ActivityReportService } from 'app/shared/services/activity-report.service';

@Component({
  selector: 'hub-download-report-button',
  templateUrl: './download-report-button.component.html',
  styleUrls: ['./download-report-button.component.scss']
})
export class DownloadReportButtonComponent implements OnInit {
  @Input() ActivityType: number;
  @Input() ActivityDetail: {};
  @Input() ID: string;
  reportLoading: boolean;
  constructor(private activityReportService: ActivityReportService) {}

  ngOnInit(): void {}

  public downloadReport(): void {
    this.reportLoading = true;
    this.activityReportService.generateReport(this.ActivityType, this.ID, this.ActivityDetail).subscribe(() => (this.reportLoading = false));
  }
}

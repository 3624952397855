import { loadingLimitIds } from '../../shared/values/loading-limit-ids';

export const loadClasses = {
  'Load Class 1': loadingLimitIds.veryLight,
  'Load Class 2': loadingLimitIds.light,
  'Load Class 3': loadingLimitIds.general,
  'Load Class 4': loadingLimitIds.heavy,
  'Load Class Loading Bay 10': loadingLimitIds.loadingBay10,
  'Load Class Loading Bay 20': loadingLimitIds.loadingBay20
};

import { HandoverHistoryTypeEnum } from 'app/core/hub-api';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

// Component for displaying a handover history type enum.
@Component({
  selector: 'hub-handover-type',
  templateUrl: './handover-type.component.html',
  styleUrls: ['./handover-type.component.scss']
})
export class HandoverTypeComponent implements OnInit, OnChanges {

  @Input()
  type: HandoverHistoryTypeEnum;

  handoverType: string;
  cssClass: string;

  constructor() { }

  ngOnInit(): void {
    this.refreshComponent();
  }

  refreshComponent(): void {
    switch (this.type) {
      case HandoverHistoryTypeEnum.FullHandover:
        this.handoverType = 'Initial Handover';
        this.cssClass = 'fullHandover';
        break;
      case HandoverHistoryTypeEnum.FullOffHire:
        this.handoverType = 'Full Off-Hire';
        this.cssClass = 'fullOffHire';
        break;
      case HandoverHistoryTypeEnum.PartialOffHire:
        this.handoverType = 'Partial Off-Hire';
        this.cssClass = 'partialOffHire';
        break;
      case HandoverHistoryTypeEnum.PartialHandover:
        this.handoverType = 'Re-Handover';
        this.cssClass = 'partialHandover';
        break;
      case HandoverHistoryTypeEnum.Variation:
        this.handoverType = 'Variation';
        this.cssClass = 'variation';
        break;
      case HandoverHistoryTypeEnum.Adaption:
        this.handoverType = 'Adaption';
        this.cssClass = 'adaption';
        break;
      default:
        this.handoverType = 'Unknown';
        this.cssClass = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      this.refreshComponent();
    }
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationEventsService, UserIdentityService } from '../authentication';

// Route guard for ensuring that the user is logged in.
@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private userIdentityService: UserIdentityService,
    private router: Router,
    private authEventsService: AuthenticationEventsService
  ) { }

  private userRedirect(): void {
  }

  canActivate(): boolean {
    const authenticated = this.userIdentityService.isAuthenticated();

    this.userRedirect();

    if (!authenticated) {
      this.authEventsService.loginRequiredEvent.trigger({ expired: false });
    }
    return authenticated;
  }

}

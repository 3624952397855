
import { HellojsService } from './hellojs.service';
import { AuthenticationModuleSettings } from '../authentication-settings';
import { AUTHENTICATION_SETTINGS_TOKEN } from '../authentication-settings-token';
import { AuthResponse } from '../models/auth-response';
import { AuthResponseErrorType } from '../enum/auth-response-error-type';
import { AuthResponseError } from '../models/auth-response-error';
import { AuthService } from '../models/auth-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

// Service for authenticating using hello.js
@Injectable()
export class HelloAuthService implements AuthService {

  private readonly network = 'adB2CSignInSignUp';

  constructor(
    @Inject(AUTHENTICATION_SETTINGS_TOKEN) private settings: AuthenticationModuleSettings,
    private hello: HellojsService,
    private http: HttpClient
  ) { }
  // Initialise the service with our policies and return the authentication status. Throws an AuthResponseError
  // if the user is not authenticated.
  initialise(): Observable<AuthResponse> {
    this.hello.init({
      adB2CSignIn: this.settings.adalB2C.clientId,
      adB2CSignInSignUp: this.settings.adalB2C.clientId,
      adB2CEditProfile: this.settings.adalB2C.clientId
    }, {
      redirect_uri: this.settings.adalB2C.redirectUri,
      scope: 'openid ' + this.settings.adalB2C.appResourceURI,
      response_type: 'token'
    });
    const adB2CSignInSignUpPolicy = this.getPolicyConfiguredData();
    this.hello.init(adB2CSignInSignUpPolicy);
    // Get authentication status for adB2CSignInSignUp
    const authResponse = this.hello.getAuthResponse(this.network);
    if (authResponse && !authResponse.error) {
      // Resolve with the response.
      return of(authResponse);
    } else if (authResponse && authResponse.error) {
      const error: AuthResponseError = {
        name: 'AuthResponseError',
        type: AuthResponseErrorType.ErrorResponse,
        message: authResponse.error.message,
        code: authResponse.error.code,
      };
      return throwError(() => error);
    } else {
      const error: AuthResponseError = {
        name: 'AuthResponseError',
        type: AuthResponseErrorType.NoResponse,
        message: 'The current user is not authenticated.'
      };
      return throwError(() => error);
    }
  }

  // Log the user in, redirect to the login page if it is a password login - otherwise refresh the access_token in the background.
  login(requirePassword: boolean): Observable<{ network, authResponse: AuthResponse }> {
    if (requirePassword) {
      // Show authentication page.
      return this.hello.login(this.network, {
        display: 'page',
        force: true
      });
    } else {
      return this.hello.login(this.network, {
        display: 'none'
      });
    }
  }

  logout(): Observable<{ network: string }> {
    return this.hello.logout(this.network, {
      force: true
    });
  }

  // Azure active directory B2C Sign-In and Sign-Up Policy Configuration, based on:
  // https://github.com/Azure-Samples/active-directory-b2c-javascript-hellojs-singlepageapp/blob/master/B2C-v2jsapp/aadb2c.js
  private getPolicyConfiguredData() {

    const adB2CSignInSignUpPolicy = {};
    adB2CSignInSignUpPolicy[this.network] = {
      name: 'Azure Active Directory B2C',
      oauth: {
        version: 2,
        auth: `${this.settings.adalB2C.adb2cBaseOAuthUri}tfp/${this.settings.adalB2C.tenantName}` +
          `/${this.settings.adalB2C.policy}/oauth2/v2.0/authorize`,
        grant: `${this.settings.adalB2C.adb2cBaseOAuthUri}tfp/${this.settings.adalB2C.tenantName}` +
          `/${this.settings.adalB2C.policy}/oauth2/v2.0/token`
      },
      refresh: true,
      scope_delim: ' ',
      // Don't even try submitting via form.
      // This means no POST operations in <=IE9
      form: false
    };
    adB2CSignInSignUpPolicy[this.network].xhr = p => {
      if (p.method === 'post' || p.method === 'put') {
        // toJSON(p);
        if (typeof (p.data) === 'object') {
          // Convert the POST into a javascript object
          try {
            p.data = JSON.stringify(p.data);
            p.headers['content-type'] = 'application/json';
          } catch (e) { }
        }
      } else if (p.method === 'patch') {
        this.hello.utils.extend(p.query, p.data);
        p.data = null;
      }
      return true;
    };
    adB2CSignInSignUpPolicy[this.network].logout = () => {
      // get id_token from auth response
      const authResponse = this.hello.getAuthResponse(this.network);
      const id_token = authResponse.id_token;
      // clearing local storage session
      this.hello.utils.store(this.network, null);

      // redirecting to Azure B2C logout URI
      const logoutUrl = `${this.settings.adalB2C.adb2cBaseOAuthUri}/${this.settings.adalB2C.tenantName}/oauth2/v2.0/logout?p=` + 
                        `${this.settings.adalB2C.policy}&id_token_hint=${id_token}&post_logout_redirect_uri=${this.settings.adalB2C.redirectUri}`;
      window.open(logoutUrl, '_self');

      return true;
    };
    return adB2CSignInSignUpPolicy;
  }
}

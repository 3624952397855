import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
  SiteAreaData,
  UpdateAreasCommand,
  ImportEndpointService,
  ItemTypeEnum
} from 'app/core/hub-api';
import { ScaffoldImportModalContext } from '../scaffold-import-modal-context';
import { UntypedFormGroup } from '@angular/forms';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { scaffoldStatusIds } from 'app/shared/values/scaffold-status-ids';
import { GenerateDefaultsService } from 'app/core/services/generateDefaults.service';
import { SiteDetailQueryService } from 'app/sites/services/site-detail-query.service';
import { SiteScaffoldQueryService } from 'app/sites/services/site-scaffolds-query.service';
import {
  flatMap as _flatMap,
  sortBy as _sortBy,
  map as _map,
  uniq as _uniq,
  uniqWith as _uniqWith,
  isEqual as _isEqual,
  uniqBy as _uniqBy
} from 'lodash';
import { TableHeadersEnum } from 'app/sites/values/table-headers.enum';
import { environment } from 'environments/environment';
import { MultiStepModalFormComponent } from 'app/shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { ImportColumnIndexes } from 'app/sites/components/site-detail/scaffolds/scaffold-import-modal/values/import-column-indexes.enum';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';
import { AngularGridInstance, Column, EditorArguments, Editors, EditorValidator, FieldType, Formatters, GridOption, OnEventArgs } from 'angular-slickgrid';
import { ReferenceGeneratorService } from 'app/core/utility';

// using external non-typed js libraries - required SlickGrid
declare var Slick: any;

@Component({
  selector: 'hub-import-spreadsheet',
  templateUrl: './import-spreadsheet.html',
  styleUrls: ['./import-spreadsheet.scss']
})
export class ImportSpreadsheetComponent extends MultiStepModalFormComponent implements OnInit, AfterViewInit {
  context: Partial<ScaffoldImportModalContext>;
  // SlickGrid
  private _commandQueue = [];
  angularGrid: AngularGridInstance;
  columnDefinitions: Column[];
  gridOptions: GridOption;
  dataset;
  gridObj: any;
  isAutoEdit = false;
  alertWarning: any;
  updatedObject: any;
  selectedLanguage = 'en';
  defaultAreaName = 'Default area';
  defaultArea: any;

  // MultiStep
  data: ScaffoldImportModalContext;
  form: UntypedFormGroup;
  validationMessages = {};

  areaNames: any;
  scaffoldTypesNames: string[];
  designTypesNames: string[];
  loadingLimitsNames: string[];
  methodCladdingsNames: string[];
  methodTyingsNames: string[];
  scaffoldingSystemNames: string[];
  scaffoldTypes;
  designTypes;
  loadingLimits;
  methodCladdings;
  methodTyings;
  quotes;
  allScaffolds;
  scaffoldingSystems;
  invalidForm: string;
  areas: SiteAreaData[];
  newAreas: any = [];
  newAndExistingAreas: any = [];
  dataView: any;
  deletedRows = [];
  disableSaveBtn = true;
  errorValidation: any = [];
  momentDateFormat = 'DD/MM/YYYY';
  flatPickerFormat = 'YYYY-MM-DD HH:mm:ss';
  filteredItems: any;

  titleTooltips = {
    required: 'This field is required',
    max100: 'This field maximum length is 100 characters',
    number: 'This field only accepts numbers'
  };

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private configurationQueryService: ConfigurationQueryService,
    private referenceGeneratorService: ReferenceGeneratorService,
    private changeDetectorRef: ChangeDetectorRef,
    private importEndpointService: ImportEndpointService,
    private generateDefaultsService: GenerateDefaultsService,
    private siteDetailQueryService: SiteDetailQueryService,
    private siteScaffoldQueryService: SiteScaffoldQueryService
  ) {
    super(); // Required due to stepper structure
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    if (!this.context) this.context = this.modalService.config.initialState;
    this.initialisationData();
    this.prepareGrid();
    this.checkForSavedData();
    this.form = new UntypedFormGroup({}); // Required due to stepper structure
    this.context.dialogClass = 'modal-dialog modal-xl';
    super.ngOnInit(); // Required due to stepper structure
  }

  ngAfterViewInit(): void {
    if (this.dataset && !this.dataset.length && !this.data.savedScaffolds) {
      this.clearAllRows(1, true);
      this.changeDetectorRef.detectChanges();
    }
    if (this.filteredItems) {
      this.filteredItems.valueChanges.subscribe(i => {
        this.changeDetectorRef.detectChanges();
        this.filteredItems = i;
      });
    }
  }

  initialisationData(): void {
    const areas = _sortBy(_map(this.context.site.SiteAreas), 'AreaName');
    this.areaNames = areas.map(a => a.AreaName);
    this.areas = areas;
    this.quotes = this.data.quotes;
    this.configurationQueryService.configurationQuery(true).subscribe(res => {
      this.scaffoldTypes = _map(res.ScaffoldTypes);
      this.scaffoldTypes.push({
        Code: '',
        Deleted: false,
        ScaffoldTypeId: '',
        Title: ''
      });
      this.designTypes = _map(res.DesignTypes);
      this.designTypes.push({
        Code: '',
        Deleted: false,
        DesignTypeId: '',
        Title: ''
      });
      this.loadingLimits = _map(res.LoadingLimits);
      this.loadingLimits.push({
        Code: '',
        Deleted: false,
        LoadingLimitId: '',
        Title: ''
      });
      this.methodCladdings = _map(res.MethodOfCladdings);
      this.methodCladdings.push({
        Code: '',
        Deleted: false,
        MethodOfCladdingId: '',
        Title: ''
      });
      this.methodTyings = _map(res.MethodOfTyings);
      this.methodTyings.push({
        Code: '',
        Deleted: false,
        MethodOfTyingId: '',
        Title: ''
      });
      this.scaffoldingSystems = _map(res.ScaffoldingSystems);
      this.scaffoldingSystems.push({
        Code: '',
        Deleted: false,
        ScaffoldginSystemId: '',
        Title: ''
      });
    });
    this.areas = _map(this.context.site.SiteAreas);
  }

  checkForSavedData(): void {
    this.errorValidation = this.data.validationErrors && this.data.validationErrors.length ? this.data.validationErrors : [];
    this.dataset = this.data.savedScaffolds ? this.data.savedScaffolds : !this.data.copyPaste ? this.generateData() : [];
    const deletedRow = this.data.deletedRows ? this.data.deletedRows : [];
    this.data.deletedRows = deletedRow;
  }

  angularGridReady(angularGrid: AngularGridInstance): void {
    this.angularGrid = angularGrid;
    this.gridObj = angularGrid.slickGrid;
    this.dataView = angularGrid.dataView;
    this.verifyGrid();
  }

  highlightCell(row, colId, cssClass: string = 'invalid'): void {
    if (row >= 0) {
      const cssCellStyle = {};
      (cssCellStyle[row] = {})[colId] = cssClass;
      (this.angularGrid.gridService as any)._grid.setCellCssStyles(`${cssClass}.${colId}.${row}`, cssCellStyle);
    }
  }

  removeQuoteAndItemVal(rowIndex): any {
    this.removeCellHighlight(rowIndex, 'quoteNumber', 'invalid');
    this.removeCellHighlight(rowIndex, 'itemNumber', 'invalid');
    this.removeCellHighlight(rowIndex, 'itemName', 'invalid');
    if (this.errorValidation.length) {
      this.errorValidation.filter(v => {
        if (
          v.cell !== ImportColumnIndexes.quoteNumber &&
          v.header !== this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.quoteNumber] &&
          v.cell !== ImportColumnIndexes.itemNumber &&
          v.header !== this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemNumber] &&
          v.cell !== ImportColumnIndexes.itemName &&
          v.header !== this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemName]
        ) {
          return v;
        }
      });
    }
  }

  verifyGrid(): void {
    this.dataset.forEach(row => {
      const rowIndex = this.dataset.indexOf(row);

      // Deleted Validation
      if (this.data.deletedRows.includes(rowIndex)) {
        this.removeAllRowHighlights(rowIndex, true);
        this.removeRowHighlight(rowIndex);
        this.errorValidation = this.errorValidation.filter(v => v.row !== rowIndex);
        return;
      }
      // SCAFFOLD NAME CHECKING
      if (row.scaffoldName.trim() !== '') {
        this.removeCellHighlight(rowIndex, 'scaffoldName', 'invalid');
        if (this.errorValidation.length) {
          this.errorValidation = this.errorValidation.filter(v => v.row !== rowIndex && v.header !== 'scaffoldName');
        }
      }
      if (row.scaffoldName.trim() === '') {
        this.highlightCell(rowIndex, 'scaffoldName', 'invalid');
        const scaffoldName = { row: rowIndex, cell: ImportColumnIndexes.scaffoldName, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.scaffoldName] };
        this.addUniqueValidation(scaffoldName);
      }
      // AREA CHECKING
      if (row.area.trim() !== '') {
        this.highlightCell(rowIndex, 'area', ' ');
        this.removeCellHighlight(rowIndex, 'area', 'slickgrid-autoFill');
      }
      if (row.area.trim() === '') {
        this.dataset[rowIndex].area = row.area.trim() === '' ? this.defaultAreaName : row.area;
        this.highlightCell(rowIndex, 'area', 'slickgrid-autoFill');
      }
      // QUOTE AND ITEM CHECKING
      if ((row.quoteNumber.trim() === '' && row.itemNumber.trim() === '' && row.itemName.trim() === '') || (row.quoteNumber.trim() && row.itemNumber.trim() && row.itemName.trim())) {
        this.removeQuoteAndItemVal(rowIndex);
      } else {
        const quoteNumberObj = { row: rowIndex, cell: ImportColumnIndexes.quoteNumber, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.quoteNumber] };
        const itemNameObj = { row: rowIndex, cell: ImportColumnIndexes.itemNumber, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemNumber] };
        const itemNumberObj = { row: rowIndex, cell: ImportColumnIndexes.itemName, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemName] };
        this.highlightCell(rowIndex, 'quoteNumber', 'invalid');
        this.addUniqueValidation(quoteNumberObj);
        this.highlightCell(rowIndex, 'itemNumber', 'invalid');
        this.addUniqueValidation(itemNameObj);
        this.highlightCell(rowIndex, 'itemName', 'invalid');
        this.addUniqueValidation(itemNumberObj);
      }

      // MAXIMUM TONNAGE CHECKING
      if (Number(row.maximumTonnage) < 0) {
        this.highlightCell(rowIndex, 'maximumTonnage', 'invalid');
        const maximumTonnageObj = { row: rowIndex, cell: ImportColumnIndexes.maximumTonnage, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.maximumTonnage] };
        this.errorValidation.push(maximumTonnageObj);
      }
      if (Number(row.maximumTonnage) >= 0 || row.maximumTonnage === '') {
        this.errorValidation = this.errorValidation.filter(v => v.cell !== ImportColumnIndexes.maximumTonnage && v.header !== 'maximumTonnage');
        this.dataset[rowIndex].maximumTonnage = row.maximumTonnage ? row.maximumTonnage : 0.00;
        this.removeCellHighlight(rowIndex, 'maximumTonnage', 'invalid');
      }

      this.errorValidation = _uniqWith(this.errorValidation, _isEqual);
    });
    this.gridObj.setData(this.dataset);
    this.gridObj.updateRowCount();
    this.gridObj.render();
    this.disableSaveBtn = this.errorValidation.length ? true : false;
    this.changeDetectorRef.detectChanges();
  }

  addUniqueValidation(item): void {
    this.errorValidation = _uniqWith(this.errorValidation, _isEqual);
    if (!this.errorValidation.length) this.errorValidation.push(item);
    if (this.errorValidation.indexOf(item) === -1) {
      this.errorValidation.push(item);
    }
  }

  removeCellHighlight(row, colId, cssClass): any {
    (this.angularGrid.gridService as any)._grid.removeCellCssStyles(`${cssClass}.${colId}.${row}`);
  }

  removeAllRowHighlights(row, removeInvalid = false, removeDeleted = false): void {
    const headers = this.columnDefinitions.map(c => c.id);
    headers.forEach(colId => {
      this.removeCellHighlight(row, colId, 'slickgrid-autoFill');
      if (removeInvalid) {
        this.removeCellHighlight(row, colId, 'invalid');
      }
      if (removeDeleted) {
        this.removeCellHighlight(row, colId, 'slickgrid-deletedRow');
      }
    });
  }

  removeRowHighlight(row): void {
    const headers = this.columnDefinitions.map(c => c.id);
    headers.forEach(colId => this.highlightCell(row, colId, 'slickgrid-deletedRow'));
  }

  handleOnBeforeEditCell(e, args): boolean {
    // returning false or call `e.preventDefault()` should cancel the edit
    this.verifyGrid();
    if (this.data.deletedRows.includes(args.row)) {
      this.removeAllRowHighlights(args.row, true);
      this.removeRowHighlight(args.row);
      return false;
    }
  }

  prepareGrid(): void {
    this.columnDefinitions = [
      {
        id: 'delete',
        field: 'id',
        excludeFromHeaderMenu: true,
        formatter: Formatters.deleteIcon,
        exportWithFormatter: false,
        minWidth: 30,
        maxWidth: 30,
        width: 30,
        onCellClick: (e: Event, args: OnEventArgs) => {
          if (this.data.deletedRows.includes(args.row)) {
            this.removeAllRowHighlights(args.row, false, true);
            this.data.deletedRows = this.data.deletedRows.filter(d => d !== args.row);
            this.verifyGrid();
          } else {
            const context: SSDialogContext = {
              title: `Delete Row?`,
              body: `Are you sure you want to delete this row?`,
              okBtnClass: 'btn button-assertive',
              okBtnText: 'Yes delete the row',
              cancelBtnText: 'No keep it',
            };
            const config: ModalOptions = {
              class: 'modal-danger',
            };
            const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));
            modal.content.onClose.subscribe(
              result => {
                if (result === true) {
                  this.data.deletedRows.push(args.row);
                  this.removeAllRowHighlights(args.row, true);
                  this.removeRowHighlight(args.row);
                  this.verifyGrid();
                }
              },
              error => {
                console.error(error);
              }
            );
          }
        }
      },
      {
        id: 'scaffoldName',
        name: 'Scaffold Name',
        field: 'scaffoldName',
        minWidth: 100,
        type: FieldType.string,
        cssClass: 'test1',
        editor: {
          model: Editors.text,
          required: true,
          validator: this.validationChecker,
          title: this.titleTooltips.required,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].scaffoldName = args.dataContext.scaffoldName.trim();
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'scaffoldDescription',
        name: 'Scaffold Description',
        field: 'scaffoldDescription',
        minWidth: 100,
        type: FieldType.string,
        editor: {
          model: Editors.text,
          required: false,
          validator: this.validationChecker,
          title: this.titleTooltips.max100,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].scaffoldDescription = args.dataContext.scaffoldDescription.trim();
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'area',
        name: 'Area',
        field: 'area',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          model: Editors.autocompleter,
          validator: this.validationChecker,
          title: this.titleTooltips.max100,
          editorOptions: {
            minLength: 2,
            forceUserInput: true,
            source: this.areas.map(a => a.AreaName),
          }
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const area = args.dataContext.area.trim();
          const checker = this.areas.filter(a => area !== '' && a.AreaName === area);
          this.dataset[args.row].area = area === '' ? this.defaultAreaName : area;
          if (this.dataset[args.row].area === this.defaultAreaName) {
            this.highlightCell(args.row, args.columnDef.id, 'slickGrid-autoFill');
          }
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'scaffoldType',
        name: 'Scaffold Type',
        field: 'scaffoldType',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.scaffoldTypes.map(st => ({
            value: st.Title,
            label: st.Title,
            id: st.ScaffoldTypeId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
          },
          model: Editors.singleSelect,
          required: false,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.scaffoldType.trim(), this.scaffoldTypes.map(s => s.Title));
          this.dataset[args.row].scaffoldType = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'loadingLimit',
        name: 'Loading Limit',
        field: 'loadingLimit',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.loadingLimits.map(ll => ({
            value: ll.Title,
            label: ll.Title,
            id: ll.LoadingLimitId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
          },
          model: Editors.singleSelect,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.loadingLimit.trim(), this.loadingLimits.map(s => s.Title));
          this.dataset[args.row].loadingLimit = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'methodCladding',
        name: 'Method of Cladding',
        field: 'methodCladding',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.methodCladdings.map(mc => ({
            value: mc.Title,
            label: mc.Title,
            id: mc.MethodOfCladdingId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
          },
          model: Editors.singleSelect,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.methodCladding.trim(), this.methodCladdings.map(s => s.Title));
          this.dataset[args.row].methodCladding = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'methodTying',
        name: 'Method of Tying',
        field: 'methodTying',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.methodTyings.map(mt => ({
            value: mt.Title,
            label: mt.Title,
            id: mt.MethodOfTyingId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
            optionLabel: 'value'
          },
          model: Editors.singleSelect,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.methodTying.trim(), this.methodTyings.map(s => s.Title));
          this.dataset[args.row].methodTying = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'quoteNumber',
        name: 'Quote Number',
        field: 'quoteNumber',
        minWidth: 100,
        type: FieldType.string,
        editor: {
          model: Editors.autocompleter,
          validator: this.validationChecker,
          title: this.titleTooltips.max100,

          editorOptions: {
            minLength: 0,
            forceUserInput: true,
            source: this.quotes.map(qt => qt.QuoteNumber),
          }
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.filteredItems = this.quotes.filter(q => q.QuoteNumber.toLowerCase() === args.dataContext.quoteNumber.trim().toLowerCase());
          if (this.filteredItems.length) {
            this.dataset[args.row].itemNumber = this.filteredItems[0].Items.map(i => i.ItemNumber)[0];
            this.dataset[args.row].itemName = this.filteredItems[0].Items.map(i => i.ItemName)[0];
            args.grid.updateCell(args.row, ImportColumnIndexes.itemNumber);
            args.grid.updateCell(args.row, ImportColumnIndexes.itemName);
            this.columnDefinitions[9].editor.editorOptions.source = this.filteredItems[0].Items.map(i => i.ItemNumber);
            this.highlightCell(args.row, 'itemNumber', 'slickgrid-autoFill');
            this.highlightCell(args.row, 'itemName', 'slickgrid-autoFill');
            this.verifyGrid();
            this.dataView.refresh();
          } else {
            this.verifyGrid();
          }
        }
      },
      {
        id: 'itemNumber',
        name: 'Item Number',
        field: 'itemNumber',
        minWidth: 100,
        type: FieldType.string,
        editor: {
          model: Editors.autocompleter,
          title: this.titleTooltips.max100,
          editorOptions: {
            minLength: 0,
            forceUserInput: true,
            source: this.filteredItems && this.filteredItems.length ? this.filteredItems[0].Items.map(i => i.ItemNumber) : '',
          }
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.removeCellHighlight(args.row, args.columnDef.field, 'slickgrid-autoFill');
          if (this.filteredItems && this.filteredItems.length) {
            const items = this.filteredItems[0];
            if (items.Items.map(it => it.ItemNumber).includes(this.dataset[args.row].itemNumber)) {
              args.columnDef.internalColumnEditor.editorOptions.source = this.filteredItems[0].Items.map(i => i.ItemNumber);
              const itemNameSelected = this.filteredItems[0].Items.filter(i => i.ItemNumber === this.dataset[args.row].itemNumber);
              this.dataset[args.row].itemName = !this.filteredItems.length && !itemNameSelected.length ? '' :
                itemNameSelected.length ? itemNameSelected[0].ItemName : this.filteredItems[0].Items.map(i => i.ItemName)[0];
              args.grid.updateCell(args.row, ImportColumnIndexes.itemName);
              args.grid.updateCell(args.row, args.cell);
            } else {
              this.dataset[args.row].itemName = '';
            }
          }
          this.verifyGrid();
        },
        onCellClick: (e: Event, args: OnEventArgs) => {
          if (this.filteredItems && this.filteredItems.length) {
            const items = this.filteredItems[0];
            if (items.Items.map(it => it.ItemNumber).includes(this.dataset[args.row].itemNumber)) {
              args.columnDef.internalColumnEditor.editorOptions.source = items.Items.map(i => i.ItemNumber);
            }
          }
        }
      },
      {
        id: 'itemName',
        name: 'Item Name',
        field: 'itemName',
        minWidth: 100,
        type: FieldType.string,
        editor: {
          title: this.titleTooltips.max100,
          model: Editors.text,
          validator: this.validationChecker
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          if (this.filteredItems && this.filteredItems.length && this.filteredItems[0].Items.map(i => i.ItemNumber).includes(this.dataset[args.row].itemNumber)) {
            this.dataset[args.row].itemName = this.filteredItems[0].Items.filter(i => i.ItemNumber === this.dataset[args.row].itemNumber)[0].ItemName;
            // defaults to the item name if an existing one
            args.grid.updateCell(args.row, args.cell);
          } else {
            // removes the highlight if it is a new item
            this.removeCellHighlight(args.row, args.columnDef.field, 'slickgrid-autoFill');
          }
          this.verifyGrid();
        }
      },
      {
        id: 'scaffoldingSystem',
        name: 'Scaffolding System',
        field: 'scaffoldingSystem',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.scaffoldingSystems.map(st => ({
            value: st.Title,
            label: st.Title,
            id: st.ScaffoldingSystemId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
          },
          model: Editors.singleSelect,
          required: false,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.scaffoldingSystem.trim(), this.scaffoldingSystems.map(s => s.Title));
          this.dataset[args.row].scaffoldingSystem = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'maximumTonnage',
        name: 'Maximum Tonnage',
        field: 'maximumTonnage',
        minWidth: 100,
        type: FieldType.float,
        editor: {
          title: this.titleTooltips.number,
          model: Editors.float,
          required: false,
          params: { decimalPlaces: 2 },
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].maximumTonnage = args.dataContext.maximumTonnage || Number(args.dataContext.maximumTonnage) < 0
            ? Number(args.dataContext.maximumTonnage).toFixed(2)
            : (0.00).toFixed(2);
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'hirePeriod',
        name: 'Hire Period',
        field: 'hirePeriod',
        minWidth: 100,
        type: FieldType.float,
        editor: {
          title: this.titleTooltips.number,
          model: Editors.float,
          required: false,
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].hirePeriod = args.dataContext.hirePeriod ? Number(Math.round(args.dataContext.hirePeriod)) : null;
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'designType',
        name: 'Design Type',
        field: 'designType',
        minWidth: 100,
        filterable: false,
        sortable: false,
        type: FieldType.string,
        editor: {
          collection: this.designTypes.map(dt => ({
            value: dt.Title,
            label: dt.Title,
            id: dt.DesignTypeId
          })),
          collectionSortBy: {
            property: 'label'
          },
          customStructure: {
            label: 'label',
            value: 'value',
          },
          model: Editors.singleSelect,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          const matchedValue = this.matchRules(args.dataContext.designType.trim(), this.designTypes.map(s => s.Title));
          this.dataset[args.row].designType = matchedValue ? matchedValue : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'dateNextInspection',
        name: 'Next Inspection Date',
        field: 'dateNextInspection',
        minWidth: 100,
        type: FieldType.dateTimeIso,
        formatter: Formatters.dateIso,
        editor: {
          model: Editors.date,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].dateNextInspection = moment(args.dataContext.dateNextInspection, this.flatPickerFormat, true).isValid()
            ? args.dataContext.dateNextInspection
            : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'onHireDate',
        name: 'On Hire Date',
        field: 'onHireDate',
        minWidth: 100,
        type: FieldType.dateTimeIso,
        formatter: Formatters.dateIso,
        editor: {
          model: Editors.date,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          this.dataset[args.row].onHireDate = moment(args.dataContext.onHireDate, this.flatPickerFormat, true).isValid()
            ? args.dataContext.onHireDate
            : '';
          args.grid.updateCell(args.row, args.cell);
          this.verifyGrid();
        }
      },
      {
        id: 'affectedAdverseWeather',
        name: 'Affected by Adverse Weather',
        field: 'affectedAdverseWeather',
        minWidth: 100,
        type: FieldType.boolean,
        formatter: Formatters.checkmark,
        editor: {
          model: Editors.checkbox,
          required: false
        },
        onCellChange: (e: Event, args: OnEventArgs) => {
          if (args.dataContext && args.dataContext.affectedAdverseWeather) {
            const cellValue = args.dataContext.affectedAdverseWeather.toString().trim().toLowerCase();
            const cellValueIsTrue = cellValue === 't' ||
              cellValue === 'true' ||
              cellValue === 'y' ||
              cellValue === 'yes';
            this.dataset[args.row].affectedAdverseWeather = cellValueIsTrue;
            args.grid.updateCell(args.row, args.cell);
          }
          this.verifyGrid();
        }
      }
    ];

    this.gridOptions = {
      forceFitColumns: true,
      enableAutoResize: true,
      autoHeight: true,
      enableColumnReorder: false,
      asyncEditorLoading: false,
      autoEdit: this.isAutoEdit,
      autoCommitEdit: false,
      enableGridMenu: false,
      enableHeaderMenu: false,
      autoResize: {
        container: 'importGrid',
        // sidePadding: 15
      },
      headerRowHeight: 45,
      rowHeight: 45,
      editable: true,
      enableCellNavigation: true,
      enableColumnPicker: false,
      enableExcelCopyBuffer: true,
      enableFiltering: false,


      editCommandHandler: (item, column, editCommand) => {
        this._commandQueue.push(editCommand);
        editCommand.execute();
      }
    };
  }

  validationChecker: EditorValidator = (value: any, args: EditorArguments) => {
    const maxCharacters = 100;
    let message = '';
    const requiredFieldMessage = 'This is a required field';
    const maxCharactersMessage = `The max length for this field is ${maxCharacters} characters.`;

    const colName = args.column.field;

    // Required
    if ((colName === 'scaffoldName' && value == null) || value === undefined || !value.length) {
      message = requiredFieldMessage;
    }

    // Max Length
    if (
      colName === 'scaffoldName' ||
      colName === 'scaffoldDescription' ||
      colName === 'area' ||
      colName === 'quoteNumber' ||
      colName === 'itemNumber' ||
      colName === 'itemName'
    ) {
      message = maxCharacters >= value.length ? '' : maxCharactersMessage;
    }

    return this.openValidationModal(message);
  }

  matchRules(userValue: string, arrayToCheck: any): string {
    let matchedValue = '';
    arrayToCheck.forEach(val => {
      const arrayVal = this.textFormatterPattern(val);
      const userVal = this.textFormatterPattern(userValue);
      if (arrayVal === userVal) {
        matchedValue = val;
      }
    });
    return matchedValue;
  }

  textFormatterPattern(value): string {
    // Following pattern takes out any sub/superscript from a string
    const convertedValue = value.replace(
      /[\u006E\u00B0\u00B2\u00B3\u00B9\u02AF\u0670\u0711\u2121\u213B\u2207\u29B5\uFC5B-\uFC5D\uFC63\uFC90\uFCD9\u2070\u2071\u2074-\u208E\u2090-\u209C\u0345\u0656\u17D2\u1D62-\u1D6A\u2A27\u2C7C]\^+/g,
      ''
    );
    return convertedValue.toLowerCase();
  }

  openValidationModal(message): any {
    if (message) {
      const context: SSDialogContext = {
        title: `Validation Error`,
        body: message,
        cancelBtnText: 'Close',
        showOnlyCloseBtn: true,
        cancelBtnClass: 'btn button-assertive',
      };
      const config: ModalOptions = {
        class: 'modal-danger',
      };
      const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));

      modal.content.onClose.subscribe(
        result => {
          if (result !== null) {
            return { valid: false, msg: 'This is a required field' };
          }
        },
        error => {
          console.error(error);
        }
      );
    } else {
      return { valid: true, msg: '' };
    }
  }

  generateData(): any {
    const headers = TableHeadersEnum;
    const scaffoldData = [];
    this.data.scaffoldImportFile.shift();
    this.data.scaffoldImportFile.forEach(row => {
      const scaffoldTypesChecker = this.scaffoldTypes.filter(st => (row[headers.ScaffoldType] !== '' && st.Title.toLowerCase() === row[headers.ScaffoldType].toLowerCase()));
      const scaffoldTypesMatchedValue = this.matchRules(row[headers.ScaffoldType], this.scaffoldTypes.map(s => s.Title));

      const designTypeChecker = this.designTypes.filter(dt => (row[headers.DesignType] !== '' && dt.Title.toLowerCase() === row[headers.DesignType].toLowerCase()));
      const designTypeMatchedValue = this.matchRules(row[headers.DesignType], this.designTypes.map(s => s.Title));

      const loadingLimitChecker = this.loadingLimits.filter(ll => (row[headers.LoadingLimits] !== '' && ll.Title.toLowerCase() === row[headers.LoadingLimits].toLowerCase()));
      const loadingLimitMatchedValue = this.matchRules(row[headers.LoadingLimits], this.loadingLimits.map(s => s.Title));

      const methodCladdingChecker = this.methodCladdings.filter(mc => (row[headers.MethodCladding] !== '' && mc.Title.toLowerCase() === row[headers.MethodCladding].toLowerCase()));
      const methodCladdingMatchedValue = this.matchRules(row[headers.MethodCladding], this.methodCladdings.map(s => s.Title));

      const methodTyingChecker = this.methodTyings.filter(mt => (row[headers.MethodTying] !== '' && mt.Title.toLowerCase() === row[headers.MethodTying].toLowerCase()));
      const methodTyingMatchedValue = this.matchRules(row[headers.MethodTying], this.methodTyings.map(s => s.Title));

      const affectedAdverseWeatherChecker = row[headers.AdverseWeather].toLowerCase() === 't'
        || row[headers.AdverseWeather].toLowerCase() === 'true'
        || row[headers.AdverseWeather].toLowerCase() === 'y'
        || row[headers.AdverseWeather].toLowerCase() === 'yes'
        ? true : false;

      const dateNextInsp = row[headers.DateNextInspection] && moment(row[headers.DateNextInspection], this.momentDateFormat, true).isValid() ? row[headers.DateNextInspection] : '';
      const hirePeriodDate = row[headers.OnHireDate] && moment(row[headers.OnHireDate], this.momentDateFormat, true).isValid() ? row[headers.OnHireDate] : '';

      if (row.length > 1) {
        const rowIndex = this.data.scaffoldImportFile.indexOf(row);
        if (row[headers.QuoteNumber] !== '' && row[headers.ItemNumber] !== '' && row[headers.ItemName] !== '') {
          if (row[headers.QuoteNumber] === '' || row[headers.ItemNumber] === '' || row[headers.ItemName] === '') {
            this.highlightCell(rowIndex, 'quoteNumber', 'invalid');
            this.highlightCell(rowIndex, 'itemNumber', 'invalid');
            this.highlightCell(rowIndex, 'itemName', 'invalid');
            this.addUniqueValidation({ row: rowIndex + 1, cell: ImportColumnIndexes.quoteNumber, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.quoteNumber] });
            this.addUniqueValidation({ row: rowIndex + 1, cell: ImportColumnIndexes.itemNumber, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemNumber] });
            this.addUniqueValidation({ row: rowIndex + 1, cell: ImportColumnIndexes.itemName, header: this.columnDefinitions.map(t => t.name)[ImportColumnIndexes.itemName] });
          }
        }
        const structure = {
          id: rowIndex + 1,
          scaffoldName: row[headers.ScaffoldName],
          scaffoldDescription: row[headers.ScaffoldDescription],
          area: row[2],
          scaffoldType: scaffoldTypesChecker.length ? scaffoldTypesChecker[0].Title : scaffoldTypesMatchedValue,
          loadingLimit: loadingLimitChecker.length ? loadingLimitChecker[0].Title : loadingLimitMatchedValue,
          methodCladding: methodCladdingChecker.length ? methodCladdingChecker[0].Title : methodCladdingMatchedValue,
          methodTying: methodTyingChecker.length ? methodTyingChecker[0].Title : methodTyingMatchedValue,
          quoteNumber: row[headers.QuoteNumber] ? row[headers.QuoteNumber] : '',
          itemNumber: row[headers.ItemNumber] ? row[headers.ItemNumber] : '',
          itemName: row[headers.ItemName] ? row[headers.ItemName] : '',
          scaffoldingSystem: row[headers.ScaffoldingSystem] ? row[headers.ScaffoldingSystem] : '',
          maximumTonnage: Number(row[headers.MaximumTonnage]) || Number(row[headers.MaximumTonnage]) < 0 ? Number(row[headers.MaximumTonnage]).toFixed(2) : 0.00,
          hirePeriod: Number(Math.round(row[headers.HirePeriod])) ? Number(Math.round(row[headers.HirePeriod])) : null,
          designType: designTypeChecker.length ? designTypeChecker[0].Title : designTypeMatchedValue,
          dateNextInspection: dateNextInsp ? moment(dateNextInsp, this.momentDateFormat).format(this.flatPickerFormat) : '',
          onHireDate: hirePeriodDate ? moment(hirePeriodDate, this.momentDateFormat).format(this.flatPickerFormat) : '',
          affectedAdverseWeather: affectedAdverseWeatherChecker
        };
        scaffoldData.push(structure);
      }
    });
    return scaffoldData;
  }

  changeAutoCommit(): boolean {
    this.gridOptions.autoCommitEdit = !this.gridOptions.autoCommitEdit;
    this.gridObj.setOptions({
      autoCommitEdit: this.gridOptions.autoCommitEdit
    });
    return true;
  }

  setAutoEdit(isAutoEdit): boolean {
    this.isAutoEdit = isAutoEdit;
    this.gridObj.setOptions({ autoEdit: isAutoEdit }); // change the grid option dynamically
    return true;
  }

  undo(): void {
    const command = this._commandQueue.pop();
    if (command && Slick.GlobalEditorLock.cancelCurrentEdit()) {
      command.undo();
      this.gridObj.gotoCell(command.row, command.cell, false);
    }
  }

  onSave(): void {
    this.gridObj.getEditorLock().commitCurrentEdit(); // auto commits open edits

    this.verifyGrid();

    // Checks if there are errors and stops user from importing data
    this.disableSaveBtn = this.errorValidation.length ? true : false;
    if (this.errorValidation.length) return;

    if (!this.dataset.filter(d => !this.data.deletedRows.includes(this.dataset.indexOf(d))).length) {
      const message = 'Unable to find any scaffolds on the spreadsheet, please note that the scaffold name is required.';
      return this.openValidationModal(message);
    }
    this.saveInProgress = true;

    const generateAreas = this.createAreas();
    const generatedScaffolds = this.createScaffolds();
    const generateData = this.createQuotesAndItems(generatedScaffolds);

    const importData = {
      CreateAreaCommand: generateAreas,
      CreateQuoteCommands: generateData.quoteCommands,
      CreateItemCommands: generateData.itemsCommands,
      CreateScaffoldCommands: generateData.generatedScaffolds
    };
    this.importEndpointService.ImportScaffold({ ImportData: JSON.stringify(importData) as any }).subscribe(response => {
      this.siteScaffoldQueryService.siteScaffoldQuery(false, this.context.site.SiteId),
        this.siteDetailQueryService.siteDetailQuery(false, this.context.site.SiteReference);
      this.saveInProgress = false;
      this.bsModalRef.hide();
    }, this.serverErrorCallback);
  }

  createQuotesAndItems(generatedScaffolds): any {
    const rows = this.dataset.filter(d => !this.data.deletedRows.includes(this.dataset.indexOf(d)));
    if (!rows.length) return;
    const quoteNumbersNames = _uniqWith(rows.map(s => s.quoteNumber.toLowerCase().trim()), _isEqual);
    const allQuotes = this.quotes.map(qt => qt.QuoteNumber.toLowerCase());
    const quotesToExculde = allQuotes.filter(q => quoteNumbersNames.includes(q));
    let quoteCommands = [];
    let itemsCommands = [];

    rows.forEach(row => {
      const quoteId = UUID.UUID();
      if (row.quoteNumber.trim() && row.itemNumber.trim()) {
        const quotesDuplicatesChecker = quoteCommands.map(qc => qc.QuoteNumber.toLowerCase());
        if (!quotesDuplicatesChecker.includes(row.quoteNumber.toLowerCase())) {
          if (!quotesToExculde.includes(row.quoteNumber.toLowerCase())) {
            quoteCommands.push({
              Estimator: '',
              ContractId: this.data.selectedContract[0].ContractId,
              QuoteId: quoteId,
              QuoteNumber: row.quoteNumber
            });
          }
        }
        const featureFlags = environment.featureFlags as any;

        const existingQuote = this.quotes.filter(quote => quote.QuoteNumber.toLowerCase() === row.quoteNumber.toLowerCase());
        const existingItems = existingQuote.length ? existingQuote[0].Items : [];
        const scaffoldIds = generatedScaffolds.filter(
          s => s.ItemNumber.toLowerCase() === row.itemNumber.toLowerCase() && s.QuoteNumber.toLowerCase() === row.quoteNumber.toLowerCase()
        );
        if (!existingItems.map(i => i.ItemNumber.toLowerCase()).includes(row.itemNumber.toLowerCase())) {
          const extQuote = this.quotes.filter(q => q.QuoteNumber.toLowerCase() === row.quoteNumber.toLowerCase());
          const newQuotes = quoteCommands.filter(q => q.QuoteNumber.toLowerCase() === row.quoteNumber.toLowerCase());
          itemsCommands.push({
            ItemId: UUID.UUID(),
            ItemName: row.itemName,
            ItemNumber: row.itemNumber,
            Estimator: '',
            ItemType: ItemTypeEnum.None,
            QuoteNumber: row.quoteNumber,
            ContractId: this.data.selectedContract[0].ContractId,
            QuoteId: newQuotes.length ? newQuotes[0].QuoteId : extQuote.length ? extQuote[0].QuoteId : quoteId,
            AllocatedHours: 0,
            ScaffoldIds: scaffoldIds ? scaffoldIds.map(s => s.ScaffoldId) : [],
            Tasks: featureFlags.timesheet ? this.generateDefaultsService.generateDefaultTasks() : null,
          });
        }
      }
    });
    // Assign QuoteIds to Scaffolds
    generatedScaffolds.map(gs => {
      if (gs.QuoteId === '' && gs.QuoteNumber.trim() !== '') {
        const matchingQuote = quoteCommands.filter(qc => qc.QuoteNumber.toLowerCase() === gs.QuoteNumber.trim().toLowerCase());
        const existingQuote = this.quotes.filter(q => q.QuoteNumber.toLowerCase() === gs.QuoteNumber.trim().toLowerCase());
        let quoteIdToAssign = null;
        if (matchingQuote && matchingQuote.length) {
          quoteIdToAssign = matchingQuote[0].QuoteId;
        } else if (existingQuote && existingQuote.length) {
          quoteIdToAssign = existingQuote[0].QuoteId;
        }
        if (quoteIdToAssign) gs.QuoteId = quoteIdToAssign;
      }
    });

    quoteCommands = _uniqBy(quoteCommands, 'QuoteId');
    itemsCommands = _uniqBy(itemsCommands, 'ItemId');
    return { quoteCommands, itemsCommands, generatedScaffolds };
  }

  /**
   * Gets the existing default area
   *
   * @returns {SiteAreaData}
   * @memberof ImportSpreadsheetComponent
   */
  getExistingDefaultArea(): SiteAreaData {
    return this.areas.find(a => a.AreaName === this.defaultAreaName);
  }

  /**
   * Creates the default area and adds it to the areas array
   *
   * @memberof ImportSpreadsheetComponent
   */
  createDefaultArea(): void {
    if (!this.defaultArea) {
      const newDefaultArea = {
        SiteAreaId: UUID.UUID(),
        AreaName: this.defaultAreaName,
        Deleted: false
      };
      this.defaultArea = newDefaultArea;
      this.newAndExistingAreas = this.newAndExistingAreas.concat(this.newAreas);
    } else {
      // in case it exists it will assign to the default area variable to later be used to assign to scaffolds
      this.defaultArea = this.getExistingDefaultArea();
    }
  }

  /**
   * Creates all the areas from CSV file
   *
   * @returns {*}
   * @memberof ImportSpreadsheetComponent
   */
  createAreas(): any {
    this.defaultArea = this.getExistingDefaultArea();
    const allAreas = this.dataset.filter(d => !this.data.deletedRows.includes(this.dataset.indexOf(d))).map(sc => sc.area);
    const areaNames = this.areas.map(a => a.AreaName);

    const newAreasNames = [];

    allAreas.filter(a => {
      if (a && !areaNames.includes(a)) {
        newAreasNames.push(a);
      }
    });

    this.newAndExistingAreas = [];
    // adds all the existing areas to the array
    this.newAndExistingAreas = this.newAndExistingAreas.concat(this.areas);

    if (newAreasNames && newAreasNames.length) {
      this.newAreas = [];
      _uniq(newAreasNames).forEach(a =>
        this.newAreas.push({
          SiteAreaId: UUID.UUID(),
          AreaName: a,
          Deleted: false
        })
      );
      const updateAreasCommand: UpdateAreasCommand = {
        SiteId: this.context.site.SiteId,
        Areas: this.newAreas
      };
      // adds the new areas to the array only in case there are any
      this.newAndExistingAreas = this.newAndExistingAreas.concat(this.newAreas);
      return updateAreasCommand ? updateAreasCommand : [];
    }
    return { SiteId: this.context.site.SiteId, Areas: [] };
  }

  /**
   * Gets the areaId if exists else it will set the default area
   *
   * @param {*} scaffold
   * @returns {string}
   * @memberof ImportSpreadsheetComponent
   */
  getAreaId(scaffold): string {
    // in case the default area was not created and the scaffold is assigned to the default area it will create it
    if (scaffold.area.toLowerCase() === this.defaultAreaName.toLowerCase() && !this.defaultArea) this.createDefaultArea();
    // will return the areaId to the respective scaffold area name
    const areaChecker = this.newAndExistingAreas.length ? this.newAndExistingAreas.find(a => a.AreaName === scaffold.area) : null;
    return areaChecker === null ? this.defaultArea.SiteAreaId : areaChecker.SiteAreaId;
  }

  createScaffolds(): any {
    const scaffoldCommand = [];
    const scaffoldNumbers = this.context.allScaffolds
      ? this.context.allScaffolds.filter(s => s.ScaffoldId).map(s => s.ScaffoldNumber)
      : [];

    this.dataset
      .filter(d => !this.data.deletedRows.includes(this.dataset.indexOf(d)))
      .forEach(s => {
        const existingQuoteNumber = this.quotes.filter(q => q.QuoteNumber.toLowerCase() === s.quoteNumber.toLowerCase());

        const existingItemNumber =
          existingQuoteNumber.length && existingQuoteNumber[0].Items
            ? existingQuoteNumber[0].Items.filter(i => i.ItemNumber.toLowerCase() === s.itemNumber.toLowerCase())
            : '';

        const existingItemName =
          existingQuoteNumber.length && existingQuoteNumber[0].Items
            ? existingQuoteNumber[0].Items.filter(i => i.ItemName.toLowerCase() === s.itemName.toLowerCase())
            : '';

        const existingItemId =
          existingQuoteNumber.length && existingQuoteNumber[0].Items
            ? existingQuoteNumber[0].Items.filter(i => i.ItemNumber.toLowerCase() === s.itemNumber.toLowerCase())
            : '';

        const newQuoteNumber = existingQuoteNumber.length ? '' : s.quoteNumber;
        const newItemNumber = existingItemNumber.length ? '' : s.itemNumber;
        const newItemName = existingItemName ? '' : s.itemName;

        const scaffoldNumber = this.referenceGeneratorService.incrementalReference(
          scaffoldNumbers,
          this.context.configuration.ScaffoldReferenceLength
        );



        const contract = this.data.selectedContract[0];
        const nextInspDate = s.dateNextInspection ? moment(s.dateNextInspection, ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD']).toISOString() : '';
        const scaffoldingSystem = this.scaffoldingSystems.find(ss => ss.Title === s.scaffoldingSystem);

        scaffoldCommand.push({
          SiteId: this.context.site.SiteId,
          ScaffoldId: UUID.UUID(),
          ScaffoldStatusId: s.onHireDate ? scaffoldStatusIds.onHire : scaffoldStatusIds.incomplete,
          SpecificLocationDetails: s.scaffoldName,
          AdverseWeatherIndicator: s.affectedAdverseWeather,
          SiteAreaId: this.getAreaId(s),
          ScaffoldNumber: scaffoldNumber,
          ContractId: contract.ContractId,
          DateOfNextInspection: nextInspDate,
          ScaffoldSpecification: {
            ScaffoldTypeId: s.scaffoldType ? this.scaffoldTypes.filter(st => st.Title === s.scaffoldType)[0].ScaffoldTypeId : '',
            ScaffoldTypeFurtherInformation: '',
            Description: s.scaffoldDescription,
            DesignTypeId: s.designType ? this.designTypes.filter(dt => dt.Title === s.designType)[0].DesignTypeId : '',
            DesignTypeFurtherInformation: '',
            LoadingLimitId: s.loadingLimit ? this.loadingLimits.filter(ll => ll.Title === s.loadingLimit)[0].LoadingLimitId : '',
            LoadingLimitFurtherInformation: '',
            MethodOfTyingId: s.methodTying ? this.methodTyings.filter(mt => mt.Title === s.methodTying)[0].MethodOfTyingId : '',
            MethodOfTyingFurtherInformation: '',
            MethodOfCladdingId: s.methodCladding ? this.methodCladdings.filter(mc => mc.Title === s.methodCladding)[0].MethodOfCladdingId : '',
            MethodOfCladdingFurtherInformation: '',
            HirePeriod: s.hirePeriod ? Number(s.hirePeriod) : null,
            MaximumTonnage: s.maximumTonnage,
            scaffoldingSystemId: scaffoldingSystem ? scaffoldingSystem.ScaffoldingSystemId : ''
          },
          OffsetFromUtc: moment().utcOffset(),
          OnHireDate: s.onHireDate ? moment(s.onHireDate, ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD']).toISOString() : '',
          QuoteNumber: existingQuoteNumber.length ? existingQuoteNumber[0].QuoteNumber : newQuoteNumber,
          QuoteId: existingQuoteNumber.length ? existingQuoteNumber[0].QuoteId : '',
          ItemNumber: existingItemNumber.length ? existingItemNumber[0].ItemNumber : newItemNumber,
          ItemName: existingItemName.length ? existingItemName[0].ItemName : newItemName,
          ItemId: existingItemId.length ? existingItemId[0].ItemId : ''
        });
        scaffoldNumbers.push(scaffoldNumber);
      });
    return scaffoldCommand;
  }

  clearAllRows(rowsToCreate, bypassModalConfirm: boolean = false): void {
    if (bypassModalConfirm) {
      this.dataset = [];
      this.createEmptyRows(rowsToCreate);
      return;
    }
    const context: SSDialogContext = {
      title: `Delete All Rows?`,
      body: `Are you sure you want to delete all the rows?`,
      okBtnClass: 'btn button-assertive',
      okBtnText: 'Yes delete all rows',
      cancelBtnText: 'No keep it',
    };
    const config: ModalOptions = {
      class: 'modal-danger',
    };
    const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config));

    modal.content.onClose.subscribe(
      result => {
        if (result === true) {
          this.dataset.forEach(row => {
            const rowIndex = this.dataset.indexOf(row);
            this.removeAllRowHighlights(rowIndex, true, true);
          });
          this.data.deletedRows = [];
          this.errorValidation = [];
          this.dataset = [];
          this.createEmptyRows(rowsToCreate);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  createEmptyRows(rowsToCreate): void {
    const len = rowsToCreate;
    for (let i = 0; i < len; i++) {
      this.dataset.push(this.newItem(i));
    }
    this.gridObj.setData(this.dataset);
    this.gridObj.updateRowCount();
    this.gridObj.render();
    this.verifyGrid();
  }

  newItem(i): any {
    this.highlightCell(this.dataset.length, 'scaffoldName');
    this.highlightCell(this.dataset.length, 'area', 'slickgrid-autoFill');
    this.highlightCell(this.dataset.length, 'affectedAdverseWeather', 'slickgrid-autoFill');

    return {
      id: this.dataset.length + 1,
      scaffoldName: '',
      scaffoldDescription: '',
      area: this.defaultAreaName,
      scaffoldType: '',
      loadingLimit: '',
      methodCladding: '',
      methodTying: '',
      quoteNumber: '',
      itemNumber: '',
      itemName: '',
      hirePeriod: null,
      designType: '',
      dateNextInspection: '',
      onHireDate: '',
      affectedAdverseWeather: true
    };
  }

  onPrevious(): void {
    this.data.savedScaffolds = this.dataset;
    this.data.validationErrors = this.errorValidation;
    this.previousStep();
  }

  onCellChanged(e, args): void {
    const colId = Object.keys(args.item)[args.cell];
    const value = args.item[colId];

    const maxCharacters = 100;
    let message = '';
    const requiredFieldMessage = 'This is a required field';
    const maxCharactersMessage = `The max length for this field is ${maxCharacters} characters.`;

    // Required
    if (
      (colId === 'scaffoldName' && value == null) ||
      (colId === 'scaffoldName' && value === undefined) ||
      (colId === 'scaffoldName' && !value.length)
    ) {
      message = requiredFieldMessage;
    }

    // Max Length
    const valueLength = value === null ? null :
      value === '' || value === undefined ? 0 : value.length;
    if (
      (colId === 'scaffoldName' && valueLength > maxCharacters) ||
      (colId === 'scaffoldDescription' && valueLength > maxCharacters) ||
      (colId === 'area' && valueLength > maxCharacters) ||
      (colId === 'quoteNumber' && valueLength > maxCharacters) ||
      (colId === 'itemNumber' && valueLength > maxCharacters) ||
      (colId === 'itemName' && valueLength > maxCharacters)
    ) {
      message = maxCharactersMessage;
    }
    if (message) {
      this.highlightCell(args.row, colId);
      return;
    }
    this.verifyGrid();
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { UserIdentityService } from 'app/core/authentication';
import { intersection } from 'lodash';
/** 
 * This component checks if the current user has any of the permissions.
 * If he does, it will render the content passed in. If he doesn't, it will not render the content.
  * Usage: 
  * ### **On HTML:** 
  * - `<hub-permission permissions="AddEditCustomers">...{add here your code}...</hub-permission>`
  * - this will not show the content if the user's permission list doesn't contain 'AddEditCustomers'
 */
@Component({
  selector: 'hub-permission',
  template: `<ng-content *ngIf="canView"></ng-content>`,
})
export class PermissionComponent implements OnInit {
  /** Stores if the user can or cannot view the element */
  public canView = false;
  /** This stores the passed in permission from html `<hub-permission permissions="AddEditCustomers">...`*/
  @Input() permissions: string;
  constructor(
    public userIdentityService: UserIdentityService,
  ) { }

  ngOnInit() {
    try {
      const permissionsArray = this.permissions.split(/\s+/);
      const user = this.userIdentityService.currentUser();
      const userHasPermission = user && user.claims && intersection(permissionsArray, user.claims).length > 0;
      this.canView = userHasPermission;
    } catch (error) {
      console.log('Error in PermissionComponent: ', error)
    }
  }
}

export const roleIds = {
  admin: 'd95374ca-9443-4c99-98b2-45856fed67f7',
  depotAdmin: '38213224-0ebd-49b8-9efa-f0cdacc33af5',
  estimator: '08190507-3ec2-4c66-8895-866842919863',
  regionalManager: '01dffd7f-502b-4e61-b9c5-0f8e9be7a511',
  safetyManager: 'e07d6548-3c32-42c0-8683-ab7e479d7a65',
  siteSupervisor: 'c3b67b04-fa5a-46c8-ae52-941d5d80da14',
  inspector: '31d7c5a7-0f99-4253-9e59-43b5e6b65166',
  contractsManager: '277bd0e9-0f3f-4814-819a-0a2f1630827b',
  scaffolder: '52ce46df-72ec-445b-a2c4-5942f6ed0047',
  payrollOfficer: 'f3dc4c31-9738-4425-b036-53da20ae81bb'
};

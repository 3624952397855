<div class="modal-footer">
  <span *ngIf="requiredNote" class="modal-form-footer-required-note required-note">* Denotes a required
    field</span>
  <div class="modal-buttons">
    <div class="modal-form-footer-error-message alert alert-danger server-error" role="alert" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <button class="modal-form-footer-cancel-button btn btn-large btn-inverse" type="button" (click)="close.emit()"
      [disabled]="inProgress">
      <i class="fa fa-close"></i> {{ cancelText }}
    </button>
    <button class="modal-form-footer-save-button btn btn-large" [ngClass]="saveBtnClass" type="button"
      (click)="submit.emit()" [disabled]="inProgress">
      <i [ngClass]="{'fa-spin': inProgress,'fa-spinner': inProgress, 'fa-check':!inProgress}" class="fa"></i> {{
      saveText }}
    </button>
  </div>
</div>
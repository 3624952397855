import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { cloneDeep, map } from 'lodash';
import { contactTypeIds } from '../../../../../shared/values/contact-type-ids';
import {
  ReportSubscriptionTypesEnum, ConfigurationData, CompanyDetails,
} from 'app/core/hub-api';
import {
  ContactTypeData,
  ContractCommandService,
  ContractContactData,
  ContractData,
  CustomerData,
  SiteWebAppData,
  CompanyContacts,
  CreateEditCompanyContactCommand
} from 'app/core/hub-api';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import * as moment from 'moment';
import { AddEditCompanyContactModalContext } from '../../../../models/add-edit-company-contact-modal-context';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { roleIds } from 'app/shared/values/role-ids';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-add-edit-company-contact-modal',
  templateUrl: './add-edit-company-contact-modal.component.html',
  styleUrls: ['./add-edit-company-contact-modal.component.scss']
})
export class AddEditCompanyContactModalComponent extends FormComponent implements OnInit {
  context: Partial<AddEditCompanyContactModalContext>;
  editMode: boolean;
  chooseContractMode: boolean;
  site: SiteWebAppData;
  contract: ContractData;
  contractContact: ContractContactData;
  customers: CustomerData[];
  contactTypes: ContactTypeData[];
  modalTitle: string;
  openContracts: ContractData[];
  staffs: any[];
  email: string;
  name: string;
  companyContacts: CompanyContacts[] = [];
  form: UntypedFormGroup;
  configuration: ConfigurationData;
  contractIdFormControl: UntypedFormControl;
  contactTypeIdFormControl: UntypedFormControl;
  emailAddressFormControl: UntypedFormControl;
  handoverCertificateFormControl: UntypedFormControl;
  variationInstructionsFormControl: UntypedFormControl;
  inspectionsCompletedFormControl: UntypedFormControl;
  detailedInspectionFormControl: UntypedFormControl;
  inspectionStatusesFormControl: UntypedFormControl;
  subContractorInspectionsFormControl: UntypedFormControl;
  contactNameFormControl: UntypedFormControl;
  contactEmailFormControl: UntypedFormControl;
  activeCompanyContactsEmails: string[];
  companyDetails: CompanyDetails;
  validationMessages = {
    contractId: {
      required: errorMessages.required
    },
    contactName: {
      required: errorMessages.required,
    },
    contactTypeId: {
      required: errorMessages.required
    },
    emailAddress: {
      required: errorMessages.required,
      pattern: errorMessages.emailInvalid,
      duplicateEmail: errorMessages.duplicateEmail,
      email: errorMessages.emailInvalid
    }
  };
  addingNewContact: boolean;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private contractCommandService: ContractCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    private staffsQueryService: StaffsQueryService,
    private configurationQueryService: ConfigurationQueryService,
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.configurationQueryService.configurationDataChanges().subscribe((configuration) => {
      this.companyDetails = configuration.CompanyDetails;
    });

    this.editMode = this.context.editMode;
    this.chooseContractMode = this.context.chooseContractMode;
    this.site = this.context.site;
    this.contract = this.context.contract;
    const contractNameRef = `${this.contract.ContractName}(${this.contract.ContractReference})`;
    this.modalTitle = this.editMode ? `Edit ${this.companyDetails.CompanyName} Contact - ${contractNameRef}` : `Add ${this.companyDetails.CompanyName} Contact - ${contractNameRef}`;
    this.contractContact = cloneDeep(this.context.contractContact) || this.getNewContractContact();
    this.customers = this.context.customers;
    this.contactTypes = map(this.context.configuration.ContactTypes);
    this.activeCompanyContactsEmails = map(this.contract.ContractContacts)
      .filter(c => !c.IsInactive && c.ContactTypeId === contactTypeIds.companyContact).map((contact) => contact.EmailAddress);
    this.openContracts = this.site.Contracts
      .filter(c => c.ContractStatusId === contractStatusIds.open)
      .map(contract => {
        contract = cloneDeep(contract);
        const customerName = this.customers.find(c => c.CustomerId === contract.CustomerId).CustomerName;
        (contract as any).optionName = `${customerName} (${contract.ContractReference})`;
        return contract;
      });

    this.staffsQueryService.staffsDataChanges(false).subscribe((staffs) => {

      this.staffs = staffs.filter(staff => !this.activeCompanyContactsEmails.includes(staff.EmailAddress)
        && !staff.RoleId.includes(roleIds.scaffolder));
      this.staffs = this.staffs.map(function (staff): any {
        return {
          ContactName: staff.ContactName,
          EmailAddress: staff.EmailAddress,
          UserCode: staff.UserCode
        };
      });
      this.staffs = this.sortStaff(this.staffs);
    });

    this.contractIdFormControl = new UntypedFormControl(this.contract ? this.contract.ContractId : '', Validators.required);
    this.contactTypeIdFormControl = new UntypedFormControl(contactTypeIds.companyContact, Validators.required);
    this.emailAddressFormControl = new UntypedFormControl(this.contractContact.EmailAddress, [Validators.required]);
    this.contactNameFormControl = new UntypedFormControl(this.contractContact.ContactName);
    this.contactEmailFormControl = new UntypedFormControl('');

    if (this.editMode) {
      this.contactNameFormControl.setValidators(Validators.required);
      this.emailAddressFormControl.setValidators([Validators.required, Validators.pattern(emailAddressRegex)]);
    }

    this.handoverCertificateFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.HandoverCertificate));
    this.variationInstructionsFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.VariationInstructions));
    this.inspectionsCompletedFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionsCompleted));
    this.detailedInspectionFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SiteInspectionsCompleted));
    this.inspectionStatusesFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionStatuses));
    this.subContractorInspectionsFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SubContractorInspections));
    this.form = new UntypedFormGroup({
      contractId: this.contractIdFormControl,
      contactTypeId: this.contactTypeIdFormControl,
      emailAddress: this.emailAddressFormControl,
      handoverCertificate: this.handoverCertificateFormControl,
      variationInstructions: this.variationInstructionsFormControl,
      inspectionsCompleted: this.inspectionsCompletedFormControl,
      detailedInspection: this.detailedInspectionFormControl,
      inspectionStatuses: this.inspectionStatusesFormControl,
      subContractorInspections: this.subContractorInspectionsFormControl,
      contactName: this.contactNameFormControl,
      contactEmail: this.contactEmailFormControl
    });
    super.ngOnInit();
  }

  getNewContractContact(): ContractContactData {
    return Object.assign(new ContractContactData(), {
      ContractContactId: UUID.UUID(),
      EmailAddress: '',
      IsInactive: false,
      ReportSubscriptionTypes: ReportSubscriptionTypesEnum.None,
      ContactTypeId: contactTypeIds.companyContact,
    });
  }

  deleteContractContact(): void {
    this.contractContact.IsInactive = true;
  }

  undoDeleteContractContact(): void {
    this.contractContact.IsInactive = false;
  }

  /**
   * Removes the validation in case the user is no longer adding a new contact
   * The validation is only required when the user is adding a new contact and the email address is not found in the list of active contacts,
   * once the user adds the contact the validation is no longer required as the email address is already in the list of active contacts
   * @param formValues gets the form values from the submit
   */
  private removeAddNewValidationErrors(formValues): void {
    this.addingNewContact = (!formValues.contactEmail && formValues.emailAddress.length) ? false : true;
    if (!this.addingNewContact) {
      this.contactName.setValidators(null);
      this.contactName.updateValueAndValidity();
      this.contactEmail.setValidators(null);
      this.contactEmail.updateValueAndValidity();
    }
  }

  onSubmit(formValues): void {
    this.removeAddNewValidationErrors(formValues);

    if (this.validateForm()) {
      this.saveInProgress = true;

      if (this.editMode) {
        const companyContact: CompanyContacts = {
          ContractContactId: this.contractContact.ContractContactId,
          EmailAddress: formValues.emailAddress,
          ContactName: formValues.contactName,
          IsInactive: this.contractContact.IsInactive
        };
        this.companyContacts.push(companyContact);
      }

      const command: CreateEditCompanyContactCommand = {
        CompanyContacts: this.companyContacts,
        ContractId: formValues.contractId,
        ReceiveInspectionReports: true,
        ScheduledReportStartDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
        ReportSubscriptionTypes: this.getReportSubscriptionType(formValues),
        ContactTypeId: formValues.contactTypeId
      };

      const commandFunction = this.contractCommandService.CreateEditCompanyContactCommand;

      commandFunction.call(this.contractCommandService, command)
        .subscribe(() => {
          // Here we must reload any data that this add/edit affects from the server.
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference)
            .subscribe(() => {
              this.saveInProgress = false;
              this.bsModalService.setDismissReason(command.ContractId);
              this.bsModalRef.hide();

            });
        }, this.serverErrorCallback);
    }
  }


  public get contactName(): AbstractControl {
    return this.form.get('contactName');
  }


  public get contactEmail(): AbstractControl {
    return this.form.get('contactEmail');
  }

  /**
   * Add contact details to the contact list array
   */
  AddEmail(): void {
    const name = this.contactName.value;
    const email = this.contactEmail.value;
    const pattern = new RegExp('^(?!\s*$).+');
    if (name === null || !pattern.test(name) || name === '') {
      this.contactName.setErrors({ required: true });
      return;
    }
    if (email === null || email === '') {
      this.contactEmail.setErrors({ required: true });
      return;
    }

    if (!emailAddressRegex.test(email)) {
      this.contactEmail.setErrors({ pattern: true });
      return;
    }

    const isEmailExists = this.staffs.find(staff => staff.EmailAddress === email);
    if (isEmailExists) {
      this.contactEmail.setErrors({ duplicate: true });
      return;
    }

    const newContact = {
      ContactName: name.trim(),
      EmailAddress: email.trim()
    };

    this.staffs.push(newContact);
    this.staffs = this.sortStaff([...this.staffs]);
    this.contactEmailFormControl.reset();
    this.contactNameFormControl.reset();
  }

  /**
   * this will be called when selecting an item from the auto complete to add
   * to the list of contact send to api
   * @param item
   */
  addCompanyContact(item): any {
    const companyContact: CompanyContacts = {
      ContractContactId: UUID.UUID(),
      EmailAddress: item.EmailAddress,
      ContactName: item.ContactName,
      IsInactive: false
    };

    this.companyContacts.push(companyContact);
    this.staffs = this.staffs.filter(staff => staff.EmailAddress !== item.EmailAddress);
    this.staffs = [...this.staffs];
  }

  /**
   *remove the staff from the already added list when removed from autocomplete
   * @param item
   */
  removeCompanyContact(item): any {
    this.companyContacts = this.companyContacts.filter(contact => contact.EmailAddress !== item.value.EmailAddress);
    this.staffs.push(item.value);
    this.staffs = this.sortStaff([...this.staffs]);
  }

  /**
   *prepare staff list on clear inclusding the staff and company contact added
   */
  rePrepareStaffList(): any {
    this.staffs = this.sortStaff([...this.staffs, ...this.companyContacts]);
    this.companyContacts = [];
  }

  sortStaff(staffs): any {
    return staffs.sort((a, b) =>
      (a.ContactName.toLowerCase() > b.ContactName.toLowerCase() ? 1 : ((b.ContactName.toLowerCase() > a.ContactName.toLowerCase()) ? -1 : 0)));
  }

  /**
   * Report subscritption values in X0R format. this is will handled at the backend
   * @param formValues
   */
  private getReportSubscriptionType(formValues: any): any {
    return (ReportSubscriptionTypesEnum.HandoverCertificate * +formValues.handoverCertificate) +
      (ReportSubscriptionTypesEnum.VariationInstructions * +formValues.variationInstructions) +
      (ReportSubscriptionTypesEnum.InspectionsCompleted * +formValues.inspectionsCompleted) +
      (ReportSubscriptionTypesEnum.SiteInspectionsCompleted * +formValues.detailedInspection) +
      (ReportSubscriptionTypesEnum.InspectionStatuses * +formValues.inspectionStatuses) +
      (ReportSubscriptionTypesEnum.SubContractorInspections * +formValues.subContractorInspections);
  }

  principalContractSelected(): boolean {
    const contract = this.site.Contracts.find(c => c.ContractId === this.contractIdFormControl.value);
    return contract ? contract.IsPrincipalContract : false;
  }


}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import * as moment from 'moment';
import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ConfigurationData, ContractData, CustomerWebAppData, ScaffoldWebAppData, SiteWebAppData, StaffDetailsWebAppData } from 'app/core/hub-api';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, some, uniq as _uniq } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditContractModalComponent } from '../../contracts/add-edit-contract-modal/add-edit-contract-modal.component';
import { AddEditScaffoldModalComponent } from '../add-edit-scaffold-modal/add-edit-scaffold-modal.component';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FixScaffoldNumbersModalComponent } from '../fix-scaffold-numbers-modal/fix-scaffold-numbers-modal.component';
import { GroupScaffoldsModalComponent } from '../group-scaffolds-modal/group-scaffolds-modal.component';
import { combineLatest } from 'rxjs';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { scaffoldNumberComparator } from '../../../../../shared/comparators/scaffold-number-comparator';
import { scaffoldStatusIds } from '../../../../../shared/values/scaffold-status-ids';
import { ExportService } from '../../../../../core/services/export-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ScaffoldImportModalComponent } from '../scaffold-import-modal/scaffold-import-modal.component';
import { mergeMap } from 'rxjs/operators';
import { LoggedInStaffService } from '../../../../../core/services/logged-in-staff-service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
/**
 * Scaffold List page
 * Shows a list of all scaffolds for the selected site
 * Allows users to export as CSV, group, show dismantle and fix duplicated scaffolds.
 *
 * @export
 * @class ScaffoldListComponent
 * @implements {OnInit} A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive
 * @implements {OnDestroy} A lifecycle hook that is called when a directive, pipe, or service is destroyed
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-scaffold-list',
  templateUrl: './scaffold-list.component.html',
  styleUrls: ['./scaffold-list.component.scss']
})
export class ScaffoldListComponent implements OnInit, OnDestroy {
  /** Stores the blob for the excel export */
  blobContainer: any;
  /** Stores all scaffolds */
  allScaffolds: ScaffoldWebAppData[];
  /** Stores all filtered scaffolds */
  filteredScaffolds: ScaffoldWebAppData[];
  /** Stores all contracts */
  contracts: ContractData[];
  /** Stores all contract names */
  contractNames: any = [];
  /** Stores all unfiltered contract names */
  allContractNames: any;
  /** Stores all customers */
  customers: CustomerWebAppData[];
  /** Stores the current selected site */
  site: SiteWebAppData;
  /** Stores SMART Manager configuration */
  configuration: ConfigurationData;
  /** Stores all customers for the selected site */
  siteCustomers: any;
  /** Stores all duplicate scaffolds */
  anyDuplicates: boolean;
  /** Stores the current logged in user */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores all filter parameters */
  filters: any;
  /** Stores if the inspection view is enabled, if false will be handover view */
  inspectionView: boolean;
  /** Stores all scaffold status ids */
  scaffoldStatusIds: any;
  /** Enables the scaffold grouping mode */
  groupScaffoldsMode: boolean;
  /** Filters Form Group control */
  filtersForm: UntypedFormGroup;
  /** Search input form control */
  searchTermFormControl: UntypedFormControl;
  /** Contracts dropdown form control */
  contractListFormControl: UntypedFormControl;
  /** Gets the scaffold number comparator function */
  scaffoldNumberComparator = scaffoldNumberComparator;
  /** Stores all selected scaffolds */
  selectedScaffolds: any;
  /** Required to access ngx-datatable settings */
  @ViewChild(DatatableComponent) scaffoldsTable: DatatableComponent;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private router: Router,
    private bsModalService: BsModalService,
    private exportService: ExportService,
    private changeDetectorRef: ChangeDetectorRef,
    private loggedInStaffService: LoggedInStaffService
  ) { }

  /**
   * Component Initialisation
   *
   * @memberof ScaffoldListComponent
   */
  public ngOnInit(): void {
    this.generateForm();
    this.searchTermFormControl.valueChanges.subscribe(searchTerm => {
      // Checks if the user is filtering by contract and searches on selected scaffolds
      const scaffolds = this.selectedScaffolds ? this.selectedScaffolds : this.allScaffolds;
      this.setFilteredScaffolds(scaffolds);
    });


    this.filters = {
      selectedContract: undefined,
      selectedCustomer: undefined,
      showOnlyDuplicates: false,
      showDismantled: false
    };
    this.inspectionView = true;
    this.scaffoldStatusIds = scaffoldStatusIds;

    const siteReference = this.route.parent.parent.snapshot.params['siteReference'];

    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(siteReference);
    const scaffoldsChanges = siteDetailChanges.pipe(mergeMap(site => this.siteScaffoldQueryService.siteScaffoldQuery(false, site.SiteId)));
    const obs$ = combineLatest([
      siteDetailChanges,
      this.customersQueryService.customersDataChanges(),
      scaffoldsChanges,
      this.configurationQueryService.configurationDataChanges(),
      this.loggedInStaffService.loggedInStaffChanges()
    ]);
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2], latest[3], latest[4]));
  }

  /**
   * Generates the scaffold filters form
   *
   * @private
   * @memberof ScaffoldListComponent
   */
  private generateForm(): void {
    this.searchTermFormControl = new UntypedFormControl('');
    this.contractListFormControl = new UntypedFormControl('');
    this.filtersForm = new UntypedFormGroup({
      searchTerm: this.searchTermFormControl,
      contractList: this.contractListFormControl
    });
  }

  /**
   * Initialises all data to feed the scaffold list page
   * It is not a private function since it is used on unit tests
   *
   * @param {SiteWebAppData} site selected site
   * @param {CustomerWebAppData[]} customers all customers
   * @param {ScaffoldWebAppData[]} scaffolds all scaffolds
   * @param {ConfigurationData} configuration SMART Manager configuration object
   * @param {StaffDetailsWebAppData} loggedInStaff current logged in user
   * @memberof ScaffoldListComponent
   */
  public refreshComponent(site: SiteWebAppData, customers: CustomerWebAppData[], scaffolds: ScaffoldWebAppData[], configuration: ConfigurationData, loggedInStaff: StaffDetailsWebAppData): void {
    this.allScaffolds = scaffolds;
    this.configuration = configuration;
    this.site = site;
    this.customers = customers;
    this.loggedInStaff = loggedInStaff;
    this.contracts = site.Contracts.map(contract => {
      // Create a clone to avoid altering cached data.
      contract = cloneDeep(contract);
      (contract as any).name = this.customers.find(c => c.CustomerId === contract.CustomerId).CustomerName;
      return contract as any;
    })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .filter(contract => contract.ContractStatusId !== contractStatusIds.closed);
    this.allScaffolds = scaffolds;
    this.setFilteredScaffolds(this.allScaffolds);
    this.anyDuplicates = this.allScaffolds.some(s => s.IsDuplicate);

    // Adds the contract name filters
    this.contractNames = [];
    this.contracts.map(c => {
      this.contractNames.push({ name: c.ContractName, type: c.ContractId });
    });
    this.allContractNames = this.contractNames;

    // Checks all contract names as default
    this.filtersForm.controls.contractList.setValue(this.contractNames.map(c => c.type));

    // Sets all the customers the site has
    const customerIds = this.site.Contracts.map(c => c.CustomerId);
    this.siteCustomers = this.customers.filter(c => customerIds.includes(c.CustomerId));
  }

  setFilteredScaffolds(scaffolds: ScaffoldWebAppData[]): void {
    let filteredScaffolds = scaffolds;

    // Filter out scaffolds from closed contracts.
    filteredScaffolds = filteredScaffolds.filter(scaffold => {
      const contract = this.contracts.find(c => c.ContractId === scaffold.ContractId) || new ContractData();
      return contract.ContractStatusId === contractStatusIds.open;
    });

    // Filter by search term.
    if (this.searchTermFormControl.value) {
      filteredScaffolds = filteredScaffolds.filter(scaffold => {
        const keys = Object.keys(scaffold);
        return keys.some(key =>
          (scaffold[key] || '')
            .toString()
            .toLowerCase()
            .includes(this.searchTermFormControl.value.toLowerCase())
        );
      });
    }

    // Filter by dismantled.
    if (!this.filters.showDismantled) {
      filteredScaffolds = filteredScaffolds.filter(scaffold => scaffold.ScaffoldStatusId !== scaffoldStatusIds.dismantled);
    }

    // Filter by isDuplicate.
    if (this.filters.showOnlyDuplicates) {
      filteredScaffolds = filteredScaffolds.filter(scaffold => scaffold.IsDuplicate);
    }

    // Adds Contract Name to filteredScaffolds and assigns it to global variable
    this.filteredScaffolds = filteredScaffolds.map(scaffold => {
      scaffold = cloneDeep(scaffold);
      const contractName = this.site.Contracts.find(c => c.ContractId === scaffold.ContractId).ContractName;
      (scaffold as any).ContractName = `${contractName}`;
      return scaffold;
    });

    // Set the scaffolds table back to the first page.
    if (this.scaffoldsTable) {
      this.scaffoldsTable.offset = 0;
    }
  }

  /**
   * When user selects the import scaffolds button launches the importer modal
   *
   * @returns {DialogRef<any>}
   * @memberof ScaffoldListComponent
   */
  public onImportScaffolds(): void {
    const context = {
      allScaffolds: this.allScaffolds,
      customers: this.customers,
      site: this.site,
      configuration: this.configuration,
      selectedContract: this.filters.selectedContract
    };
    const config: ModalOptions = {
      class: 'modal-xl'
    };
    this.bsModalService.show(ScaffoldImportModalComponent, SSModalConfig.generate(context, config));
  }

  /**
   * When user clicks the add contract button launches the add contract modal
   *
   * @returns {DialogRef<any>}
   * @memberof ScaffoldListComponent
   */
  public onAddContract(): void {
    const context = {
      editMode: false,
      customers: this.customers,
      site: this.site,
      configuration: this.configuration
    };
    this.bsModalService.show(AddEditContractModalComponent, SSModalConfig.generate(context));
  }

  /**
   * When user clicks the add scaffold button launches the add scaffold modal
   *
   * @memberof ScaffoldListComponent
   */
  public onAddScaffold(): void {
    const context = {
      editMode: false,
      configuration: this.configuration,
      site: this.site,
      customers: this.customers,
      scaffolds: this.allScaffolds,
      loggedInStaff: this.loggedInStaff,
    };
    this.bsModalService.show(AddEditScaffoldModalComponent, SSModalConfig.generate(context));
  }

  /**
   * When the user selects a customer on the UI it filters the scaffolds accordingly
   *
   * @param {*} customer selected customer
   * @memberof ScaffoldListComponent
   */
  public onSelectCustomer(customer): void {
    const scaffolds = !customer ? this.allScaffolds : this.allScaffolds.filter(s => s.CustomerName === customer.CustomerName);
    const contractIds = _uniq(scaffolds.map(s => s.ContractId));

    this.filters.selectedContract = contractIds;
    this.filtersForm.controls.contractList.setValue(contractIds);

    this.filters.selectedCustomer = customer;
    this.contractFiltering();
    this.setFilteredScaffolds(scaffolds);
  }

  /**
   * Filters the scaffold list with the selected contracts
   *
   * @private
   * @memberof ScaffoldListComponent
   */
  private contractFiltering(): void {
    if (this.filters.selectedCustomer) {
      const filteredScaffolds = this.allScaffolds.filter(s => s.CustomerName === this.filters.selectedCustomer.CustomerName);
      if (filteredScaffolds) {
        const handoverContractIds = filteredScaffolds.map(s => s.ContractId);
        this.contractNames = this.allContractNames.filter(cn => handoverContractIds.includes(cn.type));
      }
    } else {
      this.contractNames = this.allContractNames;
    }
  }

  /**
   * Activates the `scaffoldOverdueIndicator` class for the next inspections due
   *
   * @param {*} date
   * @returns {boolean}
   * @memberof ScaffoldListComponent
   */
  public dateIsInPast(date): boolean {
    return moment(date).isBefore(moment().startOf('day'));
  }

  /**
   * Shows or hides the scaffolds that are dismantled
   *
   * @memberof ScaffoldListComponent
   */
  public toggleShowDismantled(): void {
    this.filters.showDismantled = !this.filters.showDismantled;
    this.setFilteredScaffolds(this.getFilteredScaffolds());
  }

  /**
   * Detects if a customer is selected and displays the scaffolds for that specific customer
   * If no customer is selected then it will show all scaffolds
   *
   * @private
   * @returns {ScaffoldWebAppData[]}
   * @memberof ScaffoldListComponent
   */
  private getFilteredScaffolds(): ScaffoldWebAppData[] {
    if (!this.filters.selectedCustomer) {
      return this.allScaffolds;
    }
    return this.allScaffolds.filter(s => s.CustomerName === this.filters.selectedCustomer.CustomerName);
  }

  /**
   * Shows or hides the duplicate scaffold numbers on the scaffold list
   * Gets called from the UI
   *
   * @memberof ScaffoldListComponent
   */
  public toggleShowDuplicates(): void {
    this.filters.showOnlyDuplicates = !this.filters.showOnlyDuplicates;
    this.setFilteredScaffolds(this.allScaffolds);

    if (!this.filters.showOnlyDuplicates) {
      this.searchTermFormControl.enable();
      this.allScaffolds.map(s => ((s as any).fixDuplicates = false));
    } else {
      this.searchTermFormControl.disable();
    }
  }

  /**
   * Activates or deactivates the scaffold group mode
   * Gets called from the UI
   *
   * @memberof ScaffoldListComponent
   */
  public toggleGroupScaffoldsMode(): void {
    this.groupScaffoldsMode = !this.groupScaffoldsMode;

    if (!this.groupScaffoldsMode) {
      this.allScaffolds.map(s => ((s as any).group = false));
    }
  }

  /**
   * Part of the duplicates feature shows the list of scaffolds to fix
   *
   * @returns {boolean}
   * @memberof ScaffoldListComponent
   */
  public anyScaffoldsSelectedForFix(): boolean {
    return this.filteredScaffolds.some(s => (s as any).fixDuplicates);
  }

  /**
   * Part of the group scaffolds mode, detects if there is more than one scaffold selected
   *
   * @returns {boolean}
   * @memberof ScaffoldListComponent
   */
  public multipleScaffoldsSelectedForGroup(): boolean {
    return this.filteredScaffolds.filter(s => (s as any).group).length > 1;
  }

  /**
   * Disables the fix duplicate scaffolds option on the UI
   *
   * @memberof ScaffoldListComponent
   */
  public disableFixDuplicates(): void {
    const unselectedDuplicates = (this.filteredScaffolds as any[]).filter(s => s.IsDuplicate && !s.fixDuplicates);

    unselectedDuplicates.map(scaffold => {
      scaffold.fixDuplicateDisabled = !some(unselectedDuplicates, s => {
        return s.ScaffoldNumber === scaffold.ScaffoldNumber && s.ScaffoldId !== scaffold.ScaffoldId;
      });
    });
  }

  /**
   * Opens the fix duplicates modal, gets called from UI
   *
   * @memberof ScaffoldListComponent
   */
  public onFixDuplicates(): void {
    const duplicateScaffolds = this.filteredScaffolds.filter(s => (s as any).fixDuplicates);
    const context = {
      site: this.site,
      allScaffolds: this.allScaffolds,
      duplicateScaffolds: duplicateScaffolds,
      configuration: this.configuration
    };
    this.bsModalService.show(FixScaffoldNumbersModalComponent, SSModalConfig.generate(context));
  }

  /**
   * Opens the Group scaffolds modal, gets called from UI
   *
   * @memberof ScaffoldListComponent
   */
  public onGroupScaffolds(): void {
    const groupScaffolds = this.filteredScaffolds.filter(s => (s as any).group);
    const context = {
      site: this.site,
      scaffolds: groupScaffolds,
      configuration: this.configuration
    };
    
    const modal = this.bsModalService.show(GroupScaffoldsModalComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe(() => {
      // Once the modal is hidden, we we call 'toggleGroupScaffoldsMode()' method, to alter the UI related to 
      // group scaffolds in the application.
      this.toggleGroupScaffoldsMode();
    })
  }

  /**
   * Detects when a scaffold is selected from the scaffold list and navigates to that scaffold details page
   *
   * @param {*} event contains the selected scaffold data
   * @memberof ScaffoldListComponent
   */
  public onSelect(event): void {
    this.router.navigate([this.router.routerState.snapshot.url, event.selected[0].ScaffoldId]);
  }

  /**
   * Allows users to click on the checkboxes on the ngx-datatable column
   *
   * @param {*} event event of click
   * @memberof ScaffoldListComponent
   */
  public allowClick(event): void {
    event.stopPropagation();
  }

  /**
   * Makes a api call to get the full scaffold export CSV file
   *
   * @memberof ScaffoldListComponent
   */
  public onExportCsv(): void {
    this.exportService
      .exportScaffolds(this.site.SiteId, this.site.SiteName)
      .then(result => (this.blobContainer = result), error => console.error(error));
  }

  /**
   * Detects when the user changes the contracts dropdown
   *
   * @param {string[]} contractIds
   * @memberof HandoversListComponent
   */
  public onSelectedContractChanged(contractIds: string[]): void {
    this.filtersForm.controls.contractList.setValue(contractIds);
    // Applies the contract filtering
    this.contractFiltering();
    const scaffolds = this.allScaffolds.filter(s => contractIds.includes(s.ContractId));
    this.selectedScaffolds = scaffolds;
    this.setFilteredScaffolds(scaffolds);
  }

  /**
   * Required by the AutoUnsubscribe()
   *
   * @memberof ScaffoldListComponent
   */
  public ngOnDestroy(): void { }
}

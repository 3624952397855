<div class="ss-import">
  <div class="modal-header">
    <h3 class="modal-title">
      <img src="assets/img/logo-white.svg"> Import Estimate From SMART Scaffolder</h3>
    <a class="pull-right" (click)="bsModalRef.hide()">
      <i class="fa fa-close fa-2x icon"></i>
    </a>
  </div>
  <div *ngIf="!showImportForm" class="modal-body">
    <div class="file-uploader">
      <span class="file-input btn button-primary btn-file">
        <i class="fa fa-search"></i> Browse&hellip;
        <input class="btn button-primary btn-file" type="file" (change)="onFileSelected($event)">
      </span>
      <span class="import-text" *ngIf="!file">No file selected</span>
      <span class="import-text" *ngIf="file">{{file.name}}</span>
    </div>
    <span *ngIf="errorMessage" class="text-assertive">
      <i class="fa fa-warning"></i> {{errorMessage}}</span>
    <div class="modal-footer">
      <div class="modal-buttons">
        <button class="btn btn-large btn-inverse" (click)="onStartImport()">
          <i class="fa fa-chevron-circle-right"></i> Next
        </button>
      </div>
    </div>
  </div>
  <hub-multi-step *ngIf="showImportForm" [steps]="steps" [context]="context" [data]="data"></hub-multi-step>
</div>

<div class="ss-import">
    <div class="modal-header">
        <h3 class="modal-title"> {{ title }} </h3>
        <a class="pull-right" (click)="bsModalRef.hide()">
            <i class="fa fa-close fa-2x icon"></i>
        </a>
    </div>
    <div class="modal-body">
        <h4 class="limit-reached-title"><i class="fa fa-exclamation-circle text-assertive"> </i> {{type}} Limit Reached
        </h4>
        <div class="main-text">
            <p>You have reached the maximum {{mainMessageSubString}} allowed on your subscription.</p>
            <p>Before {{addEditText}}, please {{closeDeleteText}}.</p>
        </div>
        <div class="noteText">
            <i class="fa fa-envelope fa-2x"></i>
            <div class="message">
                <h5>Want more {{noteMessageSubString}}?</h5>
                <p>Contact <u><a class="linkColor"
                            href="mailto:http://www.smartscaffolder.com">sales@smartscaffolder.com</a></u>
                    to upgrade your subscription.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="modal-buttons">
            <button class="btn btn-large button-primary" (click)="bsModalRef.hide()">
                <i class="fa fa-times"></i> Cancel </button>
            <button class="btn btn-large button-primary pull-right" (click)="onRefresh()" [disabled]="saveInProgress">
                <i class="fa fa-refresh" [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}">                    
                </i> Refresh
            </button>
        </div>
    </div>
</div>
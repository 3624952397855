import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'hub-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

  @Input()
  data: Array<number>;

  @Input()
  colors: Array<string>;

  @Input()
  radius = 100;

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  options: Highcharts.Options = {
    title: {
      text: ''
    },
    chart: {
      height: this.radius,
      width: this.radius,
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent'
    },
    colors: [],
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        size: '125%',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        innerSize: '70%'
      }
    },
    series: [
      {
        type: 'pie',
        data: []
      }
    ],
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    exporting: { enabled: false }
  };

  constructor() { }

  ngOnInit(): void {
    this.options.colors = this.colors;
    (this.options.series as any[])[0].data = this.data;
    this.chart = Highcharts.chart('donutChart', this.options);
  }

}

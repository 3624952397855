import { inspectionOutcomeStatusIds } from '../../../shared/values/inspection-outcome-status-ids';
import { icons } from '../../../shared/values/icons';
import { ScaffoldTimelineData, InspectionTypeData, DiaryTypeEnum } from 'app/core/hub-api';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

// Component for displaying a scaffold diary label/icon
@Component({
  selector: 'hub-diary-type',
  templateUrl: './diary-type.component.html',
  styleUrls: ['./diary-type.component.scss']
})
export class DiaryTypeComponent implements OnInit, OnChanges {

  @Input()
  diary: ScaffoldTimelineData;

  @Input()
  inspectionTypes: InspectionTypeData[];

  diaryIconPath: string;
  label: string;

  constructor() { }

  ngOnInit(): void {
    this.refreshComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['diary']) {
      this.refreshComponent();
    }
  }

  refreshComponent(): void {
    this.label = this.getDiaryLabel();
    this.diaryIconPath = this.getIconPath();
  }

  getDiaryLabel(): string {
    switch (this.diary.DiaryType) {
      case DiaryTypeEnum.Diary:
        return 'Diary';
      case DiaryTypeEnum.Inspection:
        if (!this.diary.InspectionTypeId) {
          return '';
        }
        let inspectionTypeName = this.inspectionTypes[this.diary.InspectionTypeId].GroupTitle;

        const title = this.inspectionTypes[this.diary.InspectionTypeId].Title;
        if (title) {
          inspectionTypeName += ` - ${title}`;
        }
        if (this.diary.InspectionTypeFurtherInformation) {
          inspectionTypeName += ` (${this.diary.InspectionTypeFurtherInformation})`;
        }
        return inspectionTypeName;
      case DiaryTypeEnum.Dismantle:
        return 'Dismantle';
      case DiaryTypeEnum.Variation:
        return 'Variation';
      case DiaryTypeEnum.Adaption:
        return 'Adaption';
      case DiaryTypeEnum.FullHandover:
        return 'Initial Handover';
      case DiaryTypeEnum.FullHandover:
        return 'Initial Handover';
      case DiaryTypeEnum.PartialHandover:
        return 'Re-Handover';
      case DiaryTypeEnum.FullOffHire:
        return 'Full off-hire';
      case DiaryTypeEnum.PartialOffHire:
        return 'Partial off-hire';
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return 'Inspection follow up actions';
      case DiaryTypeEnum.InspectionFollowUpInstruction:
        return 'Remedial actions';
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return 'Unsafe scaffold report';
      case DiaryTypeEnum.PauseInspections:
        return 'Inspections paused';
      case DiaryTypeEnum.StopInspections:
        return 'Inspections stopped';
      case DiaryTypeEnum.ResumeInspections:
        return 'Inspections resumed';
    }
  }

  getIconPath(): string {
    switch (this.diary.DiaryType) {
      case DiaryTypeEnum.Diary:
        return icons.diary;
      case DiaryTypeEnum.Inspection:
        return this.getIconPathBasedOnInspectionResult();
      case DiaryTypeEnum.Dismantle:
        return icons.dismantle;
      case DiaryTypeEnum.Variation:
        return icons.variation;
      case DiaryTypeEnum.Adaption:
        return icons.variation;
      case DiaryTypeEnum.FullHandover:
        return icons.handover;
      case DiaryTypeEnum.PartialHandover:
        return icons.handover;
      case DiaryTypeEnum.FullOffHire:
        return icons.offhire;
      case DiaryTypeEnum.PartialOffHire:
        return icons.offhire;
      case DiaryTypeEnum.InspectionFollowUpInstruction:
        return icons.inspectionFollowUp;
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return icons.inspectionFollowUp;
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return icons.unsafeTag;
      case DiaryTypeEnum.PauseInspections:
        return icons.paused;
      case DiaryTypeEnum.StopInspections:
        return icons.stopped;
      case DiaryTypeEnum.ResumeInspections:
        return icons.resumed;
    }
  }

  getIconPathBasedOnInspectionResult(): string {
    switch (this.diary.InspectionOutcomeId) {
      case inspectionOutcomeStatusIds.noDefects:
        return icons.noDefects;
      case inspectionOutcomeStatusIds.defectsFixed:
        return icons.defectsFixed;
      case inspectionOutcomeStatusIds.defectsNotFixed:
        return icons.defectsNotFixed;
      case inspectionOutcomeStatusIds.unsafeScaffold:
        return icons.unsafeScaffold;
      default:
        return icons.neverInspected;
    }
  }
}

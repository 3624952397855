import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import {
  CreateContractContactCommand,
  EditContractContactCommand,
  ReportSubscriptionTypesEnum,
  SiteWebAppData,
  ContractData,
} from 'app/core/hub-api';
import {
  ContractCommandService,
  StaffDetailsWebAppData
} from 'app/core/hub-api';
import { contactTypeIds } from '../../../../../shared/values/contact-type-ids';
import { AddMeAsContactModalContext } from '../../../../models/add-me-as-contact-modal-context';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import * as moment from 'moment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@AutoUnsubscribe()
@Component({
  selector: 'hub-add-me-as-contact-modal',
  templateUrl: './add-me-as-contact-modal.component.html',
  styleUrls: ['./add-me-as-contact-modal.component.scss']
})
export class AddMeAsContactModalComponent extends FormComponent implements OnInit {
  context: Partial<AddMeAsContactModalContext>;
  editMode: boolean;
  loggedInStaff: StaffDetailsWebAppData;
  contractId: string;
  modalTitle: string;
  cancelText: string;
  saveText: string;
  errorMessage: boolean;
  site: SiteWebAppData;
  form: UntypedFormGroup;
  validationMessages = {};
  contract: ContractData;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private contractCommandService: ContractCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    public modalService: BsModalService,

  ) { super(); }

  ngOnInit(): any {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.loggedInStaff = this.context.loggedInStaff;
    this.contractId = this.context.contractId;
    this.site = this.context.site;
    this.saveText = 'Yes';
    this.cancelText = 'No';
    this.modalTitle = 'Add Me As A Contact';
    this.contract = this.context.contract;
  }



  onSubmit(): void {
    this.saveInProgress = true;
    const command: CreateContractContactCommand | EditContractContactCommand = {
      ContractContactId: UUID.UUID(),
      ContactName: this.loggedInStaff.ContactName,
      EmailAddress: this.loggedInStaff.EmailAddress,
      PhoneNumber: this.loggedInStaff.PhoneNumber,
      ContractId: this.contractId,
      ReceiveInspectionReports: true,
      IsInactive: false,
      ScheduledReportStartDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
      ReportSubscriptionTypes: ReportSubscriptionTypesEnum.HandoverCertificate +
        ReportSubscriptionTypesEnum.VariationInstructions +
        ReportSubscriptionTypesEnum.InspectionStatuses +
        ReportSubscriptionTypesEnum.InspectionsCompleted +
        ReportSubscriptionTypesEnum.SiteInspectionsCompleted,
      ContactTypeId: contactTypeIds.companyContact
    };

    if (this.contract.IsPrincipalContract) {
      command.ReportSubscriptionTypes = command.ReportSubscriptionTypes + ReportSubscriptionTypesEnum.SubContractorInspections;
    }

    const commandFunction = this.editMode
      ? this.contractCommandService.EditContractContactCommand
      : this.contractCommandService.CreateContractContactCommand;


    commandFunction.call(this.contractCommandService, command)
      .subscribe(() => {
        this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference)
          .subscribe(() => {
            this.saveInProgress = false;
            this.bsModalService.setDismissReason(command.ContractId);
            this.bsModalRef.hide();
          });
      }, this.serverErrorCallback);


  }

}

  <div class="multistep-table">

    <form class="scaffold-number-form" name="scaffoldNumberForm" [formGroup]="scaffoldNumberForm">
      <div class="form-group round-checkbox-right" *ngIf="scaffoldNumbers.length > 0 && !editMode">
        <span class="round-checkbox-label">Group with another scaffold</span>
        <div class="round-checkbox round-checkbox-medium inline-block margin-right">
          <input type="checkbox" name="LinkScaffoldToggle" id="LinkScaffoldToggle" formControlName="groupScaffolds">
          <label for="LinkScaffoldToggle"></label>
        </div>
      </div>
      <div class="form-group" *ngIf="groupScaffoldsFormControl.value && !editMode">
        <div class="combined">
          <div class="inline-block scaffold-number-incremental">
            <hub-form-group label="Scaffold Number" name="customScaffoldNumber"
              [control]="customScaffoldNumberFormControl" [validationErrors]="validationErrors">
              <hub-dropdown formControlName="customScaffoldNumber" [items]="scaffoldNumbersList" idProperty="number"
                titleProperty="number" icon="fa fa-hashtag text-positive" [multiselect]="false" [clearable]="false"
                [readonly]="!groupScaffoldsFormControl.value" (valueChanges)="onSelectedNumber($event)"
                placeholder="Filter by scaffold number" required>
              </hub-dropdown>

            </hub-form-group>
          </div>
          <div *ngIf="groupScaffoldsFormControl.value && scaffoldNumbers.length > 0" class="inline-block">
            <span> - </span>
            <div class="inline-block scaffold-number-suffix">
              <hub-form-group label="Suffix" name="suffix" [control]="suffixFormControl"
                [validationErrors]="validationErrors">
                <input type="text" class="form-control" formControlName="suffix" (blur)="propagateTouched()" ngxTrim>
              </hub-form-group>
            </div>
          </div>
          <div class="text-error" *ngIf="customScaffoldNumberFormControl.hasError('scaffoldWithQuickIdMustNotExist')">
            <i class="fa fa-exclamation-triangle"></i>
            {{ validationMessages.customScaffoldNumber.scaffoldWithQuickIdMustNotExist }}
          </div>
          <div class="text-error" *ngIf="customScaffoldNumberFormControl.hasError('required')">
            <i class="fa fa-exclamation-triangle"></i>
            {{ validationMessages.customScaffoldNumber.required }}
          </div>
          <div class="text-error" *ngIf="suffixFormControl.hasError('required')">
            <i class="fa fa-exclamation-triangle"></i>
            {{ validationMessages.suffix.required }}
          </div>
          <div class="text-error" *ngIf="suffixFormControl.hasError('pattern')">
            <i class="fa fa-exclamation-triangle"></i>
            {{ validationMessages.suffix.pattern }}
          </div>
        </div>
      </div>
      <hub-form-group *ngIf="editMode || !groupScaffoldsFormControl.value" label="Scaffold Reference"
        name="autoScaffoldNumber" [control]="autoScaffoldNumberFormControl" [validationErrors]="validationErrors">
        <input type="text" class="form-control" [value]="autoScaffoldNumberFormControl.value" [attr.disabled]="true">
      </hub-form-group>
    </form>
    <form name="scaffoldDetailForm" [formGroup]="form">

      <hub-form-group label="Scaffold Name" name="name" [control]="nameFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="name" ngxTrim>
      </hub-form-group>
      <hub-form-group *ngIf="!editMode" label="Contract" name="contractId" [control]="contractIdFormControl"
        [validationErrors]="validationErrors">
        <hub-dropdown icon="fa fa-briefcase text-positive" formControlName="contractId" [items]="openContracts"
          idProperty="ContractId" titleProperty="optionName" (valueChanges)="onSelectedContractChange($event)"
          placeholder="Choose a contract" [clearable]="false">
        </hub-dropdown>
      </hub-form-group>
      <hub-form-group label="Area" name="areaId" [control]="areaIdFormControl" [validationErrors]="validationErrors">
        <hub-dropdown icon="fa fa-th-large text-positive" formControlName="areaId" [items]="activeAreas"
          idProperty="SiteAreaId" titleProperty="AreaName" (valueChanges)="onSelectedAreaChanged($event)"
          placeholder="Choose an area" [clearable]="false">
        </hub-dropdown>
      </hub-form-group>

      <hub-form-group label="Next Inspection Date" name="nextInspectionDate" [control]="nextInspectionFormControl"
        [validationErrors]="validationErrors">
        <hub-date-picker formControlName="nextInspectionDate" (valueChanges)="onNextInspectionDateChanged($event)"
          [settings]="datePickerOptions" [isRange]="false">
        </hub-date-picker>
      </hub-form-group>

      <hub-form-group label="Contract Period (weeks)" name="contractPeriod" [control]="contractPeriodFormControl"
        [validationErrors]="validationErrors">
        <input type="number" class="form-control" formControlName="contractPeriod">
      </hub-form-group>

      <hub-form-group *ngIf="scaffoldWasIncomplete()" label="Set scaffold to on-hire?" name="onHire"
        [control]="onHireFormControl" [validationErrors]="validationErrors">
        <label for="SetScaffoldOnHire" class="control-label text-left">
          <i class="fa fa-info-circle pull-right text-positive"
            tooltip="Do this if the scaffold has already been handed over." tooltip-placement="right"></i>
        </label>
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input formControlName="onHire" [value]="true" type="radio"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input formControlName="onHire" [value]="false" type="radio"> No
            </label>
          </div>
        </div>
      </hub-form-group>

      <hub-form-group *ngIf="onHireFormControl.value" label="On Hire Date" name="onHireDate"
        [control]="onHireDateFormControl" [validationErrors]="validationErrors">
        <hub-date-picker formControlName="onHireDate" (valueChanges)="onHireDateChanged($event)"
          [settings]="datePickerOptions" [isRange]="false">
        </hub-date-picker>
      </hub-form-group>

      <hub-form-group label="Will the scaffold be affected by adverse weather?" name="adverseWeather"
        [control]="adverseWeatherFormControl" [validationErrors]="validationErrors">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input formControlName="adverseWeather" [value]="true" type="radio"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input formControlName="adverseWeather" [value]="false" type="radio"> No
            </label>
          </div>
        </div>
      </hub-form-group>
    </form>

  <div class="modal-footer">
    <span class="required-note">* Denotes a required field</span>
    <div class="modal-buttons">
      <button class="btn btn-large btn-inverse" type="button" (click)="onNext(form.value)">
        <i class="fa fa-chevron-circle-right"></i> Next
      </button>
    </div>
  </div>
</div>
<div class="site-scaffolds">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['/sites/list']"><i class="fa fa-chevron-left text-positive"></i>back
        to sites list</a>
    </div>
  </div>
  <div *ngIf="!allScaffolds" class="empty-state-placeholder-large">
    <!-- Show spinner if we are waiting for the scaffold data HTTP request. -->
    <i class="fa fa-circle-notch fa-pulse loading-circle fa-2x"></i>
    <h4>Loading Scaffold Data...</h4>
  </div>
  <div *ngIf="allScaffolds && !allScaffolds.length" class="empty-state-placeholder-large">
    <i class="fa fa-building fa-4x"></i>
    <h3>Welcome to the site scaffolds area</h3>
    <p *ngIf="contracts.length">No scaffolds to show here! Click the 'Add Scaffold' or 'Import Scaffolds' button to add
      your first scaffold.</p>
    <p *ngIf="!contracts.length && customers.length">
      Before adding a scaffold you need to create at least one contract. Click the "New Contract" button below to begin.
    </p>
    <p *ngIf="!contracts.length && !customers.length">
      Before adding a scaffold you need to add at least one customer to the system. You can do that
      <a class="link" [routerLink]="['/customers']">here</a>!
    </p>
    <span *ngIf="!contracts.length && customers.length">
      <hub-permission permissions="AddEditSites">
        <a class="btn button-primary" role="button" (click)="onAddContract()" title="Add a new contract"><i
            class="fa fa-plus"></i>New Contract</a>
      </hub-permission>
    </span>
    <a *ngIf="contracts.length" class="btn button-primary" title="Add a new scaffold" (click)="onAddScaffold()"><i
        class="fa fa-plus"></i>Add Scaffold</a>
    <a *ngIf="contracts.length" class="btn button-default" (click)="onImportScaffolds()"><i
        class="fa fa-cloud-upload"></i><span> Import Scaffolds</span></a>
  </div>

  <div *ngIf="allScaffolds && allScaffolds.length">
    <div class="row margin-bottom">
      <div class="col-md-12">
        <h2 class="inline-block">Scaffolds</h2>
        <div class="ss-toolbar pull-right">
          <a class="btn button-default button-rounded" (click)="onImportScaffolds()"><i
              class="fa fa-cloud-upload"></i><span>import scaffolds</span></a>
          <hub-export-csv [trigger]="true" [blobContainer]="blobContainer" (click)="onExportCsv()"></hub-export-csv>
          <a class="btn button-default button-rounded" (click)="onAddScaffold()"><i class="fa fa-plus"></i><span>add
              scaffold</span></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-8 col-md-7">
        <ul class="card-nav">
          <li [ngClass]="!filters.selectedCustomer ? 'active' : ''" (click)="onSelectCustomer(undefined)"
            *ngIf="siteCustomers && siteCustomers.length > 1"><a>All Customers</a></li>
          <li *ngFor="let customer of siteCustomers" (click)="onSelectCustomer(customer)"
            [ngClass]="siteCustomers.length === 1 ? 'active' : filters.selectedCustomer === customer ? 'active' : ''">
            <a>{{customer.CustomerName}}</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-4 col-md-5 text-right">
        <div class="btn-group" role="group">
          <button type="button" class="btn"
            [ngClass]="{ 'button-primary': inspectionView, 'button-default': !inspectionView }"
            (click)="inspectionView = true" *ssFeatureFlag="'inspection'">
            <i class="fa fa-eye"></i><span class="hide-when-stacked"> Inspection</span>
          </button>
          <button type="button" class="btn"
            [ngClass]="{ 'button-primary': !inspectionView, 'button-default': inspectionView }"
            (click)="inspectionView = false" *ssFeatureFlag="'handover'">
            <i class="fa fa-eye"></i><span class="hide-when-stacked"> Handover</span>
          </button>
        </div>
      </div>
    </div>
    <div class="ss-container">
      <div class="row">
        <form name="filtersForm" [formGroup]="filtersForm">
          <div class="col-xs-3">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input class="form-control" type="text" placeholder="Search..." formControlName="searchTerm" />
            </div>
          </div>
          <div class="col-xs-3">
            <hub-dropdown *ngIf="contracts" icon="fa fa-briefcase text-positive" formControlName="contractList"
              [items]="contractNames" idProperty="type" titleProperty="name" itemsToDisplay="2" [multiselect]="true"
              (valueChanges)="onSelectedContractChanged($event)" placeholder="Choose contracts"
              [hidden]="!filters.selectedCustomer">
            </hub-dropdown>
          </div>
        </form>
        <div class="col-xs-6">
          <div class="btn-group btn-group-rounded pull-right">
            <button type="button" class="btn btn-default button-left" (click)="toggleShowDismantled()"
              [ngClass]="{ active: filters.showDismantled }">
              show dismantled
            </button>
            <!-- Fix duplicates -->
            <button type="button" class="btn btn-default" *ngIf="!filters.showOnlyDuplicates"
              (click)="toggleShowDuplicates()" [disabled]="groupScaffoldsMode">
              fix duplicates
            </button>
            <button type="button" class="btn btn-default active"
              *ngIf="filters.showOnlyDuplicates && !anyScaffoldsSelectedForFix()" (click)="toggleShowDuplicates()">
              cancel
            </button>
            <button type="button" class="btn button-primary active"
              *ngIf="filters.showOnlyDuplicates && anyScaffoldsSelectedForFix()" (click)="onFixDuplicates()">
              <i class="fa fa-check"></i> done
            </button>

            <!-- GROUP SCAFFOLDS -->
            <button type="button" class="btn btn-default button-right" *ngIf="!groupScaffoldsMode"
              (click)="toggleGroupScaffoldsMode()" [disabled]="filters.showOnlyDuplicates">
              group scaffolds
            </button>
            <button type="button" class="btn btn-default button-right active"
              *ngIf="groupScaffoldsMode && !multipleScaffoldsSelectedForGroup()" (click)="toggleGroupScaffoldsMode()"
              class="btn button-default">
              cancel
            </button>
            <button type="button" class="btn button-primary active"
              *ngIf="groupScaffoldsMode && multipleScaffoldsSelectedForGroup()" (click)="onGroupScaffolds()">
              <i class="fa fa-check"></i> done
            </button>
          </div>
        </div>
        <div class="row margin-top">
          <div class="col-md-12">
            <ngx-datatable #scaffoldsTable class="table" [rows]="filteredScaffolds" [columnMode]="'flex'"
              [footerHeight]="50" [headerHeight]="40" [rowHeight]="'auto'"
              [sorts]="[{ prop: 'ScaffoldNumber', dir: 'asc' }]" [limit]="10" [messages]="{ emptyMessage: '' }"
              [selectionType]="'single'" (select)="onSelect($event)">
              <ngx-datatable-column name="" *ngIf="groupScaffoldsMode" [flexGrow]="1" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <input type="checkbox" [(ngModel)]="row.group" (click)="allowClick($event)" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="" *ngIf="filters.showOnlyDuplicates" [flexGrow]="1" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <input type="checkbox" [(ngModel)]="row.fixDuplicates" (change)="disableFixDuplicates()"
                    [disabled]="row.fixDuplicateDisabled" (click)="allowClick($event)" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="" [flexGrow]="1" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <img [src]="'assets/icons/inspection-results/inspection-result-dismantled.svg'" width="18" height="18"
                    *ngIf="row.ScaffoldStatusId === scaffoldStatusIds.dismantled" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Scaffold" prop="ScaffoldNumber" [comparator]="scaffoldNumberComparator"
                [flexGrow]="5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <p class="text-bold margin-none">
                    <i class="fa fa-warning text-assertive" title="Scaffold number already exists on this site"
                      *ngIf="row.IsDuplicate"></i>
                    {{ row.ScaffoldNumber }}
                  </p>
                  <p class="text-italic">{{ row.ScaffoldDescription }}</p>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView" name="Name" prop="SpecificLocationDetails" [flexGrow]="5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.SpecificLocationDetails }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView && !filters.selectedCustomer" name="Customer"
                prop="CustomerName" [flexGrow]="5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.CustomerName }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView" name="Contract" prop="ContractName" [flexGrow]="5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.ContractName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView" name="Area" prop="AreaName" [flexGrow]="3">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.AreaName }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView" name="Last Inspection" prop="RecentInspectionStatus"
                [flexGrow]="6">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <hub-inspection-result [status]="row.RecentInspectionStatus"
                    [handover]="row.MostRecentInspectionIsHandover"
                    *ngIf="row.ScaffoldStatusId !== scaffoldStatusIds.dismantled"></hub-inspection-result>
                  <p *ngIf="row.ScaffoldStatusId === scaffoldStatusIds.dismantled">-- n/a --</p>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="inspectionView" name="Next Inspection" prop="DateOfNextInspection"
                [flexGrow]="5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span [ngClass]="{ scaffoldOverdueIndicator: dateIsInPast(row.DateOfNextInspection) }">{{
                    row.DateOfNextInspection | datetime: 'ddd DD/MM/YY'
                    }}</span>
                  <p *ngIf="row.ScaffoldStatusId === scaffoldStatusIds.dismantled">-- n/a --</p>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Status" prop="ScaffoldStatusName" [flexGrow]="3">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.ScaffoldStatusName }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Quote no" prop="QuoteNumber" [flexGrow]="3">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.QuoteNumber ? row.QuoteNumber : '-' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Item no" prop="ItemNumber" [flexGrow]="3">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.ItemNumber ? row.ItemNumber : '-' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="On Hire Date" prop="OnHireDate" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.OnHireDate" class="handover-on-hire-date">{{ row.OnHireDate | datetime: 'DD/MM/YY'
                    }}</span>
                  <span *ngIf="!row.OnHireDate">-</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Hire Period" prop="HirePeriod" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.HirePeriod ? row.HirePeriod : '-' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Contract End" prop="EndOfContractDate" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.EndOfContractDate">{{ row.EndOfContractDate | datetime: 'DD/MM/YY' }}</span><span
                    *ngIf="!row.EndOfContractDate">-</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="On Hire" prop="WeeksOnHire" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.WeeksOnHire">{{ row.WeeksOnHire }} wks</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Extra Hire" prop="CurrentExtraHire" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.CurrentExtraHire > 0"><i class="fa fa-regular fa-clock text-assertive"></i> {{
                    row.CurrentExtraHire }} wks</span>
                  <span *ngIf="row.CurrentExtraHire <= 0 || !row.CurrentExtraHire">-</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!inspectionView" name="Date Off-Hired" prop="OffHireDate" [flexGrow]="4">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="handover-date-off-hired" *ngIf="row.OffHireDate">{{ row.OffHireDate | datetime:
                    'DD/MM/YY' }}</span>
                  <span *ngIf="!row.OffHireDate">-</span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
            <div *ngIf="anyDuplicates" class="duplicate-warning">
              <i class="fa fa-warning text-assertive"></i><strong>Warning: Duplicate scaffold references exist</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
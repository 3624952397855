import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { UserIdentityService, AUTHENTICATION_SETTINGS_TOKEN, AuthenticationModuleSettings } from 'app/core/authentication';
import { Observable } from 'rxjs';

/*
* Provide the company data from API
*/
@Injectable()
export class CompanySettingsService {
    constructor(
        private userIdentityService: UserIdentityService,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        @Inject(AUTHENTICATION_SETTINGS_TOKEN) private settings: AuthenticationModuleSettings,

    ) { }


    /*
    * Gets company details and cache the client id for further usage
    *
    * @memberof CompanySettingsService
    */
    public getCompanyDetailsAndCache(companyId: string): Observable<any> {
        var isFromServerCache = true;
        var observable = this.getCompanyDetailsRequest(companyId, isFromServerCache);
        observable.subscribe((clientAppSettings: any) => {
            this.updateCompanySettingCacheData(clientAppSettings);
        });
        return observable;
    }

    /*
     * Gets company details from server, mostly from server cache otherwise fetch from support console api 
     *
     * @memberof CompanySettingsService
     */
    public getCompanyDetailsFromServer(): Observable<any> {
        const companyId = this.getCompanyId();
        var isFromServerCache = true;
        return this.getCompanyDetailsRequest(companyId, isFromServerCache);
    }

    /*
     * Gets company details from server, force fetch data from support console api
     * @param companyId - The ID of the company to retrieve details for. If not provided, it will be retrieved from the cache.
     * @memberof CompanySettingsService
     */
    public getCompanyDetailsForceFetchFromServer(companyId?: string): Observable<any> {
        var id = companyId ? companyId : this.getCompanyId();
        var isFromServerCache = false;
        var observable = this.getCompanyDetailsRequest(id, isFromServerCache);
        observable.subscribe((clientAppSettings: any) => {
            // sets the companyId in local storage
            this.updateCompanySettingCacheData(clientAppSettings);
        });
        return observable;
    }

    /*
     * Gets company id from local storage
     *
     * @memberof CompanySettingsService
     */
    public getCompanyId(): any {
        const companyId = this.localStorageService.get('companyId');
        return (companyId) ? companyId : '';
    }

    /*
     * Generate query to fetch company details from server
     *
     * @memberof CompanySettingsService
     */
    private getCompanyDetailsRequest(companyId: string, isFromServerCache: boolean): Observable<any> {
        const token = this.userIdentityService.getAccessToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + token,
            'Cache-control': 'no-cache'
        });
        const options = { headers: headers };
        const url = `${this.settings.multiTenantUrl}configuration/ClientDetails/${companyId}/${isFromServerCache}`;

        return this.http.get(url, options);
    }

    /*
     * Update required comapny setting in local storage
     *
     * @memberof CompanySettingsService
     */
    private updateCompanySettingCacheData(clientAppSettings: any) {
        // Set feature flags and customer title.
        Object.assign(this.settings.featureFlags, clientAppSettings.FeatureFlags);
        this.settings.customerTitle = clientAppSettings.CustomerTitle;

        // Store the clientSettings in localStorage.
        this.localStorageService.set('companyId', clientAppSettings.Id);
    }
}

<form name="fixScaffoldNumbersForm" [formGroup]="form">
  <hub-modal-form-header title="Fix Duplicate Scaffold References" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <ngx-datatable class="table" [rows]="duplicateScaffolds" [columnMode]="'flex'" [footerHeight]="50"
      [headerHeight]="40" [rowHeight]="'auto'" [sorts]="[{prop: 'oldScaffoldNumber', dir: 'asc'}]" [limit]="10">
      <ngx-datatable-column name="Scaffold" prop="oldScaffoldNumber" [comparator]="scaffoldNumberComparator"
        [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.oldScaffoldNumber}}
          <span *ngIf="row.SpecificLocationDetails"> ({{row.SpecificLocationDetails}})</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="New Scaffold Number" prop="ScaffoldNumber" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.ScaffoldNumber}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <hub-modal-form-footer [requiredNote]="false" [errorMessage]="serverError" [inProgress]="saveInProgress"
      (submit)="onSubmit(form.value)" (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
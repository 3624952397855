<div class="site-header">
  <div class="pull-left">
    <div class="inline-block">
      <h1><i class="fa fa-exclamation-circle" aria-hidden="true"></i> 401 Error</h1>
      <p>Unauthorised</p>
    </div>
  </div>
</div>
<div class="row margin-top">
  <div class="col-md-4 col-md-offset-4 empty-state-placeholder">
    <img src="assets/img/house.png" width="564" class="img-responsive">
    <h3>You do not have the necessary permissions to view this page!</h3>
    <p>If you feel you should be able to view this page then please contact the administrator. For now you could go <a
        (click)="back()" class="text-positive bold">back</a> to where you were or head to the <a
        [routerLink]="decideRoute.url" class="text-positive bold">{{decideRoute.pageName}}</a>.</p>
  </div>
</div>
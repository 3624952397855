/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { DropdownTexts } from './dropdown-texts';

/**
 * This class contains all settings properties used in the dropdown component
 *
 * @export
 * @class DropdownSettings
 */
export class DropdownSettings {
    /**
    * Should be the Id of the item you want to list
    * @example
    * If you want to list sites you send 'SiteId'
    */
    idProperty?: string;
    /**
    * Displays the icon next to the item name on the dropdown items
    * @example
    * 'fa fa-building'
    */
    icon?: string;
    /**
    * Should be the Title you want to display
    * @example
    * If you want to list sites you send 'SiteName'
    */
    titleProperty?: string;
    /**
    * Should be an extension of the Title you want to display
    * @example
    * If you want to list sites with a site number in front of it you send 'SiteReference'
    */
    titleReferenceProperty?: string;
    /**
    * Will display a placeholer on the dropdown when no item is selected
    * @example
    * you can send any string here
    * @default 'Please select an option'
    */
    placeholder?: string;
    /**
    * A list of all options to be displayed
    * @example
    * e.g. You can send an array of sites
    */
    items?: any[];
    /**
    * allows the dropdown to multiselect items
    * @default false - single select
    */
    multiselect?: boolean;
    /**
    * Configuration for the number of items to show when [multiselect] is active
    * (e.g. if the value is 1 the dropdown text will be: Name, 3 more..)
    * @default this._defaultItemsToDisplay
    */
    itemsToDisplay?: number;
    /**
    * Close the menu when a value is selected
    * @default false
    */
    closeOnSelect?: boolean;
    /**
    * Allow to clear selected value.
    * @default true
    */
    clearable?: boolean;
    /**
    * Detects if the dropdown is loading.
    * @default false
    */
    loading?: boolean;
    /**
    * Displays the icon next to the group name on the dropdown items
    * @example
    * 'fa fa-users'
    */
    groupIcon?: string;
    /**
    * Groups by property
    * @default null
    */
    groupBy?: string;
    /**
    * Displays the icon next to the description name on the dropdown items
    * @example
    * 'fa fa-building'
    */
    descriptionIcon?: string;
    /** Displays the description next to the item on the dropdown items */
    description?: string;
    /** Selecting group will select all of its children but not group itself if deactivated */
    selectableGroupAsModel?: boolean;
    /** Enables/Disables Selecting groups */
    selectableGroup?: boolean;
    // Overrides the placeholder texts
    texts?: DropdownTexts;
    // Overrides all settings
    settings?: DropdownSettings;
    // Allow to search for value. Default true
    searchable?: boolean;
    // Clears search input when item is selected. Default true. Default false when closeOnSelect is false
    clearSearchOnAdd?: boolean;
    /** Set custom text when filter returns empty result */
    searchEmptyResultText?: string;
    /** Sets the text for the clear button */
    uncheckAllText?: string;
    /** Sets the text for the select all button */
    checkAllText?: string;
    /** Sites the text for the all selected label */
    allSelectedText?: string;
    /** Enables the clear option (x icon next to the label)*/
    clearItemOption?: boolean;
    /** Enables/Disables the user to access the DOM on the dropdown component */
    readonly?= false;
    /** Allows to create custom options. */
    addTag?: boolean | ((term: string) => any | Promise<any>);
    /** Set custom text when using tagging */
    addTagText?: string = null;
}


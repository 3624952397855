import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationData } from 'app/core/hub-api';
import { inspectionOutcomeStatusIds } from '../../../../../shared/values/inspection-outcome-status-ids';
import { icons } from '../../../../../shared/values/icons';

@Component({
  selector: 'hub-last-inspection',
  templateUrl: './last-inspection.component.html',
  styleUrls: ['./last-inspection.component.scss']
})
export class LastInspectionComponent implements OnInit {
  passedOrFailedTitle: string;
  passedOrFailedImageUrl: string;
  inspectionTypeTitle: string;
  inspectionTypeIcon: string;
  inspectionOutcomeStatusIds = inspectionOutcomeStatusIds;
  icon: string;
  @Input()
  scaffold: any;
  @Input()
  config: ConfigurationData;

  passed: boolean;

  constructor() { }

  ngOnInit(): void {
    this.passed = this.scaffold.LastInspection.InspectionOutcomeId !== this.inspectionOutcomeStatusIds.unsafeScaffold;
    this.passedOrFailed();
    this.scaffold.MostRecentInspectionIsHandover ? this.setIconAndTitle(icons.handoverInspection, 'Handover Inspection') : this.inspectionType();
  }

  private passedOrFailed(): void {
    this.passedOrFailedImageUrl = this.passed ? 'assets/icons/timeline/tag-safe.svg' : 'assets/icons/timeline/tag-unsafe.svg';
    this.passedOrFailedTitle = this.passed ? 'Scaffold Safe' : 'Scaffold Unsafe';
  }

  private setIconAndTitle(icon, title): void {
    this.inspectionTypeIcon = icon;
    this.inspectionTypeTitle = title;
  }

  private inspectionType(): any {
    const inspectionType = this.config.InspectionTypes[this.scaffold.LastInspection.InspectionTypeId];

    if (!inspectionType) {
      this.setIconAndTitle(icons.unknown, 'Unknown');
      return;
    }

    switch (inspectionType.GroupTitle) {
      case 'Adverse Weather':
        this.setIconAndTitle(icons.adverseWeather, 'Adverse Weather');
        break;
      case 'Full Inspection':
        this.setIconAndTitle(icons.sevenDay, 'Full Inspection');
        break;
      case 'Management':
        this.setIconAndTitle(icons.management, 'Minor Defects Remain');
        break;
      case 'Other':
        this.setIconAndTitle(icons.inspection, 'Other');
        break;
      default:
        this.setIconAndTitle(icons.unknown, 'Unknown');
    }
  }

}

<!-- SITE CONTRACTS -->
<hub-help-panel title="Contracts">
  <hub-contracts-info-text></hub-contracts-info-text>
</hub-help-panel>
<div class="row margin-bottom">
  <div class="col-md-12">
    <div class="site-details-contracts">
      <h2 class="inline-block">Contracts</h2>
      <hub-permission permissions="AddEditSites">
        <a *ngIf="!isLoading" class="btn button-primary pull-right" role="button" (click)="onAddContract()"
          title="Add a new contract">
          <i class="fa fa-plus"></i>New Contract</a>
      </hub-permission>
      <ul class="card-nav">
        <li *ngFor="let customer of allCustomers" (click)="onSelectCustomer(customer.CustomerReference)"
          [ngClass]="selectedCustomer.CustomerId === customer.CustomerId ? 'active' : ''">
          <a>{{customer.CustomerName}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="selected-customer" *ngFor="let selectedContract of selectedCustomerContracts">
  <div class="ss-container">

    <!-- START Contract Card -->
    <div class="row">
      <div class="col-xs-10">

        <div class="contract-header-name">
          <hub-initials-icon [name]="selectedContract.ContractName" [disabled]="contractIsClosed(selectedContract)">
          </hub-initials-icon>
          <span
            [ngClass]="{'closed-contract-active': contractIsClosed(selectedContract), 'val-error': contractValueError(selectedContract) }">
            <i *ngIf="contractIsClosed(selectedContract)" class="fa fa-times closed-contract"></i>
            <i *ngIf="contractValueError(selectedContract)" class="fa fa-warning"></i>
          </span>
          <h2 class="inline-block">{{selectedContract.ContractName}} ({{selectedContract.ContractReference}})</h2>
        </div>

        <div class="contract-header-info">

          <div class="gray-wrapper">
            <span class="site-info-directives" tooltip="Start Date">
              <i class="fa fa-calendar"></i> {{ selectedContract.CreationDate | datetime: 'll' }}
            </span>
            <hub-principal-contract [principalContract]="selectedContract.IsPrincipalContract">
            </hub-principal-contract>
            <hub-signatures-required
              [canSubmitDocumentsWithoutSignature]="selectedContract.CanSubmitDocumentsWithoutSignature">
            </hub-signatures-required>
            <hub-agreed-rates [isScheduleRatesAgreed]="selectedContract.IsScheduleRatesAgreed"
              *ssFeatureFlag="'variation'"></hub-agreed-rates>
            <hub-dismantle-required [isAllowDismantleWithoutOffhire]="selectedContract.AllowDismantleWithoutOffhire">
            </hub-dismantle-required>
          </div>

          <p *ngIf="selectedContract.Description"><strong>Description:</strong>
            {{selectedContract.Description}}</p>
          <p *ngIf="selectedContract.ContractType"><strong>Type:</strong> {{selectedContract.ContractType}}
          </p>
        </div>
      </div>

      <div class="col-xs-2">
        <div class="ss-toolbar pull-right" *ngIf="!contractIsClosed(selectedContract)">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded" (click)="onEditContract(selectedContract)">
              <i class="fa fa-pencil icon"></i>
              <span> edit contract</span>
            </a>
          </hub-permission>
        </div>
      </div>
    </div>
    <!-- END Contract Card -->

    <!-- START Contract IS Closed -->
    <div *ngIf="contractIsClosed(selectedContract)">
      <div class="row">
        <div class="col-xs-11 info-box">

          <div class="info-box-text">
            <strong><i class="fa fa-briefcase"></i> Contract Closed</strong>
            <p>This contract has been closed. If you would like to open it again then
              please press the 'Open Contract' button below.</p>
          </div>
          <div class="info-box-btn">
            <div>
              <hub-permission permissions="AddEditSites">
                <a class="btn button-primary" role="button" (click)="onOpenContract(selectedContract)">
                  <i class="fa fa-check"></i>Open Contract</a>
              </hub-permission>
            </div>
          </div>
          <div class="info-box-error-messages">
            <p *ngIf="customerDeletedError(selectedContract)">
              <span class="validation-error">
                <i class="fa fa-warning text-assertive"></i>{{ customerDeletedError(selectedContract) }}</span>
            </p>
            <p *ngIf="siteClosedError(selectedContract)">
              <span class="validation-error">
                <i class="fa fa-warning text-assertive"></i>{{ siteClosedError(selectedContract) }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- END Contract IS Closed -->

    <!-- START COLLAPSE -->
    <div class="accordions" *ngIf="!contractIsClosed(selectedContract)">

      <accordion>
        <hub-site-contract-contacts #contacts [selectedContract]="selectedContract"></hub-site-contract-contacts>
        <hub-site-company-contacts [selectedContract]="selectedContract"></hub-site-company-contacts>
        <accordion-group>
          <span accordion-heading>
            <img class="margin-right-sm image-darken" src="assets/img/icon-inspection-schedule-7.svg" width="16"> Weekly
            Inspections
          </span>

          <div>
            <div class="text-center" *ngIf="!contractInspStatuses(selectedContract)">
              <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
              <h4>Loading Weekly Inspection Data...</h4>
            </div>
            <div *ngIf="contractInspStatuses(selectedContract)">
              <div *ngIf="contractInspStatuses(selectedContract).State === inspectionStatusEnum.Normal">
                <p>Weekly inspections are currently
                  <strong>active</strong> for {{selectedContract.ContractName}} on this site.
                  <i class="info-circle"></i>
                </p>
                <a class="btn button-default margin-right" (click)="pauseInspections(selectedContract)" role="button">
                  <i class="fa fa-pause"></i>Pause</a>
                <a class="btn button-assertive" role="button" (click)="stopInspections(selectedContract)">
                  <i class="fa fa-stop"></i>Stop</a>
              </div>
              <div *ngIf="contractInspStatuses(selectedContract).State === inspectionStatusEnum.Stopped">
                <p>Weekly inspections are currently
                  <strong>not contracted</strong> for {{selectedContract.ContractName}} on this site.
                  <i class="info-circle"></i>
                </p>
                <a class="btn button-default" role="button" (click)="resumeInspections(selectedContract)">
                  <i class="fa fa-play"></i>Resume</a>
              </div>
              <div *ngIf="contractInspStatuses(selectedContract).State === inspectionStatusEnum.Paused">
                <p *ngIf="contractInspStatuses(selectedContract).ResumeDate">Weekly inspections are currently
                  <strong>paused</strong> for {{selectedContract.ContractName}} on this site and will resume on
                  {{contractInspStatuses(selectedContract).ResumeDate
                  | datetime: 'll' }}
                  <i class="info-circle"></i>
                </p>
                <p *ngIf="!contractInspStatuses(selectedContract).ResumeDate">Weekly inspections are currently
                  <strong>paused</strong> for {{selectedContract.ContractName}} on this site
                  <i class="info-circle"></i>
                </p>
                <a class="btn button-default margin-right" role="button" (click)="resumeInspections(selectedContract)">
                  <i class="fa fa-play"></i>Resume</a>
                <a class="btn button-assertive" role="button" (click)="stopInspections(selectedContract)">
                  <i class="fa fa-stop"></i>Stop</a>
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group>
          <span accordion-heading>
            <i class="fa fa-cog text-body-color margin-right-sm"></i> Advanced options
          </span>
          <a class="btn button-assertive pull-right" role="button" (click)="onCloseContract(selectedContract)">
            <i class="fa fa-times"></i>Close Contract</a>
        </accordion-group>
      </accordion>
    </div>
    <!-- END COLLAPSE -->



  </div>
</div>
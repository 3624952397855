import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';
import { errorMessages } from 'app/shared/values/error-messages';
import { each, map } from 'lodash';
import { combineLatest, forkJoin } from 'rxjs';
import {
  ConfigurationCommandService,
  ConfigurationData,
  CreateDiaryCategoryCommand,
  DeleteDiaryCategoryCommand,
  EditDiaryCategoryCommand
} from 'app/core/hub-api';
import { diaryCategoryIds } from 'app/shared/values/diary-category-ids';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'hub-diary-categories',
  templateUrl: './diary-categories.component.html',
  styleUrls: ['./diary-categories.component.scss']
})
export class ScaffoldDiaryCategoriesComponent implements OnInit, OnDestroy {
  siteReference: string;

  configuration: ConfigurationData;
  diaryCategories: any[];

  addCategoryForm: UntypedFormGroup;
  addCategoryTitleFormControl: UntypedFormControl;
  addCategoryError: string;
  addingCategory: boolean;

  editCategoryForm: UntypedFormGroup;
  editCategoryFormArray: UntypedFormArray;
  editCategoryValidationErrors: string[];

  @ViewChild(BsDropdownDirective)
  bsDropdown: BsDropdownDirective;

  constructor(
    private sitediaryQueryService: SiteScaffoldDiaryQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private configurationCommandService: ConfigurationCommandService,
    private route: ActivatedRoute,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.siteReference = this.route.parent.parent.snapshot.params['siteReference'];
    const obs$ = combineLatest([this.configurationQueryService.configurationDataChanges()]);
    obs$.subscribe(latest => {
      this.refreshComponent(latest[0]);
    });
  }

  refreshComponent(configuration: ConfigurationData): void {
    this.configuration = configuration;
    // Don't allow the user to edit the uncategorised category.
    this.diaryCategories = map(configuration.DiaryCategories)
      .sort((a, b) => (a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1))
      .filter(c => c.DiaryCategoryId !== diaryCategoryIds.uncategorised);

    // Initialise the add categories form.
    this.addCategoryTitleFormControl = new UntypedFormControl();
    this.addCategoryForm = new UntypedFormGroup({
      title: this.addCategoryTitleFormControl
    });

    this.addCategoryTitleFormControl.valueChanges.subscribe(() => {
      this.validateAddCategory();
    });

    // Initialise the edit categories form.
    this.editCategoryFormArray = new UntypedFormArray(
      this.diaryCategories.map((category, index) => {
        const titleFormControl = new UntypedFormControl(category.Title);
        titleFormControl.valueChanges.subscribe(title => {
          this.validateEditCategory(index);
        });
        return new UntypedFormGroup({
          title: titleFormControl
        });
      })
    );
    this.editCategoryForm = new UntypedFormGroup({
      categories: this.editCategoryFormArray
    });

    // Initialise edit categories validation errors.
    this.editCategoryValidationErrors = [];
    each(this.diaryCategories, () => this.editCategoryValidationErrors.push(''));
  }

  onDeleteCategory(field: any, index: number): void {
    const context: SSDialogContext = {
      title: 'Delete Category?',
      body: `Please note that this will delete "${this.diaryCategories[index].Title
        }" from the category list and all assigned entries will become "Uncategorised"`,
      okBtnClass: 'btn button-assertive',
      okBtnText: 'Delete',
      cancelBtnClass: 'btn btn-default',
      cancelBtnText: 'Cancel',
      showHeaderClose: true,
      showOnlyCloseBtn: false,
      dialogClass: 'modal-dialog',
      headerClass: 'confirm-modal-header',
      footerClass: 'confirm-modal-footer'
    };
    const config: ModalOptions = {
      animated: true,
      class: 'modal-md modal-danger',
      ignoreBackdropClick: true, // Required
      keyboard: false, // Required
      initialState: {
        // context: context
      }
    };
    const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context, config))
    modal.content.onClose.subscribe(
      result => {
        if (result !== null && result !== false) {
          this.deleteCategory(field, index);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  deleteCategory(field: any, index: number): void {
    const deleteDiaryCategoryCommand: DeleteDiaryCategoryCommand = {
      DiaryCategoryId: this.diaryCategories[index].DiaryCategoryId
    };

    field.updating = true;
    this.configurationCommandService.DeleteDiaryCategoryCommand(deleteDiaryCategoryCommand).subscribe(() => {
      const obs$ = forkJoin([
        this.sitediaryQueryService.siteScaffoldDiaryQuery(false, this.siteReference),
        this.configurationQueryService.configurationQuery(false)
      ]);
      obs$.subscribe(() => {
        field.updating = false;
      },
        error => {
          field.updating = false;
          console.error(error);
        });
    }, error => {
      field.updating = false;
      console.error(error);
    });
  }

  onAddCategory(): void {
    if (!this.validateAddCategory()) {
      return;
    }

    this.addingCategory = true;
    const createDiaryCategoryCommand: CreateDiaryCategoryCommand = {
      DiaryCategoryId: UUID.UUID(),
      Title: this.addCategoryTitleFormControl.value
    };

    this.configurationCommandService.CreateDiaryCategoryCommand(createDiaryCategoryCommand).subscribe(() => {
      this.configurationQueryService.configurationQuery(false).subscribe(() => {
        this.addingCategory = false;
        this.addCategoryTitleFormControl.reset('');
      });
    });
  }

  validateAddCategory(): boolean {
    if (this.addCategoryTitleFormControl.pristine) {
      return;
    }
    this.addCategoryTitleFormControl.markAsTouched();
    const title = this.addCategoryTitleFormControl.value;
    let error = '';
    if (!title) {
      error = errorMessages.required;
    }
    this.addCategoryError = error;
    return !error;
  }

  onEditCategory(field: any, index: number): void {
    const editDiaryCategoryCommand: EditDiaryCategoryCommand = {
      DiaryCategoryId: this.diaryCategories[index].DiaryCategoryId,
      Title: (this.editCategoryFormArray.controls[index] as UntypedFormGroup).controls.title.value
    };

    if (!this.validateEditCategory(index)) {
      return;
    }

    field.updating = true;
    this.configurationCommandService.EditDiaryCategoryCommand(editDiaryCategoryCommand).subscribe(() => {
      const obs$ = forkJoin([
        this.sitediaryQueryService.siteScaffoldDiaryQuery(false, this.siteReference),
        this.configurationQueryService.configurationQuery(false)
      ]);
      obs$.subscribe(() => {
        field.editing = false;
        field.updating = false;
      });
    });
  }

  validateEditCategory(index: number): boolean {
    const title = (this.editCategoryFormArray.controls[index] as UntypedFormGroup).controls.title.value;
    let error = '';
    if (!title) {
      error = errorMessages.required;
    }
    this.editCategoryValidationErrors[index] = error;
    return !error;
  }

  ngOnDestroy(): void { }
}

<span class="site-info-directives">
  <span *ngIf="_isScheduleRatesAgreed">
    <i class="fa fa-gbp"></i> Schedule of rates</span>

  <span *ngIf="!_isScheduleRatesAgreed">
    <span class="fa-stack signature-icon">
      <i class="fa fa-gbp fa-stack-1x"></i>
      <i class="fa fa-times text-assertive fa-stack-1x"></i>
    </span>
    <span class="signature-text">No schedule of rates</span>
  </span>
</span>

import { Component, OnInit, Input } from '@angular/core';
import { DismantleData } from 'app/core/hub-api';
@Component({
  selector: 'hub-dismantle-card',
  templateUrl: './dismantle-card.component.html',
  styleUrls: ['./dismantle-card.component.scss']
})
export class DismantleCardComponent implements OnInit {
@Input()
dismantleData: DismantleData;
  constructor() { }

  ngOnInit(): void {
  }

}

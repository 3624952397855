<div class="modal-content">
  <hub-modal-form-header title="Add site diary entry" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <form name="addSiteDiaryEntryForm" [formGroup]="form">
      <hub-form-group label="Category" name="categories" [control]="categoryFormControl"
        [validationErrors]="validationErrors">
        <ng-select ngDefaultControl [items]="diaryCategories" bindLabel="Title" [searchable]="true"
          bindValue="DiaryCategoryId" labelForId="Title" [multiple]="false" placeholder="Select a category"
          clearAllText="Clear" formControlName="categories" [disabled]="saveInProgress">
          <ng-template ng-label-tmp let-item="item">
            <span class="text-and-icon">
              <hub-colour-coded-title [colourGeneration]="item.DiaryCategoryId"></hub-colour-coded-title>
              <span>{{item.Title}}</span>
            </span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="dropdown-finder">
              <hub-colour-coded-title [colourGeneration]="item.DiaryCategoryId"></hub-colour-coded-title>
              <span class="bold" [ngOptionHighlight]="search"> {{item.Title}}</span>
            </div>
          </ng-template>
        </ng-select>
      </hub-form-group>

      <hub-form-group label="Notes" name="notes" [control]="notesFormControl">
        <textarea class="form-control" type="text" formControlName="notes" ngxTrim></textarea>
      </hub-form-group>

    </form>
    <div class="file-uploader">
      <button class="file-input btn button-primary btn-file" [disabled]="saveInProgress">
        <i class="fa fa-search"></i> Browse&hellip;
        <input type="file" class="btn button-primary btn-file" accept="{{allowedFileFormats}}"
          (change)="onFileSelected($event)" [disabled]="saveInProgress" name="sizeRestrictedFile"
          [(ngModel)]="sizeRestrictedFile" multiple />
      </button>
      <span class="import-text half-size" *ngIf="!files.length">No files selected</span>
      <span class="import-text half-size" *ngIf="files.length">{{files.length}} file{{ (files.length > 1) ? 's':'' }}
        selected{{errorLength}}</span>
      <span class="import-text multiple-files" *ngIf="files.length"> <span *ngFor="let file of files; let i = index"> <i
            class="fa fa-exclamation-triangle text-assertive clickable" *ngIf="onFileError(file)"
            [popover]="sizeErrorTooltip" placement="top" [outsideClick]="false" tabindex="1"></i> {{file.name}}
          <a (click)="onRemoveFile(file, i)"><i class="fa fa-times close-btn"></i></a>
          <ng-template #sizeErrorTooltip>
            <p class="no-margin" *ngIf="file.size > sizeRestrictions.max"><i
                class="fa fa-exclamation-triangle text-assertive"></i>
              {{errorMessages.fileSizeErrorMsg}} </p>
            <p class="no-margin" *ngIf="isFileWrongFormat(file)"><i
                class="fa fa-exclamation-triangle text-assertive"></i>
              {{errorMessages.wrongFormat}} </p>
          </ng-template>
        </span>
      </span>
    </div>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit()"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</div>
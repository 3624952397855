/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmLeaveComponent } from 'app/shared/models/confirm-leave';
import { DiaryQueryService } from 'app/sites/services/diary-query-service';
import { cloneDeep, concat } from 'lodash';
import { combineLatest } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  EditScaffoldMediaTranscriptionCommand,
  MediaData,
  MediaTranscriptionData,
  ScaffoldCommandService,
  DiaryData,
  DiaryQuery
} from 'app/core/hub-api';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';

/**
 * Diary Media Viewer component
 * Component for viewing diary media - media transcription is also edited here, but could potentially be a separate component.
 *
 * @export
 * @class DiaryMediaViewerComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-diary-media-viewer',
  templateUrl: './diary-media-viewer.component.html',
  styleUrls: ['./diary-media-viewer.component.scss']
})
export class DiaryMediaViewerComponent implements OnInit, OnDestroy, ConfirmLeaveComponent {
  /** Stores the selected diary */
  diary: DiaryData;
  /** Stores the selected diary ScaffoldId/SiteId */
  id: string;
  /** Stores the file transcription */
  transcription: MediaTranscriptionData;
  /** Used for checking if the user has unsaved changes. */
  originalTranscription: string;
  /** Stores the selected media file */
  media: MediaData;
  /** Stores all the supported media types */
  mediaType: 'audio' | 'video' | 'image' | 'transcription';
  /** required by the ConfirmLeaveComponent */
  disposing: boolean;
  /** Froala editor settings */
  editorOptions: Object = {
    key: (environment as any).froalaKey,
    height: 375,
    attribution: false,
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'color',
      '|',
      'align',
      'outdent',
      'indent',
      '-',
      '|',
      'selectAll',
      'clearFormatting',
      '|',
      'print',
      'spellChecker',
      'help',
      '|',
      'undo',
      'redo'
    ],
    quickInsertTags: [],
    // TODO: This needs to be removed in 5.1 as we have a fix with slickgrid
    events: {
      'froalaEditor.click': function (e: any, editor: any): void {
        editor.el.focus();
      }
    }
  };
  /** Detects is the transcription is being saved */
  savingMedia: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private diaryQueryService: DiaryQueryService,
    private scaffoldCommandService: ScaffoldCommandService,
    private changeDetectorRef: ChangeDetectorRef,
    private bsModalService: BsModalService
  ) { }

  /**
   * Component Initialisation
   *
   * @memberof DiaryMediaViewerComponent
   */
  public ngOnInit(): void {
    const diaryId = this.route.parent.snapshot.params['diaryId'];
    const diaryType = this.route.parent.snapshot.params['diaryType'];
    this.id = this.route.parent.snapshot.params['id'];

    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = diaryId;
    diaryQuery.DiaryType = diaryType;

    const obs$ = combineLatest(
      [this.diaryQueryService.getDiaryData(diaryQuery),
      this.route.params]
    );
    obs$.subscribe(latest => {
      this.refreshComponent(latest[0], latest[1]['mediaReferenceId'], latest[1]['mediaType']);
    });
  }


  /**
   * Refreshes the view with new/cached data
   *
   * @param {DiaryData} scaffoldDiary
   * @param {string} mediaReferenceId
   * @param {string} mediaType
   * @memberof DiaryMediaViewerComponent
   */
  public refreshComponent(scaffoldDiary: DiaryData, mediaReferenceId: string, mediaType: string): void {
    // There seems to be a bug where dynamically changing a HTML5 audio/video src attribute does not result in loading the new video (Chrome 65).
    // Here we briefly remove the video from the dom and rerender it.
    this.mediaType = undefined;
    this.changeDetectorRef.detectChanges();

    this.diary = scaffoldDiary[0];
    const mediaData = concat(this.diary.Audios, this.diary.Videos, this.diary.Images, this.diary.PDFs);

    // Check if recently added media is shown on the mediaData array
    const currentNav = this.router.getCurrentNavigation();
    const selectedMediaFile = currentNav ? currentNav.extras.state.selectedMedia : null;
    if (selectedMediaFile) {
      const containsSelectedMedia = !!mediaData.find(s => s.MediaReferenceId === selectedMediaFile.MediaReferenceId);
      if (!containsSelectedMedia) mediaData.push(selectedMediaFile);
    }

    this.media = mediaData.find(d => d.MediaReferenceId === mediaReferenceId);
    this.mediaType = mediaType as any;

    this.transcription = cloneDeep(
      this.diary.Transcriptions.find(t => t.MediaReferenceId === mediaReferenceId)
    );
    this.originalTranscription = this.transcription ? this.transcription.Transcription : undefined;
  }

  /**
   * User triggered action to save the transcription from the froala editor
   *
   * @memberof DiaryMediaViewerComponent
   */
  public onSaveTranscription(): void {
    this.savingMedia = true;
    const editScaffoldMediaTranscriptionCommand: EditScaffoldMediaTranscriptionCommand = {
      ScaffoldId: this.id,
      MediaReferenceId: this.transcription.MediaReferenceId,
      Title: this.transcription.Title,
      Transcription: this.transcription.Transcription,
      Deleted: false
    };
    this.scaffoldCommandService
      .EditScaffoldMediaTranscriptionCommand(editScaffoldMediaTranscriptionCommand)
      .subscribe(() => {
        this.diaryQueryService.diaryQuery(false, this.diary.DiaryId).subscribe(() => {
          this.savingMedia = false;
        });
      });
  }

  /**
   * Required by the ConfirmLeaveComponent, triggers the transcription changed function
   *
   * @returns {boolean}
   * @memberof DiaryMediaViewerComponent
   */
  public shouldConfirmLeave(): boolean {
    return this.transcriptionChanged();
  }

  /**
   * Detects if the transcription has changed
   *
   * @returns {boolean}
   * @memberof DiaryMediaViewerComponent
   */
  transcriptionChanged(): boolean {
    return !!(this.transcription && this.transcription.Transcription !== this.originalTranscription);
  }

  /**
   * Dialog to confirm that the user has unsaved changes
   *
   * @returns {DialogRef<TwoButtonPreset>}
   * @memberof DiaryMediaViewerComponent
   */
  displayConfirmLeaveModal(): Promise<any> {
    return new Promise(resolve => {
      const context: SSDialogContext = {
        title: 'Unsaved Changes',
        body: 'You have unsaved changes. Carry on and lose the changes?',
        dialogClass: 'modal-dialog modal-lg',
        headerClass: 'modal-header confirm-modal-header',
        okBtnText: 'Yes carry on',
        cancelBtnText: 'No stop so I can save my changes',
        footerClass: 'modal-footer confirm-modal-footer'
      };
      const modal = this.bsModalService.show(ModalDialogComponent, SSModalConfig.generate(context));
      modal.content.onClose.subscribe(result => {
        if (result === true) {
          resolve(result);
        }
      });
    });
  }

  /**
   * Required by the AutoUnsubscribe
   *
   * @memberof DiaryMediaViewerComponent
   */
  ngOnDestroy(): void { }
}

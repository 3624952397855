import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator to validate that a scaffold incremental number is of the correct format.
export function scaffoldNumberIncrementalValidator(control: AbstractControl): any  {
    const incremental = control.value.split('-')[0];

    const isValid = !!incremental;
    return isValid ? null : {
      incremental: {
        value: control.value
      }
    };
}

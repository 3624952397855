import { Injectable } from '@angular/core';
import {
  HandoverReportEndpointService,
  InspectionReportEndpointService,
  VariationReportEndpointService,
  DiaryTypeEnum
} from 'app/core/hub-api';
import { Observable } from 'rxjs';
// import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
@Injectable()
export class ActivityReportService {
  pdf/*: PDFDocumentProxy*/;
  constructor(
    private handoverReportService: HandoverReportEndpointService,
    private inspectionReportService: InspectionReportEndpointService,
    private variationReportService: VariationReportEndpointService
  ) {}

  generateReport(ActivityType: number, id: string, Activity: any): any {
    if (ActivityType && id) {
      const reportToLoad: Observable<Blob> = this.reportToLoad(ActivityType, id);
      return reportToLoad.pipe(
        map(val => {
          const blob = new Blob([val]);
          this.generateDownload(blob, Activity, ActivityType);
        })
      );
    }
  }

 generateDownload(blob: Blob, Activity: any, ActivityType: number): any {
    const date = moment(Activity.When).format('YYYY-MM-DDTHHmmss');
    const filename = date + ' ' + this.activityName(ActivityType) + ' ' + Activity.SiteDetails.SiteName + '.pdf';
    if ((navigator as any).msSaveOrOpenBlob) {
      // msSaveOrOpenBlob for IE/Edge.
      (navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      // file-saver library for Chrome/Firefox.
      saveAs(blob, filename);
    }
  }

  private reportToLoad(ActivityType: Number, id: string): any {
    switch (ActivityType) {
      case DiaryTypeEnum.Variation:
        return this.variationReportService.GetVariationEstimationReportForDownload(id);
      case DiaryTypeEnum.Adaption:
        return this.variationReportService.GetAdaptionInstructionReportForDownload(id);
      case DiaryTypeEnum.FullHandover:
      case DiaryTypeEnum.PartialHandover:
        return this.handoverReportService.GetHandoverCertificateReportForDownload(id);
      case DiaryTypeEnum.FullOffHire:
      case DiaryTypeEnum.PartialOffHire:
        return this.handoverReportService.GetOffhireCertificateReportForDownload(id);
      case DiaryTypeEnum.InspectionFollowUpInstruction:
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return this.inspectionReportService.GetRemedialReportForDownload(id);
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return this.inspectionReportService.GetUnsafeScaffoldReportForDownload(id);
        case DiaryTypeEnum.Inspection:
            return this.inspectionReportService.GetSiteInspectionReportForDownload(id);
    }
    return ActivityType;
  }

  private activityName(ActivityType: Number): any {
    switch (ActivityType) {
      case DiaryTypeEnum.Variation:
        return 'Variation Instruction';
      case DiaryTypeEnum.Adaption:
        return 'Variation Instruction';
      case DiaryTypeEnum.FullHandover:
      case DiaryTypeEnum.PartialHandover:
        return 'Handover Certificate';
      case DiaryTypeEnum.FullOffHire:
      case DiaryTypeEnum.PartialOffHire:
        return 'Off Hire Certificate';
      case DiaryTypeEnum.InspectionFollowUpInstruction:
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return 'Remedial Action';
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return 'Unsafe Scaffold';
    }
    return ActivityType;
  }
}

import { Component, OnInit } from '@angular/core';
import { MultiStepModalFormComponent } from 'app/shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { ScaffoldImportModalContext } from '../scaffold-import-modal-context';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  UntypedFormArray
} from '@angular/forms';
import { ContractData } from 'app/core/hub-api';
import { errorMessages } from 'app/shared/values/error-messages';
import { QuotesQueryService } from 'app/core/services/quotes-query-service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-select-contract',
  templateUrl: './select-contract.html',
  styleUrls: ['./select-contract.scss']
})
export class SelectContractComponent extends MultiStepModalFormComponent implements OnInit {
  data: ScaffoldImportModalContext;
  context: Partial<ScaffoldImportModalContext>;
  form: UntypedFormGroup;

  staffOptions: ContractData[];

  validationMessages = {
    ContractId: {
      required: errorMessages.required
    }
  };
  contractList: (ContractData & { selected: boolean })[];
  contracts;
  required: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private quotesQueryService: QuotesQueryService,
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    if (!this.context) this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.contracts = this.context.site.Contracts;
    this.contractList = this.contracts
      .sort((a, b) => {
        return a.ContractName.toLocaleLowerCase() >
          b.ContractName.toLocaleLowerCase()
          ? 1
          : -1;
      })
      .map(s => Object.assign(s, { selected: false }));

    this.getContractSelected();

    this.form = this.formBuilder.group({
      staffControls: this.buildContractControls()
    });
    super.ngOnInit();
  }

  getContractSelected(): void {
    const contractId = this.context.selectedContract && !this.data.selectedContract
      ? this.context.selectedContract.ContractId
      : this.data.selectedContract
        ? this.data.selectedContract[0].ContractId
        : '';
    if (contractId) {
      const index = this.contractList.findIndex(s => s.ContractId === contractId);
      this.contractList[index].selected = true;
      this.context.selectedContract = this.contractList[index];
    }
  }

  get contractControls(): AbstractControl {
    return this.form.get('staffControls');
  }

  private buildContractControls(): UntypedFormArray {
    const arr = this.contractList.map(contract => {
      return this.formBuilder.control(contract.selected);
    });
    return this.formBuilder.array(arr);
  }

  private deselectAll(arr: any[]): void {
    arr.forEach(val => {
      if (val.selected) {
        val.selected = false;
      }
    });
  }

  updateSelection(selectedOption): void {
    const selected = selectedOption.selected;
    this.deselectAll(this.contractList);
    selectedOption.selected = !selected;
    this.required = null;
  }

  onNext(): void {
    this.data.selectedContract = this.contractList.filter(c => c.selected);
    if (!this.data.selectedContract.length) {
      this.required = 'Please select one of the contracts';
    } else {
      this.required = null;
      this.data.selectedContract = this.contractList.filter(c => c.selected);

      // Required to wait for the quote service to render the quote names before loading the next screen
      this.quotesQueryService
        .quotesQuery(false, this.data.selectedContract[0].ContractId)
        .subscribe(q => {
          this.data.quotes = q;
          this.nextStep();
        });
    }
  }
}

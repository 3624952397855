const splitScaffoldNumber = (sn) => {
  return {
    incremental: sn.split('-')[0],
    suffix: sn.split('-')[1]
  };
};

const compareBasedOnSuffix = (suffix1, suffix2) => {

  if (!suffix2) { return 1; }
  if (!suffix1) { return -1; }

  const suffix1IsNumber = !isNaN(Number(suffix1));
  const suffix2IsNumber = !isNaN(Number(suffix2));

  if (suffix1IsNumber && suffix2IsNumber) {
    return parseInt(suffix1, 10) >= parseInt(suffix2, 10) ? 1 : -1;
  } else if (suffix1IsNumber && !suffix2IsNumber) {
    return 1;
  } else if (!suffix1IsNumber && suffix2IsNumber) {
    return -1;
  } else {
    return suffix1 >= suffix2 ? 1 : -1;
  }
};

// A comparator for comparing 2 scaffold numbers.
export const scaffoldNumberComparator = (sn1, sn2) => {

  if (typeof (sn2) === 'undefined') { return 1; }
  if (typeof (sn1) === 'undefined') { return -1; }

  const sn1Split = splitScaffoldNumber(sn1);
  const sn2Split = splitScaffoldNumber(sn2);

  if (isNaN(Number(sn2Split.incremental))) { return 1; }
  if (isNaN(Number(sn1Split.incremental))) { return -1; }

  if (parseInt(sn1Split.incremental, 10) > parseInt(sn2Split.incremental, 10)) {
    return 1;
  } else if (parseInt(sn1Split.incremental, 10) < parseInt(sn2Split.incremental, 10)) {
    return -1;
  } else {
    return compareBasedOnSuffix(sn1Split.suffix, sn2Split.suffix);
  }
};

// A comparator for comparing 2 scaffolds by their scaffold numbers.
export const scaffoldComparator = (scaffold1, scaffold2) => {

  const sn1 = scaffold1.ScaffoldNumber;
  const sn2 = scaffold2.ScaffoldNumber;

  return scaffoldNumberComparator(sn1, sn2);
};

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, some } from 'lodash';
import { SiteAreaData, SiteWebAppData } from 'app/core/hub-api';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditAreaModalComponent } from '../add-edit-area-modal/add-edit-area-modal.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@AutoUnsubscribe()
@Component({
  selector: 'hub-site-detail-areas',
  templateUrl: './site-detail-areas.component.html',
  styleUrls: ['./site-detail-areas.component.scss']
})
export class SiteDetailAreasComponent implements OnInit, OnDestroy {
  site: SiteWebAppData;
  allAreas: SiteAreaData[];
  filteredAreas: SiteAreaData[];

  anyDeletedAreas: boolean;
  errorMessage: string;

  filtersForm: UntypedFormGroup;
  showDeletedAreasFormControl: UntypedFormControl;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.snapshot.params['siteReference'];
    this.siteDetailQueryService.siteDetailDataChanges(siteReference).subscribe(newSite => this.refreshComponent(newSite));
  }

  private filterAreas(showDeletedSites: boolean): void {
    this.filteredAreas = showDeletedSites ? this.allAreas : this.allAreas.filter(a => !a.Deleted);
  }

  refreshComponent(site: SiteWebAppData): void {
    this.site = site;
    this.allAreas = (map(site.SiteAreas) as any).sortAlphaNumeric('AreaName');

    const showDeletedAreas = false;
    this.filterAreas(showDeletedAreas);

    this.errorMessage = some(this.allAreas, a => a.Deleted && a.NumberOfScaffolds > 0) ? 'A deleted area contains one or more active scaffolds' : '';
    this.anyDeletedAreas = some(this.allAreas, a => a.Deleted);

    this.showDeletedAreasFormControl = new UntypedFormControl(showDeletedAreas);
    this.filtersForm = new UntypedFormGroup({
      showDeletedAreas: this.showDeletedAreasFormControl
    });

    this.showDeletedAreasFormControl.valueChanges.subscribe(value => this.filterAreas(value));
  }

  onAddArea(): void {
    const context = {
      editMode: false,
      site: this.site
    };
    this.bsModalService.show(AddEditAreaModalComponent, SSModalConfig.generate(context));
  }

  onEditArea(area: SiteAreaData): void {
    const context = {
      editMode: true,
      site: this.site,
      area: area
    };
    this.bsModalService.show(AddEditAreaModalComponent, SSModalConfig.generate(context));
  }

  ngOnDestroy(): void { }
}

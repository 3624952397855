<div>
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['/sites/list']">
        <i class="fa fa-chevron-left text-positive"></i>back to sites list</a>
    </div>
  </div>
  <!-- Empty state placeholder -->
  <div *ngIf="siteScaffoldDiaries && !siteScaffoldDiaries.length" class="empty-state-placeholder-large">
    <i class="fa fa-building fa-4x"></i>
    <h3>Welcome to the site and scaffold diary area</h3>
    <p>It doesn't look like any site or scaffold diaries have been completed yet!</p>
    <a (click)="onAddSiteDiary()" class="btn button-primary" title="Add site diary entry">
      <i class="fa fa-plus"></i>Add site diary entry</a>
  </div>
  <!-- Loading Div -->
  <div *ngIf="!siteScaffoldDiaries" class="empty-state-placeholder-large">
    <!-- Show spinner if we are waiting for the scaffold diary data HTTP request. -->
    <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
    <h4>Loading Diary Data...</h4>
  </div>
  <div *ngIf="siteScaffoldDiaries && siteScaffoldDiaries.length">
    <div class="row margin-bottom">
      <div class="col-md-12">
        <h2 class="inline-block">Diary Entries</h2>
        <div class="col-xs-3 col-md-3 ss-toolbar text-right pull-right">
          <a class="btn button-default button-rounded" role="button" (click)="onAddSiteDiary()">
            <i class="fa fa-plus"></i><span>add site diary entry</span>
          </a>
        </div>
      </div>
    </div>
    <div class="ss-container">
      <div class="row margin-bottom">
        <form name="filtersForm" [formGroup]="filtersForm">
          <div class="col-md-3 col-xs-5">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input class="form-control" type="text" placeholder="Search..." formControlName="searchTerm">
            </div>
          </div>
          <div class="col-md-6 col-xs-5">
            <ul class="card-nav">
              <li *ngFor="let cat of diaryCategories" (click)="toggleCategory(cat)"
                [ngClass]="{ 'selected' : cat.selected }">
                <a>
                  <hub-colour-coded-title [text]="cat.Title" [colourGeneration]="cat.DiaryCategoryId">
                  </hub-colour-coded-title>
                </a>
              </li>
            </ul>
          </div>
        </form>
        <hub-diary-categories class="pull-right"></hub-diary-categories>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ngx-datatable #siteDiaryTable class="table diary-list-table" [rows]="filteredScaffoldDiaries"
            [columnMode]="'flex'" [footerHeight]="50" [headerHeight]="40" [rowHeight]="'auto'"
            [sorts]="[{prop: 'Type', dir: 'desc'},{prop: 'When', dir: 'desc'}]" [limit]="10"
            [messages]="{emptyMessage: ''}" [scrollbarH]="true" (activate)="navigateTo($event)">
            <ngx-datatable-column [minWidth]="140" name="Type" prop="Type" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p *ngIf="row.Type === diaryDataTypes.ScaffoldDiary" class="scaffold-col-number"><i
                    class="fa fa-hashtag text-positive margin-right"></i>{{ row.Label }}</p>
                <p *ngIf="row.Type === diaryDataTypes.SiteDiary" class="scaffold-col-number"><i
                    class="fa fa-building text-positive margin-right"></i>{{ row.Label }}</p>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Category" [minWidth]="130" prop="DiaryCategoryName" [flexGrow]="2">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <hub-colour-coded-title [colourGeneration]="row.DiaryCategoryId"></hub-colour-coded-title>
                <span class="scaffold-col-category">{{ row.DiaryCategoryName }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Notes" prop="Notes" [flexGrow]="3" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.Notes }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [minWidth]="163" name="Attachments" prop="Attachments" [sortable]="false"
              [flexGrow]="4">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="media-count" *ngIf="row.ImageCount">
                  <i class="fa fa-image"></i> {{row.ImageCount}}</span>
                <span class="media-count" *ngIf="row.AudioCount">
                  <i class="fa fa-headphones"></i> {{row.AudioCount}}</span>
                <span class="media-count" *ngIf="row.VideoCount">
                  <i class="fa fa-video-camera"></i> {{row.VideoCount}}</span>
                <span class="media-count" *ngIf="row.TranscriptionCount">
                  <i class="fa fa-file-text"></i> {{row.TranscriptionCount}}</span>
                <span class="media-count" *ngIf="row.PDFCount">
                  <i class="fa fa-file-pdf"></i> {{row.PDFCount}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Who" prop="OwnerName" [flexGrow]="3">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.OwnerName }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="When" prop="When" [flexGrow]="3">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.When | date : 'MMM d, y, HH:mm' }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
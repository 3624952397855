<div *ngIf="isLoading" class="empty-state-placeholder-large">
    <!-- Show spinner if we are waiting for the scaffold data HTTP request. -->
    <i class="fa fa-circle-notch fa-pulse loading-circle fa-2x"></i>
    <h4>Loading Estimate Data...</h4>
</div>
<div class="ngx-datatable-no-hover" *ngIf="!isLoading" id="estimates-table">
    <ngx-datatable 
        #estimatesTable
        class="material" 
        [rows]="rows" 
        [columns]="columns" 
        [columnMode]="ColumnMode.force"
        [headerHeight]="50" 
        [footerHeight]="50" 
        rowHeight="auto" 
        [externalPaging]="true" 
        [externalSorting]="true" 
        [count]="page.TotalRecords"
        [offset]="page.CurrentPage - 1" 
        [limit]="page.PageSize" 
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        (sort)="onSort($event)"
        >

        <!-- All Columns -->
        <ngx-datatable-column *ngFor="let col of columns" [name]="col.name" [prop]="col.prop" [sortable]="col.sortable">
            <ng-template #defaultCell let-row="row" let-column="column" ngx-datatable-cell-template>
                <div *ngIf="column.prop === 'Status'">
                    <div *ngIf="column.prop === 'Status'">
                        <ng-container *ngIf="row.PostContractSiteId">
                            <div
                                *ngIf="row.SiteStatusDescription === 'Open'">
                                <span class="badge bg-positive">Live</span>
                            </div>
                            <div *ngIf="row.SiteStatusDescription === 'Closed'">
                                <span class="badge bg-secondary">Completed</span>
                            </div>
                        </ng-container>

                        <div *ngIf="!row.PostContractSiteId">
                            <ng-select (change)="statusChanges($event, row['ProjectId'])" [clearable]="false"
                                [items]="allStatuses" bindLabel="Description" bindValue="Code"
                                [(ngModel)]="row[col.prop]" appendTo="#estimates-table">
                            </ng-select>
                        </div>
                    </div>

                </div>
                <div *ngIf="column.prop === 'TenderedPrice'">
                    <p>{{ row[col.prop] | currency: 'GBP':true:'1.2-2' }}</p>
                </div>

                <div *ngIf="column.prop === 'ProjectStartDate'">
                    <p>{{ row[col.prop] | date: 'dd MMM yyyy' }}</p>
                </div>
                <div *ngIf="col.prop !== 'Status' && col.prop !== 'ProjectStartDate' && col.prop !== 'TenderedPrice'">
                    <p>{{ row[col.prop] }}</p>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- Actions Column -->
        <ngx-datatable-column name="Actions" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="start-btn"
                    *ngIf="row.PostContractSiteId === null && matchEstimateStatus(row.Status) === 'Won' && row.SiteStatusDescription !== 'Closed'"
                    (click)="onStart(row)">Start</button>
                    <ng-container *ngIf="row.PostContractSiteId != null">
                        <button [ngClass]="{'closed-site-completed-btn': row.SiteStatusDescription === 'Closed'}"
                            class="completed-btn"
                            *ngIf="matchEstimateStatus(row.Status) === 'Completed' && row.SiteStatusDescription !== 'Closed'"
                            (click)="onCompleted(row)">Go to Site</button>
                        <button class="in-progress-btn" [ngClass]="{'closed-site-btn': row.SiteStatusDescription === 'Closed'}"
                            *ngIf="(matchEstimateStatus(row.Status) !== 'Completed' && matchEstimateStatus(row.Status) !== 'Won') || (row.SiteStatusDescription === 'Open' && matchEstimateStatus(row.Status) !== 'Completed')"
                            (click)="onInProgress(row)">Go to Site</button>
                    </ng-container>
            </ng-template>
        </ngx-datatable-column>

        <!-- Footer -->
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                <div class="total-footer">
                    <div>
                        Total: {{rowCount | number}} Records / {{page.TotalPages | number}} Pages
                    </div>
                </div>
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)" (change)="handlePageChange($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>

    </ngx-datatable>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hub-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
/**
 * This component is used to display the page displayed when there is an error on the website.
 */
export class GenericErrorComponent {
  /** Stores the error message that is sent via the route */
  errorMessage: string;
  /** Stores the error code that is sent via the route */
  errorCode: string;

  constructor(private route: ActivatedRoute) { }


  /**
   * Initializes the component.
   * Subscribes to the query parameters and retrieves the error message and error code.
   * Logs the error message and error code to the console.
   */
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params['errorMessage'];
      this.errorCode = this.getErrorMessage(params['errorCode']);
      console.log(this.errorMessage, this.errorCode)
    });
  }

  /**
   * Gets the error message based on the error code.
   * @param errorCode - The error code.
   * @returns The corresponding error message.
   */
  getErrorMessage(errorCode: string): string {
    switch (Number(errorCode)) {
      case 400:
        return '400 Bad Request';
      case 401:
        return '401 Unauthorized';
      case 403:
        return '403 Forbidden';
      case 404:
        return '404 Not Found';
      case 500:
        return '500 Internal Server Error';
      default:
        return errorCode.toString();
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserIdentityService } from '../authentication';

// Route guard for ensuring that the user is not logged in.
@Injectable()
export class NotAuthenticatedGuard implements CanActivate {

  constructor(
    private userIdentityService: UserIdentityService,
    private router: Router
  ) { }

  canActivate(): boolean {
    const authenticated = this.userIdentityService.isAuthenticated();

    if (authenticated) {
      this.router.navigate(['/']);
    }
    return !authenticated;
  }

}

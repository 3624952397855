import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { StaffDetailsWebAppData } from 'app/core/hub-api';

import { LoggedInStaffService } from '../services/logged-in-staff-service';

// Resolve the data for the currently logged in staff member.
@Injectable()
export class LoggedInStaffResolver implements Resolve<StaffDetailsWebAppData> {

  constructor(private loggedInStaffService: LoggedInStaffService) { }

  resolve(): Observable<StaffDetailsWebAppData> {
    return this.loggedInStaffService.loggedInStaffQuery(true);
  }
}

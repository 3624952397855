import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {
  ContractCommandService,
  ContractData,
  CustomerWebAppData,
  EditContractInspectionStatusCommand,
  InspectionStatusEnumEnum,
  SiteWebAppData,
  StaffDetailsWebAppData,
} from 'app/core/hub-api';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { InspectionStatusModalContext } from '../../../../models/inspection-status-modal-context';
import { ContractInspectionStatusQueryService } from '../../../../services/contract-inspection-status-query.service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { forkJoin, Subject } from 'rxjs';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import dayjs from 'dayjs/esm';
import { DatePickerSelectData } from 'app/shared/models/date-picker-select-data';
/**
 * Modal to pause, resume or stop inspections for a contract
 */
@Component({
  selector: 'hub-inspection-status-modal',
  templateUrl: './inspection-status-modal.component.html',
  styleUrls: ['./inspection-status-modal.component.scss']
})
export class InspectionStatusModalComponent extends FormComponent implements OnInit {
  /** Stores the passed in values from where it was called */
  context: Partial<InspectionStatusModalContext>;
  /** Stores the pause mode */
  pauseMode: boolean;
  /** Stores the stop mode */
  resumeMode: boolean;
  /** Stores the resume mode */
  stopMode: boolean;
  /** Stores the site data */
  site: SiteWebAppData;
  /** Stores the customers */
  customers: CustomerWebAppData[];
  /** Stores the contract data */
  contract: ContractData;
  /** Stores the staff details */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores the selected customer name */
  customerName: string;
  /** Stores the modal title */
  title: string;
  /** Stores the modal text */
  text: string;
  /** Stores the modal save button text */
  saveText: string;
  /** Stores the modal cancel button text */
  cancelText: string;
  /** Stores the form */
  form: UntypedFormGroup;
  /** Stores the reason form control */
  reasonFormControl: UntypedFormControl;
  /** Stores the resume date form control */
  resumeDateFormControl: UntypedFormControl;
  /** Stores the resume date picker options */
  datePickerOptions: DatePickerOptions;
  /** Stores contract inspection status */
  contractInspectionStatus: InspectionStatusEnumEnum;
  /** Stores the validations messages to present on the UI */
  validationMessages = {
    reason: {
      required: errorMessages.pauseInspectionsReasonRequired,
    },
    resumeDate: {
      required: errorMessages.pauseInspectionsResumeDateRequired,
    }
  };

  constructor(
    public bsModalRef: BsModalRef,
    public contractCommandService: ContractCommandService,
    public contractInspectionStatusQueryService: ContractInspectionStatusQueryService,
    public siteScaffoldQueryService: SiteScaffoldQueryService,
    public siteDetailQueryService: SiteDetailQueryService,
    public modalService: BsModalService,
  ) { super(); }

  /**
   * Initialises the modal, sets the initial values and detects which button was clicked.
   * @returns {void}
   */
  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.pauseMode = this.context.pauseMode;
    this.stopMode = this.context.stopMode;
    this.resumeMode = this.context.resumeMode;
    this.customers = this.context.customers;
    this.site = this.context.site;
    this.contract = this.context.contract;
    this.loggedInStaff = this.context.loggedInStaff;

    this.customerName = this.customers.find(c => c.CustomerId === this.contract.CustomerId).CustomerName;

    if (this.pauseMode) {
      this.initPauseMode();
    } else if (this.resumeMode) {
      this.initResumeMode();
    } else if (this.stopMode) {
      this.initStopMode();
    }
    super.ngOnInit();
  }

  /**
   * This method is called when the user clicks the 'Pause' button on the modal.
   * @returns {void}
   */
  private initPauseMode(): void {
    this.reasonFormControl = new UntypedFormControl('', [Validators.required]);
    this.resumeDateFormControl = new UntypedFormControl(null, [Validators.required]);

    this.form = new UntypedFormGroup({
      reason: this.reasonFormControl,
      resumeDate: this.resumeDateFormControl,
    });
    this.datePickerOptions = {
      ranges: {},
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      minDate: dayjs().add(1, 'day'),
    };
    this.title = 'Pause Inspections?';
    this.text = `This will pause all the scheduled weekly inspections for ${this.contract.ContractName}(${this.contract.ContractReference}) on ${this.site.SiteName}.`;
    this.contractInspectionStatus = InspectionStatusEnumEnum.Paused;
    this.saveText = 'Save';
    this.cancelText = 'Cancel';
  }

  /**
   * This method is called when the user clicks the 'Resume' button on the modal.
   * @returns {void}
   */
  private initResumeMode(): void {
    this.form = new UntypedFormGroup({});
    this.title = 'Resume Inspections?';
    this.text = `Are you sure you want to resume inspections for ${this.contract.ContractName}(${this.contract.ContractReference}) on ${this.site.SiteName}?`;
    this.contractInspectionStatus = InspectionStatusEnumEnum.Normal;
    this.saveText = 'Yes resume inspections';
    this.cancelText = 'No I\'ve changed my mind';
  }

  /**
   * This method is called when the user clicks the 'Stop' button on the modal.
   * @returns {void}
   */
  private initStopMode(): void {
    this.form = new UntypedFormGroup({});
    this.title = 'Stop Inspections?';
    this.text = `Are you sure you want to stop inspections for ${this.contract.ContractName}(${this.contract.ContractReference}) on ${this.site.SiteName}?
     Only do this if you are no longer contracted to carry out the weekly inspections.`;
    this.contractInspectionStatus = InspectionStatusEnumEnum.Stopped;
    this.saveText = 'Yes stop inspections';
    this.cancelText = 'No I\'ve changed my mind';
  }

  /** 
   * This method is called whenever the user changes the date on the date picker
   * for the inspection status date.
   * @param date The date selected by the user.
   * @returns {void}
   */
  public onInspectionStatusDateChanged(date: DatePickerSelectData): void {
    if (date.startDate == null) return this.form.controls.resumeDate.setValue(null);
    this.resumeDateFormControl.setValue(date);
  }

  /**
   * Checks the validation for the date picker.
   */
  private checkDatePickerValidation(): void {
    if (this.pauseMode && this.form.controls.resumeDate.value == null) {
      this.resumeDateFormControl.setErrors({ required: true });
    }
  }

  /**
   * This method is called when the user clicks the save button on the modal.
   * @param formValues 
   * @returns {void}
   */
  public onSubmit(formValues): void {
    this.checkDatePickerValidation();
    if (this.pauseMode && !this.validateForm()) return;
    this.saveInProgress = true;

    const command: EditContractInspectionStatusCommand = {
      ContractId: this.contract.ContractId,
      ContractInspectionStatus: {
        State: this.contractInspectionStatus,
        Reason: formValues.reason,
        ResumeDate: formValues.resumeDate
          ? formValues.resumeDate.startDate.toISOString()
          : undefined,
      },
      OwnerId: this.loggedInStaff.StaffId,
      OffsetFromUtc: moment().utcOffset()
    };
    this.contractCommandService.EditContractInspectionStatusCommand(command)
      .subscribe({
        next: () => {
          forkJoin([this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId),
          this.contractInspectionStatusQueryService.contractInspectionStatusQuery(false, this.site.SiteId)]).subscribe({
            next: () => {
              this.saveInProgress = false;
              this.bsModalRef.hide();
            },
            error: this.serverErrorCallback
          });
        },
        error: this.serverErrorCallback
      });
  }
}

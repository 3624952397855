<div *ngIf="site" class="ss-container site-details-areas">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="filtersForm" role="form" name="filtersForm">
        <h2 class="inline-block margin-right"><span
            class="text-large-highlight">{{filteredAreas.length}}</span>Area<span
            *ngIf="filteredAreas.length !== 1">s</span></h2>
        <h2 class="inline-block"><span
            class="text-large-highlight">{{site.NumberOfNonDismantledScaffolds}}</span>Scaffold<span
            *ngIf="site.NumberOfNonDismantledScaffolds !== 1">s</span></h2>
        <div class="show-closed" *ngIf="anyDeletedAreas">
          <div class="round-checkbox round-checkbox-medium inline-block">
            <input type="checkbox" value="all" formControlName="showDeletedAreas" id="showDeletedAreas">
            <label for="showDeletedAreas"></label>
          </div>
          <span class="round-checkbox-label">Show deleted areas</span>
        </div>
        <div class="ss-toolbar pull-right">
          <hub-permission permissions="AddEditSites">
            <a class="btn button-default button-rounded" (click)="onAddArea()"><i class="fa fa-plus"></i><span>add
                area</span></a>
          </hub-permission>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12 all-areas">
          <table class="table table-borderless site-area-table">
            <tbody>
              <tr *ngFor="let area of filteredAreas">
                <td class="area-name-col no-padding-left"><a class="text-bold" (click)="onEditArea(area)"><span
                      [ngClass]="{'text-line-through': area.Deleted}">
                      <i class="fa fa-warning text-assertive"
                        *ngIf="area.Deleted && area.NumberOfScaffolds > 0"></i>{{area.AreaName}}</span></a>
                </td>
                <td class="area-scaffold-col">{{area.NumberOfScaffolds}} Scaffold<span
                    *ngIf="area.NumberOfScaffolds !== 1">s</span></td>
                <hub-permission permissions="AddEditSites">
                  <td class="area-edit-col">
                    <a (click)="onEditArea(area)"><i class="fa fa-regular fa-pen-to-square icon margin-right"></i></a>
                  </td>
                </hub-permission>
              </tr>
            </tbody>
            <tfoot *ngIf="errorMessage">
              <tr>
                <td colspan="3" class="text-center"><i class="fa fa-warning text-assertive"></i> <strong>{{ errorMessage
                    }}</strong></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
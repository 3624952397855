<form name="staffForm" *ngIf="!isLoading" [formGroup]="form">
  <hub-modal-form-header [title]="title"(close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <div id="add-staff-form-account-inactive-alert" class="alert alert-warning" role="alert" *ngIf="areFormFieldsDisabled">
      <strong>
        <i class="fa fa-warning"></i> Warning:</strong> Account is inactive. Please re-open the staff/profile if you would like to make changes
    </div>
    <fieldset>

      <hub-form-group id="add-staff-form-staff-name" label="Name" name="staffName" [control]="staffNameFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="staffName" (blur)="generateQuickId($event)" ngxTrim [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-company-position" label="Company Position" name="companyPosition"
        [control]="companyPositionFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="companyPosition" [maxlength]="maxLength50" ngxTrim  [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-quick-id" *ngIf="roleIdFormControl.value !== roleIds.scaffolder" label="Quick ID" name="quickId"
        [control]="quickIdFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="quickId" (blur)="isExistingQuickId($event)" ngxTrim [attr.disabled]="areFormFieldsDisabled">
        <div class="text-error" *ngIf="quickIdExist"><i class="fa fa-warning"></i>This QuickId already exists.</div>
      </hub-form-group>

      <hub-form-group id="add-staff-form-role-id" *ngIf="!profileMode" label="Role" name="roleId" [control]="roleIdFormControl"
        [validationErrors]="validationErrors">
        <hub-dropdown icon="fa fa-id-badge text-positive" formControlName="roleId" [items]="staffRoles" idProperty="RoleId" titleProperty="RoleName"
          (valueChanges)="onSelectedRoleChanged($event)" placeholder="Choose a role" [multiselect]="false" [clearable]="false" [readonly]="!isformEditable"></hub-dropdown>
        <div *ngIf="changeRoleToScaffolder" class="text-warning"><i class="fa fa-warning"></i> Changing role from
          {{staff.Roles[0]}} to Scaffolder. User will no longer be able to login.</div>
      </hub-form-group>

      <span
        *ngIf="!profileMode && roleIdFormControl.value !== roleIds.scaffolder && roleIdFormControl.value !== roleIds.payrollOfficer">
        <hub-form-group *ssFeatureFlag="'variation'" id="add-staff-form-can-estimate">
          <label for="CanEstimate" class="control-label text-left">Can estimate own works?
            <i class="fa fa-info-circle pull-right text-positive"
              tooltip="This will allow the user to submit variations without having to email an estimator"
              placement="right"></i>
          </label>
          <div class="pull-right">
            <div class="radio radio-inline">
              <label>
                <input id="add-staff-form-can-estimate-yes" formControlName="canEstimate" [value]="true" type="radio" [attr.disabled]="areFormFieldsDisabled"> Yes
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input id="add-staff-form-can-estimate-no" formControlName="canEstimate" [value]="false" type="radio" [attr.disabled]="areFormFieldsDisabled"> No
              </label>
            </div>
          </div>
        </hub-form-group>
      </span>


      <hub-form-group id="add-staff-form-depots" *ngIf="roleIdFormControl.value !== roleIds.admin && !profileMode" label="Depots" name="depotIds"
        [control]="depotIdsFormControl" [validationErrors]="validationErrors">
        <hub-dropdown icon="fa fa-sitemap text-positive" formControlName="depotIds" [items]="depots" idProperty="SiteDepotId" titleProperty="DepotName"
          itemsToDisplay="2" [multiselect]="true" (valueChanges)="onSelectedDepotChanged($event)" [readonly]="!isformEditable"
          placeholder="Choose depots">
        </hub-dropdown>
      </hub-form-group>

      <hub-form-group id="add-staff-form-email" *ngIf="roleIdFormControl.value !== roleIds.scaffolder" label="Email Address" name="email"
        [control]="emailFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="email" ngxTrim [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-phone-number" label="Phone Number" name="phoneNumber" [control]="phoneNumberFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="text" formControlName="phoneNumber" ngxTrim [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-password" *ngIf="!editMode && roleIdFormControl.value !== roleIds.scaffolder" label="Password"
        name="password" [control]="passwordFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="password" formControlName="password" autocomplete="new-password" [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-update-password" *ngIf="editMode && roleIdFormControl.value !== roleIds.scaffolder" label="Update Password"
        name="password" [control]="passwordFormControl" [validationErrors]="validationErrors">
        <input class="form-control" type="password" formControlName="password" autocomplete="new-password" [attr.disabled]="areFormFieldsDisabled">
      </hub-form-group>

      <hub-form-group id="add-staff-form-confirm-password"
        *ngIf="passwordFormControl.value && passwordFormControl.valid && roleIdFormControl.value !== roleIds.scaffolder"
        label="Confirm Password" name="confirmPassword" [control]="confirmPasswordFormControl"
        [validationErrors]="validationErrors">
        <input class="form-control" type="password" formControlName="confirmPassword" autocomplete="new-password" >
      </hub-form-group>

      <!-- Delete/Reopen Account -->
      <div *ngIf="editMode && !profileMode" class="modal-options">
        <!--- Delete Account -->
        <a id="add-staff-form-delete-account" class="pull-left text-danger animate-fade" (click)="deleteStaff = true"
          *ngIf="!staff.Deleted && !deleteStaff">
          <i class="fa fa-times"></i> Delete account</a>
        <!--- Undo Account -->
        <span class="pull-left animate-fade" *ngIf="!staff.Deleted && deleteStaff">
          <i class="fa fa-check"></i> The account will be deleted and
          <strong>will be removed from all sites</strong>. Click
          <a id="add-staff-form-cancel-delete-account" (click)="deleteStaff = false" class="text-danger">undo</a> if this was a mistake!</span>
        <!--- Reopen Account -->
        <a id="add-staff-form-reopen-account" class="pull-left text-danger animate-fade" (click)="deleteStaff = false"
          *ngIf="staff.Deleted && deleteStaff">
          <i class="fa fa-undo"></i> Reopen account</a>
        <!--- Undo Reopen -->
        <span class="pull-left animate-fade" *ngIf="staff.Deleted && !deleteStaff">
          <i class="fa fa-check"></i> The account will be reopened. Click
          <a id="add-staff-form-reopen-delete-account" (click)="deleteStaff = true" class="text-danger">undo</a> if this was a mistake!</span>
      </div>
    </fieldset>
    <hub-modal-form-footer id="add-staff-form-footer" [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
     (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>

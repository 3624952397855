import { CustomersQueryService } from '../services/customers-query.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerEndpointService, CustomerQuery, CustomerWebAppData } from 'app/core/hub-api';

@Injectable()
export class CustomersResolver implements Resolve<CustomerWebAppData[]> {

  constructor(private customersQueryService: CustomersQueryService) { }

  resolve(): Observable<CustomerWebAppData[]> {
    return this.customersQueryService.customersQuery(true);
  }
}

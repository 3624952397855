import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as hello from 'hellojs';
import { HelloJSLoginOptions } from 'hellojs';

// Angular wrapper around hello.js library.
// Based on ngx-hello https://github.com/teamcancode/ngx-hellojs
@Injectable()
export class HellojsService {

  utils = hello.utils;

  init(serviceAppIds: { [id: string]: string }, options?: HelloJSLoginOptions) {
    return hello.init(serviceAppIds, options);
  }

  getAuthResponse(network: string): any {
    return hello.getAuthResponse(network);
  }

  login(network: string, options?: {}): Observable<{ network, authResponse }> {
    const loginPromise = hello.login(network, options, () => {});
    return new Observable(observer => {
      loginPromise.then((data: { network, authResponse }) => {
        observer.next(data);
        observer.complete();
      }, (error: { error: { code, message }, network }) => {
        observer.error(error);
      });
    });
  }

  logout(network: string, options?: {}): Observable<{ network }> {
    const logoutPromise = hello.logout(network, options, () => {});
    return new Observable(observer => {
      logoutPromise.then((data: { network }) => {
        observer.next(data);
        observer.complete();
      }, (error: { error: { code, message }, network }) => {
        observer.error(error);
      });
    });
  }

  // api(network: string, path: string, method?: string, configs?: Object): Observable<{ Object }> {
  //   return new Observable(observer => {
  //     hello(network).api(path, method, configs).then((data: any) => {
  //       observer.next(data);
  //       observer.complete();
  //     }, (error: { error: { error: { code, message }, network }, network }) => {
  //       observer.error(error);
  //     });
  //   });
  // }

    // on(event: string, callback: any) {
  //   hello.on(event, callback);
  // }

  // off(event: string, callback: any) {
  //   hello.off(event, callback);
  // }
}

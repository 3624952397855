/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';
import { AddScaffoldDiaryMediaModalContext } from 'app/sites/models/add-scaffold-diary-media-modal-context';
import { forkJoin } from 'rxjs';
import {
  CreateScaffoldDiaryMediaCommand, ExternalMedia, ScaffoldCommandService,
  DiaryData, DiaryDataTypeEnum, SiteCommandService, CreateSiteDiaryMediaCommand, DiaryQuery
} from 'app/core/hub-api';
import { ImageUploadService } from '../../../../../core/services/image-upload.service';
import { uploadMinAllowedContentLength, uploadMaxAllowedContentLength } from '../../../../../shared/values/file-values';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { fileExtensionRegex } from 'app/shared/regex/file-extension.regex';
import { DiaryQueryService } from 'app/sites/services/diary-query-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

/**
 * Add Scaffold Diary Media Modal
 * Shows a modal with an input to upload scaffold diaries
 *
 * @export
 * @class AddScaffoldDiaryMediaModalComponent
 * @extends FormComponent
 * @implements {OnInit}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-diary-media-modal',
  templateUrl: './add-diary-media-modal.component.html',
  styleUrls: ['./add-diary-media-modal.component.scss']
})
export class AddScaffoldDiaryMediaModalComponent extends FormComponent implements OnInit, OnDestroy {
  /** Stores all parameters sent to this modal */
  context: Partial<AddScaffoldDiaryMediaModalContext>;
  /** Form Group */
  form: UntypedFormGroup;
  /** validation messages structure required by the ModalFormComponent */
  validationMessages: { [key: string]: { [key: string]: string } };
  /** Diary data */
  scaffoldDiary: DiaryData;
  /** Site Reference of the selected site (e.g. 00001) */
  siteReference: string;
  /** Selected filefrom input */
  file: File;
  /** Detects if the form is being saved and triggers the spinning icon on the save button */
  saveInProgress: boolean;
  /** Gets the file error message */
  fileErrorMessage: string;
  /** Stores the media name without extension */
  mediaName: string;
  /** gets the scaffold id from modal parameters */
  scaffoldId: string;
  /** Controls the ngModel for the input file uploader */
  sizeRestrictedFile: any;
  /** Gets all Diary Types */
  diaryType: DiaryDataTypeEnum;
  /** Stores the maximum and minimum file size */
  public sizeRestrictions: { min: number; max: number; } = {
    min: uploadMinAllowedContentLength,
    max: uploadMaxAllowedContentLength
  };
  /** Stores if the file is over the required size */
  oversizeFile: boolean;
  /** Stores if the file has the wrong format */
  wrongFormatFile: boolean;
  /** List of all allowed file formats */
  allowedFileFormats = ['.jpg', '.jpeg', '.png', '.exif', '.tiff', '.gif', '.bmp', '.pdf', 'mpeg', '.mp4', '.avi', '.wmv', '.mov'];
  /** Decides if the save button is locked */
  lockSaveButton = true;

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private scaffoldCommandService: ScaffoldCommandService,
    private siteCommandService: SiteCommandService,
    private scaffoldDiaryQueryService: SiteScaffoldDiaryQueryService,
    private siteScaffoldDiaryQueryService: SiteScaffoldDiaryQueryService,
    private imageUploadService: ImageUploadService,
    private diaryQueryService: DiaryQueryService,
    public modalService: BsModalService,
  ) {
    super();
  }

  /**
   * Component initialization
   *
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  public ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.scaffoldDiary = this.context.diary;
    this.siteReference = this.context.siteReference;
    this.scaffoldId = this.context.scaffoldId;
    this.diaryType = this.context.diaryType;
  }

  /**
   * Submit addition of media data to diary
   *
   * @returns {void}
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  public onSubmit(): void {
    const formValid = !!this.file;
    if (formValid) {
      this.saveInProgress = true;
      (this.diaryType === DiaryDataTypeEnum.ScaffoldDiary) ? this.uploadScaffoldMedia() : this._uploadSiteMedia();
    } else {
      this.fileErrorMessage = 'Please select a file to upload.';
    }
  }

  /**
   * Uploads media to scaffold's blob
   * Location: Scaffold/Id/ScaffoldDiary/DiaryId/FileName
   *
   * @private
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  private uploadScaffoldMedia(): void {
    const path = `Scaffold/${this.scaffoldId}/ScaffoldDiary/${this.scaffoldDiary.DiaryId}/${this.file.name}`;
    const command: CreateScaffoldDiaryMediaCommand = {
      ScaffoldId: this.scaffoldId,
      ScaffoldDiaryId: this.scaffoldDiary.DiaryId,
      Media: {
        MimeType: this.file.type,
        Path: path,
        Title: this.mediaName
      } as ExternalMedia
    };
    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = this.scaffoldDiary.DiaryId;
    diaryQuery.DiaryType = this.diaryType;

    const observableImageUpload$ = forkJoin([
      this.imageUploadService.uploadImage(this.file, path),
      this.scaffoldCommandService.CreateScaffoldDiaryMediaCommand(command)
    ]);
    observableImageUpload$.subscribe(() => {
      const observableDiaryQuery$ = forkJoin([
        this.scaffoldDiaryQueryService.siteScaffoldDiaryQuery(false, this.scaffoldDiary.DiaryId),
        this.siteScaffoldDiaryQueryService.siteScaffoldDiaryQuery(false, this.siteReference),
        this.diaryQueryService.getDiaryData(diaryQuery)
      ]);
      observableDiaryQuery$.subscribe(() => {
        this.saveInProgress = false;
        this.bsModalService.setDismissReason(this.file as any);
        this.bsModalRef.hide();
      });
    }, this.serverErrorCallback);

  }

  /**
   * Uploads media to site's blob
   * Location: Site/Id/SiteDiary/DiaryId/FileName
   *
   * @private
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  private _uploadSiteMedia(): void {
    const path = `Site/${this.scaffoldId}/SiteDiary/${this.scaffoldDiary.DiaryId}/${this.file.name}`;
    const command: CreateSiteDiaryMediaCommand = {
      DiaryId: this.scaffoldDiary.DiaryId,
      Media: [{
        MimeType: this.file.type,
        Path: path,
        Title: this.mediaName
      } as ExternalMedia]
    };

    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = this.scaffoldDiary.DiaryId;
    diaryQuery.DiaryType = this.diaryType;

    const observableImageUpload$ = forkJoin([
      this.imageUploadService.uploadImage(this.file, path),
      this.siteCommandService.CreateSiteDiaryMediaCommand(command)
    ]);

    observableImageUpload$.subscribe(() => {
      const observableDiaryQuery$ = forkJoin([
        this.scaffoldDiaryQueryService.siteScaffoldDiaryQuery(false, this.scaffoldId),
        this.siteScaffoldDiaryQueryService.siteScaffoldDiaryQuery(false, this.siteReference),
        this.diaryQueryService.getDiaryData(diaryQuery)

      ]);
      observableDiaryQuery$.subscribe(() => {
        this.saveInProgress = false;
        this.bsModalService.setDismissReason(this.file as any);
        this.bsModalRef.hide();
      });
    }, this.serverErrorCallback);

  }

  /**
   * Checks if the file is valid (size and format)
   *
   * @param {File} file
   * @returns {boolean}
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  private _isFileValid(file: File): boolean {
    // Remove the file extension.
    const fileExtension = file.name.split('.').pop().toLowerCase();
    // in case the file format is not accepted it will add it to the error list
    this.wrongFormatFile = !this.allowedFileFormats.includes(`.${fileExtension}`);
    this.oversizeFile = file.size > this.sizeRestrictions.max;
    // Validates if file format is accepted
    if (this.wrongFormatFile) {
      this.fileErrorMessage = `The file format is not accepted.`;
      this.saveInProgress = false;
      this.lockSaveButton = true;
      return false;
    }
    // Validates if file is oversize
    if (this.oversizeFile) {
      this.fileErrorMessage = errorMessages.fileSizeErrorMsg;
      this.saveInProgress = false;
      this.lockSaveButton = true;
      return false;
    }
    this.wrongFormatFile = false;
    this.oversizeFile = false;
    return true;
  }


  /**
   * Gets the file from the user's device
   *
   * @param {Event} event
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  public onFileSelected(event: Event): void {
    this.fileErrorMessage = undefined;
    this.lockSaveButton = false;
    const file = (event.target as any).files[0];
    this.mediaName = file.name.replace(fileExtensionRegex, '');
    this.file = file;
    this._isFileValid(file);
  }

  /**
   * Required by the AutoUnsubscribe
   *
   * @memberof AddScaffoldDiaryMediaModalComponent
   */
  public ngOnDestroy(): void { }

}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationEventsService } from './services/authentication-events.service';
import { OnlineSessionService } from './services/online-session.service';
import { UserIdentityService } from './services/user-identity.service';
import { AuthenticationModuleSettings } from './authentication-settings';
import { AUTHENTICATION_SETTINGS_TOKEN } from './authentication-settings-token';
import { HelloAuthService } from './services/hello-auth.service';
import { HellojsService } from './services/hellojs.service';
import { AUTH_SERVICE_TOKEN } from './auth-service-token';

// THIS MODULE SHOULD ONLY BE IMPORTED ONCE PER APPLICATION AS IT CONTAINS APPLICATION WIDE SINGLETONS.
@NgModule({
  declarations: [],
  exports: [],
})
export class AuthenticationModule {
  constructor() { }
  static forRoot(settingsFactory: () => AuthenticationModuleSettings): ModuleWithProviders<AuthenticationModule> {

    return {
      ngModule: AuthenticationModule,
      providers: [
        OnlineSessionService,
        UserIdentityService,
        AuthenticationEventsService,
        // Later we may want to provide other implementations of the authService.
        { provide: AUTH_SERVICE_TOKEN, useClass: HelloAuthService },
        { provide: AUTHENTICATION_SETTINGS_TOKEN, useFactory: settingsFactory },
        /* { provide: JWT_HELPER_TOKEN, useClass: JwtHelperService }, */
        HellojsService,
      ],
    };
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnInit, OnDestroy } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { flow, map, partialRight, sortBy } from 'lodash';
import { AddEditStaffModalContext } from '../../models/add-edit-staff-modal-context';
import { Observable, forkJoin, combineLatest } from 'rxjs';
import { StaffDetailQueryService } from '../../../core/services/staff-detail-query.service';
import { StaffsQueryService } from '../../../core/services/staffs-query.service';
import { AutoGenerateQuickIdService } from '../../../core/services/auto-generate-quickId.service';
import { UUID } from 'angular2-uuid';
import { errorMessages } from '../../../shared/values/error-messages';
import { matchControlValidator } from '../../validators/match-control-validator';
import { mustNotExistValidator } from '../../validators/must-not-exist.validator';
import { roleIds } from '../../../shared/values/role-ids';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { passwordControlRegex } from 'app/shared/regex/password.regex';
import { quickIdControlRegex } from 'app/shared/regex/quickId.regex';
import { environment } from 'environments/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { DepotsQueryService } from 'app/core/services/depots-query.service';
import { FormComponent } from '../form/form.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterService } from 'angular2-toaster';
import { LoggedInStaffService } from 'app/core/services/logged-in-staff-service';
import { ConfigurationData, RolesData, StaffDetailsWebAppData, StaffData, DepotData, StaffCommandService, CreateStaffCommand, EditStaffCommand } from 'app/core/hub-api';

/**
 * Add edit staff modal
 * Allows to add new users to SMART Manager
 *
 * @export
 * @class AddEditStaffModalComponent
 * @extends {ModalFormComponent<AddEditStaffModalContext>} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 * @implements {OnDestroy} Stops all subscriptions and closes the component
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-staff-modal',
  templateUrl: './add-edit-staff-modal.component.html',
  styleUrls: ['./add-edit-staff-modal.component.scss']
})
export class AddEditStaffModalComponent extends FormComponent implements OnInit, OnDestroy {
  /** Stores the all parameters sent to this modal */
  context: AddEditStaffModalContext;
  /** Detects if the form is edit or add */
  editMode: boolean;
  /** Detects if the logged in user is editing his own profile */
  profileMode: boolean;
  /** Detects if the quickId already exists */
  quickIdExist: boolean;
  /** Form Group control */
  form: UntypedFormGroup;
  /** Staff name input controller */
  staffNameFormControl: UntypedFormControl;
  /** Company Position */
  companyPositionFormControl: UntypedFormControl;
  /** Role dropdown controller */
  roleIdFormControl: UntypedFormControl;
  /** Can Estimate radio button controller */
  canEstimateFormControl: UntypedFormControl;
  /** Depots dropdown controller */
  depotIdsFormControl: UntypedFormControl;
  /** Email input controller */
  emailFormControl: UntypedFormControl;
  /** Phone number input controller */
  phoneNumberFormControl: UntypedFormControl;
  /** Password input controller */
  passwordFormControl: UntypedFormControl;
  /** Confirm Password input controller */
  confirmPasswordFormControl: UntypedFormControl;
  /** Quick Id input controller */
  quickIdFormControl: UntypedFormControl;
  /** The new value for staff.Deleted */
  deleteStaff: boolean;
  /** All validation messages for the respective form fields */
  validationMessages = {
    staffName: {
      required: errorMessages.required
    },
    roleId: {
      required: errorMessages.required
    },
    depotIds: {
      required: errorMessages.required
    },
    quickId: {
      pattern: errorMessages.invalidQuickId
    },
    email: {
      required: errorMessages.required,
      pattern: errorMessages.emailInvalid,
      mustNotExist: errorMessages.duplicateEmail
    },
    password: {
      required: errorMessages.required,
      pattern: errorMessages.passwordValidation,
      minlength: errorMessages.passwordMinLength
    },
    confirmPassword: {
      matchControl: errorMessages.passwordsMustMatch
    },
    companyPosition: {
      pattern: errorMessages.maxLength50
    },
  };
  /** Stores the configuration data */
  configuration: ConfigurationData;
  /** Stores all roles and excludes timesheet roles in case it is feature flagged */
  staffRoles: RolesData[];

  /** Stores all role ids available in SMART Manager */
  roleIds = roleIds;
  /** Stores the selected user */
  staff: StaffDetailsWebAppData;
  /** Stores all staff */
  staffs: StaffData[];
  /** Stores currently logged in user */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores all depots */
  depots: DepotData[];
  /** Stores all feature flags */
  featureFlags = environment.featureFlags as any;
  /** Detects if all data was loaded before showing the form */
  isLoading = true;
  maxLength50 = 50;

   /**
   * Gets the title of the modal (e.g. Edit Staff or Add Staff or Deleted Staff)
   */
   public get title(): string {
    const titleFirstWord = this.editMode ? (this.isformEditable) ? 'Edit' : 'Deleted' : 'Add';
    const titleSecondWord = this.profileMode ? 'Profile' : 'Staff';
    const titleThirdWord = this.editMode ? (this.isformEditable) ? '' : '- Read Only' : '';
    return `${titleFirstWord} ${titleSecondWord} ${titleThirdWord}`;
  }

  // GET property to determine if fields should be ediable on the UI
  public get areFormFieldsDisabled() : string | null {
    return (!this.isformEditable ? 'disabled' : null);
  }

  public get isformEditable() : boolean{
    return (this.staff.Deleted && !this.deleteStaff) || (!this.staff.Deleted);// && !this.deleteStaff) ;
  }

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private staffCommandService: StaffCommandService,
    private staffDetailQueryService: StaffDetailQueryService,
    private staffsQueryService: StaffsQueryService,
    private toasterService: ToasterService,
    private configurationQueryService: ConfigurationQueryService,
    private depotsQueryService: DepotsQueryService,
    private autoGenerateQuickIdService: AutoGenerateQuickIdService,
    private loggedInStaffService: LoggedInStaffService,

  ) {
    super();
  }

  /**
   * Component Initialisation
   *
   * @memberof AddEditStaffModalComponent
   */
  ngOnInit(): void {
    const obs$ = combineLatest(
      [this.configurationQueryService.configurationQuery(true),
      this.depotsQueryService.depotsQuery(false),
      this.staffsQueryService.staffsQuery(false),
      this.loggedInStaffService.loggedInStaffQuery(false)
      ]
    );
    obs$.subscribe(latest => {
      this.configuration = latest[0];
      this.depots = latest[1];
      this.staffs = latest[2];
      this.loggedInStaff = latest[3];

      const staffRoles = flow([map, partialRight(sortBy, (r: RolesData) => r.RoleName.toLowerCase())])(this.configuration.Roles);

      this.staffRoles = !this.featureFlags.timesheet
        ? staffRoles.filter(
          roles => roles.RoleId !== roleIds.contractsManager && roles.RoleId !== roleIds.scaffolder && roles.RoleId !== roleIds.payrollOfficer
        )
        : staffRoles;

      this.staff = this.staff || this.getNewStaff();
      this.profileMode = this.staff && this.staff.StaffId === this.loggedInStaff.StaffId;
      const titleFirstWord = this.editMode ? 'Edit' : 'Add';
      const titleSecondWord = this.profileMode ? 'Profile' : 'Staff';

      this.deleteStaff = this.staff.Deleted;

      this.generateForm();

      this.isLoading = false;
      super.ngOnInit();
    });
  }

  /**
   * Generates the form controls and validation
   *
   * @public this is required to be public so it can be used in unit tests
   * @memberof AddEditStaffModalComponent
   */
  public generateForm(): void {
    this.staffNameFormControl = new UntypedFormControl(this.staff.ContactName, [Validators.required]);

    this.roleIdFormControl = new UntypedFormControl(this.staff.RoleId, [Validators.required]);

    this.canEstimateFormControl = new UntypedFormControl(this.staff.CanEstimate);

    this.companyPositionFormControl = new UntypedFormControl(this.staff.CompanyPosition, [Validators.pattern(/^.{1,50}$/)]);

    const staffDepotIds = this.staff.SiteDepotIds.filter(id => this.depots.map(d => d.SiteDepotId).includes(id));
    const staffDepotValidators = this.staff.RoleId === roleIds.admin ? [] : [Validators.required];
    this.depotIdsFormControl = new UntypedFormControl(staffDepotIds, staffDepotValidators);

    const otherStaffEmails = this.staffs.filter(s => s.StaffId !== this.staff.StaffId).map(s => s.EmailAddress);
    this.emailFormControl = new UntypedFormControl(this.staff.EmailAddress, [
      Validators.required,
      Validators.pattern(emailAddressRegex),
      mustNotExistValidator(otherStaffEmails, false)
    ]);

    this.quickIdFormControl = new UntypedFormControl(this.staff.QuickId, [
      Validators.pattern(quickIdControlRegex)
    ]);

    this.phoneNumberFormControl = new UntypedFormControl(this.staff.PhoneNumber);
    this.passwordFormControl = this.editMode
      ? new UntypedFormControl('', Validators.pattern(passwordControlRegex))
      : new UntypedFormControl('', [Validators.required, Validators.pattern(passwordControlRegex)]);
    this.confirmPasswordFormControl = new UntypedFormControl('', matchControlValidator(this.passwordFormControl));
    this.form = new UntypedFormGroup({
      staffName: this.staffNameFormControl,
      companyPosition: this.companyPositionFormControl,
      quickId: this.quickIdFormControl,
      roleId: this.roleIdFormControl,
      canEstimate: this.canEstimateFormControl,
      depotIds: this.depotIdsFormControl,
      email: this.emailFormControl,
      phoneNumber: this.phoneNumberFormControl,
      password: this.passwordFormControl,
      confirmPassword: this.confirmPasswordFormControl
    });
  }

  /**
   * Return staff's first letter of first name and last name
   * e.g.- staff name is John Samuel and output should be JS
   *
   * @param {string} inputStr input value
   * @returns {string}
   * @memberof AddEditStaffModalComponent
   */
  getInitials(inputStr: string): string {
    let customerInitials = inputStr.match(/\b(\w)/g) as any;
    if (!customerInitials) {
      return '';
    }
    customerInitials = customerInitials.slice(0, 2);

    if (typeof customerInitials[0] !== 'undefined') {
      customerInitials[0] = customerInitials[0].toUpperCase();
    }
    if (typeof customerInitials[1] !== 'undefined') {
      customerInitials[1] = customerInitials[1].toLowerCase();
    }
    return customerInitials.join('');
  }

  /**
   * Returns the quickId with respect to different pattern
   * e.g. - staff name is John Samuel
   * pattern 01 : return staff initial(JS)
   * pattern 02 : return single digit number with staffInitial (JS1-JS9)
   * pattern 03 : return single letter with staffInitial (JSA-JSZ)
   * pattern 04 : return three digit number (111-999)
   * pattern 05 : return three letter alphabet (AAA-ZZZ)
   * pattern 06 : return empty string
   *
   * @param {*} event
   * @returns {string}
   * @memberof AddEditStaffModalComponent
   */
  public generateQuickId(event): string {
    let _quickId = '';
    if (event.target.value) {
      const _staffInitials = this.getInitials(event.target.value).toUpperCase();
      _quickId = this.autoGenerateQuickIdService.getQuickId(_staffInitials, this.staffs);
      this.quickIdFormControl.setValue(_quickId);
    }
    return _quickId;

  }

  /**
   * Detects if the quickId already exists
   *
   * @param {*} event input event
   * @returns {boolean}
   * @memberof AddEditStaffModalComponent
   */
  public isExistingQuickId(event): boolean {
    const otherQuickId = this.staffs.filter(s => s.QuickId !== this.staff.QuickId).map(s => s.QuickId);
    if (event.target.value) {
      this.quickIdExist = otherQuickId.includes(event.target.value);
      return this.quickIdExist;
    }
    return false;
  }

  /**
   * Creates a new staff object
   *
   * @private
   * @memberof AddEditStaffModalComponent
   */
  private getNewStaff = (): StaffDetailsWebAppData =>
    Object.assign(new StaffDetailsWebAppData(), {
      StaffId: UUID.UUID(),
      ContactName: '',
      RoleId: roleIds.admin,
      CanEstimate: false,
      QuickId: '',
      SiteDepotIds: [],
      EmailAddress: '',
      PhoneNumber: ''
    })

  /**
   * Detects if the user role has been changed to Scaffolder
   * This will decide if required to trigger a message on the UI
   *
   * @memberof AddEditStaffModalComponent
   */
  get changeRoleToScaffolder(): boolean {
    return this.editMode && this.staff.RoleId !== roleIds.scaffolder && this.roleIdFormControl.value === roleIds.scaffolder;
  }

  /**
   * Sets the user status as deleted and updates the validation
   *
   * @param {boolean} deleted
   * @memberof AddEditStaffModalComponent
   */
  public setStaffAsDeleted(deleted: boolean): void {
    this.deleteStaff = deleted;
    const validators = this.staff.Deleted && !this.deleteStaff ? [Validators.minLength(6), Validators.required] : [Validators.minLength(6)];
    this.passwordFormControl.setValidators(validators);
    this.passwordFormControl.updateValueAndValidity();
  }

  /**
   * Detects when the selected role and sets it to the form
   * applies validation accordingly
   * Controlled from the UI
   *
   * @param {string} roleId selected role id
   * @returns {void}
   * @memberof AddEditStaffModalComponent
   */
  public onSelectedRoleChanged(roleId: string): void {
    // If the staff is a Scaffolder and changes to other role it will require a new password
    if (this.staff.RoleId === roleIds.scaffolder && roleId !== roleIds.scaffolder) {
      this.passwordFormControl.setValidators([Validators.required, Validators.pattern(passwordControlRegex)]);
      this.passwordFormControl.updateValueAndValidity();
      this.confirmPasswordFormControl.setValidators(matchControlValidator(this.passwordFormControl));
      this.confirmPasswordFormControl.updateValueAndValidity();
    }
    // sets the roleId to the form
    this.form.controls.roleId.setValue(roleId);
    // Sets validation for admin - depots dropdown is hidden and does not need validation
    const validators = roleId === roleIds.admin ? [] : Validators.required;
    this.depotIdsFormControl.setValidators(validators);
    this.depotIdsFormControl.updateValueAndValidity();
  }

  /**
  * Detects when the selected depots and sets them to the form
  * Controlled from the UI
  *
  * @param {string} depotIds selected depot ids
  * @returns {void}
  * @memberof AddEditStaffModalComponent
  */
  public onSelectedDepotChanged(depotIds: string[]): void {
    this.form.controls.depotIds.setValue(depotIds);
  }

  /**
   * Checks if the roleId can estimate
   *
   * @memberof AddEditStaffModalComponent
   */
  public canEstimate = (roleId: string): boolean =>
    roleId !== roleIds.siteSupervisor &&
    roleId !== roleIds.safetyManager &&
    roleId !== roleIds.inspector &&
    roleId !== roleIds.scaffolder &&
    roleId !== roleIds.payrollOfficer

  /**
   * Gets the form values, checks for validation and submits to API
   * will use different endpoints for edit and add
   *
   * @param {*} formValues all form values
   * @memberof AddEditStaffModalComponent
   */
  public onSubmit(formValues): void {
    if ((formValues.roleId === roleIds.scaffolder && formValues.staffName && formValues.depotIds.length) || this.validateForm()) {
      this.saveInProgress = true;
      const command: CreateStaffCommand | EditStaffCommand = {
        ContactName: formValues.staffName,
        CompanyPosition: formValues.companyPosition,
        PhoneNumber: formValues.phoneNumber,
        RoleId: formValues.roleId,
        SiteDepotIds: formValues.depotIds,
        StaffId: this.staff.StaffId,
        EmailAddress: formValues.email,
        Password: formValues.password || undefined,
        CanEstimate: formValues.canEstimate,
        QuickId: formValues.quickId,
        Deleted: this.deleteStaff,
        RatesToMakeIneffective: null,
        RatesToAdd: null,
        RatesToDelete: null,
        EulaAgreedVersion: this.staff.EulaAgreedVersion
      };

      let result: Observable<any>;

      if (formValues.roleId === roleIds.scaffolder) {
        result = this.editMode
          ? this.staffCommandService.EditStaffNoAccessCommand(command)
          : this.staffCommandService.CreateStaffNoAccessCommand(command);
      } else {
        result = this.editMode ? this.staffCommandService.EditStaffCommand(command) : this.staffCommandService.CreateStaffCommand(command);
      }

      result.subscribe(() => {
        const obs$ = forkJoin([
          this.staffsQueryService.staffsQuery(false),
          this.staffDetailQueryService.staffDetailQuery(false, this.staff.StaffId)
        ]).subscribe(() => {
          this.saveInProgress = false;
          this.modalService.setDismissReason(this.staff.StaffId);
          this.bsModalRef.hide();
          this.toasterService.pop('success', `Staff ${this.editMode ? 'updated' : 'created'}`);
        });
      }, this.serverErrorCallback);
    }
  }

  /**
   * Required by the AutoUnsubscribe
   *
   * @memberof AddEditStaffModalComponent
   */
  public ngOnDestroy(): void { }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { groupBy, countBy, filter, Dictionary } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SiteDashBoardScaffoldInspectionData } from 'app/core/hub-api';
import { siteStatusIds } from '../../values/site-status-ids';
import { contractStatusIds } from '../../values/contract-status-ids';

@Component({
  selector: 'hub-seven-day-inspections-due-chart',
  templateUrl: './seven-day-inspections-due-chart.component.html',
  styleUrls: ['./seven-day-inspections-due-chart.component.scss']
})
export class SevenDayInspectionsDueChartComponent implements OnInit, OnChanges {

  objectKeys: any;
  inspectionsCountBySite: Dictionary<number>;
  readonly title = 'Inspections Due';
  readonly subtitle = 'Next 7 Days';
  totalNextWeek: number;
  selectedDay: string;

  inspectionsByDay: { [key: string]: SiteDashBoardScaffoldInspectionData[] };
  inspectionCountByDay: { [key: string]: number };

  @Input()
  inspectionData: SiteDashBoardScaffoldInspectionData[];

  @Input()
  startDate: Moment;

  @Input()
  hideList: boolean;

  daysOfWeek: { day: string, count: number }[];

  constructor() { }

  /**
   * Lifecycle hook that is called when any data-bound property of the component changes.
   * @param changes - An object containing the changed properties and their current and previous values.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['inspectionData'])) {
      this.updateChartData();
    }
  }

  /**
   * Initializes the component.
   * This lifecycle hook is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.updateChartData();
  }

  /**
   * Updates the chart data for the seven-day inspections due chart.
   * Removes inspections with closed site status or closed contract status from the inspection data.
   * Calculates the number of inspections due in the next week.
   * Groups inspections by day of the week and counts the number of inspections for each day.
   * Sets the days of the week and the selected day for the chart.
   */
  updateChartData(): void {
    this.inspectionData = filter(this.inspectionData, function (scaffold): boolean {
      return scaffold.SiteStatusId !== siteStatusIds.closed && scaffold.ContractStatusId !== contractStatusIds.closed;
    });
    this.startDate = this.startDate.clone().startOf('day');
    const endDate = this.startDate.clone().add(6, 'days').endOf('day');

    const inspectionsForWeek = this.inspectionData.filter(inspection => {
      const nextInspectionDate = moment(inspection.NextInspectionDate);
      return nextInspectionDate.isSameOrAfter(this.startDate) && nextInspectionDate.isBefore(endDate);
    });

    this.totalNextWeek = inspectionsForWeek.length;

    this.inspectionsByDay = groupBy(inspectionsForWeek, (inspection) => {
      return moment(inspection.NextInspectionDate).startOf('day').format('dd');
    });

    this.inspectionCountByDay = countBy(inspectionsForWeek, inspection => {
      return moment(inspection.NextInspectionDate).startOf('day').format('dd');
    });

    this.daysOfWeek = this.getDaysBetween(this.startDate, endDate);

    this.objectKeys = Object.keys;

    this.selectedDay = this.daysOfWeek[0].day;
  }

  getDaysBetween(start: Moment, end: Moment): any[] {
    const days = [];

    const currentDate = start.clone().startOf('day');
    const lastDate = end.clone().startOf('day');

    do {
      const day = currentDate.clone().format('dd');
      const countForDay = this.inspectionCountByDay[day] || 0;
      days.push({ day: day, count: countForDay });
    }
    while (currentDate.add(1, 'days').diff(lastDate) <= 0);

    return days;
  }

  getCountBySite(day: string): void {
    this.selectedDay = day;
    const inspectionsForDay = this.inspectionsByDay[day];
    this.inspectionsCountBySite = inspectionsForDay ? countBy(inspectionsForDay, 'SiteName') : null;
  }

}

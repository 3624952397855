import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { compact, flow, join, map, partialRight, pick, sortBy } from 'lodash';
import { ContactTypeData, CreateContractContactCommand } from 'app/core/hub-api';

import {
  MultiStepModalFormComponent,
} from '../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { contactTypeIds } from '../../../../shared/values/contact-type-ids';
import { errorMessages } from '../../../../shared/values/error-messages';
import { SiteImportModalContext } from '../../../models/site-import-modal-context';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { SiteImportContactV1 } from '../../../models/site-import-v1/site-import-contact-v1';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-site-import-contacts',
  templateUrl: './site-import-contacts.component.html',
  styleUrls: ['./site-import-contacts.component.scss']
})
export class SiteImportContactsComponent extends MultiStepModalFormComponent implements OnInit {
  context: Partial<SiteImportModalContext>;

  data: SiteImportModalData;
  form: UntypedFormGroup;
  contactsFormArray: UntypedFormArray;
  validationMessages = {
    contacts: {

    },
    contactName: {
      required: errorMessages.required,
    }
  };

  errorMessages = errorMessages;

  contactTypes: ContactTypeData[];

  constructor(
    public modalService: BsModalService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.contactTypes = map(this.context.configuration.ContactTypes);
    if (!this.data.importData.contacts) {
      this.data.importData.contacts = this.data.siteImportFile.CustomerAddress.Contacts
        .map(contact => this.processContact(contact, contactTypeIds.officeContact))
        .concat(this.data.siteImportFile.SiteAddress.Contacts.map(contact => this.processContact(contact, contactTypeIds.siteContact)));
    }
    const sortedContacts = sortBy(this.data.importData.contacts, 'ContactName');
    this.contactsFormArray = new UntypedFormArray(sortedContacts.map(c => this.createFormGroup(c)));
    this.form = new UntypedFormGroup({
      contacts: this.contactsFormArray
    });
    super.ngOnInit();
  }

  processContact(contact: SiteImportContactV1, contactTypeId: string): CreateContractContactCommand {
    return Object.assign(new CreateContractContactCommand(), {
      ContactName: flow([
        pick,
        map,
        compact,
        partialRight(join, ' ')
      ])(contact, ['ForeName', 'Surname']),
      ContactTypeId: contactTypeId,
      PhoneNumber: contact.TelephoneNumber || contact.MobileNumber,
      EmailAddress: contact.EmailAddress,
    });
  }

  onNext(formValues: any): void {
    if (this.validateForm()) {
      this.saveFormValues(formValues);
      this.nextStep();
    }
  }

  onPrevious(formValues): void {
    this.saveFormValues(formValues);
    this.previousStep();
  }

  saveFormValues(formValues: any): any {
    this.data.importData.contacts = formValues.contacts.map(c => {
      return Object.assign(new CreateContractContactCommand(), {
        ContactName: c.contactName,
        ContactTypeId: c.contactTypeId,
        PhoneNumber: c.phoneNumber,
        EmailAddress: c.emailAddress,
      });
    });
  }

  createFormGroup(c: CreateContractContactCommand): UntypedFormGroup {
    return new UntypedFormGroup({
      contactName: new UntypedFormControl(c.ContactName, [Validators.required]),
      contactTypeId: new UntypedFormControl(c.ContactTypeId, Validators.required),
      phoneNumber: new UntypedFormControl(c.PhoneNumber),
      emailAddress: new UntypedFormControl(c.EmailAddress, [Validators.required, Validators.pattern(emailAddressRegex)])
    });
  }

  onAddContact(): void {
    const officeContactId = '089c042b-28ca-463d-8622-ddb81d49866a';
    this.contactsFormArray.insert(0, new UntypedFormGroup({
      contactName: new UntypedFormControl('', [Validators.required]),
      contactTypeId: new UntypedFormControl(officeContactId, Validators.required),
      phoneNumber: new UntypedFormControl(''),
      emailAddress: new UntypedFormControl('', [Validators.required, Validators.pattern(emailAddressRegex)])
    }));
  }

  onDeleteContact(index: number): void {
    this.contactsFormArray.removeAt(index);
  }
}

<span class="site-info-directives">

  <span *ngIf="!_isAllowDismantleWithoutOffhire">
    <i class="fa fa-regular fa-handshake"></i> Dismantle with off-hire
  </span>

<span *ngIf="_isAllowDismantleWithoutOffhire">
    <span class="fa-stack signature-icon">
      <i class="fa fa-regular fa-handshake fa-stack-1x"></i>
      <i class="fa fa-times text-assertive fa-stack-1x fa-fw"></i> 
    </span>
<span class="signature-text">Dismantle without off-hire</span>
</span>

</span>
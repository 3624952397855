import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HubApiGeneratedModule } from './generated/hub-api-generated.module';
import { HUB_API_SETTINGS_TOKEN } from './hub-api-settings-token';
import { throwIfAlreadyLoaded } from '../utility/functions/module-import-guard';
import { HubApiSettingsService } from './hub-api-settings.service';
import { HubApiModuleSettings } from './hub-api-module-settings';

// Module for communicating with Hub API endpoints.
// THIS MODULE SHOULD ONLY BE IMPORTED ONCE PER APPLICATION AS IT CONTAINS APPLICATION WIDE SINGLETONS.
@NgModule({
  exports: [HubApiGeneratedModule]
})
export class HubApiModule {
  constructor(@Optional() @SkipSelf() parentModule: HubApiModule) {
    throwIfAlreadyLoaded(parentModule, 'HubApiModule');
  }

  static forRoot(settingsFactory: () => HubApiModuleSettings): ModuleWithProviders<HubApiModule> {
    return {
      ngModule: HubApiModule,
      providers: [
        HubApiSettingsService,
        { provide: HUB_API_SETTINGS_TOKEN, useFactory: settingsFactory },
      ],
    };
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This service is used to cancel the pending HTTP requests.
 * It provides an observable of canceled HTTP requests.
 */
@Injectable()
export class HttpCancelService {
    /** Observable of canceled HTTP requests */
    private pendingHTTPRequests$ = new Subject<void>();

    constructor() { }

    /** Cancels Pending HTTP calls */
    public cancelPendingRequests() {
        this.pendingHTTPRequests$.next();
    }

    /** Returns the observable of Canceled Pending HTTP calls */
    public onCancelPendingRequests() {
        return this.pendingHTTPRequests$.asObservable();
    }
}

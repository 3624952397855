<div class="site-header">
  <div class="pull-left">
    <div class="inline-block padding">
      <h1><i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{ errorCode }}</h1>
    </div>
  </div>
</div>
<div class="row margin-top">
  <div class="col-md-8 col-md-offset-2 empty-state-placeholder">
    <img src="assets/img/house.png" width="564" class="img-responsive">
    <pre>{{ errorMessage }}</pre>
    <p>Please contact SMART Scaffolder sales on <a href="tel:01202603031">01202 603031</a> or email
      <a href="mailto:subscriptions&#64;cads.co.uk">subscriptions&#64;cads.co.uk</a> for more information.
    </p>
  </div>
</div>
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { siteStatusIds } from 'app/shared/values/site-status-ids';

@Component({
  selector: 'hub-site-status',
  templateUrl: './site-status.component.html',
  styleUrls: ['./site-status.component.scss']
})
export class SiteStatusComponent implements OnInit, OnChanges {

  @Input()
  statusId: string;

  status: string;
  icon: string;

  constructor() { }

  ngOnInit(): void {
    this.updateSiteStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusId']) {
      this.updateSiteStatus();
    }
  }

  updateSiteStatus(): void {
    if (this.statusId === siteStatusIds.closed) {
      this.status = 'Closed';
      this.icon = 'fa-times-circle';
    } else {
      this.status = 'Open';
      this.icon = 'fa-check-circle';
    }
  }

}

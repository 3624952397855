import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { errorMessages } from '../../../shared/values/error-messages';
import { UUID } from 'angular2-uuid';
import { Observable, forkJoin } from 'rxjs';
import { StaffsQueryService } from '../../../core/services/staffs-query.service';
import { StaffDetailQueryService } from '../../../core/services/staff-detail-query.service';
import { first, debounceTime } from 'rxjs/operators';
import { AddEditStaffRatesModalContext } from '../../../staff/components/models/add-staff-rates-modal-context';
import { roleIds } from 'app/shared/values/role-ids';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from '../form/form.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePickerSelectData } from '../../models/date-picker-select-data';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import dayjs from 'dayjs/esm';
import isoWeek from 'dayjs/esm/plugin/isoWeek';
import weekday from 'dayjs/esm/plugin/weekday';
import utc from 'dayjs/esm/plugin/utc';
import { DateService } from 'app/shared/services/date.service';
import { StaffDetailsWebAppData, StaffCommandService, StaffRate, EditStaffCommand } from 'app/core/hub-api';
import { datesDayjs } from 'app/shared/values/dates';
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(utc);

@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-staff-modal',
  templateUrl: './add-edit-staff-rates-modal.component.html',
  styleUrls: ['./add-edit-staff-rates-modal.component.scss']
})
export class AddEditStaffRatesModalComponent extends FormComponent implements OnInit, OnDestroy {
  context: Partial<AddEditStaffRatesModalContext>;
  rateOneDateFormControl: UntypedFormControl;
  rateTwoDateFormControl: UntypedFormControl;
  rateOneFormControl: UntypedFormControl;
  rateTwoFormControl: UntypedFormControl;
  form: UntypedFormGroup;

  datePickerDays: any = [];

  validationMessages = {
    rateOne: {
      required: errorMessages.required,
      min: 'Please enter a positive value',
      max: 'Please enter a value less than 99.99',
      pattern: 'Please enter a value with two decimal places.'
    },
    rateTwo: {
      required: errorMessages.required,
      min: 'Please enter a positive value',
      max: 'Please enter a value less than 99.99',
      pattern: 'Please enter a value with two decimal places.'
    },
    rateOneDate: {
      required: errorMessages.required
    },
    rateTwoDate: {
      required: errorMessages.required
    }
  };
  // TODO: Improve after Lyndon release
  rateType = {
    r1: '86bbb760-d0ea-46c7-866c-d05363a8fdf3',
    r2: '7e5a630c-71fd-4085-b6e2-0f1a6a613d08'
  };
  staff: StaffDetailsWebAppData;
  editMode: boolean;
  title: string;
  datePickerOptions: DatePickerOptions;
  staffRateTwo: any;
  staffRateOne: any;
  dateFormatWithoutOffset = 'YYYY-MM-DDTHH:mm:ss';

  invalidDates: any = [];
  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private staffCommandService: StaffCommandService,
    private staffsQueryService: StaffsQueryService,
    private staffDetailQueryService: StaffDetailQueryService,
    private dateService: DateService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.staff = this.context.staff;
    this.title = this.editMode ? 'Edit Staff Rates' : 'Add Staff Rates';

    this.staffRateOne =
      this.staff.RateTypes && this.staff.RateTypes.length ? this.staff.RateTypes.find(r => r.RateTypeId === this.rateType.r1) : null;
    this.staffRateTwo =
      this.staff.RateTypes && this.staff.RateTypes.length ? this.staff.RateTypes.find(r => r.RateTypeId === this.rateType.r2) : null;

    const rateOneDefaultVal =
      this.staffRateOne && this.staffRateOne.CurrentRate ? parseFloat(this.staffRateOne.CurrentRate.HourlyRate).toFixed(2) : null;
    const rateTwoDefaultVal =
      this.staffRateTwo && this.staffRateTwo.CurrentRate ? parseFloat(this.staffRateTwo.CurrentRate.HourlyRate).toFixed(2) : null;

    let disableDatePicker = false;
    if (!this.editMode) {
      disableDatePicker = true;
    }
    const weekStart = {
      startDate: this.getMostRecentStartPeriodDate(),
      endDate: this.getMostRecentStartPeriodDate(),
    };
    const effectiveFrom = {
      startDate: dayjs(this.staffRateOne?.CurrentRate?.EffectiveFrom),
      endDate: dayjs(this.staffRateOne?.CurrentRate?.EffectiveFrom),
    };

    const rateOneDateDefaultVal =
      this.editMode && this.staffRateOne && this.staffRateOne.CurrentRate
        ? effectiveFrom
        : weekStart;

    const rateTwoDateDefaultVal =
      this.editMode && this.staffRateTwo && this.staffRateTwo.CurrentRate
        ? effectiveFrom
        : weekStart;

    this.rateOneFormControl = new UntypedFormControl(rateOneDefaultVal, [
      Validators.min(0),
      Validators.max(999.99),
      Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
      Validators.required
    ]);
    this.rateTwoFormControl = new UntypedFormControl(rateTwoDefaultVal, [
      Validators.min(0),
      Validators.max(999.99),
      Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
      Validators.required
    ]);

    this.rateOneDateFormControl = new UntypedFormControl(rateOneDateDefaultVal, [Validators.required]);
    this.rateTwoDateFormControl = new UntypedFormControl(rateTwoDateDefaultVal, [Validators.required]);
    this.form = new UntypedFormGroup({
      rateOne: this.rateOneFormControl,
      rateTwo: this.rateTwoFormControl,
      rateOneDate: this.rateOneDateFormControl,
      rateTwoDate: this.rateTwoDateFormControl
    });
    this.initialiseDatePicker();
    super.ngOnInit();

    this.rateOneFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        // .map(res => parseFloat(res).toFixed(2)) // TODO: Get this working as it's much cleaner then using the first operator
        // .distinctUntilChanged()
        first() // This will unsubscribe after first value has changed
      )
      .subscribe(() => {
        if (!this.editMode) return;
      });
    this.rateTwoFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        first()
      )
      .subscribe(() => {
        if (!this.editMode) return;
      });
  }

  /**
   * Returns an object containing the moment.js objects for the current week and the last week before it.
   * @param startDate The start date to calculate the weeks from.
   * @returns An object containing the moment.js objects for the current week and the last week before it.
   */
  getCurrentAndLastMomentWeeks(startDate): any {
    const activePeriodStartDate = dayjs(startDate);
    const activePeriodWeekDayNumber = activePeriodStartDate.isoWeekday();
    const currentWeekStart = dayjs()
      .startOf('week')
      .weekday(activePeriodWeekDayNumber);
    const lastWeekBeforeWeekStart = dayjs(currentWeekStart).subtract(14, 'days');
    const lastWeekAfterWeekStart = dayjs(currentWeekStart).subtract(6, 'days');
    const lastWeek = dayjs().isoWeekday() < activePeriodWeekDayNumber ? lastWeekBeforeWeekStart : lastWeekAfterWeekStart;
    return { lastWeek: lastWeek, currentWeek: currentWeekStart };
  }

  /**
   * Initializes the date picker with valid dates and options.
   * Uses the current and last weeks to set the valid dates.
   * Sets the days of the week on the calendar headers.
   * @returns void
   */
  private initialiseDatePicker(): void {
    const periodType = this.context.configuration.CompanyDetails.PeriodType;
    const firstPeriodStartDate = dayjs(this.context.configuration.CompanyDetails.FirstPeriodStartDate).toISOString();
    const weeks = this.getCurrentAndLastMomentWeeks(firstPeriodStartDate);

    // logic to set to last week when adding for first time and to next week when editing
    let dayToAdd = !this.editMode ? weeks.lastWeek : weeks.currentWeek;
    let startDate = dayjs();
    const endDate = dayjs().add(3, 'M');

    while (startDate <= endDate) {
      this.datePickerDays.push(dayToAdd);
      dayToAdd = dayjs(dayToAdd).add(7, 'days');
      startDate = startDate.clone().add(1, 'days');
    }
    const weekData = this.dateService.getDatePeriods(firstPeriodStartDate, periodType);
    this.datePickerOptions = {
      alwaysShowCalendars: false,
      ranges: {},
      showCustomRangeLabel: false,
      locale: {
        firstDay: weekData.firstDay ? Number(weekData.firstDay) : 0,
      },
      minDate: dayjs(weeks.currentWeek).startOf('year'),
      maxDate: datesDayjs.startOfToday.clone().add(3, 'months'),
      validDates: this.datePickerDays,
      opens: 'left'
    };
  }

  /**
   * Updates the value of the rateOneDate form control with the selected start and end dates.
   * @param date - The selected start and end dates.
   * @returns void
   */
  onRateOneDateChanged(date: DatePickerSelectData): void {
    const dates = {
      startDate: date.startDate,
      endDate: date.endDate
    };
    this.form.controls.rateOneDate.setValue(dates);
  }


  /**
   * Handles the change event of the second rate date picker.
   * @param date The selected date range.
   * @returns void
   */
  onRateTwoDateChanged(date: DatePickerSelectData): void {
    const dates = {
      startDate: date.startDate,
      endDate: date.endDate
    };
    this.form.controls.rateTwoDate.setValue(dates);
  }

  /**
   * Returns the current and last weeks based on a given start date.
   * @param startDate The start date to calculate the weeks from.
   * @returns An object containing the last week and current week start dates.
   */
  getCurrentAndLastWeeks(startDate): any {
    const activePeriodStartDate = dayjs(startDate);
    const activePeriodWeekDayNumber = activePeriodStartDate.isoWeekday();
    const currentWeekStart = dayjs()
      .startOf('week')
      .weekday(activePeriodWeekDayNumber);
    const lastWeekBeforeWeekStart = dayjs(currentWeekStart).subtract(14, 'days');
    const lastWeekAfterWeekStart = dayjs(currentWeekStart).subtract(6, 'days');
    const lastWeek = dayjs().isoWeekday() < activePeriodWeekDayNumber ? lastWeekBeforeWeekStart : lastWeekAfterWeekStart;
    return { lastWeek: lastWeek, currentWeek: currentWeekStart };
  }

  /**
   * Returns the most recent start period date based on the company's first period start date.
   * @returns A Dayjs object representing the most recent start period date.
   */
  private getMostRecentStartPeriodDate(): dayjs.Dayjs {
    const firstPeriodStartDate = dayjs(this.context.configuration.CompanyDetails.FirstPeriodStartDate).toISOString();
    const firstPeriodStartDay = dayjs(firstPeriodStartDate).day();
    /*
    * That effective from date of a rate must be on the same DAY as the first period start date day.
    * If the rates are applied on the same day as the first period start date then make that the effective from date
    * If the rates are applied after the first period start date then find the previous day that matches the first perios start dates day
    */
    const mostRecentPayPeriodStartDate = dayjs().subtract(2, 'weeks').day(firstPeriodStartDay);
    return mostRecentPayPeriodStartDate;
  }

  /**
   * Checks if the staff rate one date has changed.
   * @returns {boolean} True if the date has changed, false otherwise.
   */
  private hasStaffRateOneDateChanged(): boolean {
    return this.hasStaffRateChanged(this.form.controls.rateOneDate.value.startDate, this.staffRateOne.CurrentRate.EffectiveFrom);
  }

  /**
   * Checks if the date for staff rate two has changed.
   * @returns {boolean} True if the date has changed, false otherwise.
   */
  private hasStaffRateTwoDateChanged(): boolean {
    return this.hasStaffRateChanged(this.form.controls.rateTwoDate.value.startDate, this.staffRateTwo.CurrentRate.EffectiveFrom);
  }

  /**
   * Determines if the staff rate has changed based on the rate start date and the previous date.
   * @param rateStartDate The new rate start date.
   * @param previousDate The previous rate start date.
   * @returns True if the staff rate has changed, false otherwise.
   */
  private hasStaffRateChanged(rateStartDate, previousDate): boolean {
    const previousDateFormatted = dayjs(previousDate).format(this.dateFormatWithoutOffset);
    var newDateISOWithoutOffset = dayjs(rateStartDate).format(this.dateFormatWithoutOffset);
    return previousDateFormatted !== newDateISOWithoutOffset;
  }

  /**
   * Handles the form submission. If the form is valid, it saves the changes made to the staff rates.
   * If the values of the staff rates have changed, it creates new staff rates and adds them to the staff.
   * If the staff rates have not changed, it updates the existing staff rates.
   * If the form is invalid, it does nothing.
   */
  onSubmit(): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      // Check if the values have changed
      const ratesToAdd: StaffRate[] = [];
      if (this.rateOneFormControl.dirty || this.hasStaffRateOneDateChanged()) {
        ratesToAdd.push({
          StaffRateId: UUID.UUID(),
          HourlyRate: this.form.value.rateOne,
          EffectiveFrom: !this.staff.RateTypes.length
            ? this.getMostRecentStartPeriodDate().toISOString()
            : dayjs(this.form.value.rateOneDate.startDate)
              .utc()
              .add(dayjs(this.form.value.rateOneDate.startDate).utcOffset(), 'm')
              .toISOString(),
          RateTypeId: this.rateType.r1
        });
      }

      if (this.rateTwoFormControl.dirty || this.hasStaffRateTwoDateChanged()) {
        ratesToAdd.push({
          StaffRateId: UUID.UUID(),
          HourlyRate: this.form.value.rateTwo,
          EffectiveFrom: !this.staff.RateTypes.length
            ? this.getMostRecentStartPeriodDate().toISOString()
            : dayjs(this.form.value.rateTwoDate.startDate)
              .utc()
              .add(dayjs(this.form.value.rateTwoDate.startDate).utcOffset(), 'm')
              .toISOString(),
          RateTypeId: this.rateType.r2
        });
      }

      const command: EditStaffCommand = Object.assign(this.staff, {
        ExternalAuthId: undefined,
        RatesToAdd: ratesToAdd,
        Password: undefined,
        RatesToMakeIneffective: [],
        RatesToDelete: []
      });

      let result: Observable<any>;

      result =
        this.staff.RoleId === roleIds.scaffolder
          ? this.staffCommandService.EditStaffNoAccessCommand(command)
          : this.staffCommandService.EditStaffCommand(command);

      result.subscribe(() => {
        const obs$ = forkJoin([this.staffsQueryService.staffsQuery(false), this.staffDetailQueryService.staffDetailQuery(false, this.staff.StaffId)]);
        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.modalService.setDismissReason(this.staff.StaffId);
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }

  /**
   * Handles the blur event of the rate control.
   * If the control has a value, it sets the value to a fixed 2 decimal places.
   * @param control - The rate control.
   */
  onRateBlur(control): void {
    if (!!control.value) control.setValue(parseFloat(control.value).toFixed(2));
  }

  ngOnDestroy(): void { }
}

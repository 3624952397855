import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hub-site-contracts',
  templateUrl: './site-contracts.component.html',
  styleUrls: ['./site-contracts.component.scss']
})
export class SiteContractsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

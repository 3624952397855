import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator to validate that a scaffold number suffix is of the correct format.
export function scaffoldNumberSuffixValidator(control: AbstractControl): any {
    const suffix = control.value.split('-')[1];
    const suffixRegex = /^[A-Z0-9]{1,3}$/;

    const numberContainsOneOrZeroDashes = (control.value.split('-').length <= 2);

    const isValid = numberContainsOneOrZeroDashes && (suffix === undefined || suffixRegex.test(suffix));
    return isValid ? null : {
      suffix: {
        value: control.value
      }
    };
}

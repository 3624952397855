/**
 * @license
 * Copyright © Computer and Design Services Ltd.
 * All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
 * electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
 */
/**
 * Enum representing the validation status of a quote and item.
 */
export enum QuoteAndItemValidationStatus {
    NoQuoteNoItem = 0,
    NewQuoteNewItem = 1,
    ExistingQuoteNoItem = 2,
    ExistingQuoteNewItem = 3,
    ExistingQuoteExistingItem = 4
}

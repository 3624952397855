import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnDestroy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import dayjs from 'dayjs/esm';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { defaultRanges } from '../../values/dates';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { DatePickerSelectData } from '../../models/date-picker-select-data';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { isEqual } from 'lodash';
export const SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};
/**
 * Date picker wrapper that allows single or range of date select
 *
 * @export
 * @class DatePickerComponent
 * @implements {OnInit} A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive. Define an ngOnInit() method to handle any additional initialization tasks.
 * @implements {AfterViewInit} A lifecycle hook that is called after Angular has fully initialized a component's view. Define an ngAfterViewInit() method to handle any additional initialization tasks.
 */

@AutoUnsubscribe()
@Component({
  selector: 'hub-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /** Enables the code to access the date picker component */
  @ViewChild(DaterangepickerDirective, { static: true }) dateRangePicker: DaterangepickerDirective;
  /** Gets if it is a range selector or a single date picker */
  @Input()
  isRange = true;
  /* Settings specified here will override any defaults. */
  @Input()
  settings: DatePickerOptions;
  /** Output of the dates selected to UI caller */
  @Output()
  dateRangeSelected = new EventEmitter();
  /** Shows a message on the placeholder */
  @Input()
  placeholder = "Select Dates...";
  /** Dates to be disabled on the date picker */
  @Input()
  invalidDates: dayjs.Dayjs[] = [];
  /**
   * Shows or hides the calendars
   * @default true
   */
  @Input()
  alwaysShowCalendars = true;
  /** Opens the date calendar in specific position */
  @Input()
  opens: 'left' | 'right' | 'center';
  /** Drops the date calendar down or up */
  @Input()
  drops: 'down' | 'up';
  /** Gets the custom ranges or applies the defaults */
  @Input()
  ranges = defaultRanges;
  /** Maximum date available */
  @Input()
  maxDate: dayjs.Dayjs;
  /** Minimum date available */
  @Input()
  minDate: dayjs.Dayjs;
  /** Enables or disables the date picker */
  @Input()
  disable: boolean;
  /** Stores a list of valid dates */
  validDates: dayjs.Dayjs[] = [];
  /** Stores the locale settings */
  locale: any;
  /** Stores if the clear button is visible */
  showClearButton = false;
  /** Stores if the custom range label is visible */
  showCustomRangeLabel = true;
  /** Stores if the dropdowns are visible on the date picker */
  showDropdowns = true;
  /** Closes the date calendar on apply */
  closeOnAutoApply = true;
  /** Returns the invalid dates from the invalidDates property 
   *  This can also be used to give custom class names to dates
   *
   * NOTE - The invalidDates property does not allow to select ranges only single date pick, so this property needs to be used instead
   */
  isCustomDate = (date: dayjs.Dayjs) => {
    if (this.settings) {
      const validDates = this.settings.validDates;
      const invalidDates = this.settings.invalidDates;
      const cssClass = 'invalid-calendar-date';
      if (validDates && !validDates.some(d => d.isSame(date, 'day'))) {
        return cssClass;
      }
      if (invalidDates && invalidDates.some(d => d.isSame(date, 'day'))) {
        return cssClass;
      }
    }
    return false;
  }
  /** Form that controls this component */
  form: UntypedFormGroup;
  /** Form control for the date picker */
  dateFormControl: UntypedFormControl;
  /** main variables to allow change propagation
  required ControlValueAccessor variables */
  _propagateChanges: Function;
  _propagateTouched: Function;
  /** Determines the property key name for the start date */
  startKey = 'startDate';
  /** Determines the property key name for the end date */
  endKey = 'endDate';
  @Output() valueChanges = new EventEmitter<Object>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  /**
   * Required to unsubscribeAll
   *
   * @memberof DatePickerComponent
   */
  ngOnDestroy(): void {
  }

  /**
   * Writes a new value to the element.
   * This method is called by the forms API to write to the view when programmatic changes from model to view are requested.
   *
   * @param {DatePickerSelectData} obj
   * @memberof DatePickerComponent
   */
  writeValue(obj: DatePickerSelectData): void {
    this.dateFormControl.setValue(obj);
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * This method is called by the forms API on initialization to update the form model when values propagate from the view to the model.
   * @param {*} fn
   * @memberof DatePickerComponent
   */
  registerOnChange(fn: any): void {
    if (this._propagateChanges) this._propagateChanges = fn;
  }


  /**
   * Registers a callback function is called by the forms API on initialization to update the form model on blur.
   * Gets called when the control is considered blurred or "touched".
   *
   * @param {*} fn
   * @memberof DatePickerComponent
   */
  registerOnTouched(fn: any): void {
    this._propagateTouched = fn;
  }

  /**
   * @description
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
   * required to have this here due to ControlValueAccessor
   * @example
   * The following is an example of writing the disabled property to a native DOM element:
   *
   * @param {boolean} isDisabled
   * @memberof DatePickerComponent
   */
  setDisabledState?(isDisabled: boolean): void {
  }

  loadDatePickerOptions() {
    // In the case it is a single date pick it will change these properties
    if (!this.isRange) {
      this.placeholder = "Select a date...";
    }
    if (this.settings) {
      if (this.settings.showCustomRangeLabel != null) this.showCustomRangeLabel = this.settings.showCustomRangeLabel;
      if (this.settings.ranges !== this.ranges) this.ranges = this.settings.ranges;
      if (this.settings.invalidDates) this.invalidDates = this.settings.invalidDates;
      if (this.settings.validDates) this.validDates = this.settings.validDates;
      if (this.settings.locale) this.locale = this.settings.locale;
      if (this.settings.placeholder) this.placeholder = this.settings.placeholder;
      if (this.settings.alwaysShowCalendars != null) this.alwaysShowCalendars = this.settings.alwaysShowCalendars;
      if (this.settings.opens) this.opens = this.settings.opens;
      if (this.settings.drops) this.drops = this.settings.drops;
      if (this.settings.maxDate != null) this.maxDate = this.settings.maxDate
      if (this.settings.minDate != null) this.minDate = this.settings.minDate;
      if (this.settings.showClearButton != true) this.showClearButton = this.settings.showClearButton;
      if (this.settings.showDropdowns != null) this.showDropdowns = this.settings.showDropdowns;
      if (this.settings.closeOnAutoApply != null) this.closeOnAutoApply = this.settings.closeOnAutoApply;
    }
  }

  /**
   * After the view initialises it emits the start date and end date
   *
   * @memberof DatePickerComponent
   */
  ngAfterViewInit(): void {
    this.loadDatePickerOptions();
  }

  /**
   * Detects if it is a range or single date picker and sets the initial date in case it was not set
   *
   * @memberof DatePickerComponent
   */
  ngOnInit(): void {
    this.loadDatePickerOptions();
    this.dateFormControl = new UntypedFormControl('');
    this.form = new UntypedFormGroup({
      date: this.dateFormControl,
    });
    const obs$ = combineLatest([this.dateFormControl.valueChanges]);
    obs$.subscribe(latest => {
      if (this._propagateChanges) this._propagateChanges(latest[0]);
      if (!isEqual(latest[0], this.dateFormControl.value)) {
        this.dateFormControl.setValue(latest[0], { emitEvent: false });
      }
    });
  }

  /**
   * Detects when the user clears the date manually and sets the value to null
   *
   * @param {*} event
   * @memberof DatePickerComponent
   */
  onKeyUp(event: any) {
    if (event.target.value == "") {
      this.dateFormControl.setValue(null);
    }
  }

  /**
   * Detects if the dates were changed on the UI and emits the selected dates
   *
   * @param {*} dateRange
   * @memberof DatePickerComponent
   */
  onDateRangeSelected(dateRange: any): void {
    this.valueChanges.emit(dateRange);
  }

  /**
   * Detects when the user presses the clear button and sets the value to null
   *
   * @param {MouseEvent} e
   * @memberof DatePickerComponent
   */
  clear(e: MouseEvent): void {
    this.dateFormControl.setValue(null);
  }

}
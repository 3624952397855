import { ContractData, SiteWebAppData, CustomerWebAppData, StaffDetailsWebAppData } from 'app/core/hub-api';


export class InspectionStatusModalContext {
  site: SiteWebAppData;
  contract: ContractData;
  customers: CustomerWebAppData[];
  loggedInStaff: StaffDetailsWebAppData;
  pauseMode?: boolean;
  stopMode?: boolean;
  resumeMode?: boolean;
}

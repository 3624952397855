import { Pipe, PipeTransform } from '@angular/core';

// Checks if there is a value and returns Not Set if there is none
@Pipe({
  name: 'displayValue'
})
export class DisplayValuePipe implements PipeTransform {

 transform(value: any): any {
    if (value === 0) {
      return 0;
    }
    if (value) {
      return value;
    } else {
      return 'Not Set';
    }
  }
}

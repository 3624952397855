/**
 * The extension of Array type allows to sort AlphaNumeric data
 * @param fieldName {string} requires the field name which you want to sort by
 */
// @ts-ignore
Array.prototype.sortAlphaNumeric = function (fieldName: string): Array<any> {
    return this.sort((a, b) => {
        return a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase(), undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    });
};

import { SiteDetailQueryService } from '../services/site-detail-query.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteEndpointService, SiteDetailQuery, SiteWebAppData } from 'app/core/hub-api';

@Injectable()
export class SiteResolver implements Resolve<SiteWebAppData> {

  constructor(private siteDetailQueryService: SiteDetailQueryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<SiteWebAppData> {
    const reference = route.params['siteReference'];
    return this.siteDetailQueryService.siteDetailQuery(true, reference);
  }
}

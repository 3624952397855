import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { find, map } from 'lodash';
import {
  ConfigurationData,
  CreateContractCommand,
  CustomerWebAppData,
  InspectionStatusEnumEnum,
  EditCustomerCommand,
  CustomerCommandService,
  ContractTypeData
} from 'app/core/hub-api';
import { MultiStepModalFormComponent } from '../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { customerStatusIds } from '../../../../shared/values/customer-status-ids';
import { errorMessages } from '../../../../shared/values/error-messages';
import { SiteImportModalContext } from '../../../models/site-import-modal-context';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { CustomerDetailQueryService } from 'app/core/services/customer-detail-query.service';
import { forkJoin } from 'rxjs';
import { CustomersQueryService } from 'app/core/services/customers-query.service';
import { ToasterService } from 'angular2-toaster';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReferenceGeneratorService } from 'app/core/utility';
@Component({
  selector: 'hub-site-import-contract',
  templateUrl: './site-import-contract.component.html',
  styleUrls: ['./site-import-contract.component.scss']
})
export class SiteImportContractComponent extends MultiStepModalFormComponent implements OnInit {
  context: Partial<SiteImportModalContext>;
  existingCustomer: CustomerWebAppData;
  data: SiteImportModalData;
  allCustomers: CustomerWebAppData[];
  filteredCustomers: CustomerWebAppData[];
  configuration: ConfigurationData;
  contractTypes: ContractTypeData[];

  form: UntypedFormGroup;
  contractNameFormControl: UntypedFormControl;
  contractTypeFormControl: UntypedFormControl;
  contractDescriptionFormControl: UntypedFormControl;
  customerFormControl: UntypedFormControl;
  contractReferenceFormControl: UntypedFormControl;
  mainContractorFormControl: UntypedFormControl;
  submitWithoutSignatureFormControl: UntypedFormControl;
  inspectionStatusFormControl: UntypedFormControl;

  showCustomerForm: boolean;
  customerEditMode: boolean;
  customerAdded: boolean;
  showAreYouSure: boolean;
  deletedCustomer: boolean;
  noCustomerFound: boolean;

  validationMessages = {
    customer: {
      required: errorMessages.required
    },
    contractName: {
      required: errorMessages.required
    },
    contractReference: {
      required: errorMessages.required,
      mustNotExist: errorMessages.contractReferenceMustBeUnique
    },
    mainContractor: {
      required: errorMessages.required,
      mustNotExist: errorMessages.oneMainContractor
    },
    submitWithoutSignature: {
      required: errorMessages.required
    },
    agreedScheduleOfRates: {
      required: errorMessages.required
    },
    inspectionStatusFormControl: {
      required: errorMessages.required
    }
  };

  inspectionStatusEnum = InspectionStatusEnumEnum;
  customerName: string;

  constructor(
    public referenceGeneratorService: ReferenceGeneratorService,
    private customerCommandService: CustomerCommandService,
    private customerDetailQueryService: CustomerDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private toasterService: ToasterService,
    public modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.customerAdded = !!this.data.importData.customer;
    this.showCustomerForm = false;
    this.allCustomers = this.context.customers;
    this.filteredCustomers = this.allCustomers.filter(c => c.CustomerStatusId === customerStatusIds.active);
    this.configuration = this.context.configuration;
    this.contractTypes = map(this.configuration.ContractTypes);

    if (!this.data.importData.contract) {
      this.data.importData.contract = Object.assign(new CreateContractCommand(), {
        CustomerId: this.tryGetCustomerId(),
        IsPrincipalContract: false,
        CanSubmitDocumentsWithoutSignature: false,
        IsScheduleRatesAgreed: false,
        ContractInspectionStatus: InspectionStatusEnumEnum.Normal,
        ContractReference: this.referenceGeneratorService.incrementalReference([], this.configuration.ContractReferenceLength)
      });
    }

    // Required for when navigating back and forth after re-activiating a deleted customer
    if (this.data.importData.contract && !this.data.importData.contract.CustomerId) {
      this.data.importData.contract.CustomerId = this.tryGetCustomerId();
    }

    // sets the selected customer
    this.customerFormControl = new UntypedFormControl(this.data.importData.contract.CustomerId, Validators.required);
    if (this.customerAdded) {
      // A bit of a hack to make validation work.
      this.customerFormControl.setValue({});
    }

    this.contractNameFormControl = new UntypedFormControl(this.data.importData.contract.ContractName, Validators.required);
    this.contractTypeFormControl = new UntypedFormControl(this.data.importData.contract.ContractTypeId || '');
    this.contractDescriptionFormControl = new UntypedFormControl(this.data.importData.contract.Description);
    this.contractReferenceFormControl = new UntypedFormControl(this.data.importData.contract.ContractReference, Validators.required);
    this.mainContractorFormControl = new UntypedFormControl(this.data.importData.contract.IsPrincipalContract);
    this.submitWithoutSignatureFormControl = new UntypedFormControl(this.data.importData.contract.CanSubmitDocumentsWithoutSignature);
    this.inspectionStatusFormControl = new UntypedFormControl(this.data.importData.contract.ContractInspectionStatus);

    this.form = new UntypedFormGroup({
      contractName: this.contractNameFormControl,
      contractType: this.contractTypeFormControl,
      contractDescription: this.contractDescriptionFormControl,
      customer: this.customerFormControl,
      contractReference: this.contractReferenceFormControl,
      mainContractor: this.mainContractorFormControl,
      submitWithoutSignature: this.submitWithoutSignatureFormControl,
      inspectionStatus: this.inspectionStatusFormControl
    });
    super.ngOnInit();
  }

  onNext(formValues: any): void {
    if (this.validateForm()) {
      this.saveFormValues(formValues);
      this.nextStep();
    }
  }

  onPrevious(formValues): void {
    this.saveFormValues(formValues);
    this.previousStep();
  }

  saveFormValues(formValues: any): void {
    this.data.importData.contract.ContractName = formValues.contractName;
    this.data.importData.contract.ContractTypeId = formValues.contractType;
    this.data.importData.contract.Description = formValues.contractDescription;
    this.data.importData.contract.CustomerId = formValues.customer ? formValues.customer : undefined;
    this.data.importData.contract.ContractReference = formValues.contractReference;
    this.data.importData.contract.IsPrincipalContract = formValues.mainContractor;
    this.data.importData.contract.CanSubmitDocumentsWithoutSignature = formValues.submitWithoutSignature;
    this.data.importData.contract.ContractInspectionStatus = formValues.inspectionStatus;
  }


  /**
   * Tries to get the customer ID based on the site import file data.
   * @returns The customer ID if found, otherwise undefined.
   */
  tryGetCustomerId(): string {
    this.existingCustomer = find(this.allCustomers, customer => {
      const addressName = this.data.siteImportFile.CustomerAddress?.AddressName?.toLowerCase().trim();
      const addressGroup = (this.data.siteImportFile.CustomerAddress as any)?.AddressGroup?.toLowerCase().trim();
      const customerName = customer.CustomerName.toLowerCase().trim();

      if (!addressGroup && !addressName) {
        return false;
      }

      const similarityGroup = addressGroup ? this.calculateSimilarity(customerName, addressGroup) : 0;
      const similarityName = addressName ? this.calculateSimilarity(customerName, addressName) : 0;
      const similarity = Math.max(similarityGroup, similarityName);
      return similarity >= 0.8;
    });

    if (this.existingCustomer && this.existingCustomer.CustomerStatusId === customerStatusIds.inactive) {
      this.deletedCustomer = true;
    }

    return this.existingCustomer && !this.deletedCustomer ? this.existingCustomer.CustomerId : undefined;
  }

  /**
   * Calculates the similarity between two strings using the edit distance algorithm.
   * @param str1 - The first string.
   * @param str2 - The second string.
   * @returns The similarity score between 0 and 1, where 1 indicates identical strings and 0 indicates completely different strings.
   */
  calculateSimilarity(str1: string, str2: string): number {
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength.toString());
  }

  /**
   * Calculates the edit distance between two strings.
   * The edit distance is the minimum number of operations (insertions, deletions, or substitutions)
   * required to transform one string into another.
   *
   * @param str1 - The first string.
   * @param str2 - The second string.
   * @returns The edit distance between the two strings.
   */
  editDistance(str1: string, str2: string): number {
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    const costs = new Array<number>();
    for (let i = 0; i <= str1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= str2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (str1.charAt(i - 1) !== str2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) {
        costs[str2.length] = lastValue;
      }
    }
    return costs[str2.length];
  }

  onAddCustomer(): void {
    this.context.customerName = this.customerName;
    this.customerEditMode = false;
    this.showCustomerForm = true;
  }

  onEditCustomer(): void {
    this.customerEditMode = true;
    this.showCustomerForm = true;
  }

  onRemoveCustomer(): void {
    this.customerAdded = false;
    this.data.importData.customer = undefined;
    this.showAreYouSure = false;

    this.customerFormControl.setValue(undefined);
  }

  onCancelAddCustomer(): void {
    this.showCustomerForm = false;
  }

  onDoneAddCustomer(): void {
    this.showCustomerForm = false;
    this.customerAdded = true;
    // A bit of a hack to make validation work.
    this.customerFormControl.setValue({});
  }

  onReactivateCustomer(): void {
    this.customerDetailQueryService.customerDetailQuery(true, this.existingCustomer.CustomerReference).subscribe(deletedCustomer => {
      let command = new EditCustomerCommand();
      command = Object.assign(deletedCustomer, command, { CustomerStatusId: customerStatusIds.active });
      const commandResult = this.customerCommandService.EditCustomerCommand(command);
      commandResult.subscribe(() => {
        const obs$ = forkJoin([
          this.customerDetailQueryService.customerDetailQuery(false, deletedCustomer.CustomerReference),
          this.customersQueryService.customersQuery(false)
        ]);
        obs$.subscribe(() => {
          this.toasterService.pop('success', 'Customer Reactivated', `${this.existingCustomer.CustomerName} is now active.`);
          this.deletedCustomer = false;
          this.customerFormControl.setValue(deletedCustomer.CustomerName);
          this.existingCustomer.CustomerStatusId = customerStatusIds.active;
          this.data.importData.contract.CustomerId = deletedCustomer.CustomerId;
        });
      }, this.serverErrorCallback);
    });
  }

  /**
   * Triggered by UI, gets the selected customer
   *
   * @param {*} customerId
   * @memberof SiteImportContractComponent
   */
  public onSelectedCustomer(customerId: string): void {
    this.form.controls.customer.setValue(customerId);
  }
}

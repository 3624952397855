import { colors } from '../../values/colours';

import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TextToColorService } from 'app/core/services/text-to-color.service';

@Component({
  selector: 'hub-initials-icon',
  templateUrl: './initials-icon.component.html',
  styleUrls: ['./initials-icon.component.scss']
})
export class InitialsIconComponent implements OnInit, OnChanges {

  @Input()
  name = '';

  @Input()
  disabled: boolean;

  initials: string;

  @ViewChild('initialsContainer', { static: true })
  initialsContainer: ElementRef;

  constructor(private textToColourService: TextToColorService) { }

  ngOnInit(): void {
    this.refreshComponent();
  }

  refreshComponent(): void {
    this.initials = this.getInitials();
    this.setColour();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['name'] || changes['disabled']) {
      this.refreshComponent();
    }
  }

  getInitials(): string {
    let customerInitials = this.name.match(/\b(\w)/g);
    if (!customerInitials) {
      return '';
    }
    customerInitials = customerInitials.slice(0, 2);

    if (typeof customerInitials[0] !== 'undefined') {
      customerInitials[0] = customerInitials[0].toUpperCase();
    }
    if (typeof customerInitials[1] !== 'undefined') {
      customerInitials[1] = customerInitials[1].toLowerCase();
    }
    return customerInitials.join('');
  }

  // Set the icon to grey if disabled, or a colour selected based on the name string otherwise.
  setColour(): void {
    let color;
    if (this.disabled) {
      color = colors.lightGrey;
    } else {
      const iconColours = [
        colors.carrot,
        colors.tomato,
        colors.river,
        colors.emerald,
        colors.amethyst,
        colors.sunflower,
        colors.pumpkin,
      ];

      color = this.textToColourService.textToColor(this.name, iconColours);
    }
    this.initialsContainer.nativeElement.style.backgroundColor = color;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { uniqBy as _uniqBy, flatMap as _flatMap, forEach as _forEach } from 'lodash';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomerApprovalImportModalComponent } from '../customer-approval-file-import/customer-approval-file-import.component';
import { FileUploadService } from 'app/core/services/file-upload-service';
import { ContractData } from 'app/core/hub-api';
import { contactTypeIds } from 'app/shared/values/contact-type-ids';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@AutoUnsubscribe()
// TODO: [SSE-440] Add AutoUnsubscribe when on 4.7
@Component({
  selector: 'hub-handover-view',
  templateUrl: './handover-view.component.html',
  styleUrls: ['./handover-view.component.scss']
})
export class HandoverViewComponent implements OnInit, OnDestroy {
  ActivityType: number;
  ActivityDetail: any;
  RequestedBy: string;
  HandoverDetails = [];
  SiteReference: string;
  staffName: {};
  staffCompanyPosition: string;
  customerContact: string;
  customerContactId: string;
  fileName: string;
  fileAttachmentDate: any;
  risks: any[];
  handoverRiskReasons: any;
  handoverRiskTypes: any;
  scaffoldTitle: { name: string; other: any };
  reportLoading: boolean;
  activeContract: ContractData;
  context: Partial<ActivityModalContext>;

  constructor(
    private configurationQueryService: ConfigurationQueryService,
    private staffsQueryService: StaffsQueryService,
    private fileUploadService: FileUploadService,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.ActivityType = this.context.ActivityType;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.activeContract = this.context.Site.Contracts.find(
      siteContract => {
        return (
          siteContract.ContractReference ===
          this.ActivityDetail.SiteDetails.ContractReference
        );
      }
    );
    this.SiteReference = this.context.Site.SiteReference;
    this.customerContact = this.getCustomerContact();
    this.customerContactId = this.getCustomerContactId();
    // sliced "covi-" from the filename eg. covi-file.pdf, then the filename would be file.pdf in UI
    this.fileName = this.ActivityDetail.CustomerAttachment.length > 0 ? this.ActivityDetail.CustomerAttachment[0].FileName.slice(5) : undefined;
    this.fileAttachmentDate = this.ActivityDetail.CustomerAttachment.length > 0 ? this.ActivityDetail.CustomerAttachment[0].When : undefined;
    this.generateHandoverDetails();
    this.getStaffNames();
    this.risks = _flatMap(this.ActivityDetail.IdentifiedRisks);
  }

  getRiskReason(riskReasonId): any {
    if (!this.handoverRiskReasons) return null;
    return this.handoverRiskReasons[riskReasonId] ? this.handoverRiskReasons[riskReasonId] : null;
  }

  getRiskType(riskTypeId): any {
    if (!this.handoverRiskTypes) return null;
    return this.handoverRiskTypes[riskTypeId] ? this.handoverRiskTypes[riskTypeId] : null;
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  private getStaffNames(): any {
    this.staffsQueryService.staffsQuery(false).subscribe(res => {
      this.staffName =
        this.ActivityDetail && this.ActivityDetail.StaffSignature
          ? _flatMap(res).filter(s => s.StaffId === this.ActivityDetail.StaffSignature.StaffId)[0].ContactName
          : '';
      this.staffCompanyPosition = 
        this.ActivityDetail && this.ActivityDetail.StaffSignature
        ? _flatMap(res).filter(s => s.StaffId === this.ActivityDetail.StaffSignature.StaffId)[0].CompanyPosition
        : '';
    });
  }

  getImageArray(media): string[] {
    if (Object.keys(media).length) {
      const keys = [];
      _forEach(media, m => {
        keys.push(m);
      });
      return keys;
    }
    return;
  }

  /**
   * Opens a modal which uploads files
   *
   * @memberof HandoverViewComponent
   */
  uploadFile(): void {
    const context = {
      Contracts: this.getContractContacts(),
      HandoverId: this.ActivityDetail.HandoverId,
      CustomerId: this.ActivityDetail.CustomerId
    };
    this.modalService.show(CustomerApprovalImportModalComponent, SSModalConfig.generate(context)).onHide.subscribe(
      // Here the component subscribes to when the modal is closed
      res => {
        this.fileName = res as string;
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * Downloads Customer Attachment files
   *
   * @memberof HandoverViewComponent
   */
  downloadFile(): void {
    const path = `Handover/${this.ActivityDetail.HandoverId}/contact/${this.customerContactId}/covi-${this.fileName}`;
    this.fileUploadService.downloadFile(this.fileName, path);
  }

  private generateHandoverDetails(): any {
    this.configurationQueryService.configurationQuery(true).subscribe(res => {
      const scaffold = this.ActivityDetail.Scaffolds.find(s => {
        return s.ScaffoldNumber === this.ActivityDetail.ScaffoldDetails.ScaffoldName;
      });
      this.handoverRiskTypes = res.HandoverRiskTypes;
      this.handoverRiskReasons = res.HandoverRiskReasons;
      if (!scaffold || !scaffold.ScaffoldSpecification) {
        return;
      }
      const scaffoldSpec = scaffold.ScaffoldSpecification;
      this.HandoverDetails.push(
        {
          name: 'Design No:',
          value: scaffoldSpec.DesignTypeId ? res.DesignTypes[scaffoldSpec.DesignTypeId].Title : null,
          other: scaffoldSpec.DesignTypeFurtherInformation
        },
        {
          name: 'Quote No:',
          value: scaffoldSpec.QuoteNumber
        },
        {
          name: 'Item No:',
          value: scaffoldSpec.QuoteItemNumber
        },
        {
          name: 'Item Name:',
          value: scaffoldSpec.QuoteItemName
        },
        {
          name: 'Loading:',
          value: scaffoldSpec.LoadingLimitId ? res.LoadingLimits[scaffoldSpec.LoadingLimitId].Title : null,
          other: scaffoldSpec.LoadingLimitFurtherInformation
        },
        {
          name: 'Cladding:',
          value: scaffoldSpec.MethodOfCladdingId ? res.MethodOfCladdings[scaffoldSpec.MethodOfCladdingId].Title : null,
          other: scaffoldSpec.MethodOfCladdingFurtherInformation
        },
        {
          name: 'Ties:',
          value: scaffoldSpec.MethodOfTyingId ? res.MethodOfTyings[scaffoldSpec.MethodOfTyingId].Title : null,
          other: scaffoldSpec.MethodOfTyingFurtherInformation
        }
      );

      this.scaffoldTitle = {
        name: scaffoldSpec.ScaffoldTypeId ? res.ScaffoldTypes[scaffoldSpec.ScaffoldTypeId].Title : null,
        other: scaffoldSpec.ScaffoldTypeFurtherInformation
      };
    });
  }

  private getCustomerContact(): any {
    if (this.ActivityDetail && this.ActivityDetail.CustomerSignature) {
      return this.activeContract.ContractContacts[this.ActivityDetail.CustomerSignature.ContractContactId].ContactName;
    }
    if (this.ActivityDetail && this.ActivityDetail.CustomerAttachment.length > 0) {
      return this.activeContract.ContractContacts[this.ActivityDetail.CustomerAttachment[0].ContractContactId].ContactName;
    }
  }

  /**
   * Gets ContractContactId
   *
   * @returns {*}
   * @memberof HandoverViewComponent
   */
  private getCustomerContactId(): any {
    if (this.ActivityDetail && this.ActivityDetail.CustomerAttachment.length > 0) {
      return this.activeContract.ContractContacts[this.ActivityDetail.CustomerAttachment[0].ContractContactId].ContractContactId;
    }
  }

  /**
   * Gets ContractContacts which are active
   *
   * @returns {*}
   * @memberof HandoverViewComponent
   */
  private getContractContacts(): any {
    const contractContactList = [];

    _forEach(this.activeContract.ContractContacts, contact => {
      if (!contact.IsInactive &&
        contact.ContactTypeId !== contactTypeIds.companyContact &&
        contact.ContactTypeId !== contactTypeIds.bccContact
      ) {
        contractContactList.push(contact);
      }
    });
    return contractContactList;
  }

  ngOnDestroy(): void { }
}

import { ConfigurationQueryService } from '../services/configuration-query.service';

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationData, ConfigurationEndpointService, ConfigurationQuery } from 'app/core/hub-api';

@Injectable()
export class ConfigurationResolver implements Resolve<ConfigurationData> {

  constructor(private configurationQueryService: ConfigurationQueryService) { }

  resolve(): Observable<ConfigurationData> {
    return this.configurationQueryService.configurationQuery(true);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { find as _find, uniqBy as _uniqBy, flatMap as _flatMap, forEach as _forEach } from 'lodash';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { ContractData } from 'app/core/hub-api';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@AutoUnsubscribe()
@Component({
  selector: 'hub-offhire-view',
  templateUrl: './offhire-view.component.html',
  styleUrls: ['./offhire-view.component.scss']
})
export class OffhireViewComponent implements OnInit, OnDestroy {
  activityType: number;
  siteReference: string;
  staffName: any;
  site: any;
  activityDetail: any;
  requestedBy: string;
  customerContact: any;
  activeContract: ContractData;
  context: Partial<ActivityModalContext>;

  constructor(
    public staffsQueryService: StaffsQueryService,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.activityType = this.context.ActivityType;
    this.activityDetail = this.context.ActivityDetail[0];
    this.site = this.context.Site;
    this.activeContract = this.context.Site.Contracts.find(siteContract => {
      return siteContract.ContractReference === this.activityDetail.SiteDetails.ContractReference;
    });
    this.getStaffNames();
    this.customerContact = this.activityDetail.OffHireData.CustomerSignature ? this.getCustomerContact() : '';
    this.requestedBy = this.requestedByContact();
  }

  getImageArray(media): string[] {
    if (Object.keys(media).length) {
      const keys = [];
      _forEach(media, m => {
        keys.push(m.Path);
      });
      return keys;
    }
    return;
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  private requestedByContact(): any {
    return this.activeContract.ContractContacts[this.activityDetail.OffHireData.RequestedBy].ContactName;
  }

  private getCustomerContact(): string {
    return this.activeContract.ContractContacts[this.activityDetail.OffHireData.CustomerSignature.ContractContactId].ContactName;
  }

  private getStaffNames(): void {
    this.staffsQueryService.staffsQuery(true).subscribe(res => {
      this.staffName =
        this.activityDetail.OffHireData && this.activityDetail.OffHireData.StaffSignature
          ? _flatMap(res).filter(s => s.StaffId === this.activityDetail.OffHireData.StaffSignature.StaffId)[0].ContactName
          : '';
    });
  }
  ngOnDestroy(): void { }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { chain, cloneDeep, each, findIndex, map, some } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SiteDashBoardScaffoldInspectionData } from 'app/core/hub-api';

import { TimeoutService } from '../../../core/services/timeout.service';
import { colors } from '../../values/colours';
import { inspectionOutcomeStatusIds } from '../../values/inspection-outcome-status-ids';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'hub-seven-day-inspection-chart',
  templateUrl: './seven-day-inspection-chart.component.html',
  styleUrls: ['./seven-day-inspection-chart.component.scss']
})
export class SevenDayInspectionChartComponent implements OnInit, OnChanges {

  @Input()
  inspectionData: SiteDashBoardScaffoldInspectionData[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  @Input()
  startDate: Moment = moment();

  @Input()
  contractId: string;

  uiSettings = {
    showChart: true
  };

  chartConfig: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      }
    },
    colors: [
      colors.tomato,
      colors.carrot,
      colors.river,
      colors.positive
    ],
    xAxis: {
      categories: [],
      title: {
        text: 'Days'
      }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Total'
      }
    },
    series: [],
    title: {
      text: '',
      style: {
        color: colors.river,
      }
    },
    subtitle: {
      text: 'Inspections completed in the last 7 days'
    },
    credits: {
      enabled: false
    },
  };

  constructor(private timeoutService: TimeoutService) { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('inspectionsLast7Days', this.chartConfig);
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['inspectionData'] || changes['contractId']) && !!this.chart) {
      this.updateChartData();
    }
  }

  // Update the chart with the current inspection data.
  updateChartData(): Promise<void> {
    if (this.chart) {
      // Inspection data formatted in the way required by the chart.
      const formattedInspectionData = chain(this.inspectionData)
        .map((scaffold: any) => {
          const inspectionData = map(scaffold.InspectionData, (inspection: any) => {
            inspection.ContractId = scaffold.ContractId;
            return inspection;
          });
          return inspectionData;
        })
        .flatten()
        .value();

      return this.getChartData(formattedInspectionData).then((chartData) => {

        while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

        this.chart.addSeries({
          name: 'Unsafe (U)',
          data: chartData[inspectionOutcomeStatusIds.unsafeScaffold],
        }, false);

        this.chart.addSeries({
          name: 'Minor defects remain (Nm)',
          data: chartData[inspectionOutcomeStatusIds.defectsNotFixed],
        }, false);

        this.chart.addSeries({
          name: 'Defects fixed by inspector (Nf)',
          data: chartData[inspectionOutcomeStatusIds.defectsFixed],
        }, false);

        this.chart.addSeries({
          name: 'No defects found (N)',
          data: chartData[inspectionOutcomeStatusIds.noDefects],
        }, false);

        // Redraw so we can use chart.series.
        this.chart.redraw();

        const titleText = chain(this.chart.series)
          .map((series) => {
            return series.data;
          })
          .flatten()
          .map((dayData: any) => {
            return dayData.y;
          })
          .flatten()
          .reduce((total: any, current: any) => {
            return total + current;
          })
          .value()
          .toString();

        this.chart.setTitle({ text: titleText }, undefined, false);

        const xAxis = {
          categories: map(this.getDatesForWeek(), (date: any) => {
            const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            return days[date.day()];
          })
        };

        this.chart.xAxis[0].update(xAxis, false);

        this.uiSettings.showChart = this.showChart(this.chart.series);

        // Redraw the chart as few times as possible.
        this.chart.redraw();
      });
    }
  }

  private getChartData(formattedInspectionData): Promise<any> {
    const chartData = {};

    const datesInWeek = this.getDatesForWeek();

    each(inspectionOutcomeStatusIds, (outcomeId) => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      chartData[outcomeId] = map(datesInWeek, (dateInWeek) => {
        return {
          name: days[dateInWeek.day()],
          y: 0
        };
      });
    });

    const processInspectionData = (inspection) => {
      const day = findIndex(datesInWeek, (dayInWeek) => {
        return moment(inspection.When).isSame(dayInWeek, 'day');
      });

      if ((!this.contractId || this.contractId === inspection.ContractId) && day !== -1 && inspection.InspectionOutcomeId) {
        chartData[inspection.InspectionOutcomeId][day].y += 1;
      }
    };

    let resolve;
    const promise = new Promise((res) => { resolve = res; });

    const iterationsbeforeTimeout = 10;

    this.timeoutService.forWithTimeouts(formattedInspectionData, processInspectionData, iterationsbeforeTimeout).then(() => {
      resolve(chartData);
    });

    return promise;
  }

  private getDatesForWeek(): any[] {
    const datesForWeek = [];
    for (let i = 0; i < 7; i++) {
      const day = cloneDeep(this.startDate).add(i, 'days');
      datesForWeek.push(day);
    }
    return datesForWeek;
  }

  private showChart(seriesCollection): boolean {
    let show;
    each(seriesCollection, (series) => {
      show = some(series.data, (el: any) => {
        return el.y > 0;
      });
    });
    return show;
  }

}

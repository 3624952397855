<div *ngIf="design === overUnderDesign.timeEntry">
  <p>{{ overUnder | removeNegativeSymbol | number }}</p>
  <p>{{ title }}</p>
</div>

<div *ngIf="design === overUnderDesign.report" [ngClass]="{ 'report-design-positive ': overUnder >= 0, 'report-design-assertive ': overUnder < 0 }">
  <p>
    <i class="fa" [ngClass]="{ 'fa-caret-down ': overUnder >= 0, 'fa-caret-up ': overUnder < 0 }"></i> {{ overUnder | removeNegativeSymbol | number }}
  </p>
  <p>{{ title }}</p>
</div>

<div *ngIf="scaffoldDiary">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['../../../']">
        <i class="fa fa-chevron-left text-positive"></i>back to diary list</a>
    </div>
  </div>
  <div class="row margin-bottom">
    <div class="col-md-12">
      <h2 class="inline-block">{{title}}</h2>
      <!--scaffold diary only-->
      <p *ngIf="!isSiteDiary">{{ scaffoldDiary.SiteAreaName }}</p>
    </div>
  </div>
  <div *ngIf="loaded" class="ss-container diary-info">
    <div class="row">
      <div class="col-md-8">
        <span>
          <i class="fa fa-calendar"></i>{{ scaffoldDiary.When | date : 'MMM d, y, HH:mm' }}
          <i class="fa fa-user"></i>{{ scaffoldDiary.OwnerName }}
        </span>
        <span class="pull-right">
          <hub-colour-coded-title [text]="diaryCategory.Title" [colourGeneration]="diaryCategory.DiaryCategoryId">
          </hub-colour-coded-title>
        </span>
        <p class="diary-notes">{{ scaffoldDiary.Notes }}</p>
      </div>
      <!--scaffold diary only-->
      <div *ngIf="!isSiteDiary" class="col-md-2 percentage-standing-container">
        <h5>Standing Tonnage</h5>
        <p class="standing-tonnage-text">{{ scaffoldDiary.StandingTonnage | number:'.1-2'}}</p>
      </div>
      <!--scaffold diary only-->
      <div *ngIf="!isSiteDiary" class="col-md-2 percentage-standing-container">
        <h5>Scaffold Standing</h5>
        <hub-donut-chart [data]="percentageStandingData" [colors]="percentageStandingColors" [radius]="100">
        </hub-donut-chart>
        <p class="percentage-standing-text">{{ scaffoldDiary.PercentageStanding }}%</p>
      </div>
    </div>
  </div>
  <div *ngIf="loaded" class="ss-container media-container">
    <div class="media-header">
      <h4>
        <i class="fa fa-play"></i> Media
      </h4>
      <a (click)="onUploadMedia()" class="btn button-default button-rounded pull-right" role="button">
        <i class="fa fa-cloud-upload"></i>
        <span>Upload Media</span>
      </a>
    </div>
    <div *ngIf="!mediaData.length" class="empty-state-placeholder">
      <i class="fa fa-play fa-4x"></i>
      <h3>Welcome to the {{isSiteDiary?'site': 'scaffold'}} diary media area</h3>
      <p>It doesn't look like you have added any media for this {{isSiteDiary?'site': 'scaffold'}} diary.</p>
      <a (click)="onUploadMedia()" class="btn button-primary" title="Upload Media">
        <i class="fa fa-plus"></i>Upload Media</a>
    </div>
    <div *ngIf="mediaData.length" class="row margin-top-md">
      <div class="col-md-5">
        <div class="media-list">
          <table class="table table-hover media-table">
            <tr *ngFor="let media of mediaData">
              <td (click)="onMediaClicked(media)" class="type-col clickable">
                <i class="fa" [ngClass]="mediaTypeIcons[media.Type]"></i>
              </td>
              <td (click)="onMediaClicked(media)" class="title-col clickable">
                {{ media.Title }}
              </td>
              <td class="menu-col">
                <div class="btn-group" dropdown container="body">
                  <a dropdownToggle>
                    <i class="fa fa-bars"></i>
                  </a>
                  <ul *dropdownMenu class="dropdown-menu pull-right">
                    <li>
                      <a (click)="onRename(media)" class="dropdown-item">
                        <span>
                          <i class="fa fa-regular fa-pen-to-square"></i>
                        </span> Rename</a>
                    </li>
                    <li>
                      <a class="dropdown-item" *ngIf="media.Type === mediaTypes.transcription"
                        (click)="onDownloadClicked(media)">
                        <span>
                          <i class="fa fa-cloud"></i>
                        </span> Download</a>
                      <a class="dropdown-item" *ngIf="media.Type !== mediaTypes.transcription" hubDownloadClick
                        [path]="media.Path" [fileName]="media.Title">
                        <span>
                          <i class="fa fa-cloud"></i>
                        </span> Download</a>
                    </li>
                    <!-- Hiding transcribe option -->
                    <li *ngIf="canBeTranscribed(media)">
                      <a class="dropdown-item" (click)="onTranscribeClicked(media)">
                        <span>
                          <i class="fa fa-regular fa-file-lines"></i>
                        </span> Transcribe
                      </a>
                    </li>
                    <li class="divider dropdown-divider"></li>
                    <li>
                      <a class="dropdown-item dropdown-delete" (click)="onDelete(media)">
                        <span>
                          <i class="fa fa-regular fa-trash-can"></i>
                        </span> Delete</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-md-7">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
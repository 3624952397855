import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  /*
  * Takes an iso date string and returns a date object
  * that can be used for the "my-date-picker" component
  */

  getDate(isoDate: string): any {
    const date = new Date(isoDate);
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      },
      jsdate: date
    };
  }
}

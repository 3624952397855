<form name="contractContactForm" [formGroup]="form">
  <hub-modal-form-header title="Rename File" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <hub-form-group label="Please insert a new name for the file" name="mediaTitle" [control]="mediaTitleFormControl"
      [validationErrors]="validationErrors">
      <input class="form-control" type="text" formControlName="mediaTitle">
    </hub-form-group>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../../core/models/page';
import { PagedData } from '../../core/models/paged-data';
import { EstimateItem, Estimates } from '../models/estimate';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubApiSettingsService } from '../../core/hub-api';
import { BinaryOperator, EstimateRequest, UnaryOperator } from '../models/estimate-filter-options';
import moment from 'moment';
import { saveAs } from 'file-saver';

/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class EstimatesService {
    /** The base URL for the estimates service. */
    private baseUrl: string = `${this.settingsService.getApiServiceBaseUrl()}estimates`;

    constructor(
        private http: HttpClient,
        private settingsService: HubApiSettingsService,
    ) { }

    /**
     * Sends a post request with the specified payload
     * @param payload The payload object to send in the request
     * @returns {Observable<any>} An observable containing the response data
     */
    public requestEstimates(payload): Observable<any> {
        /** The access token used for authentication. */
        const token: string = this.settingsService.getAccessToken();
        /** The headers used for the HTTP request. */
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        });
        /** The options used for the HTTP request. */
        const options = { headers: headers };
        return this.http.post(`${this.baseUrl}`, payload, options);
    }

    /**
    * Sends a get request to retrieve the estimate statuses
    * @returns {Observable<any>} An observable containing the response data
    */
    public getEstimateStatus(): Observable<any> {
        /** The access token used for authentication. */
        const token: string = this.settingsService.getAccessToken();
        /** The headers used for the HTTP request. */
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        });
        /** The options used for the HTTP request. */
        const options = { headers: headers };
        return this.http.get(`${this.baseUrl}/statuses`, options);
    }
    /**
    * Sends a get request to export the estimate with the specified ID
    * @param estimateId The ID of the estimate to export
    * @returns {Observable<any>} An observable containing the response data
    */
    public exportEstimate(estimateId: string): Observable<any> {
        /** The access token used for authentication. */
        const token: string = this.settingsService.getAccessToken();
        /** The headers used for the HTTP request. */
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        });
        return this.http.get(`${this.baseUrl}/${estimateId}/export`, {
            headers: headers,
            responseType: 'text'
        }).pipe(
            map(response => JSON.parse(response))
        );
    }

    /**
     * Updates the site for a specific estimate.
     *
     * @param estimateId - The ID of the estimate.
     * @param siteId - The ID of the site to update.
     * @returns An Observable that emits the updated site data.
     */
    public updateSite(estimateId: string, siteId: string): Observable<any> {
        /** The access token used for authentication. */
        const token: string = this.settingsService.getAccessToken();
        /** The headers used for the HTTP request. */
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        });
        /** The options used for the HTTP request. */
        const options = { headers: headers };
        var payload = { PostContractSiteId: siteId }
        return this.http.put(`${this.baseUrl}/${estimateId}/site/update`, payload, options);
    }

    /**
     * Updates the status of an estimate.
     *
     * @param estimateId - The ID of the estimate to update.
     * @param statusId - The ID of the new status for the estimate.
     * @returns An Observable that emits the updated estimate.
     */
    public updateStatus(estimateId: string, statusId: string): Observable<any> {
        try {
            /** The access token used for authentication. */
            const token: string = this.settingsService.getAccessToken();
            /** The headers used for the HTTP request. */
            const headers: HttpHeaders = new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
            /** The options used for the HTTP request. */
            const options = { headers: headers };
            var payload = {
                EstimateId: estimateId,
                Status: statusId
            };
            return this.http.put(`${this.baseUrl}/${estimateId}/update-status`, payload, options);
        } catch (error) {
            return error;
        }
    }

    /**
     * Checks if an estimate is locked.
     *
     * @param projectId - The ID of the project.
     * @returns An Observable that emits whether the estimate is locked.
     */
    public isEstimateLocked(projectId: string): Observable<any> {
        try {
            /** The access token used for authentication. */
            const token: string = this.settingsService.getAccessToken();
            /** The headers used for the HTTP request. */
            const headers: HttpHeaders = new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            });
            /** The options used for the HTTP request. */
            const options = { headers: headers };
            return this.http.get(`${this.baseUrl}/${projectId}/IsEstimateLocked`, options);
        } catch (error) {
            return error;
        }
    }

    /**
     * A method that mocks a paged server response
     * @param page The selected page
     * @returns {any} An observable containing the data
     */
    public getResults(page: Page, payload: EstimateRequest): Observable<PagedData<EstimateItem>> {
        return this.requestEstimates(payload)
            .pipe(map(d => this.getPagedData(page, d)));
    }

    /**
     * Exports the data as a CSV file.
     * @returns A promise that resolves with the exported data.
     */
    public exportCsv(): Observable<Blob> {
        /** The access token used for authentication. */
        const token: string = this.settingsService.getAccessToken();
        /** The headers used for the HTTP request. */
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        });
        /** The options used for the HTTP request. */
        const options = { headers: headers };
        (options as any).responseType = 'blob';
        return this.http.get<Blob>(`${this.baseUrl}/exportcsv`, options).pipe(
            map(blob => {
                const dateTime = moment().format('D-MM-YY-hh-mm-ss');
                saveAs(blob, `${dateTime}-export-estimates.csv`);
                return blob;
            })
        );
    }

    /**
     * Package data into a PagedData object based on the selected Page
     * @param page The page data used to get the selected data from data
     * @returns {PagedData<EstimateItem>} An array of the selected data and page
     */
    private getPagedData(page: Page, estimates: Estimates): PagedData<EstimateItem> {
        page.pageNumber = estimates.Pagination.CurrentPage;
        page.size = estimates.Pagination.PageSize;
        page.totalElements = estimates.Pagination.TotalPages;
        page.totalPages = estimates.Pagination.TotalPages;
        const pagedData = new PagedData<EstimateItem>();
        pagedData.data = estimates.Items;
        pagedData.page = page;
        return pagedData;
    }

    /**
     * Returns the string representation of the given binary operator.
     * @param operator - The binary operator.
     * @returns The string representation of the binary operator.
     */
    public getBinaryOperator(operator: BinaryOperator): string {
        switch (operator) {
            case BinaryOperator.Equals:
                return 'Equals';
            case BinaryOperator.NotEquals:
                return 'NotEquals';
            case BinaryOperator.GreaterThan:
                return 'GreaterThan';
            case BinaryOperator.LessThan:
                return 'LessThan';
            case BinaryOperator.GreaterThanOrEquals:
                return 'GreaterThanOrEquals';
            case BinaryOperator.LessThanOrEquals:
                return 'LessThanOrEquals';
            case BinaryOperator.Contains:
                return 'Contains';
            default:
                return '';
        }
    }

    /**
     * Returns the string representation of the given unary operator.
     * @param operator - The unary operator.
     * @returns The string representation of the unary operator.
     */
    public getUnaryOperator(operator: UnaryOperator): string {
        switch (operator) {
            case UnaryOperator.IsNull:
                return 'IsNull';
            case UnaryOperator.IsNotNull:
                return 'IsNotNull';
            default:
                return '';
        }
    }
}

<div *ngIf="site" class="site-header">
  <div class="pull-left">
    <div *ngIf="site.SiteImageUrl" class="site-header-container">
      <a (click)="openImageModal([site.SiteImageUrl])"><img [src]="site.SiteImageUrl" /></a>
    </div>
    <div class="inline-block">
      <h1>{{site.SiteName}}</h1>
      <p>{{site.SiteAddress}}</p>
    </div>
  </div>
</div>
<div id="wrapper">
  <div id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <li routerLinkActive="active">
        <a [routerLink]="['dashboard']" class="side-nav-mobile-title">Dashboard</a>
        <a [routerLink]="['dashboard']" class="side-nav-mobile-icon">Da</a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['details']" class="side-nav-mobile-title">Site Details</a>
        <a [routerLink]="['details']" class="side-nav-mobile-icon">S</a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['contracts']" class="side-nav-mobile-title">Contracts</a>
        <a [routerLink]="['contracts']" class="side-nav-mobile-icon">C</a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['scaffolds']" class="side-nav-mobile-title">Scaffolds</a>
        <a [routerLink]="['scaffolds']" class="side-nav-mobile-icon">S</a>
      </li>
      <li routerLinkActive="active" *ssFeatureFlag="'handover'">
        <a [routerLink]="['handovers']" class="side-nav-mobile-title">Handovers</a>
        <a [routerLink]="['handovers']" class="side-nav-mobile-icon">H</a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['diary']" class="side-nav-mobile-title">Diary</a>
        <a [routerLink]="['diary']" class="side-nav-mobile-icon">Di</a>
      </li>
    </ul>
  </div>
  <div class="content site-details-container">
    <router-outlet></router-outlet>
  </div>
</div>

<div id="page-content-wrapper" class="sites-list activity-tab">
  <div class="ss-container fill-container">
    <h2 class="inline-block"><i class="fa fa-rss"></i> Activity</h2>
    <form class="row table-filters shadow-header" [formGroup]="activityForm" *ngIf="staff && sites">
      <div class="col-xs-12 col-sm-6 col-md-2">
        <div class="form-group" *ngIf="sites">
          <div class="input-group filter-dropdown">
            <hub-dropdown #siteDropdown formControlName="sitesForm" [items]="sites" idProperty="SiteId" titleProperty="SiteName"
              icon="fa fa-building text-positive" [multiselect]="false" (valueChanges)="onSelectedSiteChanged($event)"
              placeholder="Filter by sites">
            </hub-dropdown>

          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 text-right">
        <div class="form-group">
          <div class="input-group filter-dropdown">
            <hub-dropdown #activityDropdown formControlName="activityType" [items]="activityDropdownItems" idProperty="value"
              titleProperty="name" icon="fa fa-rss text-positive" [multiselect]="false"
              (valueChanges)="onSelectedActivityChanged($event)" placeholder="Filter by activity">
            </hub-dropdown>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 text-right">
        <div class="form-group" *ngIf="staff">
          <div class="input-group filter-dropdown">
            <hub-dropdown #staffDropdown formControlName="staffForm" [items]="staff" idProperty="StaffId" titleProperty="ContactName"
              icon="fa fa-user text-positive" [multiselect]="false" (valueChanges)="onSelectedStaffChanged($event)"
              placeholder="Filter by name">
            </hub-dropdown>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2">
        <div class="form-group">
          <hub-date-picker formControlName="timeAndDate" (valueChanges)="onDateRangeSelected($event)"
            [settings]="datePickerOptions" [ranges]="{}" [opens]="'left'" placeholder="Filter by Date">
          </hub-date-picker>
          <span *ngIf="!enableButton" class="text-error text-left center-block"><i class="fa fa-warning"></i> A date
            range is required</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2">
        <div class="form-group">
          <button (click)="getNewFormControlsData()" class="btn btn-block"
            [ngClass]="{ 'btn-success': enableButton, 'btn-disabled': !enableButton }"
            [disabled]="!enableButton || loading">
            Apply Filters
          </button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2">
        <div class="form-group"><a (click)="resetFilters()" class="btn btn-danger btn-block">Reset Filters</a></div>
      </div>
    </form>
    <div class="row margin-top">
      <hub-loading-spinner *ngIf="loading"></hub-loading-spinner>
      <hub-activity-table [viewApiData]="viewApiData" *ngIf="!loading" (sortActivity)="sortApi($event)"
        (bufferedRecords)="updateView($event)" [refresh]="refresh"></hub-activity-table>
    </div>
  </div>
</div>

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaffoldDetailQuery, ScaffoldEndpointService, ScaffoldWebAppData } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries scaffold data from the server (or in memory cache where specified) and allows consumer to subscribe to scaffold data changes.
@Injectable()
export class ScaffoldDetailQueryService extends QueryService<ScaffoldWebAppData> {

  constructor(private scaffoldEndpointService: ScaffoldEndpointService) { super(); }

  scaffoldDetailQuery(useCached: boolean, scaffoldId: string): Observable<ScaffoldWebAppData> {
    return super.query(useCached, scaffoldId);
  }

  scaffoldDetailDataChanges(scaffoldId: string): Observable<ScaffoldWebAppData> {
    return super.cachedDataChanges(scaffoldId);
  }

  protected getDataFromServer(scaffoldId: string): Observable<ScaffoldWebAppData> {
    const query = Object.assign(new ScaffoldDetailQuery(), { ScaffoldId: scaffoldId });
    return this.scaffoldEndpointService.ScaffoldDetailQuery(query).pipe(map(data => data[0]));
  }
}

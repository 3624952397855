<form name="staffRatesForm" [formGroup]="form">
  <hub-modal-form-header [title]="title" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <fieldset>
      <div class="alert alert-warning" role="alert" *ngIf="editMode">
        <strong>
          <i class="fa fa-warning"></i> Warning:</strong> This will override any existing upcoming rates
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <hub-form-group label="Staff Rate 1" name="rateOne" [control]="rateOneFormControl"
              [validationErrors]="validationErrors" class="col-xs-4">
              <label class="sr-only" for="rateOne">Rate One</label>
              <div class="input-group">
                <div class="input-group-addon">£</div>
                <input type="number" class="form-control" formControlName="rateOne" min="0.00" max="99.99" step="0.01"
                  placeholder="00.00" (blur)="onRateBlur(rateOneFormControl)">
              </div>
            </hub-form-group>

            <hub-form-group label="Rate One Start Date" name="rateOneDate" [control]="rateOneDateFormControl"
              [validationErrors]="validationErrors" class="col-xs-6">
              <div class="rate-dp">
                <hub-date-picker formControlName="rateOneDate" (valueChanges)="onRateOneDateChanged($event)"
                  [disable]="!editMode" [settings]="datePickerOptions" [isRange]="false">
                </hub-date-picker>
              </div>
              <i class="fa fa-info-circle fa icon" tooltip="Rates can only be set to the start of a pay period"
                placement="left" *ngIf="editMode"></i>
            </hub-form-group>

          </div>

          <div class="row">
            <hub-form-group label="Staff Rate 2" name="rateTwo" [control]="rateTwoFormControl"
              [validationErrors]="validationErrors" class="col-xs-4">
              <label class="sr-only" for="rateTwo">Rate Two</label>
              <div class="input-group">
                <div class="input-group-addon">£</div>
                <input type="number" class="form-control" formControlName="rateTwo" min="0.00" max="99.99" step="0.01"
                  placeholder="00.00" (blur)="onRateBlur(rateTwoFormControl)">
              </div>
            </hub-form-group>

            <hub-form-group label="Rate Two Start Date" name="rateTwoDate" [control]="rateTwoDateFormControl"
              [validationErrors]="validationErrors" class="col-xs-6">
              <div class="rate-dp">
                <hub-date-picker formControlName="rateTwoDate" (valueChanges)="onRateTwoDateChanged($event)"
                  [disable]="!editMode" [settings]="datePickerOptions" [isRange]="false">
                </hub-date-picker>
              </div>
              <i class="fa fa-info-circle icon" tooltip="Rates can only be set to the start of a pay period"
                placement="left" *ngIf="editMode"></i>
            </hub-form-group>
          </div>
        </div>
      </div>
    </fieldset>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit()"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
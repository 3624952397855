import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { DefectTypeData, DiaryTypeEnum } from 'app/core/hub-api';
import { uniqBy as _uniqBy, forEach as _forEach, flatMap as _flatMap } from 'lodash';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';

@AutoUnsubscribe()
@Component({
  selector: 'hub-inspection-view',
  templateUrl: './inspection-view.component.html',
  styleUrls: ['./inspection-view.component.scss']
})
export class InspectionViewComponent implements OnInit, OnDestroy {
  ActivityTypeId: string;
  ActivityType: number;
  ActivityDetail: any;
  Outcome: any = {};
  Defects: DefectTypeData[];
  InspectionFollowUpInstructions: any;
  InspectionFollowUpNotifys: any;
  SiteReference: string;
  notifyPeople: any = [];
  staffFromActionToBeCarriedOutBy: any = [];
  isUnsafe: boolean;
  activateDownloadReport: boolean;
  scaffoldInspectionMedia: any;
  context: Partial<ActivityModalContext>;

  constructor(
    private configurationQueryService: ConfigurationQueryService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private staffsQueryService: StaffsQueryService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.ActivityTypeId = this.context.ActivityTypeId;
    this.ActivityType = this.context.ActivityType;
    this.activateDownloadReport = DiaryTypeEnum.InspectionFollowUpInstruction === this.ActivityType || DiaryTypeEnum.Inspection === this.ActivityType ? true : false;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.SiteReference = this.context.Site.SiteReference;
    this.generateHandoverDetails();
    this.getCustomerContact();
    if (this.Outcome && this.Outcome.title === 'Unsafe') {
      this.context.dialogClass = 'modal-dialog modal-lg modal-danger';
      this.isUnsafe = true;
    } else {
      this.context.dialogClass = 'modal-dialog modal-lg';
      this.isUnsafe = false;
    }
    this.scaffoldInspectionMedia = this.ActivityDetail.ScaffoldInspections && this.ActivityDetail.ScaffoldInspections.Media
      ? this.ActivityDetail.ScaffoldInspections.Media : [];
  }

  private generateHandoverDetails(): any {
    this.configurationQueryService.configurationQuery(true).subscribe(res => {
      if (this.ActivityDetail.ScaffoldInspections.InspectionTypeId && this.ActivityDetail.ScaffoldInspections.InspectionOutcomeId) {
        this.Outcome = {
          title: res.InspectionOutcomes[this.ActivityDetail.ScaffoldInspections.InspectionOutcomeId].Title,
          comments: this.ActivityDetail.ScaffoldInspections.Comments,
          type: res.InspectionTypes[this.ActivityDetail.ScaffoldInspections.InspectionTypeId].Title
        };
      }
      this.Defects = this.ActivityDetail.ScaffoldInspections.InspectionDefects;
      this.InspectionFollowUpInstructions = this.ActivityDetail.InspectionFollowUpInstructions;
      this.getStaffFromActionToBeCarriedOutBy();
      this.InspectionFollowUpNotifys = this.ActivityDetail.InspectionFollowUpNotifys;
    });
  }

  /**
   * Retrieves and populates the 'staffFromActionToBeCarriedOutBy' array with staff details based on the 'ActionToBeCarriedOutBy' field from 'InspectionFollowUpInstructions' in 'ActivityDetail'.
   * This function checks if 'ActionToBeCarriedOutBy' is present in 'InspectionFollowUpInstructions'. If it is, it iterates over each staffId in 'ActionToBeCarriedOutBy'.
   * For each staffId, it executes a query using 'staffsQueryService' to fetch staff details. The query is set to not use the cache data (indicated by the 'false' parameter).
   * It subscribes to the result of this query and pushes the first staff object (found using the 'find' method) where 'StaffId' matches staffId into the 'staffFromActionToBeCarriedOutBy' array.
   */
  private getStaffFromActionToBeCarriedOutBy(): any {
    if (this.ActivityDetail.InspectionFollowUpInstructions?.ActionToBeCarriedOutBy) {
      _forEach(this.ActivityDetail.InspectionFollowUpInstructions.ActionToBeCarriedOutBy, staffId => {
        this.staffsQueryService.staffsQuery(false).subscribe(res => {
          this.staffFromActionToBeCarriedOutBy.push(res.find(s => s.StaffId === staffId));
        });
      });
    }
  }

  private getCustomerContact(): any {
    const contract = this.context.Site.Contracts.find(siteContract => {
      return siteContract.ContractReference === this.ActivityDetail.SiteDetails.ContractReference;
    });
    if (this.ActivityDetail.InspectionFollowUpNotifys && this.ActivityDetail.InspectionFollowUpNotifys.PeopleNotified) {
      _forEach(this.ActivityDetail.InspectionFollowUpNotifys.PeopleNotified, pn => {
        this.notifyPeople.push(contract.ContractContacts[pn].ContactName);
      });
    }
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void { }
}

import { Component, Input, OnInit } from '@angular/core';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ImageModalContext } from '../image-modal/image-modal-context';
import { indexOf as _indexOf } from 'lodash';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {
  @Input() media?: string[];
  @Input() height?: string;


  constructor(private bsModalService: BsModalService) { }

  ngOnInit(): void {
    this.height = this.height ? this.height : '100px';
  }

  openImageModal(imageURL): void {
    if (!imageURL) return;
    const imageIndex = _indexOf(this.media, imageURL);
    this.media.splice(imageIndex, 1);
    this.media.unshift(imageURL);
    const context: ImageModalContext = { imageUrl: this.media };
    this.bsModalService.show(ImageModalComponent, SSModalConfig.generate(context, { ignoreBackdropClick: false, keyboard: true }));
  }
}

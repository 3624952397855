
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiaryData, DiaryQuery, HubApiSettingsService, ScaffoldEndpointService } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Queries scaffold/site diary data from the server (or in memory cache where specified) and allows
// consumer to subscribe to scaffold diary data changes.
@Injectable()
export class DiaryQueryService extends QueryService<DiaryData> {

  constructor(
    private scaffoldEndpointService: ScaffoldEndpointService,
    private http: HttpClient,
    private settingsService: HubApiSettingsService,
  ) { super(); }

  diaryQuery(useCached: boolean, diaryId: string): Observable<DiaryData> {
    return super.query(useCached, diaryId);
  }

  diaryDataChanges(diaryId: string): Observable<DiaryData> {
    return super.cachedDataChanges(diaryId);
  }

  protected getDataFromServer(diaryId: string): Observable<DiaryData> {
    const query = Object.assign(new DiaryQuery(), { DiaryId: diaryId });
    return this.scaffoldEndpointService.DiaryQuery(query).pipe(map(data => data[0]));
  }

  getDiaryData(queryData): Observable<any> {
    const token = this.settingsService.getAccessToken();
    const data = {
      QueryData: JSON.stringify(queryData),
      QueryType: 'Cads.SmartScaffolder.Hub.Business.Query.DiaryQuery'
    };
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      });
    const options = {
      headers: headers,
    };

    return this.http.post(this.settingsService.getApiServiceBaseUrl() + 'webQuery', data, options);
  }
}

import { Component, OnInit } from '@angular/core';

// Placeholder view, will only be seen when there is no diary media.
@Component({
  selector: 'hub-diary-media-viewer-placeholder',
  templateUrl: './diary-media-viewer-placeholder.component.html',
  styleUrls: ['./diary-media-viewer-placeholder.component.scss']
})
export class DiaryMediaViewerPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<form name="contractForm" [formGroup]="form">
  <hub-modal-form-header [title]="editMode ? 'Edit Contract' : 'New Contract'" (close)="bsModalRef.hide()">
  </hub-modal-form-header>
  <div class="modal-body add-edit-contract-modal">
    <div class="alert alert-warning" role="alert">
      <strong>
        <i class="fa fa-warning"></i> Warning:</strong> Contract references can not be edited once scaffolds have been
      assigned to them.
    </div>
    <fieldset>
      <hub-form-group label="Customer Name" name="customer" [control]="customerFormControl"
        [validationErrors]="validationErrors">
        <div class="row">
          <div class="col-md-12">
            <div class="input-button">
              <hub-dropdown formControlName="customer" class="auto-complete" [items]="filteredCustomers"
                idProperty="CustomerId" titleProperty="CustomerName" icon="fa fa-users text-positive"
                [multiselect]="false" (focus)="blurReadOnly($event)" [clearable]="false"
                [readonly]="contract.HasScaffolds" (valueChanges)="onCustomerSelected($event)"
                placeholder="Select a customer">
              </hub-dropdown>
              <div class="right-button" *ngIf="!contract.HasScaffolds">
                <hub-permission permissions="AddEditCustomers">
                  <a class="btn button button-primary" role="button" (click)="onAddCustomer()">
                    <i class="fa fa-plus"></i><span>Add</span>
                  </a>
                </hub-permission>
              </div>
            </div>
          </div>
        </div>
      </hub-form-group>
      <hub-form-group label="Contract Reference" name="contractReference" [control]="contractReferenceFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="contractReference" [readonly]="contract.HasScaffolds"
          (focus)="blurReadOnly($event)" ngxTrim>
      </hub-form-group>

      <hub-form-group label="Contract Name" name="contractName" [control]="contractNameFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="contractName" (focus)="blurReadOnly($event)" ngxTrim>
      </hub-form-group>

      <hub-form-group label="Contract Type" name="contractType" [control]="contractTypeFormControl">
        <hub-dropdown formControlName="contractType" [items]="contractTypes" idProperty="ContractTypeId"
          titleProperty="Title" (valueChanges)="onSelectedContractTypeChanged($event)" placeholder="None"
          [clearable]="false">
        </hub-dropdown>
      </hub-form-group>

      <hub-form-group label="Contract Description" name="contractDescription" [control]="contractDescriptionFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="contractDescription" (focus)="blurReadOnly($event)"
          ngxTrim>
      </hub-form-group>

      <table>
        <tr>
          <td>
            <hub-form-group name="mainContractor" [control]="mainContractorFormControl"
              [validationErrors]="validationErrors">
              <label>Is this customer the main contractor?</label>
              <div class="text-error" *ngIf="mainContractorFormControl.value && existingMainContractor">
                <i class="fa fa-warning"></i> This will make
                <strong>{{existingMainContractor.customerName}} ({{existingMainContractor.contractReference}})</strong>
                a
                subcontractor.
              </div>
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="mainContractor" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="mainContractor" [value]="false"> No
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <hub-form-group name="submitWithoutSignature"
              label="Allow handover/off-hire documents to be submitted without the customer signature?"
              [control]="submitWithoutSignatureFormControl" [validationErrors]="validationErrors"
              tooltipPlacement='right'
              tooltipText="If 'yes' then the site documents can be submitted without the customer signature by selecting the 'customer not present' option from the contact picker">
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="submitWithoutSignature" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="submitWithoutSignature" [value]="false"> No
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <hub-form-group name="agreedScheduleOfRates" [control]="agreedScheduleOfRatesFormControl"
              [validationErrors]="validationErrors" label="Does this contract have an agreed schedule of rates?">
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="agreedScheduleOfRates" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="agreedScheduleOfRates" [value]="false"> No
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr *ngIf="!editMode">
          <td>
            <hub-form-group name="inspectionStatus" [control]="inspectionStatusFormControl"
              [validationErrors]="validationErrors"
              label="Are you contracted to carry out inspections for this customer?">
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="inspectionStatus" [value]="inspectionStatusEnum.Normal"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input type="radio" formControlName="inspectionStatus" [value]="inspectionStatusEnum.Stopped"> No
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="site.IsCameraAllowed">
          <td>
            <hub-form-group name="camerasAllowed" [control]="camerasAllowedFormControl"
              [validationErrors]="validationErrors" label="Handover/Variation risk photos required?">
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <hub-form-group name="dismantleWithoutOffhire" [control]="dismantleWithoutOffhireFormControl"
              [validationErrors]="validationErrors" label="Dismantle without off-hire?">
            </hub-form-group>
          </td>
          <td>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="dismantleWithoutOffhire" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="dismantleWithoutOffhire" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </fieldset>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
export enum TableHeadersEnum {
    ScaffoldName = 0,
    ScaffoldDescription = 1,
    Area = 2,
    ScaffoldType = 3,
    LoadingLimits = 4,
    MethodCladding = 5,
    MethodTying = 6,
    QuoteNumber = 7,
    ItemNumber = 8,
    ItemName = 9,
    ScaffoldingSystem = 10,
    MaximumTonnage = 11,
    HirePeriod = 12,
    DesignType = 13,
    DateNextInspection = 14,
    OnHireDate = 15,
    AdverseWeather = 16,
}



import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepotData, DepotQuery, SiteDepotEndpointService } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class DepotsQueryService extends QueryService<DepotData[]> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'depots';

  constructor(private siteDepotEndpointService: SiteDepotEndpointService) { super(); }

  depotsQuery(useCached: boolean): Observable<DepotData[]> {
    return super.query(useCached, this.key);
  }

  depotsDataChanges(): Observable<DepotData[]> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<DepotData[]> {
    return this.siteDepotEndpointService.DepotQuery(new DepotQuery());
  }
}

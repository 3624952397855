import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HandoverEndpointService, HandoverHistoryWebAppData, HandoverHistoryQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class HandoverHistoryQueryService extends QueryService<HandoverHistoryWebAppData[]> {

  constructor(private handoverEndpointService: HandoverEndpointService) { super(); }

  handoverHistoryQuery(useCached: boolean, siteIds: string[]): Observable<HandoverHistoryWebAppData[]> {
    // Note that this implementation means that each query (for a particular combination of sites) is cached individually.
    return super.query(useCached, siteIds.sort().toString());
  }

  handoverHistoryDataChanges(siteIds: string[]): Observable<HandoverHistoryWebAppData[]> {
    return super.cachedDataChanges(siteIds.sort().toString());
  }

  protected getDataFromServer(siteIds: string): Observable<HandoverHistoryWebAppData[]> {
    const query = Object.assign(new HandoverHistoryQuery(), { SiteIds: siteIds.split(',') });
    return this.handoverEndpointService.HandoverHistoryQuery(query);
  }
}

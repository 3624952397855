import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { UserIdentityService } from '../authentication';
import { filter } from 'rxjs';

// Route guard for ensuring that the user authorised to view a particular page.
@Injectable()
export class AuthorisedGuard implements CanActivate {
  /** Stores the previous url the user has accessed */
  private previousUrl: string = undefined;

  constructor(
    private userIdentityService: UserIdentityService,
    private router: Router
  ) {
    // stores the previous url
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = event.url;
    });
  }

  // If the user is not authorised to view the page then they will be redirected to the denied page
  // Exception: Payroll Officer are only allowed to view the Timesheet Reports
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var claims = route.data.claims;
    const user = this.userIdentityService.currentUser();
    // if the claims are not defined then it will use the claims from the user
    if (!claims) claims = user.claims;
    if (!user) { return false; }
    const authorised = claims.every(c => user.claims.includes(c));
    if (!authorised) {
      this.router.navigate(['/denied']);
    }
    // if the user is on the Collective Report page and they click on the Timesheet Items tab then it will redirect them to the denied page
    if (this.previousUrl === '/timesheet/reports/collective' && state.url === '/timesheet/items') {
      return false;
    }
    // If it detects that the user is a Payroll Officer then it will redirect them to the Collective Report page
    if (user.roles[0] === 'Payroll Officer') {
      if (state.url === '/home' || state.url.includes('helpcentre/') || state.url.includes('/timesheet/items')) {
        this.router.navigate(['/timesheet/reports/collective']);
      }
      switch (state.url) {
        case '/timesheet/reports/collective':
        case '/timesheet/reports/individual':
        case '/timesheet/reports/cumulative':
          break;
        default:
          return true;
      }
      return;
    }

    return authorised;
  }
}

export const colors: { [key: string]: string} = {
  lightGrey: '#efefef',
  positive: '#45B975',
  calm: '#d0d0d0',
  assertive: '#d0021b',
  carrot: '#e67e22',
  tomato: '#ee684b',
  river: '#3498db',
  lightBlue: '#8bc4ea',
  emerald: '#2ecc71',
  amethyst: '#9b59b6',
  sunflower: '#f1c40f',
  pumpkin: '#d35400',
  turquoise: '#1abc9c',
  lightGold: '#ffe993',
};

<div class="prompt-modal">
  <form name="undismantleScaffoldForm" [formGroup]="form">
    <hub-modal-form-header title="Undismantle Scaffold" (close)="bsModalRef.hide()"></hub-modal-form-header>
    <div class="modal-body">
      <p>Are you sure you want to "un-dismantle" the scaffold?
      </p>

    </div>
    <hub-modal-form-footer [requiredNote]="false" saveText="Yes undismantle it" cancelText="Cancel" [errorMessage]="serverError"
      [inProgress]="saveInProgress" (submit)="onSubmit()" (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </form>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationQueryService } from 'app/core/services/configuration-query.service';
import { SiteScaffoldDiaryQueryService } from 'app/core/services/site-scaffold-diary-query.service';
import { each, map } from 'lodash';
import { combineLatest } from 'rxjs';
import { ConfigurationData, DiaryDataTypeEnum, ScaffoldWebAppData, SiteScaffoldDiaryData, SiteWebAppData } from 'app/core/hub-api';
import { InsightBaseComponent } from 'app/core/components/insight/insight-base.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AddSiteDiaryModalComponentContext } from '../add-site-diary-modal/add-site-diary-modal-context';
import { AddSiteDiaryModalComponent } from '../add-site-diary-modal/add-site-diary-modal.component';
import { SiteDetailQueryService } from 'app/sites/services/site-detail-query.service';
import { mergeMap } from 'rxjs/operators';
import { SiteScaffoldQueryService } from 'app/sites/services/site-scaffolds-query.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@AutoUnsubscribe()
@Component({
  selector: 'hub-scaffold-diary-list',
  templateUrl: './diary-list.component.html',
  styleUrls: ['./diary-list.component.scss']
})
export class DiaryListComponent extends InsightBaseComponent implements OnInit, OnDestroy {
  siteReference: string;

  siteScaffoldDiaries: SiteScaffoldDiaryData[];
  filteredScaffoldDiaries: SiteScaffoldDiaryData[];
  diaryDataTypes = DiaryDataTypeEnum;
  diaryCategories: any[];
  allScaffolds: ScaffoldWebAppData[];

  filtersForm: UntypedFormGroup;
  searchTermFormControl: UntypedFormControl;

  currentSite: SiteWebAppData;

  constructor(
    private sitediaryQueryService: SiteScaffoldDiaryQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private route: ActivatedRoute,
    private router: Router,
    private siteDetailQueryService: SiteDetailQueryService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private bsModalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.searchTermFormControl = new UntypedFormControl();

    this.filtersForm = new UntypedFormGroup({
      searchTerm: this.searchTermFormControl
    });

    this.searchTermFormControl.valueChanges.subscribe(() => {
      this.setFilteredScaffoldDiaries();
    });
    this.siteReference = this.route.parent.parent.snapshot.params['siteReference'];

    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(this.siteReference);
    const scaffoldsChanges = siteDetailChanges.pipe(mergeMap(site => this.siteScaffoldQueryService.siteScaffoldDataChanges(site.SiteId)));

    const obs$ = combineLatest([
      this.sitediaryQueryService.siteScaffoldDiaryDataChanges(this.siteReference),
      this.configurationQueryService.configurationDataChanges(),
      this.siteDetailQueryService.siteDetailQuery(false, this.siteReference),
      scaffoldsChanges,
    ]);
    obs$.subscribe(latest => {
      this.refreshComponent(latest[0], latest[1], latest[2], latest[3]);
    });
  }

  refreshComponent(_siteScaffoldDiaries: SiteScaffoldDiaryData[], configuration: ConfigurationData, site: SiteWebAppData, scaffolds: ScaffoldWebAppData[]): void {
    this.allScaffolds = scaffolds;
    this.currentSite = site;
    this.siteScaffoldDiaries = _siteScaffoldDiaries;

    this.diaryCategories = map(configuration.DiaryCategories).sort((a, b) => (a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1));

    // Show diaries belonging to any category to begin with.
    each(this.diaryCategories, c => (c.selected = true));
    this.setFilteredScaffoldDiaries();
  }

  private setFilteredScaffoldDiaries(): void {
    if (!this.siteScaffoldDiaries) {
      return;
    }

    let filteredScaffoldDiaries = this.siteScaffoldDiaries;

    // Filter by search term.
    if (this.searchTermFormControl.value) {
      filteredScaffoldDiaries = filteredScaffoldDiaries.filter(d => {
        const keys = Object.keys(d);
        return keys.some(key =>
          (d[key] || '')
            .toString()
            .toLowerCase()
            .includes(this.searchTermFormControl.value.toLowerCase())
        );
      });
    }

    // Filter by category
    const selectedCategoryIds = this.diaryCategories.filter(c => c.selected).map(c => c.DiaryCategoryId);
    filteredScaffoldDiaries = filteredScaffoldDiaries.filter(d => selectedCategoryIds.includes(d.DiaryCategoryId));

    this.filteredScaffoldDiaries = filteredScaffoldDiaries;
  }

  toggleCategory(category: any): void {
    category.selected = !category.selected;
    this.setFilteredScaffoldDiaries();
  }

  /**
   * Navigates to the diary details page
   *
   * @param {*} event
   * @memberof ScaffoldDiaryListComponent
   */
  navigateTo(event): void {
    if (event.type === 'click') {
      const id = this.getId(event);
      this.router.navigate([`${event.row.DiaryId}/${event.row.Type}/${id}`], { relativeTo: this.route });
    }
  }

  /**
   * Gets the site ID or scaffold ID depending on the type
   *
   * @param {*} event
   * @returns {string}
   * @memberof ScaffoldDiaryListComponent
   */
  getId(event): string {
    if (event.row.Type === 0) {
      return this.currentSite.SiteId;
    }
    return this.allScaffolds[0].ScaffoldId;
  }

  /**
   * When user clicks add site diary this will open the add site diary modal
   *
   * @memberof ScaffoldDiaryListComponent
   */
  onAddSiteDiary(): void {
    const context: AddSiteDiaryModalComponentContext = {
      siteReference: this.currentSite.SiteReference,
      diaryCategories: this.diaryCategories,
      site: this.currentSite
    };
    this.bsModalService.show(AddSiteDiaryModalComponent, SSModalConfig.generate(context));
  }


  ngOnDestroy(): void { }
}

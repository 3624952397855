import { AddEditStaffModalComponent } from '../../../shared/components/add-edit-staff-modal/add-edit-staff-modal.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import {
  ConfigurationData,
  DepotData,
  StaffData,
  StaffDetailsWebAppData,
} from 'app/core/hub-api';
import { LoggedInStaffService } from '../../services/logged-in-staff-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { AuthenticationEventsService, OnlineSessionService, User, UserIdentityService } from 'app/core/authentication';

@AutoUnsubscribe()
@Component({
  selector: 'hub-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  authenticated: boolean;
  username: string;
  displayName: string;

  loggedInStaff: StaffDetailsWebAppData;
  staffs: StaffData[];
  configuration: ConfigurationData;
  depots: DepotData[];

  setupComplete: boolean;

  constructor(
    private authenticationEventsService: AuthenticationEventsService,
    private userIdentityService: UserIdentityService,
    private onlineSessionService: OnlineSessionService,
    private router: Router,
    private loggedInStaffService: LoggedInStaffService,
    private bsModalService: BsModalService,
  ) { }

  ngOnInit(): void {
    const obs$ = combineLatest(
      [this.authenticationEventsService.userUpdatedEvent.asObservable(),
      this.loggedInStaffService.loggedInStaffChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1]));
  }

  refreshComponent(
    user: User,
    loggedInStaff: StaffDetailsWebAppData,
  ): void {
    this.loggedInStaff = loggedInStaff;

    this.authenticated = this.userIdentityService.isAuthenticated();
    this.username = this.loggedInStaff ? this.loggedInStaff.EmailAddress : '';
    this.displayName = this.loggedInStaff ? this.loggedInStaff.ContactName : '';
  }

  logout(): void {
    this.onlineSessionService.logout();
  }

  ngOnDestroy(): void { }

  onEditProfile(): void {
    const context = {
      editMode: true,
      staff: this.loggedInStaff,
      staffs: this.staffs,
      configuration: this.configuration,
      depots: this.depots,
      loggedInStaff: this.loggedInStaff
    };
    this.bsModalService.show(AddEditStaffModalComponent, SSModalConfig.generate(context));
  }
}

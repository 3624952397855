<a (click)="onTogglePanel()" class="btn btn-default btn-inverse" id="user-help"
    [ngClass]="{'help-side-button': !togglePanel, 'help-side-button-close': togglePanel}">
    <i class="fa" [ngClass]="{'fa-info': !togglePanel, 'fa-solid fa-xmark': togglePanel}"></i>
</a>
<div class="help-panel animate-show" *ngIf="togglePanel">
    <div class="help-body">
        <div class="scrollable-content">
            <div class="help-title">
                <h2>{{title}}</h2>
            </div>
            <div class="help-main">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
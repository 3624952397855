
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, map, some } from 'lodash';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import {
  ConfigurationData,
  ContactTypeData,
  ContractCommandService,
  ContractContactData,
  ContractData,
  CustomerWebAppData,
  ReportSubscriptionTypesEnum,
  ScheduleTypeEnum,
  SiteWebAppData,
  CompanyDetails
} from 'app/core/hub-api';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { AddEditCompanyContactModalContext } from '../../../../models/add-edit-company-contact-modal-context';
import { ScheduledReportsStartGeneratorService } from '../../../../services/scheduled-reports-start-generator.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditCompanyContactModalComponent } from '../add-edit-company-contact-modal/add-edit-company-contact-modal.component';
import { customerContactsHelpPanelData } from '../../../../values/contracts-help-panel-data';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { contactTypeIds } from '../../../../../shared/values/contact-type-ids';
import { AddEditBccContactModalContext } from 'app/sites/models/add-edit-bcc-contact-modal-context';
import { AddEditBccContactModalComponent } from '../add-edit-bcc-contact-modal/add-edit-bcc-contact-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';

@AutoUnsubscribe()
@Component({
  selector: 'hub-site-company-contacts',
  templateUrl: './site-company-contacts.component.html',
  styleUrls: ['./site-company-contacts.component.scss']
})

export class SiteCompanyContactsComponent implements OnInit, OnDestroy {
  @Input()
  selectedContract: ContractData;

  contract: ContractData;
  activeCompanyContacts: ContractContactData[];

  site: SiteWebAppData;
  siteReference: string;

  customers: CustomerWebAppData[];
  configuration: ConfigurationData;

  contactTypes: { [key: string]: ContactTypeData };
  activeBccContacts: ContractContactData[];

  contractContactsChanged: boolean;
  updateInProgress: boolean;

  contractContactsForm: UntypedFormGroup;
  contractContactsFormArray: UntypedFormArray;
  scheduledReportsIntervalFormControl: UntypedFormControl;

  scheduleTypeEnum: any;

  contactHelpPanelData = customerContactsHelpPanelData;
  selectedHelp = customerContactsHelpPanelData[0];

  showCustomerContacts: boolean;

  companyDetails: CompanyDetails;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private customersQueryService: CustomersQueryService,
    private scheduledReportsStartGeneratorService: ScheduledReportsStartGeneratorService,
    private contractCommandService: ContractCommandService,
    public modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.siteReference = this.route.parent.parent.snapshot.params['siteReference'];
    const obs$ = combineLatest(
      [this.siteDetailQueryService.siteDetailDataChanges(this.siteReference),
      this.configurationQueryService.configurationDataChanges(),
      this.route.params,
      this.customersQueryService.customersDataChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2]['contractReference'], latest[3]));
  }

  refreshComponent(
    site: SiteWebAppData,
    configuration: ConfigurationData,
    contractReference: string,
    customers: CustomerWebAppData[]
  ): void {
    let contract;
    this.companyDetails = configuration.CompanyDetails;
    if (this.selectedContract && this.selectedContract.ContractReference) {
      contract = cloneDeep(site.Contracts.find(c => c.ContractReference === this.selectedContract.ContractReference));
    }
    // When updating the contract reference it may temporarily not match any contract.
    if (!contract) {
      return;
    }
    this.contract = contract;

    this.site = site;
    this.customers = customers;
    this.configuration = configuration;
    this.scheduleTypeEnum = ScheduleTypeEnum;
    (this.contract as any).name = customers.find(c => c.CustomerId === this.contract.CustomerId).CustomerName;

    this.activeCompanyContacts = map(this.contract.ContractContacts)
      .filter(c => !c.IsInactive && c.ContactTypeId === contactTypeIds.companyContact && c.ContactTypeId !== contactTypeIds.bccContact)
      .sort((a, b) => (a.ContactName.toLowerCase() > b.ContactName.toLowerCase() ? 1 : -1));
    this.contactTypes = configuration.ContactTypes;
    this.contractContactsChanged = false;
    this.updateInProgress = false;

    this.activeBccContacts = map(this.contract.ContractContacts)
      .filter(c => !c.IsInactive && c.ContactTypeId === contactTypeIds.bccContact);

    this.contractContactsFormArray = new UntypedFormArray(this.activeCompanyContacts.map(c => this.createFormGroup(c)));

    this.contractContactsFormArray.valueChanges.subscribe(contactValues => {
      this.contractContactsChanged = this.formValuesChanged(
        contactValues,
        this.scheduledReportsIntervalFormControl.value
      );
    });

    const scheduledReportsInterval = this.activeCompanyContacts.length
      ? this.activeCompanyContacts[0].ScheduleType
      : ScheduleTypeEnum.Weekly;
    this.scheduledReportsIntervalFormControl = new UntypedFormControl(scheduledReportsInterval);

    this.scheduledReportsIntervalFormControl.valueChanges.subscribe(interval => {
      this.contractContactsChanged = this.formValuesChanged(this.contractContactsFormArray.value, interval);
    });

    this.contractContactsForm = new UntypedFormGroup({
      contractContacts: this.contractContactsFormArray,
      scheduledReportsInterval: this.scheduledReportsIntervalFormControl
    });
  }

  createFormGroup(contact: ContractContactData): UntypedFormGroup {
    return new UntypedFormGroup({
      handoverCertificates: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.HandoverCertificate)
      ),
      variationInstructions: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.VariationInstructions)
      ),
      inspectionsCompleted: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionsCompleted)
      ),
      inspectionStatuses: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionStatuses)
      ),
      subContractorInspections: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SubContractorInspections)
      ),
      detailedInspection: new UntypedFormControl(
        !!(contact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SiteInspectionsCompleted)
      )
    });
  }

  addEditContractContact(editMode: boolean, contractContact?: ContractContactData): void {
    const context: AddEditCompanyContactModalContext = Object.assign(new AddEditCompanyContactModalContext(), {
      editMode: editMode,
      chooseContractMode: false,
      site: this.site,
      contract: this.contract,
      customers: this.customers,
      configuration: this.configuration,
      contractContact: contractContact
    });
    this.modalService.show(AddEditCompanyContactModalComponent, SSModalConfig.generate(context));
  }

  onEditContractContact(contractContact: ContractContactData): void {
    this.addEditContractContact(true, contractContact);
  }

  onAddContractContact(): void {
    this.addEditContractContact(false);
  }

  onSaveContractContacts(formValues: any): void {
    this.updateInProgress = true;

    const results: Observable<any>[] = formValues.contractContacts.map((contactControl, i) => {
      const reportSubscriptionTypes = this.getReportSubscriptionType(contactControl);

      const command = {
        ContractContactId: this.activeCompanyContacts[i].ContractContactId,
        ContactName: this.activeCompanyContacts[i].ContactName,
        EmailAddress: this.activeCompanyContacts[i].EmailAddress,
        PhoneNumber: this.activeCompanyContacts[i].PhoneNumber,
        ContractId: this.contract.ContractId,
        ReceiveInspectionReports: true,
        IsInactive: false,
        ScheduleType: formValues.scheduledReportsInterval,
        ScheduledReportStartDate: this.scheduledReportsStartGeneratorService.getStartDate(
          formValues.scheduledReportsInterval
        ),
        ReportSubscriptionTypes: reportSubscriptionTypes,
        ContactTypeId: this.activeCompanyContacts[i].ContactTypeId
      };
      return this.contractCommandService.EditContractContactCommand(command);
    });

    const obs$ = forkJoin([...results]);
    obs$.subscribe(() => {
      // Reload any effected data.
      this.siteDetailQueryService.siteDetailQuery(false, this.siteReference);
      this.updateInProgress = false;
    });
  }

  getContactTypeFor(contractContact: ContractContactData): string {
    let contactType;
    if (contractContact) {
      contactType = this.contactTypes[contractContact.ContactTypeId];
    }
    return contactType ? contactType.Title : '';
  }

  private getReportSubscriptionType(contactFormValue: any): ReportSubscriptionTypesEnum {
    return (
      ReportSubscriptionTypesEnum.HandoverCertificate * +contactFormValue.handoverCertificates +
      ReportSubscriptionTypesEnum.VariationInstructions * +contactFormValue.variationInstructions +
      ReportSubscriptionTypesEnum.InspectionsCompleted * +contactFormValue.inspectionsCompleted +
      ReportSubscriptionTypesEnum.InspectionStatuses * +contactFormValue.inspectionStatuses +
      ReportSubscriptionTypesEnum.SubContractorInspections * +contactFormValue.subContractorInspections +
      ReportSubscriptionTypesEnum.SiteInspectionsCompleted * +contactFormValue.detailedInspection
    );
  }


  addEditBccContact(editMode: boolean, contractContact?: ContractContactData): void {
    const context: AddEditBccContactModalContext = Object.assign(new AddEditBccContactModalContext(), {
      editMode: editMode,
      site: this.site,
      contract: this.contract,
      contractContact: contractContact
    });
    this.modalService.show(AddEditBccContactModalComponent, SSModalConfig.generate(context));
  }

  onEditBccContact(contractContact: ContractContactData): void {
    this.addEditBccContact(true, contractContact);
  }

  onAddBccContact(contractContact: ContractContactData): void {
    this.addEditBccContact(false, contractContact);
  }

  // TODO: What is the scheduledReportInterval for???
  formValuesChanged(contactFormValues: any[], scheduledReportInterval: ScheduleTypeEnum): boolean {
    const contactsChanged = some(
      contactFormValues,
      (contact, i) => this.getReportSubscriptionType(contact) !== this.activeCompanyContacts[i].ReportSubscriptionTypes
    );
    return contactsChanged;
  }

  confirmLeave(): void {
    const context: SSDialogContext = {
      title: 'Unsaved Changes',
      body: 'You have unsaved changes. Carry on and lose the changes?',
      dialogClass: 'modal-dialog modal-lg',
      headerClass: 'modal-header confirm-modal-header',
      okBtnText: 'Yes carry on',
      cancelBtnText: 'No stop so I can save my changes',
      footerClass: 'modal-footer confirm-modal-footer'
    };
    this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context));
  }

  ngOnDestroy(): void { }

  onSelectHelp(helpItem): void {
    this.selectedHelp = helpItem;
  }
}

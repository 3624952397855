import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { ActivityTableComponent } from './components/activity-table/activity-table.component';
import { ActivityTabComponent } from './components/activity-tab/activity-tab.component';
import { SitesModule } from 'app/sites/sites.module';
import { CustomerApprovalImportModalComponent } from './modals/customer-approval-file-import/customer-approval-file-import.component';
import { IdToActivityPipe } from 'app/shared/pipes/id-to-activity.pipe';

@NgModule({
    declarations: [
        ActivityTabComponent, 
        ActivityTableComponent, 
        CustomerApprovalImportModalComponent
    ],
    imports: [
        CommonModule, 
        NgxDatatableModule, 
        SharedModule, 
        SitesModule
    ],
    entryComponents: [
        CustomerApprovalImportModalComponent
    ],
    exports: [
        ActivityTabComponent, 
        ActivityTableComponent
    ],
    providers: [
        IdToActivityPipe
    ]
})
export class ActivityModule { }

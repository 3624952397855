
export interface AuthenticationModuleSettings {
  production: boolean;
  apiServiceBaseUri: string;
  adalB2C: AdalB2CSettings;
  multiTenantUrl: string;
  appUrl: string;
  featureFlags: { [key: string]: boolean };
  customerTitle?: string;
}

export interface AdalB2CSettings {
  tenantName: string;
  redirectUri: string;
  clientId: string;
  policy: string;
  appResourceURI: string;
  adb2cBaseOAuthUri: string;
}

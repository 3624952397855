import { LoggedInStaffService } from '../../../../../core/services/logged-in-staff-service';
import { combineLatest } from 'rxjs';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { StaffsQueryService } from '../../../../../core/services/staffs-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { AddEditScaffoldModalComponent } from '../../scaffolds/add-edit-scaffold-modal/add-edit-scaffold-modal.component';
import { AddEditContractContactModalContext } from '../../../../models/add-edit-contract-contact-modal-context';
import { AddMeAsContactModalComponent } from '../../contracts/add-me-as-contact-modal/add-me-as-contact-modal.component';
import { AddEditAreaModalComponent } from '../add-edit-area-modal/add-edit-area-modal.component';
import { AddEditSiteStaffModalComponent } from '../add-edit-site-staff-modal/add-edit-site-staff-modal.component';
import { AddEditContractModalComponent } from '../../contracts/add-edit-contract-modal/add-edit-contract-modal.component';
import { DepotsQueryService } from '../../../../../core/services/depots-query.service';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditSiteModalComponent } from '../../../sites-list/add-edit-site-modal/add-edit-site-modal.component';
import { ActivatedRoute } from '@angular/router';
import {
  SiteWebAppData,
  DepotData,
  SiteStatusData,
  ConfigurationData,
  StaffData,
  CustomerWebAppData,
  ScaffoldWebAppData,
  StaffDetailsWebAppData,
} from 'app/core/hub-api';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, flatMap } from 'lodash';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { mergeMap } from 'rxjs/operators';
import { siteStatusIds } from 'app/shared/values/site-status-ids';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddEditContractContactModalComponent } from '../../contracts/add-edit-contract-contact-modal/add-edit-contract-contact-modal.component';


@AutoUnsubscribe()
@Component({
  selector: 'hub-site-detail-overview',
  templateUrl: './site-detail-overview.component.html',
  styleUrls: ['./site-detail-overview.component.scss']
})
export class SiteDetailOverviewComponent implements OnInit, OnDestroy {
  site: SiteWebAppData;
  configuration: ConfigurationData;
  depots: DepotData[];
  sitesStatuses: SiteStatusData[];
  staffs: StaffData[];
  customers: CustomerWebAppData[];
  scaffolds: ScaffoldWebAppData[] = [];
  loggedInStaff: StaffDetailsWebAppData;

  siteSetupPercentage: number;
  siteSetupComplete: boolean;
  progressWidth: string;

  totalAreas: number;
  totalContracts: number;
  totalContractContacts: number;
  openContracts: number;
  activeContractContacts: number;
  activeStaff: number;

  siteDepotName: string;
  sitesLimit: number;
  isStockControlAllowed: boolean;
  maxSupportedOpensites: number;
  isStatusVisible: boolean;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private depotsQueryService: DepotsQueryService,
    private customersQueryService: CustomersQueryService,
    private staffsQueryService: StaffsQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private siteScaffoldsQueryService: SiteScaffoldQueryService,
    private loggedInStaffService: LoggedInStaffService,
    private CompanySettingsService: CompanySettingsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.snapshot.params['siteReference'];

    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(siteReference);
    const scaffoldsChanges = siteDetailChanges.pipe(mergeMap(site => this.siteScaffoldsQueryService.siteScaffoldDataChanges(site.SiteId)));
    // Scaffolds will load in the background.
    scaffoldsChanges.subscribe(scaffolds => (this.scaffolds = scaffolds));
    const obs$ = combineLatest([
      siteDetailChanges,
      this.depotsQueryService.depotsDataChanges(),
      this.configurationQueryService.configurationDataChanges(),
      this.customersQueryService.customersDataChanges(),
      this.staffsQueryService.staffsDataChanges(),
      this.loggedInStaffService.loggedInStaffChanges()
    ]);
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2], latest[3], latest[4], latest[5]));
  }

  refreshComponent(
    site: SiteWebAppData,
    depots: DepotData[],
    configuration: ConfigurationData,
    customers: CustomerWebAppData[],
    staffs: StaffData[],
    loggedInStaff: StaffDetailsWebAppData
  ): void {
    this.loggedInStaff = loggedInStaff;
    this.staffs = staffs;
    this.customers = customers;
    this.site = site;
    this.depots = depots;
    // siteDepotName was created for the case when the depot name is edited in the settings screen it takes the depot instead of the sites depot name.
    this.siteDepotName = this.depots.find(c => c.SiteDepotId === this.site.SiteDepotId).DepotName;
    this.configuration = configuration;
    this.sitesStatuses = map(configuration.SiteStatuses);
    this.totalAreas = map(site.SiteAreas).length;
    this.totalContracts = site.Contracts.length;
    this.totalContractContacts = flatMap(site.Contracts, c => map(c.ContractContacts)).length;
    this.openContracts = site.Contracts.filter(c => c.ContractStatusId === contractStatusIds.open).length;
    this.activeContractContacts = flatMap(site.Contracts, c => map(c.ContractContacts)).filter(c => !c.IsInactive).length;
    this.activeStaff = site.Staff.filter(s => !s.Deleted).length;

    this.siteSetupPercentage = (() => {
      const noOfSteps = 4;
      const noOfStepsCompleted = +(this.totalContracts > 0) + +(this.totalContractContacts > 0) + +(this.activeStaff > 0) + +this.site.HasScaffolds;
      return (100 / noOfSteps) * noOfStepsCompleted;
    })();

    this.siteSetupComplete = this.siteSetupPercentage === 100;
    this.progressWidth = `${this.siteSetupPercentage}%`;

    this.CompanySettingsService.getCompanyDetailsFromServer().subscribe(clientSettings => {
      this.isStatusVisible = clientSettings.FeatureFlags.stockControl;
      this.maxSupportedOpensites = clientSettings.MaxOpenSites;
      if (this.isStatusVisible) {
        if (this.site.IsManageStockControl) {
          this.isStockControlAllowed = this.site.IsManageStockControl;
        } else {
          this.isStockControlAllowed = this.site.IsManageStockControl;
        }
      }
    });
  }

  onEditSite(): void {
    // A bit of a hack in order to get type checking while also not having to define all the base class properties for our context.
    const isStockControl = this.isStockControlAllowed;
    this.sitesLimit = this.maxSupportedOpensites;
    this.configurationQueryService.configurationQueryFromServer().subscribe(configuration => {
      const noOfSites = configuration.CompanyStatisticData.NoOfOpenSite;

      if (this.site.SiteStatusId !== siteStatusIds.closed || noOfSites < this.sitesLimit) {
        const context = {
          editMode: true,
          configuration: configuration,
          depots: this.depots,
          loggedInStaff: this.loggedInStaff,
          site: this.site,
          stockControl: isStockControl
        };

        this.bsModalService.show(AddEditSiteModalComponent, SSModalConfig.generate(context));
      } else {
        const context = {
          editMode: true,
          NoOfOpenSites: noOfSites,
          ClientMaxSitesLimit: this.sitesLimit,
          configuration: configuration,
          depots: this.depots,
          loggedInStaff: this.loggedInStaff,
          type: 'site'
        };
        this.bsModalService.show(AddEditContractModalComponent, SSModalConfig.generate(context));
      }
    });
  }

  onAddContract(): void {
    const context = {
      editMode: false,
      customers: this.customers,
      site: this.site,
      configuration: this.configuration
    };

    this.bsModalService.show(AddEditContractModalComponent, SSModalConfig.generate(context));
  }

  onAddStaff(): void {
    const context = {
      site: this.site,
      staffs: this.staffs.filter(s => s.SiteDepotIds.includes(this.site.SiteDepotId))
    };
    this.bsModalService.show(AddEditSiteStaffModalComponent, SSModalConfig.generate(context));
  }

  onAddArea(): void {
    const context = {
      editMode: false,
      site: this.site
    };
    this.bsModalService.show(AddEditAreaModalComponent, SSModalConfig.generate(context));
  }

  onAddScaffold(): void {
    const context = {
      editMode: false,
      configuration: this.configuration,
      site: this.site,
      customers: this.customers,
      scaffolds: this.scaffolds,
      loggedInStaff: this.loggedInStaff,
    };
    this.bsModalService.show(AddEditScaffoldModalComponent, SSModalConfig.generate(context));
  }

  onAddContractContact(): void {
    const context: AddEditContractContactModalContext = Object.assign(new AddEditContractContactModalContext(), {
      contract: this.site.Contracts.find(c => !c.ContractContacts.length),
      editMode: false,
      chooseContractMode: true,
      site: this.site,
      customers: this.customers,
      configuration: this.configuration
    });
    var modal = this.bsModalService.show(AddEditContractContactModalComponent, SSModalConfig.generate(context));
    modal.onHide.subscribe(
      (contractId) => {
        if (contractId === null || typeof contractId !== 'string') return;
        this.onAddMeAsContact(contractId as string);
      },
      error => {
        console.error(error);
      }
    );
  }
  /**
   * Add the current logged in user as a contact to the contract
   * @param contractId 
   */
  onAddMeAsContact(contractId: string): void {
    const context = {
      contractId: contractId,
      editMode: false,
      loggedInStaff: this.loggedInStaff,
      site: this.site,
      contract: this.site.Contracts.find(c => !c.ContractContacts.length),
    };
    this.bsModalService.show(AddMeAsContactModalComponent, SSModalConfig.generate(context));
  }

  ngOnDestroy(): void { }
}

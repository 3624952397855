import { Component, ReflectiveInjector, Injector } from '@angular/core';
import { InsightMonitoringService } from '../../services/insight-monitoring.service';

@Component({
  template: '',
  providers: [InsightMonitoringService]
})
export class InsightBaseComponent {

    private insightMonitoringService: InsightMonitoringService;

    constructor() {
        // Manually retrieve the monitoring service from the injector
        // so that constructor has no dependencies that must be passed in from child
      const injector = Injector.create({providers: [
          { provide: InsightMonitoringService, deps: []}]});
          // InsightMonitoringService
        // ]);
        this.insightMonitoringService = injector.get(InsightMonitoringService);
        this.logNavigation();
    }

    private logNavigation(): void {
        this.insightMonitoringService.logPageView();
    }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { tap, map, flatMap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/user';
import { UserIdentityService } from './user-identity.service';
import { AuthService } from '../models/auth-service';
import { AuthResponse } from '../models/auth-response';
import { AccessTokenData } from '../models/access-token-data';
import { StaffEndpointService } from '../../hub-api/generated/api/staff-endpoint.service';
import { AUTHENTICATION_SETTINGS_TOKEN } from '../authentication-settings-token';
import { AuthenticationModuleSettings } from '../authentication-settings';
import { StaffDetailsWebAppData } from '../../hub-api/generated/model/query/staff-details-web-app-data';
import { AUTH_SERVICE_TOKEN } from '../auth-service-token';
import { AuthResponseErrorType } from '../enum/auth-response-error-type';
import { LocalStorageService } from 'angular-web-storage';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';
import { Router } from '@angular/router';
import userflow from 'userflow.js';
import { GenericErrorComponent } from 'app/core/components/generic-error/generic-error.component';

// Service for handling online sessions, ie. authentication, and configuration of user and client/customer settings.
@Injectable()
export class OnlineSessionService {
  private jwtHelper = new JwtHelperService();
  constructor(
    private userIdentityService: UserIdentityService,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthService,
    /* @Inject(JWT_HELPER_TOKEN) private jwtHelper: JwtHelperService, */
    private staffEndpointService: StaffEndpointService,
    @Inject(AUTHENTICATION_SETTINGS_TOKEN) private settings: AuthenticationModuleSettings,
    private http: HttpClient,
    private companySettingsService: CompanySettingsService,
    private router: Router,
  ) { }

  // Try and authenticate the user, and configure the logged in user if successful, otherwise throw an AuthResponseError.
  initialise(): Observable<AuthResponse> {
    // Clear existng user data.
    this.userIdentityService.updateUser(undefined);
    // Attempt to authenticate the user and then configure the logged in user.
    const initRequest = this.authService.initialise().pipe(
      tap(authResponse => {
        const expired = moment(authResponse.expires * 1000) < moment();
        if (expired) {
          throw {
            name: 'AuthResponseError',
            type: AuthResponseErrorType.ExpiredResponse,
            message: 'Session expired'
          };
        }
      }),
      flatMap(authResponse => {
        // Wait for configureLoggedInUser, but then pass the authResponse back into to the stream.
        return this.configureLoggedInUser(authResponse).pipe(map(() => authResponse));
      })
    );
    return initRequest;
  }

  // Redirect the user to the login page if requirePassword is true, ortherwise refresh the access token in the background.
  login(requirePassword: boolean): Observable<{ network; authResponse: AuthResponse }> {
    const loginRequest = this.authService.login(requirePassword);
    if (requirePassword) {
      loginRequest.subscribe(r => this.configureLoggedInUser(r.authResponse));
    }
    return loginRequest;
  }

  logout(): Observable<{ network }> {
    this.userIdentityService.updateUser(undefined);
    return this.authService.logout();
  }

  // Configure the logged in user settings and customer/client specific settings.
  private configureLoggedInUser(authResponse: AuthResponse): Observable<any> {
    // Decode our access token to get the client name and ID.
    const accessTokenData: AccessTokenData = this.jwtHelper.decodeToken(authResponse.access_token);
    const clientData = {
      clientName: accessTokenData.extension_ClientName,
      clientId: accessTokenData.extension_ClientId
    };

    // Set the user data that we currently have available - set the rest after querying the logged in staff.
    const tokenExpiryTime = moment(authResponse.expires * 1000);
    const userDetails = {
      roles: [],
      claims: [],
      accessToken: authResponse.access_token,
      expiryTime: tokenExpiryTime.toISOString()
    } as User;
    this.userIdentityService.updateUser(userDetails, false);

    const results$ = forkJoin(this.updateClientSettings(clientData), this.staffEndpointService.GetSingle(accessTokenData.oid));
    return results$.pipe(
      tap(async res => {
        // Update logged in user specific settings.
        const loggedInStaff: StaffDetailsWebAppData = res[1];
        const user: User = {
          roles: loggedInStaff.Roles,
          claims: loggedInStaff.Claims,
          username: loggedInStaff.EmailAddress,
          deviceId: accessTokenData.deviceId,
          accessToken: authResponse.access_token,
          lastLogin: moment().toISOString(),
          displayName: loggedInStaff.ContactName,
          userId: loggedInStaff.StaffId,
          expiryTime: tokenExpiryTime.toISOString()
        };
        this.userIdentityService.updateUser(user);
      }),
      catchError(error => {
        this.router.navigate(['/error'], { queryParams: { errorCode: error.status, errorMessage: error.message } });
        throw error;
      })
    );
  }

  // Update app settings specific to this client/customer, eg. feature flags, api URL.
  private updateClientSettings(clientData: { clientName; clientId }): Observable<any> {
    return this.companySettingsService.getCompanyDetailsForceFetchFromServer(clientData.clientId).pipe(map(data => data[0]));
  }
}

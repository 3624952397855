<div class="top-options">
  <button class="btn btn-large btn-danger top-btn" type="button" (click)="clearAllRows(1)">
    <i class="fa fa-times"></i> Clear all
  </button>
  <button class="btn btn-large button-inverse top-btn" type="button" (click)="undo()">
    <i class="fa fa-undo"></i> Undo Cell Changes
  </button>
  <button class="btn btn-large button-primary top-btn" type="button" (click)="createEmptyRows(1)">
    <i class="fa fa-plus"></i> Add new row
  </button>
</div>
<div class="container-fluid">
  <div>
    <angular-slickgrid gridId="importGrid" [columnDefinitions]="columnDefinitions" [gridOptions]="gridOptions"
      [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event.detail)"
      (sgOnCellChange)="onCellChanged($event.detail.eventData, $event.detail.args)"
      (sgOnBeforeEditCell)="handleOnBeforeEditCell($event.detail.eventData, $event.detail.args)">
    </angular-slickgrid>
  </div>
</div>

<div class="modal-footer">
  <div class="error-message">
    <span *ngIf="invalidForm || serverError" class="text-error text-left center-block"><i class="fa fa-warning"></i>
      {{ invalidForm || serverError }}</span>
  </div>

  <div class="table-legend">
    <div class="legend">
      <div class="invalid"></div>
      <div class="title">Invalid</div>
    </div>
    <div class="legend">
      <div class="autoFilled"></div>
      <div class="title">Default Value</div>
    </div>
    <div class="legend">
      <div class="deletedRow"></div>
      <div class="title">Deleted</div>
    </div>
  </div>
  <div class="modal-buttons">
    <button class="btn btn-large btn-inverse" type="button" (click)="onPrevious()" [disabled]="saveInProgress">
      <i class="fa fa-chevron-circle-left"></i> Back</button>
    <button class="btn btn-large button-primary" type="onSave" (click)="onSave()"
      [disabled]="saveInProgress || disableSaveBtn">
      <i [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"
        class="fa"></i>
      Save</button>
  </div>
</div>
import { SiteImportScaffoldsComponent } from '../site-import-scaffolds/site-import-scaffolds.component';
import { SiteImportContactsComponent } from '../site-import-contacts/site-import-contacts.component';
import { SiteImportContractComponent } from '../site-import-contract/site-import-contract.component';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { SiteImportSiteComponent } from '../site-import-site/site-import-site.component';
import { MultiStepFormStep } from '../../../../shared/models/multi-step-form-step';
import { SiteImportModalContext } from '../../../models/site-import-modal-context';
import { Component, OnInit } from '@angular/core';
import { JSONCasing } from 'json-casing';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-site-import-modal',
  templateUrl: './site-import-modal.component.html',
  styleUrls: ['./site-import-modal.component.scss']
})

export class SiteImportModalComponent implements OnInit {
  context: Partial<SiteImportModalContext>;

  file: File;
  reader: FileReader;
  errorMessage: string;
  showImportForm: boolean;

  steps: MultiStepFormStep[] | any;
  data: SiteImportModalData;

  errors = {
    problemLoadingFile: 'There has been a problem loading the file',
    noFileSelected: 'Please select a SMART Scaffolder Cloud import file.',
    wrongFileExtension: 'The file must be a SSCloudImport file.',
    unsupportedFileVersion: 'The selected file is corrupted or the file version is unsupported.',
  };

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;

    this.steps = [
      {
        title: 'Site Details',
        component: SiteImportSiteComponent
      },
      {
        title: 'Customer',
        component: SiteImportContractComponent
      },
      {
        title: 'Contacts',
        component: SiteImportContactsComponent
      },
      {
        title: 'Scaffolds',
        component: SiteImportScaffoldsComponent
      },
    ];
    this.data = {
      siteImportFile: undefined,
      importData: {} as any,
    };

    this.showImportForm = false;

    if (this.context.selectedEstimate) {
      this.startEstimateImport();
    } else {
      this.readUploadedFile();
    }
  }

  /** Reads the uploaded file and processes its content. */
  private readUploadedFile() {
    this.reader = new FileReader();
    this.reader.onload = (e) => {
      try {
        this.data.siteImportFile = JSONCasing.toPascal(JSON.parse((e.target as any).result));
      } catch (error) {
        this.errorMessage = this.errors.problemLoadingFile;
      }
    };
  }


  /**
   * Starts the import process for the selected estimate.
   * The selected estimate comes from the estimates table.
   */
  private startEstimateImport(): void {
    this.data.siteImportFile = this.context.selectedEstimate;
    this.showImportForm = true;
  }

  /**
   * Handles the start of the import process.
   * Checks if a file is selected, if it has the correct extension,
   * if the version is supported, and if there are any error messages.
   * If all conditions are met, it sets the showImportForm flag to true.
   */
  onStartImport(): void {
    // No file was selected.
    if (!this.file) {
      this.errorMessage = this.errors.noFileSelected;
      return;
    }
    // The file has the wrong extension.
    if ((this.file.name.split('.').pop()) !== 'SSCloudImport') {
      this.errorMessage = this.errors.wrongFileExtension;
      return;
    }
    // The version is not supported
    const majorVersion = this.data.siteImportFile.FileVersion.split('.')[0];
    if (majorVersion !== '1' && majorVersion !== '2') {
      this.errorMessage = this.errors.unsupportedFileVersion;
      return;
    }
    // There was an error while loading the file.
    if (this.errorMessage) {
      return;
    }
    this.showImportForm = true;
  }

  /**
   * Handles the file selection event.
   * 
   * @param event - The file selection event.
   */
  onFileSelected(event: Event): void {
    this.errorMessage = undefined;
    this.file = (event.target as any).files[0];
    this.reader.readAsText(this.file);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScaffoldEndpointService, ScaffoldTimelineData, ScaffoldTimelineQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries scaffold timeline data from the server (or in memory cache where specified) and allows
// consumer to subscribe to scaffold timeline data changes.
@Injectable()
export class ScaffoldTimelineQueryService extends QueryService<ScaffoldTimelineData[]> {

  constructor(private scaffoldEndpointService: ScaffoldEndpointService) { super(); }

  scaffoldTimelineQuery(useCached: boolean, scaffoldId: string): Observable<ScaffoldTimelineData[]> {
    return super.query(useCached, scaffoldId);
  }

  scaffoldTimelineDataChanges(scaffoldId: string): Observable<ScaffoldTimelineData[]> {
    return super.cachedDataChanges(scaffoldId);
  }

  protected getDataFromServer(scaffoldId: string): Observable<ScaffoldTimelineData[]> {
    const query = Object.assign(new ScaffoldTimelineQuery(), { ScaffoldId: scaffoldId });
    return this.scaffoldEndpointService.ScaffoldTimelineQuery(query);
  }
}

<div *ngIf="diaryCategories" dropdown [autoClose]="false" class="dropdown" (clickOutside)="bsDropdown.hide()">
  <a class="btn button-default" role="button" dropdownToggle>
    <span>Categories</span>
  </a>
  <div class="dropdown-menu" *dropdownMenu>
    <div [formGroup]="editCategoryForm" class="diary-categories-list">
      <div formArrayName="categories" *ngFor="let cat of editCategoryForm.controls.categories.controls; let i = index">
        <div [formGroupName]="i">
          <table class="table">
            <tr class="form-group" [ngClass]="{'has-error': editCategoryValidationErrors[i] }">
              <!-- Hide from dom rather than using ngIf to avoid issues with the clickOutside directive -->
              <td [ngClass]="{'hide' : cat.editing || cat.updating }" class="category-title">{{ diaryCategories[i].Title }}</td>
              <td [ngClass]="{'hide' : !cat.editing || cat.updating }" class="category-title category-title-edit">
                <input class="form-control" type="text" formControlName="title" ngxTrim>
                <p *ngIf="editCategoryValidationErrors[i]" class="text-error">{{ editCategoryValidationErrors[i] }}</p>
              </td>
              <td class="spinner-col" [ngClass]="{'hide' : !cat.updating}">
                <a>
                  <i class="fa fa-spinner fa-spin"></i>
                </a>
              </td>
              <td [ngClass]="{'hide' : cat.editing || cat.updating}">
                <a (click)="cat.editing = true">
                  <i class="fa fa-regular fa-pen-to-square"></i>
                </a>
              </td>
              <td (click)="onDeleteCategory(cat, i)" [ngClass]="{'hide' : cat.editing || cat.updating}">
                <a>
                  <i class="fa fa-regular fa-trash-can"></i>
                </a>
              </td>
              <td (click)="onEditCategory(cat, i)" [ngClass]="{'hide' : !cat.editing || cat.updating}">
                <a>
                  <i class="fa fa-check"></i>
                </a>
              </td>
              <td [ngClass]="{'hide' : !cat.editing || cat.updating}">
                <a (click)="cat.editing = false">
                  <i class="fa fa-times"></i>
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div [formGroup]="addCategoryForm" class="form-group add-category" [ngClass]="{'has-error': addCategoryError && addCategoryTitleFormControl.touched }">
      <input class="form-control" type="text" formControlName="title" ngxTrim>
      <button (click)="onAddCategory()" class="btn button-default pull-right" role="button" [disabled]="addingCategory">
        <span [ngClass]="{'hide' : addingCategory}">
          <i class="fa fa-plus"></i>Add
        </span>
        <span [ngClass]="{'hide' : !addingCategory}">
          <i class="fa fa-spinner fa-spin"></i>
        </span>
      </button>
      <p *ngIf="addCategoryError && addCategoryTitleFormControl.touched" class="text-error">
        <i class="fa fa-warning"></i> {{ addCategoryError }}</p>
    </div>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  SiteWebAppData } from 'app/core/hub-api';
import { ImageModalContext } from '../../../../shared/components/image-modal/image-modal-context';
import { ImageModalComponent } from '../../../../shared/components/image-modal/image-modal.component';
import { SiteDetailQueryService } from '../../../services/site-detail-query.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@Component({
  selector: 'hub-site-detail-container',
  templateUrl: './site-detail-container.component.html',
  styleUrls: ['./site-detail-container.component.scss']
})
export class SiteDetailContainerComponent implements OnInit {

  site: SiteWebAppData;
  contractReference: string;

  constructor(
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private siteDetailQueryService: SiteDetailQueryService,
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.snapshot.params['siteReference'];
    this.siteDetailQueryService.siteDetailDataChanges(siteReference).subscribe(updatedSite => this.refreshComponent(updatedSite));
  }

  refreshComponent(site: SiteWebAppData): void {
    this.site = site;
  }

  openImageModal(imageUrl): void {
    const context: ImageModalContext = { imageUrl: [imageUrl] };
    this.bsModalService.show(ImageModalComponent, SSModalConfig.generate(context));
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, map } from 'lodash';
import { Subscription, combineLatest } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';
import {
  ConfigurationData,
  ContractData,
  ContractInspectionStatus,
  CustomerWebAppData,
  InspectionStatusEnumEnum,
  SiteWebAppData,
  StaffDetailsWebAppData
} from 'app/core/hub-api';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { LoggedInStaffService } from '../../../../../core/services/logged-in-staff-service';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { customerStatusIds } from '../../../../../shared/values/customer-status-ids';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { siteStatusIds } from '../../../../../shared/values/site-status-ids';
import { InspectionStatusModalContext } from '../../../../models/inspection-status-modal-context';
import { OpenCloseContractModalContext } from '../../../../models/open-close-contract-modal-context';
import { ContractInspectionStatusQueryService } from '../../../../services/contract-inspection-status-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditContractModalComponent } from '../add-edit-contract-modal/add-edit-contract-modal.component';
import { InspectionStatusModalComponent } from '../inspection-status-modal/inspection-status-modal.component';
import { OpenCloseContractModalComponent } from '../open-close-contract-modal/open-close-contract-modal.component';
import { SiteContractContactsComponent } from '../site-contract-contacts/site-contract-contacts.component';
import { ConfirmLeaveComponent } from '../../../../../shared/models/confirm-leave';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSDialogContext, SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ModalDialogComponent } from 'app/shared/components/modal-dialog/modal-dialog.component';
import { CustomerDetailQueryService } from 'app/core/services/customer-detail-query.service';

@AutoUnsubscribe()
@Component({
  selector: 'hub-site-contract',
  templateUrl: './site-contract.component.html',
  styleUrls: ['./site-contract.component.scss']
})
export class SiteContractComponent implements OnInit, OnDestroy, ConfirmLeaveComponent {
  @ViewChild('contacts') contractContactsComponent: SiteContractContactsComponent;


  disposing: false;
  site: SiteWebAppData;
  customerReference: string;
  customers: CustomerWebAppData[];
  loggedInStaff: StaffDetailsWebAppData;
  configuration: ConfigurationData;
  selectedCustomer: any;

  allContracts: ContractData[];
  allCustomers: any;
  selectedContract: ContractData;
  selectedCustomerContracts: any;

  contractInspectionStatus: ContractInspectionStatus;
  inspectionStatusEnum: any;

  contractInspectionStatusesSubscription: Subscription;

  showAdvanced: boolean;
  showWeeklyInspections: boolean;
  contractInspectionStatuses: any;

  isLoading = false;

  constructor(
    public modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private contractInspectionStatusQueryService: ContractInspectionStatusQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private loggedInStaffService: LoggedInStaffService,
    private customerDetailQueryService: CustomerDetailQueryService,
  ) {
    // https://stackoverflow.com/questions/39533291/angular2-router-2-0-0-not-reloading-components-when-same-url-loaded-with-differe/39533351#39533351
    // since after editing contract the route does not change the url but only query string added.So the site is not getting updated,results in error.
    // hence the routeResuseStratgy is false to delete and re-render component even if query strig is updated
    this.router.routeReuseStrategy.shouldReuseRoute = function (): any {
      return false;
    };
  }

  /** Initialises the component */
  ngOnInit(): void {
    this.isLoading = true;
    const siteReference = this.route.parent.parent.snapshot.params['siteReference'];

    this.showAdvanced = false;

    const siteDetailChanges = this.siteDetailQueryService.siteDetailDataChanges(siteReference);
    const contractInspectionStatusChanges = siteDetailChanges.pipe(
      mergeMap(site =>
        // emit undefined until the data arrives, so we can display everything apart from the contract inspection status straight away.
        this.contractInspectionStatusQueryService.contractInspectionStatusDataChanges(site.SiteId).pipe(
          startWith(undefined)
        )
      )
    );
    const obs$ = combineLatest(
      [
        siteDetailChanges,
        this.route.params,
        this.customersQueryService.customersDataChanges(),
        contractInspectionStatusChanges,
        this.configurationQueryService.configurationDataChanges(),
        this.loggedInStaffService.loggedInStaffChanges()]
    );
    obs$.subscribe(latest => {
      const site = latest[0];
      const routeParams = latest[1];
      const customers = latest[2];
      const contractInspectionStatuses = latest[3];
      const configuration = latest[4];
      const loggedInStaff = latest[5];
      
      this.refreshComponent(
        site,
        routeParams.customerReference,
        customers,
        contractInspectionStatuses,
        configuration,
        loggedInStaff
      );
    });
  }

  /** Sets all initial values for displaying data on the UI */
  refreshComponent(site: SiteWebAppData, customerReference: string, customers: CustomerWebAppData[],
    contractInspectionStatuses: any, configuration: ConfigurationData,
    loggedInStaff: StaffDetailsWebAppData): void {

    this.loggedInStaff = loggedInStaff;
    this.configuration = configuration;
    const allContracts = site.Contracts.map((contract: any) => {
      // Don't modify the cached data object, clone it.
      contract = cloneDeep(contract);
      contract.name = customers.find(c => c.CustomerId === contract.CustomerId).CustomerName;
      return contract;
    });

    this.site = site;
    this.customers = customers;

    this.customerReference = customerReference ? customerReference : customers.find(c => c.CustomerId === this.site.Contracts[0].CustomerId).CustomerReference;
    this.allContracts = allContracts.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    const allCustomerIds = this.allContracts.map(c => c.CustomerId);
    this.allCustomers = customers.filter(c => allCustomerIds.includes(c.CustomerId));

    this.selectedCustomer = this.allCustomers.find(c => c.CustomerReference === customerReference);
    this.selectedCustomerContracts = this.allContracts.filter(c => c.CustomerId === this.selectedCustomer.CustomerId);

    this.contractInspectionStatuses = contractInspectionStatuses;

    this.inspectionStatusEnum = InspectionStatusEnumEnum;
    this.isLoading = false;
  }


  /** 
   * Returns the contract inspection statuses for a selected contract.
   * The contract inspection statuses are available only after the data arrives.
   */
  contractInspStatuses(selectedContract): any {
    // Ignore contractInspectionStatuses until the data arrives.
    if (this.contractInspectionStatuses) {
      const contractWithInspectionStatus = this.contractInspectionStatuses.find(
        c => c.ContractId === selectedContract.ContractId
      );
      if (!contractWithInspectionStatus) {
        return;
      }
      return contractWithInspectionStatus.InspectionStatus;
    }
  }



  /**
   * Determines whether a contract can be opened.
   * @param selectedContract The contract to be opened.
   * @returns True if the contract can be opened.
   */
  openValid(selectedContract: ContractData): boolean {
    const openContracts = map(this.site.Contracts).some(
      c =>
        c.ContractId !== selectedContract.ContractId &&
        c.ContractStatusId === contractStatusIds.open &&
        c.CustomerId === selectedContract.CustomerId
    );

    const customerDeleted =
      this.customers.find(c => c.CustomerId === selectedContract.CustomerId).CustomerStatusId ===
      customerStatusIds.inactive;

    const siteClosed = this.site.SiteStatusId === siteStatusIds.closed;

    return !openContracts && !customerDeleted && !siteClosed;
  }

  /** Returns a customer deleted error */
  customerDeletedError(selectedContract: ContractData): any {
    const customerDeleted = this.customers.find(c => c.CustomerId === selectedContract.CustomerId).CustomerStatusId === customerStatusIds.inactive;
    return customerDeleted ? errorMessages.cannotOpenContractForDeletedCustomer : '';
  }

  /** Returns a closed site error in case the site is closed */
  siteClosedError(selectedContract: ContractData): string {
    const siteClosed = this.site.SiteStatusId === siteStatusIds.closed;
    return siteClosed ? errorMessages.cannotOpenContractForClosedSite : '';
  }

  /** Opens the add edit contract modal */
  addEditContract(editMode: boolean, contract?: ContractData): void {
    const context = {
      editMode: editMode,
      customers: this.customers,
      site: this.site,
      contract: contract,
      configuration: this.configuration
    };

    const modal = this.modalService.show(AddEditContractModalComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe(
      customerReference => {
        if (customerReference && typeof customerReference === 'string') {
          this.customerDetailQueryService.customerDetailQuery(false, customerReference).subscribe(customer => {
            var customerContract = this.site.Contracts.find(c => c.CustomerId === customer.CustomerId);
            if (customerContract) this.router.navigate([`../${customerReference}`], { relativeTo: this.route });
          });
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  /** Opens the add contract modal */
  onAddContract(): void {
    this.addEditContract(false);
  }

  /** Opens the add edit contract modal */
  onEditContract(selectedContract: ContractData): void {
    this.addEditContract(true, selectedContract);
  }

  /** Detects if the given contract is closed */
  contractIsClosed(contract: ContractData): boolean {
    return contract && contract.ContractStatusId === contractStatusIds.closed;
  }

  /** Detects if the given contract has contacts */
  hasContacts(contract: ContractData): number {
    return contract && map(contract.ContractContacts).length;
  }

  /** Detects of the given contract contains at least one active contract contact */
  hasAtLeastOneActiveContractContact(contract: ContractData): boolean {
    return contract && map(contract.ContractContacts).some(c => c.IsInactive === false);
  }

  /** Stops the inspections for the given contract */
  stopInspections(selectedContract: ContractData): void {
    this.editInspectionStatus(false, true, false, selectedContract);
  }

  /** Pauses the inspections for the given contract */
  pauseInspections(selectedContract: ContractData): void {
    this.editInspectionStatus(true, false, false, selectedContract);
  }

  /** Resumes the inspections for the given contract */
  resumeInspections(selectedContract: ContractData): void {
    this.editInspectionStatus(false, false, true, selectedContract);
  }

  /** Opens the inspection status modal */
  editInspectionStatus(pauseMode: boolean, stopMode: boolean, resumeMode: boolean, selectedContract: ContractData): void {
    const context: InspectionStatusModalContext = Object.assign(new InspectionStatusModalContext(), {
      site: this.site,
      contract: selectedContract,
      customers: this.customers,
      pauseMode: pauseMode,
      stopMode: stopMode,
      resumeMode: resumeMode,
      loggedInStaff: this.loggedInStaff
    });
    const modal = this.modalService.show(InspectionStatusModalComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe({
      next: result => {
        this.contractInspectionStatusQueryService.contractInspectionStatusQuery(false, this.site.SiteId).subscribe({
          next: contractInspectionStatuses => {
            this.contractInspectionStatuses = contractInspectionStatuses;
          },
          error: error => {
            console.error(error);
          }
        });
      }
    });
  }
  /** Opens openCloseContract modal with openMode false */
  onCloseContract(selectedContract: ContractData): void {
    this.openCloseContract(false, selectedContract);
  }

  /** Opens openCloseContract modal with openMode true */
  onOpenContract(selectedContract: ContractData): void {
    if (!this.customerDeletedError(selectedContract)) {
      this.openCloseContract(true, selectedContract);
    }
  }

  /** Opens the close the contract modal */
  openCloseContract(openMode: boolean, selectedContract: ContractData): void {
    const context: OpenCloseContractModalContext = Object.assign(new OpenCloseContractModalContext(), {
      openMode: openMode,
      site: this.site,
      contract: selectedContract,
      customers: this.customers
    });
    this.modalService.show(OpenCloseContractModalComponent, SSModalConfig.generate(context));
  }

  /** Confirms if the user wants to leave the page with unsaved changes */
  shouldConfirmLeave(): boolean {
    return !!(this.contractContactsComponent && this.contractContactsComponent.contractContactsChanged);
  }

  /** Displays the confirm leave modal */
  displayConfirmLeaveModal(): Promise<any> {
    return new Promise(resolve => {
      const context: SSDialogContext = {
        title: 'Unsaved Changes',
        body: 'You have unsaved changes. Carry on and lose the changes?',
        dialogClass: 'modal-dialog modal-lg',
        headerClass: 'modal-header confirm-modal-header',
        okBtnText: 'Yes carry on',
        cancelBtnText: 'No stop so I can save my changes',
        footerClass: 'modal-footer confirm-modal-footer'
      };
      const modal = this.modalService.show(ModalDialogComponent, SSModalConfig.generate(context));
      modal.content.onClose.subscribe(result => {
        if (result === true) {
          resolve(result);
        }
      });
    });
  }

  /** Displays the confirm delete modal */
  onSelectCustomer(customerReference): void {
    this.router.navigate([`../${customerReference}`], { relativeTo: this.route });
  }

  /**
   * Returns if there is an error with the contract
   * This shows a warning icon next to the contract name so the user knows that there is a contract contact missing
   */
  contractValueError(selectedContract: ContractData): boolean {
    return (!this.hasContacts(selectedContract) && !this.contractIsClosed(selectedContract)) || !this.hasAtLeastOneActiveContractContact(selectedContract)
  }


  /** Required by the AutoUnsubscribe */
  ngOnDestroy(): void { }
}

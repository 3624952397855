import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmLeaveComponent } from 'app/shared/models/confirm-leave';

// Route guard for ensuring that a user confirms that they wish to leave a route (ie. they have unsaved changes).
@Injectable()
export class ConfirmLeaveGuard implements CanDeactivate<ConfirmLeaveComponent> {

  canDeactivate(component: ConfirmLeaveComponent): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (component && !component.disposing && component.shouldConfirmLeave()) {
        component.displayConfirmLeaveModal()
          .then(result => {
            component.disposing = result;
            resolve(result);
            // resets the disposing flag, so next time will ask the user if he really wants to leave
            component.disposing = undefined;
          },
            error => {
              console.log(error);
              resolve(false)
            });
      } else {
        resolve(true);
      }
    });
  }
}

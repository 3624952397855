export const customerContactsHelpPanelData = [
  {
    name: 'Handover Certificates',
    text: 'Sent shortly after they are submitted on the SMART Handover app.  Please note you will need an active internet connection.',
    featureFlag: 'handover'
  },
  {
    name: 'Detailed/Summary Inspection Reports',
    text: 'Sent daily if inspections were completed the previous day. Please ensure that you sync the device at the end of the day.',
    featureFlag: 'inspection'
  },
  {
    name: 'Inspection Status',
    text: 'Sent every Monday.',
    featureFlag: 'inspection'
  }
];



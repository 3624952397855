import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator to validate that the value of some control does not exist in a list of given values.
export function mustNotExistValidator(values: string[], caseSensitive = true): ValidatorFn {
  return (control: AbstractControl) => {
    values = caseSensitive ? values : values.map((v) => {
      if (v != null) { return v.toLowerCase(); }
    });
    const controlValue = caseSensitive || !control.value ? control.value : control.value.toLowerCase();
    const isValid = !values.includes(controlValue);
    return isValid ? null : {
      mustNotExist: {
        value: control.value
      }
    };
  };
}

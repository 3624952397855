import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureFlagDirective } from './feature-flag.directive';
import { FEATURE_FLAGS_TOKEN } from './feature-flags.token';

@NgModule({
  declarations: [
    FeatureFlagDirective
  ],
  exports: [
    FeatureFlagDirective
  ]
})
export class FeatureFlagsModule {
  constructor() { }
  static forRoot(featureFlags: () => { [key: string]: boolean }): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [
        {
          provide: FEATURE_FLAGS_TOKEN,
          useFactory: featureFlags
        }
      ]
    };
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersResolver } from '../core/resolvers/customers.resolver';
import { SitesResolver } from '../core/resolvers/sites.resolver';
import { SiteContractComponent } from './components/site-detail/contracts/site-contract/site-contract.component';
import {
  SiteContractsWelcomeComponent,
} from './components/site-detail/contracts/site-contracts-welcome/site-contracts-welcome.component';
import { SiteContractsComponent } from './components/site-detail/contracts/site-contracts/site-contracts.component';
import {
  SiteDashboardContractComponent,
} from './components/site-detail/dashboard/site-dashboard-contract/site-dashboard-contract.component';
import { SiteDashboardComponent } from './components/site-detail/dashboard/site-dashboard/site-dashboard.component';
import { SiteDetailComponent } from './components/site-detail/detail/site-detail/site-detail.component';
import { HandoversListComponent } from './components/site-detail/handovers/handovers-list/handovers-list.component';
import { ScaffoldDetailComponent } from './components/site-detail/scaffolds/scaffold-detail/scaffold-detail.component';
import { ScaffoldListComponent } from './components/site-detail/scaffolds/scaffold-list/scaffold-list.component';
import {
  SiteDetailContainerComponent,
} from './components/site-detail/site-detail-container/site-detail-container.component';
import { SitesListComponent } from './components/sites-list/sites-list/sites-list.component';
import { SitesSectionContainerComponent } from './components/sites-section-container/sites-section-container.component';
import { NoSiteContractsGuard } from './guards/no-site-contracts.guard';
import { ScaffoldDetailResolver } from './resolvers/scaffold-detail.resolver';
import { ScaffoldTimelineResolver } from './resolvers/scaffold-timeline.resolver';
import { SiteResolver } from './resolvers/site.resolver';
import { DiaryListComponent } from 'app/sites/components/site-detail/diary/diary-list/diary-list.component';
import { DiaryDetailComponent } from 'app/sites/components/site-detail/diary/diary-detail/diary-detail.component';
import { DiaryMediaViewerComponent } from 'app/sites/components/site-detail/diary/diary-media-viewer/diary-media-viewer.component';
import { DiaryDetailGuard } from 'app/sites/guards/diary-detail.guard';
import { DiaryMediaViewerPlaceholderComponent } from 'app/sites/components/site-detail/diary/diary-media-viewer-placeholder/diary-media-viewer-placeholder.component';
import { ConfirmLeaveGuard } from 'app/core/guards/confirm-leave.guard';
import { AuthenticatedGuard } from '../core/guards/authenticated-guard';
import { AuthorisedGuard } from '../core/guards/authorised-guard';
import { ConfigurationResolver } from '../core/resolvers/configuration.resolver';
import { DepotsResolver } from 'app/core/resolvers/depots.resolver';
import { StaffsResolver } from '../core/resolvers/staffs.resolver';
import { EulaSlaGuard } from 'app/core/guards/eula-sla.guard';

const routes: Routes = [
  {
    path: '',
    component: SitesSectionContainerComponent,
    canActivate: [EulaSlaGuard],
    resolve: {
      customers: CustomersResolver,
      configuration: ConfigurationResolver,
      depots: DepotsResolver,
      site: SiteResolver,
      staffs: StaffsResolver
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: SitesListComponent,
        resolve: {
          sites: SitesResolver
        }
      },
      {
        path: ':siteReference',
        component: SiteDetailContainerComponent,
        canActivate: [AuthenticatedGuard, AuthorisedGuard],
        data: {
          claims: ['AddEditSites']
        },
        resolve: {
          site: SiteResolver
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'details'
          },
          {
            path: 'dashboard',
            component: SiteDashboardComponent,
            children: [
              {
                path: '',
                component: SiteDashboardContractComponent
              },
              {
                path: ':customerReference',
                component: SiteDashboardContractComponent
              }
            ]
          },
          {
            path: 'details',
            component: SiteDetailComponent,
          },
          {
            path: 'contracts',
            component: SiteContractsComponent,
            children: [
              {
                path: '',
                component: SiteContractsWelcomeComponent,
                canActivate: [NoSiteContractsGuard]
              },
              {
                path: ':customerReference',
                component: SiteContractComponent,
                canDeactivate: [ConfirmLeaveGuard]
              }
            ]
          },
          {
            path: 'scaffolds',
            children: [
              {
                path: '',
                component: ScaffoldListComponent,
              },
              {
                path: ':scaffoldId',
                component: ScaffoldDetailComponent,
                resolve: {
                  scaffold: ScaffoldDetailResolver,
                  scaffoldTimeline: ScaffoldTimelineResolver
                },
              }
            ]
          },
          {
            path: 'handovers',
            component: HandoversListComponent,
          },
          {
            path: 'diary',
            children: [
              {
                path: '',
                component: DiaryListComponent,
              },
              {
                path: ':diaryId/:diaryType/:id',
                component: DiaryDetailComponent,

                children: [
                  {
                    path: '',
                    canActivate: [DiaryDetailGuard],
                    component: DiaryMediaViewerPlaceholderComponent
                  },
                  {
                    path: ':mediaType/:mediaReferenceId',
                    component: DiaryMediaViewerComponent,
                    canDeactivate: [ConfirmLeaveGuard],
                  },
                ]
              }
            ]
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SitesRoutingModule { }

<form name="contractContactForm" [formGroup]="form">
  <hub-modal-form-header [title]="modalTitle" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <hub-form-group *ngIf="chooseContractMode" label="Contract" name="contractId" [control]="contractIdFormControl"
      [validationErrors]="validationErrors">
      <select class="form-control" formControlName="contractId">
        <option value="">-- please select a contract --</option>
        <option *ngFor="let contract of openContracts" [value]="contract.ContractId">{{contract.optionName}}</option>
      </select>
    </hub-form-group>
    <hub-form-group label="Contact Name" name="contactName" [control]="contactNameFormControl"
      [validationErrors]="validationErrors">
      <input class="form-control" type="text" formControlName="contactName" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Contact Type" name="contactTypeId" [control]="contactTypeIdFormControl"
      [validationErrors]="validationErrors">
      <hub-dropdown formControlName="contactTypeId" [items]="contactTypes" idProperty="ContactTypeId"
        titleProperty="Title" (valueChanges)="onSelectedContactTypeChanged($event)" placeholder="Contact Type"
        [clearable]="false">
      </hub-dropdown>
    </hub-form-group>
    <hub-form-group label="Email Address" name="emailAddress" [control]="emailAddressFormControl"
      [validationErrors]="validationErrors">
      <input class="form-control" type="email" formControlName="emailAddress" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Phone Number" name="phoneNumber" [control]="phoneNumberFormControl"
      [validationErrors]="validationErrors">
      <input class="form-control" type="text" formControlName="phoneNumber" ngxTrim>
    </hub-form-group>
    <h4>Subscriptions</h4>
    <p>Select which reports you would like this contact to receive.</p>
    <div class="form-group text-smaller">
      <label *ssFeatureFlag="'handover'">
        <input type="checkbox" name="subscriptions" formControlName="handoverCertificate"> Handover Certificates
      </label>
      <label *ssFeatureFlag="'variation'">
        <input type="checkbox" name="subscriptions" formControlName="variationInstructions"> Variation Instructions
      </label>
      <label *ssFeatureFlag="'inspection'">
        <input type="checkbox" name="subscriptions" formControlName="inspectionsCompleted"> Inspection Summary Report
      </label>
      <label *ssFeatureFlag="'inspection'">
        <input type="checkbox" name="subscriptions" formControlName="detailedInspection"> Detailed Inspection Report
      </label>
      <label *ssFeatureFlag="'inspection'">
        <input type="checkbox" name="subscriptions" formControlName="inspectionStatuses"> Inspection Status Reports
      </label>
      <span *ngIf="principalContractSelected()">
        <label class="checkbox-inline" *ssFeatureFlag="'inspection'">
          <input type="checkbox" name="subscriptions" formControlName="subContractorInspections"> Subcontractor
          Inspection Reports
        </label>
      </span>
    </div>
    <div class="modal-options" *ngIf="editMode">
      <a *ngIf="editMode && !contractContact.IsInactive" class="text-danger animate-fade"
        (click)="deleteContractContact()">
        <i class="fa fa-times"></i> Delete contact</a>
      <span *ngIf="editMode && contractContact.IsInactive" class="animate-fade">
        <i class="fa fa-check"></i> This contact will be deleted. Click
        <a (click)="undoDeleteContractContact()" class="text-danger">undo</a> if this was a mistake!</span>
    </div>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>

import {mergeMap} from 'rxjs/operators';
import { AssetsEndpointService } from 'app/core/hub-api';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable ,  AsyncSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ImageUploadService {

  constructor(private assetsEndpointService: AssetsEndpointService, private http: HttpClient) { }

  uploadImage(imageFile: File, path: string): Observable<any> {
    const fileReader = new FileReader();
    const subject = new AsyncSubject();

    fileReader.onload = (e: any) => {
      const paths = {
        [path]: path
      };
      this.assetsEndpointService.GetUploadKeys(paths).pipe(mergeMap((results) => {
        const uploadKeys = results as any;
        return this.http.put(uploadKeys.UploadPaths[path], e.target.result, {
          headers: new HttpHeaders({
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': imageFile.type,
            'x-ms-blob-content-type': imageFile.type
          }),
        });
      })).subscribe(() => {
        subject.next(true);
        subject.complete();
      });
    };
    fileReader.readAsArrayBuffer(imageFile);

    return subject.asObservable();
  }
}

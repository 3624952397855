/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DiaryQueryService } from '../services/diary-query-service';
import { DiaryQuery } from 'app/core/hub-api';

/**
 * Route guard for redirecting to first (alphabetically) scaffold/site diary media.
 *
 * @export
 * @class DiaryDetailGuard
 * @implements {CanActivate}
 */
@Injectable()
export class DiaryDetailGuard implements CanActivate {

  constructor(private diaryQueryService: DiaryQueryService, private router: Router) { }

  /**
   * Allows/denies access to the diary pages
   *
   * @param {ActivatedRouteSnapshot} route contains the information about a route associated with a component
   * @param {RouterStateSnapshot} state represents the state of the router at a moment in time.
   * @returns {Observable<boolean>}
   * @memberof DiaryDetailGuard
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const diaryId = route.parent.params['diaryId'];
    const diaryType = route.parent.params['diaryType'];
    const diaryQuery = new DiaryQuery();
    diaryQuery.DiaryId = diaryId;
    diaryQuery.DiaryType = diaryType;

    return this.diaryQueryService.getDiaryData(diaryQuery).pipe(map(diary => {
      const _diary = diary[0];
      const medias: any[] = _diary.Images
        .concat(_diary.Audios, _diary.Videos, _diary.Transcriptions as any)
        // in the odd case where media does not have a title it will add Untitled
        .filter(d => d.Title = !d.Title ? 'Untitled' : d.Title)
        .sort((a, b) => a.Title != null ? (a.Title.toLowerCase() > b.Title.toLowerCase()) ? 1 : -1 : -1);
      if (medias.length) {
        this.router.navigate([state.url, medias[0].Type, medias[0].MediaReferenceId]);
      }
      return true;
    }));
  }
}

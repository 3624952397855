<div class="modal-content modal-green-important">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <ng-container *ngIf="!isSiteDiary">{{ ActivityDetail?.SiteDetails.CustomerName }} - </ng-container>{{
      ActivityDetail?.SiteDetails.SiteName }}
      <ng-container *ngIf="!isSiteDiary">({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})</ng-container>
    </h4>
    <a class="pull-right" (click)="dismiss()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3"><img src=" {{ (ActivityType | idToActivity: true).icon }}" class="modal-content-icon" />
        </div>
        <div class="col-sm-9">
          <ul>
            <li><span class="bold">Site: </span> {{ context.Site.SiteName }} ({{SiteReference}})</li>
            <li><span class="bold">Address: </span> {{ context.Site.SiteAddress }}</li>
            <li *ngIf="!isSiteDiary"><span class="bold">Customer: </span> {{ ActivityDetail?.SiteDetails.CustomerName }}
            </li>
            <li *ngIf="!isSiteDiary"><span class="bold">Scaffold: </span> {{
              ActivityDetail?.ScaffoldDetails.ScaffoldName }}</li>
            <li *ngIf="!isSiteDiary" [hidden]="!ActivityDetail.SiteDetails.IsManageStockControl"><span
                class="bold">Standing Tonnage: </span>
              {{ ActivityDetail?.ScaffoldDetails.StandingTonnage | number:'.1-2' }}</li>
            <li>
              <span class="bold">Staff Name: </span> {{ ActivityDetail?.OwnerName }}
              <span *ngIf="ActivityDetail?.StaffCompanyPosition?.length">({{ ActivityDetail?.StaffCompanyPosition }})</span>
            </li>
            <li><span class="bold">Date: </span>{{ ActivityDetail?.ActivityDateTime | date: 'MMM dd, yyy' }}</li>
            <li><span class="bold">Time: </span>{{ ActivityDetail?.ActivityDateTime | date: 'hh:mm a' }}</li>
            <li><span class="bold">Category: </span> {{ DiaryCategory }}</li>
            <ul class="list-inline">
              <li><i class="fa fa-photo"></i> {{ ActivityDetail?.ImagesCount }}</li>
              <li><i class="fa fa-headphones"></i> {{ ActivityDetail?.AudiosCount }}</li>
              <li><i class="fa fa-video-camera"></i> {{ ActivityDetail?.VideosCount }}</li>
              <li><i class="fa fa-file"></i> {{ ActivityDetail?.TranscriptionsCount }}</li>
              <li><i class="fa fa-file-pdf"></i> {{ ActivityDetail?.PDFCount }}</li>
            </ul>
          </ul>
        </div>
        <div class="col-sm-2">
          <div class="percentage-standing-container" *ngIf="!isSiteDiary">
            <h5>Scaffold Standing</h5>
            <hub-donut-chart [data]="percentageStandingData" [colors]="percentageStandingColors" [radius]="100">
            </hub-donut-chart>
            <p class="percentage-standing-text">{{ ActivityDetail?.PercentageStanding }}%</p>
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="ActivityDetail?.Notes">
        <div class="col-sm-12">
          <div class="notes">Notes: {{ ActivityDetail?.Notes }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-large button-secondary pull-right" (click)="dismiss()">Close</button>
    <button class="btn btn-large button-primary pull-right" (click)="goToDetails()">More Details</button>
  </div>
</div>

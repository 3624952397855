import { scaffoldComparator, scaffoldNumberComparator } from '../../../../../shared/comparators/scaffold-number-comparator';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import {
  ConfigurationData,
  ScaffoldWebAppData,
  SiteCommandService,
  SiteWebAppData,
  UpdateScaffoldNumbersCommand,
} from 'app/core/hub-api';
import { GroupScaffoldsModalContext } from '../../../../models/group-scaffolds-modal-context';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { SiteDetailQueryService } from 'app/sites/services/site-detail-query.service';

@AutoUnsubscribe()
@Component({
  selector: 'hub-group-scaffolds-modal',
  templateUrl: './group-scaffolds-modal.component.html',
  styleUrls: ['./group-scaffolds-modal.component.scss']
})
export class GroupScaffoldsModalComponent extends FormComponent implements OnInit, OnDestroy {
  context: Partial<GroupScaffoldsModalContext>;

  form: UntypedFormGroup;
  baseScaffoldIdFormControl: UntypedFormControl;

  configuration: ConfigurationData;
  site: SiteWebAppData;

  selectedScaffolds: ScaffoldWebAppData[];

  validationMessages = {};

  scaffoldNumberComparator = scaffoldNumberComparator;


  constructor(
    public bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private siteCommandService: SiteCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    public modalService: BsModalService,
    private siteDetailQueryService: SiteDetailQueryService,
  ) { super(); }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.configuration = this.context.configuration;
    this.site = this.context.site;
    this.selectedScaffolds = this.context.scaffolds
      .sort(scaffoldComparator)
      .map(scaffold => {
        scaffold = cloneDeep(scaffold);
        (scaffold as any).oldScaffoldNumber = scaffold.ScaffoldNumber;
        return scaffold;
      });

    this.baseScaffoldIdFormControl = new UntypedFormControl();

    this.baseScaffoldIdFormControl.valueChanges.subscribe((baseScaffoldId) => {
      const baseScaffold = this.selectedScaffolds.find(s => s.ScaffoldId === baseScaffoldId);
      let suffix = 1;
      this.selectedScaffolds.map((scaffold) => {
        const baseScaffoldIncremental = (baseScaffold as any).oldScaffoldNumber
          .slice(0, this.configuration.ScaffoldReferenceLength);
        scaffold.ScaffoldNumber = `${baseScaffoldIncremental}-${suffix}`;
        suffix++;
        return scaffold;
      });
    });

    this.baseScaffoldIdFormControl.setValue(this.selectedScaffolds[0].ScaffoldId);

    this.form = new UntypedFormGroup({
      baseScaffoldId: this.baseScaffoldIdFormControl
    });
    super.ngOnInit();
  }

  onSubmit(formValues: any): void {
    this.saveInProgress = true;

    const command: UpdateScaffoldNumbersCommand = {
      SiteId: this.site.SiteId,
      Scaffolds: this.selectedScaffolds,
    };

    this.siteCommandService.UpdateScaffoldNumbersCommand(command)
      .subscribe(() => {
          // Once the groups have been saved we need to refresh the cache
          forkJoin(
            [ 
              this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
              this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId)
            ]
          ).subscribe(() => {
            this.saveInProgress = false;
            this.bsModalService.setDismissReason(this.site.SiteReference);
            this.bsModalRef.hide();
          });
      }, this.serverErrorCallback);
  }

  ngOnDestroy(): void { }

}

<form name="contractForm" [formGroup]="form">
  <hub-modal-form-header [title]="editMode ? 'Edit Customer' : 'Add Customer'" (close)="closeModal()">
  </hub-modal-form-header>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <hub-form-group label="Customer Name" name="customerName" [control]="customerNameFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="customerName" ngxTrim>
        </hub-form-group>
        <hub-form-group label="Customer Reference" name="customerReference" [control]="customerReferenceFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="customerReference" ngxTrim>
        </hub-form-group>
        <hub-form-group label="Address" name="customerAddress" [control]="customerAddressFormControl"
          [validationErrors]="validationErrors">
          <hub-places-input formControlName="customerAddress" (placeSelected)="onPlaceSelected($event)">
          </hub-places-input>
        </hub-form-group>
        <hub-form-group label="Telephone" name="telephone" [control]="telephoneFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="telephone" ngxTrim>
        </hub-form-group>
        <hub-form-group label="Email" name="email" [control]="emailFormControl" [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="email" ngxTrim>
        </hub-form-group>
        <hub-form-group label="Website" name="website" [control]="websiteFormControl"
          [validationErrors]="validationErrors">
          <input class="form-control" type="text" formControlName="website" ngxTrim>
        </hub-form-group>
      </div>
      <div class="col-md-6">
        <div class="google-map-wrapper">
          <google-map width="100%" height="100%" [zoom]="map.zoom" [center]="{ lat: map.latitude, lng: map.longitude }" [options]="{ scrollwheel: false }">
            <map-marker [position]="{ lat: map.latitude, lng: map.longitude }" [options]="{ draggable: true }" 
              (mapDragend)="onMarkerMoved($event)"></map-marker>
          </google-map>
        </div>
      </div>
      <div class="col-md-12 modal-options" *ngIf="editMode">
        <div *ngIf="!validation.hasOpenContracts">
          <div *ngIf="customer.CustomerStatusId === customerStatusIds.active">
            <!--- Delete Account -->
            <a class="pull-left text-danger animate-fade" (click)="delete()"
              *ngIf="customerStatusId === customerStatusIds.active"><i class="fa fa-times"></i> Delete customer</a>
            <!--- Undo Delete -->
            <span class="pull-left animate-fade" *ngIf="customerStatusId === customerStatusIds.inactive"><i
                class="fa fa-check"></i> The customer will be deleted. Click <a (click)="undelete()"
                class="text-danger">undo</a> if this was a mistake!</span>
          </div>
          <div *ngIf="customer.CustomerStatusId === customerStatusIds.inactive">
            <!--- Reopen Account -->
            <a class="pull-left text-danger animate-fade" (click)="undelete()"
              *ngIf="customerStatusId === customerStatusIds.inactive"><i class="fa fa-undo"></i> Reactivate customer</a>
            <!--- Undo Reopen -->
            <span class="pull-left animate-fade" *ngIf="customerStatusId === customerStatusIds.active"><i
                class="fa fa-check"></i> The customer will be reactivated. Click <a (click)="delete()"
                class="text-danger">undo</a> if this was a mistake!</span>
          </div>
        </div>
        <div *ngIf="validation.hasOpenContracts" class="text-danger"><i class="fa fa-warning"></i>You need to close all
          of the customer's contracts before deleting them.</div>
      </div>
    </div>
    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="closeModal()"></hub-modal-form-footer>
  </div>
</form>
import { AbstractControl } from '@angular/forms';


export class CustomValidator {


/**
 *To validate the inpur for non zero,positive,2 decimal places
 *
 * @static
 * @param {AbstractControl} control
 * @returns {({ [key: string]: boolean } | null)}
 * @memberof CustomValidator
 */
static negativeDecimalValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined) {
        const REGEXP = /^(?:\d*\.\d{1,2}|\d+)$/;
        if (REGEXP.test(control.value)) {
            return null;
         }
         return {
            'invalidValue': true
         };
    }
    return null;
}
}

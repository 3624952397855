<div class="site-details-dashboard">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <a class="btn button-default" [routerLink]="['/sites/list']"><i class="fa fa-chevron-left text-positive"></i>back
        to sites list</a>
    </div>
  </div>
  <div class="row margin-bottom">
    <div class="col-md-12">
      <h2>Dashboard</h2>
      <ul class="card-nav">
        <li *ngIf="customers.length > 1" [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a>All
            Customers</a></li>
        <li *ngFor="let customer of customers" [routerLink]="[customer.CustomerReference]" routerLinkActive="active" [ngClass]="customers.length === 1 ? 'active': ''">
          <a>{{customer.CustomerName}}</a></li>
      </ul>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <fieldset>

        <div class="title">
          <h1>END USER SOFTWARE APPLICATION LICENCE AGREEMENT</h1>
          <h1>Computer and Design Services Ltd (CADS)</h1>
        </div>
        <div class="notice">
          <strong>IMPORTANT:</strong>
          <p>PLEASE READ THIS END USER LICENCE AGREEMENT CAREFULLY. </p>
          <p> INSTALLING OR USING CADS SOFTWARE CONSTITUTES ACCEPTANCE OF THIS AGREEMENT. </p>
        </div>

        <div class="intro">
          <p> Computer and Design Services Ltd (CADS) is willing to licence its software to you only on the condition
            that
            you accept all of the terms contained in the Licence Agreement overleaf which has 33 Clauses.
          </p>
          <p>
            It is a legal agreement between you (either as an individual, firm, partnership, sole trader, corporate
            entity
            or
            organisation) as the End User and Computer and Design Services Ltd as the author or distributor for the
            software
            product or products identified below.
          </p>
          <p>
            The Software is protected by Copyright Laws and by international provisions as with other intellectual
            property.
            The
            Software is licensed not sold. Title to and ownership of the intellectual property rights associated with
            the
            Software Application and any copies remain with CADS and its suppliers.
          </p>
          <p>
            By installing this software, you, whether as owner or lessee of the Licence, agree to be bound by the
            terms
            and
            conditions of this agreement and to procure that your employees or those working for you in any capacity,
            direct
            or
            indirect, comply with its full terms. If you do not agree to the terms and conditions of this Agreement
            then
            you
            must not install, register, copy or use the software.
          </p>

          <p>This page is for information and does not form part of the Licencing Agreement.</p>
        </div>

        <article>
          <h2>1.1 Parties</h2>
          <p>
            This Licence is a legal agreement between Computer and Design Services Limited, Arrowsmith Court, 10
            Station
            Approach, Broadstone, Dorset BH18 8AX ("CADS") and the End User hereinafter referred to as the "Licensee",
            being the person, individual, firm, partnership, sole trader, corporate entity or organisation named on:
          </p>
          <p>the CADS Confirmation of Order Form(s) or</p>
          <p>the CADS Acknowledgement of Order Form(s) or</p>
          <p>the CADS CD or DVD Licence Form</p>
          <p>by whom or on whose behalf this Licence is agreed by ticking the acceptance box at the end of the Licence
            displayed on initial installation of the software.</p>
        </article>


        <article>
          <h2>1.2 The Software</h2>
          <p>This Licence governs the Licensee's use of the CADS Software Application(s) named on</p>
          <p>CADS Confirmation of Order Form(s) or</p>
          <p>CADS Acknowledgement of Order Form(s) or</p>
          <p>CADS CD or DVD Licence Form(s)</p>
          <p>including its programs, documentation, subsequent corrections, additions and modifications ("the
            Software"). This Licence shall replace and discharge all prior representations and agreements (if any)
            relating to use of the Software. In case of conflict, the terms of this Licence shall over-ride any terms or
            conditions imposed by the Licensee.</p>
        </article>

        <article>
          <h2>1.3 The Licence</h2>
          <p>
            CADS owns or is otherwise authorised to licence the software and in consideration of you, the Licensee,
            agreeing to abide by the terms and conditions of this Licence, and subject to the payment of applicable
            licence and other related fees, hereby grants the Licensee a non-exclusive, non-transferable right to use
            the Software Application but only on the Licensee’s compatible computer system or systems, or devices for
            the Licensee’s internal business operations subject to the terms and conditions of this Licence. The
            Software is in use on a computer when it is loaded into the temporary memory or installed into the
            permanent
            memory. The Licensee shall not install, access or otherwise copy or use the Software except as expressly
            authorised by this Agreement and the Licensee agrees not to use the Software for any re-sale purposes.
          </p>
          <p>
            Where the software is supplied on a Single User USB Hardware Key (Dongle) basis the Licensee may install
            as
            many copies of the software as is required on compatible computers but shall only use the software on one
            compatible computer at a time with the Dongle attached.
          </p>
          <p>
            Where the software is supplied on a Single User basis to be used with a CADS Single-User Software Licence
            the Licensee may install one copy of the Software on one Central Processing Unit (CPU) of the compatible
            computer and may also keep one copy of the Software for security or archival purposes only. Except for
            such
            security copy, no other copies of any part of the Software not incidental to use of the Software as
            permitted in Clause 3 above shall be made and the Licensee shall not download the Software to or permit it
            to be accessed from any unlicensed equipment or by any third party. Should the Licensee wish to cease to
            use
            the software on the computer on which it has been installed then it can be removed without further
            reference. If the Licensee wishes to install the software on a different compatible computer then the
            Software Licence may be transferred with the assistance of the CADS Technical Support Team.
          </p>
          <p>
            Where the software is supplied on a multi concurrent user basis on a Local Area or Wide Area Network the
            Licensee may install as many copies of the software as required on compatible computers with the Network
            Dongle or the CADS Floating-Network Software Licence installed on the Network Server. The Licensee shall
            at
            no time use more copies of the software than have been authorised by the Network Dongle or the Software
            Licence.
          </p>
          <p>
            Where the Licensee is supplied with software for a Virtual Server or a Server and dumb terminal
            installation
            then a Network Dongle will be required at the virtual server that has been authorised for each copy of the
            software that is being used.
          </p>
          <p>
            The Licensee agrees that any copies of the Software Application shall contain the same proprietary notices
            which appear on and in the Software Application.
          </p>
          <p>
            The Licensee will not attempt to alter, modify, translate, reverse engineer, decompile, decrypt, extract,
            or
            disassemble or copy the software either in whole or part or incorporate it within any other software or to
            emulate or reverse engineer the Dongle so that the software may be used without the authorised Dongle
            issued
            by CADS or any third party.
          </p>
          <p>
            The Licensee further agrees that it shall not itself or through any subsidiary agent or third party modify
            vary, enhance, copy, sell, lease, licence, sub-licence, grant a security interest in or otherwise deal
            with
            the Software and or the Dongle and or the Software Licence or any part or parts or variations,
            modifications, copies releases, versions or enhancements or have any Software or other programme written
            or
            developed for itself based on any proprietary and/or confidential, information supplied to it by CADS
            otherwise than in accordance with the provisions of this Licence and without first having obtained the
            written consent of CADS.
          </p>
          <p>
            Nothing in this Licence permits the Licensee to derive the source or assembly code of files that are
            provided to the Licensee in executable form.
          </p>
        </article>


        <article>
          <h2>1.4 Distribution by Licensee</h2>
          <p>
            The Licensee shall not distribute, rent, loan, lease, sell, sub-licence, or otherwise transfer all or any
            portion of the Software Application, or any rights granted in this Agreement, to any other person without
            the prior written consent of CADS. The Licensee shall not install or access, or allow the installation or
            access of, the Software Application over the Internet, including, without limitation, use in connection
            with
            a Web hosting or similar service, or make the Software Application available to third parties via the
            Internet on an End-User’s computer system or otherwise.
          </p>
          <p>
            The Licensee shall not modify, translate, adapt, arrange, or create derivative works based on the Software
            Application for any purpose. The Licensee may not use or export the Software Application outside of the
            country of purchase for any reason. The Licensee shall not assign, give or transfer the Software
            Application
            to another individual or entity. The Licensee is prohibited from (a) timesharing, service bureau,
            subscription service or rental use of the Software Application or (b) passing title to the Software
            Application to any other individual or (c) publication of any results of benchmark tests run on the
            Software
            Application. The Licensee acknowledges that the Software Application is the confidential information of
            CADS
            and its suppliers, and the Licensee agrees that under no circumstances may the Licensee disclose the
            Software Application to any third party.
          </p>
        </article>

        <article>
          <h2>1.5 Payment of Licence Fees</h2>
          <p>
            In consideration of the grant of the Licence, the Licensee agrees to pay CADS all licence fees and other
            charges payable by virtue of the provisions of this Licence and acknowledges that CADS may issue upgraded
            versions of the Software to the Licensee for an extra fee, the amount of which will be determined at the
            absolute discretion of CADS. The Licensee shall be entitled to receive the upgraded Software only after it
            has paid CADS the notified fee.
          </p>
        </article>


        <article>
          <h2>1.6 Enhancement and Variations to the Software</h2>
          <p>
            The Licensee shall not enhance or vary any part of the Software nor permit the whole or any part of the
            Software to be incorporated in any other software or used on any other equipment.
          </p>
        </article>


        <article>
          <h2>1.7 Additional Licences</h2>
          <p>
            CADS will on request provide additional licences to increase the number of licensed Software users subject
            to the Licensee paying the then current market price and cost of the Software.
          </p>
        </article>

        <article>
          <h2>1.8 Use of the Software by the Licensee </h2>
          <p>
            The Licensee shall use the software on the device or system at each and every location where it is
            installed
            in accordance with the following and for the purpose of this licence use of software shall mean:
          </p>

          <p>
            to store and/or run the Software on the System or device in accordance with the terms of this Licence
          </p>

          <p>
            to process data or instructions using the software
          </p>
          <p>
            to make a copy of the machine readable form of the Software for security and back up purposes.
          </p>
          <p>
            The Licensee shall procure and ensure that all users of any CADS Software that is Licenced to him, whether
            employees, sub-contractors, associates, affiliates, subsidiary or sister organisations abide by all the
            terms of the Licence.
          </p>
        </article>

        <article>
          <h2>1.9 Non Bespoke Software</h2>
          <p>
            The Licensee acknowledges that the Software has not been developed to meet the Licensee’s individual
            requirements, and that it is therefore the Licensees responsibility to ensure that the facilities and
            functions of the Software as described in the Documentation meet their requirements.
          </p>
        </article>


        <article>
          <h2>1.10 Software Application Function</h2>
          <p>
            In particular, the Software is not intended for use as a tool to ensure compliance with any laws,
            obligations, regulations guidance or codes of practice including but not limited to matters of Health and
            Safety. Any diagrams that may be included in the software or documentation are for information purposes
            only
            and are not detailed technical drawings and may omit components or features that are required in practice.
            Whilst reasonable efforts have been made to ensure that the Software and Documentation is accurate, CADS
            do
            not accept any responsibility for any associated errors, omissions or misleading statements. Except as
            specified in the Limitation of liability section of Clause 15 of this licence and to the extent lawfully
            permitted CADS hereby excludes all liability in connection with this Software and Documentation and any
            reliance you may place on the Software and/or Documentation.
          </p>
        </article>

        <article>
          <h2>1.11 Users of Software to be Proficient </h2>
          <p>
            The software must only be used by suitably trained and skilled staff as an aid to design and specification
            and it is not intended as and must never be used as a substitute for professional skill, experience or
            expertise. The Licensee should always ensure that users of the Software either have themselves the
            necessary
            skill and experience or recourse to the necessary advice specific to the circumstances for which the
            software is being used and that it is not a substitute for on-site assessment.
          </p>
        </article>

        <article>
          <h2>1.12 Maintenance and Support</h2>
          <p>
            CADS will, in respect of the software in Clause 1.2, on payment in advance of the appropriate maintenance
            fee, provide an annual Maintenance and Support Service. The terms under which such Maintenance and Support
            Services are supplied are set out on CADS website.
          </p>
        </article>

        <article>
          <h2>1.13 Intellectual Property & Confidentiality</h2>
          <p>
            Subject to the rights granted to the Licensee by this Licence, the Licensee acknowledges that all and any
            proprietary rights in the Software worldwide, (including but not limited to copyright) and in all
            associated
            documentation and other materials related to the Software shall be and remain the sole property of CADS or
            its suppliers.
          </p>
          <p>
            The Software and all information, data, drawings, sketches, specifications, documents, software listings,
            source code, or object code is proprietary, secret and confidential to CADS. The Licensee hereby agrees
            that
            it shall use such information solely in accordance with the provisions of this Licence and that it shall
            take all measures necessary to maintain confidence and secrecy in the Software both for the duration of
            this
            Licence and after its termination, howsoever caused. Disclosure shall be limited to those members of the
            Licensee's staff who need access to the Software to enable the Licensee to exercise this Licence and shall
            not be to any Third Party. These confidentiality obligations extend to the structure, sequence,
            organisation
            and screen presentation of the Software.
          </p>
        </article>

        <article>
          <h2>1.14 Indemnity and Warranties</h2>
          <p>
            CADS warrants to the Licensee that the Software will not infringe any copyright, patent or other
            intellectual property rights of any third party.
          </p>
          <p>
            The Licensee shall promptly give notice to CADS of any claim alleging infringement of any intellectual
            property right of any third party by the Software and CADS shall at its discretion either compromise or
            defend such claims. CADS shall have the right to replace or change the Software so as to avoid
            infringement
            of any third party's intellectual property rights.
          </p>
          <p>
            CADS warrants that for a period of 90 days the Software will when delivered perform substantially in
            accordance with CADS's published specifications but does not warrant that the Software is error free.
            Faulty
            media and documentation will be replaced during the warranty period free of charge on receipt by CADS of
            written notification from the Licensee setting out details of faults. The Licensee is responsible for loss
            or damage of all dongles received from CADS as part of the Licence. In case of loss of a dongle, CADS has
            the right to terminate the Licence and CADS is not obligated to replace any lost dongle. Damaged dongles
            may
            be returned to CADS and will be replaced, but CADS reserves the right to charge additional fees to cover
            the
            costs in that case. This warranty does not apply if the defect or fault in the Software results from the
            Licensee having amended or attempted to amend the Software or if the defect or fault in the Software
            results
            from the Licensee having used the Software in contravention of the terms of this Licence.
          </p>
        </article>


        <article>
          <h2>1.15 Liability</h2>
          <p>
            CADS and its suppliers shall not be liable for any loss or damages, direct or indirect, consequential or
            financial suffered by the Licensee caused by the delivery, use or performance of the Software or for
            negligence or breach of contract by CADS in relation to the Software whether foreseeable or not and
            regardless of whether the Licensee or its suppliers have been advised of the possibility of such damages.
          </p>
          <p>
            Because CADS has no control over the way in which the Software may be used, the Licensee shall indemnify
            CADS against all third-party claims which may arise from possession or use of the Software not being
            claims
            of the kind described in Clause 9 above. CADS shall have no support or error correction obligation in
            respect of the Software other than as provided in Clause 10 above or by the terms of a separate
            Maintenance
            and Support Agreement made between the parties.
          </p>
          <p>
            CADS and its suppliers shall not under any circumstances whatsoever be liable to you, whether in contract,
            tort (including negligence), breach of statutory duty, or otherwise arising under or in connection with
            the
            licence including without limitation:
          </p>
          <ol>
            <li>loss of profits, sales, business, or revenue</li>
            <li>business interruption</li>
            <li>loss of anticipated savings</li>
            <li>liability or injury to third persons</li>
            <li>loss or corruption of data or information</li>
            <li>loss of business opportunity, goodwill or reputation or</li>
            <li>any indirect, special or consequential loss or damage of any nature</li>

          </ol>
        </article>

        <article>
          <h2>1.16 Liability Limits</h2>
          <p>
            Other than the losses set out in Clause 15 (for which CADS are not liable) the maximum aggregate of the
            liability of CADS under or in connection with this Licence whether in contract, tort (including
            negligence)
            or otherwise, shall in all circumstances be limited to the price paid for the Software.
          </p>
          <p>
            Nothing in this licence shall limit or exclude our liability for:
          </p>
          <ol>
            <li>death or personal injury resulting from our negligence</li>
            <li>any other liability that cannot be excluded or limited by English law.</li>
          </ol>
        </article>

        <article>
          <h2>1.17 Privacy Statement</h2>
          <p>
            CADS respects your right to privacy. Our software may collect and analyse general usage information about
            hardware, its configuration, software usage patterns, design outcomes and error reports to help us improve
            the quality of our service. We will not collect personal information about individual users and do not
            sell
            or otherwise give away any such information to unaffiliated third parties unless legally compelled to do
            so.
          </p>
        </article>

        <article>
          <h2>1.18 Audit Rights</h2>
          <p>
            At any time during the Licence Term, CADS or its re-seller in any location may conduct or appoint an
            external auditor to conduct an audit in order to ensure compliance by the Customer with the terms of any
            Licences of CADS Software Applications. CADS or its re-seller as applicable shall provide no less than
            five
            days advance notice of Audit to the customer unless the Audit is carried out electronically.
          </p>
          <p>
            The Customer shall grant the Licensor CADS or the appointed external auditor reasonable assistance and
            co-operation and such access to premises, systems and other such material and data as may be reasonably
            necessary to determine the Customer’s compliance with any CADS licensing agreements. CADS or its external
            auditor shall ensure that such Audit will not interfere unreasonably with the legitimate business
            activities
            of the customer.
          </p>
          <p>
            The Audit may also be conducted electronically utilising the Audit functionality of the Software designed
            to
            enable CADS to automatically monitor the use of its software. The Customer expressly agrees and consents
            to
            such monitoring and the reporting of it to CADS to detect and notify CADS of CADS software installations
            or
            third party applications provided by CADS and the use of any unlicensed CADS software. That an electronic
            Audit has been carried out does not limit CADS right to carry out further Audits through non-electronic
            means.
          </p>
          <p>
            Should the Audit whether electronic or physical determine that the Customer is not in compliance with the
            Terms of the Licence for use issued by CADS, the Customer shall immediately remedy the situation either
            through purchase and payment for additional Licences or otherwise as well as compensating CADS for the
            cost
            of the Audit. Such compensation for costs shall be without prejudice to any rights or remedies that may be
            available to CADS whether at law or in equity, and whether due to any breach by the Customer of this
            Agreement.
          </p>
        </article>

        <article>
          <h2>1.19 CADS Obligations</h2>
          <p>
            This Licence sets out the full extent of CADS obligations and liabilities in respect of the supply of the
            Software and Documentation. Except as expressly stated in this licence there are no conditions warranties,
            representations or other terms, express or implied, that are binding on us. Any condition, warranty,
            representation or other term concerning the supply of the Software and Documentation which might otherwise
            be implied into, or incorporated in, this Licence whether by statute, common law or otherwise, is excluded
            to the fullest extent permitted by law.
          </p>
        </article>

        <article>
          <h2>1.20 Length of Licence</h2>
          <p>
            This Licence shall start on the ticking of the acceptance box by the Licensee and shall continue for a
            period of five years unless earlier terminated. The Licensee may terminate this Licence by giving to CADS
            90
            days’ written notice on its ceasing to use the Software for the purposes of their business.
          </p>
        </article>


        <article>
          <h2>1.21 Termination</h2>
          <p>
            CADS may terminate the Licensee's right to use the Software under this Licence on 30 days written notice
            if
            the Licensee breaches any of the terms, conditions, or provisions of this Licence or any obligation to
            CADS
            required by law or without prejudice to clause 18 above, if the Licensee fails to pay any sum due to CADS
            whether due under this Licence or not and/or if the Software Maintenance and Support Agreement is
            terminated
            (for whatever reason) if the Licensee, being a body corporate, shall present a petition or have a petition
            that is not discharged or stayed within 30 days presented by a creditor for its winding up, or shall
            convene
            a meeting to pass a revolution for voluntary winding up, or shall enter into any liquidation (other than
            for
            the purpose of a bona fide reconstruction or amalgamation); shall call a meeting of its creditors, or
            shall
            have a receiver of all or any of its undertaking or assets appointed, or shall be deemed by virtue of
            section 123 of the Insolvency Act 1986 to be able to pay its debts.
          </p>
        </article>

        <article>
          <h2>1.22 Return of Software and Documentation</h2>
          <p>
            On termination for whatever reason, the Licensee shall return immediately all copies of the Software and
            associated Documentation to CADS and shall pay to CADS all costs and expenses, including legal and other
            fees incurred and all arrears of fees, charges and other payments arising in respect of the Software and
            the
            total amount of any Licence fees outstanding under this Licence or otherwise. Termination, howsoever or
            whenever occasioned shall be subject to the any rights and remedies CADS or its suppliers may have and
            under
            this Licence or in law.
          </p>
        </article>

        <article>
          <h2>1.23 Notices</h2>
          <p>
            All notices to be given under this Licence shall be in writing and be presented at or posted to the
            addresses as indicated on the Confirmation of Order Form unless otherwise advised in writing. Notices
            shall
            be effective when presented at such addresses or 48 hours after posting if mailed by ordinary first class
            post.
          </p>
        </article>

        <article>
          <h2>1.24 Assignment</h2>
          <p>
            The Licensee shall not be entitled to assign this licence or any of its obligations hereunder. The
            Licensee
            agrees that CADS shall be entitled to assign or sub-contract its rights and obligations under this
            agreement
            to a third party and the Licensee agrees to enter into a novation of the Licence to achieve such an
            assignment by CADS if CADS so directs the Licensee.
          </p>
        </article>

        <article>
          <h2>1.25 Third Party Rights</h2>
          <p>
            Nothing in this Licence shall confer on any third party any right to enforce any term of this Licence
            under
            the Contracts (Rights of Third Parties) Act 1999 .
          </p>
        </article>

        <article>
          <h2>1.26 Taxes</h2>
          <p>
            All charges and fees paid are exclusive of and do not include any taxes, duties, or similar charges imposed
            by any government. The Licensee agrees to pay or reimburse CADS for all federal, state, national, dominion,
            provincial, or local sales, use, personal property, excise, VAT or other taxes, fees or duties arising out
            of this agreement or the transactions contemplated by the Agreement.
          </p>
        </article>

        <article>
          <h2>1.27 Law</h2>
          <p>
            The parties hereby agree that the Licence concluded between them constructed on these terms and conditions
            shall be construed in accordance with English Law and the parties shall submit to the non-exclusive
            jurisdiction of the English Courts.
          </p>
        </article>

        <article>
          <h2>1.28 Headings</h2>
          <p>
            The headings of the terms and conditions herein contained are inserted for the convenience or reference
            only
            and are not intended to be part of or to affect the meaning or interpretation of any of the terms and
            conditions of this Licence.
          </p>
        </article>

        <article>
          <h2>1.29 Force Majeure</h2>
          <p>
            No Party shall be liable for, nor shall any party be considered in breach of this agreement due to any
            failure to perform its obligations under this Agreement as a result of a cause beyond its control (each a
            “Force Majeure Event”), including any act of God or a public enemy, any act of terrorism, act of military,
            civil or regulatory authority, change in law or regulation, fire , flood, earthquake, storm or other like
            event, disruption outage of communications, power or other utility, labour problem, unavailability of
            supplies, or any other cause, whether similar or dissimilar to any of the foregoing; provided, however
            that
            upon learning of any Force Majeure event, the applicable party shall immediately notify the other party of
            such a Force Majeure Event.
          </p>
        </article>

        <article>
          <h2>1.30 Entire Agreement</h2>
          <p>
            This Licence constitutes the entire agreement between the parties in relation to its subject matter and
            supersedes any prior agreements on these matters and any such agreements shall cease to have any further
            effect. It is also agreed that neither party has relied upon any representations nor other statements in
            entering into this Licence other than those set out herein. No purported amendments to this Licence shall
            be
            effective unless in writing and signed by duly appointed representatives of all parties.
          </p>
        </article>

        <article>
          <h2>1.31 Acknowledgement</h2>
          <p>
            You acknowledge that you and any entity that obtained the Software and on whose behalf it is used and any
            other user of this installation have read the licence understand it and agree to be bound by its terms and
            conditions.
          </p>
        </article>

        <article>
          <h2>1.32 Severability</h2>
          <p>
            In the event that any of these terms, conditions, provision shall be determined invalid, unlawful or
            unenforceable to any extent, such term, condition or provisions shall be severed from the remaining terms,
            conditions and provisions which shall continue to be valid to the fullest extent permitted by law.
          </p>
        </article>

        <article>
          <h2>1.33 Waiver</h2>
          <p>
            Failure or neglect by CADS to enforce at any time any of the terms of this Licence shall not be deemed to
            be
            a waiver of CADS rights hereunder nor in any way affect the validity of the whole or any part of this
            Licence nor prejudice CADS rights to take subsequent action.
          </p>
        </article>

        <footer>
          <p>Copyright © Computer and Design Services Limited</p>
        </footer>
      </fieldset>
    </div>
  </div>
</div>

<div class="modal-body accept-block">
  <div class="row">
    <div class="col-xs-9">
      <div class="agree-check">
        <div class="form-group">
          <label><input type="checkbox" value="all" name="acceptEula" id="acceptEula" [ngModel]="acceptEula"
              (change)="onChange($event)" />
            <span>I have read the licence agreement and service level agreement and agree to their terms.</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-xs-3">
      <div class="modal-buttons">
        <button class="btn btn-large button-primary" type="button" (click)="onSubmit()" [disabled]="!acceptEula">
          <i [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"
            class="fa"></i>
          Agree
        </button>
      </div>
    </div>
  </div>
</div>
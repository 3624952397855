import { Component, OnInit } from '@angular/core';
import { ScaffoldImportModalContext } from './scaffold-import-modal-context';
import { ImportSpreadsheetComponent } from './import-spreadsheet/import-spreadsheet';
import { MultiStepFormStep } from 'app/shared/models/multi-step-form-step';
import { JSONCasing } from 'json-casing';
import { SelectContractComponent } from './select-contract/select-contract';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AngularGridInstance, Column, DelimiterType, FileType, GridOption } from 'angular-slickgrid';
import { ExcelExportService } from '@slickgrid-universal/excel-export';
import { TextExportService } from '@slickgrid-universal/text-export';
enum ExportType {
  EXCEL,
  CSV
}
/** 
 * This is the first page of 3 (Import, Select Contract, Slickgrid)
 * Here the user can import a CSV file
 * User can select to copy excel data
 * User can also download a template for importing data
 * 
*/
@Component({
  selector: 'hub-scaffold-import-modal',
  templateUrl: './scaffold-import-modal.component.html',
  styleUrls: ['./scaffold-import-modal.component.scss']
})
export class ScaffoldImportModalComponent implements OnInit {
  /** Modal Context */
  context: Partial<ScaffoldImportModalContext>;
  /** SlickGrid Template */
  angularGrid: AngularGridInstance;
  /** SlickGrid Column Definitions */
  columnDefinitions: Column[];
  /** SlickGrid Options */
  gridOptions: GridOption;
  /** SlickGrid Data */
  dataset: any[];
  /** Stores the Modal Steps */
  steps: MultiStepFormStep[] | any;
  /** Stores the data from the CSV file */
  data;
  /** Stores the exported file */
  file: File;
  /** Stores the file reader */
  reader: FileReader;
  /** Stores the error message */
  errorMessage: string;
  /** Stores the file name */
  showImportForm: boolean;
  /** UI Settings */
  uiSettings = {
    fileHeadersCount: 17
  };
  /** Error messages */
  errors = {
    problemLoadingFile: 'There has been a problem loading the file',
    noFileSelected: 'Please select a CSV file.',
    wrongFileExtension: 'The file must be a .CSV file.',
    tableFormat: 'The table structure is not correct.',
    unsupportedFileVersion: 'The selected file is corrupted or the file version is unsupported.'
  };
  /** Export Text Service */
  textExportService = new TextExportService();
  /** Export Excel Service */
  excelExportService = new ExcelExportService();
  /** Export Type */
  exportType = ExportType;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
  ) { }

  /** Initialises the component */
  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.steps = [
      {
        title: 'Select Contract',
        component: SelectContractComponent
      },
      {
        title: 'Imported Scaffold',
        component: ImportSpreadsheetComponent
      }
    ];

    this.context.dialogClass = 'modal-dialog modal-lg';

    this.showImportForm = false;
    this.reader = new FileReader();
    this.reader.onload = e => {
      try {
        const csvData = JSONCasing.toPascal((e.target as any).result);
        this.data.scaffoldImportFile = this.CSVToArray(csvData).filter(s => s[0] !== '');
        if (this.data.scaffoldImportFile[0].length !== this.uiSettings.fileHeadersCount) {
          throw new Error();
        }
      } catch (error) {
        this.errorMessage = this.errors.problemLoadingFile;
      }
    };

    this.data = {
      scaffoldImportFile: undefined
    };
    this.generateTemplate();
  }

  /** Generates the template for users to be able to download */
  generateTemplate(): void {
    // IMPORTANT: When the column definitions is changed please update the enum so the properties are liked correctly (TableHeadersEnum)
    this.columnDefinitions = [
      { id: 'scaffoldName', name: 'Scaffold Name', field: 'scaffoldName' },
      { id: 'scaffoldDescription', name: 'Scaffold Description', field: 'scaffoldDescription' },
      { id: 'area', name: 'Area', field: 'area' },
      { id: 'scaffoldType', name: 'Scaffold Type', field: 'scaffoldType' },
      { id: 'loadingLimit', name: 'Loading Limit', field: 'loadingLimit' },
      { id: 'methodCladding', name: 'Method of Cladding', field: 'methodCladding' },
      { id: 'methodTying', name: 'Method of Tying', field: 'methodTying' },
      { id: 'quoteNumber', name: 'Quote Number', field: 'quoteNumber' },
      { id: 'itemNumber', name: 'Item Number', field: 'itemNumber' },
      { id: 'itemName', name: 'Item Name', field: 'itemName' },
      { id: 'scaffoldingSystem', name: 'Scaffolding System', field: 'scaffoldingSystem' },
      { id: 'maximumTonnage', name: 'Maximum Tonnage', field: 'maximumTonnage' },
      { id: 'hirePeriod', name: 'Hire Period', field: 'hirePeriod' },
      { id: 'designType', name: 'Design Type', field: 'designType' },
      { id: 'dateNextInspection', name: 'Next Inspection Date', field: 'dateNextInspection' },
      { id: 'onHireDate', name: 'On Hire Date', field: 'onHireDate' },
      { id: 'affectedAdverseWeather', name: 'Affected by Adverse Weather', field: 'affectedAdverseWeather' },
    ];
    this.gridOptions = {
      enableColumnReorder: false,
      autoResize: {
        container: 'hidden-grid',
      },
      enableAutoResize: true,
      enableFiltering: true,
      enableTextExport: true,
      textExportOptions: {
        exportWithFormatter: true,
        sanitizeDataExport: true,
      },
      excelExportOptions: {
        exportWithFormatter: true,
        sanitizeDataExport: true,
      },
      registerExternalResources: [this.textExportService, this.excelExportService],
      gridMenu: {
        hideExportExcelCommand: false,
      }
    };
    this.dataset = [];
  }

  /** Initialises the Angular Slickgrid table */
  angularGridReady(angularGrid: any): void {
    this.angularGrid = angularGrid;
  }

  /** Handles the export defined by the user, CSV or EXCEL */
  exportToFile(type: ExportType): void {
    if (type === ExportType.EXCEL) {
      this.excelExportService.exportToExcel({
        filename: 'Scaffold Import Template',
        format: FileType.xls,
      });
      return;
    }
    this.textExportService.exportToFile({
      delimiter: DelimiterType.comma,
      filename: 'Scaffold Import Template',
      format: FileType.csv
    });

  }

  /** Activates the copy and paste feature */
  copyPasteValues(): void {
    this.data.copyPaste = true;
    this.showImportForm = true;
  }

  /** Converts the CSV file to an Array */
  CSVToArray(strData, strDelimiter?): Array<any> {
    strDelimiter = strDelimiter || ',';
    const objPattern = new RegExp(`(\\${strDelimiter}|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\\${strDelimiter}\\r\\n]*))`, 'gi');
    const arrData = [[]];
    let arrMatches = null;
    while ((arrMatches = objPattern.exec(strData))) {
      const strMatchedDelimiter = arrMatches[1];
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        arrData.push([]);
      }
      let strMatchedValue;
      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
      } else {
        strMatchedValue = arrMatches[3];
      }
      arrData[arrData.length - 1].push(strMatchedValue);
    }
    return arrData;
  }

  /** Handles the file selected event */
  onFileSelected(event: Event): void {
    this.errorMessage = undefined;
    this.file = (event.target as any).files[0];
    this.reader.readAsText(this.file, 'ISO-8859-1');
  }

  /** Validates if the data is correct to enable the Next button */
  onStartImport(): void {
    // No file was selected.
    if (!this.file) {
      this.errorMessage = this.errors.noFileSelected;
      return;
    }
    // The file has the wrong extension.
    if (this.file.name.split('.').pop() !== 'csv') {
      this.errorMessage = this.errors.wrongFileExtension;
      return;
    }
    // There was an error while loading the file.
    if (this.errorMessage) {
      return;
    }
    this.showImportForm = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSDialogContext } from 'app/shared/models/ss-modal-config';
import { ModalFormFooterComponent } from '../modal-form-footer/modal-form-footer.component';
import { Subject } from 'rxjs';

/**
 * Component for displaying the modal dialog.
 *
 * @export
 * @class ModalDialogComponent
 * @extends {ModalFormFooterComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'hub-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent extends ModalFormFooterComponent implements OnInit {
    /* Stores the sent data context */
    public context: Partial<SSDialogContext>;
    /* Modal reference */
    public modalRef: BsModalRef;
    /* Message to be displayed */
    public message: string;
    /* Stores the onClose event */
    public onClose: Subject<boolean>;
    /* Sets the dialog to active or inactive */
    public active: boolean = false;

    constructor(public bsModalRef: BsModalRef, public modalService: BsModalService) { super(); }

    /**
     * Initializes the component.
     *
     * @memberof ModalDialogComponent
     */
    public ngOnInit(): void {
        this.onClose = new Subject();
        this.active = true;

        // gets the initial values from the function that calls this modal
        this.context = this.modalService.config.initialState;
        this.context.okBtnText = this.context.okBtnText ? this.context.okBtnText : 'Yes';
        this.context.cancelBtnText = this.context.cancelBtnText ? this.context.cancelBtnText : 'No';
        this.context.showHeaderClose = this.context.showHeaderClose ? this.context.showHeaderClose : true;
        this.context.showOnlyCloseBtn = this.context.showOnlyCloseBtn ? this.context.showOnlyCloseBtn : false;
        this.context.footerClass = this.context.footerClass ? this.context.footerClass : 'confirm-modal-footer';
    }

    /**
     * When user presses submit button, this method is called.
     *
     * @memberof ModalDialogComponent
     */
    public confirm(): void {
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    /**
     * When user presses cancel button, this method is called.
     *
     * @memberof ModalDialogComponent
     */
    public decline(): void {
        this.active = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    /**
     * Hides the confirmation modal.
     *
     * @memberof ModalDialogComponent
     */
    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}

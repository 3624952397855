import { Pipe, PipeTransform } from '@angular/core';
import { DiaryTypeEnum } from 'app/core/hub-api';
import { icons } from '../values/icons';
import { inspectionOutcomeStatusIds } from '../values/inspection-outcome-status-ids';

@Pipe({
  name: 'idToActivity'
})
export class IdToActivityPipe implements PipeTransform {
  transform(value: number, inspectionId?: string): any {
    if (!value) {
      return {
        text: 'None'
      };
    }
    switch (value) {
      case DiaryTypeEnum.Diary:
        return {
          text: 'Diary',
          icon: icons.diary
        };
      case DiaryTypeEnum.Dismantle:
        return {
          text: 'Dismantle',
          icon: icons.dismantle
        };
      case DiaryTypeEnum.Variation:
        return {
          text: 'Variation',
          icon: icons.variation
        };
      case DiaryTypeEnum.Adaption:
        return {
          text: 'Adaption',
          icon: icons.variation
        };
      case DiaryTypeEnum.FullHandover:
        return {
          text: 'Handover',
          icon: icons.handover
        };
      case DiaryTypeEnum.PartialHandover:
        return {
          text: 'Re-Handover',
          icon: icons.handover
        };
      case DiaryTypeEnum.FullOffHire:
        return {
          text: 'Full off-hire',
          icon: icons.offhire
        };
      case DiaryTypeEnum.PartialOffHire:
        return {
          text: 'Partial off-hire',
          icon: icons.offhire
        };
      case DiaryTypeEnum.InspectionFollowUpNotify:
        return {
          text: 'Inspection follow up actions',
          icon: icons.inspectionFollowUp
        };
      case DiaryTypeEnum.InspectionFollowUpInstruction:
        return {
          text: 'Remedial actions',
          icon: icons.inspectionFollowUp
        };
      case DiaryTypeEnum.UnsafeScaffoldReport:
        return {
          text: 'Unsafe scaffold report',
          icon: icons.unsafeScaffold
        };
      case DiaryTypeEnum.PauseInspections:
        return {
          text: 'Inspections paused',
          icon: icons.paused
        };
      case DiaryTypeEnum.StopInspections:
        return {
          text: 'Inspections stopped',
          icon: icons.stopped
        };
      case DiaryTypeEnum.ResumeInspections:
        return {
          text: 'Inspections resumed',
          icon: icons.resumed
        };
      case DiaryTypeEnum.Inspection:
        return {
          text: 'Inspection',
          icon: this.getIconPathBasedOnInspectionResult(inspectionId[1])
        };
    }
    return value;
  }

  getIconPathBasedOnInspectionResult(inspectionId: string): string {
    switch (inspectionId) {
      case inspectionOutcomeStatusIds.noDefects:
        return icons.noDefects;
      case inspectionOutcomeStatusIds.defectsFixed:
        return icons.defectsFixed;
      case inspectionOutcomeStatusIds.defectsNotFixed:
        return icons.defectsNotFixed;
      case inspectionOutcomeStatusIds.unsafeScaffold:
        return icons.unsafeScaffold;
      default:
        return icons.inspection;
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable()
export class TimeoutService {

  // Iterate over the data array, passing each item in the array to the given function func,
  // calling setTimeout after the timeoutAfter number of iterations to free up the browser.
  //
  // Note that if work is so CPU intensive that it needs this function, maybe it should be done on the server.
  forWithTimeouts(data: any[], func: Function, timeoutAfter: number): Promise<any[]> {
    let i = 0;
    let resolve;
    const promise = new Promise((res) => { resolve = res; });
    const processData = function (): any {
      for (; i < data.length; i++) {
        func(data[i]);
        if (i + 1 < data.length && (i + 1) % timeoutAfter === 0) {
          setTimeout(processData, 0);
          i++;
          return promise;
        }
      }
      resolve();
      return promise;
    };

    return processData();
  }

}

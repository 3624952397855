import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ScaffoldStatisticsData } from 'app/core/hub-api';
import { colors } from '../../values/colours';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'hub-handovers-extra-hire-chart',
  templateUrl: './handovers-extra-hire-chart.component.html',
  styleUrls: ['./handovers-extra-hire-chart.component.scss']
})
export class HandoversExtraHireChartComponent implements OnInit, OnChanges {

  @Input()
  scaffoldData: ScaffoldStatisticsData;

  Highcharts: typeof Highcharts = Highcharts;
  chart: any;

  chartConfig = {
    credits: { enabled: false },
    title: {
      text: '',
      y: 15,
      style: {
        color: colors.river
      }
    },
    subtitle: {
      text: 'Scaffolds On Hire',
      y: 30
    },
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent'
    },
    colors: [colors.positive, colors.assertive],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter: function (): any {
            return this.y > 0 ? this.point.y : null;
          },
          color: 'black'
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          formatter(): any {
            return this.y > 0 ? Math.round(this.percentage * 100) / 100 + ' %' : null;
          },
          distance: 15,
          color: 'black'
        },
        showInLegend: true,
        innerSize: '80%'
      }
    },
    series: []
  };

  constructor() { }

  ngOnInit(): void {
    this.chart = Highcharts.chart('handoversExtraHireChart', this.chartConfig);
    this.updateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scaffoldData'] && !!this.chartConfig) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    if (this.chart) {

      while (!!this.chart.series[0]) { this.chart.series[0].remove(); }

      this.chart.setTitle({ text: this.scaffoldData.TotalScaffoldsOnHire.toString() });

      this.chart.addSeries(
        {
          type: 'pie',
          name: 'Scaffolds',
          data: [
            ['On Hire', this.scaffoldData.TotalScaffoldsOnHire - this.scaffoldData.TotalScaffoldsInExtraHire],
            ['Extra Hire', this.scaffoldData.TotalScaffoldsInExtraHire]
          ]
        }
      );
      this.chart.redraw();
    }
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { cloneDeep, map } from 'lodash';
import {
  CreateContractContactCommand,
  CustomerWebAppData,
  EditContractContactCommand,
  ReportSubscriptionTypesEnum,
} from 'app/core/hub-api';
import {
  ContactTypeData,
  ContractCommandService,
  ContractContactData,
  ContractData,
  SiteWebAppData,
} from 'app/core/hub-api';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import * as moment from 'moment';
import { contactTypeIds } from '../../../../../shared/values/contact-type-ids';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddEditContractModalContext } from 'app/sites/models/add-edit-contract-modal-context';

/**
 * Add edit Contract Contact Modal
 * Shows the add or edit contract contact form
 *
 * @export
 * @class AddEditContractContactModalComponent
 * @extends {ModalFormComponent<AddEditSiteModalContext>} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 * @implements {OnDestroy} Stops all subscriptions and closes the component
 */
@Component({
  selector: 'hub-add-edit-contract-contact-modal',
  templateUrl: './add-edit-contract-contact-modal.component.html',
  styleUrls: ['./add-edit-contract-contact-modal.component.scss']
})
export class AddEditContractContactModalComponent extends FormComponent implements OnInit, OnDestroy {
  /* Stores all parameters for this modal */
  context: Partial<AddEditContractModalContext>;
  /** Detects if the form is edit or add */
  editMode: boolean;
  /** Decides if it shows the contract dropdown */
  chooseContractMode: boolean;
  /** Current selected site */
  site: SiteWebAppData;
  /** Selected contract */
  contract: ContractData;
  /** Selected contract contact */
  contractContact: ContractContactData;
  /** Stores all customers */
  customers: CustomerWebAppData[];
  /** Stores all contact types */
  contactTypes: ContactTypeData[];
  /** Stores the modal title */
  modalTitle: string;
  /** Stores the modal title */
  openContracts: ContractData[];
  /** Form Group control */
  form: UntypedFormGroup;
  /** Contract dropdown controller */
  contractIdFormControl: UntypedFormControl;
  /** Contact name input controller */
  contactNameFormControl: UntypedFormControl;
  /** Contact type input controller */
  contactTypeIdFormControl: UntypedFormControl;
  /** Email address input controller */
  emailAddressFormControl: UntypedFormControl;
  /** Phone number input controller */
  phoneNumberFormControl: UntypedFormControl;
  /** Handover certificate checkbox controller */
  handoverCertificateFormControl: UntypedFormControl;
  /** Variation Instructions checkbox controller */
  variationInstructionsFormControl: UntypedFormControl;
  /** Inspections Completed checkbox controller */
  inspectionsCompletedFormControl: UntypedFormControl;
  /** Detailed Inspection checkbox controller */
  detailedInspectionFormControl: UntypedFormControl;
  /** Inspection Statuses checkbox controller */
  inspectionStatusesFormControl: UntypedFormControl;
  /** Sub-Contractor Inspections checkbox controller */
  subContractorInspectionsFormControl: UntypedFormControl;
  /** All validation messages for the respective form fields */
  validationMessages = {
    contractId: {
      required: errorMessages.required
    },
    contactName: {
      required: errorMessages.required,
    },
    contactTypeId: {
      required: errorMessages.required
    },
    emailAddress: {
      required: errorMessages.required,
      pattern: errorMessages.emailInvalid
    }
  };

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private contractCommandService: ContractCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
  ) { super(); }

  /**
   * Initialises the component
   *
   * @memberof AddEditContractContactModalComponent
   */
  public ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.context.editMode;
    this.chooseContractMode = this.context.chooseContractMode;
    this.site = this.context.site;
    this.contract = this.context.contract;
    const contractNameRef = `${this.contract.ContractName}(${this.contract.ContractReference})`;
    this.modalTitle = this.editMode ? `Edit Customer Contact - ${contractNameRef}` : `Add Customer Contact - ${contractNameRef}`;
    this.contractContact = cloneDeep(this.context.contractContact) || this.getNewContractContact();
    this.customers = this.context.customers;
    this.contactTypes = map(this.context.configuration.ContactTypes).filter((type) => {
      return type.ContactTypeId !== contactTypeIds.bccContact && type.ContactTypeId !== contactTypeIds.companyContact;
    });

    this.openContracts = this.site.Contracts
      .filter(c => c.ContractStatusId === contractStatusIds.open)
      .map(contract => {
        contract = cloneDeep(contract);
        const customerName = this.customers.find(c => c.CustomerId === contract.CustomerId).CustomerName;
        (contract as any).optionName = `${customerName} (${contract.ContractReference})`;
        return contract;
      });

    this.generateForm();
    super.ngOnInit();
  }

  /**
   * Generates the form with values and validation
   *
   * @memberof AddEditContractContactModalComponent
   */
  public generateForm(): void {
    this.contractIdFormControl = new UntypedFormControl(this.contract ? this.contract.ContractId : '', Validators.required);
    this.contactNameFormControl = new UntypedFormControl(this.contractContact.ContactName, [Validators.required]);
    this.contactTypeIdFormControl = new UntypedFormControl(this.contractContact.ContactTypeId, Validators.required);
    this.emailAddressFormControl = new UntypedFormControl(this.contractContact.EmailAddress, [Validators.required, Validators.pattern(emailAddressRegex)]);
    this.phoneNumberFormControl = new UntypedFormControl(this.contractContact.PhoneNumber);
    this.handoverCertificateFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.HandoverCertificate));
    this.variationInstructionsFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.VariationInstructions));
    this.inspectionsCompletedFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionsCompleted));
    this.detailedInspectionFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SiteInspectionsCompleted));
    this.inspectionStatusesFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.InspectionStatuses));
    this.subContractorInspectionsFormControl =
      new UntypedFormControl(!!(this.contractContact.ReportSubscriptionTypes & ReportSubscriptionTypesEnum.SubContractorInspections));
    this.form = new UntypedFormGroup({
      contractId: this.contractIdFormControl,
      contactName: this.contactNameFormControl,
      contactTypeId: this.contactTypeIdFormControl,
      emailAddress: this.emailAddressFormControl,
      phoneNumber: this.phoneNumberFormControl,
      handoverCertificate: this.handoverCertificateFormControl,
      variationInstructions: this.variationInstructionsFormControl,
      inspectionsCompleted: this.inspectionsCompletedFormControl,
      detailedInspection: this.detailedInspectionFormControl,
      inspectionStatuses: this.inspectionStatusesFormControl,
      subContractorInspections: this.subContractorInspectionsFormControl
    });
  }

  /**
   * Creates a new contract contact object
   *
   * @private
   * @returns {ContractContactData}
   * @memberof AddEditContractContactModalComponent
   */
  private getNewContractContact(): ContractContactData {
    return Object.assign(new ContractContactData(), {
      ContractContactId: UUID.UUID(),
      ContactName: '',
      EmailAddress: '',
      PhoneNumber: '',
      IsInactive: false,
      ReportSubscriptionTypes: ReportSubscriptionTypesEnum.None,
      // Same as existing or weekly
      // ScheduleType: ScheduleTypeEnum.None,
      // ScheduledReportStartDate: '',
      ContactTypeId: '',
    });
  }

  /**
   * Sets the contract contact to inactive
   *
   * @memberof AddEditContractContactModalComponent
   */
  public deleteContractContact(): void {
    this.contractContact.IsInactive = true;
  }

  /**
   * Activates the contract contact
   *
   * @memberof AddEditContractContactModalComponent
   */
  public undoDeleteContractContact(): void {
    this.contractContact.IsInactive = false;
  }

  /**
   * Submits the form to api
   *
   * @param {*} formValues all form values
   * @memberof AddEditContractContactModalComponent
   */
  public onSubmit(formValues): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const contract = this.site.Contracts.find(c => c.ContractId === formValues.contractId);
      const contacts = map(contract.ContractContacts).filter(c => !c.IsInactive);

      const command: CreateContractContactCommand | EditContractContactCommand = {
        ContractContactId: this.contractContact.ContractContactId,
        ContactName: formValues.contactName,
        EmailAddress: formValues.emailAddress,
        PhoneNumber: formValues.phoneNumber,
        ContractId: formValues.contractId,
        ReceiveInspectionReports: true,
        IsInactive: this.contractContact.IsInactive,
        ScheduledReportStartDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
        ReportSubscriptionTypes: this.getReportSubscriptionType(formValues),
        ContactTypeId: formValues.contactTypeId
      };

      const commandFunction = this.editMode
        ? this.contractCommandService.EditContractContactCommand
        : this.contractCommandService.CreateContractContactCommand;

      commandFunction.call(this.contractCommandService, command)
        .subscribe(() => {
          // Here we must reload any data that this add/edit affects from the server.
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference)
            .subscribe(() => {
              this.saveInProgress = false;
              this.modalService.setDismissReason(command.ContractId);
              this.bsModalRef.hide();
            });
        }, this.serverErrorCallback);
    }
  }

  /**
   * Returns the report subscription type as a total count
   *
   * @private
   * @param {*} formValues
   * @returns {number}
   * @memberof AddEditContractContactModalComponent
   */
  private getReportSubscriptionType(formValues: any): number {
    return (ReportSubscriptionTypesEnum.HandoverCertificate * +formValues.handoverCertificate) +
      (ReportSubscriptionTypesEnum.VariationInstructions * +formValues.variationInstructions) +
      (ReportSubscriptionTypesEnum.InspectionsCompleted * +formValues.inspectionsCompleted) +
      (ReportSubscriptionTypesEnum.SiteInspectionsCompleted * +formValues.detailedInspection) +
      (ReportSubscriptionTypesEnum.InspectionStatuses * +formValues.inspectionStatuses) +
      (ReportSubscriptionTypesEnum.SubContractorInspections * +formValues.subContractorInspections);
  }

  /**
   * Checks if it is the principal contract
   *
   * @returns {boolean}
   * @memberof AddEditContractContactModalComponent
   */
  public principalContractSelected(): boolean {
    const contract = this.site.Contracts.find(c => c.ContractId === this.contractIdFormControl.value);
    return contract ? contract.IsPrincipalContract : false;
  }


  /**
 * Detect when the selected contact type changes and sets it to the form
 *
 * @param {string} contactTypeId
 * @memberof AddEditContractModalComponent
 */
  public onSelectedContactTypeChanged(contactTypeId: string): void {
    this.form.controls.contactTypeId.setValue(contactTypeId);
  }

  /**
   * Required by AutoUnsubscribe()
   *
   * @memberof AddEditContractContactModalComponent
   */
  ngOnDestroy(): void { }

}

<div class="media-container" [ngClass]="{'transcription-container' : mediaType === 'transcription' }"
  *ngIf="media || transcription">
  <div class="audio-bg" *ngIf="mediaType === 'audio'">
    <i class="fa fa-headphones fa-5x"></i>
    <p>{{ media.Title }}</p>
  </div>
  <audio *ngIf="mediaType === 'audio'" controls>
    <!-- Hardcoding the mimetype to mp4 rather than using the actual mimetype seems to give the best compatibility results for different audio formats across different browsers. -->
    <source [src]="media.Path" type="audio/mp4"> Your browser does not support HTML5 audio.
  </audio>
  <video *ngIf="mediaType === 'video'" controls>
    <source [src]="media.Path" type="video/mp4"> Your browser does not support the HTML5 video.
  </video>
  <img *ngIf="mediaType === 'image'" [src]="media.Path" [alt]="media.Title">
  <div *ngIf="transcription && mediaType === 'transcription'">
    <div [froalaEditor]="editorOptions" [(froalaModel)]="transcription.Transcription"></div>
    <button [disabled]="savingMedia || !transcriptionChanged()" (click)="onSaveTranscription()"
      class="btn button-default save-media-button pull-right margin-top" role="button">
      <span *ngIf="!savingMedia"><i class="fa fa-save"></i>Save</span>
      <span *ngIf="savingMedia"><i class="fa fa-spinner fa-spin"></i></span>
    </button>
  </div>
  <div class="diary-pdf-viewer">
    <ngx-extended-pdf-viewer *ngIf="mediaType === 'pdf'" [src]="media.Path" [filenameForDownload]="media.Title"
      [useBrowserLocale]="true">
    </ngx-extended-pdf-viewer>
  </div>
</div>
<div *ngIf="!showCustomerForm">
  <form name="importContractForm" [formGroup]="form">
    <div class="site-import-contract">
      <div *ngIf="!customerAdded && !customerFormControl.value && !deletedCustomer" class="text-center">
        <p class="validation-error" *ngIf="data.siteImportFile.CustomerAddress.AddressName">
          <i class="fa fa-warning text-assertive"></i> No customer with the name
          '{{data.siteImportFile.CustomerAddress.AddressName}}' was found. Please
          <a (click)="onAddCustomer()" class="link">add a new customer</a> or select an existing customer by entering
          the name in the input box below.
        </p>
        <p class="validation-error" *ngIf="!data.siteImportFile.CustomerAddress.AddressName">
          <i class="fa fa-warning text-assertive"></i> No customer details found. Please
          <a (click)="onAddCustomer()" class="link">add a new customer</a> or select an existing customer by entering
          the name in the input box below.
        </p>
      </div>
      <div *ngIf="deletedCustomer && !customerAdded">
        <p class="validation-error">
          <i class="fa fa-warning text-assertive"></i> It looks like you are trying to import a deleted customer. You
          can
          <a (click)="onReactivateCustomer()" class="link text-bold">reactivate</a>
          <strong>"{{data.siteImportFile.CustomerAddress.AddressName}}"</strong>,
          <a (click)="onAddCustomer()" class="link text-bold">add a new customer</a> or select an existing customer by
          entering the name in the input box below.
        </p>
      </div>

      <fieldset *ngIf="!customerAdded">
        <hub-form-group label="Customer Name" name="customer" [control]="customerFormControl"
          [validationErrors]="validationErrors">
          <div class="row">
            <div class="col-md-12">
              <div class="input-button">
                <hub-dropdown formControlName="customer" class="auto-complete" [items]="filteredCustomers"
                  idProperty="CustomerId" titleProperty="CustomerName" icon="fa fa-users text-positive"
                  [multiselect]="false" (valueChanges)="onSelectedCustomer($event)" [clearable]="true"
                  placeholder="Select a customer">
                </hub-dropdown>
                <div class="right-button">
                  <hub-permission permissions="AddEditCustomers">
                    <a class="btn button button-primary" role="button" (click)="onAddCustomer()">
                      <i class="fa fa-plus"></i><span>Add</span>
                    </a>
                  </hub-permission>
                </div>
              </div>
            </div>
          </div>
        </hub-form-group>
      </fieldset>

      <div *ngIf="customerAdded" class="form-group">
        <label>*Customer Name</label>
        <input type="text" class="form-control" [value]="data.importData.customer.CustomerName" disabled>
        <span class="pull-right text-smaller" *ngIf="!showAreYouSure">
          <a class="link text-assertive" (click)="showAreYouSure = true">
            <i class="fa fa-times"></i> remove new customer
          </a> |
          <a class="link" (click)="onEditCustomer()">
            <i class="fa fa-pencil"></i> edit
          </a>
        </span>
        <span class="pull-right text-smaller" *ngIf="showAreYouSure">
          Are you sure?
          <a class="link text-assertive" (click)="onRemoveCustomer()">yes remove customer</a> /
          <a class="link" (click)="showAreYouSure = false">no keep them</a>
        </span>
      </div>
      <hub-form-group label="Contract Reference" name="contractReference" [control]="contractReferenceFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="contractReference" ngxTrim>
      </hub-form-group>
      <hub-form-group label="Contract Name" name="contractName" [control]="contractNameFormControl"
        [validationErrors]="validationErrors">
        <input type="text" class="form-control" formControlName="contractName" ngxTrim>
      </hub-form-group>
      <hub-form-group label="Contract Type" name="contractType" [control]="contractTypeFormControl">
        <select class="form-control" formControlName="contractType">
          <option value="">None</option>
          <option *ngFor="let type of contractTypes" [value]="type.ContractTypeId">{{type.Title}}</option>
        </select>
      </hub-form-group>
      <hub-form-group label="Contract Description" name="contractDescription"
        [control]="contractDescriptionFormControl">
        <input type="text" class="form-control" formControlName="contractDescription" ngxTrim>
      </hub-form-group>

      <hub-form-group label="Is this customer the main contractor?" name="mainContractor"
        [control]="mainContractorFormControl" [validationErrors]="validationErrors">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="mainContractor" [value]="true"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="mainContractor" [value]="false"> No
            </label>
          </div>
        </div>
      </hub-form-group>
      <hub-form-group label="Allow handover/off-hire documents to be submitted without the customer signature?"
        name="submitWithoutSignature" [control]="submitWithoutSignatureFormControl"
        [validationErrors]="validationErrors">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="submitWithoutSignature" [value]="true"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="submitWithoutSignature" [value]="false"> No
            </label>
          </div>
        </div>
      </hub-form-group>
      <hub-form-group label="Are you contracted to carry out inspections for this customer?" name="inspectionStatus"
        [control]="inspectionStatusFormControl" [validationErrors]="validationErrors">
        <div class="pull-right">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="inspectionStatus" [value]="inspectionStatusEnum.Normal"> Yes
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="inspectionStatus" [value]="inspectionStatusEnum.Stopped"> No
            </label>
          </div>
        </div>
      </hub-form-group>
      <div class="modal-footer">
        <span class="required-note">* Denotes a required field</span>
        <div class="modal-buttons">
          <button class="btn btn-large btn-inverse" type="button" (click)="onPrevious(form.value)">
            <i class="fa fa-chevron-circle-left"></i> Back
          </button>
          <button class="btn btn-large btn-inverse" type="button" [disabled]="noCustomerFound && !customerAdded"
            (click)="onNext(form.value)">
            <i class="fa fa-chevron-circle-right"></i> Next
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="showCustomerForm">
  <hub-site-import-customer [data]="data" [context]="context" (cancel)="onCancelAddCustomer()"
    (done)="onDoneAddCustomer()" [editMode]="customerEditMode"></hub-site-import-customer>
</div>
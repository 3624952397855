import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HandoverStatisticsData } from 'app/core/hub-api';

import { DashboardQueryService } from '../services/dashboard-query.service';

@Injectable()
export class HandoverStatisticsResolver implements Resolve<HandoverStatisticsData> {

  constructor(private dashboardQueryService: DashboardQueryService) { }

  resolve(): Observable<HandoverStatisticsData> {
    return this.dashboardQueryService.getHandoverData();
  }
}

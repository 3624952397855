<form name="importCustomerForm" [formGroup]="form">
    <hub-form-group label="Name" name="customerName" [control]="customerNameFormControl" [validationErrors]="validationErrors">
      <input type="text" class="form-control" formControlName="customerName" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Address" name="customerAddress" [control]="customerAddressFormControl" [validationErrors]="validationErrors">
      <input type="text" class="form-control" formControlName="customerAddress" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Customer Reference" name="customerReference" [control]="customerReferenceFormControl" [validationErrors]="validationErrors">
      <input type="text" class="form-control" formControlName="customerReference" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Phone Number" name="phoneNumber" [control]="phoneNumberFormControl" [validationErrors]="validationErrors">
      <input type="text" class="form-control" formControlName="phoneNumber" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Email Address" name="emailAddress" [control]="emailAddressFormControl" [validationErrors]="validationErrors">
      <input type="email" class="form-control" formControlName="emailAddress" ngxTrim>
    </hub-form-group>
    <hub-form-group label="Website" name="website" [control]="websiteFormControl" [validationErrors]="validationErrors">
      <input type="text" class="form-control" formControlName="website" ngxTrim>
    </hub-form-group>
    <hub-modal-form-footer (submit)="onSubmit(form.value)" (close)="onCancel()" saveText="Add Customer"></hub-modal-form-footer>
</form>

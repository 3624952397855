import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  ConfigurationData,
  CustomerWebAppData,
  DepotData,
  SiteWebAppData,
  StaffDetailsWebAppData
} from 'app/core/hub-api';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddEditSiteModalComponent } from '../add-edit-site-modal/add-edit-site-modal.component';
import { ConfigurationQueryService } from '../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../core/services/customers-query.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DepotsQueryService } from '../../../../core/services/depots-query.service';
import { LoggedInStaffService } from '../../../../core/services/logged-in-staff-service';
import { combineLatest } from 'rxjs';
import { SiteImportModalComponent } from '../../site-import/site-import-modal/site-import-modal.component';
import { SitesQueryService } from '../../../../core/services/sites-query.service';
import { SitesResolver } from '../../../../core/resolvers/sites.resolver';
import { cloneDeep } from 'lodash';
import { mapValues } from '../../../../shared/values/maps';
import { siteStatusIds } from '../../../../shared/values/site-status-ids';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { LimitReachedModalComponent } from 'app/shared/components/limit-reached-modal/limit-reached-modal.component';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@AutoUnsubscribe()
@Component({
  selector: 'hub-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss']
})
export class SitesListComponent implements OnInit, OnDestroy {
  mapView = false;
  noOfSites: number;
  configuration: ConfigurationData;
  depots: DepotData[];
  allDepots: DepotData[];
  loggedInStaff: StaffDetailsWebAppData;

  allCustomers: CustomerWebAppData[];
  allSites: SiteWebAppData[];
  filteredSites: SiteWebAppData[];

  filtersForm: UntypedFormGroup;
  searchFormControl: UntypedFormControl;
  showClosedSitesFormControl: UntypedFormControl;
  selectedDepot: DepotData;

  @ViewChild(DatatableComponent) sitesTable: DatatableComponent;

  /** This property decorator configures a view query for the map's markers */
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

  map = cloneDeep(mapValues);

  constructor(
    private route: ActivatedRoute,
    private sitesResolver: SitesResolver,
    private router: Router,
    private sitesQueryService: SitesQueryService,
    private customersQueryService: CustomersQueryService,
    private configurationQueryService: ConfigurationQueryService,
    private depotsQueryService: DepotsQueryService,
    private loggedInStaffService: LoggedInStaffService,
    private companySettingsService: CompanySettingsService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.searchFormControl = new UntypedFormControl('');
    this.showClosedSitesFormControl = new UntypedFormControl(false);

    this.filtersForm = new UntypedFormGroup({
      search: this.searchFormControl,
      showClosedSites: this.showClosedSitesFormControl
    });

    this.searchFormControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((searchTerm: string) => this.updateFilters(searchTerm, this.showClosedSitesFormControl.value));

    this.showClosedSitesFormControl.valueChanges.subscribe((showClosedSites: boolean) =>
      this.updateFilters(this.searchFormControl.value, showClosedSites)
    );
    const obs$ = combineLatest(
      [this.sitesQueryService.sitesDataChanges(),
      this.customersQueryService.customersDataChanges(),
      this.configurationQueryService.configurationDataChanges(),
      this.depotsQueryService.depotsDataChanges(),
      this.loggedInStaffService.loggedInStaffChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2], latest[3], latest[4]));
  }

  refreshComponent(
    sites: SiteWebAppData[],
    customers: CustomerWebAppData[],
    configuration: ConfigurationData,
    depots: DepotData[],
    loggedInStaff: StaffDetailsWebAppData
  ): void {
    this.allDepots = depots;
    // When logout the logged in staff gets cleared
    if (loggedInStaff == null) {
      return;
    }
    this.loggedInStaff = loggedInStaff;
    this.configuration = configuration;
    this.depots = depots
      .filter(d => this.loggedInStaff.SiteDepotIds.includes(d.SiteDepotId))
      .sort((a, b) => (a.DepotName.toLowerCase() > b.DepotName.toLowerCase() ? 1 : -1));
    this.allCustomers = customers;
    this.allSites = sites.map(site => {
      const tableSite = cloneDeep(site) as any;
      tableSite.depotName = this.depots.filter(d => site.SiteDepotId.includes(d.SiteDepotId)).map(d => d.DepotName);
      tableSite.customerList = this.allCustomers
        .filter(customer => site.Customers.includes(customer.CustomerId))
        .map(customer => customer.CustomerName)
        .sort()
        .join(', ');
      return tableSite;
    });
    this.filteredSites = this.allSites;
    this.updateFilters(this.searchFormControl.value, this.showClosedSitesFormControl.value);
  }

  updateFilters(searchTerm: string, showClosedSites: boolean): void {
    this.filteredSites = this.allSites.filter(site => {
      const searchFilterResult = site.SiteName.toLowerCase().includes(searchTerm.toLowerCase());
      const showClosedSitesFilterResult = showClosedSites || site.SiteStatusId === siteStatusIds.open;
      return this.selectedDepot
        ? site.SiteDepotId === this.selectedDepot.SiteDepotId && searchFilterResult && showClosedSitesFilterResult
        : searchFilterResult && showClosedSitesFilterResult;
    });
    if (this.sitesTable) {
      this.sitesTable.offset = 0;
    }
  }

  onAddSite(): void {
    this.companySettingsService.getCompanyDetailsFromServer().subscribe(companyDetails => {
      const sitesLimit = companyDetails.MaxOpenSites;

      this.configurationQueryService.configurationQueryFromServer().subscribe(configuration => {
        this.noOfSites = configuration.CompanyStatisticData.NoOfOpenSite;

        if (this.noOfSites < sitesLimit) {
          const context = {
            editMode: false,
            configuration: configuration,
            depots: this.allDepots,
            loggedInStaff: this.loggedInStaff,
            stockControl: companyDetails.FeatureFlags.stockControl
          };
          const modal = this.modalService.show(AddEditSiteModalComponent, SSModalConfig.generate(context))
          modal.onHide.subscribe(
            siteReference => {
              if (siteReference === null || typeof siteReference !== 'string') return;
              this.router.navigate([`../${siteReference}`], { relativeTo: this.route });
            },
            error => {
              console.error(error);
            }
          );
        } else {
          const context = {
            editMode: false,
            NoOfOpenSites: this.noOfSites,
            ClientMaxSitesLimit: sitesLimit,
            configuration: configuration,
            depots: this.allDepots,
            loggedInStaff: this.loggedInStaff,
            type: 'site'
          };
          const modal = this.modalService.show(LimitReachedModalComponent, SSModalConfig.generate(context))
          modal.onHide.subscribe(
            siteReference => {
              if (siteReference === null || typeof siteReference !== 'string') return;
              this.router.navigate([`../${siteReference}`], { relativeTo: this.route });
            },
            error => {
              console.error(error);
            }
          );
        }
      });
    });
  }

  onImportSite(): void {
    const context = {
      sites: this.allSites,
      customers: this.allCustomers,
      configuration: this.configuration,
      depots: this.depots,
      loggedInStaff: this.loggedInStaff
    };
    const modal = this.modalService.show(SiteImportModalComponent, SSModalConfig.generate(context));
    modal.onHide.subscribe(
      siteReference => {
        if (siteReference === null || typeof siteReference !== 'string') return;
        this.router.navigate([`../${siteReference}`], { relativeTo: this.route });
      },
      error => {
        console.error(error);
      }
    );
  }

  onSiteSelected(event: any): void {
    const site: SiteWebAppData = event.selected[0];
    this.router.navigate([`/sites/${site.SiteReference}`]);
  }

  onSelectDepot(depot?: DepotData): void {
    this.selectedDepot = depot;
    if (!this.selectedDepot) {
      this.filteredSites = this.allSites;
    }
    this.updateFilters(this.searchFormControl.value, this.showClosedSitesFormControl.value);
  }

  /**
   * Open the google maps info window when a marker is clicked
   *
   * @memberof SitesListComponent
   */
  openInfoWindow(marker: MapMarker, windowIndex: number) {
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }

  ngOnDestroy(): void { }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-modal-form-header',
  templateUrl: './modal-form-header.component.html',
  styleUrls: ['./modal-form-header.component.scss']
})
export class ModalFormHeaderComponent implements OnInit {

  @Input()
  title: string;
  @Output()
  /* eslint-disable-next-line @angular-eslint/no-output-native */
  close = new EventEmitter<void>();

  constructor(public bsModalRef: BsModalRef) { }


  ngOnInit(): void { }

}

import { Component, OnInit } from '@angular/core';
import { LookupDataBase } from 'app/core/hub-api';
import { ListPreviewModalContext } from '../../models/list-preview-modal-context';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-list-preview-modal',
  templateUrl: './list-preview-modal.component.html',
  styleUrls: ['./list-preview-modal.component.scss']
})
export class ListPreviewModalComponent implements OnInit {
  context: Partial<ListPreviewModalContext>;

  title: string;
  mobileListItems: LookupDataBase[];
  constructor(public bsModalRef: BsModalRef, public modalService: BsModalService) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.title = this.context.listTitle;
    this.mobileListItems = this.context.mobileListItems;
    this.context.dialogClass = 'modal-dialog modal-lg';
  }
}

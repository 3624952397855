<div class="ss-container" *ngIf="dismantleData">
  <div class="row">
    <div class="col-md-12">
      <div class="dismantle-card">
        <h3 [ngClass]="{'margin-none': !dismantleData.MediaUrls.length && !dismantleData.Notes}">
          <img [src]="'assets/icons/inspection-results/inspection-result-dismantled.svg'" width="18" height="18"> Scaffold Dismantled
          <span class="dismantle-right">
            <span>
              <i class="fa fa-user"></i>
              <span>{{dismantleData.OwnerName}}</span>
            </span>
            <span>
              <i class="fa fa-calendar"></i> {{ dismantleData.When | datetime: 'lll' }}
            </span>
          </span>
        </h3>
        <blockquote *ngIf="dismantleData.Notes">
          <p>
            <i class="fa fa-comment"></i>
            <span> {{dismantleData.Notes}}</span>
          </p>
        </blockquote>
        <div class="image-container" *ngIf="dismantleData.MediaUrls.length">
          <div>
            <hub-image-carousel [media]="dismantleData.MediaUrls"></hub-image-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form name="importContractForm" [formGroup]="form" class="import-scaffold-scaffolds-page">
  <div *ngIf="!anyScaffoldsInFile">
    <div class="empty-state-placeholder">
      <i class="fa fa-times fa-4x"></i>
      <h3>No scaffolds found</h3>
    </div>
  </div>
  <table *ngIf="anyScaffoldsInFile" formArrayName="items" class="table table-borderless import-scaffold-table">
    <thead>
      <tr>
        <th colspan="4" class="import-body-width">
          <div class="checkbox">
            <label>
              <input type="checkbox" [checked]="importAll && allScaffoldSelected()" (click)="onToggleImportAll()">Import
              all
            </label>
          </div>
          <a class="link pull-right" (click)="onStartTour()">
            <i class="fa fa-blind"></i> Start tour</a>
        </th>
      </tr>
    </thead>
    <tbody [formGroupName]="i" *ngFor="let item of form.get('items')['controls']; let i = index;" class="row-group">
      <tr>
        <td colspan="4" class="row-group-header">
          <a (click)="item.showRows = !item.showRows">
            <span class="glyphicon"
              [ngClass]="{ 'glyphicon-chevron-right': !item.showRows, 'glyphicon-chevron-down': item.showRows }"></span>
            <strong class="inline-block">{{item.controls.itemName.value}} (Item number
              {{item.controls.itemNumber.value}})</strong>
          </a>
          <div class="pull-right">
            <span class="inline-block hire-period">
              <i class="fa fa-clock"></i>
              <strong>Hire Period: </strong> {{item.controls.hirePeriodInWeeks.value}}wks</span>
            <div class="quantity-input">
              <strong>Quantity: </strong> {{item.controls.quantity.value}}
            </div>
          </div>
        </td>
      </tr>
      <tr formArrayName="scaffolds" *ngFor="let scaffold of item.controls.scaffolds.controls; let j = index;"
        class="scaffold-row">
        <td [formGroupName]="j" *ngIf="item.showRows" class="vert-align-middle">
          <div class="checkbox">
            <label>
              <input type="checkbox" name="ImportSelected" formControlName="selected">
            </label>
          </div>
        </td>
        <td [formGroupName]="j" *ngIf="item.showRows" colspan="1" title="'Reference'" sortable="'Reference'"
          class="align-middle">
          <!-- Reference -->
          <div class="form-group" id="scaffold-ref">
            <input class="form-control" type="text" placeholder="reference"
              [value]="scaffold.controls.scaffoldNumber.value" name="ScaffoldReference" disabled>
          </div>
          <!-- Scaffold Type -->
          <div class="form-group" id="scaffold-type">
            <select class="form-control" formControlName="scaffoldTypeId" name="ScaffoldType" id="ScaffoldType">
              <option value="">-- select scaffold type --</option>
              <option *ngFor="let type of scaffoldTypes" [value]="type.ScaffoldTypeId">{{type.Title}}</option>
            </select>
          </div>
        </td>
        <td [formGroupName]="j" *ngIf="item.showRows" colspan="3" class="align-middle">
          <div class="three-col-inputs" [ngClass]="{'double-size': !data.importData.site.IsManageStockControl}">
            <div class="form-group" id="scaffold-name">
              <input class="form-control" formControlName="specificLocationDetails" type="text"
                placeholder="scaffold name" ngxTrim>
            </div>
            <div class="form-group" id="scaffoldingSystems">
              <select class="form-control" formControlName="scaffoldingSystemId" name="ScaffoldingSystems"
                id="ScaffoldingSystems">
                <option value="">-- select scaffolding system --</option>
                <option *ngFor="let sys of scaffoldingSystems" [value]="sys.ScaffoldingSystemId">{{sys.Title}}
                </option>
              </select>
            </div>
            <div *ngIf="scaffold.controls.scaffoldingSystemId.value !== ''" class="input-group" id="maximumTonnage"
              [hidden]="!data.importData.site.IsManageStockControl">
              <input class="form-control addon-inline"
                [ngClass]="{'input-error': scaffold.controls.maximumTonnage?.errors?.invalidValue }"
                formControlName="maximumTonnage" type="number" placeholder="0.00" step="0.01"
                [tooltip]="scaffold.controls.maximumTonnage?.errors?.invalidValue ? invalidValueMessage : ''"
                placement="bottom" ngxTrim>
              <span class="input-group-addon addon-inline input-source-observer">tons</span>
            </div>
          </div>

          <div class="three-col-inputs">
            <div class="form-group" id="scaffold-loading">
              <select class="form-control" formControlName="loadingLimitId" name="LoadingLimit" id="LoadingLimit">
                <option value="">-- select loading limit --</option>
                <option *ngFor="let limit of loadingLimits" [value]="limit.LoadingLimitId">{{limit.Title}}</option>
              </select>
            </div>
            <div class="form-group" id="scaffold-cladding">
              <select class="form-control" formControlName="methodOfCladdingId" name="MethodOfCladding"
                id="MethodOfCladding">
                <option value="">-- select cladding --</option>
                <option *ngFor="let cladding of methodOfCladdings" [value]="cladding.MethodOfCladdingId">
                  {{cladding.Title}}</option>
              </select>
            </div>
            <div class="form-group" id="scaffold-design">
              <select class="form-control" formControlName="designTypeId" name="Design" id="Design">
                <option value="">-- select design type --</option>
                <option *ngFor="let type of designTypes" [value]="type.DesignTypeId">{{type.Title}}</option>
              </select>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="modal-footer">
    <div class="alert alert-danger server-error" role="alert" *ngIf="serverError">
      {{ serverError }}
    </div>
    <span class="required-note">* Denotes a required field</span>
    <div class="modal-buttons">
      <button class="btn btn-large btn-inverse" type="button" (click)="onPrevious(form.value)"
        [disabled]="saveInProgress">
        <i class="fa fa-chevron-circle-left"></i> Back</button>
      <button class="btn btn-large button-primary" type="button" (click)="onSubmit(form.value)"
        [disabled]="saveInProgress">
        <i [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"
          class="fa"></i>
        Save</button>
    </div>
  </div>
</form>
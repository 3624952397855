
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerDetailQuery, CustomerEndpointService, CustomerDetailWebAppData, } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class CustomerDetailQueryService extends QueryService<CustomerDetailWebAppData> {

  constructor(private customerEndpointService: CustomerEndpointService) { super(); }

  customerDetailQuery(useCached: boolean, customerReference: string): Observable<CustomerDetailWebAppData> {
    return super.query(useCached, customerReference);
  }

  customerDetailDataChanges(customerReference: string): Observable<CustomerDetailWebAppData> {
    return super.cachedDataChanges(customerReference);
  }

  protected getDataFromServer(customerReference: string): Observable<CustomerDetailWebAppData> {
    return this.customerEndpointService.CustomerDetailQuery
      (Object.assign(new CustomerDetailQuery(), { CustomerReference: customerReference })).pipe(map(data => data[0]));
  }
}

import { SitesQueryService } from '../services/sites-query.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteEndpointService, SiteQuery, SiteWebAppData } from 'app/core/hub-api';

@Injectable()
export class SitesResolver implements Resolve<SiteWebAppData[]> {

  constructor(private sitesQueryService: SitesQueryService) { }

  resolve(): Observable<SiteWebAppData[]> {
    return this.sitesQueryService.sitesQuery(false);
  }
}

<div class="row main-header">
  <div class="navbar-header">
    <span *ngIf="authenticated"><a [routerLink]="['/home']" class="logo"></a></span>
    <span *ngIf="!authenticated "><a class="logo"></a></span>
    <span class="ss-logo-title"><span>SMART</span> Scaffolder</span>
  </div>
  <div *ngIf="authenticated">
    <ul class="nav navbar-nav nav-icons">
      <li id="home" routerLinkActive="active" tooltip="Home" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/home']" tabindex="-1"><i class="fa fa-house fa-2x"></i></a>
      </li>
      <li id="activity" routerLinkActive="active" tooltip="Activity" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/activity']" tabindex="-1"><i class="fa fa-rss fa-2x"></i></a>
      </li>
      <li id="sites" routerLinkActive="active" tooltip="Sites" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/sites']" tabindex="-1"><i class="fa fa-building fa-2x"></i></a>
      </li>
      <li id="estimates" routerLinkActive="active" tooltip="Estimates" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/estimates']" tabindex="-1"><i
            class="fa fa-file-text fa-2x"></i></a>
      </li>
      <li id="customers" routerLinkActive="active" tooltip="Customers" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/customers']" tabindex="-1"><i class="fa fa-users fa-2x"></i></a>
      </li>
      <li id="reports" routerLinkActive="active" tooltip="Reports" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/reports']" tabindex="-1"><i class="fa fa-line-chart fa-2x"></i></a>
      </li>
      <ng-container *ssFeatureFlag="'timesheet'">
        <li id="timesheet" routerLinkActive="active" tooltip="Timesheet" placement="bottom">
          <a class="nav-icons-anchor" [routerLink]="['/timesheet']" tabindex="-1"><i
              class="fa-regular fa-clock fa-2x"></i></a>
        </li>
      </ng-container>
      <li id="staff" routerLinkActive="active" tooltip="Staff" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/staff']" tabindex="-1"><i class="fa fa-user fa-2x"></i></a>
      </li>
      <li id="helpcentre" routerLinkActive="active" tooltip="Help Centre" placement="bottom">
        <a class="nav-icons-anchor" [routerLink]="['/helpcentre']" tabindex="-1"><i
            class="fa fa-info-circle fa-2x"></i></a>
      </li>
      <li dropdown id="settings" routerLinkActive="active">
        <!-- Hidden link used just to make routerLinkActive work as expected. -->
        <a class="hide" [routerLink]="['/admin']" tabindex="-1"></a>
        <a class="nav-icons-anchor" tabindex="-1" dropdownToggle id="dropdownMenu1"><i class="fa fa-cog fa-2x"></i></a>
        <ul class="dropdown-menu" *dropdownMenu>
          <li><a [routerLink]="['/admin/company-setup']">Company Setup</a></li>
          <li><a [routerLink]="['/admin/list-settings']">List Settings</a></li>
          <li><a [routerLink]="['/admin/handover-settings']">Handover Settings</a></li>
          <li><a [routerLink]="['/admin/inspection-settings']">Inspection Settings</a></li>
        </ul>
      </li>
      <li dropdown *ngIf="authenticated" class="dropdown user-menu" id="user-profile">
        <a dropdownToggle id="user-menu" class="dropdown-toggle">
          <span>{{displayName}}</span>
          <span class="caret"></span>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li>{{displayName}}</li>
          <li>{{username}}</li>
          <li role="separator" class="divider"></li>
          <li>
            <div class="profile-buttons">
              <div class="text-left profile-button-single">
                <a (click)="logout()"><i class="fa fa-sign-out"></i> Sign Out</a>
              </div>
              <div class="text-right profile-button-single">
                <a (click)="onEditProfile()"><i class="fa fa-pencil"></i> Update Profile</a>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
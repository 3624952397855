import { icons } from '../../values/icons';
import { inspectionOutcomeStatusIds } from '../../values/inspection-outcome-status-ids';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { scaffoldStatusIds } from 'app/shared/values/scaffold-status-ids';
import { ActivityStatusEnum, DiaryDataTypeEnum } from 'app/core/hub-api';

@Component({
  selector: 'hub-inspection-result',
  templateUrl: './inspection-result.component.html',
  styleUrls: ['./inspection-result.component.scss'],
})
export class InspectionResultComponent implements OnInit, OnChanges {
  @Input()
  status: number | string;
  @Input()
  handover?: boolean;
  @Input()
  hideTitle: boolean;
  @Input()
  hideIcon?: boolean;

  icon: string;
  title: string;

  ngOnInit(): void {
    this.refreshComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['handover'] || changes['status']) {
      this.refreshComponent();
    }
  }

  refreshComponent(): void {
    if (this.handover) {
      this.icon = icons.handoverInspection;
      this.title = 'Handover inspection';
      return;
    }
    switch (this.status) {
      case DiaryDataTypeEnum.SiteDiary:
        this.icon = icons.siteDiary;
        this.title = 'Site Diary';
        break;
      case ActivityStatusEnum.Incomplete:
        this.icon = icons.inCompleteStatus;
        this.title = 'Incomplete';
        break;
      case inspectionOutcomeStatusIds.noDefects:
      case ActivityStatusEnum.InspectionNoDefects:
        this.icon = icons.noDefects;
        this.title = 'No defects';
        break;
      case inspectionOutcomeStatusIds.defectsFixed:
      case ActivityStatusEnum.InspectionDefectsFixed:
        this.icon = icons.defectsFixed;
        this.title = 'Defects fixed';
        break;
      case inspectionOutcomeStatusIds.defectsNotFixed:
      case ActivityStatusEnum.InspectionDefectsNotFixed:
        this.icon = icons.defectsNotFixed;
        this.title = 'Minor defects remain';
        break;
      case inspectionOutcomeStatusIds.unsafeScaffold:
      case ActivityStatusEnum.InspectionUnSafeScaffold:
        this.icon = icons.unsafeScaffold;
        this.title = 'Unsafe';
        break;
      case scaffoldStatusIds.incomplete:
      case ActivityStatusEnum.Incomplete:
        this.icon = icons.inCompleteStatus;
        this.title = 'Incomplete';
        break;
      case scaffoldStatusIds.onHire:
      case ActivityStatusEnum.OnHire:
        this.icon = icons.onHireStatus;
        this.title = 'On-hire';
        break;
      case scaffoldStatusIds.offHire:
      case ActivityStatusEnum.OffHire:
        this.icon = icons.offHireStatus;
        this.title = 'Off-hire';
        break;
      case scaffoldStatusIds.dismantled:
      case ActivityStatusEnum.Dismantled:
        this.icon = icons.dismantle;
        this.title = 'Dismantled';
        break;
      case undefined:
        this.icon = icons.neverInspected;
        this.title = 'Never inspected';
        break;
      default:
        this.icon = icons.unknown;
        this.title = 'Unknown';
    }
  }
}

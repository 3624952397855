<div>
  <p>A contract is the relationship between a site and a customer. When you add a customer to a site you are creating a contract.
    A customer can have one or more contracts on any given site.</p>
  <h4>Main Contractor vs Sub Contractor</h4>
  <p>The only difference is that a main contractor can receive the sub contractors inspection reports and unsafe scaffold reports.
    You can only have one main contractor on a site.</p>
  <h4>Customer Signatures</h4>
  <p>Some documents on the mobile apps require a customer signature before they can be submitted. If this is not possible then
    you can allow documents to be submitted without a customer signature. This will allow the users of the app to select
    "customer not present" in the customer contact pickers.</p>
  <h4>Weekly Inspections</h4>
  <p>Inspections can be paused for any given contract and the app will not prompt you to inspect scaffolds whilst they are paused.
    If you are no longer contracted to carry out inspections for a particular customer then you can press the stop button.
    Once inspections have been paused or stopped then all inspection reports will reflect this. Pause/Stopped inspections
    can be resumed at anytime by clicking the "Play" button.</p>
  <h4>Closing Contracts</h4>
  <p>Contracts can be closed at any time by clicking the "Advanced Options" link and tapping the "Close Contract button". You
    can re-open the contract at anytime and any previous information/scaffolds will still be available.</p>
</div>

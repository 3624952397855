
import {map} from 'rxjs/operators';
import { ItemDetailsData, ContractEndpointService } from 'app/core/hub-api';
import { Injectable } from '@angular/core';
import { QueryService } from './query.service';
import { Observable } from 'rxjs';

@Injectable()
export class ItemDetailsQueryService extends QueryService<ItemDetailsData> {

  constructor(private contractEndpointService: ContractEndpointService) {
    super();
  }

  itemDetailsQuery(useCached: boolean, itemId): Observable<ItemDetailsData> {
    return super.query(useCached, itemId);
  }

  itemDetailDataChanges(itemId: string): Observable<ItemDetailsData> {
    return super.cachedDataChanges(itemId);
  }

  protected getDataFromServer(itemId: string): Observable<ItemDetailsData> {
    return this.contractEndpointService.ItemDetailsQuery({ ItemId: itemId }).pipe(map(response => response[0]));
  }
}

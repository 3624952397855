
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HandoverEndpointService, HandoverStatisticsData, HandoverStatisticsQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class HandoverStatisticsQueryService extends QueryService<HandoverStatisticsData> {

  constructor(private handoverEndpointService: HandoverEndpointService) { super(); }

  handoverStatisticsQuery(useCached: boolean, query: HandoverStatisticsQuery): Observable<HandoverStatisticsData> {
    return super.query(useCached, JSON.stringify(query));
  }

  handoverStatisticsDataChanges(query: HandoverStatisticsQuery): Observable<HandoverStatisticsData> {
    return super.cachedDataChanges(JSON.stringify(query));
  }

  protected getDataFromServer(queryJson: string): Observable<HandoverStatisticsData> {
    const query = JSON.parse(queryJson);
    return this.handoverEndpointService.HandoverStatisticsQuery(query).pipe(map(data => data[0]));
  }
}

<div class="ss-container" *ngIf="scaffold.LastInspection">
  <div class="row">
    <div class="col-md-12">
      <div class="last-inspection">
        <h3>Last Inspection
          <span class="last-inspection-right">
            <span>
              <i class="fa fa-user"></i>
              <span>{{ scaffold.LastInspection.Inspector }}</span>
            </span>
            <span>
              <i class="fa fa-calendar"></i> {{ scaffold.LastInspection.DateOfInspection | datetime: 'lll' }}
            </span>
          </span>
        </h3>
        <div class="row">
          <div [ngClass]="{'col-md-5': scaffold.LastInspection.InspectionDefects.length > 0, 'col-md-12': scaffold.LastInspection.InspectionDefects.length === 0}">

            <div class="gray-wrapper">

              <span *ngIf="inspectionTypeTitle" class="site-info-directives">
                <img class="inspection-type-img" [src]="inspectionTypeIcon">
                <span class="inspection-type-text">{{ inspectionTypeTitle }}</span>
              </span>

              <hub-inspection-result [status]="scaffold.RecentInspectionStatus" [handover]="scaffold.MostRecentInspectionIsHandover"></hub-inspection-result>

              <span *ngIf="passedOrFailedTitle" class="site-info-directives">
                <img [src]="passedOrFailedImageUrl">
                <span>{{ passedOrFailedTitle }}</span>
              </span>

            </div>

            <blockquote *ngIf="scaffold.LastInspection.Comments">
              <p>
                <i class="fa fa-comment"></i>
                <span> {{scaffold.LastInspection.Comments}}</span>
              </p>
            </blockquote>

          </div>

          <div class="col-md-7 right-panel" *ngIf="scaffold.LastInspection.InspectionDefects.length > 0">
            <h4>Defects</h4>
            <ul>
              <li *ngFor="let defect of scaffold.LastInspection.InspectionDefects">
                <div>
                  <hub-image-carousel [media]="defect.Media" height="100%"></hub-image-carousel>
                </div>
                <div>
                  <p>
                    <hub-defect-result [status]="defect.DefectStatus" [showOnlyIcon]="true"></hub-defect-result> {{ defect.DefectName }}
                    <span *ngIf="defect.DefectTypeFurtherInformation">({{ defect.DefectTypeFurtherInformation }})</span>
                  </p>
                  <span *ngIf="defect.Comments">
                    <strong>Comments: </strong>{{ defect.Comments }}
                  </span>
                  <span>
                    <strong>Status: </strong>
                    <hub-defect-result [status]="defect.DefectStatus" [showOnlyTitle]="true"></hub-defect-result>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

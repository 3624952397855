import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-signatures-required',
  templateUrl: './signatures-required.component.html',
  styleUrls: ['./signatures-required.component.scss']
})
export class SignaturesRequiredComponent implements OnInit {

  tooltipMessage: string;
  icon: string;
  status: string;
  private _canSubmitDocumentsWithoutSignature: boolean;

  @Input()
  set canSubmitDocumentsWithoutSignature(canSubmitDocumentsWithoutSignature: boolean) {
    this._canSubmitDocumentsWithoutSignature = canSubmitDocumentsWithoutSignature;
    this.submitDocumentsWithoutSignature();
  }
  constructor() { }

  ngOnInit(): void {
    this.submitDocumentsWithoutSignature();
  }

  submitDocumentsWithoutSignature(): void {
    if (this._canSubmitDocumentsWithoutSignature) {
      this.status = 'Signatures not required';
      this.icon = 'fa-times text-assertive';
      this.tooltipMessage = 'Site documents can be submitted without the customer signature by selecting the "customer not present" option from the app.';
    } else {
      this.status = 'Signatures required';
      this.icon = 'fa-pencil';
      this.tooltipMessage = 'Customer signatures will be required on some site documents before they can be submitted from the app.';
    }
  }

}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=stage` then `environment.stage.ts` will be used instead.
// The list of which environment maps to which file can be found in `.angular.json`.
export const environment = {
  production: true, // This is a setting currently required by angular. If true it disables angular's development mode. Set this to true for all environments since we are testing them as if they are production
  apiServiceBaseUri: 'https://beta-console.smartscaffolder.services/api/v1/',
  appUrl: '', // TODO (Patrick Lee 8 Sep 2023) probably no longer needed after removing unecessary logic in the front end code?
  multiTenantUrl: 'https://beta-console.smartscaffolder.services/api/v1/',  // Not clear why this is needed as well as apiServiceBaseUri
  multiTenantAppName: 'sitemanager',
  clientVersion: require('../../package.json').version + '.' + require('../../package.json').build + '-BETA',
  featureFlags: {},
  insightsKey: '',
  froalaKey: 'XAG4eF3J3A7A6C5E5C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3A3E2B2B6D6D4C2==',
  youtubeApi: 'AIzaSyAcUDNX7CWAejROxfNb2whTntHP3oGhWXY',
  googleMapsApiKey: 'AIzaSyBljGrraQCqtCV2-eRcA0U527WGb0-FWs8', // NB note this key is different for Production (and Beta Production)
  userflowKey: 'ct_4uusmlu4snf5bikvynw5gcu2oi',
  adalB2C: {
    tenantName: 'smartscaffolderenterprise.onmicrosoft.com',
    // redirectUri: 'http://localhost:9001/', // Uncomment this if you want SMART Manager front end running in debug
    redirectUri: 'https://beta-console.smartscaffolder.services',
    clientId: 'e3bf6d19-3448-46a0-a3d3-71b605be01a0', // the app Id for "SMART Manager beta" in Production.
    policy: 'B2C_1_SmartAppsAug2023v1SignIn',
    appResourceURI: 'openid https://smartscaffolderenterprise.onmicrosoft.com/b2csitemanagermobilewebapi/user_impersonation',
    adb2cBaseOAuthUri: 'https://smartscaffolderenterprise.b2clogin.com/',
  },
  appInsights: {
    instrumentationKey: '06500e66-0e96-433b-97b2-7bb648116107',
    ingestionEndpoint: 'https://uksouth-1.in.applicationinsights.azure.com/',
    liveEndpoint: 'https://uksouth.livediagnostics.monitor.azure.com/',
  }
};


<div class="nextweekinspections-title margin-top text-center">
    <h2>{{title}}</h2>
    <p>{{subtitle}}</p>
    <p class="text-positive text-large">{{totalNextWeek}}</p>
</div>
<ul class="next-week-inspections margin-top">
    <li *ngFor="let day of daysOfWeek">
        <a class="block" (click)="getCountBySite(day.day)" [ngClass]="{'active': day.day === selectedDay }">
            <p class="text-bold text-primary-gray">{{day.day}}</p>
            <p>{{day.count}}</p>
        </a>
    </li>
</ul>

<div class="overdueList list-group margin-top" *ngIf="inspectionsCountBySite && !hideList">
    <ul>
        <li class="list-group-item" *ngFor="let key of objectKeys(inspectionsCountBySite)">
            <div class="flex-list">
                <div class="flex-text-truncated">{{ key }}</div>
                <div class="badge white-bg">
                    <div class="badge badge-default label-size-fix">{{ inspectionsCountBySite[key] }}</div>
                </div>
            </div>
        </li>
    </ul>
</div>

<div class="text-center nextweek-clickondate" *ngIf="!inspectionsCountBySite && !hideList">
    <i class="fa fa-check fa-3x text-positive" aria-hidden="true"></i>
    <p>No inspections due</p>
</div>

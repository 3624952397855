import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-dismantle-required',
  templateUrl: './dismantle-required.component.html',
  styleUrls: ['./dismantle-required.component.scss']
})
export class DismantleRequiredComponent implements OnInit {

  _isAllowDismantleWithoutOffhire: boolean;
  @Input()
  set isAllowDismantleWithoutOffhire(isAllowDismantleWithoutOffhire: boolean) {
    this._isAllowDismantleWithoutOffhire = isAllowDismantleWithoutOffhire;
  }

  constructor() { }

  ngOnInit(): void {
  }


}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import '../../rxjs-imports';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng4-click-outside';
import { CarouselConfig, CarouselModule } from 'ngx-bootstrap/carousel';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DiaryTypeComponent } from './components/diary-type/diary-type.component';
import { HandoverTypeComponent } from './components/handover-type/handover-type.component';
import {
  ScaffoldInspectionStateComponent,
} from './components/scaffold-inspection-state/scaffold-inspection-state.component';
import {
  AddEditContractContactModalComponent,
} from './components/site-detail/contracts/add-edit-contract-contact-modal/add-edit-contract-contact-modal.component';
import {
  AddEditContractModalComponent,
} from './components/site-detail/contracts/add-edit-contract-modal/add-edit-contract-modal.component';
import {
  InspectionStatusModalComponent,
} from './components/site-detail/contracts/inspection-status-modal/inspection-status-modal.component';
import {
  OpenCloseContractModalComponent,
} from './components/site-detail/contracts/open-close-contract-modal/open-close-contract-modal.component';
import {
  SiteContractContactsComponent,
} from './components/site-detail/contracts/site-contract-contacts/site-contract-contacts.component';
import { SiteContractComponent } from './components/site-detail/contracts/site-contract/site-contract.component';
import {
  SiteContractsWelcomeComponent,
} from './components/site-detail/contracts/site-contracts-welcome/site-contracts-welcome.component';
import { SiteContractsComponent } from './components/site-detail/contracts/site-contracts/site-contracts.component';
import {
  SiteDashboardContractComponent,
} from './components/site-detail/dashboard/site-dashboard-contract/site-dashboard-contract.component';
import { SiteDashboardComponent } from './components/site-detail/dashboard/site-dashboard/site-dashboard.component';
import {
  AddEditAreaModalComponent,
} from './components/site-detail/detail/add-edit-area-modal/add-edit-area-modal.component';
import {
  AddEditSiteStaffModalComponent,
} from './components/site-detail/detail/add-edit-site-staff-modal/add-edit-site-staff-modal.component';
import { SiteDetailAreasComponent } from './components/site-detail/detail/site-detail-areas/site-detail-areas.component';
import {
  SiteDetailOverviewComponent,
} from './components/site-detail/detail/site-detail-overview/site-detail-overview.component';
import { SiteDetailStaffComponent } from './components/site-detail/detail/site-detail-staff/site-detail-staff.component';
import { SiteDetailComponent } from './components/site-detail/detail/site-detail/site-detail.component';
import {
  DiaryMediaViewerPlaceholderComponent,
} from './components/site-detail/diary/diary-media-viewer-placeholder/diary-media-viewer-placeholder.component';
import { DiaryMediaViewerComponent } from './components/site-detail/diary/diary-media-viewer/diary-media-viewer.component';
import {
  RenameScaffoldMediaModalComponent,
} from './components/site-detail/diary/rename-diary-media-modal/rename-diary-media-modal.component';
import {
  ScaffoldDiaryCategoriesComponent,
} from './components/site-detail/diary/diary-categories/diary-categories.component';
import {
  DiaryDetailComponent,
} from './components/site-detail/diary/diary-detail/diary-detail.component';
import {
  DiaryListComponent,
} from './components/site-detail/diary/diary-list/diary-list.component';
import { HandoversListComponent } from './components/site-detail/handovers/handovers-list/handovers-list.component';
import {
  AddEditScaffoldDetailComponent,
} from './components/site-detail/scaffolds/add-edit-scaffold-detail/add-edit-scaffold-detail.component';
import {
  AddEditScaffoldModalComponent,
} from './components/site-detail/scaffolds/add-edit-scaffold-modal/add-edit-scaffold-modal.component';
import {
  AddEditScaffoldSpecificationComponent,
} from './components/site-detail/scaffolds/add-edit-scaffold-specification/add-edit-scaffold-specification.component';
import {
  DismantleScaffoldModalComponent,
} from './components/site-detail/scaffolds/dismantle-scaffold-modal/dismantle-scaffold-modal.component';
import {
  FixScaffoldNumbersModalComponent,
} from './components/site-detail/scaffolds/fix-scaffold-numbers-modal/fix-scaffold-numbers-modal.component';
import {
  GroupScaffoldsModalComponent,
} from './components/site-detail/scaffolds/group-scaffolds-modal/group-scaffolds-modal.component';
import { ScaffoldDetailComponent } from './components/site-detail/scaffolds/scaffold-detail/scaffold-detail.component';
import { ScaffoldListComponent } from './components/site-detail/scaffolds/scaffold-list/scaffold-list.component';
import {
  SiteDetailContainerComponent,
} from './components/site-detail/site-detail-container/site-detail-container.component';
import { SiteImportContactsComponent } from './components/site-import/site-import-contacts/site-import-contacts.component';
import { SiteImportContractComponent } from './components/site-import/site-import-contract/site-import-contract.component';
import { SiteImportCustomerComponent } from './components/site-import/site-import-customer/site-import-customer.component';
import { SiteImportModalComponent } from './components/site-import/site-import-modal/site-import-modal.component';
import {
  SiteImportScaffoldsComponent,
} from './components/site-import/site-import-scaffolds/site-import-scaffolds.component';
import { SiteImportSiteComponent } from './components/site-import/site-import-site/site-import-site.component';
import { AddEditSiteModalComponent } from './components/sites-list/add-edit-site-modal/add-edit-site-modal.component';
import { SitesListComponent } from './components/sites-list/sites-list/sites-list.component';
import { SitesSectionContainerComponent } from './components/sites-section-container/sites-section-container.component';
import { NoSiteContractsGuard } from './guards/no-site-contracts.guard';
import { DiaryDetailGuard } from './guards/diary-detail.guard';
import { ScaffoldDetailResolver } from './resolvers/scaffold-detail.resolver';
import { ScaffoldDiaryResolver } from './resolvers/scaffold-diary-resolver';
import { ScaffoldTimelineResolver } from './resolvers/scaffold-timeline.resolver';
import { SiteResolver } from './resolvers/site.resolver';
import { ContractInspectionStatusQueryService } from './services/contract-inspection-status-query.service';
import { ScaffoldDetailQueryService } from './services/scaffold-detail-query-service';
import { DiaryQueryService } from './services/diary-query-service';
import { ScaffoldTimelineQueryService } from './services/scaffold-timeline-query-service';
import { ScheduledReportsStartGeneratorService } from './services/scheduled-reports-start-generator.service';
import { SiteDetailQueryService } from './services/site-detail-query.service';
import { SiteScaffoldQueryService } from './services/site-scaffolds-query.service';
import { SitesRoutingModule } from './sites-routing.module';
import { SiteStatusComponent } from './components/site-status/site-status.component';
import { SiteCameraComponent } from './components/site-camera/site-camera.component';
import { ContractsInfoTextComponent } from './components/site-detail/contracts/contracts-info-text/contracts-info-text.component';
import { PrincipalContractComponent } from './components/principal-contract/principal-contract.component';
import { SignaturesRequiredComponent } from './components/signatures-required/signatures-required.component';
import { AgreedRatesComponent } from './components/agreed-rates/agreed-rates.component';
import { UndismantleScaffoldModalComponent } from './components/site-detail/scaffolds/undismantle-scaffold-modal/undismantle-scaffold-modal.component';
import { LastInspectionComponent } from './components/site-detail/scaffolds/last-inspection/last-inspection.component';
import { OffHireScaffoldModalComponent } from './components/site-detail/scaffolds/off-hire-scaffold-modal/off-hire-scaffold-modal.component';
import { DefectResultComponent } from './components/site-detail/scaffolds/defect-result/defect-result.component';
import { DismantleCardComponent } from './components/site-detail/scaffolds/dismantle-card/dismantle-card.component';
import { AddScaffoldDiaryMediaModalComponent } from './components/site-detail/diary/add-diary-media-modal/add-diary-media-modal.component';
import { DeleteScaffoldDiaryMediaModalComponent } from './components/site-detail/diary/delete-diary-media-modal/delete-diary-media-modal.component';
import { HandoverTypeService } from './services/handover-type-service';
import { DismantleRequiredComponent } from './components/dismantle-required/dismantle-required.component';
import { QuotesQueryService } from '../core/services/quotes-query-service';
import { ScaffoldImportModalComponent } from './components/site-detail/scaffolds/scaffold-import-modal/scaffold-import-modal.component';
import { ImportSpreadsheetComponent } from './components/site-detail/scaffolds/scaffold-import-modal/import-spreadsheet/import-spreadsheet';
import { SelectContractComponent } from './components/site-detail/scaffolds/scaffold-import-modal/select-contract/select-contract';
import { SiteStockComponent } from './components/site-stock/site-stock.component';
import { AddEditCompanyContactModalComponent } from './components/site-detail/contracts/add-edit-company-contact-modal/add-edit-company-contact-modal.component';
import { SiteCompanyContactsComponent } from './components/site-detail/contracts/site-company-contacts/site-company-contacts.component';
import { AddEditBccContactModalComponent } from './components/site-detail/contracts/add-edit-bcc-contact-modal/add-edit-bcc-contact-modal.component';
import { AddMeAsContactModalComponent } from './components/site-detail/contracts/add-me-as-contact-modal/add-me-as-contact-modal.component';
import { SiteScaffoldDiaryResolver } from './resolvers/site-scaffold-diary.resolver';
import { AddSiteDiaryModalComponent } from './components/site-detail/diary/add-site-diary-modal/add-site-diary-modal.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

/**
 * Controls all modules, imports, providers and declarations for the sites route
 *
 * @export
 * @class SitesModule
 */
@NgModule({
  imports: [
    SharedModule,
    SitesRoutingModule,
    NgxDatatableModule,
    CarouselModule,
    ClickOutsideModule,
    FormsModule,
    FroalaEditorModule,
    FroalaViewModule,
  ],
  declarations: [
    SitesSectionContainerComponent,
    SitesListComponent,
    AddEditSiteModalComponent,
    SiteDetailContainerComponent,
    SiteDashboardComponent,
    SiteDashboardContractComponent,
    SiteDetailComponent,
    SiteDetailStaffComponent,
    SiteDetailOverviewComponent,
    SiteDetailAreasComponent,
    AddEditAreaModalComponent,
    AddEditSiteStaffModalComponent,
    SiteContractsComponent,
    SiteContractComponent,
    SiteContractsWelcomeComponent,
    AddEditContractModalComponent,
    SiteContractContactsComponent,
    AddEditContractContactModalComponent,
    InspectionStatusModalComponent,
    OpenCloseContractModalComponent,
    ScaffoldListComponent,
    AddEditScaffoldModalComponent,
    AddEditScaffoldDetailComponent,
    AddEditScaffoldSpecificationComponent,
    FixScaffoldNumbersModalComponent,
    GroupScaffoldsModalComponent,
    ScaffoldDetailComponent,
    ScaffoldInspectionStateComponent,
    DiaryTypeComponent,
    DismantleScaffoldModalComponent,
    HandoversListComponent,
    HandoverTypeComponent,
    SiteImportModalComponent,
    SiteImportSiteComponent,
    SiteImportContractComponent,
    SiteImportContactsComponent,
    SiteImportScaffoldsComponent,
    SelectContractComponent,
    SiteImportCustomerComponent,
    SiteStatusComponent,
    SiteCameraComponent,
    ContractsInfoTextComponent,
    PrincipalContractComponent,
    SignaturesRequiredComponent,
    AgreedRatesComponent,
    UndismantleScaffoldModalComponent,
    LastInspectionComponent,
    DefectResultComponent,
    OffHireScaffoldModalComponent,
    DismantleCardComponent,
    OffHireScaffoldModalComponent,
    DiaryListComponent,
    DiaryDetailComponent,
    DiaryMediaViewerComponent,
    ScaffoldDiaryCategoriesComponent,
    DiaryMediaViewerPlaceholderComponent,
    RenameScaffoldMediaModalComponent,
    AddScaffoldDiaryMediaModalComponent,
    DeleteScaffoldDiaryMediaModalComponent,
    DismantleRequiredComponent,
    ScaffoldImportModalComponent,
    ImportSpreadsheetComponent,
    SiteStockComponent,
    AddEditCompanyContactModalComponent,
    SiteCompanyContactsComponent,
    AddEditBccContactModalComponent,
    AddMeAsContactModalComponent,
    AddSiteDiaryModalComponent,
  ],
  providers: [
    // GUARDS
    NoSiteContractsGuard,
    DiaryDetailGuard,

    // RESOLVERS
    ScaffoldDetailResolver,
    ScaffoldTimelineResolver,
    ScaffoldDiaryResolver,
    SiteScaffoldDiaryResolver,
    SiteResolver,

    // SERVICES
    { provide: CarouselConfig, useValue: { interval: 0 } },
    SiteDetailQueryService,
    ScheduledReportsStartGeneratorService,
    ContractInspectionStatusQueryService,
    SiteScaffoldQueryService,
    ScaffoldDetailQueryService,
    ScaffoldTimelineQueryService,
    DiaryQueryService,
    HandoverTypeService,
    QuotesQueryService
  ]
})
export class SitesModule { }

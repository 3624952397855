import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import {
  HandoverStatisticsData, ScaffoldStatisticsData,
  SiteDashBoardScaffoldInspectionData, SiteWebAppData, ContractData, ScaffoldStatisticsQuery, HandoverStatisticsQuery
} from 'app/core/hub-api';

import { HandoverStatisticsQueryService } from '../../../../../core/services/handover-statistics-query-service';
import { ScaffoldInspectionQueryService } from '../../../../../core/services/scaffold-inspection-query-service';
import { ScaffoldStatisticsQueryService } from '../../../../../core/services/scaffold-statistics-query-service';
import { dates } from '../../../../../shared/values/dates';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { mergeMap } from 'rxjs/operators';
import { CustomersQueryService } from 'app/core/services/customers-query.service';
@AutoUnsubscribe()
@Component({
  selector: 'hub-site-dashboard-contract',
  templateUrl: './site-dashboard-contract.component.html',
  styleUrls: ['./site-dashboard-contract.component.scss']
})
export class SiteDashboardContractComponent implements OnInit, OnDestroy {
  contractId: string;
  site: SiteWebAppData;
  inspectionData: SiteDashBoardScaffoldInspectionData[];
  scaffoldStatisticsData: ScaffoldStatisticsData;
  handoverStatisticsData: HandoverStatisticsData;

  readonly sixDaysAgo = dates.sixDaysAgo;
  readonly elevenMonthsAgo = dates.elevenMonthsAgo;
  readonly today = dates.today;
  readonly tomorrow = dates.tomorrow;

  private scaffoldStatisticsSubscription: Subscription;
  private handoverStatisticsSubscription: Subscription;

  contracts: ContractData[];
  customers: any;
  selectedCustomer: any;

  constructor(
    private route: ActivatedRoute,
    private scaffoldInspectionQueryService: ScaffoldInspectionQueryService,
    private scaffoldStatisticsQueryService: ScaffoldStatisticsQueryService,
    private handoverStatisticsQueryService: HandoverStatisticsQueryService,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,

  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.parent.snapshot.params['siteReference'];

    const siteDetailChanges = this.siteDetailQueryService.siteDetailQuery(false, siteReference);
    const siteDetailChanges$ = siteDetailChanges.pipe(
      mergeMap(site => this.scaffoldInspectionQueryService.scaffoldInspectionDataChanges(site.SiteId))
    );
    siteDetailChanges$.subscribe(inspectionData => (this.inspectionData = inspectionData));

    siteDetailChanges.subscribe(site => (this.site = site));
    const obs$ = combineLatest([
      siteDetailChanges,
      this.route.params,
      this.customersQueryService.customersDataChanges()
    ]);
    obs$.subscribe(latest => {
      const site = latest[0];
      const params = latest[1];
      this.customers = latest[2];

      const customerReference = params['customerReference'];

      this.selectedCustomer = this.customers.find(c => c.CustomerReference === customerReference);
      //  Query new statistics data when the contract id changes.
      const query: ScaffoldStatisticsQuery | HandoverStatisticsQuery = new HandoverStatisticsQuery();

      this.scaffoldStatisticsData = undefined;
      this.handoverStatisticsData = undefined;

      // Unsubscribe from previous query result in case the data hasn't arrived yet.
      if (this.scaffoldStatisticsSubscription) {
        this.scaffoldStatisticsSubscription.unsubscribe();
      }
      if (this.handoverStatisticsSubscription) {
        this.handoverStatisticsSubscription.unsubscribe();
      }

      query.DepotId = this.site.SiteDepotId;
      query.SiteId = this.site.SiteId;
      query.AdminAssignedSitesOnly = false;

      if (this.selectedCustomer && this.selectedCustomer.CustomerId) {
        query.CustomerId = this.selectedCustomer.CustomerId;
      }

      this.scaffoldStatisticsSubscription = this.scaffoldStatisticsQueryService
        .scaffoldStatisticsQuery(true, query)
        .subscribe(scaffoldStatisticsData => (this.scaffoldStatisticsData = scaffoldStatisticsData[0]));
      this.handoverStatisticsSubscription = this.handoverStatisticsQueryService
        .handoverStatisticsQuery(true, query)
        .subscribe(handoverStatisticsData => (this.handoverStatisticsData = handoverStatisticsData));
    });

  }


  ngOnDestroy(): void { }
}

import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { colors } from 'app/shared/values/colours';
import { TextToColorService } from 'app/core/services/text-to-color.service';

@Component({
  selector: 'hub-colour-coded-title',
  templateUrl: './colour-coded-title.component.html',
  styleUrls: ['./colour-coded-title.component.scss']
})
export class ColourCodedTitleComponent implements OnInit {

  _colourGeneration: string;

  @ViewChild('colouredCircle', { static: true })
  colouredCircle: ElementRef;

  // String used as an input for the hash function which outputs the colour..
  @Input()
  set colourGeneration(colourGeneration: string) {
    this._colourGeneration = colourGeneration;
    this.updateColour(colourGeneration);
  }
  get colourGeneration(): string {
    return this._colourGeneration;
  }

  @Input()
  text: string;

  constructor(private textToColourService: TextToColorService) { }

  ngOnInit(): void {}

  updateColour(colourGeneration: string): void {
    const color = this.textToColourService.textToColor(colourGeneration);
    this.colouredCircle.nativeElement.style.backgroundColor = color;
  }
}

<div id="page-content-wrapper" class="sites-list">
  <div class="ss-container fill-container">
    <h2 class="inline-block"><i class="fa fa-file-text"></i> Estimates</h2>
    <div class="ss-toolbar pull-right">
      <a class="btn button-default button-rounded" role="button" (click)="onExportToCSV()"><i
          class="fa-solid fa-file-excel"></i><span>export to csv</span></a>
    </div>
    <div class="row table-filters shadow-header">
      <div class="col-xs-6 col-md-5">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input class="form-control sites-search" type="text" placeholder="Search..." [(ngModel)]="searchInputValue" (keyup.enter)="applyFilter()">
        </div>
      </div>
      <div class="col-xs-6 col-md-3 text-left">
        <div class="table-filters">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa-solid fa-filter"></i></span>
            <ng-select [items]="filtersDropdownOptions" bindLabel="name" bindValue="prop" placeholder="Filter by status"
              [clearable]="false" [searchable]="true" [multiple]="false" [(ngModel)]="filtersDropdownValue">
            </ng-select>
          </div>
        </div>

      </div>

      <div class="filter-buttons col-xs-12 col-md-3">
        <div class="table-filters">
          <button class="btn button-default" (click)="applyFilter()"><i class="fa fa-check"></i>
            Apply Filters</button>
          <button class="btn button-default" (click)="clearFilter()"><i class="fa fa-times"></i>
            Reset Filters</button>
        </div>
      </div>
    </div>

    <div class="row margin-top">
      <div class="col-md-12">
        <hub-estimates-table [rows]="rows" [columns]="columns" [allStatuses]="allStatuses" [page]="pagination"
          (pageChange)="setPage($event)" (start)="onStart($event)" (completed)="onCompleted($event)"
          [isLoading]="isLoading" (inProgress)="onInProgress($event)" (footerPageChanged)="pageChanged($event)" 
          (statusChanged)="statusChanged($event)" (sort)="onSort($event)" [sorts]="sorts">
        </hub-estimates-table>
      </div>
    </div>
  </div>
</div>
import { Directive, OnInit, HostListener, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

// Download the specified blob file when the element is clicked.
@Directive({
  selector: '[hubDownloadClick]'
})
export class DownloadClickDirective implements OnInit {

  @Input()
  path: string;

  @Input()
  fileName: string;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void { }

  @HostListener('click', ['$event'])
  clickEvent(event): void {
    this.httpClient.get(this.path, {
      responseType: 'blob'
    }).subscribe(mediaBlob => {
      const ext = this.path.split('?')[0].split('.').pop();
      const filename = `${this.fileName}.${ext}`;
      saveAs(mediaBlob, filename);
    });
  }
}

<div *ngIf="site">
  <div class="row margin-bottom">
    <div class="col-md-12">
      <h2>Site Details</h2>
    </div>
  </div>
  <div class="ss-container">
    <div class="site-details">
      <div class="row">
        <div class="col-lg-7 col-md-4">
          <div class="google-map-wrapper">
            <google-map width="100%" [zoom]=12 [center]="{ lat: site.Latitude, lng: site.Longitude }" [options]="{ scrollwheel: false }">
              <map-marker [position]="{ lat: site.Latitude, lng: site.Longitude }" [options]="{ draggable: false }"></map-marker>
            </google-map>
          </div>
        </div>
        <div class="col-lg-5 col-md-8">
          <div class="row">
            <div class="col-xs-10">
              <div class="header-content">
                <h3><a>{{site.SiteName}} ({{site.SiteReference}})</a>
                </h3>
                <p>{{site.SiteAddress}}</p>
              </div>
              <div class="table-display">
                <div class="table-row">
                  <div class="table-cell text-bold">Status:</div>
                  <div class="gray-wrapper">
                    <hub-site-status [statusId]="site.SiteStatusId"></hub-site-status>
                    <hub-site-camera [cameraAllowed]="site.IsCameraAllowed"></hub-site-camera>
                    <hub-site-stock [isVisible]="isStatusVisible" [stockAllowed]="isStockControlAllowed"></hub-site-stock>
                  </div>
                </div>
                <div class="table-row" *ngIf="depots.length > 1">
                  <div class="table-cell"><strong>Depot:</strong></div>
                  <div class="table-cell text-left">{{siteDepotName}}</div>
                </div>
              </div>
            </div>
            <div class="col-xs-2">
              <div class="ss-toolbar pull-right">
                <hub-permission permissions="AddEditSites">
                  <a class="btn button-default button-rounded" (click)="onEditSite()"><i class="fa fa-pencil icon"></i><span>edit site</span></a>
                </hub-permission>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="site-setup-progress" *ngIf="!siteSetupComplete">
                <h4>Site Setup {{siteSetupPercentage}}% Complete</h4>
                <div class="progress">
                  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width]="progressWidth"></div>
                </div>
                <div class="site-setup-progress-steps">
                  <h5>Steps</h5>
                  <ul class="fa-ul">
                    <li><i class="fa-li fa" [ngClass]="totalContracts > 0 ? 'fa-check text-positive': 'fa-times text-assertive'"></i>Add
                      a customer</li>
                    <li><i class="fa-li fa" [ngClass]="totalContractContacts > 0 ? 'fa-check text-positive': 'fa-times text-assertive'"></i>Add
                      a customer contact</li>
                    <li><i class="fa-li fa" [ngClass]="activeStaff > 0 ? 'fa-check text-positive': 'fa-times text-assertive'"></i>Add
                      a staff member</li>
                    <li><i class="fa-li fa" [ngClass]="site.HasScaffolds ? 'fa-check text-positive': 'fa-times text-assertive'"></i>Add
                      a scaffold</li>
                  </ul>
                </div>
                <div class="site-setup-progress-steps margin-top">
                  <h5>Next Step</h5>
                  <ul class="fa-ul">
                    <li *ngIf="totalContracts === 0; else addContractContact"><i class="fa-li fa fa-plus"></i><a class="link" (click)="onAddContract()">Add a customer (create a new contract)</a></li>
                    <ng-template #addContractContact>
                      <li *ngIf="totalContractContacts === 0; else addStaff"><i class="fa-li fa fa-plus"></i><a class="link" (click)="onAddContractContact()">Add a customer contact</a></li>
                    </ng-template>
                    <ng-template #addStaff>
                      <li *ngIf="activeStaff === 0; else addScaffolds"><i class="fa-li fa fa-plus"></i><a class="link" (click)="onAddStaff()">Add a staff member</a></li>
                    </ng-template>
                    <ng-template #addScaffolds>
                      <li *ngIf="!site.HasScaffolds"><i class="fa-li fa fa-plus"></i><a class="link" (click)="onAddScaffold()">Add a scaffold</a></li>
                    </ng-template>
                  </ul>
                </div>
              </div>
              <div class="site-stats" *ngIf="siteSetupComplete">
                <div class="stat">
                  <h3>{{openContracts}}</h3>
                  <p>Customer<span *ngIf="openContracts > 1">s</span></p>
                </div>
                <div class="stat">
                  <h3>{{activeStaff}}</h3>
                  <p>Staff</p>
                </div>
                <div class="stat last">
                  <h3>{{activeContractContacts}}</h3>
                  <p>Contact<span *ngIf="activeContractContacts > 1">s</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateSiteCommand,
  DepotData,
  EditSiteWebConsoleCommand,
  SiteCommandService,
  SiteStatusData,
  SiteWebAppData,
  StaffDetailsWebAppData,
  ScaffoldingSystemdata,
} from 'app/core/hub-api';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { cloneDeep, map } from 'lodash';
import { AddEditSiteModalContext } from '../../../models/add-edit-site-modal-context';
import { CoordinatesEvent } from '../../../../shared/models/coordinates-event';
import { Observable, forkJoin } from 'rxjs';
import { SiteDetailQueryService } from '../../../services/site-detail-query.service';
import { SitesQueryService } from '../../../../core/services/sites-query.service';
import { UUID } from 'angular2-uuid';
import { contractStatusIds } from '../../../../shared/values/contract-status-ids';
import { errorMessages } from '../../../../shared/values/error-messages';
import { mapValues } from '../../../../shared/values/maps';
import { siteStatusIds } from '../../../../shared/values/site-status-ids';
import { FilterService } from '../../../../core/services/filter-service';
import { CustomValidator } from 'app/shared/validators/custom-validators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormComponent } from 'app/shared/components/form/form.component';

/**
 * Add Edit Site Modal
 * Here the user can create a new site
 *
 * @export
 * @class AddEditSiteModalComponent
 * @extends {ModalFormComponent<AddEditSiteModalContext>} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 * @implements {OnDestroy} Stops all subscriptions and closes the component
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-site-modal',
  templateUrl: './add-edit-site-modal.component.html',
  styleUrls: ['./add-edit-site-modal.component.scss']
})
export class AddEditSiteModalComponent extends FormComponent implements OnInit, OnDestroy {
  /** Gets the parameters sent to this modal */
  context: Partial<AddEditSiteModalContext>;
  /** Cameras are allowed radio button control */
  camerasAllowedFormControl: UntypedFormControl;
  /** Stock allowed radio button control */
  stockAllowedFormControl: UntypedFormControl;
  /** Detects if it is adding or editing a site */
  editMode = false;
  /** Stores the selected site */
  site: SiteWebAppData;
  /** Stores all depots */
  depots: DepotData[];
  /** Stores all site statuses that come from configuration */
  siteStatuses: SiteStatusData[];
  /** Stores the current logged in user */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores all scaffolding systems that come from configuration */
  scaffoldingSystems: ScaffoldingSystemdata[];
  /** Stores the selected scaffolding system id */
  selectedScaffoldingSystemid: String;
  /** Stores the selected scaffolding system */
  selectedScaffoldingSystem: ScaffoldingSystemdata;
  /** Form group control */
  form: UntypedFormGroup;
  /** Site name input control */
  siteNameFormControl: UntypedFormControl;
  /** Site address input control */
  siteAddressFormControl: UntypedFormControl;
  /** Depots dropdown control */
  siteDepotIdFormControl: UntypedFormControl;
  /** Scaffolding system list if stock control is activated */
  scaffoldinSystemFormArray: UntypedFormArray = new UntypedFormArray([]);
  /** Tonnage for scaffolding system list if stock control is activated */
  tonnageFormGroup: UntypedFormGroup;
  /** Stores the current site status on form launch */
  siteWasOpen: boolean;
  /** Stores if the site is valid to be closed (e.g. if all contracts are closed) */
  closeSiteValid = true;
  /** Option to toggle the Scaffolding System list */
  toggleScaffoldingSystem = {};
  /** Stores the message for invalid tonnage  */
  invalidValueMessage = errorMessages.invalidValue;
  /** Form validation for all required fields */
  validationMessages = {
    siteName: {
      required: errorMessages.required
    },
    siteAddress: {
      required: errorMessages.required
    },
    siteDepotId: {
      required: errorMessages.required
    }
  };
  /** Stores the coordinates for the site location - to be used to show the google maps */
  map = {
    latitude: mapValues.defaultLatitude,
    longitude: mapValues.defaultLongitude,
    zoom: mapValues.defaultZoom
  };
  /** Stores any server errors thrown */
  serverError = '';
  /** Detects if the stock control option is enabled */
  isStockControlEnable: boolean;

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private siteCommandService: SiteCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    private sitesQueryService: SitesQueryService,
    private filterService: FilterService
  ) {
    super();
  }

  /**
   * Component Initialisation
   *
   * @memberof AddEditSiteModalComponent
   */
  public ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.siteStatuses = map(this.context.configuration.SiteStatuses);
    this.loggedInStaff = this.context.loggedInStaff;
    this.depots = this.filterService.filterDepots(this.context.depots, this.context.loggedInStaff);
    this.editMode = this.context.editMode;
    this.site = this.context.site ? cloneDeep(this.context.site) : this.getNewSite();
    this.isStockControlEnable = this.context.stockControl;
    this.siteWasOpen = this.site.SiteStatusId === siteStatusIds.open;
    this.scaffoldingSystems = map(this.context.configuration.ScaffoldingSystems);
    this.generateForm();
    this.renderTonnage();

    // set map coordinates from site
    if (this.editMode && this.site.Latitude && this.site.Longitude) {
      this.map.latitude = this.site.Latitude;
      this.map.longitude = this.site.Longitude;
    }

    // detects the changes on the stock control option
    this.stockAllowedFormControl.valueChanges.subscribe((selected: boolean) => {
      this.isStockControlEnable = selected;
    });

    super.ngOnInit();
  }

  /**
   * Generates the add site form and sets the validation and fields
   *
   * @private
   * @memberof AddEditSiteModalComponent
   */
  private generateForm(): void {
    this.siteNameFormControl = new UntypedFormControl(this.site.SiteName, [Validators.required]);
    this.siteAddressFormControl = new UntypedFormControl(this.site.SiteAddress, [Validators.required]);
    this.siteDepotIdFormControl = new UntypedFormControl(this.site.SiteDepotId, Validators.required);
    this.camerasAllowedFormControl = new UntypedFormControl(this.site.IsCameraAllowed);
    this.stockAllowedFormControl = new UntypedFormControl(this.site.IsManageStockControl);

    this.form = new UntypedFormGroup({
      siteName: this.siteNameFormControl,
      siteAddress: this.siteAddressFormControl,
      siteDepotId: this.siteDepotIdFormControl,
      camerasAllowed: this.camerasAllowedFormControl,
      stockAllowed: this.stockAllowedFormControl,
      scaffoldinSystemFormArray: this.scaffoldinSystemFormArray,
      tonnage: new UntypedFormArray([])
    });
  }

  /**
   * Get tonnage details for each scaffolding system for the site and render it
   */
  private renderTonnage(): any {

    const siteTonnages = this.site.Tonnages;
    const deletedScafffoldingSystems = siteTonnages.filter(siteTonnage => siteTonnage.ScaffoldingSystemData.Deleted);

    // if a scaffolding system is deleted from setting but it has tonnage in a site show them in the tonnage list
    for (const system of deletedScafffoldingSystems) {
      this.scaffoldingSystems.push(system.ScaffoldingSystemData);
    }

    this.scaffoldingSystems.forEach((system) => {
      this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = false;
      if (system.IsDefault) {
        this.selectedScaffoldingSystemid = system.ScaffoldingSystemId;
        this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = true;
      }
      const tonnagePerScaffolding = siteTonnages.find(tonnage => tonnage.ScaffoldingSystemId === system.ScaffoldingSystemId);
      let totalTonnage = '0.00', siteTonnageId = UUID.UUID(), onHireErectedTonnage = '0.00', allocatedTonnage = '0.00', idleRackTonnage = '0.00';
      if (tonnagePerScaffolding) {
        totalTonnage = tonnagePerScaffolding.TotalTonnage.toString();
        siteTonnageId = tonnagePerScaffolding.SiteTonnageId == null ? siteTonnageId : tonnagePerScaffolding.SiteTonnageId;
        onHireErectedTonnage = tonnagePerScaffolding.OnHireErectedTonnage === 0 ? '0.00' : tonnagePerScaffolding.OnHireErectedTonnage.toFixed(2).toString();
        allocatedTonnage = tonnagePerScaffolding.AllocatedTonnage === 0 ? '0.00' : tonnagePerScaffolding.AllocatedTonnage.toFixed(2).toString();
        idleRackTonnage = (parseFloat(totalTonnage) - (parseFloat(onHireErectedTonnage) + parseFloat(allocatedTonnage))).toFixed(2);
      }
      (this.form.get('tonnage') as UntypedFormArray).push(new UntypedFormGroup({
        scaffoldingSystemId: new UntypedFormControl(system.ScaffoldingSystemId),
        scaffoldingSystemName: new UntypedFormControl(system.Title),
        totalTonnage: new UntypedFormControl(totalTonnage, [CustomValidator.negativeDecimalValidator]),
        allocatedTonnage: new UntypedFormControl(allocatedTonnage),
        onHireErectedTonnage: new UntypedFormControl(onHireErectedTonnage),
        idleRackTonnage: new UntypedFormControl(idleRackTonnage),
        siteTonnageId: new UntypedFormControl(siteTonnageId)
      }));
    });
  }

  /**
   * Return the tonnage form control
   *
   * @readonly
   * @type {*}
   * @memberof AddEditSiteModalComponent
   */
  public get tonnage(): any { return this.form.get('tonnage'); }

  /**
   * Update the IdleRackTonnage based on OnHire and Allocated Tonnage
   *
   * @param {*} event input event
   * @param {*} scaffoldingSystem scaffolding system form controls
   * @returns {void}
   * @memberof AddEditSiteModalComponent
   */
  public updateIdleRackTonnage(event, scaffoldingSystem): void {
    scaffoldingSystem.controls.totalTonnage.setErrors(null);
    let totalTonnage = parseFloat(event.target.value);
    if (isNaN(totalTonnage)) {
      totalTonnage = 0;
    }
    const controls = scaffoldingSystem.controls;
    const idleRackTonnage = totalTonnage - (parseFloat(controls.onHireErectedTonnage.value) + parseFloat(controls.allocatedTonnage.value));
    controls.idleRackTonnage.setValue(idleRackTonnage.toFixed(2));
  }

  /**
   * Creates a new empty site object
   *
   * @private
   * @returns {SiteWebAppData}
   * @memberof AddEditSiteModalComponent
   */
  private getNewSite(): SiteWebAppData {
    const site = new SiteWebAppData();
    site.SiteId = UUID.UUID();
    site.SiteName = '';
    site.SiteAddress = '';
    site.SiteDepotId = this.depots.length ? this.depots[0].SiteDepotId : '';
    site.SiteStatusId = siteStatusIds.open;
    site.IsCameraAllowed = true;
    site.IsManageStockControl = false;
    site.Contracts = [];
    site.Tonnages = [];
    return site;
  }


  /**
  * Detects when the selected depots and sets them to the form
  * Controlled from the UI
  *
  * @param {string} depotId selected depot id
  * @returns {void}
  * @memberof AddEditStaffModalComponent
  */
  public onSelectedDepotsChanged(siteDepotId: string): void {
    this.form.controls.siteDepotId.setValue(siteDepotId);
  }

  /**
   * Submits the edited or new site to api
   *
   * @param {*} siteFormValues all form values
   * @memberof AddEditSiteModalComponent
   */
  public onSubmit(siteFormValues): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const commandValues = {
        SiteName: siteFormValues.siteName,
        SiteAddress: siteFormValues.siteAddress,
        SiteDepotId: siteFormValues.siteDepotId,
        Latitude: this.map.latitude,
        Longitude: this.map.longitude,
        SiteImage: undefined,
        StaffId: this.loggedInStaff.StaffId,
        DefaultSiteAreaId: undefined,
        IsCameraAllowed: siteFormValues.camerasAllowed,
        IsManageStockControl: siteFormValues.stockAllowed,
      };

      if (commandValues.IsManageStockControl) {
        commandValues['Tonnages'] = siteFormValues.tonnage;
      }

      let commandResult: Observable<any>;

      if (!this.editMode) {
        const createSiteCommand: CreateSiteCommand = Object.assign(new CreateSiteCommand(), this.site, commandValues);
        commandResult = this.siteCommandService.CreateSiteCommand(createSiteCommand);
      } else if (this.editMode) {
        const editSiteCommand: EditSiteWebConsoleCommand = Object.assign({}, this.site, commandValues);
        commandResult = this.siteCommandService.EditSiteWebConsoleCommand(editSiteCommand);
      }

      commandResult.subscribe(() => {
        // Here we must reload any data that this add/edit affects from the server.
        const obs$ = forkJoin([
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.sitesQueryService.sitesQuery(false)
        ]);
        obs$.subscribe(([siteDetail, sites]) => {
          this.saveInProgress = false;
          // Find the site reference from the updated list of sites (as site reference is generated on the server).
          const siteReference = sites.find(s => s.SiteId === this.site.SiteId).SiteReference;
          this.modalService.setDismissReason(siteReference);
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }

  /**
   * Checks if the site status is open
   *
   * @returns {boolean}
   * @memberof AddEditSiteModalComponent
   */
  public siteIsOpen(): boolean {
    return this.site.SiteStatusId === siteStatusIds.open;
  }

  /**
   * Sets the site status to open
   *
   * @memberof AddEditSiteModalComponent
   */
  public openSite(): void {
    this.site.SiteStatusId = siteStatusIds.open;
  }

  /**
   * Check is the site is valid and sets it to closed if true
   *
   * @memberof AddEditSiteModalComponent
   */
  public closeSite(): void {
    this.closeSiteValid = this.site.Contracts.every(contract => contract.ContractStatusId === contractStatusIds.closed);
    if (this.closeSiteValid) {
      this.site.SiteStatusId = siteStatusIds.closed;
    }
  }

  /**
   * Sets the map marker for the given coordinates when they are moved
   *
   * @param {*} event
   * @memberof AddEditSiteModalComponent
   */
  public onMarkerMoved(event: google.maps.MapMouseEvent): void {
    this.map.latitude = event.latLng.lat();
    this.map.longitude = event.latLng.lng();
  }

  /**
   * Places the selected coordinates on the map
   *
   * @param {CoordinatesEvent} event
   * @memberof AddEditSiteModalComponent
   */
  public onPlaceSelected(event: CoordinatesEvent): void {
    this.map.latitude = event.latitude;
    this.map.longitude = event.longitude;
  }

  /**
   * show only clicked scaffolding system tonnage inputs
   * @param scaffoldingSystem
   */
  toggle(scaffoldingSystem): void {
    this.scaffoldingSystems.forEach((system) => {
      this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = false;
      if (scaffoldingSystem.ScaffoldingSystemId === system.ScaffoldingSystemId) {
        this.selectedScaffoldingSystemid = scaffoldingSystem.ScaffoldingSystemId;
        this.toggleScaffoldingSystem[system.ScaffoldingSystemId] = true;
      }
    });
  }

  /**
   * Closes the modal and resets the dismiss reason to null.
   *
   * @memberof AddEditSiteModalComponent
   * @returns {void}
   */
  closeModal(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  /**
   * Required by AutoUnsubscribe()
   *
   * @memberof AddEditSiteModalComponent
   */
  ngOnDestroy(): void { }

}

export const siteImportScaffoldIntroSteps = [{
  element: '#scaffold-ref',
  intro: `
        <h4>Scaffold Reference</h4>
        <p>The unique reference number for the scaffold.</p>`,
  position: 'bottom'
}, {
  element: '#scaffold-name',
  intro: `
        <h4>Scaffold Name</h4>
        <p>To help identify the scaffold.</p>`,
  position: 'bottom'
},
{
  element: '#scaffoldingSystems',
  intro: `
        <h4>Scaffolding Systems</h4>
        <p>The scaffolding system used for the scaffold.</p>`,
  position: 'bottom'
}, {
  element: '#scaffold-type',
  intro: `
        <h4>Scaffold Type</h4>
        <p>The type of scaffold. For example "Birdcage" scaffold.</p>`,
  position: 'bottom'
}, {
  element: '#scaffold-loading',
  intro: `
        <h4>Scaffold Loading</h4>
        <p>The loading classification of the scaffold.</p>`,
  position: 'bottom'
}, {
  element: '#scaffold-cladding',
  intro: `
        <h4>Scaffold Cladding</h4>
        <p>The type of cladding to be used on the scaffold.</p>`,
  position: 'bottom'
}, {
  element: '#scaffold-design',
  intro: `
        <h4>Scaffold Design</h4>
        <p>The scaffold design type or design number.</p>`,
  position: 'bottom'
}];

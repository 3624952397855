<form name="staffForm" [formGroup]="form">
  <hub-modal-form-header [title]="'Add Staff To Site'" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <ul>
      <li class="dd-checkbox dd-checkbox-medium" *ngFor="let staff of staffControls['controls']; let i=index">
        <label [for]="i">
          <input type="checkbox" [value]="staffList[i].StaffId" [name]="i" [id]="i" [formControl]="staff">
          <span class="dd-label"></span>
          <span class="dd-checkbox-label">{{ staffList[i].ContactName }} ({{staffList[i].UserCode}})</span>
        </label>
      </li>
    </ul>
    <hub-modal-form-footer [requiredNote]="false" [errorMessage]="serverError" [inProgress]="saveInProgress"
      (submit)="onSubmit()" (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
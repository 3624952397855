/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EulaSlaGuard } from 'app/core/guards/eula-sla.guard';
import { EstimatesContainerComponent } from './components/estimates-container/estimates-container.component';
import { AuthorisedGuard } from 'app/core/guards/authorised-guard';

const routes: Routes = [
  {
    path: '',
    component: EstimatesContainerComponent,
    canActivate: [EulaSlaGuard, AuthorisedGuard],
    data: {
      claims: ['AddEditSites']
    },
    resolve: {

    },
    // children: []
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EstimatesRoutingModule { }

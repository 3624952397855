import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { StaffsQueryService } from 'app/core/services/staffs-query.service';
import { uniqBy as _uniqBy, flatMap as _flatMap } from 'lodash';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@AutoUnsubscribe()
@Component({
  selector: 'hub-unsafe-view',
  templateUrl: './unsafe-view.component.html',
  styleUrls: ['./unsafe-view.component.scss']
})
export class UnsafeViewComponent implements OnInit, OnDestroy {
  data: any;
  ActivityType: number;
  ActivityDetail: any;
  SiteReference: String;
  scaffoldSupervisor: any;
  scaffoldSupervisorCompanyPosition: any;
  customerContact: string;
  context: Partial<ActivityModalContext>;

  constructor(
    private staffsQueryService: StaffsQueryService,
    private bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.ActivityType = this.context.ActivityType;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.SiteReference = this.context.Site.SiteReference;
    this.customerContact = this.getCustomerContact(this.context.Site);
    this.getStaffNames();
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }

  private getStaffNames(): any {
    if (this.ActivityDetail.InspectionFollowUpNotifys) {
      this.staffsQueryService.staffsQuery(true, true).subscribe(res => {
        this.scaffoldSupervisor = _flatMap(res).filter(s => s.StaffId === this.ActivityDetail.UnsafeScaffoldReports.SiteManager)[0].ContactName;
        this.scaffoldSupervisorCompanyPosition = _flatMap(res).filter(s => s.StaffId === this.ActivityDetail.UnsafeScaffoldReports.SiteManager)[0].CompanyPosition;
      });
    }
  }
  private getCustomerContact(site): any {
    const contract = site.Contracts.find(siteContract => {
      return siteContract.ContractReference === this.ActivityDetail.SiteDetails.ContractReference;
    });
    return this.ActivityDetail.UnsafeScaffoldReports
      ? contract.ContractContacts[this.ActivityDetail.UnsafeScaffoldReports.CustomerSignature.ContractContactId].ContactName
      : null;
  }
  ngOnDestroy(): void { }
}

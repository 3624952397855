import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'hub-browser-warning',
  templateUrl: './browser-warning.component.html',
  styleUrls: ['./browser-warning.component.scss']
})
export class BrowserWarningComponent implements OnInit {
  approvedBrowsers = ['Chrome', 'Firefox', 'MS-Edge', 'Safari', "MS-Edge-Chromium"];
  notApproved = false;
  browserURL = 'http://outdatedbrowser.com/en';
  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): any {
    this.checkBrowser();
  }

  checkBrowser(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceService.isDesktop() && !this.approvedBrowsers.includes(deviceInfo.browser)) {
      this.notApproved = true;
    }
  }


}

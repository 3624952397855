<div class="modal-content modal-red-important">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ ActivityDetail?.SiteDetails.CustomerName }} - {{ ActivityDetail?.SiteDetails.SiteName }}
      ({{ ActivityDetail?.ScaffoldDetails.ScaffoldName }})
    </h4>
    <a class="pull-right" (click)="dismiss()"><i class="fa fa-close fa-2x icon"></i></a>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-1"><img src="{{ (ActivityType | idToActivity: true).icon }}" class="modal-content-icon" />
        </div>
        <div class="col-sm-11">
          <div class="row">
            <div class="col-sm-6">
              <ul>
                <li><span class="bold">Site: </span>{{ ActivityDetail?.SiteDetails.SiteName }} ({{ SiteReference }})
                </li>
                <li><span class="bold">Address: </span>{{ ActivityDetail?.SiteDetails.SiteAddress }}</li>
                <li><span class="bold">Customer: </span>{{ ActivityDetail?.SiteDetails.CustomerName }}</li>
                <li><span class="bold">Contract Contact: </span> {{ customerContact }}</li>
                <li><span class="bold">Scaffold: </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldName }}</li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul>
                <li><span class="bold" *ngIf="ActivityDetail?.ScaffoldDetails.Location">Scaffold Name:
                  </span>{{ ActivityDetail?.ScaffoldDetails.Location }}</li>
                <li><span class="bold" *ngIf="ActivityDetail?.ScaffoldDetails.ScaffoldDescription">Scaffold Description:
                  </span>{{ ActivityDetail?.ScaffoldDetails.ScaffoldDescription }}</li>
                <li><span class="bold">Date:
                  </span>{{ ActivityDetail?.ScaffoldInspections.DateOfInspection | date: 'MMM dd, yyy' }}</li>
                <li><span class="bold">Time:
                  </span>{{ ActivityDetail?.ScaffoldInspections.DateOfInspection | date: 'hh:mm a' }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="ActivityDetail?.UnsafeScaffoldReports">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Inspector Recommendation & Comments</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-6 my-1" *ngIf="ActivityDetail?.ScaffoldInspections.Inspector">
                  <span class="bold">Inspector: </span> {{ ActivityDetail?.ScaffoldInspections.Inspector }}
                  <span *ngIf="ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition?.length"> ({{ ActivityDetail?.ScaffoldInspections.InspectorCompanyPosition }})</span>
                </div>
                <div class="col-sm-6 my-1"><span class="bold">Date: </span>
                  {{ ActivityDetail?.UnsafeScaffoldReports.InspectorsSignature.When | date: 'MMM dd, yyy - hh:mm a' }}
                </div>
                <div class="col-sm-12 my-1" *ngIf="ActivityDetail?.ScaffoldInspections.Comments">
                  <span class="bold">Inspector Comments: </span> {{ ActivityDetail?.ScaffoldInspections.Comments }}
                </div>
                <div class="col-sm-12 my-1" *ngIf="ActivityDetail?.InspectionFollowUpNotifys.InspectorsRecommendations">
                  <span class="bold">Inspector Recommendations: </span>
                  {{ ActivityDetail?.InspectionFollowUpNotifys.InspectorsRecommendations }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="ActivityDetail?.UnsafeScaffoldReports">
        <div class="row" *ngFor="let defect of ActivityDetail?.ScaffoldInspections.InspectionDefects">
          <div class="col-sm-12">
            <div class="panel panel-danger">
              <div class="panel-heading">
                <h4 class="panel-title">
                  {{ defect?.DefectName }} {{ defect?.DefectTypeFurtherInformation }}</h4>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-xs-3 carousel">
                    <hub-image-carousel [media]="defect?.Media" height="100px"></hub-image-carousel>
                  </div>
                  <div class="col-sm-9 my-1" *ngIf="defect?.DefectStatus">
                    <span class="bold">Defect Status: </span> <i
                      class="fa {{ (defect.DefectStatus | defectResults).icon }}"></i>
                    {{ (defect?.DefectStatus | defectResults).title }}
                  </div>
                  <div class="col-sm-9 my-1" *ngIf="defect?.Comments"><span class="bold">Comment: </span>
                    {{ defect?.Comments }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row" *ngIf="ActivityDetail?.UnsafeScaffoldReports">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Scaffold Supervisor & Recommendations</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 my-1" *ngIf="ActivityDetail?.UnsafeScaffoldReports.SiteManagerRecommendations">
                  <span class="bold">Scaffold Supervisor Recommendations: </span>
                  {{ ActivityDetail?.UnsafeScaffoldReports.SiteManagerRecommendations }}
                </div>
                <div class="col-md-6">
                  <div>
                    <hub-signatures-svg [data]="ActivityDetail?.UnsafeScaffoldReports.SiteManagersSignature.Data"
                      width="100">
                    </hub-signatures-svg>
                  </div>
                  <div class="my-1" *ngIf="scaffoldSupervisor">
                    <span class="bold">Scaffold Supervisor: </span> {{ scaffoldSupervisor }}
                    <span *ngIf="scaffoldSupervisorCompanyPosition?.length"> ({{ scaffoldSupervisorCompanyPosition }})</span>
                  </div>
                  <div class="my-1"><span class="bold">Date: </span>
                    {{ ActivityDetail?.UnsafeScaffoldReports.SiteManagersSignature.When | date: 'MMM dd, yyy - hh:mm a' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row"
        *ngIf="ActivityDetail?.UnsafeScaffoldReports && ActivityDetail?.UnsafeScaffoldReports.CustomerSignature">
        <div class="col-sm-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h4 class="panel-title">Customer Acknowledgement</h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <div>
                    <hub-signatures-svg [data]="ActivityDetail?.UnsafeScaffoldReports.CustomerSignature.Data"
                      width="100">
                    </hub-signatures-svg>
                  </div>
                  <div class="my-1">
                    <span class="bold">Customer Representative: </span>{{ customerContact }}</div>
                  <div class="my-1"><span class="bold">Date: </span>
                    {{ ActivityDetail?.UnsafeScaffoldReports.CustomerSignature.When | date: 'MMM dd, yyy - hh:mm a' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"><button class="btn btn-large button-primary pull-right" (click)="dismiss()">Close</button>
    <hub-download-report-button [ActivityType]="ActivityType" ID="{{ActivityDetail.RelativeInspectionId}}" [ActivityDetail]="ActivityDetail"></hub-download-report-button>
  </div>
</div>

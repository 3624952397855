import { Injectable, Inject } from '@angular/core';
import { UserIdentityService } from '../authentication/services/user-identity.service';
import { HUB_API_SETTINGS_TOKEN } from './hub-api-settings-token';
import { HubApiModuleSettings } from './hub-api-module-settings';

// Service exposing functions to retrieve the current Hub API settings.
@Injectable()
export class HubApiSettingsService {

  constructor(private userIdentityService: UserIdentityService,
    @Inject(HUB_API_SETTINGS_TOKEN) private settings: HubApiModuleSettings) { }

  getApiServiceBaseUrl = () => this.settings.apiServiceBaseUri;

  getAccessToken = () => this.userIdentityService.getAccessToken();
}

import { Injectable } from '@angular/core';
import { taskIdsEnums } from 'app/timesheet/values/taskids-enum';
import { TaskStatusEnum } from 'app/core/hub-api';
import { UUID } from 'angular2-uuid';

@Injectable()
export class GenerateDefaultsService {

    constructor() { }

    generateDefaultTasks(tasks?: any): any {
        // Generates the default tasks when adding an item from the web console
        return [
            {
                AllocatedHours: tasks && tasks.Erect && tasks.Erect.TenderedHours ? tasks.Erect.TenderedHours : 0,
                ActualHours: null,
                PercentageComplete: 0,
                TaskId: UUID.UUID(),
                TaskTypeId: taskIdsEnums.erect,
                TaskStatus: TaskStatusEnum.Open
            },
            {
                AllocatedHours: tasks && tasks.Adaptions && tasks.Adaptions.TenderedHours ? tasks.Adaptions.TenderedHours : 0,
                ActualHours: null,
                PercentageComplete: 0,
                TaskId: UUID.UUID(),
                TaskTypeId: taskIdsEnums.modify,
                TaskStatus: 0
            },
            {
                AllocatedHours: tasks && tasks.GeneralLabour && tasks.GeneralLabour.TenderedHours ? tasks.GeneralLabour.TenderedHours : 0,
                ActualHours: null,
                PercentageComplete: 0,
                TaskId: UUID.UUID(),
                TaskTypeId: taskIdsEnums.other,
                TaskStatus: TaskStatusEnum.Open
            },
            {
                AllocatedHours: tasks && tasks.Dismantle && tasks.Dismantle.TenderedHours ? tasks.Dismantle.TenderedHours : 0,
                ActualHours: null,
                PercentageComplete: 0,
                TaskId: UUID.UUID(),
                TaskTypeId: taskIdsEnums.dismantle,
                TaskStatus: TaskStatusEnum.Open
            }
        ];
    }
}

import { defaultRanges } from 'app/shared/values/dates';
import { Dayjs } from 'dayjs/esm';
import dayjs from 'dayjs/esm';
import localeData from 'dayjs/esm/plugin/localeData';
dayjs.extend(localeData);
/**
 * Controls what options are available for the date picker
 *
 * @export
 * @interface DatePickerOptions
 */
export class DatePickerOptions {

    constructor() {
        this.ranges = defaultRanges;
        this.alwaysShowCalendars = true;
        this.showClearButton = false;
        this.showCustomRangeLabel = true;
        this.showDropdowns = true;
        this.closeOnAutoApply = true;
        this.locale = {
            format: 'DD/MM/YYYY',
            displayFormat: 'DD/MM/YYYY',
            direction: 'ltr',
            weekLabel: 'W',
            separator: ' - ',
            cancelLabel: 'Cancel',
            applyLabel: 'Apply',
            clearLabel: 'Clear',
            customRangeLabel: 'Custom Range',
            daysOfWeek: dayjs.weekdaysMin(),
            monthNames: dayjs.monthsShort(),
            firstDay: dayjs.localeData().firstDayOfWeek()
        }
    }

    /** List of ranges to be displayed next to the calendar (Last Month, Last Year, etc) */
    ranges?: any;
    locale?: {
        /** 'MM/DD/YYYY', could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ' */
        format?: string;
        /** 'MM/DD/YYYY', default is format value */
        displayFormat?: string;
        /** 'ltr', // could be rtl */
        direction?: string;
        /** e.g.'W', */
        weekLabel?: string;
        /** ' To ', // default is ' - ' */
        separator?: string;
        /** 'Cancel', default is 'Cancel' */
        cancelLabel?: string;
        /** 'Okay', // default is 'Apply' */
        applyLabel?: string;
        /** 'Clear', // default is 'Clear' */
        clearLabel?: string;
        /** 'Custom range' */
        customRangeLabel?: string;
        /** e.g. dayjs.weekdaysMin(), */
        daysOfWeek?: string[];
        /** e.g. dayjs.monthsShort(), */
        monthNames?: string[];
        /** first day is monday, e.g. 1 */
        firstDay?: number;
    };
    /** Hides or shows the calendars */
    alwaysShowCalendars?: boolean;
    /** Minimum available date on the calendar */
    minDate?: Dayjs;
    /** Maximum available date on the calendar */
    maxDate?: Dayjs;
    /** A list of invalid dates */
    invalidDates?: Dayjs[];
    /** A List of valid dates will exclude all dates that are no in this range */
    validDates?: Dayjs[];
    /** Placeholder for the input */
    placeholder?: string;
    /** Opens the date calendar in specific position */
    opens?: 'left' | 'right' | 'center';
    /** Drops the date calendar down or up */
    drops?: 'down' | 'up';
    /** Enables or disables the date picker clear button */
    showClearButton?: boolean;
    /** Enables or disables the date picker custom range label */
    showCustomRangeLabel?: boolean;
    /** Shows the month and year dropdowns on the date picker, defaults to true */
    showDropdowns?: boolean;
    /** Closes the date calendar on apply */
    closeOnAutoApply?: boolean;
}


import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// Datetime pipe, as Angular 4 date pipe doesn't work for IE/Edge.
@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

  transform(value: string, format: string): string {
    if (!value) { return ''; }
    return moment(value).format(format);
  }

}

<form name="importSiteDetailsForm" [formGroup]="form">
    <div class="row">
      <div class="col-md-7">
        <fieldset>
          <hub-form-group label="Site Name" name="siteName" [control]="siteNameFormControl"
            [validationErrors]="validationErrors">
            <input type="text" class="form-control" formControlName="siteName" ngxTrim>
          </hub-form-group>
          <hub-form-group label="Site Address" name="siteAddress" [control]="siteAddressFormControl"
            [validationErrors]="validationErrors">
            <hub-places-input formControlName="siteAddress" (placeSelected)="onPlaceSelected($event)">
            </hub-places-input>
          </hub-form-group>
          <hub-form-group label="Depot" name="siteDepotId" [control]="siteDepotIdFormControl"
            [validationErrors]="validationErrors">
            <select class="form-control" formControlName="siteDepotId">
              <option value="">-- please choose a depot --</option>
              <option *ngFor="let depot of depots" [value]="depot.SiteDepotId">{{depot.DepotName}}</option>
            </select>
          </hub-form-group>
          <hub-form-group name="camerasAllowed" [control]="camerasAllowedFormControl"
            [validationErrors]="validationErrors">
            <label for="camerasAllowed" class="control-label text-left">
              Cameras permitted on site?
              <i class="fa fa-info-circle pull-right"
                tooltip="If 'no' then photos will be made optional on the mobile apps" tooltip-placement="right"></i>
            </label>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="camerasAllowed" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </hub-form-group>
          <hub-form-group *ngIf="isShowManageStockControl()"  name="stockAllowed" [control]="stockAllowedFormControl" [validationErrors]="validationErrors">
            <label for="stockAllowed" class="control-label text-left">
              Stock control for this site?
              <i class="fa fa-info-circle pull-right" tooltip="If 'yes' then shows the tonnage calculation"
                tooltip-placement="right"></i>
            </label>
            <div class="pull-right">
              <div class="radio radio-inline">
                <label>
                  <input formControlName="stockAllowed" type="radio" [value]="true"> Yes
                </label>
              </div>
              <div class="radio radio-inline">
                <label>
                  <input formControlName="stockAllowed" type="radio" [value]="false"> No
                </label>
              </div>
            </div>
          </hub-form-group>


        </fieldset>
      </div>
      <div class="col-md-5">
        <div class="google-map-wrapper google-maps-modal">
          <google-map [zoom]="map.zoom" [center]="{ lat: map.latitude, lng: map.longitude }" [options]="{ scrollwheel: false }">
            <map-marker [position]="{ lat: map.latitude, lng: map.longitude }" [options]="{ draggable: true }" 
              (dragEnd)="onMarkerMoved($event)"></map-marker>
          </google-map>
        </div>
      </div>
    </div>

    <div class="row tonnage-container" [hidden]="!form.value.stockAllowed">
      <div class="col-md-4 scaffold-system">
        <ul>
          <li *ngFor="let scaffoldingSystem of scaffoldingSystems" (click)="toggle(scaffoldingSystem)">
            <a
              [ngStyle]="{'color':scaffoldingSystem.ScaffoldingSystemId === selectedScaffoldingSystemid ? 'green' : 'black'}">
              {{scaffoldingSystem.Title}}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-8 tonnage-options">
        <ul formArrayName="tonnage">
          <li *ngFor="let scaffoldingSystem of tonnage.controls; let i = index" formGroupName="{{i}}">
            <div [hidden]="!toggleScaffoldingSystem[scaffoldingSystem.controls.scaffoldingSystemId.value]">
              <div class="col-md-12">
                <h4 class="tonnage-title">{{scaffoldingSystem.controls.scaffoldingSystemName.value}}</h4>
              </div>
              <div class="col-md-12">
                <hub-form-group label="Total Tonnage" name="totalTonnage">
                  <input type="number" class="form-control" formControlName="totalTonnage" placeholder="0.00" min="0"
                    ngxTrim>
                  <div *ngIf="scaffoldingSystem.controls.totalTonnage?.errors?.invalidValue" class="text-error">
                    <i class="fa fa-warning"> </i> {{invalidValueMessage}} </div>
                </hub-form-group>
              </div>

            </div>
          </li>
        </ul>
      </div>
    </div>


    <div class="modal-footer">
      <span class="required-note">* Denotes a required field</span>
      <div class="modal-buttons">
        <button class="btn btn-large btn-inverse" type="button" (click)="onNext(form.value)">
          <i class="fa fa-chevron-circle-right"></i> Next
        </button>
      </div>
    </div>
</form>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-defect-result',
  templateUrl: './defect-result.component.html',
  styleUrls: ['./defect-result.component.scss']
})
export class DefectResultComponent implements OnInit {

  @Input()
  status: number;
  @Input()
  showOnlyTitle?: boolean;
  @Input()
  showOnlyIcon?: boolean;

  icon: string;
  title: string;

  inspectionDefectStatusIds = {
    remains: 2,
    unsafe: 1,
    fixed: 0,
  };

  constructor() { }

  ngOnInit(): void {
    switch (this.status) {
      case this.inspectionDefectStatusIds.fixed:
        this.icon = 'fa-exclamation-circle text-orange';
        this.title = 'Fixed by inspector';
        break;
      case this.inspectionDefectStatusIds.remains:
        this.icon = 'fa-exclamation-circle text-amber';
        this.title = 'Minor defects remain';
        break;
      case this.inspectionDefectStatusIds.unsafe:
        this.icon = 'fa-exclamation-circle text-assertive';
        this.title = 'Unsafe';
        break;
      default:
        this.icon = 'fa-question-circle text-gray-50';
        this.title = 'Unknown';
    }
  }
}

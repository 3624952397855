import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hub-site-stock',
  templateUrl: './site-stock.component.html',
  styleUrls: ['./site-stock.component.scss']
})
export class SiteStockComponent implements OnInit {

  private _stockAllowed: boolean;
  _isVisible: boolean;
  tooltipMessage: string;
  icon: string;
  status: string;
  @Input()
  set stockAllowed(stockAllowed: boolean) {
    this._stockAllowed = stockAllowed;
    this.isStockAllowed();
  }
  @Input()
  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isStockAllowed();
  }

  constructor() { }

  ngOnInit(): void {
    this.isStockAllowed();
  }

  isStockAllowed(): void {
    if (this._isVisible) {
      if (this._stockAllowed) {
        this.status = 'Stock control On';
        this.icon = 'fa-align-left text-positive';
        this.tooltipMessage = 'Scaffolding material tonnage is tracked on site, so some app tonnage fields will be mandatory.';
      } else {
        this.status = 'Stock control Off';
        this.icon = 'fa-align-left text-assertive';
        this.tooltipMessage = 'Scaffolding material tonnage is not tracked on site, so app tonnage fields will be hidden.';
      }
    }
  }
}

<!-- CUSTOMER CONTACTS EMPTY STATE PLACEHOLDER -->
<div *ngIf="contract">
  <accordion-group>
    <span accordion-heading>
      <i class="fa fa-regular fa-address-book text-body-color margin-right-sm"></i> {{ companyDetails.CompanyName }}
      Contacts
    </span>



    <form [formGroup]="contractContactsForm" class="form-horizontal"
      (ngSubmit)="onSaveContractContacts(contractContactsForm.value)">

      <div class="row">
        <hub-help-panel title="Reports">
          <div class="text-left">
            <div *ngFor="let helpItem of contactHelpPanelData" class="inline-block">
              <h4 class="text-positive" feature-flag="helpItem.featureFlag">{{ helpItem.name }}</h4>
              <p class="text-left" feature-flag="helpItem.featureFlag">{{ helpItem.text }}</p>
            </div>
          </div>
        </hub-help-panel>


        <div class="col-md-8 col-xs-7">
          <p> These are the contacts for {{ contract.name }} on this site.</p>
        </div>

        <div class="col-md-4 col-xs-5">
          <div class="ss-toolbar pull-right">
            <hub-permission permissions="AddEditSites">
              <a class="btn button-default button-rounded" role="button" (click)="onAddContractContact()">
                <i class="fa fa-plus"></i> <span>add contact</span>
              </a>
              <button class="btn button-default button-rounded button-rounded-expanded"
                [disabled]="updateInProgress || !contractContactsChanged" type="submit">
                <i class="fa fa-save" [ngClass]="{ 'fa-spin': updateInProgress, 'fa-spinner': updateInProgress }"></i>
                <span>save changes</span>
              </button>
            </hub-permission>
          </div>
        </div>
      </div>


      <div *ngIf="!activeCompanyContacts.length">
        <div class="row">
          <div class="col-xs-11 info-box">

            <div class="info-box-text">
              <i class="fa fa-warning text-assertive"></i> <strong class="text-assertive">Warning:
              </strong>None of your staff are receiving emails for this contract.
            </div>
            <div class="info-box-btn">
              <div>
                <hub-permission permissions="AddEditSites">
                  <a class="btn button-primary" role="button" (click)="onAddContractContact()"
                    title="Add a new customer contact">
                    <i class="fa fa-plus"></i><span>Add Company&#13;&#10;Contact</span></a>
                </hub-permission>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row margin-top-contacts">
        <div class="col-md-10">
          <table class="table-header-rotated table-borderless">
            <thead>
              <tr>
                <th class="left-head-table"></th>
                <th class="hide-on-resize"></th>
                <ng-container *ngIf="activeCompanyContacts.length">

                  <th class="rotate" *ssFeatureFlag="'handover'">
                    <div>
                      <span> <i class="fa fa-mobile rotated-table-icon"></i>Handover Certificates</span>
                    </div>
                  </th>
                  <th class="rotate" *ssFeatureFlag="'variation'">
                    <div>
                      <span> <i class="fa fa-mobile rotated-table-icon"></i>Variation Instructions</span>
                    </div>
                  </th>
                  <th class="rotate" *ssFeatureFlag="'inspection'">
                    <div>
                      <span> <i class="fa fa-regular fa-clock rotated-table-icon"></i>Inspection Summary</span>
                    </div>
                  </th>
                  <th class="rotate" *ssFeatureFlag="'inspection'">
                    <div>
                      <span> <i class="fa fa-regular fa-clock rotated-table-icon"></i>Detailed Inspection</span>
                    </div>
                  </th>
                  <th class="rotate" *ssFeatureFlag="'inspection'">
                    <div>
                      <span> <i class="fa fa-regular fa-clock rotated-table-icon"></i>Inspection Status</span>
                    </div>
                  </th>
                  <th class="rotate" *ssFeatureFlag="'inspection'">
                    <div *ngIf="contract.IsPrincipalContract">
                      <span> <i class="fa fa-info-circle rotated-table-icon"></i>Sub-Contractors Inspections</span>
                    </div>
                  </th>
                </ng-container>
                <th></th>
              </tr>
            </thead>
            <tbody class="space-below-20">
              <tr *ngFor="let contact of contractContactsForm.get('contractContacts')['controls']; let i = index"
                [formGroup]="contact">
                <td class="text-left">
                  <div class="icon-header">
                    <i class="fa fa-user fa-2x"></i>
                    <div>
                      <span class="show-on-resize">
                        <ng-template #contactTooltip>
                          <p *ngIf="activeCompanyContacts[i].EmailAddress">{{ activeCompanyContacts[i].EmailAddress }}
                          </p>
                          <p *ngIf="activeCompanyContacts[i].PhoneNumber">{{ activeCompanyContacts[i].PhoneNumber }}</p>
                        </ng-template>
                        <a [popover]="contactTooltip" [popoverTitle]="'Contacts'" placement="bottom"
                          [outsideClick]="true" tabindex="1">
                          <h4><i class="fa fa-info-circle"></i> {{ activeCompanyContacts[i].ContactName}}</h4>
                        </a>
                      </span>
                      <h4 class="hide-on-resize">{{ activeCompanyContacts[i].ContactName}}</h4>
                      <p class="margin-none">{{ getContactTypeFor(activeCompanyContacts[i]) }}</p>
                    </div>
                  </div>
                </td>
                <td class="spacing hide-on-resize">
                  <p>{{ activeCompanyContacts[i].EmailAddress }}</p>
                  <p>{{ activeCompanyContacts[i].PhoneNumber }}</p>
                </td>
                <ng-container *ngIf="activeCompanyContacts.length">
                  <td *ssFeatureFlag="'handover'">
                    <div class="round-checkbox">
                      <input type="checkbox" formControlName="handoverCertificates"
                        id="{{activeCompanyContacts[i].ContractContactId}}-handoverCertificates{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-handoverCertificates{{i}}"></label>
                    </div>
                  </td>
                  <td *ssFeatureFlag="'variation'">
                    <div class="round-checkbox">
                      <input type="checkbox" formControlName="variationInstructions"
                        id="{{activeCompanyContacts[i].ContractContactId}}-variationInstructions{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-variationInstructions{{i}}"></label>
                    </div>
                  </td>
                  <td *ssFeatureFlag="'inspection'">
                    <div class="round-checkbox">
                      <input type="checkbox" formControlName="inspectionsCompleted"
                        id="{{activeCompanyContacts[i].ContractContactId}}-inspectionsCompleted{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-inspectionsCompleted{{i}}"></label>
                    </div>
                  </td>
                  <td *ssFeatureFlag="'inspection'">
                    <div class="round-checkbox">
                      <input type="checkbox" formControlName="detailedInspection"
                        id="{{activeCompanyContacts[i].ContractContactId}}-detailedInspection{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-detailedInspection{{i}}"></label>
                    </div>
                  </td>
                  <td *ssFeatureFlag="'inspection'">
                    <div class="round-checkbox">
                      <input type="checkbox" formControlName="inspectionStatuses"
                        id="{{activeCompanyContacts[i].ContractContactId}}-inspectionStatuses{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-inspectionStatuses{{i}}"></label>
                    </div>
                  </td>
                  <td *ssFeatureFlag="'inspection'">
                    <div *ngIf="contract.IsPrincipalContract" class="round-checkbox">
                      <input type="checkbox" formControlName="subContractorInspections"
                        id="{{activeCompanyContacts[i].ContractContactId}}-subContractorInspections{{i}}" />
                      <label for="{{activeCompanyContacts[i].ContractContactId}}-subContractorInspections{{i}}"></label>
                    </div>
                  </td>
                  <td>
                    <hub-permission permissions="AddEditSites">
                      <a class="edit-link" (click)="onEditContractContact(activeCompanyContacts[i])">Edit</a>
                    </hub-permission>
                  </td>
                </ng-container>
              </tr>
              <tr>
                <td class="text-left">
                  <div class="icon-header">
                    <i class="fa fa-envelope bcc-icon"></i>
                    <div>
                      <ng-template #bccTooltip>
                        <p *ngIf="activeBccContacts && activeBccContacts[0].EmailAddress">
                          {{ activeBccContacts[0].EmailAddress }}
                        </p>
                        <p>Send a copy of all customer emails to this address</p>
                      </ng-template>
                      <a class="show-on-resize" [popover]="bccTooltip" [popoverTitle]="'BCC Email Address'"
                        placement="bottom" [outsideClick]="true" tabindex="1">
                        <h4><i class="fa fa-info-circle"></i> BCC email address</h4>
                      </a>
                      <h4 class="hide-on-resize">BCC email address</h4>
                      <p class="margin-none hide-on-resize">Send a copy of all customer emails to this address</p>
                    </div>
                  </div>
                </td>
                <td class="spacing hide-on-resize" *ngIf="activeBccContacts.length">
                  <p>{{ activeBccContacts[0].EmailAddress }}</p>
                </td>
                <ng-container *ngIf="activeBccContacts.length">
                  <td *ssFeatureFlag="'handover'"></td>
                  <td *ssFeatureFlag="'variation'"></td>
                  <td *ssFeatureFlag="'inspection'"></td>
                  <td *ssFeatureFlag="'inspection'"></td>
                  <td *ssFeatureFlag="'inspection'"></td>
                  <td *ssFeatureFlag="'inspection'"></td>
                </ng-container>
                <td colspan="8" *ngIf="!activeBccContacts.length" class="text-right">
                  <hub-permission permissions="AddEditSites">
                    <a class="btn button-default button-rounded-expanded" role="button"
                      (click)="onAddBccContact(activeBccContacts[0])">
                      <i class="fa fa-plus"></i> <span>add bcc email
                        address</span>
                    </a>
                  </hub-permission>
                </td>
                <td *ngIf="activeBccContacts.length">
                  <hub-permission permissions="AddEditSites">
                    <a class="edit-link" (click)="onEditBccContact(activeBccContacts[0])">Edit</a>
                  </hub-permission>
                <td>
              </tr>
            </tbody>
          </table>
          <div class="pull-right" *ngIf="activeCompanyContacts.length">
            <span class="margin-right" *ssFeatureFlag="'handover'"> <i
                class="fa fa-mobile fa-2x margin-right-sm"></i>app reports</span>
            <span> <i class="fa fa-regular fa-clock fa-2x margin-right-sm"></i>scheduled documents</span>
          </div>
        </div>
      </div>

    </form>



  </accordion-group>

</div>
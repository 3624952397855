export const icons = {
  noDefects: 'assets/icons/timeline/inspection-result-n.svg',
  defectsFixed: 'assets/icons/timeline/inspection-result-nf.svg',
  defectsNotFixed: 'assets/icons/timeline/inspection-result-nm.svg',
  unsafeScaffold: 'assets/icons/timeline/inspection-result-u.svg',
  neverInspected: 'assets/icons/inspection-results/inspection-result-empty.svg',
  handoverInspection: 'assets/icons/inspection-results/inspection-result-handover.svg',
  unknown: 'assets/icons/inspection-results/inspection-result-unknown.svg',
  diary: 'assets/icons/timeline/diary.svg',
  dismantle: 'assets/icons/timeline/dismantle.svg',
  handover: 'assets/icons/timeline/handover.svg',
  offhire: 'assets/icons/timeline/off-hire.svg',
  inspectionFollowUp: 'assets/icons/timeline/inspection-follow-up.svg',
  unsafeTag: 'assets/icons/timeline/tag-unsafe.svg',
  paused: 'assets/icons/timeline/inspections-paused.svg',
  stopped: 'assets/icons/timeline/inspections-stopped.svg',
  resumed: 'assets/icons/timeline/inspections-resumed.svg',
  variation: 'assets/icons/timeline/variation.svg',
  adverseWeather: 'assets/icons/inspection-types/inspection-adverse-weather.svg',
  inspection: 'assets/icons/inspection-types/inspection-generic.svg',
  sevenDay: 'assets/icons/inspection-types/inspection-schedule-7-green.svg',
  management: 'assets/icons/inspection-types/management.svg',
  extraHire: 'assets/icons/hire-status/extrahire-icon.svg',
  onHireStatus: 'assets/icons/hire-status/onhire-icon.svg',
  offHireStatus: 'assets/icons/hire-status/offhire-icon.svg',
  inCompleteStatus: 'assets/icons/hire-status/incomplete-icon.svg',
  siteDiary: 'assets/icons/siteDiary.svg'
};

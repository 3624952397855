import { Injectable } from '@angular/core';
import { PeriodTypeEnum } from 'app/core/hub-api';
import * as moment from 'moment';

/**
 * A service that provides utility functions for working with dates.
 */
@Injectable()
export class DateService {
  private readonly weekdays = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

  /**
  * Returns an array of abbreviated weekdays, starting with the specified week first day.
  * @param weekFirstDay The week first day, as a two-letter abbreviation (e.g. 'su', 'mo').
  */
  public getWeekdays(weekFirstDay: 'su' | 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa') {
    const weekFirstDayIndex = this.weekdays.indexOf(weekFirstDay);
    return [
      ...this.weekdays.slice(weekFirstDayIndex).map(day => day.toUpperCase()),
      ...this.weekdays.slice(0, weekFirstDayIndex).map(day => day.toUpperCase())
    ];
  }

  /**
  * Converts the dates in a moment object
  * @param dateRange The date range to convert.
  * @param single A flag indicating whether the date range is a single date (default: false).
  */
  public generateMomentDate(dateRange: any, single: boolean = false): any {
    // Converts the dates in a moment object before submitting
    if (dateRange) {

      if (single) {
        const singleDate = moment()
          .year(dateRange.date.year)
          .month(dateRange.date.month - 1)
          .date(dateRange.date.day).startOf('day');
        return moment(singleDate);
      }

      const start = moment()
        .year(dateRange.startDate.year)
        .month(dateRange.startDate.month - 1)
        .date(dateRange.startDate.day).startOf('day');

      const end = moment()
        .year(dateRange.endDate.year)
        .month(dateRange.endDate.month - 1)
        .date(dateRange.endDate.day).endOf('day');
      return { startDate: moment(start), endDate: moment(end) };
    }
  }

  /**
  * Converts the dates in a datepicker object
  * @param dateRange The date range to convert.
  */
  public generateDatePickerDate(dateRange: any): any {
    const dates = {
      startDate: {
        year: dateRange.startDate.year(),
        month: dateRange.startDate.month() + 1,
        day: dateRange.startDate.date()
      },
      endDate: {
        year: dateRange.endDate.year(),
        month: dateRange.endDate.month() + 1,
        day: dateRange.endDate.date()
      }
    };
    return dates;
  }
  /**
   * Applies the timezone offset to a moment date object.
   * @param timezone The timezone offset to apply, in the format `±HH:mm`.
   * @param date The date to apply the timezone to.
   * @returns A new moment date object with the timezone offset applied.
   */
  public applyTimezoneToMomentDate(timezone: string, date: moment.Moment): moment.Moment {
    return moment(date.format(`YYYY-MM-DDTHH:mm:ss${timezone}`));
  }

  /**
   * Returns an object containing the start and end dates for the last period, current period, and first period,
   * as well as other relevant information based on the given start date and period type.
   * @param firstPeriodStartDate The start date of the first period.
   * @param periodType The type of period to calculate (e.g. weekly).
   */
  getDatePeriods(firstPeriodStartDate: string, periodType: PeriodTypeEnum): any {
    let periodDays;
    // TODO: Add different Periods later
    if (periodType === PeriodTypeEnum.Weekly) {
      periodDays = 6;
    }
  
    // gets the company preferred start date (Ex: '5' = Friday)
    const weekDayNumberStart = moment(firstPeriodStartDate).isoWeekday();
  
    // sets the current week start to the company preferred start day (ex: Friday)
    const weekStart = moment().startOf('week').weekday(weekDayNumberStart);
    const lastWeekAfterWeekStart = moment(weekStart).subtract(7, 'days');
    const currentWeekStart = moment().isoWeekday() < weekDayNumberStart ? lastWeekAfterWeekStart : weekStart;
  
    // calculate the start date of the first period
    const firstPeriodStart = moment(firstPeriodStartDate);
    if (firstPeriodStart.isAfter(currentWeekStart)) {
      firstPeriodStart.subtract(7, 'days');
    }
  
    // gets the first day of the week as string
    const firstDayOfWeek = moment(firstPeriodStart).format('dddd');
    const firstDayInt = moment(firstPeriodStart).format('d');
  
    return {
      lastPeriod: {
        start: moment(currentWeekStart).subtract(periodDays + 1, 'day').startOf('day'),
        end: moment(currentWeekStart).subtract(1, 'days').startOf('day')
      },
      currentPeriod: {
        start: moment(currentWeekStart).startOf('day'),
        end: moment(currentWeekStart).add(periodDays, 'days').startOf('day')
      },
      firstPeriod: {
        start: firstPeriodStart.startOf('day'),
        end: moment(firstPeriodStart).add(periodDays, 'days').startOf('day')
      },
      weekDayNumberStart: weekDayNumberStart,
      firstDayOfWeek: firstDayOfWeek.slice(0, 2).toLowerCase(),
      firstDay: firstDayInt,
      periodDays: periodDays,
    };
  }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ScaffoldWebAppData } from 'app/core/hub-api';

import { ScaffoldDetailQueryService } from '../services/scaffold-detail-query-service';

@Injectable()
export class ScaffoldDetailResolver implements Resolve<ScaffoldWebAppData> {

  constructor(private scaffoldDetailQueryService: ScaffoldDetailQueryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ScaffoldWebAppData> {
    const id = route.params['scaffoldId'];
    return this.scaffoldDetailQueryService.scaffoldDetailQuery(false, id);
  }
}

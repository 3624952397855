<div class="modal-content">
    <div class="modal-header" [ngClass]="context.headerClass">
        <h4 class="modal-title pull-left">{{ context.title }}</h4>
        <a *ngIf="context.showHeaderClose" class="pull-right" (click)="hideConfirmationModal()" aria-label="Close">
            <i class="fa fa-close fa-2x icon"></i>
        </a>
    </div>

    <div class="modal-body">
        <div [innerHTML]="context.body"></div>
    </div>

    <div class="modal-footer" [ngClass]="context.footerClass">
        <button class="btn btn-large" [ngClass]="context.cancelBtnClass" type="button" (click)="decline()"
            [disabled]="inProgress">
            <i class="fa fa-close"></i> {{ context.cancelBtnText }}
        </button>
        <button [hidden]="context.showOnlyCloseBtn" class="btn btn-large" [ngClass]="context.okBtnClass" type="button"
            (click)="confirm()" [disabled]="inProgress">
            <i [ngClass]="{'fa-spin': inProgress,'fa-spinner': inProgress, 'fa-check':!inProgress}" class="fa"></i>
            {{ context.okBtnText }}
        </button>
    </div>

</div>
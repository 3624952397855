import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonStyleValues } from 'app/shared/values/button-style-enum';

@Component({
  selector: 'hub-export-csv',
  template: `
  <a class="btn button-default"
  [ngClass]="{ 'button-rounded': buttonStyle === buttonStyleValues.round, 'button-report-square': buttonStyle === buttonStyleValues.reportSquare }">
    <i class="fa fa-regular fa-file-excel"></i><span>export csv</span>
  </a>`
})

export class ExportCsvComponent implements OnInit, OnChanges {

  @Input()
  blobContainer: any;
  @Input()
  buttonStyle?: any;
  @Input()
  trigger?: boolean; // workaround for change detection generating multiple downloads
  buttonStyleValues = ButtonStyleValues;
  constructor() { }

  private isVaildBlob(blob: any): any {
    return blob && blob.fileURL && blob.fileName;
  }

  private download(): void {
    if (this.isVaildBlob(this.blobContainer) && this.trigger) {
      const exportLink = document.createElement('a');
      document.body.appendChild(exportLink);
      exportLink.href = this.blobContainer.fileURL;
      exportLink.download = this.blobContainer.fileName;
      exportLink.click();
    }
  }

  ngOnInit(): void {
    this.buttonStyle = this.buttonStyle ? this.buttonStyle : this.buttonStyleValues.round;
    this.download();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['blobContainer']) {
      this.download();
    }
  }
}



import {map} from 'rxjs/operators';



import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaffData, StaffEndpointService, StaffQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';
import { cadsAdminStaffId } from '../../shared/values/cads-admin-id';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class StaffsQueryService extends QueryService<StaffData[]> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'staffs';

  constructor(private staffEndpointService: StaffEndpointService) { super(); }

  staffsQuery(useCached: boolean, includeCadsAdmin = false): Observable<StaffData[]> {
    return super.query(useCached, this.key).pipe(map(staffs => {
      return staffs.filter(s => includeCadsAdmin || s.StaffId !== cadsAdminStaffId);
    }));
  }

  staffsDataChanges(includeCadsAdmin = false): Observable<StaffData[]> {
    return super.cachedDataChanges(this.key).pipe(map(staffs => {
      return staffs.filter(s => includeCadsAdmin || s.StaffId !== cadsAdminStaffId);
    }));
  }

  protected getDataFromServer(key: string): Observable<StaffData[]> {
    return this.staffEndpointService.StaffQuery(new StaffQuery());
  }
}

<form name="bccContactForm" [formGroup]="form">
    <hub-modal-form-header [title]="modalTitle" (close)="bsModalRef.hide()"></hub-modal-form-header>
    <div class="modal-body">
  
      <hub-form-group label="Bcc Email Address :" name="emailAddress" [control]="emailAddressFormControl" [validationErrors]="validationErrors">
            <input class="form-control" type="email" formControlName="emailAddress" placeholder="email@email.com" ngxTrim>
      </hub-form-group>

      <hub-modal-form-footer [requiredNote]="false" [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)" (close)="bsModalRef.hide()"></hub-modal-form-footer>
    </div>
  </form>
  
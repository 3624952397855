<form name="companyContactForm">
  <hub-modal-form-header [title]="modalTitle" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <p>Also add <strong>{{loggedInStaff.EmailAddress}}</strong> as a contact?</p>

    <p class="smaller-text">Choose <strong>Yes</strong> to receive all types of email generated by SMART Scaffolder for
      this contract.</p>
    <hub-modal-form-footer [cancelText]="cancelText" [saveText]="saveText" [requiredNote]="false"
      [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit()" (close)="bsModalRef.hide()">
    </hub-modal-form-footer>
  </div>
</form>
import { ContractInspectionStatus, InspectionStatusEnumEnum } from 'app/core/hub-api';
import { Component, Input, OnInit } from '@angular/core';

// Component for displaying a scaffold's inspection status.
@Component({
  selector: 'hub-scaffold-inspection-state',
  templateUrl: './scaffold-inspection-state.component.html',
  styleUrls: ['./scaffold-inspection-state.component.scss']
})
export class ScaffoldInspectionStateComponent implements OnInit {

  @Input()
  status: ContractInspectionStatus;
  @Input()
  inspectionsCompleted: number;
  @Input()
  overdue: boolean;

  inspectionState: string;

  constructor() { }

  ngOnInit(): void {
    if (this.status && this.status.State === InspectionStatusEnumEnum.Paused) {
      this.inspectionState = 'Paused';
    } else if (this.status && this.status.State === InspectionStatusEnumEnum.Stopped) {
      this.inspectionState = 'Stopped';
    } else if (this.inspectionsCompleted <= 0) {
      this.inspectionState = 'Never Inspected';
    } else if (this.overdue) {
      this.inspectionState = 'Overdue';
    } else {
      this.inspectionState = 'Active';
    }
  }

}

<div class="row">
  <div class="col-md-8 text-right">
    <a class="btn button-default button-rounded" role="button" (click)="onAddItem()">
      <i class="fa fa-plus"></i>
      <span class="lowercase">add {{title}}</span>
    </a>
    <a *ngIf="showMobilePreview" class="btn button-default button-rounded" role="button" (click)="onViewPreview()">
      <i class="fa fa-mobile"></i>
      <span>mobile preview</span>
    </a>
  </div>
  <div class="col-md-8">
    <table class="table table-borderless data-entries-table">
      <thead class="table-header">
        <tr>
          <th colspan="2">
            {{title}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items, let i = index">
          <td>{{item.Title}}</td>
          <td class="truncate">{{item.Subtitle}}</td>
          <td class="filters-col text-right">
            <div dropdown class="btn-group">
              <a dropdownToggle class="dropdown-toggle">
                <span class="fa fa-bars"></span>
              </a>
              <ul *dropdownMenu class="dropdown-menu pull-right">
                <li *ngIf="!item['IsDefault'] &&  listKey === 'ScaffoldingSystem'">
                  <a (click)="onSetAsDefault(i)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Set As Default</a>
                </li>
                <li>
                  <a (click)="onMoveItem(i, 0)">
                    <i class="fa fa-level-up"></i> Move to Top</a>
                </li>
                <li>
                  <a (click)="onMoveItem(i, i-1)">
                    <i class="fa fa-long-arrow-up"></i> Move Up</a>
                </li>
                <li>
                  <a (click)="onMoveItem(i, i+1)">
                    <i class="fa fa-long-arrow-down"></i> Move Down</a>
                </li>
                <li>
                  <a (click)="onMoveItem(i, items.length - 1)">
                    <i class="fa fa-level-down"></i> Move to Bottom</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a (click)="onDeleteItem(i)">
                    <i class="fa fa-times text-assertive" aria-hidden="true"></i> Delete</a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

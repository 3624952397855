import { siteImportScaffoldIntroSteps } from '../../../values/site-import-scaffold-intro-steps';
import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { filter, find, flatten, flow, map, partialRight, times, forEach as _forEach } from 'lodash';
import { forkJoin } from 'rxjs';
import {
  CreateScaffoldWebConsoleCommand,
  ScaffoldSpecificationWebConsoleData,
  ItemTypeEnum,
  ItemStatusEnum,
} from 'app/core/hub-api';
import { ImportEndpointService } from 'app/core/hub-api';
import {
  ConfigurationData,
  DesignTypeData,
  LoadingLimitData,
  MethodsOfCladdingdata,
  ScaffoldTypeData
} from 'app/core/hub-api';
import { CustomersQueryService } from '../../../../core/services/customers-query.service';
import { SitesQueryService } from '../../../../core/services/sites-query.service';
import { MultiStepModalFormComponent } from '../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { designTypeIds } from '../../../../shared/values/design-type-ids';
import { SiteImportModalContext } from '../../../models/site-import-modal-context';
import { SiteImportModalData } from '../../../models/site-import-modal-data';
import { SiteImportScaffold } from '../../../models/site-import-scaffold';
import { SiteImportScaffoldItem } from '../../../models/site-import-scaffold-item';
import { claddings } from '../../../values/claddings';
import { loadClasses } from '../../../values/load-classes';
import { GenerateDefaultsService } from 'app/core/services/generateDefaults.service';
import { environment } from 'environments/environment';
import { CustomValidator } from 'app/shared/validators/custom-validators';
import { errorMessages } from 'app/shared/values/error-messages';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as introJs from 'intro.js';
import { ReferenceGeneratorService } from 'app/core/utility';

@Component({
  selector: 'hub-site-import-scaffolds',
  templateUrl: './site-import-scaffolds.component.html',
  styleUrls: ['./site-import-scaffolds.component.scss']
})
export class SiteImportScaffoldsComponent extends MultiStepModalFormComponent implements OnInit {
  introJS = introJs.default();

  context: Partial<SiteImportModalContext>;
  data: SiteImportModalData;
  form: UntypedFormGroup;

  importAll: boolean;
  anyScaffoldsInFile: boolean;

  itemsFormArray: UntypedFormArray;
  validationMessages: {};

  saveInProgress: boolean;

  scaffoldNumbers: string[];
  configuration: ConfigurationData;

  scaffoldTypes: ScaffoldTypeData[];
  loadingLimits: LoadingLimitData[];
  methodOfCladdings: MethodsOfCladdingdata[];
  designTypes: DesignTypeData[];
  scaffoldingSystems;

  invalidValueMessage = errorMessages.invalidValue;

  /** Stores any error message of the api calls */
  serverError = '';

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private referenceGeneratorService: ReferenceGeneratorService,
    private importEndpointService: ImportEndpointService,
    private customersQueryService: CustomersQueryService,
    private sitesQueryService: SitesQueryService,
    private generateDefaultsService: GenerateDefaultsService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.importAll = true;
    this.scaffoldNumbers = [];

    this.configuration = this.context.configuration;
    this.scaffoldTypes = map(this.configuration.ScaffoldTypes);
    this.loadingLimits = map(this.configuration.LoadingLimits);
    this.methodOfCladdings = map(this.configuration.MethodOfCladdings);
    this.designTypes = map(this.configuration.DesignTypes);
    this.scaffoldingSystems = map(this.configuration.ScaffoldingSystems);


    if (!this.data.importData.scaffoldItems && this.data.siteImportFile.Areas.length > 0) {
      this.data.importData.scaffoldItems = this.data.siteImportFile.Areas[0].Items.map(item => {
        const tasks =
          item.Pricing && item.Pricing.TotalTenderedPrice
            ? item.Pricing.TotalTenderedPrice.PreTaxTenderedPrice.TotalCost.DirectCost.Labour.OutputPay
            : [];
        const allTasks = this.generateDefaultsService.generateDefaultTasks(tasks);

        return {
          itemName: item.ItemName,
          itemNumber: item.ScheduleItemId ? item.ScheduleItemId : item.ItemNumber,
          quantity: item.Quantity,
          hirePeriodInWeeks: item.HirePeriodInWeeks,
          tenderedPrice: item.TenderedPrice,
          allocatedHours:
            item.Pricing && item.Pricing.TotalTenderedPrice
              ? item.Pricing.TotalTenderedPrice.PreTaxTenderedPrice.TotalCost.DirectCost.Labour.OutputPay.TenderedHours
              : 0,
          tasks: allTasks,
          scaffolds: flatten(
            item.Scaffolds.map(s => {
              return times(item.Quantity, () => {
                const scaffoldNumber = this.referenceGeneratorService.incrementalReference(
                  this.scaffoldNumbers,
                  this.configuration.ScaffoldReferenceLength
                );
                this.scaffoldNumbers.push(scaffoldNumber);
                return Object.assign(new SiteImportScaffold(), {
                  ScaffoldNumber: scaffoldNumber,
                  SpecificLocationDetails: s.ScaffoldName,
                  ScaffoldSpecification: {
                    QuoteNumber: this.data.siteImportFile.QuotationNumber,
                    QuoteItemNumber: item.ItemNumber,
                    ScaffoldTypeId: this.getScaffoldType(s.ScaffoldType),
                    DesignTypeId: this.getDesignType(s.ScaffoldingSystem),
                    LoadingLimitId: loadClasses[s.LoadClass] || '',
                    MethodOfCladdingId: claddings[s.CladdingList[0]] || '',
                    HirePeriod: item.HirePeriodInWeeks,
                    MaximumTonnage: (0.00).toFixed(2),
                    ScaffoldingSystemId: this.getScaffoldSystemType(s.ScaffoldingSystem)
                  },
                  selected: true
                });
              });
            })
          )
        };
      });
    }

    this.itemsFormArray = new UntypedFormArray(this.data.importData.scaffoldItems ? this.data.importData.scaffoldItems.map(i => this.createItemFormGroup(i)) : []);
    if (this.itemsFormArray.length > 0) (this.itemsFormArray.controls[0] as any).showRows = true;
    this.form = new UntypedFormGroup({
      items: this.itemsFormArray
    });

    // sets the validation messages for the form
    this.form.get('items').valueChanges.subscribe((value) => {
      this.setConditionalValidation();
    });
    super.ngOnInit();
  }

  /**
   * Sets conditional validation for the form fields.
   * in this specific case it is setting or removing the validation for the scaffold name field.
   */
  setConditionalValidation() {
    const formArray = this.form.get('items') as FormArray;
    formArray.controls.forEach(itemGroup => {
      const scaffoldsArray = itemGroup.get('scaffolds') as FormArray;
      scaffoldsArray.controls.forEach(scaffoldGroup => {
        if (scaffoldGroup.value.selected === true) {
          scaffoldGroup.get('specificLocationDetails').setValidators([Validators.required]);
          scaffoldGroup.get('specificLocationDetails').updateValueAndValidity({ emitEvent: false });
        } else {
          scaffoldGroup.get('specificLocationDetails').clearValidators();
          scaffoldGroup.get('specificLocationDetails').updateValueAndValidity({ emitEvent: false });
        }
      });
    });
  }

  createItemFormGroup(item: SiteImportScaffoldItem): UntypedFormGroup {
    return new UntypedFormGroup({
      itemName: new UntypedFormControl(item.itemName),
      itemNumber: new UntypedFormControl(item.itemNumber),
      quantity: new UntypedFormControl(item.quantity),
      hirePeriodInWeeks: new UntypedFormControl(item.hirePeriodInWeeks),
      tenderedPrice: new UntypedFormControl(item.tenderedPrice),
      allocatedHours: new UntypedFormControl(item.allocatedHours),
      scaffolds: new UntypedFormArray(
        item.scaffolds.map(s => {
          return this.createScaffoldFormGroup(s);
        })
      ),
      tasks: new UntypedFormControl(item.tasks)
    });
  }

  createScaffoldFormGroup(scaffold: SiteImportScaffold): UntypedFormGroup {
    this.anyScaffoldsInFile = true;
    // If the scaffold has a method of cladding, use it. Otherwise, use the default of 'None'
    const methodOfCladding = scaffold.ScaffoldSpecification.MethodOfCladdingId ? scaffold.ScaffoldSpecification.MethodOfCladdingId : this.methodOfCladdings.find(mc => mc.Title === 'None').MethodOfCladdingId;
    return new UntypedFormGroup({
      scaffoldNumber: new UntypedFormControl(scaffold.ScaffoldNumber, Validators.required),
      specificLocationDetails: new UntypedFormControl(scaffold.SpecificLocationDetails, scaffold.selected ? Validators.required : []),
      // TODO: Is this required??
      // quoteNumber: new FormControl(scaffold.ScaffoldSpecification.QuoteNumber),
      // quoteItemNumber: new FormControl(scaffold.ScaffoldSpecification.QuoteItemNumber),
      scaffoldTypeId: new UntypedFormControl(scaffold.ScaffoldSpecification.ScaffoldTypeId),
      designTypeId: new UntypedFormControl(scaffold.ScaffoldSpecification.DesignTypeId),
      loadingLimitId: new UntypedFormControl(scaffold.ScaffoldSpecification.LoadingLimitId),
      methodOfCladdingId: new UntypedFormControl(methodOfCladding),
      hirePeriod: new UntypedFormControl(scaffold.ScaffoldSpecification.HirePeriod),
      selected: new UntypedFormControl(scaffold.selected),
      maximumTonnage: new UntypedFormControl(scaffold.ScaffoldSpecification.MaximumTonnage, [CustomValidator.negativeDecimalValidator]),
      scaffoldingSystemId: new UntypedFormControl(scaffold.ScaffoldSpecification.ScaffoldingSystemId)
    });
  }

  onPrevious(formValues): void {
    this.saveFormValues(formValues);
    this.previousStep();
  }

  isScaffoldCommandsValid(createScaffoldCommands): any {
    return _forEach(createScaffoldCommands, scaffold => {
      scaffold.ScaffoldSpecification.MaximumTonnage = scaffold.ScaffoldSpecification.MaximumTonnage !== '' ?
        scaffold.ScaffoldSpecification.MaximumTonnage : 0.00;
      return scaffold;
    });
  }

  onSubmit(formValues): void {
    this.saveFormValues(formValues);
    if (this.validateForm()) {
      this.saveInProgress = true;

      const siteId = UUID.UUID();
      const defaultSiteAreaId = UUID.UUID();
      const contractId = UUID.UUID();
      const customerId = UUID.UUID();
      const quoteId = UUID.UUID();

      const createScaffoldCommands = flow([partialRight(map, i => i.scaffolds), flatten, partialRight(filter, s => s.selected)])(
        this.data.importData.scaffoldItems
      );

      const scaffoldCommands = this.isScaffoldCommandsValid(createScaffoldCommands);

      // Get Estimator Name From Import File
      const estimatorForeName = this.data.siteImportFile.EstimatorAddress.Contacts.length
        ? this.data.siteImportFile.EstimatorAddress.Contacts[0].ForeName
        : '';
      const estimatorSurName = this.data.siteImportFile.EstimatorAddress.Contacts.length
        ? this.data.siteImportFile.EstimatorAddress.Contacts[0].Surname
        : '';
      const estimatorName = estimatorForeName && estimatorSurName ? `${estimatorForeName} ${estimatorSurName}` : estimatorForeName;
      const featureFlags = environment.featureFlags as any;

      // TODO: const importData: ImportData = {
      const importData = {
        CreateSiteCommand: Object.assign(this.data.importData.site, {
          SiteId: siteId,
          DefaultSiteAreaId: defaultSiteAreaId
        }),
        CreateContractCommand: Object.assign(this.data.importData.contract, {
          SiteId: siteId,
          ContractId: contractId,
          CustomerId: this.data.importData.customer ? customerId : this.data.importData.contract.CustomerId
        }),
        CreateCustomerCommand: this.data.importData.customer ? Object.assign(this.data.importData.customer, { CustomerId: customerId }) : undefined,
        CreateScaffoldCommands: scaffoldCommands.map(command => {
          return Object.assign(command, {
            ScaffoldId: UUID.UUID(),
            ContractId: contractId,
            SiteAreaId: defaultSiteAreaId
          });
        }),
        CreateQuoteCommand: {
          ContractId: contractId,
          QuoteId: quoteId,
          QuoteNumber: this.data.siteImportFile.QuotationNumber,
          Estimator: estimatorName ? estimatorName : ''
        },
        CreateContractContactCommands: this.data.importData.contacts.map(command => {
          return Object.assign(command, {
            ContractId: contractId,
            ContractContactId: UUID.UUID()
          });
        }),
        CreateItemCommands: this.data.importData.scaffoldItems.map(command => {
          return {
            ContractId: contractId,
            ItemName: command.itemName,
            ItemNumber: command.itemNumber,
            QuoteNumber: this.data.siteImportFile.QuotationNumber,
            QuoteId: quoteId,
            ItemId: UUID.UUID(),
            ItemStatus: ItemStatusEnum.Open,
            ItemType: ItemTypeEnum.Quoted,
            ScaffoldIds: command.scaffolds.filter(s => s.selected).map(s => s.ScaffoldId),
            AllocatedHours: command.allocatedHours,
            Tasks: featureFlags.timesheet ? command.tasks : null
          };
        })
      };

      this.importEndpointService.Import({ ImportData: JSON.stringify(importData) }).subscribe(() => {
        const obs$ = forkJoin([this.customersQueryService.customersQuery(false), this.sitesQueryService.sitesQuery(false)]);
        obs$.subscribe(result => {
          const sites = result[1];
          const site = sites.find(s => s.SiteId === siteId);
          const siteReference = site ? site.SiteReference : undefined;
          this.saveInProgress = false;
          // the dismiss reason is set to the siteId if it is being imported from the estimates grid
          if (this.context.projectId) {
            this.modalService.setDismissReason(site.SiteId);
          } else {
            this.modalService.setDismissReason(siteReference);
          }
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }

  saveFormValues(formValues: any): void {
    this.data.importData.scaffoldItems = formValues.items.map(item => {
      return {
        itemName: item.itemName,
        itemNumber: item.itemNumber,
        quantity: item.quantity,
        hirePeriodInWeeks: item.hirePeriodInWeeks,
        tenderedPrice: item.tenderedPrice,
        allocatedHours: item.allocatedHours,
        tasks: item.tasks,
        scaffolds: item.scaffolds.map(s => {
          return Object.assign(new CreateScaffoldWebConsoleCommand(), {
            selected: s.selected,
            ScaffoldNumber: s.scaffoldNumber,
            SpecificLocationDetails: s.specificLocationDetails,
            ScaffoldSpecification: Object.assign(new ScaffoldSpecificationWebConsoleData(), {
              QuoteNumber: s.quoteNumber,
              QuoteItemNumber: s.quoteItemNumber,
              ScaffoldTypeId: s.scaffoldTypeId,
              DesignTypeId: s.designTypeId,
              LoadingLimitId: s.loadingLimitId,
              MethodOfCladdingId: s.methodOfCladdingId,
              HirePeriod: s.hirePeriod,
              MaximumTonnage: s.maximumTonnage,
              ScaffoldingSystemId: s.scaffoldingSystemId
            })
          });
        })
      };
    });
  }

  getScaffoldType(type: string): string {
    const firstWordOfType = type
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')[0]
      .toLowerCase();
    const importedScaffoldType = find(this.scaffoldTypes, scaffoldType => {
      return scaffoldType.Title.toLowerCase().indexOf(firstWordOfType) > -1;
    });
    return importedScaffoldType ? importedScaffoldType.ScaffoldTypeId : '';
  }

  getScaffoldSystemType(type: string): string {
    const firstWordOfType = type
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')[0]
      .toLowerCase();
    const importedScaffoldSystemType = find(this.scaffoldingSystems, scaffoldingSystem => {
      return scaffoldingSystem.Title.toLowerCase().indexOf(firstWordOfType) > -1;
    });
    return importedScaffoldSystemType ? importedScaffoldSystemType.ScaffoldingSystemId : '';

  }

  getDesignType(type: string): string {
    if (type.toLowerCase() !== 'tubeandfitting') {
      return designTypeIds.systemScaffold;
    }
    return '';
  }

  onToggleImportAll(): void {
    this.importAll = !this.importAll;
    this.itemsFormArray.controls.map((formGroup: UntypedFormGroup) =>
      (formGroup.controls.scaffolds as UntypedFormArray).controls.map((c: UntypedFormGroup) => c.controls.selected.setValue(this.importAll))
    );
  }

  allScaffoldSelected(): boolean {
    const allScaffoldsSelected = this.itemsFormArray.controls.every((formGroup: UntypedFormGroup) =>
      (formGroup.controls.scaffolds as UntypedFormArray).controls.every((c: UntypedFormGroup) => c.controls.selected.value)
    );
    this.importAll = allScaffoldsSelected;
    return allScaffoldsSelected;
  }

  onStartTour(): void {
    this.introJS.setOptions({
      steps: siteImportScaffoldIntroSteps
    });
    this.introJS.start();
  }
}

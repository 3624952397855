import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * This component creates an iframe for youtube embed links
 *
 * @export
 * @class YoutubeComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'hub-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit, OnChanges {
  // Gets the youtube iframe to be accessed here
  @ViewChild('iframeYoutube') iframeYoutube: ElementRef;
  // Gets the youtube url from the parent component
  @Input() source;

  // Gets the safe url to be displayed in the embed iframe
  src: SafeResourceUrl;

  constructor(public domSanitizer: DomSanitizer) { }

  /**
   * Sets the url as a trusted resouce so UI can access it
   *
   * @memberof YoutubeComponent
   */
  ngOnInit(): void {
    this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(this.source);
  }

  /**
   * Detects changes to source property and sets the new url and refreshes the iframe
   *
   * @param {SimpleChanges} changes
   * @memberof YoutubeComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.source = changes['source'].currentValue;
    if (this.iframeYoutube) {
      this.iframeYoutube.nativeElement.src = this.source;
    }
  }

}

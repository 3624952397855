<form name="groupScaffoldsForm" [formGroup]="form">
  <hub-modal-form-header title="Group Scaffolds" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <p>Grouping scaffolds allows a number of scaffolds to share the same scaffold reference, each with an individual suffix.</p>
    <p>For example 0001-1, 0001-2, 0001-3 and 0001-4.</p>
    <hub-form-group label="Select the scaffold reference you would like to keep" name="baseScaffoldId" [control]="baseScaffoldIdFormControl"
      [validationErrors]="validationErrors">
      <select class="form-control" formControlName="baseScaffoldId">
        <option *ngFor="let scaffold of selectedScaffolds" [value]="scaffold.ScaffoldId">{{scaffold.oldScaffoldNumber}}</option>
      </select>
    </hub-form-group>
    <ngx-datatable class="table" [rows]="selectedScaffolds" [columnMode]="'flex'" [footerHeight]="50" [headerHeight]="40" [rowHeight]="'auto'"
      [sorts]="[{prop: 'oldScaffoldNumber', dir: 'asc'}]" [limit]="10">
      <ngx-datatable-column name="Scaffold" prop="oldScaffoldNumber" [comparator]="scaffoldNumberComparator" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.oldScaffoldNumber}}
          <span *ngIf="row.SpecificLocationDetails"> ({{row.SpecificLocationDetails}})</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="New Scaffold Number" prop="ScaffoldNumber" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.ScaffoldNumber}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <hub-modal-form-footer [errorMessage]="serverError" [requiredNote]="false" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, UntypedFormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SetSiteStaffPermissionsCommand, SiteWebAppData, StaffData, SiteCommandService } from 'app/core/hub-api';
import { StaffDetailQueryService } from '../../../../../core/services/staff-detail-query.service';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { AddEditSiteStaffModalContext } from '../../../../models/add-edit-site-staff-modal-context';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { forEach } from 'lodash';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-add-edit-site-staff-modal',
  templateUrl: './add-edit-site-staff-modal.component.html',
  styleUrls: ['./add-edit-site-staff-modal.component.scss']
})
export class AddEditSiteStaffModalComponent extends FormComponent implements OnInit {
  context: Partial<AddEditSiteStaffModalContext>;

  form: UntypedFormGroup;

  site: SiteWebAppData;
  staffList: StaffData[];

  validationMessages = {
    staffId: {
      required: errorMessages.required
    }
  };

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private siteDetailQueryService: SiteDetailQueryService,
    private staffDetailQueryService: StaffDetailQueryService,
    private formBuilder: UntypedFormBuilder,
    private siteCommandService: SiteCommandService,
    public modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.site = this.context.site;
    this.staffList = this.context.staffs
      .filter(s => !s.Deleted)
      .sort((a, b) => (a.ContactName.toLocaleLowerCase() > b.ContactName.toLocaleLowerCase() ? 1 : -1));

    this.form = this.formBuilder.group({
      staffControls: this.buildStaffControls()
    });
    super.ngOnInit();
  }

  get staffControls(): AbstractControl {
    return this.form.get('staffControls');
  }

  private buildStaffControls(): UntypedFormArray {
    const arr = this.staffList.map(staff => {
      return this.formBuilder.control((staff as any).selected);
    });
    return this.formBuilder.array(arr);
  }

  onSubmit(): void {
    if (this.validateForm()) {
      this.saveInProgress = true;

      const formValue = Object.assign(this.form.value, {
        staffIds: this.form.value.staffControls.map((selected, i) => {
          return {
            id: this.staffList[i].StaffId,
            selected
          };
        })
      });

      const setSiteStaffPermissionsCommand = Object.assign(new SetSiteStaffPermissionsCommand(), {
        StaffIds: formValue.staffIds.filter(staff => staff.selected).map(val => val.id),
        IsInactive: false,
        SiteId: this.site.SiteId
      });

      if (setSiteStaffPermissionsCommand.StaffIds.length) {
        const obs$ = forkJoin([
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          forEach(setSiteStaffPermissionsCommand.StaffIds, staffId => this.staffDetailQueryService.staffDetailQuery(false, staffId))
        ]);

        this.siteCommandService.SetSiteStaffPermissionsCommand(setSiteStaffPermissionsCommand).subscribe(() => {
          // Here we must reload any data that this add/edit affects from the server.
          const obs2$ = forkJoin([
            this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
            forEach(setSiteStaffPermissionsCommand.StaffIds, staffId => this.staffDetailQueryService.staffDetailQuery(false, staffId))
          ]);
          obs2$.subscribe(() => {
            this.saveInProgress = false;
            this.bsModalService.setDismissReason(setSiteStaffPermissionsCommand.StaffIds);
            this.bsModalRef.hide();
          });
        }, this.serverErrorCallback);
      } else {
        this.saveInProgress = false;
        this.bsModalService.setDismissReason(setSiteStaffPermissionsCommand.StaffIds);
        this.bsModalRef.hide();
      }
    }
  }
}

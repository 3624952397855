/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ConfigurationData,
  ContractData,
  CustomerWebAppData,
  ScaffoldWebAppData,
  StaffDetailsWebAppData,
  SiteAreaData,
  QuoteData
} from 'app/core/hub-api';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep, map, uniq } from 'lodash';
import { AddEditScaffoldModalContext } from '../../../../models/add-edit-scaffold-modal-context';

import { MultiStepModalFormComponent } from '../../../../../shared/components/multi-step-modal-form/multi-step-modal-form.component';
import { UUID } from 'angular2-uuid';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { mustNotExistValidator } from '../../../../../shared/validators/must-not-exist.validator';
import { scaffoldNumberIncrementalValidator } from '../../../../../shared/validators/scaffold-number-incremental.validator';
import { scaffoldNumberSuffixValidator } from '../../../../../shared/validators/scaffold-number-suffix.validator';
import { scaffoldStatusIds } from '../../../../../shared/values/scaffold-status-ids';
import { QuotesQueryService } from '../../../../../core/services/quotes-query-service';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DatePickerSelectData } from '../../../../../shared/models/date-picker-select-data';
import { ReferenceGeneratorService } from 'app/core/utility';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import dayjs from 'dayjs/esm';
import { scaffoldWithQuickIdMustNotExistValidator } from 'app/shared/validators/scaffold-must-not-exist-validator';
/**
 * Add Edit Scaffold (Details page)
 * Displays a form to edit the scaffold details
 * when next is pressed will show specification page
 *
 * @export
 * @class AddEditScaffoldDetailComponent
 * @extends {MultiStepModalFormComponent<AddEditScaffoldModalContext>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-scaffold-detail',
  templateUrl: './add-edit-scaffold-detail.component.html',
  styleUrls: ['./add-edit-scaffold-detail.component.scss']
})
export class AddEditScaffoldDetailComponent extends MultiStepModalFormComponent implements OnInit, OnDestroy {
  /** Store all quotes */
  quotes: QuoteData[];
  /** Store all data that is shared across both modals */
  data: any;
  /** Store the modal dialog */
  context: Partial<AddEditScaffoldModalContext>;
  /** Detects if the form is edit or add */
  editMode: boolean;
  /** Detects if the form is edit or add */
  scaffold: ScaffoldWebAppData;
  /** Stores the current logged in user */
  loggedInStaff: StaffDetailsWebAppData;
  /** Stores all scaffolds */
  allScaffolds: ScaffoldWebAppData[];
  /** Stores all customers */
  customers: CustomerWebAppData[];
  /** Stores all configuration */
  configuration: ConfigurationData;
  /** Stores all active areas */
  activeAreas: SiteAreaData[];
  /** Stores all open contracts */
  openContracts: ContractData[];
  /** Stores the current scaffold quickId */
  quickId: string;
  /** Stores all scaffold numbers */
  scaffoldNumbers: string[];
  /** Stores all incremental scaffold numbers */
  incrementalScaffoldNumbers: string[];
  /** Form Group control */
  form: UntypedFormGroup;
  /** Scaffold number input controller */
  autoScaffoldNumberFormControl: UntypedFormControl;
  /** Name input controller */
  nameFormControl: UntypedFormControl;
  /** Contract dropdown controller */
  contractIdFormControl: UntypedFormControl;
  /** Area dropdown controller */
  areaIdFormControl: UntypedFormControl;
  /** OnHire radio controller */
  onHireFormControl: UntypedFormControl;
  /** Next Inspection date picker controller */
  nextInspectionFormControl: UntypedFormControl;
  /** OnHire date picker controller */
  onHireDateFormControl: UntypedFormControl;
  /** Contract dropdown controller */
  contractPeriodFormControl: UntypedFormControl;
  /** Adverse weather input controller */
  adverseWeatherFormControl: UntypedFormControl;
  /** Date picker configuration */
  datePickerOptions: DatePickerOptions;
  /** All validation messages for the respective form fields */
  validationMessages = {
    scaffoldNumber: {
      incremental: errorMessages.scaffoldNumberIncremental,
      suffix: errorMessages.scaffoldNumberSuffix,
      mustNotExist: errorMessages.scaffoldReferenceMustBeUnique
    },
    customScaffoldNumber: {
      required: errorMessages.scaffoldNumberRequired,
      scaffoldWithQuickIdMustNotExist: errorMessages.scaffoldReferenceMustBeUnique
    },
    contractId: {
      required: errorMessages.required
    },
    areaId: {
      required: errorMessages.required
    },
    contractPeriod: {
      min: errorMessages.minimumContractPeriod
    },
    name: {
      required: errorMessages.required
    },
    suffix: {
      required: errorMessages.suffixRequired,
      pattern: errorMessages.scaffoldNumberSuffix
    }
  };
  /** List of scaffold numbers */
  scaffoldNumbersList = [];
  /** Stores the form for the scaffold number */
  scaffoldNumberForm: UntypedFormGroup;
  /** group scaffold toggle form control */
  groupScaffoldsFormControl: UntypedFormControl;
  /** custom scaffold number form control */
  customScaffoldNumberFormControl: UntypedFormControl;
  /** suffix form control */
  suffixFormControl: UntypedFormControl;
  /** Stores suffix validation */
  suffixValidation = [Validators.required, Validators.pattern(/^[A-Z0-9]{1,3}$/)];

  constructor(
    private referenceGeneratorService: ReferenceGeneratorService,
    private quotesQueryService: QuotesQueryService,
    public modalService: BsModalService,
  ) {
    super();
  }

  /**
   * Initialises the Component
   *
   * @memberof AddEditScaffoldDetailComponent
   */
  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.datePickerOptions = {
      alwaysShowCalendars: false,
      ranges: {},
      showCustomRangeLabel: false,
    };
    this.editMode = this.context.editMode;
    this.allScaffolds = this.context.scaffolds;
    this.configuration = this.context.configuration;
    this.loggedInStaff = this.context.loggedInStaff;

    const currentScaffoldId = this.context.scaffold ? this.context.scaffold.ScaffoldId : undefined;
    this.scaffoldNumbers = this.allScaffolds.filter(s => s.ScaffoldId !== currentScaffoldId).map(s => s.ScaffoldNumber);
    this.incrementalScaffoldNumbers = uniq(this.scaffoldNumbers.map(n => n.slice(0, 5)));

    this.customers = this.context.customers;
    this.openContracts = this.context.site.Contracts.filter(c => c.ContractStatusId === contractStatusIds.open).map(contract => {
      contract = cloneDeep(contract);
      (contract as any).optionName = `${contract.ContractName} (${contract.ContractReference})`;
      return contract;
    });
    this.activeAreas = (map(this.context.site.SiteAreas)
      .filter(a => !a.Deleted) as any)
      .sortAlphaNumeric('AreaName');

    this.quickId = this.context.loggedInStaff.QuickId ? this.context.loggedInStaff.QuickId : '';
    this.context.scaffoldSuffix = this.context.scaffoldSuffix || this.quickId;
    this.data.scaffold = this.data.scaffold || cloneDeep(this.context.scaffold) || this.getNewScaffold();

    this.generateForm();

    this.quotesQueryService.quotesQuery(false, this.form.value.contractId).subscribe(q => (this.quotes = q)),
      this.contractIdFormControl.valueChanges.subscribe(id => this.onSelectedContractChange(id));
    super.ngOnInit();
  }

  /**
   * Creates the form fields, sets the values and validation
   *
   * @memberof AddEditScaffoldDetailComponent
   */
  public generateForm(): void {
    this.autoScaffoldNumberFormControl = new UntypedFormControl(this.context.autoScaffoldNumber ? this.context.autoScaffoldNumber : this.data.scaffold.ScaffoldNumber.toUpperCase(), [
      scaffoldNumberSuffixValidator,
      scaffoldNumberIncrementalValidator,
      mustNotExistValidator(this.scaffoldNumbers)
    ]);
    // To handle null scaffold specification for existing scaffolds.
    if (!this.data.scaffold.ScaffoldSpecification) {
      this.data.scaffold.ScaffoldSpecification = {};
    }
    this.nameFormControl = new UntypedFormControl(this.data.scaffold.SpecificLocationDetails, [Validators.required]);
    this.contractIdFormControl = new UntypedFormControl(this.data.scaffold.ContractId, Validators.required);
    this.areaIdFormControl = new UntypedFormControl(this.data.scaffold.SiteAreaId, Validators.required);
    this.onHireFormControl = new UntypedFormControl(this.data.scaffold.ScaffoldStatusId === scaffoldStatusIds.onHire);
    this.nextInspectionFormControl = new UntypedFormControl();
    this.onHireDateFormControl = new UntypedFormControl();
    this.contractPeriodFormControl = new UntypedFormControl(this.data.scaffold.ScaffoldSpecification.HirePeriod, Validators.min(0));
    this.adverseWeatherFormControl = new UntypedFormControl(this.data.scaffold.AdverseWeatherIndicator);

    this.groupScaffoldsFormControl = new UntypedFormControl(this.context.groupedScaffoldNumbers ?? false);
    this.customScaffoldNumberFormControl = new UntypedFormControl(
      this.context.customScaffoldNumber, this.editMode || !this.groupScaffoldsFormControl.value
      ? []
      : [Validators.required, scaffoldWithQuickIdMustNotExistValidator(this.scaffoldNumbers, this.context.scaffoldSuffix)]);
    this.suffixFormControl = new UntypedFormControl(this.context.scaffoldSuffix ?? this.quickId, this.editMode ? [] : [Validators.required, Validators.pattern(/^[A-Z0-9]{1,3}$/)]);

    this.scaffoldNumberForm = new UntypedFormGroup({
      groupScaffolds: this.groupScaffoldsFormControl,
      customScaffoldNumber: this.customScaffoldNumberFormControl,
      suffix: this.suffixFormControl,
      autoScaffoldNumber: this.autoScaffoldNumberFormControl
    });

    this.form = new UntypedFormGroup({
      scaffoldNumber: this.scaffoldNumberForm,
      name: this.nameFormControl,
      contractId: this.contractIdFormControl,
      areaId: this.areaIdFormControl,
      nextInspectionDate: this.nextInspectionFormControl,
      onHire: this.onHireFormControl,
      onHireDate: this.onHireDateFormControl,
      contractPeriod: this.contractPeriodFormControl,
      adverseWeather: this.adverseWeatherFormControl
    });

    const nextInspectionDate = this.data.scaffold.DateOfNextInspection;
    this.form.controls.nextInspectionDate.setValue(nextInspectionDate ? this.generateStartAndEndDate(nextInspectionDate) : undefined);
    const onHireDate = this.data.scaffold.OnHireDate;
    this.form.controls.onHireDate.setValue(onHireDate ? this.generateStartAndEndDate(onHireDate) : undefined);
    this.incrementalScaffoldNumbers.forEach(sn => { this.scaffoldNumbersList.push({ number: sn }) });

    this.groupScaffoldsFormControl.valueChanges.subscribe(areGrouped => {
      if (!areGrouped) {
        this.autoScaffoldNumberFormControl.setValue(this.context.autoScaffoldNumber);
        this.customScaffoldNumberFormControl.clearValidators();
        this.customScaffoldNumberFormControl.updateValueAndValidity();
        this.suffixFormControl.clearValidators();
        this.suffixFormControl.updateValueAndValidity();
      }

    });

    /// removes the validation for the suffix if the scaffold is not grouped
    if (!this.groupScaffoldsFormControl.value) {
      this.suffixFormControl.setValidators([]);
      this.suffixFormControl.updateValueAndValidity();
    }
  }

  /**
   * Creates a data range object for the date picker
   *
   * @private
   * @param {*} date
   * @returns {DatePickerSelectData}
   * @memberof AddEditScaffoldDetailComponent
   */
  private generateStartAndEndDate(date): DatePickerSelectData {
    const dateRange: DatePickerSelectData = { startDate: dayjs(date), endDate: dayjs(date) };
    return dateRange;
  }

  /**
   * Detects when the contract is changed
   *
   * @private
   * @param {string} contractId
   * @memberof AddEditScaffoldDetailComponent
   */
  private onSelectedContractChange(contractId: string): void {
    this.quotesQueryService
      .quotesDataChanges(contractId)
      .pipe(first())
      .subscribe(q => (this.quotes = q));
    this.form.controls.contractId.setValue(contractId);
  }

  /**
   * Detects when the next inspection is changed and applies that change to the form
   *
   * @param {*} dateRange selected date
   * @memberof AddEditScaffoldDetailComponent
   */
  public onNextInspectionDateChanged(dateRange: DatePickerSelectData) {
    const value = dateRange.startDate ? this.generateStartAndEndDate(dateRange.startDate) : undefined;
    this.form.controls.nextInspectionDate.setValue(value);
  }

  /**
   * Detects when the on hire date has changed and applies that change to the form
   *
   * @param {DatePickerSelectData} dateRange
   * @memberof AddEditScaffoldDetailComponent
   */
  public onHireDateChanged(dateRange: DatePickerSelectData): void {
    const value = dateRange.startDate ? this.generateStartAndEndDate(dateRange.startDate) : undefined;
    this.form.controls.onHireDate.setValue(value);
  }

  /**
 * Adds a quickId to the scaffoldNumber if it doesn't already contain one.
 * 
 * @param scaffoldNumber - The scaffold number to which the quickId will be added.
 * @param quickId - The quickId to be appended to the scaffoldNumber.
 * @returns The scaffoldNumber with the quickId appended, if quickId is provided. Otherwise, returns the scaffoldNumber as is.
 */
  private addQuickId(scaffoldNumber, quickId) {
    // Check if scaffoldNumber already contains a quickId
    if (scaffoldNumber.includes('-')) {
      return scaffoldNumber;
    }
    // If quickId is provided, append it to scaffoldNumber
    if (quickId) {
      return `${scaffoldNumber}-${quickId}`;
    }
    // If quickId is not provided, return scaffoldNumber as is
    return scaffoldNumber;
  }

  /**
   * Creates a new scaffold object
   *
   * @private
   * @returns {ScaffoldWebAppData}
   * @memberof AddEditScaffoldDetailComponent
   */
  private getNewScaffold(): ScaffoldWebAppData {
    const scaffoldNumbers = this.allScaffolds.map(s => s.ScaffoldNumber);
    const newScaffoldReference = this.addQuickId(this.referenceGeneratorService.incrementalReference(scaffoldNumbers, this.configuration.ScaffoldReferenceLength), this.quickId);
    this.context.autoScaffoldNumber = newScaffoldReference;
    return Object.assign(new ScaffoldWebAppData(), {
      ScaffoldId: UUID.UUID(),
      ScaffoldNumber: newScaffoldReference,
      SpecificLocationDetails: '',
      ContractId: this.openContracts.length ? this.openContracts[0].ContractId : '',
      SiteAreaId: this.activeAreas.length ? this.activeAreas[0].SiteAreaId : '',
      ScaffoldStatusId: scaffoldStatusIds.incomplete,
      AdverseWeatherIndicator: true,
      ScaffoldSpecification: {
        Description: '',
        ScaffoldTypeId: '',
        ScaffoldTypeFurtherInformation: '',
        QuoteNumber: '',
        QuoteItemNumber: '',
        DesignTypeId: '',
        DesignTypeFurtherInformation: '',
        LoadingLimitId: '',
        LoadingLimitFurtherInformation: '',
        MethodOfTyingId: '',
        MethodOfTyingFurtherInformation: '',
        MethodOfCladdingId: '',
        MethodOfCladdingFurtherInformation: ''
      }
    });
  }

  /**
   * Sets the context scaffold number based on the provided form values or default values.
   * If the scaffolds are grouped, the scaffold number, suffix, and scaffold reference are set in the context.
   * If the scaffolds are not grouped, the scaffold number, suffix, and scaffold reference are set to null in the context.
   * 
   * @param {any} formValues - The form values containing the scaffold number, suffix, and groupScaffolds flag.
   */
  private _saveParametersScaffoldNumber(formValues?: any) {
    this.context.groupedScaffoldNumbers = formValues ? formValues?.groupScaffolds : this.context.groupedScaffoldNumbers;
    var areGrouped = this.context.groupedScaffoldNumbers;
    this.context.customScaffoldNumber = formValues.customScaffoldNumber;
    this.context.autoScaffoldNumber = formValues.autoScaffoldNumber;
    this.context.scaffoldSuffix = areGrouped ? formValues.suffix : null;
  }

  /**
   * Determines the user-defined scaffold number based on the provided form values and context.
   * If the scaffolds are grouped, the user-defined scaffold number is constructed by combining the custom scaffold number and the suffix.
   * If the scaffolds are not grouped, the user-defined scaffold number is set to the auto scaffold number.
   * 
   * @param {any} formValues - The form values containing the suffix, custom scaffold number, and groupScaffolds flag.
   * @returns {string} - The user-defined scaffold number.
   */
  private _userDefinedScaffoldNumber(scaffoldNumberFormValues: any): string {
    let userDefinedScaffoldNumber: string;
    if (this.context.groupedScaffoldNumbers) {
      userDefinedScaffoldNumber = !scaffoldNumberFormValues.suffix ? this.context.customScaffoldNumber : `${this.context.customScaffoldNumber}-${scaffoldNumberFormValues.suffix}`;
    } else {
      userDefinedScaffoldNumber = this.context.autoScaffoldNumber;
    }
    return userDefinedScaffoldNumber;
  }

  /**
   * Opens the Scaffold Specification screen if all validation passes
   *
   * @param {*} formValues
   * @memberof AddEditScaffoldDetailComponent
   */
  public onNext(formValues: any): void {
    this._saveParametersScaffoldNumber(formValues.scaffoldNumber);
    if (this.validateForm()) {
      this.data.quotes = this.quotes;
      this.data.scaffold.ScaffoldNumber = this._userDefinedScaffoldNumber(formValues.scaffoldNumber);
      this.data.scaffold.SpecificLocationDetails = formValues.name;
      this.data.scaffold.ContractId = formValues.contractId;
      this.data.scaffold.SiteAreaId = formValues.areaId;
      this.data.scaffold.ScaffoldStatusId = formValues.onHire ? scaffoldStatusIds.onHire : this.data.scaffold.ScaffoldStatusId;

      const nextInspectionStartDate = formValues.nextInspectionDate && formValues.nextInspectionDate.startDate ? formValues.nextInspectionDate.startDate : null;
      const onHireStartDate = formValues.onHireDate && formValues.onHireDate.startDate ? formValues.onHireDate.startDate : null;
      this.data.scaffold.DateOfNextInspection = nextInspectionStartDate
        ? nextInspectionStartDate
          .utc()
          .add(moment(nextInspectionStartDate).utcOffset(), 'm')
          .toISOString()
        : undefined;
      this.data.scaffold.OnHireDate = onHireStartDate
        ? onHireStartDate
          .utc()
          .add(moment(onHireStartDate).utcOffset(), 'm')
          .toISOString()
        : undefined;
      this.data.scaffold.ScaffoldSpecification.HirePeriod = formValues.contractPeriod;
      this.data.scaffold.AdverseWeatherIndicator = formValues.adverseWeather;
      this.nextStep();
    }
  }

  /**
   * Checks if the scaffold status is incomplete
   *
   * @returns {boolean}
   * @memberof AddEditScaffoldDetailComponent
   */
  public scaffoldWasIncomplete(): boolean {
    return this.data.scaffold.ScaffoldStatusId === scaffoldStatusIds.incomplete;
  }

  /**
   * Detect when the selected area changes and sets it to the form
   *
   * @param {string} areaId
   * @memberof AddEditScaffoldDetailComponent
   */
  public onSelectedAreaChanged(areaId: string): void {
    this.form.controls.areaId.setValue(areaId);
  }


  /**
   * Required by the AutoUnsubscribe()
   *
   * @memberof AddEditScaffoldDetailComponent
   */
  public ngOnDestroy(): void { }

  /**
   * Sets the value of the customScaffoldNumber control to the selected scaffold number.
   * 
   * @param {any} scaffoldNumber - The selected scaffold number.
   */
  onSelectedNumber(scaffoldNumber) {
    this.scaffoldNumberForm.controls.customScaffoldNumber.setValue(scaffoldNumber);
  }
}

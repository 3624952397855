<form name="scaffoldDiaryMediaForm">
  <hub-modal-form-header title="Upload File" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <div class="file-uploader">
      <button class="file-input btn button-primary btn-file" [disabled]="saveInProgress">
        <i class="fa fa-search"></i> Browse&hellip;
        <input type="file" class="btn button-primary btn-file" accept="{{allowedFileFormats}}"
          (change)="onFileSelected($event)" [disabled]="saveInProgress" name="sizeRestrictedFile"
          [(ngModel)]="sizeRestrictedFile">
      </button>
      <span class="import-text" *ngIf="!file">No file selected</span>
      <span class="import-text" *ngIf="file">{{file.name}}</span>
    </div>
    <p *ngIf="fileErrorMessage" class="text-assertive">
      <i class="fa fa-warning"></i> {{fileErrorMessage}}
    </p>

    <p *ngIf="serverError" class="text-assertive">
      <i class="fa fa-warning"></i> {{serverError}}
    </p>

    <div class="modal-footer">
      <div class="modal-buttons">
        <button class="btn btn-large button-default" (click)="bsModalRef.hide()">
          <i class="fa fa-times"></i> Cancel </button>
        <button class="btn btn-large button-primary" type="button" (click)="onSubmit()"
          [disabled]="saveInProgress || lockSaveButton">
          <i [ngClass]="{'fa-spin': saveInProgress,'fa-spinner': saveInProgress, 'fa-check':!saveInProgress}"
            class="fa"></i> Save
        </button>
      </div>
    </div>
  </div>
</form>
/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { mapValues } from '../../../shared/values/maps';
import { errorMessages } from '../../../shared/values/error-messages';
import { emailAddressRegex } from 'app/shared/regex/email-address.regex';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterService } from 'angular2-toaster';
import { ConfigurationData, RolesData, StaffDetailsWebAppData, StaffData, DepotData, StaffCommandService, CreateStaffCommand, EditStaffCommand, CustomerCommandService, CustomerDetailWebAppData, CustomerWebAppData, CreateCustomerCommand, EditCustomerCommand } from 'app/core/hub-api';
import { FormComponent } from 'app/shared/components/form/form.component';
import { mustNotExistValidator } from 'app/shared/validators/must-not-exist.validator';
import { AddEditCustomerModalContext } from 'app/customers/models/add-edit-customer-modal-context';
import { CustomerDetailQueryService } from 'app/core/services/customer-detail-query.service';
import { CustomersQueryService } from 'app/core/services/customers-query.service';
import { ReferenceGeneratorService } from 'app/core/utility';
import { customerStatusIds } from 'app/shared/values/customer-status-ids';
import { CoordinatesEvent } from 'app/shared/models/coordinates-event';

/**
 * Add edit customer modal
 * Allows to add new customers to SMART Manager
 *
 * @export
 * @class AddEditCustomerModalComponent
 * @extends {FormComponent} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 * @implements {OnDestroy} Stops all subscriptions and closes the component
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-customer-modal',
  templateUrl: './add-edit-customer-modal.component.html',
  styleUrls: ['./add-edit-customer-modal.component.scss']
})
export class AddEditCustomerModalComponent extends FormComponent implements OnInit, OnDestroy {
  context: Partial<AddEditCustomerModalContext>;
  editMode: boolean;
  customers: CustomerWebAppData[];
  customer: CustomerDetailWebAppData;
  configuration: ConfigurationData;

  otherCustomerReferences: string[];

  form: UntypedFormGroup;
  customerNameFormControl: UntypedFormControl;
  customerReferenceFormControl: UntypedFormControl;
  customerAddressFormControl: UntypedFormControl;
  telephoneFormControl: UntypedFormControl;
  emailFormControl: UntypedFormControl;
  websiteFormControl: UntypedFormControl;

  validation: { hasOpenContracts: boolean };
  customerStatusIds = customerStatusIds;

  // The new value of the customer status id.
  customerStatusId: string;

  validationMessages = {
    customerName: {
      required: errorMessages.required
    },
    customerReference: {
      required: errorMessages.required,
      mustNotExist: errorMessages.customerReferenceMustBeUnique
    },
    email: {
      pattern: errorMessages.emailInvalid
    }
  };

  map: any;

  constructor(
    public referenceGeneratorService: ReferenceGeneratorService,
    public customerCommandService: CustomerCommandService,
    public customerDetailQueryService: CustomerDetailQueryService,
    public customersQueryService: CustomersQueryService,
    private toasterService: ToasterService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.editMode = this.editMode;
    this.customers = this.customers;
    this.configuration = this.configuration;

    this.otherCustomerReferences = this.customers
      .filter(c => !this.context.customer || c.CustomerId !== this.context.customer.CustomerId)
      .map(c => c.CustomerReference);
    this.customer = this.context.customer || this.getNewCustomer();

    this.map = {
      latitude: this.customer.Latitude || mapValues.defaultLatitude,
      longitude: this.customer.Longitude || mapValues.defaultLongitude,
      zoom: mapValues.defaultZoom
    };

    this.customerStatusId = this.customer.CustomerStatusId;
    this.validation = { hasOpenContracts: false };

    this.customerNameFormControl = new UntypedFormControl(this.customer.CustomerName, [Validators.required]);

    this.customerReferenceFormControl = new UntypedFormControl(this.customer.CustomerReference, [
      Validators.required,
      mustNotExistValidator(this.otherCustomerReferences)
    ]);
    this.customerAddressFormControl = new UntypedFormControl(this.customer.CustomerAddress);
    this.telephoneFormControl = new UntypedFormControl(this.customer.PhoneNumber);
    this.emailFormControl = new UntypedFormControl(this.customer.EmailAddress, Validators.pattern(emailAddressRegex));
    this.websiteFormControl = new UntypedFormControl(this.customer.Website);

    this.form = new UntypedFormGroup({
      customerName: this.customerNameFormControl,
      customerReference: this.customerReferenceFormControl,
      customerAddress: this.customerAddressFormControl,
      telephone: this.telephoneFormControl,
      email: this.emailFormControl,
      website: this.websiteFormControl
    });
    super.ngOnInit();
  }

  getNewCustomer(): CustomerDetailWebAppData {
    return Object.assign(new CustomerDetailWebAppData(), {
      CustomerId: UUID.UUID(),
      CustomerReference: this.referenceGeneratorService.incrementalReference(
        this.otherCustomerReferences,
        this.configuration.CustomerReferenceLength
      ),
      CustomerName: '',
      CustomerAddress: '',
      CustomerStatusId: customerStatusIds.active,
      EmailAddress: '',
      PhoneNumber: '',
      Website: ''
    });
  }

  /**
   * Sets the map marker for the given coordinates when they are moved
   *
   * @param {*} event
   * @memberof AddEditSiteModalComponent
   */
  onMarkerMoved(event: google.maps.MapMouseEvent): void {
    this.map.latitude = event.latLng.lat();
    this.map.longitude = event.latLng.lng();
  }

  onPlaceSelected(event: CoordinatesEvent): void {
    this.map.latitude = event.latitude;
    this.map.longitude = event.longitude;
  }

  delete(): void {
    this.validation.hasOpenContracts = this.customer.HasOpenContracts;

    if (!this.validation.hasOpenContracts) {
      this.customerStatusId = customerStatusIds.inactive;
    }
  }

  undelete(): void {
    this.customerStatusId = customerStatusIds.active;
  }

  onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.saveInProgress = true;

      const command: CreateCustomerCommand | EditCustomerCommand = {
        CustomerId: this.customer.CustomerId,
        CustomerName: formValues.customerName,
        CustomerReference: formValues.customerReference,
        CustomerStatusId: this.customerStatusId,
        CustomerImage: undefined,
        ApprovalExpires: undefined,
        Latitude: this.map.latitude,
        Longitude: this.map.longitude,
        CustomerAddress: formValues.customerAddress,
        EmailAddress: formValues.email,
        PhoneNumber: formValues.telephone,
        Website: formValues.website
      };

      const commandResult = this.editMode
        ? this.customerCommandService.EditCustomerCommand(command)
        : this.customerCommandService.CreateCustomerCommand(command);

      commandResult.subscribe(() => {
        const obs$ = forkJoin([
          this.customerDetailQueryService.customerDetailQuery(false, formValues.customerReference),
          this.customersQueryService.customersQuery(false)
        ]);

        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.modalService.setDismissReason(formValues.customerReference);
          this.bsModalRef.hide();
          this.toasterService.pop('success', `Customer ${this.editMode ? 'updated' : 'created'}`);
        });
      }, this.serverErrorCallback);
    }
  }

  /** Closes the modal */
  closeModal() {
    this.bsModalRef.hide();
  }

  /** Required by the AutoUnsubscribe */
  public ngOnDestroy(): void { }

}

import { Injectable } from '@angular/core';

@Injectable()
export class ArrayHelperService {

  constructor() { }

  // Move an item in an array from one index to another, returning a new array.
  move<T>(array: T[], oldIndex, newIndex): T[] {
    if (oldIndex >= 0 && oldIndex < array.length && newIndex >= 0 && newIndex < array.length) {
      const newDefectTypes = array.slice();
      newDefectTypes.splice(oldIndex, 1);
      newDefectTypes.splice(newIndex, 0, array[oldIndex]);
      return newDefectTypes;
    }
  }
}


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaffDetailsWebAppData, StaffEndpointService } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class StaffDetailQueryService extends QueryService<StaffDetailsWebAppData> {

  constructor(private staffEndpointService: StaffEndpointService) { super(); }

  staffDetailQuery(useCached: boolean, staffId: string): Observable<StaffDetailsWebAppData> {
    return super.query(useCached, staffId);
  }

  staffDetailDataChanges(staffId: string): Observable<StaffDetailsWebAppData> {
    return super.cachedDataChanges(staffId);
  }

   getDataFromServer(staffId: string): Observable<StaffDetailsWebAppData> {
    return this.staffEndpointService.StaffDetailsQuery({ StaffId: staffId }).pipe(map(data => data[0]));
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { combineLatest } from 'rxjs';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { sortBy } from 'lodash';
import { SelectWithInfoData } from '../../models/select-with-info-data';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

export const SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectWithInfoComponent),
  multi: true
};

/**
 *  A control with a select input and a text input fore select options which require further information.
 *
 * @export
 * @class SelectWithInfoComponent
 * @implements {ControlValueAccessor} Defines an interface that acts as a bridge between the Angular forms API and a native element in the DOM.
 * @implements {OnInit} A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
 * @implements {OnDestroy} A lifecycle hook that is called when a directive, pipe, or service is destroyed.
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-select-with-info',
  templateUrl: './select-with-info.component.html',
  styleUrls: ['./select-with-info.component.scss'],
  providers: [SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR]
})
export class SelectWithInfoComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /** Property ID */
  @Input()
  idProperty: string;
  /** Title Property */
  @Input()
  titleProperty: string;
  /** Checks if it will show the further info input */
  @Input()
  requiresInfoProperty: string;
  /** placeholder for the ng-select  */
  @Input()
  optionPlaceholder: string;
  /** Placeholder for the input */
  @Input()
  furtherInfoPlaceholder: string;
  /** All dropdown items */
  @Input()
  options: any[];
  /** Sorts by property name */
  @Input()
  sortByProperty: string; // Overrides the requiresInfoProperty if sorting is required but does not add an extra field
  /**
  * Displays the icon next to the item name on the dropdown items
  * @example
  * 'fa fa-building'
  */
  @Input() icon: string;
  /** Form group control */
  form: UntypedFormGroup;
  /** Id dropdown control */
  idFormControl: UntypedFormControl;
  /** further Information input controller */
  furtherInfoFormControl: UntypedFormControl;

  /** main variables to allow change propagation, required ControlValueAccessor variables */
  propagateChanges: (data: SelectWithInfoData) => {};
  propagateTouched: Function;
  /** Detects if it requires further information */
  requiresFurtherInfo: boolean;


  constructor() { }

  /**
   * Component initialisation
   *
   * @memberof SelectWithInfoComponent
   */
  ngOnInit(): void {
    this.options = sortBy(this.options, o =>
      [o[this.sortByProperty ? this.sortByProperty : this.requiresInfoProperty], o[this.titleProperty].toLowerCase()].join()
    );
    this.idFormControl = new UntypedFormControl('');
    this.furtherInfoFormControl = new UntypedFormControl('');
    this.form = new UntypedFormGroup({
      id: this.idFormControl,
      furtherInfo: this.furtherInfoFormControl
    });
    const obs$ = combineLatest([this.idFormControl.valueChanges, this.furtherInfoFormControl.valueChanges]);
    obs$.subscribe(latest => {
      const option = this.options.find(o => o[this.idProperty] === latest[0]);
      this.requiresFurtherInfo = option ? option[this.requiresInfoProperty] : false;
      if (this.propagateChanges) {
        this.propagateChanges({ id: latest[0], furtherInfo: latest[1] });
      }
    });
  }

  /**
   * Writes a new value to the element.
   * This method is called by the forms API to write to the view when programmatic changes from model to view are requested.
   *
   * @param {SelectWithInfoData} value
   * @memberof SelectWithInfoComponent
   */
  writeValue(value: SelectWithInfoData): void {
    if (this.idFormControl && this.furtherInfoFormControl) {
      this.idFormControl.setValue(value.id);
      this.furtherInfoFormControl.setValue(value.furtherInfo);
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * This method is called by the forms API on initialization to update the form model when values propagate from the view to the model.
   *
   * @param {*} fn
   * @memberof SelectWithInfoComponent
   */
  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization to update the form model on blur.
   * Gets called when the control is considered blurred or "touched".
   *
   * @param {*} fn
   * @memberof SelectWithInfoComponent
   */
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /**
   * @description
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
   * required to have this here due to ControlValueAccessor
   * @example
   * The following is an example of writing the disabled property to a native DOM element:
   *
   * @param {boolean} isDisabled
   * @memberof SelectWithInfoComponent
   */
  setDisabledState?(isDisabled: boolean): void { }

  /**
   * Required by AutoUnsubscribe()
   *
   * @memberof SelectWithInfoComponent
   */
  ngOnDestroy(): void { }
}

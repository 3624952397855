import { Component, OnInit } from '@angular/core';
import { OffHireScaffoldModalContext } from '../../../../models/off-hire-scaffold-modal-context';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { cloneDeep, map } from 'lodash';
import {
  SiteWebAppData,
  ScaffoldWebAppData,
  ContractContactData,
  OffHireTypeEnum,
  CreateOffhireWebConsoleCommand,
  CustomerWebAppData,
  OffHireCommandService
} from 'app/core/hub-api';
import { datePickerSettings } from '../../../../../shared/values/date-picker-settings';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { ScaffoldTimelineQueryService } from '../../../../services/scaffold-timeline-query-service';
import { ScaffoldDetailQueryService } from '../../../../services/scaffold-detail-query-service';
import { forkJoin } from 'rxjs';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { UtilityService } from '../../../../../core/services/utility-service';
import { HandoverHistoryQueryService } from '../../../../../core/services/handover-history-query-service';
import { contactTypeIds } from 'app/shared/values/contact-type-ids';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { dates, datesDayjs } from '../../../../../shared/values/dates';
import { UserIdentityService } from 'app/core/authentication';

@Component({
  selector: 'hub-off-hire-scaffold',
  templateUrl: './off-hire-scaffold-modal.component.html',
  styleUrls: ['./off-hire-scaffold-modal.component.scss']
})
export class OffHireScaffoldModalComponent extends FormComponent implements OnInit {
  context: Partial<OffHireScaffoldModalContext>;
  customer: CustomerWebAppData;
  offHireTypeEnum: typeof OffHireTypeEnum;
  activeContractContacts: ContractContactData[];
  site: SiteWebAppData;
  scaffold: ScaffoldWebAppData;
  datePickerOptions: any;
  offHireDateFormControl: UntypedFormControl;
  offHireTypeFormControl: UntypedFormControl;
  requestedByFormControl: UntypedFormControl;
  commentsFormControl: UntypedFormControl;
  form: UntypedFormGroup;
  validationMessages = {
    offHireDate: {
      required: errorMessages.required
    },
    offHireType: {
      required: errorMessages.required
    },
    requestedBy: {
      required: errorMessages.required
    }
  };

  constructor(
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private userIdentityService: UserIdentityService,
    private offHireCommandService: OffHireCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private siteDetailQueryService: SiteDetailQueryService,
    private scaffoldTimelineQueryService: ScaffoldTimelineQueryService,
    private scaffoldDetailQueryService: ScaffoldDetailQueryService,
    private handoverHistoryQueryService: HandoverHistoryQueryService,
    private utilityService: UtilityService,
    public modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.site = this.context.site;
    this.scaffold = this.context.scaffold;
    this.customer = this.context.customer;

    const contract = cloneDeep(this.site.Contracts.find(c => c.ContractId === this.scaffold.ContractId));
    this.activeContractContacts = map(contract.ContractContacts).filter(c => !c.IsInactive &&
      (c.ContactTypeId === contactTypeIds.siteContact ||
        c.ContactTypeId === contactTypeIds.officeContact));

    const onHireDate = this.scaffold.OnHireDate;

    this.datePickerOptions = {
      alwaysShowCalendars: false,
      ranges: {},
      showCustomRangeLabel: false,
      disableUntil: {
        year: moment(onHireDate).year(),
        month: moment(onHireDate).month() + 1,
        day: moment(onHireDate).date() - 1
      }
    };
    const dates = {
      startDate: datesDayjs.now,
    };

    this.offHireTypeEnum = OffHireTypeEnum;
    this.offHireDateFormControl = new UntypedFormControl(dates, Validators.required);
    this.offHireTypeFormControl = new UntypedFormControl(this.offHireTypeEnum.Full, Validators.required);
    this.requestedByFormControl = new UntypedFormControl(
      this.activeContractContacts.length ? this.activeContractContacts[0].ContractContactId : '',
      Validators.required
    );
    this.commentsFormControl = new UntypedFormControl();

    this.form = new UntypedFormGroup({
      offHireDate: this.offHireDateFormControl,
      offHireType: this.offHireTypeFormControl,
      requestedBy: this.requestedByFormControl,
      comments: this.commentsFormControl
    });

    this.offHireDateFormControl.setValue(dates.startDate);
    super.ngOnInit();
  }

  /**
   * Detects the selected date range
   *
   * @param {*} dateRange date range selected by the user
   * @memberof ApplicationForPaymentFiltersComponent
   */
  public onDateRangeSelected(dateRange: any): void {
    this.offHireDateFormControl.setValue(dateRange.startDate);
  }

  onSubmit(): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const command: CreateOffhireWebConsoleCommand = {
        OffHireId: UUID.UUID(),
        SiteId: this.site.SiteId,
        CustomerId: this.customer.CustomerId,
        ScaffoldId: this.scaffold.ScaffoldId,
        OffhiredOn: this.form.value.offHireDate.toISOString(),
        SubmittedBy: this.userIdentityService.currentUser().userId,
        RequestedBy: this.form.value.requestedBy,
        Comments: this.form.value.comments,
        OffHireType: this.form.value.offHireType,
        OffsetFromUtc: moment().utcOffset()
      };

      this.offHireCommandService.CreateOffhireWebConsoleCommand(command).subscribe(() => {
        const obs$ = forkJoin([
          this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId),
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
          this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.scaffold.ScaffoldId),
          this.scaffoldTimelineQueryService.scaffoldTimelineQuery(false, this.scaffold.ScaffoldId),
          this.handoverHistoryQueryService.handoverHistoryQuery(false, [this.site.SiteId])
        ]);
        obs$.subscribe(() => {
          this.saveInProgress = false;
          this.bsModalService.setDismissReason(this.scaffold.ScaffoldId);
          this.bsModalRef.hide();
        });
      }, this.serverErrorCallback);
    }
  }
}

/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { forkJoin } from 'rxjs';
import { map } from 'lodash';
import {
  ConfigurationData,
  ContractData,
  CustomerWebAppData,
  SiteWebAppData,
  ContractTypeData
} from 'app/core/hub-api';
import { ContractCommandService, CreateContractCommand, EditContractCommand, InspectionStatusEnumEnum } from 'app/core/hub-api';
import { CustomerDetailQueryService } from '../../../../../core/services/customer-detail-query.service';
import { mustNotExistValidator } from '../../../../../shared/validators/must-not-exist.validator';
import { contractStatusIds } from '../../../../../shared/values/contract-status-ids';
import { errorMessages } from '../../../../../shared/values/error-messages';
import { AddEditContractModalContext } from '../../../../models/add-edit-contract-modal-context';
import { ContractInspectionStatusQueryService } from '../../../../services/contract-inspection-status-query.service';
import { AddEditCustomerModalContext } from '../../../../../customers/models/add-edit-customer-modal-context';
import { AddEditCustomerModalComponent } from '../../../../../customers/components/add-edit-customer-modal/add-edit-customer-modal.component';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { customerStatusIds } from '../../../../../shared/values/customer-status-ids';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';
import { ReferenceGeneratorService } from 'app/core/utility';

/**
 * Add Edit Contract Modal
 * Allows users to create or edit contracts
 *
 * @export
 * @class AddEditContractModalComponent
 * @extends {ModalFormComponent<AddEditContractModalContext>} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 * @implements {OnDestroy} Stops all subscriptions and closes the component
 */
@AutoUnsubscribe()
@Component({
  selector: 'hub-add-edit-contract-modal',
  templateUrl: './add-edit-contract-modal.component.html',
  styleUrls: ['./add-edit-contract-modal.component.scss']
})
export class AddEditContractModalComponent extends FormComponent implements OnInit, OnDestroy {
  /** Stores the parameters for this modal */
  context: Partial<AddEditContractModalContext>;
  /** Detects if the form is edit or add */
  editMode: boolean;
  /** Stores the selected site */
  site: SiteWebAppData;
  /** Stores all customers */
  allCustomers: CustomerWebAppData[];
  /** Stores all filtered customers */
  filteredCustomers: CustomerWebAppData[];
  /** Stores the selected customer */
  customer: CustomerWebAppData;
  /** Stores the selected contract */
  contract: ContractData;
  /** Contract name input control */
  existingMainContractor: any;
  inspectionStatusEnum: any;
  configuration: ConfigurationData;
  contractTypes: ContractTypeData[];
  /** Form group control */
  form: UntypedFormGroup;
  /** Contract name input control */
  contractNameFormControl: UntypedFormControl;
  /** Contract description input control */
  contractDescriptionFormControl: UntypedFormControl;
  /** Contract type dropdown control */
  contractTypeFormControl: UntypedFormControl;
  /** Customer form control */
  customerFormControl: UntypedFormControl;
  /** Contract reference input control */
  contractReferenceFormControl: UntypedFormControl;
  /** Main contractor radio control */
  mainContractorFormControl: UntypedFormControl;
  /** Submit without signature radio control */
  submitWithoutSignatureFormControl: UntypedFormControl;
  /** Agreed schedule of rates radio control */
  agreedScheduleOfRatesFormControl: UntypedFormControl;
  /** Inspection Status radio control */
  inspectionStatusFormControl: UntypedFormControl;
  /** Cameras allowed radio control */
  camerasAllowedFormControl: UntypedFormControl;
  /** Dismantle without offhire radio control */
  dismantleWithoutOffhireFormControl: UntypedFormControl;
  /** Form validation for all required fields */
  validationMessages = {
    contractName: {
      required: errorMessages.required
    },
    customer: {
      required: errorMessages.required
    },
    contractReference: {
      required: errorMessages.required,
      mustNotExist: errorMessages.contractReferenceMustBeUnique
    },
    mainContractor: {
      required: errorMessages.required,
      mustNotExist: errorMessages.oneMainContractor
    },
    submitWithoutSignature: {
      required: errorMessages.required
    },
    agreedScheduleOfRates: {
      required: errorMessages.required
    },
    inspectionStatusFormControl: {
      required: errorMessages.required
    }
  };

  constructor(
    public bsModalRef: BsModalRef,
    private referenceGeneratorService: ReferenceGeneratorService,
    private contractCommandService: ContractCommandService,
    private siteDetailQueryService: SiteDetailQueryService,
    private contractInspectionStatusQueryService: ContractInspectionStatusQueryService,
    private customerDetailQueryService: CustomerDetailQueryService,
    public modalService: BsModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Component Initialises
   *
   * @memberof AddEditContractModalComponent
   */
  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.inspectionStatusEnum = InspectionStatusEnumEnum;

    this.editMode = this.context.editMode;
    this.site = this.context.site;
    this.configuration = this.context.configuration;
    this.contract = this.context.contract || this.getNewContract();
    this.allCustomers = this.context.customers;
    this.filteredCustomers = this.allCustomers
      .filter(customer => customer.CustomerId === this.contract.CustomerId || customer.CustomerStatusId === customerStatusIds.active);
    this.customer = this.customer ? this.customer : this.filteredCustomers.find(customer => customer.CustomerId === this.contract.CustomerId);

    const _contractTypes = map(this.context.configuration.ContractTypes);
    // creates and adds an empty field so user can select 'None'
    const emptyContractType = new ContractTypeData();
    emptyContractType.Title = 'None';
    emptyContractType.ContractTypeId = '';
    _contractTypes.unshift(emptyContractType);
    this.contractTypes = _contractTypes;

    const contractCustomer = this.filteredCustomers.find(customer => customer.CustomerId === this.contract.CustomerId);
    const contractRefs = this.site.Contracts.filter(c => c.ContractId !== this.contract.ContractId).map(c => c.ContractReference);

    this.generateForm(contractCustomer, contractRefs);

    this.initialiseExistingMainContractor();
    super.ngOnInit();
  }

  /**
   * Generates the add or edit contract form and applies all values and validation
   *
   * @private
   * @param {CustomerWebAppData} contractCustomer
   * @param {string[]} contractRefs
   * @memberof AddEditContractModalComponent
   */
  private generateForm(contractCustomer: CustomerWebAppData, contractRefs: string[]): void {
    this.customerFormControl = new UntypedFormControl(contractCustomer ? contractCustomer.CustomerId : null, Validators.required);
    this.contractReferenceFormControl = new UntypedFormControl(this.contract.ContractReference, [Validators.required, mustNotExistValidator(contractRefs)]);

    this.contractNameFormControl = new UntypedFormControl(this.contract.ContractName || '', [Validators.required]);
    this.contractDescriptionFormControl = new UntypedFormControl(this.contract.Description || '');
    this.contractTypeFormControl = new UntypedFormControl(this.contract.ContractTypeId || '');

    this.mainContractorFormControl = new UntypedFormControl(this.contract.IsPrincipalContract);
    this.submitWithoutSignatureFormControl = new UntypedFormControl(this.contract.CanSubmitDocumentsWithoutSignature);
    this.agreedScheduleOfRatesFormControl = new UntypedFormControl(this.contract.IsScheduleRatesAgreed);
    this.inspectionStatusFormControl = new UntypedFormControl(this.contract.InspectionStatus.State);
    this.camerasAllowedFormControl = new UntypedFormControl(this.contract.IsCameraAllowed);
    this.dismantleWithoutOffhireFormControl = new UntypedFormControl(this.contract.AllowDismantleWithoutOffhire);
    this.form = new UntypedFormGroup({
      contractName: this.contractNameFormControl,
      contractDescription: this.contractDescriptionFormControl,
      contractType: this.contractTypeFormControl,
      customer: this.customerFormControl,
      contractReference: this.contractReferenceFormControl,
      mainContractor: this.mainContractorFormControl,
      submitWithoutSignature: this.submitWithoutSignatureFormControl,
      agreedScheduleOfRates: this.agreedScheduleOfRatesFormControl,
      inspectionStatus: this.inspectionStatusFormControl,
      camerasAllowed: this.camerasAllowedFormControl,
      dismantleWithoutOffhire: this.dismantleWithoutOffhireFormControl
    });
  }

  /**
   * Checks if there is a main contractor for the current contract
   * this function is public so it can be tested
   *
   * @memberof AddEditContractModalComponent
   */
  public initialiseExistingMainContractor(): void {
    const existingMainContract = this.site.Contracts.find(c => c.ContractId !== this.contract.ContractId && c.IsPrincipalContract);
    if (existingMainContract) {
      this.existingMainContractor = {
        contractReference: existingMainContract.ContractReference,
        customerName: this.allCustomers.find(c => c.CustomerId === existingMainContract.CustomerId).CustomerName
      };
    }
  }

  /**
   * Creates a mew contract object
   *
   * @private
   * @returns {ContractData}
   * @memberof AddEditContractModalComponent
   */
  private getNewContract(): ContractData {
    const contractReferences = this.site.Contracts.map(c => c.ContractReference);
    return Object.assign(new ContractData(), {
      ContractId: UUID.UUID(),
      ContractReference: this.referenceGeneratorService.incrementalReference(contractReferences, this.configuration.ContractReferenceLength),
      IsPrincipalContract: false,
      CanSubmitDocumentsWithoutSignature: false,
      IsScheduleRatesAgreed: false,
      InspectionStatus: {
        State: InspectionStatusEnumEnum.Normal
      },
      IsCameraAllowed: true,
      AllowDismantleWithoutOffhire: false
    });
  }

  /**
   * Submits the edited or new contract to the api
   *
   * @param {*} formValues all form values
   * @memberof AddEditContractModalComponent
   */
  public onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.saveInProgress = true;

      const command: EditContractCommand | CreateContractCommand = {
        ContractId: this.contract.ContractId,
        ContractReference: formValues.contractReference,
        CustomerId: this.customer.CustomerId,
        ContractStatusId: contractStatusIds.open,
        SiteId: this.site.SiteId,
        IsPrincipalContract: formValues.mainContractor,
        IsCustomerNotPresent: formValues.submitWithoutSignature,
        CreationDate: undefined,
        IsScheduleRatesAgreed: formValues.agreedScheduleOfRates,
        CanSubmitDocumentsWithoutSignature: formValues.submitWithoutSignature,
        ContractInspectionStatus: formValues.inspectionStatus,
        ContractName: formValues.contractName,
        Description: formValues.contractDescription,
        ContractTypeId: formValues.contractType,
        IsCameraAllowed: formValues.camerasAllowed,
        AllowDismantleWithoutOffhire: formValues.dismantleWithoutOffhire
      };

      if (this.editMode) {
        this.contractCommandService.EditContractCommand.call(this.contractCommandService, command).subscribe(() => {
          // Here we must reload any data that this edit affects from the server.
          this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference).subscribe((site) => {
            this.saveInProgress = false;
            this.modalService.setDismissReason(this.customer.CustomerReference);
            this.bsModalRef.hide();
          });
        }, this.serverErrorCallback);
      } else {
        this.contractCommandService.CreateContractCommand.call(this.contractCommandService, command).subscribe(() => {
          // Here we must reload any data that this add affects from the server.
          const obs$ = forkJoin([
            this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteReference),
            this.contractInspectionStatusQueryService.contractInspectionStatusQuery(false, this.site.SiteId),
            this.customerDetailQueryService.customerDetailQuery(false, this.customer.CustomerReference)
          ]);
          obs$.subscribe(() => {
            this.saveInProgress = false;
            this.modalService.setDismissReason(this.customer.CustomerReference);
            this.bsModalRef.hide();
          });
        }, this.serverErrorCallback);
      }
    }
  }


  /**
   * Detects if user is creating a new customer and displays the add customer form when
   * Add button is actioned
   *
   * @memberof AddEditContractModalComponent
   */
  public onAddCustomer(): void {
    const context: AddEditCustomerModalContext = {
      editMode: false,
      customers: this.allCustomers,
      configuration: this.configuration
    };
    const modal = this.modalService.show(AddEditCustomerModalComponent, SSModalConfig.generate(context));
    modal.onHidden.subscribe(
      customerReference => {
        if (customerReference && typeof customerReference === 'string') {
          this.customerDetailQueryService.customerDetailQuery(false, customerReference).subscribe(customer => {
            this.customer = customer;
            this.filteredCustomers = [...this.filteredCustomers, customer];
            this.changeDetectorRef.detectChanges();
            this.form.controls.customer.setValue(customer.CustomerId)
          });
        }
      },
      error => {
        console.log(error);
      },
      () => { }
    );
  }

  /**
   * Blurs the following fields on the UI
   * * Contract Description
   * * Contract Reference
   * * Contract Name
   *
   * @param {*} event
   * @memberof AddEditContractModalComponent
   */
  public blurReadOnly(event: any): void {
    if (event.target.readOnly) {
      event.target.blur();
    }
  }

  /**
   * When the user clicks on a typeahead customer result it will select that customer
   *
   * @param {CustomerWebAppData} customerId
   * @memberof AddEditContractModalComponent
   */
  public onCustomerSelected(customerId: string): void {
    this.customer = this.allCustomers.find(c => c.CustomerId === customerId);
    this.form.controls.customer.setValue(customerId);
  }

  /**
   * Detect when the selected contract type changes and sets it to the form
   *
   * @param {string} contractTypeId
   * @memberof AddEditContractModalComponent
   */
  public onSelectedContractTypeChanged(contractTypeId: string): void {
    this.form.controls.contractType.setValue(contractTypeId);
  }

  /**
   * Required by AutoUnsubscribe()
   *
   * @memberof AddEditContractModalComponent
   */
  ngOnDestroy(): void { }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationData, SiteDashBoardScaffoldInspectionData } from 'app/core/hub-api';

import { DashboardQueryService } from '../services/dashboard-query.service';

@Injectable()
export class ScaffoldInspectionResolver implements Resolve<SiteDashBoardScaffoldInspectionData[]> {

  constructor(private dashboardQueryService: DashboardQueryService) { }

  resolve(): Observable<SiteDashBoardScaffoldInspectionData[]> {
    return this.dashboardQueryService.getInspectionData();
  }
}

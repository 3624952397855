// Copyright © Computer and Design Services Ltd.
// All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
// electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.

import { combineLatest } from 'rxjs';
import {
    AfterViewChecked, ChangeDetectionStrategy,
    ChangeDetectorRef, Component, EventEmitter, forwardRef,
    Input, OnDestroy, OnInit, Output, OnChanges, ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { SelectWithInfoData } from '../../models/select-with-info-data';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { isString as _isString, isNumber as _isNumber } from 'lodash';
import { DropdownSettings } from './dropdown-settings';
import { DropdownTexts } from './dropdown-texts';
import { NgSelectComponent } from '@ng-select/ng-select';
export const SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
};

/**
 * Dropdown single or multiselect component
 * ```html
 *  <hub-dropdown formControlName="sites" [items]="sites" idProperty="SiteId" titleProperty="SiteName"
 *  icon="fa fa-building" [multiselect]="true" (valueChanges)="onSelectedSiteChanged($event)"> </hub-dropdown>
 * ```
 * @export
 * @class DropdownComponent
 * @implements {ControlValueAccessor}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@AutoUnsubscribe()
@Component({
    selector: 'hub-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [SELECT_WITH_INPUT_COMPONENT_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.Default
})

export class DropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewChecked, OnChanges {
    /** The default number of items to show when [multiselect] is active */
    _defaultItemsToDisplay = 1;
    /**
    * Should be the Id of the item you want to list
    * @example
    * If you want to list sites you send 'SiteId'
    */
    @Input() idProperty: string;
    /**
    * Displays the icon next to the item name on the dropdown items
    * @example
    * 'fa fa-building'
    */
    @Input() icon: string;
    /**
    * Should be the Title you want to display
    * @example
    * If you want to list sites you send 'SiteName'
    */
    @Input() titleProperty: string;
    /**
    * Should be an extension of the Title you want to display
    * @example
    * If you want to list sites with a site number in front of it you send 'SiteReference'
    */
    @Input() titleReferenceProperty: string;
    /**
    * Will display a placeholder on the dropdown when no item is selected
    * @example
    * you can send any string here
    * @default 'Please select an option'
    */
    @Input() placeholder = 'Please select an option';
    /**
    * A list of all options to be displayed
    * @example
    * e.g. You can send an array of sites
    */
    @Input() items: any[];
    /**
    * allows the dropdown to multiselect items
    * @default false - single select
    */
    @Input() multiselect = false;
    /**
    * Configuration for the number of items to show when [multiselect] is active
    * (e.g. if the value is 1 the dropdown text will be: Name, 3 more..)
    * @default this._defaultItemsToDisplay
    */
    @Input()
    itemsToDisplay: number = this._defaultItemsToDisplay;
    /**
    * Close the menu when a value is selected
    * @default false
    */
    @Input() closeOnSelect = false;
    /**
    * Allow to clear selected value.
    * @default true
    */
    @Input() clearable = true;
    /**
    * Detects if the dropdown is loading.
    * @default false
    */
    @Input() loading = false;
    /**
    * Displays the icon next to the group name on the dropdown items
    * @example
    * 'fa fa-users'
    */
    @Input() groupIcon: string | null = null;
    /**
    * Groups by property
    * @default null
    */
    @Input() groupBy: string | null = null;
    /**
    * Displays the icon next to the description name on the dropdown items
    * @example
    * 'fa fa-building'
    */
    @Input() descriptionIcon: string | null = null;
    /** Displays the description next to the item on the dropdown items */
    @Input() description: string | null = null;
    /** Selecting group will select all of its children but not group itself if deactivated */
    @Input() selectableGroupAsModel = false;
    /** Enables/Disables Selecting groups */
    @Input() selectableGroup = false;
    // Overrides the placeholder texts
    @Input() texts: DropdownTexts;
    // Overrides all settings
    @Input() settings: DropdownSettings;
    // Allow to search for value. Default true
    @Input() searchable = true;
    // Clears search input when item is selected. Default true. Default false when closeOnSelect is false
    @Input() clearSearchOnAdd = false;
    /** Set custom text when filter returns empty result */
    @Input() searchEmptyResultText?= 'No results found';
    /** Sets the text for the clear button */
    @Input() uncheckAllText = 'Clear All';
    /** Sets the text for the select all button */
    @Input() checkAllText = 'Select All';
    /** Sites the text for the all selected label */
    @Input() allSelectedText = 'All Selected';
    /** Enables the clear option (x icon next to the label)*/
    @Input() clearItemOption = false;
    /** Enables/Disables the user to access the DOM on the dropdown component */
    @Input() readonly?= false;
    /** Form that controls this component */
    form: UntypedFormGroup;
    // Form control for the ng-select */
    idFormControl: UntypedFormControl;
    /** main variables to allow change propagation
    required ControlValueAccessor variables */
    _propagateChanges: Function;
    _propagateTouched: Function;
    /** Allows to create custom options. */
    addTag?: boolean | ((term: string) => any | Promise<any>) = false;
    /** Set custom text when using tagging */
    addTagText: string | null = null;
    /**
    * Creates an instance of this class that can deliver events synchronously or asynchronously.
    * @example
    * ```html
    * <hub-dropdown ... (valueChanges)="onSelectedSiteChanged($event)"> </hub-dropdown>
    * ```
    */
    /* eslint-disable-next-line @angular-eslint/no-output-native */
    @Output() valueChanges = new EventEmitter<Object>();
    /**
     * Triggers the focus from the dropdown to the component
     * @example
     * ```html
     * <hub-dropdown ... (focus)="onFocus($event)"> </hub-dropdown>
     * ```
     */
    /* eslint-disable-next-line @angular-eslint/no-output-native */
    @Output() focus = new EventEmitter<Object>();
    /** The ngSelect component is used to get the selected value from the DOM */
    @ViewChild('dropdownComponent') ngSelect: NgSelectComponent;

    constructor(private cdr: ChangeDetectorRef) { }

    /**
     * Detects the changes to the list and sets an empty search term if the list is empty,
     * In case there are items it will set the first item in the list
     * Only gets activated if the [refreshTitleActivated] is true
     *
     * @memberof DropdownComponent
     */
    ngOnChanges(): void {
        if (!this.ngSelect) return;
        const searchedTerm = this.ngSelect.searchTerm;
        if (searchedTerm != null && searchedTerm != "") {
            var searchTermMatchesList = this.items.filter(x => x[this.titleProperty] === searchedTerm);
            var filteredItems = this.items.filter(x => x[this.titleProperty] !== searchedTerm);
            if (searchTermMatchesList.length == 0) {
                if (filteredItems.length > 0) {
                    this.ngSelect.searchTerm = filteredItems[0][this.titleProperty];
                }
            }
        }
    }


    /**
     * Creates the form and subscribes to the changes made to the ng-select
     *
     * @memberof DropdownComponent
     */
    ngOnInit(): void {
        this.textOverrides();
        this.settingsOverrides();
        this._defaultItemsToDisplay = this.itemsToDisplay != null ? this.itemsToDisplay : this._defaultItemsToDisplay;
        this.idFormControl = new UntypedFormControl('');
        this.form = new UntypedFormGroup({
            id: this.idFormControl,
        });
        const obs$ = combineLatest([this.idFormControl.valueChanges]);
        obs$.subscribe(latest => {
            if (this._propagateChanges) this._propagateChanges(latest[0]);
            this.idFormControl.setValue(latest[0], { emitEvent: true });
        });
    }

    /**
     * Overrides the texts of the dropdown
     *
     * @private
     * @memberof DropdownComponent
     */
    private textOverrides(): void {
        if (this.texts) {
            if (this.texts.defaultTitle) this.placeholder = this.texts.defaultTitle;
            if (this.texts.checkAll) this.checkAllText = this.texts.checkAll;
            if (this.texts.uncheckAll) this.uncheckAllText = this.texts.uncheckAll;
            if (this.texts.allSelected) this.allSelectedText = this.texts.allSelected;
            if (this.texts.searchEmptyResult) this.searchEmptyResultText = this.texts.searchEmptyResultText;
        }
    }
    /**
     * Overrides the settings of the dropdown
     *
     * @private
     * @memberof DropdownComponent
     */
    private settingsOverrides(): void {
        if (this.settings) {
            if (this.settings.idProperty) this.idProperty = this.settings.idProperty;
            if (this.settings.icon) this.icon = this.settings.icon;
            if (this.settings.titleProperty) this.titleProperty = this.settings.titleProperty;
            if (this.settings.titleReferenceProperty) this.titleReferenceProperty = this.settings.titleReferenceProperty;
            if (this.settings.placeholder) this.placeholder = this.settings.placeholder;
            if (this.settings.items) this.items = this.settings.items;
            if (this.settings.multiselect != null) this.multiselect = this.settings.multiselect;
            if (this.settings.itemsToDisplay) this.itemsToDisplay = this.settings.itemsToDisplay;
            if (this.settings.closeOnSelect != null) this.closeOnSelect = this.settings.closeOnSelect;
            if (this.settings.clearable != null) this.clearable = this.settings.clearable;
            if (this.settings.loading != null) this.loading = this.settings.loading;
            if (this.settings.groupIcon) this.groupIcon = this.settings.groupIcon;
            if (this.settings.groupBy) this.groupBy = this.settings.groupBy;
            if (this.settings.descriptionIcon) this.descriptionIcon = this.settings.descriptionIcon;
            if (this.settings.description) this.description = this.settings.description;
            if (this.settings.selectableGroupAsModel != null) this.selectableGroupAsModel = this.settings.selectableGroupAsModel;
            if (this.settings.selectableGroup != null) this.selectableGroup = this.settings.selectableGroup;
            if (this.settings.texts) this.texts = this.settings.texts;
            if (this.settings.searchable != null) this.searchable = this.settings.searchable;
            if (this.settings.clearSearchOnAdd != null) this.clearSearchOnAdd = this.settings.clearSearchOnAdd;
            if (this.settings.searchEmptyResultText) this.searchEmptyResultText = this.settings.searchEmptyResultText;
            if (this.settings.uncheckAllText) this.uncheckAllText = this.settings.uncheckAllText;
            if (this.settings.checkAllText) this.checkAllText = this.settings.checkAllText;
            if (this.settings.allSelectedText) this.allSelectedText = this.settings.allSelectedText;
            if (this.settings.clearItemOption != null) this.clearItemOption = this.settings.clearItemOption;
            if (this.settings.readonly != false) this.readonly = this.settings.readonly;
            if (this.settings.addTag != false) this.addTag = this.settings.addTag;
            if (this.settings.addTagText != null) this.addTagText = this.settings.addTagText;
        }
    }

    /**
     *  Callback method that is invoked immediately after the default change detector has completed one change-check cycle
     *
     * @memberof DropdownComponent
     */
    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    /**
     * Detects the changes on the ng-select component and propagates the changes through the output
     *
     * @param {*} event
     * @memberof DropdownComponent
     */
    public onChange(selectedValues): void {
        let values: any = this.multiselect ? [] : null;
        if (selectedValues && !this.multiselect) values = selectedValues[this.idProperty];
        if (selectedValues && this.multiselect) {

            values = selectedValues.map(v => {
                // The selected values must have an ID, that ID can be either a string or a number
                if (_isString(v) || _isNumber(v)) return v;
                // In the case where it does not match the previous it will send the property value
                return v[this.idProperty];
            });
        }

        this.valueChanges.emit(values);
    }

    /**
     * Triggered by the focus on the input, emits the focus values to an output
     *
     * @param {*} values
     * @memberof DropdownComponent
     */
    public onFocus(values): void {
        this.focus.emit(values);
    }

    /**
     * Writes a new value to the element.
     * This method is called by the forms API to write to the view when programmatic changes from model to view are requested.
     *
     * @param {SelectWithInfoData} value
     * @memberof DropdownComponent
     */
    writeValue(value: SelectWithInfoData): void {
        this.idFormControl.setValue(value);
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * This method is called by the forms API on initialization to update the form model when values propagate from the view to the model.
     *
     * @param {*} fn
     * @memberof DropdownComponent
     */
    registerOnChange(fn: any): void {
        if (this._propagateChanges) this._propagateChanges = fn;
    }

    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * Gets called when the control is considered blurred or "touched".
     *
     * @param {*} fn
     * @memberof DropdownComponent
     */
    registerOnTouched(fn: any): void {
        this._propagateTouched = fn;
    }

    /**
     * @description
     * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     * required to have this here due to ControlValueAccessor
     * @example
     * The following is an example of writing the disabled property to a native DOM element:
     *
     * @param {boolean} isDisabled
     * @memberof DropdownComponent
     */
    setDisabledState?(isDisabled: boolean): void { }

    /**
     * Selects all items on the dropdown
     *
     * @memberof DropdownComponent
     */
    onSelectAll(): void {
        const selected = this.items.map(item => item[this.idProperty]);
        this.idFormControl.setValue(selected);
        this.onChange(selected);
    }

    /**
     * Clears all items on the dropdown
     *
     * @memberof DropdownComponent
     */
    onClearAll(): void {
      this.idFormControl.setValue(null);
      this.onChange('');
    }

    /**
     * Generates the text for the dropdown list depending how many are selected
     * 1 selected - Will display the item name
     * +1 selected - will display the Item name +X many selected
     *
     * @returns {string}
     * @memberof DropdownComponent
     */
    getLengthSelected(): string {
        const formValues = this.form.get('id')!.value;
        if (formValues.length - this.itemsToDisplay === 0) return '';
        if (formValues.length === this.items.length) {
            this.itemsToDisplay = 0;
            return this.allSelectedText;
        }
        this.itemsToDisplay = this._defaultItemsToDisplay;
        return `+ ${(formValues.length - this.itemsToDisplay).toString()}`;
    }

    /**
     * Clears the selected value
     * Usage:
     * ```html
     *  <hub-dropdown #siteDropdown> </hub-dropdown>
     * ```
     * ```typescript
     * @ViewChild('siteDropdown') siteDropdown: DropdownComponent;
     * this.siteDropdown.clearSelectedValue();
     * ```
     *
     * @memberof DropdownComponent
     */
    clearSelectedValue(): void {
        this.ngSelect.searchTerm = "";
        this.onClearAll();
    }

    ngOnDestroy(): void { }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApplicationSettingsQueryService } from 'app/core/services/application-settings-query.service';
import { Observable, of, pipe } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { AuthResponseError, AuthResponseErrorType, AuthenticationEventsService, OnlineSessionService } from '../authentication';

@Injectable()
export class AuthenticationSetupGuard implements CanActivate {
  constructor(
    private authEventsService: AuthenticationEventsService,
    private router: Router,
    private applicationSettingsQueryService: ApplicationSettingsQueryService,
    private onlineSessionService: OnlineSessionService
  ) { }

  canActivate(): Observable<any> {
    // Handler to respond to the login required event.
    this.authEventsService.loginRequiredEvent.subscribe(event => {
      this.onlineSessionService.login(true);
    });

    // Initialise the login service.
    const appSettingsQuery = this.applicationSettingsQueryService.applicationSettingsQuery(true);
    return appSettingsQuery.pipe(      
      pipe(
        mergeMap(() => this.onlineSessionService.initialise()),
      ),
      map(() => true),
      catchError(error => {
        this.apiErrorCallback(error);
        return of(false);
      })
    );
  }

  private apiErrorCallback = (error: AuthResponseError) => {
    if (error.type === AuthResponseErrorType.ErrorResponse) {
      this.onlineSessionService.logout();
      this.router.navigate(['/login/error']);
    } else {
      // Otherwise the user is not logged in or the session has expired, so redirect to the AD login page.
      this.authEventsService.loginRequiredEvent.trigger({ expired: false });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CustomerApprovalModalContext } from 'app/activity/models/customer-approval-modal-context.model';
import { CustomerCommandService } from 'app/core/hub-api';
import { forEach as _forEach } from 'lodash';
import * as moment from 'moment';
import { FileUploadService } from 'app/core/services/file-upload-service';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'angular-web-storage';
import { CompanySettingsService } from 'app/shared/services/company-setting.service';

@Component({
  selector: 'hub-customer-approval-file-import-modal',
  templateUrl: './customer-approval-file-import.component.html',
  styleUrls: ['./customer-approval-file-import.component.scss']
})

export class CustomerApprovalImportModalComponent extends FormComponent implements OnInit {
  form: any;
  validationMessages = {};
  context: Partial<CustomerApprovalModalContext>;
  file: File;
  reader: FileReader;
  private fileMaxSizeMB = 50;
  private fileMaxCharacter = 63; // maximum character for COVI file name is 63
  errorMessage: string;
  contactNames: any[];
  handoverId: string;
  customerId: string;
  clientId: string;
  contractContactId: string;
  data: any;
  saveInProgress: boolean;

  errors = {
    problemLoadingFile: 'There has been a problem loading the file',
    noFileSelected: 'Please select a file.',
    fileSizeExceeded: `The file exceeds the ${this.fileMaxSizeMB} MB size limit. Please choose a smaller file.`,
    customerRequired: 'Please select a customer.'
  };
  private coviPrefix = 'covi-';
  customerReq: boolean;
  firstload = true;

  constructor(
    public customerCommandService: CustomerCommandService,
    private fileUploadService: FileUploadService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private companySettingsService: CompanySettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.context.dialogClass = 'modal-dialog modal-lg';
    this.contactNames = this.getContactNames(this.context.Contracts);
    this.handoverId = this.context.HandoverId;
    this.customerId = this.context.CustomerId;
    this.clientId = this.companySettingsService.getCompanyId();
    this.reader = new FileReader();
    this.reader.onload = e => {
      try {
        this.data = (e.target as any).result;
        if (this.data.length === 0) {
          throw new Error();
        }
      } catch (error) {
        this.errorMessage = this.errors.problemLoadingFile;
      }
    };
  }

  /**
   * Converts bytes into megabytes
   * (requires the values to be sent in bytes and returns in megabytes)
   * Defaults the decimals to 2
   *
   * @param {number} bytes
   * @param {number} roundTo
   * @returns {number}
   * @memberof CustomerApprovalImportModalComponent
   */
  private bytesToMB(bytes: number, roundTo?: number): number {
    if (!roundTo) { roundTo = 2; }
    const converted = Number(bytes / Math.pow(1024, 2));
    return Number(roundTo ? converted.toFixed(roundTo) : converted);
  }

  /**
   * Validates the file and returns the correct error message
   * Detects if there is a file
   * Detects if the file size matches the max limit on (fileMaxSizeMB)
   *
   * @returns {string}
   * @memberof CustomerApprovalImportModalComponent
   */
  fileValidation(): string {
    this.errorMessage = null;
    // No file was selected.
    if (!this.file) {
      this.errorMessage = this.errors.noFileSelected;
    }
    if (this.file && this.bytesToMB(this.file.size) > this.fileMaxSizeMB) {
      this.errorMessage = this.errors.fileSizeExceeded;
    }
    return this.errorMessage;
  }

  /**
   * Gets the selected file and checks for validation
   *
   * @param {Event} event
   * @memberof CustomerApprovalImportModalComponent
   */
  onFileSelected(event: Event): void {
    this.errorMessage = undefined;
    this.file = (event.target as any).files[0];
    if (this.file) {
      this.reader.readAsBinaryString(this.file);
    }
    this.fileValidation();
  }


  /**
   * Gets the selected customer id
   *
   * @param {Event} event
   * @memberof CustomerApprovalImportModalComponent
   */
  onCustomerSelected(event: Event): void {
    this.contractContactId = (event.target as any).value;
  }

  /**
   * calls onSubmit method when the attachment is filled and saved
   *
   * @returns {string}
   * @memberof CustomerApprovalImportModalComponent
   */
  onSubmit(): string {
    this.firstload = false;
    if (this.fileValidation() == null && this.contractContactId) {
      this.saveInProgress = true;
      const newFileName = this.getFileName();
      const path = `Handover/${this.handoverId}/contact/${this.contractContactId}/${newFileName}`;
      const command: any = {
        CustomerId: this.customerId,
        HandoverId: this.handoverId,
        CustomerAttachment: {
          BlobPath: path,
          When: moment().toISOString(),
          ContractContactId: this.contractContactId,
          FileName: newFileName
        }
      };
      this.fileUploadService.uploadFile(this.file, path);
      this.customerCommandService.CreateCustomerApprovalWebConsoleCommand(command).subscribe(() => {
        this.saveInProgress = false;
        this.bsModalRef.hide();
      });
      return newFileName;
    }
  }

  /**
   * Gets new File name after trimming the file name with 63 characters
   *
   * @private
   * @returns {string}
   * @memberof CustomerApprovalImportModalComponent
   */
  private getFileName(): string {
    const fileExtension = this.file.name.split('.').pop().toLowerCase();
    const fileName = this.file.name.split('.').slice(0, -1).join('.');
    const maxChars = this.fileMaxCharacter - this.coviPrefix.length - fileExtension.length - 1; // (-1 for dot)
    const fileNameSized = fileName.substring(0, maxChars);
    return `${this.coviPrefix}${fileNameSized}.${fileExtension}`;
  }

  /**
   * Gets ContactName from ContractContacts
   *
   * @param {*} contractContacts
   * @returns {string[]}
   * @memberof CustomerApprovalImportModalComponent
   */
  getContactNames(contractContacts): string[] {
    const arrayOfString = [];
    _forEach(contractContacts, contractContact => {
      arrayOfString.push({
        id: contractContact.ContractContactId,
        value: contractContact.ContactName
      });
    });
    return arrayOfString;
  }
}

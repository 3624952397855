<carousel *ngIf="media" [attr.height]="height">
    <slide *ngFor="let image of media">
      <div *ngIf="image" class="image-thumbnail">
        <a (click)="openImageModal(image)">
          <div [ngStyle]="{'background-image': 'url(' + image + ')'}"></div>
          <i class="fa fa-search-plus"></i>
        </a>
      </div>
    </slide>
</carousel>

<div *ngIf="!media" class="no-image-placeholder" [attr.height]="height">
    <i class="fa fa-image"></i>
</div>

<form name="dismantleScaffoldForm" [formGroup]="form">
  <hub-modal-form-header title="{{title}}" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    <hub-form-group label="Notes" name="notes" [control]="notesFormControl">
      <textarea class="form-control" formControlName="notes" rows="3"></textarea>
    </hub-form-group>

    <hub-form-group label="Dismantle Date" name="dismantleDate" [control]="dismantleDateFormControl"
      [validationErrors]="validationErrors">
      <div class="date-picker">
        <hub-date-picker formControlName="dismantleDate" (valueChanges)="onDateRangeSelected($event)"
          [settings]="datePickerOptions" [isRange]="false">
        </hub-date-picker>
      </div>
    </hub-form-group>
    <hub-modal-form-footer saveText="Yes dismantle it" cancelText="No keep it" [errorMessage]="serverError"
      [inProgress]="saveInProgress" (submit)="onSubmit()" (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
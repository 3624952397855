<!-- EMPTY STATE PLACEHOLDER -->
<div class="row">
    <div class="empty-state-placeholder-large">
        <i class="fa fa-building fa-4x"></i><i class="fa fa-users fa-4x"></i>
        <h3>Welcome to the site contracts area</h3>
        <p *ngIf="customers.length">It looks like you <strong>haven't added any customers to the site</strong> yet.
            Click the 'New Contract' button to add a customer to the site and start a new contract.</p>
        <span *ngIf="customers.length">
            <hub-permission permissions="AddEditSites">
                <a class="btn button-primary" role="button" (click)="onAddContract()" title="Add a new contract"><i
                        class="fa fa-plus"></i>New
                    Contract</a>
            </hub-permission>
        </span>
        <p *ngIf="!customers.length">Before creating a contract you need to <strong>add at least one customer</strong>
            to the system. You can do that <a class="link" [routerLink]="['/customers']">here</a>!</p>
    </div>
</div>
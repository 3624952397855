import { Pipe, PipeTransform } from '@angular/core';

// Pipe for displaying items from any user Configurable lists.
@Pipe({
  name: 'idToTitle'
})
export class IdToTitlePipe implements PipeTransform {

  transform(id: string, configurationItems: any, titleProperty: string = 'Title'): any {
    const item = configurationItems[id];
    return item ? item[titleProperty] : '';
  }
}

<form name="inspectionStatusForm" [formGroup]="form">
  <hub-modal-form-header [title]="title" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">
    {{ text }}
    <hub-form-group *ngIf="pauseMode" label="Reason for pausing inspections" name="reason" [control]="reasonFormControl"
      [validationErrors]="validationErrors">
      <textarea class="form-control" formControlName="reason" rows="3"
        placeholder="Example: The site is closing for two weeks over the holidays."></textarea>
    </hub-form-group>
    <hub-form-group *ngIf="pauseMode" label="Date that inspections should resume again" name="resumeDate"
      [control]="resumeDateFormControl" [validationErrors]="validationErrors">
      <div class="date-picker">
        <hub-date-picker formControlName="resumeDate" (valueChanges)="onInspectionStatusDateChanged($event)"
        [settings]="datePickerOptions" [ranges]="{}" [isRange]="false" [showCustomRangeLabel]="false">
      </hub-date-picker>
      </div>
    </hub-form-group>
    <hub-modal-form-footer [saveText]="saveText" [cancelText]="cancelText" [requiredNote]="pauseMode"
      [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit(form.value)"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
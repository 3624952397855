import { MultiStepFormStep } from '../../models/multi-step-form-step';
import { MultiStepModalFormComponent } from '../multi-step-modal-form/multi-step-modal-form.component';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild } from '@angular/core';

/**
 * Component for controlling a multistep modal form.
 *
 * @export
 * @class MultiStepComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'hub-multi-step',
  templateUrl: './multi-step.component.html',
  styleUrls: ['./multi-step.component.scss']
})
export class MultiStepComponent implements OnInit, AfterViewInit {
  /* Stores all steps */
  @Input()
  steps: MultiStepFormStep[];
  /* Gets the sent context */
  @Input()
  context: any;
  /* Gets the current step */
  currentStep: number;
  /* Data object shared between all steps. */
  @Input()
  data: any;
  /* Dynamic component that directive access */
  @ViewChild(DynamicComponentDirective, { static: false }) dynamicComponent: DynamicComponentDirective;


  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef) { }

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view. 
   * It is invoked only once when the view is instantiated.
   *
   * @memberof MultiStepComponent
   */
  ngAfterViewInit(): void {
    this.showCurrentStep();
  }

  /**
   * Initializes the component.
   *
   * @memberof MultiStepComponent
   */
  ngOnInit(): void {
    this.currentStep = 0;
    this.data = this.data || {};
  }

  /**
   * Shows the current step data and layout
   *
   * @memberof MultiStepComponent
   */
  showCurrentStep(): void {
    const step = this.steps[this.currentStep];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(step.component);
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const instance = <MultiStepModalFormComponent>componentRef.instance;
    instance.data = this.data;
    instance.context = this.context;
    instance.nextStep = this.next;
    instance.previousStep = this.previous;
    // Checks this view and its children
    this.cdr.detectChanges();
  }

  /**
   * Moves to the next step.
   *
   * @memberof MultiStepComponent
   */
  next = () => {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
      this.showCurrentStep();
    }
  }

  /**
   * Moves to the previous step.
   *
   * @memberof MultiStepComponent
   */
  previous = () => {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.showCurrentStep();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivityModalContext } from 'app/activity/models/activity-modal-context.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-dismantle-view',
  templateUrl: './dismantle-view.component.html',
  styleUrls: ['./dismantle-view.component.scss']
})
export class DismantleViewComponent implements OnInit {
  ActivityType: Number;
  ActivityDetail: any;
  SiteReference: string;
  dismantleImages: any;
  context: Partial<ActivityModalContext>;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.ActivityType = this.context.ActivityType;
    this.ActivityDetail = this.context.ActivityDetail[0];
    this.SiteReference = this.context.Site.SiteReference;
    this.dismantleImages = Object.keys(this.ActivityDetail.DismantleData.MediaUrls).length ? this.ActivityDetail.DismantleData.MediaUrls : '';
  }

  dismiss(): void {
    this.modalService.setDismissReason(null);
    this.bsModalRef.hide();
  }
}

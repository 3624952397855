import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that a scaffold with a quick ID does not already exist in a given list of values.
 * if the scaffold number is null or empty, the validation will fail and return the required error. 
 * @param values - The list of values to check against.
 * @param suffix - An optional suffix to append to the scaffold number.
 * @param caseSensitive - Specifies whether the validation should be case-sensitive. Default is true.
 * @returns A validator function that can be used to validate a form control.
 */
export function scaffoldWithQuickIdMustNotExistValidator(values: string[], suffix?: string, caseSensitive = true): ValidatorFn {
    return (control: AbstractControl) => {
        values = caseSensitive ? values : values.map((v) => {
            if (v != null) { return v.toLowerCase(); }
        });
        var scaffoldNumber = control.value;
        if (scaffoldNumber == null || scaffoldNumber == "") {
            return {
                required: {
                    value: control.value
                }
            };
        }
        if (suffix) {
            scaffoldNumber += `-${suffix}`;
        }
        const controlValue = caseSensitive || !scaffoldNumber ? scaffoldNumber : scaffoldNumber.toLowerCase();
        const isValid = !values.includes(controlValue);
        return isValid ? null : {
            scaffoldWithQuickIdMustNotExist: {
                value: scaffoldNumber
            }
        };
    };
}

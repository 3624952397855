/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/


/**
 * Represents a filter option for estimating.
 */
export class Filter {
    Field: string;
    Operator: string;
    Value: string;
    IsUnary: boolean;
}

/**
 * Represents the sorting criteria for estimates.
 */
export class SortingCriteria {
    Field: string;
    Direction: string;
}

/**
 * Represents pagination options for retrieving a subset of data.
 */
export class Pagination {
    PageNumber: number;
    PageSize: number;
}

/**
 * Represents the binary operators that can be used in filtering operations.
 */
export enum BinaryOperator {
    Equals,
    NotEquals,
    GreaterThan,
    LessThan,
    GreaterThanOrEquals,
    LessThanOrEquals,
    Contains,
}

/**
 * Represents the unary operators used for filtering estimates.
 */
export enum UnaryOperator {
    IsNull,
    IsNotNull,
}

/**
 * Represents the request payload for estimating.
 */
export interface EstimateRequest {
    Pagination: Pagination;
    Sorting: SortingCriteria[];
    Filters: Filter[];
}

/**
 * Represents a request object for retrieving estimates.
 */
export class EstimatesRequest {
    Pagination: Pagination;
    Sorting: SortingCriteria[];
    Filters: Filter[];

    /**
     * Creates an instance of EstimatesRequest.
     * @param pagination - The pagination options.
     * @param sorting - The sorting criteria.
     * @param filters - The filter options.
     */
    constructor(pagination?: Pagination, sorting?: SortingCriteria[], filters?: Filter[]) {
        this.Pagination = pagination || new Pagination();
        this.Sorting = sorting || [];
        this.Filters = filters || [];
    }

    /**
     * Converts the EstimatesRequest object to a JSON string.
     * @returns The JSON representation of the EstimatesRequest object.
     */
    toJson(): string {
        return JSON.stringify(this);
    }

    /**
     * Creates an EstimatesRequest object from a JSON string.
     * @param json - The JSON string to parse.
     * @returns A new EstimatesRequest object.
     */
    static fromJson(json: string): EstimatesRequest {
        const obj = JSON.parse(json);
        return new EstimatesRequest(obj.Pagination, obj.Sorting, obj.Filters);
    }
}
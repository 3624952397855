<form name="undismantleScaffoldForm" [formGroup]="form">
  <hub-modal-form-header title="Off Hire Scaffold" (close)="bsModalRef.hide()"></hub-modal-form-header>
  <div class="modal-body">

    <!-- Off-hire Type -->
    <hub-form-group label="Off-hire Type" name="offHireType" [control]="offHireTypeFormControl"
      [validationErrors]="validationErrors">
      <div class="radio">
        <label>
          <input formControlName="offHireType" type="radio" [value]="offHireTypeEnum.Full"> Full
        </label>
      </div>
      <div class="radio">
        <label>
          <input formControlName="offHireType" type="radio" [value]="offHireTypeEnum.Partial"> Partial
        </label>
      </div>
    </hub-form-group>

    <hub-form-group label="Off-hire Date" name="offHireDate" [control]="offHireDateFormControl"
      [validationErrors]="validationErrors">
      <div class="date-picker">
        <hub-date-picker formControlName="offHireDate" (valueChanges)="onDateRangeSelected($event)"
          [settings]="datePickerOptions" [isRange]="false">
        </hub-date-picker>
      </div>
    </hub-form-group>

    <hub-form-group label="Requested By" name="requestedBy" [control]="requestedByFormControl"
      [validationErrors]="validationErrors">
      <select class="form-control md-select" formControlName="requestedBy">
        <option value="">-- please select a customer contact --</option>
        <option *ngFor="let contact of activeContractContacts" [value]="contact.ContractContactId">
          {{contact.ContactName}}</option>
      </select>
    </hub-form-group>

    <hub-form-group label="Comments" name="comments" [control]="commentsFormControl">
      <textarea class="form-control" formControlName="comments" rows="3"></textarea>
    </hub-form-group>

    <hub-modal-form-footer [errorMessage]="serverError" [inProgress]="saveInProgress" (submit)="onSubmit()"
      (close)="bsModalRef.hide()"></hub-modal-form-footer>
  </div>
</form>
import { Subscription, Observable, combineLatest } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { CustomerWebAppData, HandoverStatisticsQuery, ScaffoldStatisticsQuery, SiteWebAppData } from 'app/core/hub-api';
import { ContractData } from 'app/core/hub-api';

import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ScaffoldInspectionQueryService } from 'app/core/services/scaffold-inspection-query-service';
import { ScaffoldStatisticsQueryService } from 'app/core/services/scaffold-statistics-query-service';
import { HandoverStatisticsQueryService } from 'app/core/services/handover-statistics-query-service';

@AutoUnsubscribe()
@Component({
  selector: 'hub-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss']
})
export class SiteDashboardComponent implements OnInit, OnDestroy {
  contracts: ContractData[];
  customers: any;

  constructor(
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private scaffoldInspectionQueryService: ScaffoldInspectionQueryService,
    private scaffoldStatisticsQueryService: ScaffoldStatisticsQueryService,
    private handoverStatisticsQueryService: HandoverStatisticsQueryService,
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.snapshot.params['siteReference'];
    const obs$ = combineLatest(
      [this.siteDetailQueryService.siteDetailDataChanges(siteReference),
      this.customersQueryService.customersDataChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1]));
  }

  refreshComponent(site: SiteWebAppData, customers: CustomerWebAppData[]): void {
    // Dashboard data.
    this.scaffoldInspectionQueryService.scaffoldInspectionQuery(true, site.SiteId);
    this.scaffoldStatisticsQueryService
      .scaffoldStatisticsQuery(true, Object.assign(new ScaffoldStatisticsQuery(), { SiteId: site.SiteId }));
    this.handoverStatisticsQueryService
      .handoverStatisticsQuery(true, Object.assign(new HandoverStatisticsQuery(), { SiteId: site.SiteId }));

    this.contracts = site.Contracts.map(contract => {
      // Don't modify the cached data, clone it.
      contract = cloneDeep(contract);
      (contract as any).name = customers.find(customer => customer.CustomerId === contract.CustomerId).CustomerName;
      (contract as any).customerReference = customers.find(customer => customer.CustomerId === contract.CustomerId).CustomerReference;
      (contract as any).customerName = customers.find(customer => customer.CustomerId === contract.CustomerId).CustomerName;

      return contract as any;
    }).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    const customerIds = this.contracts.map(c => c.CustomerId);
    this.customers = customers.filter(c => customerIds.includes(c.CustomerId));
  }

  ngOnDestroy(): void { }
}

import { combineLatest } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationData, CustomerWebAppData, SiteWebAppData } from 'app/core/hub-api';
import { ConfigurationQueryService } from '../../../../../core/services/configuration-query.service';
import { CustomersQueryService } from '../../../../../core/services/customers-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { AddEditContractModalComponent } from '../add-edit-contract-modal/add-edit-contract-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SSModalConfig } from 'app/shared/models/ss-modal-config';

@Component({
  selector: 'hub-site-contracts-welcome',
  templateUrl: './site-contracts-welcome.component.html',
  styleUrls: ['./site-contracts-welcome.component.scss']
})
export class SiteContractsWelcomeComponent implements OnInit, OnDestroy {
  customers: CustomerWebAppData[];
  site: SiteWebAppData;
  configuration: ConfigurationData;

  constructor(
    public modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private siteDetailQueryService: SiteDetailQueryService,
    private customersQueryService: CustomersQueryService,
    private configurationQueryService: ConfigurationQueryService
  ) { }

  ngOnInit(): void {
    const siteReference = this.route.parent.parent.snapshot.params.siteReference;
    const obs$ = combineLatest(
      [this.siteDetailQueryService.siteDetailDataChanges(siteReference),
      this.customersQueryService.customersDataChanges(),
      this.configurationQueryService.configurationDataChanges()]
    );
    obs$.subscribe(latest => this.refreshComponent(latest[0], latest[1], latest[2]));
  }
  refreshComponent(site: SiteWebAppData, customers: CustomerWebAppData[], configuration: ConfigurationData): void {
    this.site = site;
    this.customers = customers;
    this.configuration = configuration;
  }

  onAddContract(): void {
    const context = {
      editMode: false,
      customers: this.customers,
      site: this.site,
      configuration: this.configuration
    };
    var modal = this.modalService.show(AddEditContractModalComponent, SSModalConfig.generate(context))
    modal.onHidden.subscribe(
      customerReference => {
        if (customerReference && typeof customerReference === 'string') {
          this.router.navigate([`./${customerReference}`], { relativeTo: this.route });
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnDestroy(): void { }
}

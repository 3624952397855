/**
* @license
* Copyright © Computer and Design Services Ltd.
* All rights are reserved. Reproduction or transmission in whole or in part, in any form or by any means,
* electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.
*/

import { UUID } from 'angular2-uuid';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigurationQueryService } from '../../../core/services/configuration-query.service';
import { errorMessages } from '../../../shared/values/error-messages';
import { AddEditListItemModalContext } from '../../models/add-edit-list-item-modal-context';
import { FormComponent } from '../form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LookupDataBase, ConfigurationCommandService } from 'app/core/hub-api';

/**
 * Add Edit List Item Modal
 * Allows users to create new list settings
 *
 * @export
 * @class AddEditListItemModalComponent
 * @extends {ModalFormComponent<AddEditListItemModalContext>} extends the generic modal form component
 * @implements {OnInit} Initialises the component
 */
@Component({
  selector: 'hub-add-edit-list-item-modal',
  templateUrl: './add-edit-list-item-modal.component.html',
  styleUrls: ['./add-edit-list-item-modal.component.scss']
})
export class AddEditListItemModalComponent extends FormComponent implements OnInit {
  /** Stores all parameters sent to this modal */
  context: Partial<AddEditListItemModalContext>;
  /** Form Group control */
  form: UntypedFormGroup;
  /** Title input controller */
  titleFormControl: UntypedFormControl;
  /** Report Text input controller */
  reportTextFormControl: UntypedFormControl;
  /** Subtitle input controller */
  subtitleFormControl: UntypedFormControl;
  /** Group name dropdown controller */
  groupNameFormControl: UntypedFormControl;
  /** All validation messages for the respective form fields */
  validationMessages = {
    title: {
      required: errorMessages.required
    },
    reportText: {
      required: errorMessages.required
    },
    groupIndex: {
      required: errorMessages.required
    }
  };
  /** Stores the selected item */
  item: LookupDataBase;
  /** Stores all the group names */
  groupNames: any[];
  /** Stores the selected item sort order */
  itemSortOrder: number;
  /** Stores the list key to be used to create the command */
  listKey: string;
  /** Stores the list title to be used on the modal title */
  listTitle: string;
  /** Decides if the subtitle input is visible */
  showSubtitle: boolean;
  /** Decides if the report text input is visible */
  showReportText: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public configurationCommandService: ConfigurationCommandService,
    public configurationQueryService: ConfigurationQueryService,
    public modalService: BsModalService,
  ) { super(); }

  /**
   * Component Initialisation
   *
   * @memberof AddEditListItemModalComponent
   */
  public ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.item = this.getNewItem();
    // the group names are a string list, for the ng-select it requires a property to link the id and name
    const _groupNames = this.context.groupNames;
    this.groupNames = [];
    if (_groupNames && _groupNames.length) {
      _groupNames.forEach(g => {
        this.groupNames.push({ name: g });
      });
    }
    this.itemSortOrder = this.context.itemSortOrder;
    this.listKey = this.context.listKey;
    this.listTitle = this.context.listTitle;

    this.showReportText = this.context.showReportText;
    this.showSubtitle = this.context.showSubtitle;

    this.titleFormControl = new UntypedFormControl(this.item.Title, Validators.required);
    this.reportTextFormControl = this.showReportText
      ? new UntypedFormControl(this.item.ReportText, Validators.required) : new UntypedFormControl(this.item.ReportText);
    this.subtitleFormControl = new UntypedFormControl(this.item.Subtitle);
    this.groupNameFormControl = this.groupNames && this.groupNames.length ? new UntypedFormControl(this.groupNames[0], Validators.required) : new UntypedFormControl(undefined);
    this.form = new UntypedFormGroup({
      title: this.titleFormControl,
      reportText: this.reportTextFormControl,
      groupName: this.groupNameFormControl,
      subtitle: this.subtitleFormControl
    });
    super.ngOnInit();
  }

  /**
   * Generates a new list item
   *
   * @private
   * @returns {LookupDataBase}
   * @memberof AddEditListItemModalComponent
   */
  private getNewItem(): LookupDataBase {
    return {
      GroupTitle: '',
      Code: '',
      Title: '',
      Subtitle: '',
      ReportText: '',
      RequireFurtherInformation: false,
      SortOrder: 0,
      IconName: '',
      ReplacedWithId: '',
      Deleted: false,
      IsDefault: false
    };
  }


  /**
   * Detects when the selected group name and sets it to the form
   *
   * @param {string} groupName selected group name
   * @returns {void}
   * @memberof AddEditSettingsModalComponent
   */
  public onSelectedGroupNameChanged(groupName: string): void {
    this.form.controls.groupName.setValue(groupName);
  }

  /**
   * When the user clicks save it will trigger validation then it will send the command to the api
   *
   * @param {*} formValues
   * @memberof AddEditListItemModalComponent
   */
  public onSubmit(formValues: any): void {
    if (this.validateForm()) {
      this.saveInProgress = true;
      const command: any = {
        Title: formValues.title,
        ReportText: formValues.reportText,
        SortOrder: this.itemSortOrder,
        GroupTitle: formValues.groupName || '',
        Subtitle: formValues.subtitle,
        RequireFurtherInformation: this.item.RequireFurtherInformation,
      };
      command[`${this.listKey}Id`] = UUID.UUID();
      const commandMethod = `Create${this.listKey}Command`;
      this.configurationCommandService[commandMethod](command).subscribe(() => {
        this.configurationQueryService.configurationQuery(false).subscribe(() => {
          this.saveInProgress = false;
          this.bsModalRef.hide();
        });
      });
    }
  }

}

import { Moment } from 'moment';
import * as moment from 'moment';
import dayjs, { Dayjs } from 'dayjs/esm';
import { DatePickerOptions } from 'app/reports/models/date-picker-options';
import utc from 'dayjs/esm/plugin/utc';

dayjs.extend(utc)

/** Default Dates used across the entire application */
export const dates: { [key: string]: Moment } = {
  // What should be considered the earliest date for the purpose of this application.
  minDate: moment('2000-01-01T00:00:00'),
  today: moment().startOf('day'),
  sixDaysAgo: moment().startOf('day').subtract(6, 'days'),
  oneMonthAgo: moment().startOf('day').subtract(29, 'days'),
  elevenMonthsAgo: moment().startOf('day').subtract(11, 'months'),
  tomorrow: moment().startOf('day').add(1, 'days'),
  nextSevenDays: moment().startOf('day').add(7, 'days'),
  currentMonthStart: moment().startOf('month').startOf('day'),
  currentMonthEnd: moment().endOf('month').endOf('day'),
};

/** Default Dates used across the entire application */
export const datesDayjs: { [key: string]: Dayjs } = {
  // What should be considered the earliest date for the purpose of this application.
  minDate: dayjs.utc("2000-01-01T00:00:00"),
  now: dayjs().utcOffset(0),
  startOfToday: dayjs().utcOffset(0).startOf('day'),
  endOfToday: dayjs().utcOffset(0).endOf('day'),
  oneWeekAgo: dayjs().utcOffset(0).subtract(1, 'week').startOf('day'),
  oneMonthAgo: dayjs().utcOffset(0).subtract(1, 'month').startOf('day'),
  elevenMonthsAgo: dayjs().utcOffset(0).subtract(11, 'months').startOf('day'),
  tomorrow: dayjs().utcOffset(0).add(1, 'days').endOf('day'),
  nextSevenDays: dayjs().utcOffset(0).add(1, 'week').endOf('day'),
  currentMonthStart: dayjs().utcOffset(0).startOf('month').startOf('day'),
  currentMonthEnd: dayjs().utcOffset(0).endOf('month').endOf('day'),
  yesterday: dayjs().utcOffset(0).subtract(1, 'days').startOf('day'),
};

/** Default Date Picker Range Settings */
export const defaultRanges = {
  'Today': {
    0: datesDayjs.startOfToday,
    1: datesDayjs.endOfToday,
  },
  'Yesterday & Today': {
    0: datesDayjs.yesterday,
    1: datesDayjs.endOfToday,
  },
  'Last 7 Days': {
    0: datesDayjs.oneWeekAgo,
    1: datesDayjs.endOfToday,
  },
  'Last 30 Days': {
    0: datesDayjs.oneMonthAgo,
    1: datesDayjs.endOfToday,
  },
  'This Month': {
    0: datesDayjs.currentMonthStart,
    1: datesDayjs.currentMonthEnd
  },
  'All Dates': {
    0: dates.minDate,
    1: datesDayjs.endOfToday,
  }
};

/** Default Date Picker Settings */
export const defaultDateSettings: DatePickerOptions = {
  ranges: defaultRanges,
  minDate: datesDayjs.minDate,
  maxDate: datesDayjs.endOfToday,
};
import { QueryService } from './query.service';
import { ContractEndpointService, QuoteQuery, QuoteScaffoldData } from 'app/core/hub-api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UnassignedScaffoldQueryService extends QueryService<QuoteScaffoldData[]> {

  constructor( private contractEndpointService: ContractEndpointService) {
    super();
  }

  unassignedQuery(useCached: boolean, contractId: string): Observable<QuoteScaffoldData[]> {
    return super.query(useCached, contractId);
  }

  unassignedDataChanges(contractId: string): Observable<QuoteScaffoldData[]> {
    return super.cachedDataChanges(contractId);
  }

  protected getDataFromServer(contractId: string): Observable<QuoteScaffoldData[]> {
    return this.contractEndpointService.UnassignedScaffoldQuery({ContractId: contractId});
  }
}

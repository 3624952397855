import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { scaffoldStatusIds } from '../../../../../shared/values/scaffold-status-ids';
import { AddEditScaffoldModalContext } from '../../../../models/add-edit-scaffold-modal-context';
import { EditScaffoldWebConsoleCommand, ScaffoldWebAppData, ScaffoldCommandService, SiteWebAppData } from 'app/core/hub-api';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { ScaffoldDetailQueryService } from '../../../../services/scaffold-detail-query-service';
import { SiteScaffoldQueryService } from '../../../../services/site-scaffolds-query.service';
import { SiteDetailQueryService } from '../../../../services/site-detail-query.service';
import { forkJoin } from 'rxjs';
import { FormComponent } from 'app/shared/components/form/form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hub-undismantle-scaffold-modal',
  templateUrl: './undismantle-scaffold-modal.component.html',
  styleUrls: ['./undismantle-scaffold-modal.component.scss']
})
export class UndismantleScaffoldModalComponent extends FormComponent implements OnInit {
  context: Partial<AddEditScaffoldModalContext>;
  site: SiteWebAppData;
  scaffold: ScaffoldWebAppData;
  validationMessages = {};
  scaffoldStatusIds: { [key: string]: string };
  form: UntypedFormGroup;
  undismantleFormControl: UntypedFormControl;

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private scaffoldCommandService: ScaffoldCommandService,
    private siteScaffoldQueryService: SiteScaffoldQueryService,
    private siteDetailQueryService: SiteDetailQueryService,
    private scaffoldDetailQueryService: ScaffoldDetailQueryService
  ) {
    super();
  }

  ngOnInit(): void {
    // gets the initial values from the function that calls this modal
    this.context = this.modalService.config.initialState;
    this.scaffoldStatusIds = scaffoldStatusIds;
    this.scaffold = cloneDeep(this.context.scaffold);
    this.site = this.context.site;
    this.undismantleFormControl = new UntypedFormControl(scaffoldStatusIds.incomplete);
    this.form = new UntypedFormGroup({
      undismantle: this.undismantleFormControl
    });
  }

  onSubmit(): void {
    this.saveInProgress = true;

    const command: EditScaffoldWebConsoleCommand = Object.assign(this.scaffold, {
      ScaffoldStatusId: scaffoldStatusIds.incomplete,
      OffsetFromUtc: moment().utcOffset()
    });

    this.scaffoldCommandService.EditScaffoldWebConsoleCommand(command).subscribe(() => {
      const obs$ = forkJoin([
        this.siteScaffoldQueryService.siteScaffoldQuery(false, this.site.SiteId),
        this.siteDetailQueryService.siteDetailQuery(false, this.site.SiteId),
        this.scaffoldDetailQueryService.scaffoldDetailQuery(false, this.scaffold.ScaffoldId)
      ]);
      obs$.subscribe(() => {
        this.saveInProgress = false;
        this.modalService.setDismissReason(this.scaffold.ScaffoldId);
        this.bsModalRef.hide();
      });
    }, this.serverErrorCallback);
  }
}

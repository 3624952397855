<hub-modal-form-header title="Import Scaffold" (close)="bsModalRef.hide()"></hub-modal-form-header>
<div class="modal-body">
  <div *ngIf="!showImportForm">

    <div class="item">
      <p class="title"><i class="fa fa-regular fa-file-excel"></i> Import a CSV file</p>

      <div class="file-uploader">
        <span class="file-input btn button-primary btn-file">
          <i class="fa fa-search"></i> Browse&hellip;
          <input class="btn button-primary btn-file" type="file" (change)="onFileSelected($event)">
        </span>
        <span class="import-text" *ngIf="!file">No file selected</span>
        <span class="import-text" *ngIf="file">{{file.name}}</span>
      </div>
      <span *ngIf="errorMessage" class="text-assertive">
        <i class="fa fa-warning"></i> {{errorMessage}}</span>
    </div>

    <div class="item" (click)="copyPasteValues()">
      <p class="title"><i class="fa fa-paste"></i> Type or paste values from a spreadsheet</p>
    </div>
    <div class="modal-footer">
      <div class="modal-buttons">
        <div class="btn-group pull-left" dropdown [dropup]="true">
          <button id="download-template" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-download-template"><i class="fa fa-download"></i>
            Download CSV Template <span class="caret"></span>
          </button>
          <ul id="dropdown-download-template" *dropdownMenu class="dropdown-menu" role="menu"
            aria-labelledby="download-template">
            <li role="menuitem"><a class="dropdown-item" (click)="exportToFile(exportType.CSV)">CSV (.csv)</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="exportToFile(exportType.EXCEL)">Excel (.xls)</a></li>
          </ul>
        </div>
        <button class="btn btn-large btn-inverse" (click)="onStartImport()">
          <i class="fa fa-chevron-circle-right"></i> Next
        </button>
      </div>
    </div>

  </div>

  <hub-multi-step *ngIf="showImportForm" [steps]="steps" [context]="context" [data]="data"></hub-multi-step>
</div>

<angular-slickgrid class="hidden" gridId="templateGrid" (onAngularGridCreated)="angularGridReady($event.detail)"
  [columnDefinitions]="columnDefinitions" [gridOptions]="gridOptions" [dataset]="dataset">
</angular-slickgrid>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ScaffoldTimelineData } from 'app/core/hub-api';

import { ScaffoldTimelineQueryService } from '../services/scaffold-timeline-query-service';

@Injectable()
export class ScaffoldTimelineResolver implements Resolve<ScaffoldTimelineData[]> {

  constructor(private scaffoldTimelineQueryService: ScaffoldTimelineQueryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ScaffoldTimelineData[]> {
    const id = route.params['scaffoldId'];
    return this.scaffoldTimelineQueryService.scaffoldTimelineQuery(true, id);
  }
}


import {map} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationData, ConfigurationEndpointService, ConfigurationQuery } from 'app/core/hub-api';

import { QueryService } from '../../core/services/query.service';

// Queries data from the server (or in memory cache where specified) and allows consumer to subscribe to data changes.
@Injectable()
export class ConfigurationQueryService extends QueryService<ConfigurationData> {

  // This is just the key we will use to look up our single value, we could use anything.
  private key = 'configuration';

  constructor(private configurationEndpointService: ConfigurationEndpointService) { super(); }

  configurationQuery(useCached: boolean): Observable<ConfigurationData> {
    return super.query(useCached, this.key);
  }

  configurationDataChanges(): Observable<ConfigurationData> {
    return super.cachedDataChanges(this.key);
  }

  protected getDataFromServer(key: string): Observable<ConfigurationData> {
    return this.configurationEndpointService.ConfigurationQuery(new ConfigurationQuery()).pipe(map(data => data[0]));
  }

  configurationQueryFromServer(): Observable<ConfigurationData> {
    return this.configurationEndpointService.ConfigurationQuery(new ConfigurationQuery()).pipe(map(data => data[0]));
  }
}

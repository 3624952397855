import { DepotsQueryService } from '../services/depots-query.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DepotData, DepotQuery } from 'app/core/hub-api';

@Injectable()
export class DepotsResolver implements Resolve<DepotData[]> {

  constructor(private depotsQueryService: DepotsQueryService) { }

  resolve(): Observable<DepotData[]> {
    return this.depotsQueryService.depotsQuery(false);
  }
}
